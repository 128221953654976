// src/components/client/TopMenu/NotificationTop.tsx

import React, { useState } from 'react';
import { FaBell } from 'react-icons/fa';
import { useTheme } from '../../../context/ThemeContext';
import useUserNotification from '../../../hooks/Dashboard/useUserNotification';
import { motion, AnimatePresence } from 'framer-motion';

interface NotifyTopProps {
  unreadNotifications: number;
  setUnreadNotifications: (count: number) => void; 
}

const formatDate = (dateString: string, includeTime: boolean = true) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  if (includeTime) {
    options.hour = '2-digit';
    options.minute = '2-digit';
    options.hour12 = true;
  }

  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
};

const NotifyTop: React.FC<NotifyTopProps> = ({ unreadNotifications, setUnreadNotifications }) => {
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const { notifications, setNotifications, markNotificationAsRead } = useUserNotification();

  const handleNotificationClick = async (id: string, isSubscription: boolean) => {
    try {
        await markNotificationAsRead(id, isSubscription);
        setNotifications((prevNotifications) =>
            prevNotifications.map((notification) => {
                const notificationItem = notification.orderID || notification.ticketId || notification.accountID;
                if (notificationItem === id && !notification.read) {
                    setUnreadNotifications(unreadNotifications - 1);
                    return { ...notification, read: true, notificationStatus: 'read' }; 
                }
                return notification;
            })
        );
    } catch (error) {
        console.error('Failed to mark notification as read:', error);
    }
  };

  return (
    <div className="relative inline-block">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative z-20 block p-2 text-gray-700"
      >
        <FaBell className="w-5 h-5 text-cyan-800" style={{ color: theme.textColor }} />
        {unreadNotifications > 0 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            {unreadNotifications}
          </span>
        )}
      </button>
      {isOpen && (
        <div
          onClick={() => setIsOpen(false)}
          className="fixed inset-0 z-10 w-full h-full bg-black opacity-50"
        />
      )}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute right-0 z-20 w-[320px] mt-2 origin-top-right bg-white rounded-md shadow-lg md:w-[280px] sm:w-[260px] sm:left-[-120px] xs:w-[240px] xs:left-[-105px]"
            style={{
              maxWidth: '90vw',
              right: '0%',
              transform: 'translateX(-50%)',
            }}>
            <div
              className="right-0 sm:left-[128px] xs:left-[115px]"
              style={{
                position: 'absolute',
                top: '-10px',
                right: '10px',
                width: '0',
                height: '0',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: '10px solid white',
              }}>
            </div>
            <div className="p-2 max-h-60 overflow-y-auto">
              {notifications.length === 0 ? (
                <div className="flex items-center justify-center h-24 border-2 border-dashed border-gray-300 rounded-lg">
                  <p className="text-sm text-slate-400 xs:text-xs">No new notifications</p>
                </div>
              ) : (
                notifications.map((notification) => (
                  <button
                    key={notification.ticketId || notification.orderID}
                    onClick={() => handleNotificationClick(notification.orderID || notification.ticketId || notification.accountID, notification.type === 'subscription')}
                    className={`flex text-left w-full px-4 py-3 mx-2 transition-colors duration-300 transform border-b border-gray-100 hover:bg-gray-50 ${
                      notification.read ? 'bg-white' : `bg-${theme.primaryColor}`
                    }`}
                  >
                    <div className="flex-1">
                      {notification.type === 'subscription' ? (
                        <p className="text-sm text-gray-600">
                          <span className="font-bold">{notification.userName}</span> received approved subscription for <span className="text-blue-500 hover:underline">{notification.planName}</span>,
                          expired on 
                          <span className="font-semibold"> {formatDate(notification.planExpiredDate, false)}</span>.
                        </p>
                      ) : (
                        <p className="text-sm text-gray-600">
                          <span className="font-bold">{notification.username}</span> received new approved ticket for{' '}
                          <span className="text-blue-500 hover:underline">{notification.eventTitle }</span>.
                        </p>
                      )}
                      <p className="text-xs text-gray-500 flex justify-end p-2 font-bold">
                        {formatDate(notification.createdAt)}
                      </p>
                    </div>
                  </button>
                ))
              )}
            </div>
            <button
              className="block w-full py-2 text-center hover:underline text-base md:text-sm sm:text-sm xs:text-sm"
              style={{
                backgroundColor: theme.textColor,
                color: theme.text2Color,
                fontFamily: theme.fontFamily,
              }}
            >
              Notifications
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NotifyTop;


// // udpate code --> 14 Aug 2024 v03
// // src/components/client/TopMenu/NotificationTop.tsx

// import React, { useState } from 'react';
// import { FaBell } from 'react-icons/fa';
// import { useTheme } from '../../../context/ThemeContext';
// import useUserNotification from '../../../hooks/Dashboard/useUserNotification';
// import { motion, AnimatePresence } from 'framer-motion';

// interface NotifyTopProps {
//   unreadNotifications: number;
//   setUnreadNotifications: (count: number) => void; 
// }

// const formatDate = (dateString: string, includeTime: boolean = true) => {
//   const options: Intl.DateTimeFormatOptions = {
//     year: 'numeric',
//     month: 'long',
//     day: 'numeric',
//   };

//   if (includeTime) {
//     options.hour = '2-digit';
//     options.minute = '2-digit';
//     options.hour12 = true;
//   }

//   const date = new Date(dateString);
//   return date.toLocaleDateString(undefined, options);
// };

// const NotifyTop: React.FC<NotifyTopProps> = ({ unreadNotifications, setUnreadNotifications }) => {
//   const { theme } = useTheme();
//   const [isOpen, setIsOpen] = useState(false);
//   const { notifications, setNotifications, markNotificationAsRead } = useUserNotification();

//   const handleNotificationClick = async (id: string, isSubscription: boolean) => {
//     try {
//       await markNotificationAsRead(id, isSubscription);
//       setNotifications((prevNotifications) =>
//         prevNotifications.map((notification) => {
//           if ((notification.ticketId || notification.accountID) === id && !notification.read) {
//             setUnreadNotifications(unreadNotifications - 1);
//             return { ...notification, read: true };
//           }
//           return notification;
//         })
//       );
//     } catch (error) {
//       console.error('Failed to mark notification as read:', error);
//     }
//   };

//   return (
//     <div className="relative inline-block">
//       <button
//         onClick={() => setIsOpen(!isOpen)}
//         className="relative z-20 block p-2 text-gray-700"
//       >
//         <FaBell className="w-5 h-5" style={{ color: theme.textColor }} />
//         {unreadNotifications > 0 && (
//           <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
//             {unreadNotifications}
//           </span>
//         )}
//       </button>
//       {isOpen && (
//         <div
//           onClick={() => setIsOpen(false)}
//           className="fixed inset-0 z-10 w-full h-full bg-black opacity-50"
//         />
//       )}
//       <AnimatePresence>
//         {isOpen && (
//           <motion.div
//             initial={{ opacity: 0, y: -10 }}
//             animate={{ opacity: 1, y: 0 }}
//             exit={{ opacity: 0, y: -10 }}
//             className="absolute right-0 z-20 w-[320px] mt-2 origin-top-right bg-white rounded-md shadow-lg md:w-[280px] sm:w-[260px] sm:left-[-120px] xs:w-[240px] xs:left-[-105px]"
//             style={{
//               maxWidth: '90vw',
//               right: '0%',
//               transform: 'translateX(-50%)',
//             }}>
//             <div
//               className="right-0 sm:left-[128px] xs:left-[115px]"
//               style={{
//                 position: 'absolute',
//                 top: '-10px',
//                 right: '10px',
//                 width: '0',
//                 height: '0',
//                 borderLeft: '10px solid transparent',
//                 borderRight: '10px solid transparent',
//                 borderBottom: '10px solid white',
//               }}>
//             </div>
//             <div className="p-2 max-h-60 overflow-y-auto">
//               {notifications.length === 0 ? (
//                 <div className="flex items-center justify-center h-24 border-2 border-dashed border-gray-300 rounded-lg">
//                   <p className="text-sm text-slate-400 xs:text-xs">No new notifications</p>
//                 </div>
//               ) : (
//                 notifications.map((notification) => (
//                   <button
//                     key={notification.ticketId || notification.orderID}
//                     onClick={() => handleNotificationClick(notification.orderID || notification.ticketId || notification.accountID, notification.type === 'subscription')}
//                     className={`flex text-left w-full px-4 py-3 mx-2 transition-colors duration-300 transform border-b border-gray-100 hover:bg-gray-50 ${
//                       notification.read ? 'bg-white' : `bg-${theme.primaryColor}`
//                     }`}
//                   >
//                     <div className="flex-1">
//                       {notification.type === 'subscription' ? (
//                         <p className="text-sm text-gray-600">
//                           <span className="font-bold">{notification.userName}</span> received approved subscription for <span className="text-blue-500 hover:underline">{notification.planName}</span>,
//                           expired on 
//                           <span className="font-semibold"> {formatDate(notification.planExpiredDate, false)}</span>.
//                         </p>
//                       ) : (
//                         <p className="text-sm text-gray-600">
//                           <span className="font-bold">{notification.username}</span> received new approved ticket for{' '}
//                           <span className="text-blue-500 hover:underline">{notification.eventTitle }</span>.
//                         </p>
//                       )}
//                       <p className="text-xs text-gray-500 flex justify-end p-2 font-bold">
//                         {formatDate(notification.createdAt)}
//                       </p>
//                     </div>
//                   </button>
//                 ))
//               )}
//             </div>
//             <button
//               className="block w-full py-2 text-center hover:underline text-base md:text-sm sm:text-sm xs:text-sm"
//               style={{
//                 backgroundColor: theme.textColor,
//                 color: theme.text2Color,
//                 fontFamily: theme.fontFamily,
//               }}
//             >
//               Notifications
//             </button>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// export default NotifyTop;



// // update code --> 14 Aug 2024 v02
// // src/components/client/TopMenu/NotificationTop.tsx

// import React, { useState } from 'react';
// import { FaBell } from 'react-icons/fa';
// import { useTheme } from '../../../context/ThemeContext';
// import useUserNotification from '../../../hooks/Dashboard/useUserNotification';
// import { motion, AnimatePresence } from 'framer-motion';

// interface NotifyTopProps {
//   unreadNotifications: number;
//   setUnreadNotifications: (count: number) => void; 
// }

// const formatDate = (dateString: string, includeTime: boolean = true) => {
//   const options: Intl.DateTimeFormatOptions = {
//     year: 'numeric',
//     month: 'long',
//     day: 'numeric',
//   };

//   if (includeTime) {
//     options.hour = '2-digit';
//     options.minute = '2-digit';
//     options.hour12 = true;
//   }

//   const date = new Date(dateString);
//   return date.toLocaleDateString(undefined, options);
// };

// const NotifyTop: React.FC<NotifyTopProps> = ({ unreadNotifications, setUnreadNotifications }) => {
//   const { theme } = useTheme();
//   const [isOpen, setIsOpen] = useState(false);
//   const { notifications, setNotifications, markNotificationAsRead } = useUserNotification();

//   const handleNotificationClick = async (id: string) => {
//     try {
//       await markNotificationAsRead(id);
//       setNotifications((prevNotifications) =>
//         prevNotifications.map((notification) => {
//           if ((notification.ticketId || notification.accountID) === id && !notification.read) {
//             setUnreadNotifications(unreadNotifications - 1);
//             return { ...notification, read: true };
//           }
//           return notification;
//         })
//       );
//     } catch (error) {
//       console.error('Failed to mark notification as read:', error);
//     }
//   };

//   return (
//     <div className="relative inline-block">
//       <button
//         onClick={() => setIsOpen(!isOpen)}
//         className="relative z-20 block p-2 text-gray-700"
//       >
//         <FaBell className="w-5 h-5" style={{ color: theme.textColor }} />
//         {unreadNotifications > 0 && (
//           <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
//             {unreadNotifications}
//           </span>
//         )}
//       </button>
//       {isOpen && (
//         <div
//           onClick={() => setIsOpen(false)}
//           className="fixed inset-0 z-10 w-full h-full bg-black opacity-50"
//         />
//       )}
//       <AnimatePresence>
//         {isOpen && (
//           <motion.div
//             initial={{ opacity: 0, y: -10 }}
//             animate={{ opacity: 1, y: 0 }}
//             exit={{ opacity: 0, y: -10 }}
//             className="absolute right-0 z-20 w-[320px] mt-2 origin-top-right bg-white rounded-md shadow-lg md:w-[280px] sm:w-[260px] sm:left-[-120px] xs:w-[240px] xs:left-[-105px]"
//             style={{
//               maxWidth: '90vw',
//               right: '0%',
//               transform: 'translateX(-50%)',
//             }}>
//             <div
//               className="right-0 sm:left-[128px] xs:left-[115px]"
//               style={{
//                 position: 'absolute',
//                 top: '-10px',
//                 right: '10px',
//                 width: '0',
//                 height: '0',
//                 borderLeft: '10px solid transparent',
//                 borderRight: '10px solid transparent',
//                 borderBottom: '10px solid white',
//               }}>
//             </div>
//             <div className="p-2 max-h-60 overflow-y-auto">
//               {notifications.length === 0 ? (
//                 <div className="flex items-center justify-center h-24 border-2 border-dashed border-gray-300 rounded-lg">
//                   <p className="text-sm text-slate-400 xs:text-xs">No new notifications</p>
//                 </div>
//               ) : (
//                 notifications.map((notification) => (
//                   <button
//                     key={notification.ticketId || notification.accountID}
//                     onClick={() => handleNotificationClick(notification.ticketId || notification.accountID)}
//                     className={`flex text-left w-full px-4 py-3 mx-2 transition-colors duration-300 transform border-b border-gray-100 hover:bg-gray-50 ${
//                       notification.read ? 'bg-white' : `bg-${theme.primaryColor}`
//                     }`}
//                   >
//                     <div className="flex-1">
//                       {notification.type === 'subscription' ? (
//                         <p className="text-sm text-gray-600">
//                           <span className="font-bold">{notification.userName}</span> received approved subscription for <span className="text-blue-500 hover:underline">{notification.planName}</span>,
//                           expired on 
//                           <span className="font-semibold"> {formatDate(notification.planExpiredDate, false)}</span>.
//                         </p>
//                       ) : (
//                         <p className="text-sm text-gray-600">
//                           <span className="font-bold">{notification.username}</span> received new approved ticket for{' '}
//                           <span className="text-blue-500 hover:underline">{notification.eventTitle }</span>.
//                         </p>
//                       )}
//                       <p className="text-xs text-gray-500 flex justify-end p-2 font-bold">
//                         {formatDate(notification.createdAt)}
//                       </p>
//                     </div>
//                   </button>
//                 ))
//               )}
//             </div>
//             <button
//               className="block w-full py-2 text-center hover:underline text-base md:text-sm sm:text-sm xs:text-sm"
//               style={{
//                 backgroundColor: theme.textColor,
//                 color: theme.text2Color,
//                 fontFamily: theme.fontFamily,
//               }}
//             >
//               Notifications
//             </button>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// export default NotifyTop;



// // update cdode --> 14 Aug 2024
// // src/components/client/TopMenu/NotificationTop.tsx

// import React, { useState } from 'react';
// import { FaBell } from 'react-icons/fa';
// import { useTheme } from '../../../context/ThemeContext';
// import useUserNotification from '../../../hooks/Dashboard/useUserNotification';
// import { motion, AnimatePresence } from 'framer-motion';

// interface NotifyTopProps {
//   unreadNotifications: number;
// }

// const NotifyTop: React.FC<NotifyTopProps> = ({ unreadNotifications }) => {
//   const { theme } = useTheme();
//   const [isOpen, setIsOpen] = useState(false);
//   const { notifications, setNotifications } = useUserNotification(); // Add setNotifications

//   const handleNotificationClick = (ticketId: string) => {
//     setNotifications((prevNotifications) =>
//       prevNotifications.map((notification) =>
//         notification.ticketId === ticketId ? { ...notification, read: true } : notification
//       )
//     );
//   };

//   const formatDate = (dateString: string) => {
//     const options: Intl.DateTimeFormatOptions = {
//       year: 'numeric',
//       month: 'long',
//       day: 'numeric',
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//     };
//     const date = new Date(dateString);
//     return date.toLocaleDateString(undefined, options);
//   };

//   return (
//     <div className="relative inline-block">
//       <button
//         onClick={() => setIsOpen(!isOpen)}
//         className="relative z-20 block p-2 text-gray-700"
//       >
//         <FaBell className="w-5 h-5" style={{ color: theme.textColor }} />
//         {unreadNotifications > 0 && (
//           <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
//             {unreadNotifications}
//           </span>
//         )}
//       </button>
//       {isOpen && (
//         <div
//           onClick={() => setIsOpen(false)}
//           className="fixed inset-0 z-10 w-full h-full bg-black opacity-50"
//         />
//       )}
//       <AnimatePresence>
//         {isOpen && (
//           <motion.div
//             initial={{ opacity: 0, y: -10 }}
//             animate={{ opacity: 1, y: 0 }}
//             exit={{ opacity: 0, y: -10 }}
//             className="absolute right-0 z-20 w-[320px] mt-2 origin-top-right bg-white rounded-md shadow-lg md:w-[280px] sm:w-[260px] sm:left-[-120px] xs:w-[240px] xs:left-[-105px]"
//             style={{
//               maxWidth: '90vw',
//               right: '0%',
//               transform: 'translateX(-50%)',
//             }}>
//             <div
//               className="right-0 sm:left-[128px] xs:left-[115px]"
//               style={{
//                 position: 'absolute',
//                 top: '-10px',
//                 right: '10px',
//                 width: '0',
//                 height: '0',
//                 borderLeft: '10px solid transparent',
//                 borderRight: '10px solid transparent',
//                 borderBottom: '10px solid white',
//               }}>
//             </div>
//             <div className="p-2 max-h-60 overflow-y-auto">
//               {notifications.length === 0 ? (
//                 <div className="flex items-center justify-center h-24 border-2 border-dashed border-gray-300 rounded-lg">
//                   <p className="text-sm text-slate-400 xs:text-xs">No new notifications</p>
//                 </div>
//               ) : (
//                 notifications.map((notification) => (
//                   <button
//                     key={notification.ticketId}
//                     onClick={() => handleNotificationClick(notification.ticketId)}
//                     className={`flex text-left w-full px-4 py-3 mx-2 transition-colors duration-300 transform border-b border-gray-100 hover:bg-gray-50 ${
//                       notification.read ? 'bg-white' : `bg-${theme.primaryColor}`
//                     }`}
//                   >
//                     <div className="flex-1">
//                       <p className="text-sm text-gray-600">
//                         <span className="font-bold">{notification.username}</span> has a new approved ticket for{' '}
//                         <span className="text-blue-500 hover:underline">{notification.eventTitle}</span>.
//                       </p>
//                       <p className="text-xs text-gray-500 flex justify-end p-2 font-bold">
//                         {formatDate(notification.createdAt)}
//                       </p>
//                     </div>
//                   </button>
//                 ))
//               )}
//             </div>
//             <button
//               className="block w-full py-2 text-center hover:underline text-base md:text-sm sm:text-sm xs:text-sm"
//               style={{
//                 backgroundColor: theme.textColor,
//                 color: theme.text2Color,
//                 fontFamily: theme.fontFamily,
//               }}
//             >
//               Your notifications
//             </button>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// export default NotifyTop;
