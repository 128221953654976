
// src/components/admin/Notification/NotificationFilter.tsx

import { useState, useEffect, useCallback } from 'react';

interface Notification {
  ticketId: string;
  eventTitle: string;
  username: string;
  status: string;
  createdAt: string;
  isImportant: boolean;
  isRead: boolean;
  receiptPath?: string;
}

export const useNotificationFilters = (notifications: Notification[]) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState('inbox');
  const [statusFilter, setStatusFilter] = useState<'all' | 'pending' | 'rejected' | 'approved'>('all');
  const [filteredNotifications, setFilteredNotifications] = useState<Notification[]>(notifications);

  useEffect(() => {
    const filtered = notifications.filter(
      (notification) =>
        (activeCategory === 'inbox' || notification.status === activeCategory) &&
        (statusFilter === 'all' || notification.status === statusFilter) &&
        (notification.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.eventTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.ticketId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.status.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredNotifications(filtered);
  }, [notifications, searchTerm, activeCategory, statusFilter]);

  const toggleStatusFilter = useCallback(() => {
    setStatusFilter((prevStatus) => {
      switch (prevStatus) {
        case 'all':
          return 'pending';
        case 'pending':
          return 'approved';
        case 'approved':
          return 'rejected';
        default:
          return 'all';
      }
    });
  }, []);

  const refetchNotifications = useCallback(async (fetchNotifications: () => void) => {
    fetchNotifications();
  }, []);

  return {
    searchTerm,
    setSearchTerm,
    activeCategory,
    setActiveCategory,
    statusFilter,
    toggleStatusFilter,
    filteredNotifications,
    setFilteredNotifications,
    refetchNotifications,
  };
};




// // update code --> 23 june 2024 v02
// // src/components/admin/Notification/NotificationFilters.tsx

// import { useState, useEffect, useCallback } from 'react';

// interface Notification {
//   ticketId: string;
//   eventTitle: string;
//   username: string;
//   status: string;
//   createdAt: string;
//   isImportant: boolean;
//   isRead: boolean;
//   receiptPath?: string;
// }

// export const useNotificationFilters = (notifications: Notification[]) => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [activeCategory, setActiveCategory] = useState('inbox');
//   const [filteredNotifications, setFilteredNotifications] = useState<Notification[]>(notifications);

//   useEffect(() => {
//     const filtered = notifications.filter(
//       (notification) =>
//         (activeCategory === 'inbox' || notification.status === activeCategory) &&
//         (notification.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           notification.eventTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           notification.ticketId.toLowerCase().includes(searchTerm.toLowerCase()))
//     );
//     setFilteredNotifications(filtered);
//   }, [notifications, searchTerm, activeCategory]);

//   const refetchNotifications = useCallback(async (fetchNotifications: () => void) => {
//     fetchNotifications();
//   }, []);

//   return {
//     searchTerm,
//     setSearchTerm,
//     activeCategory,
//     setActiveCategory,
//     filteredNotifications,
//     setFilteredNotifications,
//     refetchNotifications,
//   };
// };

