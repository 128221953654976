// src/components/UpdateState.tsx

import React, { useState } from 'react';
import { stateOptions } from '../hooks/Login/useFormValidation';

const StateModal: React.FC<{ onSave: (state: string) => void }> = ({ onSave }) => {
  const [selectedState, setSelectedState] = useState('');

  const handleSave = () => {
    if (selectedState) {
      onSave(selectedState);
    } else {
      alert('Please select a state.');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <div className="border text-rose-600 p-4 rounded-md my-4 text-sm">
          Thank you for being a valued member of our R One community. To ensure your profile is complete and to provide 
          you with the best updated application experience, please select your state from the options below. Updating your latest 
          information allows us to tailor our services and communications to better meet your needs.
        </div>        
        <select
          className="w-full p-2 border border-gray-300 rounded-lg"
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
        >
          <option value="">Select a state</option>
          {stateOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            className="px-4 py-2 bg-cyan-800 text-white rounded"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default StateModal;
