// src/components/client/MiniApp/App03_MySubscriptionPlan.tsx

import React, { useState } from 'react';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { FaSquareCheck } from "react-icons/fa6";
import MySubscriptionRegistration from './App03_MySubscriptionRegistration';
import useSubscriptionData from '../../../hooks/Subscription/useSubscriptionData';
import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

interface Plan {
  id: number;
  planID: string; 
  planName: string;
  planDescription: string;
  planFeatures: string[];
  planAmount: string;
  planCurrency: string;
  planPeriod: string;
  planTrialPeriod: string;
  planRequirement: string;
  trialRequirement: string[]; 
}
interface SubscriptionPlanProps {
  plans: Plan[];
  onBackToProducts: () => void;
  accountDetails: {
    accountID: string;
    accountType: string;
    accountNumber: string;
    accountPassword: string;
    accountServer: string;
  } | null;
  onPlanSelect: (plan: Plan) => void;
}

const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({ plans, onBackToProducts, onPlanSelect, accountDetails  }) => {
  const { user } = useUserDashboard();
  const [ selectedPlan, setSelectedPlan ] = useState<Plan | null>(null);
  const { convertPlanPeriod, fetchTrialInfo } = useSubscriptionData();
  const [ trialInfo, setTrialInfo ] = useState<string | null>(null);
  const [ trialDays, setTrialDays ] = useState<number | null>(null);

  const handleTrialInfoMouseEnter = async (planID: number) => {
    if (user?.email) {
      const { trialInfo, trialDays } = await fetchTrialInfo(user.email, planID);
      setTrialInfo(trialInfo);
      setTrialDays(trialDays);
    }
  };
  
  const handleTrialInfoMouseLeave = () => {
    setTrialInfo(null);
    setTrialDays(null);
  };  
 
  const handlePlanSelection = (plan: Plan) => {
    onPlanSelect(plan);
  };

  return (
    <div>
      {selectedPlan ? (
        <MySubscriptionRegistration 
          plan={selectedPlan} 
          onBack={() => setSelectedPlan(null)}
          accountDetails={accountDetails}
        /> 
      ) : (
        <>
          <div>
            {plans && plans.length > 0 ? (
              <div className="flex flex-wrap justify-center gap-4 mt-4 sm:flex-col xs:flex-col sm:mt-0 xs:mt-0">
              {[...plans].reverse().map((plan, index) => (
                <div 
                  key={index} 
                  className="flex-1 max-w-xs text-center p-4 border rounded shadow-sm flex flex-col justify-between py-4">
                  <div>
                    <h4 className="font-semibold sm:text-lg xs:text-lg">{plan.planName}</h4>
                    <p className="text-muted-foreground my-2 sm:text-sm xs:text-xs">{plan.planDescription}</p>
                    <div className="flex justify-center items-center m-4">
                      <ul className="list-none pl-0 text-left sm:text-sm xs:text-xs">
                        {plan.planFeatures.map((planFeature, i) => (
                          <li key={i} className="flex items-start mb-1">
                            <span className="mr-2 mt-1"><FaSquareCheck /></span>
                            <span>{planFeature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p className="flex justify-center items-center text-sm mt-4">
                        Trial info
                        <div className="relative group">
                          <BsExclamationCircleFill
                            className="w-4 h-4 ml-2 sm:w-3 xs:w-3 text-cyan-800 hover:text-gray-900 cursor-pointer"
                            aria-hidden="true"
                            onMouseEnter={() => handleTrialInfoMouseEnter(plan.id)}
                            onMouseLeave={handleTrialInfoMouseLeave}
                          />
                          {/* Tool Tips */}
                          <div className="absolute top-full mt-2 hidden z-150 w-[160px] px-4 py-2 text-sm sm:text-xs rounded-lg shadow-lg group-hover:block bg-cyan-800 text-white border-2 border-white">
                            {trialInfo && trialDays ? `You are entitled to a trial for ${trialDays} days` : trialInfo}
                          </div>
                        </div>
                      </p>
                      <p className="font-semibold mt-0">
                        {Number(plan.planPeriod) === 30 ? (
                          <div className="flex flex-col">
                            <span>{`${plan.planAmount} ${plan.planCurrency} / Month`}</span>
                            <span className="text-xs text-gray-400">{`( Total: ${(Number(plan.planAmount) * 12).toFixed(2)} ${plan.planCurrency} per annual )`}</span>
                          </div>
                        ) : Number(plan.planPeriod) === 365 ? (
                          <div className="flex flex-col">
                            <span>{`${(Number(plan.planAmount) * 12).toFixed(2)} ${plan.planCurrency} / Annual`}</span>
                            <span className="text-xs text-gray-400">{`( Equivalent: ${Number(plan.planAmount).toFixed(2)} ${plan.planCurrency} per month )`}</span>
                          </div>
                        ) : (
                          `${plan.planAmount} ${plan.planCurrency} / ${convertPlanPeriod(plan.planPeriod)}`
                        )}
                      </p>
                    </div>
                    <div className="px-4 py-2">
                      {Number(plan.planPeriod) === 30 && (
                        <p className="text-rose-600 bg-gray-200 text-xs py-1 rounded-full">
                          Yearly Total: {(Number(plan.planAmount) * 12).toFixed(2)} {plan.planCurrency}
                        </p>
                      )}
                      {Number(plan.planPeriod) === 365 && (
                        <p className="text-rose-600 bg-gray-200 text-xs py-1 rounded-full">
                          {(() => {
                            const monthlyPlan = plans.find((p: Plan) => Number(p.planPeriod) === 30);
                            if (monthlyPlan) {
                              const monthlyCost = Number(monthlyPlan.planAmount);
                              const yearlyCost = Number(plan.planAmount) * 12;
                              const yearlyCostIfMonthly = monthlyCost * 12;
                              const savings = yearlyCostIfMonthly - yearlyCost;
                              
                              return `Yearly Save: $${savings.toFixed(2)} ${plan.planCurrency}`;
                            }
                            return 'No monthly plan available for comparison';
                          })()}
                        </p>
                      )}
                    </div>
                    <div className="text-center mt-2">
                      <span className="px-4 py-2 bg-cyan-800 text-white hover:bg-cyan-900 rounded cursor-pointer sm:text-sm xs:text-sm"
                        onClick={() => handlePlanSelection(plan)}>
                        Subscribe
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>            
            ) : (
              <div className="flex justify-center items-center p-4 border-dashed border-4 border-gray-300 rounded-lg">
                <p className="text-muted-foreground text-center sm:text-sm xs:text-sm">No plans available for this product.</p>
              </div>
            )}
          </div>
          <div className="flex justify-end items-center py-4">
            {/* <div className="text-rose-500 text-sm">AlgoMatrix Subscription are only allow <strong>1</strong> Trial account and <strong>1</strong> Demo account</div> */}
            <button
              className="flex justify-between items-center px-4 py-2 sm:text-sm xs:text-xs bg-gray-200 text-gray-800 rounded"
              onClick={onBackToProducts}
            >
            <FaArrowCircleLeft className="mr-2" />
              Back to Products
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionPlan;

// // update code --> 02 Sept 2024
// // src/components/client/MiniApp/App03_MySubscriptionPlan.tsx

// import React, { useState } from 'react';
// import { BsExclamationCircleFill } from 'react-icons/bs';
// import { FaArrowCircleLeft } from 'react-icons/fa';
// import { FaSquareCheck } from "react-icons/fa6";
// import MySubscriptionRegistration from './App03_MySubscriptionRegistration';
// import useSubscriptionData from '../../../hooks/Subscription/useSubscriptionData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';

// interface Plan {
//   id: number;
//   planID: string; 
//   planName: string;
//   planDescription: string;
//   planFeatures: string[];
//   planAmount: string;
//   planCurrency: string;
//   planPeriod: string;
//   planTrialPeriod: string;
//   planRequirement: string;
//   trialRequirement: string[]; 
// }
// interface SubscriptionPlanProps {
//   plans: Plan[];
//   onBackToProducts: () => void;
//   accountDetails: {
//     accountID: string;
//     accountType: string;
//     accountNumber: string;
//     accountPassword: string;
//     accountServer: string;
//   } | null;
//   onPlanSelect: (plan: Plan) => void;
// }

// const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({ plans, onBackToProducts, onPlanSelect, accountDetails  }) => {
//   const { user } = useUserDashboard();
//   const [ selectedPlan, setSelectedPlan ] = useState<Plan | null>(null);
//   const { convertPlanPeriod, fetchTrialInfo } = useSubscriptionData();
//   const [ trialInfo, setTrialInfo ] = useState<string | null>(null);
//   const [ trialDays, setTrialDays ] = useState<number | null>(null);

//   const handleTrialInfoMouseEnter = async (planID: number) => {
//     if (user?.email) {
//       const { trialInfo, trialDays } = await fetchTrialInfo(user.email, planID);
//       setTrialInfo(trialInfo);
//       setTrialDays(trialDays);
//     }
//   };
  
//   const handleTrialInfoMouseLeave = () => {
//     setTrialInfo(null);
//     setTrialDays(null);
//   };  
 
//   const handlePlanSelection = (plan: Plan) => {
//     onPlanSelect(plan);
//   };

//   return (
//     <div>
//       {selectedPlan ? (
//         <MySubscriptionRegistration 
//           plan={selectedPlan} 
//           onBack={() => setSelectedPlan(null)}
//           accountDetails={accountDetails}
//         /> 
//       ) : (
//         <>
//           <div>
//             {plans && plans.length > 0 ? (
//               <div className="flex flex-wrap justify-center gap-4 mt-4 sm:flex-col xs:flex-col sm:mt-0 xs:mt-0">
//               {[...plans].reverse().map((plan, index) => (
//                 <div 
//                   key={index} 
//                   className="flex-1 max-w-xs text-center p-4 border rounded shadow-sm flex flex-col justify-between py-4">
//                   <div>
//                     <h4 className="font-semibold sm:text-lg xs:text-lg">{plan.planName}</h4>
//                     <p className="text-muted-foreground my-2 sm:text-sm xs:text-xs">{plan.planDescription}</p>
//                     <div className="flex justify-center items-center m-4">
//                       <ul className="list-none pl-0 text-left sm:text-sm xs:text-xs">
//                         {plan.planFeatures.map((planFeature, i) => (
//                           <li key={i} className="flex items-start mb-1">
//                             <span className="mr-2 mt-1"><FaSquareCheck /></span>
//                             <span>{planFeature}</span>
//                           </li>
//                         ))}
//                       </ul>
//                     </div>
//                   </div>
//                   <div>
//                     <div>
//                       <p className="flex justify-center items-center text-sm mt-4">
//                         Trial info
//                         <div className="relative group">
//                           <BsExclamationCircleFill
//                             className="w-4 h-4 ml-2 sm:w-3 xs:w-3 text-cyan-800 hover:text-gray-900 cursor-pointer"
//                             aria-hidden="true"
//                             onMouseEnter={() => handleTrialInfoMouseEnter(plan.id)}
//                             onMouseLeave={handleTrialInfoMouseLeave}
//                           />
//                           {/* Tool Tips */}
//                           <div className="absolute top-full mt-2 hidden z-150 w-[160px] px-4 py-2 text-sm sm:text-xs rounded-lg shadow-lg group-hover:block bg-cyan-800 text-white border-2 border-white">
//                             {trialInfo && trialDays ? `You are entitled to a trial for ${trialDays} days` : trialInfo}
//                           </div>
//                         </div>
//                       </p>
//                       <p className="font-semibold mt-0">
//                         {Number(plan.planPeriod) === 30 ? (
//                           <div className="flex flex-col">
//                             <span>{`${plan.planAmount} ${plan.planCurrency} / Month`}</span>
//                             <span className="text-xs text-gray-400">{`( Total: ${(Number(plan.planAmount) * 12).toFixed(2)} ${plan.planCurrency} per annual )`}</span>
//                           </div>
//                         ) : Number(plan.planPeriod) === 365 ? (
//                           <div className="flex flex-col">
//                             <span>{`${(Number(plan.planAmount) * 12).toFixed(2)} ${plan.planCurrency} / Annual`}</span>
//                             <span className="text-xs text-gray-400">{`( Equivalent: ${Number(plan.planAmount).toFixed(2)} ${plan.planCurrency} per month )`}</span>
//                           </div>
//                         ) : (
//                           `${plan.planAmount} ${plan.planCurrency} / ${convertPlanPeriod(plan.planPeriod)}`
//                         )}
//                       </p>
//                     </div>
//                     <div className="px-4 py-2">
//                       {Number(plan.planPeriod) === 30 && (
//                         <p className="text-rose-600 bg-gray-200 text-xs py-1 rounded-full">
//                           Yearly Total: {(Number(plan.planAmount) * 12).toFixed(2)} {plan.planCurrency}
//                         </p>
//                       )}
//                       {Number(plan.planPeriod) === 365 && (
//                         <p className="text-rose-600 bg-gray-200 text-xs py-1 rounded-full">
//                           {(() => {
//                             const monthlyPlan = plans.find((p: Plan) => Number(p.planPeriod) === 30);
//                             if (monthlyPlan) {
//                               const monthlyCost = Number(monthlyPlan.planAmount);
//                               const yearlyCost = Number(plan.planAmount) * 12;
//                               const yearlyCostIfMonthly = monthlyCost * 12;
//                               const savings = yearlyCostIfMonthly - yearlyCost;
                              
//                               return `Yearly Save: $${savings.toFixed(2)} ${plan.planCurrency}`;
//                             }
//                             return 'No monthly plan available for comparison';
//                           })()}
//                         </p>
//                       )}
//                     </div>
//                     <div className="text-center mt-2">
//                       <span className="px-4 py-2 bg-cyan-800 text-white hover:bg-cyan-900 rounded cursor-pointer sm:text-sm xs:text-sm"
//                         onClick={() => handlePlanSelection(plan)}>
//                         Subscribe
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>            
//             ) : (
//               <div className="flex justify-center items-center p-4 border-dashed border-4 border-gray-300 rounded-lg">
//                 <p className="text-muted-foreground text-center sm:text-sm xs:text-sm">No plans available for this product.</p>
//               </div>
//             )}
//           </div>
//           <div className="flex justify-end items-center py-4">
//             {/* <div className="text-rose-500 text-sm">AlgoMatrix Subscription are only allow <strong>1</strong> Trial account and <strong>1</strong> Demo account</div> */}
//             <button
//               className="flex justify-between items-center px-4 py-2 sm:text-sm xs:text-xs bg-gray-200 text-gray-800 rounded"
//               onClick={onBackToProducts}
//             >
//             <FaArrowCircleLeft className="mr-2" />
//               Back to Products
//             </button>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default SubscriptionPlan;

