// src/components/admin/a01_Overview.tsx

import React from 'react';
import { FaDollarSign, FaShoppingCart, FaArrowUp, FaUser, FaFlag, FaChartBar } from 'react-icons/fa';
import { RiLineChartFill } from "react-icons/ri";
import { useTheme } from '../../context/ThemeContext';

// Define types for stats and events
interface Stat {
  title: string;
  value: string;
  icon: React.ReactNode;
}

interface Event {
  title: string;
  date: string;
}

// Define stats and events data
const stats: Stat[] = [
  { title: 'Earnings', value: '$350.4', icon: <FaDollarSign /> },
  { title: 'Spending This Month', value: '$642.39', icon: <FaShoppingCart /> },
  { title: 'Sales', value: '$574.34', icon: <FaArrowUp /> },
  { title: 'Your Balance', value: '$1,000', icon: <FaUser /> },
  { title: 'New Tasks', value: '154', icon: <FaFlag /> },
  { title: 'Total Projects', value: '2935', icon: <FaChartBar /> },
];

const events: Event[] = [
  { title: 'Meeting with John', date: '2024-06-01' },
  { title: 'Project Deadline', date: '2024-06-10' },
  { title: 'Team Outing', date: '2024-06-15' },
];

const Overview: React.FC = () => {
  const { theme } = useTheme(); // Access theme from ThemeContext

  return (
    <div style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }} className="p-6"> {/* Apply theme text color and font */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {stats.map((stat, index) => (
          <div key={index} className="bg-white p-4 rounded shadow flex items-center">
            <div className="text-blue-500 mr-4">
              {stat.icon}
            </div>
            <div>
              <h4 className="text-xl font-semibold">{stat.value}</h4>
              <p>{stat.title}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
        <div className="md:col-span-2 bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-4">Revenue</h2>
          <div className="flex items-center">
            <RiLineChartFill className="text-blue-500 text-3xl mr-4" />
            <div className="w-full">
              <div className="h-4 bg-gray-200 rounded-full">
                <div className="h-4 bg-blue-500 rounded-full" style={{ width: '60%' }}></div>
              </div>
              <p className="mt-2">Monthly Revenue</p>
            </div>
          </div>
        </div>
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-4">Events</h2>
          <ul>
            {events.map((event, index) => (
              <li key={index} className="mb-2">
                <h4 className="font-semibold">{event.title}</h4>
                <p className="text-gray-600">{event.date}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Overview;
