// src/hooks/MiniApp/useEventSlot.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

const useEventSlot = (eventTitle: string) => {
  const { apiUrl } = useEnv();
  const [slot, setSlot] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchSlot = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}/api/programs`);
      const data = await response.json();
      const event = data.find((e: any) => e.name === eventTitle);
      if (event) {
        setSlot(event.slot);
      } else {
        setError('Event not found');
      }
    } catch (err) {
      setError('Failed to fetch slot count');
    } finally {
      setLoading(false);
    }
  }, [apiUrl, eventTitle]);

  useEffect(() => {
    fetchSlot();
  }, [fetchSlot]);

  return { slot, loading, error, fetchSlot };
};

export default useEventSlot;


