// src/components/client/dashboard/UserInfoAlert.tsx

import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimes, FaExclamationCircle } from 'react-icons/fa';
import { useTheme } from '../../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';

interface UserUpdateAlertProps {
  visible: boolean;
  onClose: () => void;
  success: boolean;
}

const UserUpdateAlert: React.FC<UserUpdateAlertProps> = ({ visible, onClose, success }) => {
  const { theme } = useTheme();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (visible) {
      setShowModal(true);
      if (success) {
        const timer = setTimeout(() => {
          onClose();
          navigate('/dashboard'); // Navigate back to dashboard
        }, 3000);
        return () => clearTimeout(timer);
      }
    } else {
      setShowModal(false);
    }
  }, [visible, success, navigate, onClose]);

  if (!visible) return null;

  return (
    <div className={`fixed inset-x-0 top-0 transition-transform transform ${showModal ? 'translate-y-0' : '-translate-y-full'} z-50 px-4`}
      style={{ fontFamily: theme.fontFamily }}>
      <div className={`relative flex flex-col p-4 mt-4 mb-4 text-sm ${success ? 'text-green-800 bg-green-100 border-green-500' : 'text-red-800 bg-red-100 border-red-500'} rounded-lg max-w-lg mx-auto md:max-w-2xl`}
        role="alert">
        <div className="flex items-center">
          {success ? <FaCheckCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" /> : <FaExclamationCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" />}
          <span className="font-medium" style={{ color: theme.textColor }}>
            {success ? 'Update Successful!' : 'Update Failed'}
          </span>
        </div>
        <div className={`mt-2 ${success ? 'text-green-700' : 'text-red-700'} md:ml-4`}>
          {success 
            ? 'Your profile has been successfully updated.'
            : 'There was an error updating your profile. Please try again later.'}
        </div>
        <button onClick={onClose} className={`absolute top-2 right-2 ${success ? 'text-green-800 hover:text-green-600' : 'text-red-800 hover:text-red-600'} focus:outline-none`}>
          <FaTimes className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default UserUpdateAlert;
