// // src/components/admin/ClientData/UserDetailAlert.tsx

import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

interface SuccessAlertProps {
  message: string;
}

const SuccessAlert: React.FC<SuccessAlertProps> = ({ message }) => {
  return (
    <div className="flex w-full overflow-hidden bg-green-100 rounded-lg shadow-md mt-4">
      <div className="flex items-center justify-center w-12 bg-green-300">
        <FaCheckCircle className="w-6 h-6 text-white" />
      </div>
      <div className="px-4 py-2 -mx-3">
        <div className="mx-3">
          <span className="font-semibold text-green-500">Success</span>
          <p className="text-sm text-gray-700">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default SuccessAlert;

// // update code --> 25 june 2024
// // src/components/admin/ClientData/UserDetailAlert.tsx

// import React from 'react';
// import { FaCheckCircle } from 'react-icons/fa';

// interface SuccessAlertProps {
// }

// const SuccessAlert: React.FC<SuccessAlertProps> = () => {
//   return (
//     <div className="flex w-full overflow-hidden bg-green-100 rounded-lg shadow-md mt-4">
//       <div className="flex items-center justify-center w-12 bg-green-300">
//         <FaCheckCircle className="w-6 h-6 text-white" />
//       </div>
//       <div className="px-4 py-2 -mx-3">
//         <div className="mx-3">
//           <span className="font-semibold text-green-500">Success</span>
//           <p className="text-sm text-gray-700">User info had been modify!</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SuccessAlert;
