// src/components/admin/admin04_ProgramSetting.tsx

import React from 'react';
import ProgramList from '../../components/admin/EventSetting/ProgramList';
import ProgramForm from '../../components/admin/EventSetting/ProgramSetting';

const ProgramSetting: React.FC = () => {

  return (
    <div className="w-auto h-auto flex flex-col justify-center items-center p-10 border-2">
      <div className="px-10">
        <ProgramList />
      </div>
      <div className="px-10">
        <ProgramForm />
      </div>
    </div>
  );
};

export default ProgramSetting;

// // udpate code --> 19 june 2024
// // src/components/admin/admin04_ProgramSetting.tsx

// import React, { useState } from 'react';
// import { useTheme } from '../../context/ThemeContext';
// import useProgram, { Program } from '../../hooks/AdminDashboard/useProgram';

// const ProgramSetting: React.FC = () => {
//   const { theme } = useTheme();
//   const { programs, loading, error, createProgram, updateProgramStatus } = useProgram();
//   const [formValues, setFormValues] = useState({
//     programName: '',
//     timePeriod: '',
//     amount: '',
//     currency: 'USD',
//     startDate: '',
//     endDate: '',
//     points: '',
//     description: '',
//     redeemNumber: '',
//   });

//   const handleSelectProgram = (program: Program) => {
//     setFormValues({
//       programName: program.name,
//       timePeriod: program.timePeriod,
//       amount: program.amount.toString(),
//       currency: 'USD', // Default value for now
//       startDate: program.startDate || '',
//       endDate: program.endDate || '',
//       points: program.points?.toString() || '',
//       description: program.description || '',
//       redeemNumber: program.redeemNumber?.toString() || '',
//     });
//   };

//   const handleApply = (programId: number) => {
//     updateProgramStatus(programId, 'running');
//   };

//   const handleCancel = (programId: number) => {
//     updateProgramStatus(programId, 'stopped');
//   };

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     const newProgram: Omit<Program, 'id'> = {
//       name: formValues.programName,
//       timePeriod: formValues.timePeriod,
//       amount: Number(formValues.amount),
//       currency: formValues.currency as 'USD' | 'MYR' | 'FREE',
//       startDate: formValues.startDate,
//       endDate: formValues.endDate,
//       points: Number(formValues.points),
//       description: formValues.description,
//       redeemNumber: Number(formValues.redeemNumber),
//       status: 'stopped',
//     };
//     createProgram(newProgram);
//   };

//   const calculateDays = (start: string, end: string) => {
//     const startDate = new Date(start);
//     const endDate = new Date(end);
//     const timeDiff = endDate.getTime() - startDate.getTime();
//     const daysDiff = timeDiff / (1000 * 3600 * 24);
//     return daysDiff;
//   };

//   const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const amount = e.target.value;
//     setFormValues({ ...formValues, amount });
//   };

//   return (
//     <div className="flex justify-center p-10 border-2">
//       {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}
//       {programs.length === 0 ? (
//         <p>No event listed</p>
//       ) : (
//         <div className="w-[800px] mr-4 py-10">
//           <h2 className="text-2xl font-bold mb-4" style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}>
//             Program List
//           </h2>
//           <div className="p-4 bg-white rounded border-2 border-gray-300 shadow-ml" style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//             <ul>
//               {programs.map((program) => (
//                 <li key={program.id} className="mb-4 p-4 bg-gray-100 rounded cursor-pointer" onClick={() => handleSelectProgram(program)}>
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <h3 className="font-semibold" style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//                         {program.name}
//                       </h3>
//                       <p>Period: {program.timePeriod}, Amount: ${program.amount}</p>
//                     </div>
//                     <span className={`px-2 py-1 rounded ${program.status === 'running' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
//                       {program.status === 'running' ? 'Running' : 'Stopped'}
//                     </span>
//                   </div>

//                   {/* Program List Button */}
//                   <div className="flex mt-4">
//                     <button className="mr-2 px-6 py-2 rounded font-medium" onClick={() => handleApply(program.id)} style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }}>
//                       Release
//                     </button>
//                     <button className="mr-2 px-4 py-2 bg-rose-500 rounded font-medium" onClick={() => handleCancel(program.id)} style={{ color: theme.text2Color, fontFamily: theme.fontFamily }}>
//                       Stopped
//                     </button>
//                   </div>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         </div>
//       )}
//       <div className="w-[1000px] ml-4 py-10">
//         <h2 className="text-2xl font-semibold mb-4" style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//           Program Setting
//         </h2>
//         <div className="p-6 bg-white rounded shadow">
//           <form onSubmit={handleSubmit}>
//             <div className="mb-4">
//               <label className="block text-sm font-medium mb-1">Program Name</label>
//               <input
//                 type="text"
//                 value={formValues.programName}
//                 onChange={(e) => setFormValues({ ...formValues, programName: e.target.value })}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               />
//             </div>
//             <div className="mb-4">
//               <label className="block text-sm font-medium mb-1">Event Date</label>
//               <input
//                 type="date"
//                 value={formValues.startDate}
//                 onChange={(e) => setFormValues({ ...formValues, startDate: e.target.value })}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               />
//               <input
//                 type="date"
//                 value={formValues.endDate}
//                 onChange={(e) => setFormValues({ ...formValues, endDate: e.target.value })}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               />
//               {formValues.startDate && formValues.endDate && (
//                 <p>{calculateDays(formValues.startDate, formValues.endDate)} days</p>
//               )}
//             </div>
//             <div className="mb-4">
//               <label className="block text-sm font-medium mb-1">Amount</label>
//               <input
//                 type="number"
//                 value={formValues.amount}
//                 onChange={handleAmountChange}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               />
//               <select
//                 value={formValues.currency}
//                 onChange={(e) => setFormValues({ ...formValues, currency: e.target.value })}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               >
//                 <option value="USD">USD</option>
//                 <option value="MYR">MYR</option>
//                 <option value="FREE">Free</option>
//               </select>
//               {/* Currency conversion logic can be added here */}
//             </div>
//             <div className="mb-4">
//               <label className="block text-sm font-medium mb-1">Points</label>
//               <input
//                 type="number"
//                 value={formValues.points}
//                 onChange={(e) => setFormValues({ ...formValues, points: e.target.value })}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               />
//             </div>
//             <div className="mb-4">
//               <label className="block text-sm font-medium mb-1">Description</label>
//               <textarea
//                 value={formValues.description}
//                 onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               />
//             </div>
//             <div className="mb-4">
//               <label className="block text-sm font-medium mb-1">Redeem Number of Times</label>
//               <input
//                 type="number"
//                 value={formValues.redeemNumber}
//                 onChange={(e) => setFormValues({ ...formValues, redeemNumber: e.target.value })}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               />
//             </div>
//             <div>
//               <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
//                 Save
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProgramSetting;



// // update code --> 19 june 2024
// // src/components/admin/admin04_ProgramSetting.tsx

// import React, { useState } from 'react';
// import { useTheme } from '../../context/ThemeContext';

// interface Program {
//   id: number;
//   name: string;
//   timePeriod: string;
//   amount: number;
//   startDate: string | null;
//   status: 'running' | 'stopped';
// }

// const presetPrograms: Program[] = [
//   { id: 1, name: 'Basic Plan', timePeriod: 'monthly', amount: 10, startDate: null, status: 'running' },
//   { id: 2, name: 'Standard Plan', timePeriod: 'quarterly', amount: 25, startDate: null, status: 'stopped' },
//   { id: 3, name: 'Premium Plan', timePeriod: 'yearly', amount: 100, startDate: null, status: 'running' },
// ];

// const ProgramSetting: React.FC = () => {
//   const { theme } = useTheme(); // Access theme from ThemeContext
//   const [formValues, setFormValues] = useState({
//     programName: '',
//     timePeriod: '',
//     amount: '',
//     startDate: '',
//   });

//   const handleSelectProgram = (program: Program) => {
//     setFormValues({
//       programName: program.name,
//       timePeriod: program.timePeriod,
//       amount: program.amount.toString(),
//       startDate: program.startDate || '',
//     });
//   };

//   const handleApply = (programId: number) => {
//     console.log(`Apply program with id: ${programId}`);
//     // Implement the logic to apply the program here
//   };

//   const handleCancel = (programId: number) => {
//     console.log(`Cancel program with id: ${programId}`);
//     // Implement the logic to cancel the program here
//   };

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     console.log('Received values:', formValues);
//   };

//   return (
//     <div className="flex justify-center	p-10 border-2">
//       {/* <h1 className="text-3xl font-bold"
//         style={{color: theme.primaryColor, fontFamily: theme.fontFamily }}> 
//           Event Ticket/Programs Setting 
//       </h1> */}

//       {/* Program List */}
//       <div className="w-[800px] mr-4 py-10">
//         <h2 className="text-2xl font-bold mb-4"
//         style={{ fontFamily:theme.fontFamily, color:theme.primaryColor }}>
//           Program List
//         </h2>
//         <div className="p-4 bg-white rounded border-2 border-gray-300 shadow-ml" 
//           style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}> 
//           <ul>
//             {presetPrograms.map((program) => (
//               <li key={program.id} className="mb-4 p-4 bg-gray-100 rounded cursor-pointer" onClick={() => handleSelectProgram(program)}>
//                 <div className="flex justify-between items-center">
//                   <div>
//                     <h3 className="font-semibold" 
//                       style={{ color:theme.primaryColor, fontFamily:theme.fontFamily }}>
//                         {program.name}
//                     </h3>
//                     <p>Period: {program.timePeriod}, Amount: ${program.amount}</p>
//                   </div>
//                   <span className={`px-2 py-1 rounded ${program.status === 'running' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
//                     {program.status === 'running' ? 'Running' : 'Stopped'}
//                   </span>
//                 </div>

//                 {/* Program List Button */}
//                 <div className="flex mt-4">
//                   <button className="mr-2 px-6 py-2 rounded font-medium" 
//                     onClick={() => handleApply(program.id)}
//                     style={{ backgroundColor: theme.primaryColor, color:theme.text2Color, fontFamily:theme.fontFamily }}>
//                       Release
//                   </button>
//                   <button className="mr-2 px-4 py-2 bg-rose-500 rounded font-medium" 
//                     onClick={() => handleCancel(program.id)}
//                     style={{ color:theme.text2Color, fontFamily:theme.fontFamily }}>
//                       Stopped
//                   </button>
//                 </div>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
//       {/* Program Setting */}
//       <div className="w-[1000px] ml-4 py-10">
//         <h2 className="text-2xl font-semibold mb-4"
//           style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//             Program Setting
//         </h2>
//         <div className="p-6 bg-white rounded shadow" >
//           <form onSubmit={handleSubmit}>
//             <div className="mb-4">
//               <label className="block text-sm font-medium mb-1">Program Name</label>
//               <input
//                 type="text"
//                 value={formValues.programName}
//                 onChange={(e) => setFormValues({ ...formValues, programName: e.target.value })}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               />
//             </div>
//             <div className="mb-4">
//               <label className="block text-sm font-medium mb-1">Time Period</label>
//               <select
//                 value={formValues.timePeriod}
//                 onChange={(e) => setFormValues({ ...formValues, timePeriod: e.target.value })}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               >
//                 <option value="" disabled>Select a time period</option>
//                 <option value="monthly">Monthly</option>
//                 <option value="quarterly">Quarterly</option>
//                 <option value="yearly">Yearly</option>
//               </select>
//             </div>
//             <div className="mb-4">
//               <label className="block text-sm font-medium mb-1">Amount</label>
//               <input
//                 type="number"
//                 value={formValues.amount}
//                 onChange={(e) => setFormValues({ ...formValues, amount: e.target.value })}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               />
//             </div>
//             <div className="mb-4">
//               <label className="block text-sm font-medium mb-1">Start Date</label>
//               <input
//                 type="date"
//                 value={formValues.startDate}
//                 onChange={(e) => setFormValues({ ...formValues, startDate: e.target.value })}
//                 className="w-full p-2 border border-gray-300 rounded"
//                 required
//               />
//             </div>

//             {/* Program Setting Button */}
//             <div>
//               <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">Save</button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProgramSetting;
