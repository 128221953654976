// src/components/modals/ErrorModal.tsx

import React, { useEffect, useState } from 'react';
import { FaExclamationCircle, FaTimes } from 'react-icons/fa';
import { useTheme } from '../../context/ThemeContext';

// Define the props for the ErrorModal component
interface ErrorModalProps {
  visible: boolean; // Determines if the modal is visible
  onClose: () => void; // Function to close the modal
}

const ErrorModal: React.FC<ErrorModalProps> = ({ visible, onClose }) => {
  const { theme } = useTheme(); // Get theme from context
  const [showModal, setShowModal] = useState(false);

  // Effect to handle the animation
  useEffect(() => {
    if (visible) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [visible]);

  // If the modal is not visible, return null
  if (!visible) return null;

  return (
    // Modal container with transition effect
    <div 
      className={`fixed inset-x-0 top-0 transition-transform transform ${showModal ? 'translate-y-0' : '-translate-y-full'} z-50 px-4`}
      style={{ fontFamily: theme.fontFamily }}
    >
      {/* Alert box with light red background and red border */}
      <div className="relative flex flex-col p-4 mt-4 mb-4 text-sm text-red-800 rounded-lg bg-red-100 border border-red-500 max-w-lg mx-auto md:max-w-2xl" role="alert">
        {/* Error icon and title */}
        <div className="flex items-center">
          <FaExclamationCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" />
          <span className="font-medium" style={{ color: theme.textColor }}>
            Sign In Error
          </span>
        </div>
        {/* Error message content */}
        <div className="mt-2 text-red-700 md:ml-4">
          The email or password you entered is incorrect. Please try again.
        </div>
        {/* Close icon button */}
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-red-800 hover:text-red-600 focus:outline-none"
        >
          <FaTimes className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default ErrorModal;


// update code --> 30 May 2024 
// // src/components/modals/ErrorModal.tsx

// import React from 'react';
// import { FaExclamationCircle } from 'react-icons/fa';
// import { useTheme } from '../../context/ThemeContext'; // Import theme context

// interface ErrorModalProps {
//   visible: boolean;
//   onClose: () => void;
// }

// const ErrorModal: React.FC<ErrorModalProps> = ({ visible, onClose }) => {
//   const { theme } = useTheme(); // Get theme from context

//   if (!visible) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       {/* Modal container */}
//       <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center space-y-4 max-w-md mx-4">
//         <FaExclamationCircle className="text-red-500 text-4xl" /> {/* Error icon */}
//         <span className="text-center" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//           <strong>Sign In Failed!</strong><br />
//           The email or password you entered is incorrect. Please check your credentials and try again. If you have forgotten your password, you can use the 'Forgot Password' link to reset it.
//         </span>
//         <button 
//           onClick={onClose} 
//           className="mt-4 py-2 px-10 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
//           style={{ fontFamily: theme.fontFamily }}
//         >
//           OK
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ErrorModal;