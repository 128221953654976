// src/hooks/MiniApp/useEventStats.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

interface EventStats {
  attend: number;
  notCheckIn: number;
  totalRegistered: number;
}

export interface TableStats {
  totalRegister: number;
  totalApprove: number;
  totalPending: number;
  totalReject: number;
  totalNotCheckIn: number;
}

export interface TableData {
  ticketId: string;
  eventTitle: string;
  username: string;
  email: string;
  appointmentDate: string;
  time: string;
  updatedAt: string;
  paymentMethod: string;
  eventStatus: string;
  status: string;
  receiptPath: string;
}

const useEventStats = () => {
  const { apiUrl } = useEnv();
  const [stats, setStats] = useState<EventStats>({ attend: 0, notCheckIn: 0, totalRegistered: 0 });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableError, setTableError] = useState<string | null>(null);
  const [tableStats, setTableStats] = useState<TableStats>({ totalRegister: 0, totalApprove: 0, totalPending: 0, totalReject:0, totalNotCheckIn: 0 });
  const [eventTables, setEventTables] = useState<string[]>([]);

  const fetchStats = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/event-stats`);
      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error('Failed to fetch event stats:', error);
      setError('Failed to fetch event stats');
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

  const fetchTableData = useCallback(async (selectedTable: string) => {
    if (!selectedTable) {
      setTableData([]);
      return;
    }
    setTableLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/event-table-data?table=${selectedTable}`);
      const { data, stats } = await response.json();
      setTableData(data);
      setTableStats(stats);
    } catch (error) {
      console.error('Failed to fetch table data:', error);
      setTableError('Failed to fetch table data');
    } finally {
      setTableLoading(false);
    }
  }, [apiUrl]); 

  const fetchEventTables = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/api/event-tables`);
      const data = await response.json();
      setEventTables(data);
    } catch (error) {
      console.error('Failed to fetch event tables:', error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);
  

  return { 
    stats, loading, error, 
    refetch: fetchStats, 
    tableData, 
    fetchTableData, 
    tableLoading, 
    tableError, 
    eventTables, 
    fetchEventTables,
    tableStats,
  };
};

export default useEventStats;


// // udpate code --> 17 Aug 2024 
// // src/hooks/MiniApp/useEventStats.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface EventStats {
//   attend: number;
//   notCheckIn: number;
//   totalRegistered: number;
// }

// interface TableData {
//   ticketId: string;
//   eventTitle: string;
//   username: string;
//   email: string;
//   appointmentDate: string;
//   time: string;
//   paymentMethod: string;
//   eventStatus: string;
//   status: string;
// }

// const useEventStats = () => {
//   const { apiUrl } = useEnv();
//   const [stats, setStats] = useState<EventStats>({ attend: 0, notCheckIn: 0, totalRegistered: 0 });
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);
//   const [tableData, setTableData] = useState<TableData[]>([]);
//   const [tableLoading, setTableLoading] = useState(false);
//   const [tableError, setTableError] = useState<string | null>(null);
//   const [eventTables, setEventTables] = useState<string[]>([]);

//   const fetchStats = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/event-stats`);
//       const data = await response.json();
//       setStats(data);
//     } catch (error) {
//       console.error('Failed to fetch event stats:', error);
//       setError('Failed to fetch event stats');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const fetchTableData = useCallback(async (selectedTable: string) => {
//     if (!selectedTable) {
//       setTableData([]);
//       return;
//     }
//     setTableLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/event-table-data?table=${selectedTable}`);
//       const data = await response.json();
//       setTableData(data);
//     } catch (error) {
//       console.error('Failed to fetch table data:', error);
//       setTableError('Failed to fetch table data');
//     } finally {
//       setTableLoading(false);
//     }
//   }, [apiUrl]);  

//   const fetchEventTables = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/api/event-tables`);
//       const data = await response.json();
//       setEventTables(data);
//     } catch (error) {
//       console.error('Failed to fetch event tables:', error);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchStats();
//   }, [fetchStats]);

//   return { stats, loading, error, refetch: fetchStats, tableData, fetchTableData, tableLoading, tableError, eventTables, fetchEventTables };
// };

// export default useEventStats;
