
// src/hooks/MiniApp/useEventRegistrationSubmit.tsx

import { useState } from 'react';
import { useEnv } from '../../context/EnvContext';

interface RegistrationData {
    ticketId: string;
    eventTitle: string;
    location: string;
    email: string;
    username: string;
    appointmentDate: string;
    time: string;
    paymentMethod: string;
    amount: number;
    points: number;
    accountId: string;
    accountPassword: string;
    accountServer: string;
    receipts: File[];
    termsAccepted: boolean;
    notes: string;
    image: string | null;
  }
  
interface RegistrationResult {
    success: boolean;
    message: string;
}

const useEventRegistrationSubmit = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { apiUrl } = useEnv();

  const submitRegistration = async (data: RegistrationData): Promise<RegistrationResult> => {
    setLoading(true);
    setError(null);
  
    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (key === 'receipts' && Array.isArray(value)) {
          value.forEach((file, index) => {
            formData.append(`receipts`, file); // Use 'receipts' for all files
          });
        } else {
          formData.append(key, value);
        }
      });
  
      const response = await fetch(`${apiUrl}/api/submit-registration`, {
        method: 'POST',
        body: formData,
      });
  
      const result = await response.json();
  
      if (!response.ok) {
        throw new Error(result.message || 'Failed to submit registration');
      }
  
      return { success: true, message: 'Registration submitted successfully' };
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      return { success: false, message: err instanceof Error ? err.message : 'An unknown error occurred' };
    } finally {
      setLoading(false);
    }
  };  

  return { submitRegistration, loading, error };
};

export default useEventRegistrationSubmit;

// // update code --> 12 july 2024
// // src/hooks/MiniApp/useEventRegistrationSubmit.tsx

// import { useState } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface RegistrationData {
//     ticketId: string;
//     eventTitle: string;
//     location: string;
//     email: string;
//     username: string;
//     appointmentDate: string;
//     time: string;
//     paymentMethod: string;
//     amount: number;
//     points: number;
//     accountId: string;
//     accountPassword: string;
//     accountServer: string;
//     receipts: File[];
//     termsAccepted: boolean;
//     notes: string;
//     image: string | null;
//   }
  
// interface RegistrationResult {
//     success: boolean;
//     message: string;
// }

// const useEventRegistrationSubmit = () => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();

//   const submitRegistration = async (data: RegistrationData): Promise<RegistrationResult> => {
//     setLoading(true);
//     setError(null);
  
//     try {
//       const formData = new FormData();
//       Object.entries(data).forEach(([key, value]) => {
//         if (key === 'receipts' && Array.isArray(value)) {
//           value.forEach((file, index) => {
//             formData.append(`receipts`, file); // Use 'receipts' for all files
//           });
//         } else {
//           formData.append(key, value);
//         }
//       });
  
//       const response = await fetch(`${apiUrl}/api/submit-registration`, {
//         method: 'POST',
//         body: formData,
//       });
  
//       const result = await response.json();
  
//       if (!response.ok) {
//         throw new Error(result.message || 'Failed to submit registration');
//       }
  
//       return { success: true, message: 'Registration submitted successfully' };
//     } catch (err) {
//       setError(err instanceof Error ? err.message : 'An unknown error occurred');
//       return { success: false, message: err instanceof Error ? err.message : 'An unknown error occurred' };
//     } finally {
//       setLoading(false);
//     }
//   };  

//   return { submitRegistration, loading, error };
// };

// export default useEventRegistrationSubmit;


// // update code --> 27 june 2024 
// // src/hooks/MiniApp/useEventRegistrationSubmit.tsx

// import { useState } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface RegistrationData {
//     ticketId: string; // Add this line
//     eventTitle: string;
//     email: string;
//     username: string;
//     appointmentDate: string;
//     paymentMethod: string;
//     amount: number;
//     points: number;
//     receipt: File;
//     termsAccepted: boolean; // Add this line
//     notes: string;
//   }
  
// interface RegistrationResult {
//     success: boolean;
//     message: string;
// }

// const useEventRegistrationSubmit = () => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();

//   const submitRegistration = async (data: RegistrationData): Promise<RegistrationResult> => {
//     setLoading(true);
//     setError(null);

//     try {
//       const formData = new FormData();
//       Object.entries(data).forEach(([key, value]) => {
//         formData.append(key, value);
//       });

//       const response = await fetch(`${apiUrl}/api/submit-registration`, {
//         method: 'POST',
//         body: formData,
//       });

//       const result = await response.json();

//       if (!response.ok) {
//         throw new Error(result.message || 'Failed to submit registration');
//       }

//       return { success: true, message: 'Registration submitted successfully' };
//     } catch (err) {
//       setError(err instanceof Error ? err.message : 'An unknown error occurred');
//       return { success: false, message: err instanceof Error ? err.message : 'An unknown error occurred' };
//     } finally {
//       setLoading(false);
//     }
//   };

//   return { submitRegistration, loading, error };
// };

// export default useEventRegistrationSubmit;