// src/components/AccountExpireAlert.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import { RiAlarmWarningFill } from "react-icons/ri";

interface AccountExpireAlertProps {
  visible: boolean;
  onClose: () => void;
}

const AccountExpireAlert: React.FC<AccountExpireAlertProps> = ({ visible, onClose }) => {
  const navigate = useNavigate();
  const { theme } = useTheme();

  const handleConfirm = () => {
    onClose();
    navigate('/login');
  };

  if (!visible) return null;

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg max-w-sm w-full">
        <div className="flex items-center justify-center mb-4">
          <RiAlarmWarningFill className="text-2xl mr-2" style={styles.icon} />
          <h2 className="text-xl font-bold" style={styles.title}>Session Expired</h2>
        </div>
        <p className="mb-4" style={styles.text}>
          Your session has expired. Please log in again to continue.
        </p>
        <button
          onClick={handleConfirm}
          className="w-full py-2 rounded hover:bg-opacity-90"
          style={styles.button}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default AccountExpireAlert;