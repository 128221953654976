// src/AppVersion.tsx

import React from 'react';
import packageJson from '../package.json';

const Version: React.FC = () => {
  return (
    <div>
      <p>Ver: {packageJson.version}</p>
    </div>
  );
};

export default Version;
