// src/components/admin/EventSetting/ProgramDetailModal.tsx

import React, { useState } from 'react';
import { useTheme } from '../../../context/ThemeContext';
import {
  FaEdit, FaSave, FaMapMarkerAlt, FaCalendarAlt,
  FaDollarSign, FaListOl, FaInfoCircle, FaUser,
  FaMale, FaTag, FaChartPie
} from 'react-icons/fa';
import { RiCloseCircleFill } from "react-icons/ri";
import { PiBankFill } from "react-icons/pi";
import { Program, convertToShortForm, getTagColor, formatAmount } from '../../../hooks/AdminDashboard/useProgram';

interface ProgramDetailModalProps {
  program: Program;
  fieldValues: Partial<Program>;
  isEditing: boolean;
  onClose: () => void;
  onSave: (updatedFieldValues: Partial<Program>) => void;
  onFieldChange: (field: keyof Program, value: any) => void;
  apiUrl: string;
}

const ProgramDetailModal: React.FC<ProgramDetailModalProps> = ({
  program,
  fieldValues,
  isEditing,
  onClose,
  onSave,
  onFieldChange,
  apiUrl,
}) => {
  const [localIsEditing, setIsEditing] = useState(isEditing);
  const [currency, setCurrency] = useState<'USD' | 'MYR' | 'FREE'>(program.currency || 'USD');
  const { theme } = useTheme();

  const formatDate = (date: string | null | undefined) => {
    if (!date) return '';
    const d = new Date(date);
    return d.toISOString().split('T')[0]; // format as yyyy-mm-dd
  };

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCurrency = e.target.value as 'USD' | 'MYR' | 'FREE';
    setCurrency(newCurrency);
    onFieldChange('currency', newCurrency);
    if (newCurrency === 'FREE') {
      onFieldChange('amount', 0);
      onFieldChange('points', 0);
    }
  };

  const handleSaveClick = () => {
    const updatedFieldValues = {
      ...fieldValues,
      memberType: fieldValues.memberType ?? program.memberType ?? [],
    };
  
    // Ensure memberType is an array, not a string
    if (typeof updatedFieldValues.memberType === 'string') {
      try {
        updatedFieldValues.memberType = JSON.parse(updatedFieldValues.memberType);
      } catch (e) {
        console.error('Error parsing memberType:', e);
        updatedFieldValues.memberType = program.memberType || [];
      }
    }
  
    onSave(updatedFieldValues);
    setIsEditing(false);
  };   

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const getValue = (field: keyof Program) => {
    if (field in fieldValues) {
      return fieldValues[field] ?? '';
    }
    if (field.includes('Date')) {
      return formatDate(program[field] as string);
    }
    return program[field] ?? '';
  };

  const handleMemberTypeChange = (memberType: string) => {
    const currentMemberTypes = fieldValues.memberType || program.memberType || [];
    const updatedMemberTypes = Array.isArray(currentMemberTypes)
      ? currentMemberTypes.includes(memberType)
        ? currentMemberTypes.filter((type: string) => type !== memberType)
        : [...currentMemberTypes, memberType]
      : [memberType];
    onFieldChange('memberType', updatedMemberTypes);
  };  

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-12 rounded shadow-xl w-[650px] h-[80vh] relative">
        {program.image && <img src={`${apiUrl}/uploads/forEventTicket/${program.image}`} 
          alt={program.name} 
          className="w-full h-48 object-cover rounded mb-4"/>}
        <div className="overflow-y-auto h-[640px] p-4">
          <table className="w-full">
            <tbody>
              {[
                { title: 'Title', field: 'name', type: 'text', icon: <FaEdit /> },
                { title: 'Start Date', field: 'startDate', type: 'date', icon: <FaCalendarAlt /> },
                { title: 'End Date', field: 'endDate', type: 'date', icon: <FaCalendarAlt /> },
                { title: 'Time', field: 'time', type: 'time', icon: <FaCalendarAlt /> },
                { title: 'Location', field: 'location', type: 'text', icon: <FaMapMarkerAlt /> },
                { title: 'Online Link', field: 'onlineConferenceText', type: 'textarea', icon: <FaInfoCircle /> },
                { title: 'Amount', field: 'amount', type: 'number', icon: <FaDollarSign /> },
                { title: 'Currency', field: 'currency', type: 'select', icon: <FaDollarSign /> },
                { title: 'Lot Size', field:'lotSize', type: 'number', icon: <FaChartPie /> },
                { title: 'Deposit Amount', field: 'deposit', type: 'number', icon: <FaDollarSign /> },
                { title: 'Broker', field: 'broker', type: 'text', icon: <PiBankFill /> },
                { title: 'Points', field: 'points', type: 'number', icon: <FaListOl /> },
                { title: 'Slot', field: 'slot', type: 'number', icon: <FaUser /> },
                { title: 'Member Type', field: 'memberType', type: 'select', icon: <FaMale /> },
                { title: 'Description', field: 'description', type: 'textarea', icon: <FaInfoCircle /> },
                { title: 'Redeem Counts', field: 'redeemNumber', type: 'number', icon: <FaListOl /> }
              ].map(({ title, field, type, icon }) => (
                <tr key={field as string} className="border-b py-2">
                  <td className="font-semibold pr-4 w-[200px] flex items-center py-2">
                    {icon} <span className="ml-2">{title}:</span>
                  </td>
                  <td>
                    {localIsEditing ? (
                      type === 'textarea' ? (
                        <textarea
                          className="w-full p-4"
                          value={fieldValues[field as keyof Program] ?? program[field as keyof Program] ?? ''}
                          onChange={(e) => onFieldChange(field as keyof Program, e.target.value)}
                        />
                      ) : type === 'select' ? (
                        field === 'currency' ? (
                          <select
                            className="w-full p-4"
                            value={currency}
                            onChange={handleCurrencyChange}
                          >
                            <option value="USD">USD</option>
                            <option value="MYR">MYR</option>
                            <option value="FREE">Free</option>
                          </select>
                        ) : (
                          <div className="flex flex-col space-y-2">
                            {[
                              "R One Guest",
                              "R One Member",
                              "R One Dealer",
                              "Trader Hub Member",
                              "R One Elite Trader",
                              "R One Master Trader",
                              "R One Certified Trader"
                            ].map((type) => {
                              const shortForm = convertToShortForm(type);
                              const tagColor = getTagColor(shortForm);
                              return (
                                <div className="flex items-center" key={type}>
                                  <input
                                    type="checkbox"
                                    id={type}
                                    value={type}
                                    checked={(fieldValues.memberType || program.memberType || []).includes(type)}
                                    onChange={() => handleMemberTypeChange(type)}
                                    className="mr-2"
                                    style={{ accentColor: theme.primaryColor }}
                                  />
                                  <FaTag className={`mr-1 ${tagColor}`} />
                                  <label htmlFor={type} className={`px-2 py-1 rounded ${tagColor} bg-opacity-20`}>
                                    {shortForm}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        )
                      ) : (
                        <input
                          type={type}
                          className="w-full p-4"
                          value={getValue(field as keyof Program)}
                          onChange={(e) => 
                            onFieldChange(field as keyof Program, 
                              ['slot', 'amount', 'points', 'redeemNumber', 'deposit'].includes(field) 
                                ? (e.target.value === '' ? '' : Number(e.target.value)) 
                                : e.target.value
                            )
                          }
                        />
                      )
                    ) : field === 'memberType' ? (
                      <div className="flex space-x-2 mt-2">
                        {program.memberType && Array.isArray(program.memberType) && program.memberType.map((type, index) => { // Ensure memberType is always an array
                          const shortForm = convertToShortForm(type);
                          const tagColor = getTagColor(shortForm);
                          return (
                            <span key={index} className={`flex items-center px-2 py-1 rounded ${tagColor} bg-opacity-20`}>
                              <FaTag className={`mr-1 ${tagColor}`} />
                              {shortForm}
                            </span>
                          );
                        })}
                      </div>
                    ) : (
                      field === 'amount'
                        ? `${formatAmount(getValue(field as keyof Program) as string | number)}`
                        : getValue(field as keyof Program)
                    )}
                  </td>
                </tr>
              ))}
              <tr className="border-b py-2">
                <td className="font-semibold pr-4 flex items-center">
                  <FaUser /> <span className="ml-2 my-2">Created By:</span>
                </td>
                <td>{formatDate(program.createdAt)}</td>
              </tr>
              <tr className="border-b py-2">
                <td className="font-semibold pr-4 flex items-center">
                  <FaInfoCircle /> <span className="ml-2 my-2">Status:</span>
                </td>
                <td className={`py-2 px-2 rounded-full text-white text-center  ${program.status === 'running' ? 'bg-green-500' : program.status === 'stopped' ? 'bg-red-500' : 'bg-gray-500'}`}>
                  {program.status.charAt(0).toUpperCase() + program.status.slice(1)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
          <div className="flex justify-between mt-4 bg-gray-100 p-4 rounded">
            {localIsEditing ? (
              <>
                <button className="px-4 py-2 bg-blue-500 rounded flex items-center space-x-1"
                  onClick={handleSaveClick}
                  style={{ backgroundColor: theme.primaryColor, fontFamily: theme.fontFamily, color: theme.text2Color }}>
                  <FaSave />
                  <span>Save</span>
                </button>
                <button className="px-4 py-2 bg-gray-500 rounded flex items-center space-x-1"
                  onClick={handleCancelClick}
                  style={{ fontFamily: theme.fontFamily, color: theme.text2Color }}>
                  Cancel
                </button>
              </>
            ) : (
              <button className="px-4 py-2 bg-blue-500 rounded flex items-center space-x-1"
                onClick={() => setIsEditing(true)}
                style={{ backgroundColor: theme.primaryColor, fontFamily: theme.fontFamily, color: theme.text2Color }}>
                <FaEdit />
                <span>Modify</span>
              </button>
            )}
            <button className="px-4 py-2 bg-red-500 rounded flex items-center space-x-1"
              onClick={onClose}
              style={{ fontFamily: theme.fontFamily, color: theme.text2Color }}>
              <RiCloseCircleFill />
              Close
            </button>
          </div>
        </div>
    </div>
  );
};

export default ProgramDetailModal;


// // udpoate code --> 01 july 2024
// // src/components/admin/EventSetting/ProgramDetailModal.tsx

// import React, { useState } from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import {
//   FaEdit, FaSave, FaMapMarkerAlt, FaCalendarAlt,
//   FaDollarSign, FaListOl, FaInfoCircle, FaUser,
//   FaMale, FaTag 
// } from 'react-icons/fa';
// import { RiCloseCircleFill } from "react-icons/ri";
// import { Program, convertToShortForm, getTagColor } from '../../../hooks/AdminDashboard/useProgram';

// interface ProgramDetailModalProps {
//   program: Program;
//   fieldValues: Partial<Program>;
//   isEditing: boolean;
//   onClose: () => void;
//   onSave: () => void;
//   onFieldChange: (field: keyof Program, value: any) => void;
//   apiUrl: string;
// }

// const ProgramDetailModal: React.FC<ProgramDetailModalProps> = ({
//   program,
//   fieldValues,
//   isEditing,
//   onClose,
//   onSave,
//   onFieldChange,
//   apiUrl,
// }) => {
//   const [localIsEditing, setIsEditing] = useState(isEditing);
//   const [currency, setCurrency] = useState<'USD' | 'MYR' | 'FREE'>(program.currency || 'USD');
//   const { theme } = useTheme();

//   const formatDate = (date: string | null | undefined) => {
//     if (!date) return '';
//     const d = new Date(date);
//     return d.toISOString().split('T')[0]; // format as yyyy-mm-dd
//   };

//   const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const newCurrency = e.target.value as 'USD' | 'MYR' | 'FREE';
//     setCurrency(newCurrency);
//     onFieldChange('currency', newCurrency);
//     if (newCurrency === 'FREE') {
//       onFieldChange('amount', 0);
//       onFieldChange('points', 0);
//     }
//   };

//   const handleSaveClick = () => {
//     onFieldChange('memberType', JSON.stringify(fieldValues.memberType || program.memberType || []));
//     onSave();
//     setIsEditing(false);
//   };  

//   const handleCancelClick = () => {
//     setIsEditing(false);
//   };

//   const getValue = (field: keyof Program) => {
//     if (field in fieldValues) {
//       return fieldValues[field] ?? '';
//     }
//     if (field.includes('Date')) {
//       return formatDate(program[field] as string);
//     }
//     return program[field] ?? '';
//   };

//   const handleMemberTypeChange = (memberType: string) => {
//     const currentMemberTypes = fieldValues.memberType || program.memberType || [];
//     const updatedMemberTypes = Array.isArray(currentMemberTypes)
//       ? currentMemberTypes.includes(memberType)
//         ? currentMemberTypes.filter((type: string) => type !== memberType)
//         : [...currentMemberTypes, memberType]
//       : [memberType];
//     onFieldChange('memberType', updatedMemberTypes);
//   };

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="bg-white p-10 rounded shadow-xl w-[650px] max-w-full h-[80vh] relative overflow-y-auto">
//         {program.image && <img src={`${apiUrl}/uploads/forEventTicket/${program.image}`} alt={program.name} className="w-full h-48 object-cover rounded mb-4" />}
//         <table className="w-auto mr-4 p-10">
//           <tbody>
//             {[
//               { title: 'Title', field: 'name', type: 'text', icon: <FaEdit /> },
//               { title: 'Start Date', field: 'startDate', type: 'date', icon: <FaCalendarAlt /> },
//               { title: 'End Date', field: 'endDate', type: 'date', icon: <FaCalendarAlt /> },
//               { title: 'Time', field: 'time', type: 'time', icon: <FaCalendarAlt /> },
//               { title: 'Location', field: 'location', type: 'text', icon: <FaMapMarkerAlt /> },
//               { title: 'Online Conference Text', field: 'onlineConferenceText', type: 'textarea', icon: <FaInfoCircle /> },
//               { title: 'Amount', field: 'amount', type: 'number', icon: <FaDollarSign /> },
//               { title: 'Currency', field: 'currency', type: 'select', icon: <FaDollarSign /> },
//               { title: 'Points', field: 'points', type: 'number', icon: <FaListOl /> },
//               { title: 'Slot', field: 'slot', type: 'number', icon: <FaUser /> },
//               { title: 'Member Type', field: 'memberType', type: 'select', icon: <FaMale /> },
//               { title: 'Description', field: 'description', type: 'textarea', icon: <FaInfoCircle /> },
//               { title: 'Redeem Number of Times', field: 'redeemNumber', type: 'number', icon: <FaListOl /> }
//             ].map(({ title, field, type, icon }) => (
//               <tr key={field as string} className="border-b py-2">
//                 <td className="font-semibold pr-4 flex items-center">
//                   {icon} <span className="ml-2">{title}:</span>
//                 </td>
//                 <td>
//                   {localIsEditing ? (
//                     type === 'textarea' ? (
//                       <textarea
//                         className="w-full p-4"
//                         value={fieldValues[field as keyof Program] ?? program[field as keyof Program] ?? ''}
//                         onChange={(e) => onFieldChange(field as keyof Program, e.target.value)}
//                       />
//                     ) : type === 'select' ? (
//                       field === 'currency' ? (
//                         <select
//                           className="w-full p-4"
//                           value={currency}
//                           onChange={handleCurrencyChange}
//                         >
//                           <option value="USD">USD</option>
//                           <option value="MYR">MYR</option>
//                           <option value="FREE">Free</option>
//                         </select>
//                       ) : (
//                         <div className="flex flex-col space-y-2">
//                           {[
//                             "Trader Hub Member",
//                             "R One Member",
//                             "R One Dealer",
//                             "R One Elite Trader",
//                             "R One Master Trader",
//                             "R One Certified Trader"
//                           ].map((type) => {
//                             const shortForm = convertToShortForm(type);
//                             const tagColor = getTagColor(shortForm);
//                             return (
//                               <div className="flex items-center" key={type}>
//                                 <input
//                                   type="checkbox"
//                                   id={type}
//                                   value={type}
//                                   checked={(fieldValues.memberType || program.memberType || []).includes(type)}
//                                   onChange={() => handleMemberTypeChange(type)}
//                                   className="mr-2"
//                                   style={{ accentColor: theme.primaryColor }}
//                                 />
//                                 <FaTag className={`mr-1 ${tagColor}`} />
//                                 <label htmlFor={type} className={`px-2 py-1 rounded ${tagColor} bg-opacity-20`}>
//                                   {shortForm}
//                                 </label>
//                               </div>
//                             );
//                           })}
//                         </div>
//                       )
//                     ) : (
//                       <input
//                         type={type}
//                         className="w-full p-4"
//                         value={getValue(field as keyof Program)}
//                         onChange={(e) => 
//                           onFieldChange(field as keyof Program, 
//                             ['slot', 'amount', 'points', 'redeemNumber'].includes(field) 
//                               ? (e.target.value === '' ? '' : Number(e.target.value)) 
//                               : e.target.value
//                           )
//                         }
//                       />
//                     )
//                   ) : field === 'memberType' ? (
//                     <div className="flex space-x-2 mt-2">
//                       {program.memberType && Array.isArray(program.memberType) && program.memberType.map((type, index) => { // Ensure memberType is always an array
//                         const shortForm = convertToShortForm(type);
//                         const tagColor = getTagColor(shortForm);
//                         return (
//                           <span key={index} className={`flex items-center px-2 py-1 rounded ${tagColor} bg-opacity-20`}>
//                             <FaTag className={`mr-1 ${tagColor}`} />
//                             {shortForm}
//                           </span>
//                         );
//                       })}
//                     </div>
//                   ) : (
//                     getValue(field as keyof Program)
//                   )}
//                 </td>
//               </tr>
//             ))}
//             <tr className="border-b py-2">
//               <td className="font-semibold pr-4 flex items-center">
//                 <FaUser /> <span className="ml-2 my-2">Created By:</span>
//               </td>
//               <td>{formatDate(program.createdAt)}</td>
//             </tr>
//             <tr className="border-b py-2">
//               <td className="font-semibold pr-4 flex items-center">
//                 <FaInfoCircle /> <span className="ml-2 my-2">Status:</span>
//               </td>
//               <td className={`py-1 px-2 w-1/2 rounded-full text-white text-center ${program.status === 'running' ? 'bg-green-500' : program.status === 'stopped' ? 'bg-red-500' : 'bg-gray-500'}`}>
//                 {program.status.charAt(0).toUpperCase() + program.status.slice(1)}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//         <div className="flex justify-between mt-4 bg-gray-100 p-4 rounded">
//           {localIsEditing ? (
//             <>
//               <button className="px-4 py-2 bg-blue-500 rounded flex items-center space-x-1"
//                 onClick={handleSaveClick}
//                 style={{ backgroundColor: theme.primaryColor, fontFamily: theme.fontFamily, color: theme.text2Color }}>
//                 <FaSave />
//                 <span>Save</span>
//               </button>
//               <button className="px-4 py-2 bg-gray-500 rounded flex items-center space-x-1"
//                 onClick={handleCancelClick}
//                 style={{ fontFamily: theme.fontFamily, color: theme.text2Color }}>
//                 Cancel
//               </button>
//             </>
//           ) : (
//             <button className="px-4 py-2 bg-blue-500 rounded flex items-center space-x-1"
//               onClick={() => setIsEditing(true)}
//               style={{ backgroundColor: theme.primaryColor, fontFamily: theme.fontFamily, color: theme.text2Color }}>
//               <FaEdit />
//               <span>Modify</span>
//             </button>
//           )}
//           <button className="px-4 py-2 bg-red-500 rounded flex items-center space-x-1"
//             onClick={onClose}
//             style={{ fontFamily: theme.fontFamily, color: theme.text2Color }}>
//             <RiCloseCircleFill />
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProgramDetailModal;


// // update code --> 26 june 2024
// src/components/admin/EventSetting/ProgramDetailModal.tsx
// import React, { useState } from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import { 
//   FaEdit, FaSave, FaMapMarkerAlt, FaCalendarAlt, 
//   FaDollarSign, FaListOl, FaInfoCircle, FaUser,
//   FaMale
// } from 'react-icons/fa';
// import { RiCloseCircleFill } from "react-icons/ri";
// import { Program } from '../../../hooks/AdminDashboard/useProgram';

// interface ProgramDetailModalProps {
//   program: Program;
//   fieldValues: Partial<Program>;
//   isEditing: boolean;
//   onClose: () => void;
//   onSave: () => void;
//   onFieldChange: (field: keyof Program, value: any) => void;
//   apiUrl: string;
// }

// const ProgramDetailModal: React.FC<ProgramDetailModalProps> = ({
//   program,
//   fieldValues,
//   isEditing,
//   onClose,
//   onSave,
//   onFieldChange,
//   apiUrl,
// }) => {
//   const [localIsEditing, setIsEditing] = useState(isEditing);
//   const [currency, setCurrency] = useState<'USD' | 'MYR' | 'FREE'>(program.currency || 'USD');
//   const { theme } = useTheme();

//   const formatDate = (date: string | null | undefined) => {
//     if (!date) return '';
//     const d = new Date(date);
//     return d.toISOString().split('T')[0]; // format as yyyy-mm-dd
//   };

//   const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const newCurrency = e.target.value as 'USD' | 'MYR' | 'FREE';
//     setCurrency(newCurrency);
//     onFieldChange('currency', newCurrency);
//     if (newCurrency === 'FREE') {
//       onFieldChange('amount', 0);
//       onFieldChange('points', 0);
//     }
//   };
  
//   const handleSaveClick = () => {
//     onSave();
//     setIsEditing(false);
//   };
  
//   const getValue = (field: keyof Program) => {
//     if (field in fieldValues) {
//       return fieldValues[field];
//     }
//     if (field.includes('Date')) {
//       return formatDate(program[field] as string);
//     }
//     return program[field];
//   };

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="bg-white p-10 rounded shadow-xl w-[600px] relative">
//         {program.image && <img src={`${apiUrl}/uploads/forEventTicket/${program.image}`} alt={program.name} className="w-full h-48 object-cover rounded mb-4" />}
//         <table>
//           <tbody>
//             {[
//               { title: 'Title', field: 'name', type: 'text', icon: <FaEdit /> },
//               { title: 'Start Date', field: 'startDate', type: 'date', icon: <FaCalendarAlt /> },
//               { title: 'End Date', field: 'endDate', type: 'date', icon: <FaCalendarAlt /> },
//               { title: 'Location', field: 'location', type: 'text', icon: <FaMapMarkerAlt /> },
//               { title: 'Amount', field: 'amount', type: 'number', icon: <FaDollarSign /> },
//               { title: 'Currency', field: 'currency', type: 'select', icon: <FaDollarSign /> },
//               { title: 'Points', field: 'points', type: 'number', icon: <FaListOl /> },
//               { title: 'Slot', field: 'slot', type: 'number', icon: <FaUser /> },
//               { title: 'Description', field: 'description', type: 'textarea', icon: <FaInfoCircle /> },
//               { title: 'Redeem Number of Times', field: 'redeemNumber', type: 'number', icon: <FaListOl /> }
//             ].map(({ title, field, type, icon }) => (
//               <tr key={field as string} className="border-b py-2">
//                 <td className="font-semibold pr-4 flex items-center">
//                   {icon} <span className="ml-2">{title}:</span>
//                 </td>
//                 <td>
//                   {localIsEditing ? (
//                     type === 'textarea' ? (
//                       <textarea
//                         className="w-full p-4"
//                         value={fieldValues[field as keyof Program] ?? program[field as keyof Program] ?? ''}
//                         onChange={(e) => onFieldChange(field as keyof Program, e.target.value)}
//                       />
//                     ) : type === 'select' ? (
//                       <select
//                         className="w-full p-4"
//                         value={currency}
//                         onChange={handleCurrencyChange}
//                       >
//                         <option value="USD">USD</option>
//                         <option value="MYR">MYR</option>
//                         <option value="FREE">Free</option>
//                       </select>
//                     ) : (
//                       <input
//                       type={type}
//                       className="w-full p-4"
//                       value={getValue(field as keyof Program) || ''}
//                       onChange={(e) => onFieldChange(field as keyof Program, ['slot', 'amount', 'points', 'redeemNumber'].includes(field) ? Number(e.target.value) : e.target.value)}
//                     />
//                     )
//                   ) : (
//                     getValue(field as keyof Program)
//                   )}
//                 </td>
//               </tr>
//             ))}
//             <tr className="border-b py-2">
//               <td className="font-semibold pr-4 flex items-center">
//                 <FaUser /> <span className="ml-2 my-2">Created By:</span>
//               </td>
//               <td>{formatDate(program.createdAt)}</td>
//             </tr>
//             <tr className="border-b py-2">
//               <td className="font-semibold pr-4 flex items-center">
//                 <FaInfoCircle /> <span className="ml-2 my-2">Status:</span>
//               </td>
//               <td className={`py-1 px-2 w-1/2 rounded-full text-white text-center ${program.status === 'running' ? 'bg-green-500' : program.status === 'stopped' ? 'bg-red-500' : 'bg-gray-500'}`}>
//                 {program.status.charAt(0).toUpperCase() + program.status.slice(1)}
//               </td>
//             </tr>
//             <tr className="border-b py-2">
//               <td className="font-semibold pr-4 flex items-center">
//                 <FaCalendarAlt /> <span className="ml-2 my-2">Time:</span>
//               </td>
//               <td>
//                 {localIsEditing ? (
//                   <input
//                     type="time"
//                     value={fieldValues.time || program.time || ''}
//                     onChange={(e) => onFieldChange('time', e.target.value)}
//                     className="w-full p-4"
//                   />
//                 ) : (
//                   program.time
//                 )}
//               </td>
//             </tr>
//             <tr className="border-b py-2">
//               <td className="font-semibold pr-4 flex items-center">
//                 <FaUser /> <span className="ml-2 my-2">Allow Member Type:</span>
//               </td>
//               <td>
//                 {localIsEditing ? (
//                   <select
//                     value={fieldValues.memberType || program.memberType || ''}
//                     onChange={(e) => onFieldChange('memberType', e.target.value)}
//                     className="w-full p-4"
//                   >
//                     <option value="Trader Hub Member">Trader Hub Member</option>
//                     <option value="R One Member">R One Member</option>
//                     <option value="R One Certified Trader">R One Certified Trader</option>
//                     <option value="R One Elite Trader">R One Elite Trader</option>
//                     <option value="R One Master Trader">R One Master Trader</option>
//                     <option value="R One Dealer">R One Dealer</option>
//                   </select>
//                 ) : (
//                   program.memberType
//                 )}
//               </td>
//             </tr>            
//           </tbody>
//         </table>
//         <div className="flex justify-between mt-4 bg-gray-100 p-4 rounded">
//           <button className="px-4 py-2 bg-blue-500 rounded flex items-center space-x-1"
//             onClick={() => { localIsEditing ? handleSaveClick() : setIsEditing(true); }}
//             style={{ backgroundColor: theme.primaryColor, fontFamily: theme.fontFamily, color: theme.text2Color }}>
//             {localIsEditing ? <FaSave /> : <FaEdit />}
//             <span>{localIsEditing ? 'Save' : 'Modify'}</span>
//           </button>
//           <button className="px-4 py-2 bg-red-500 rounded flex items-center space-x-1"
//             onClick={onClose}
//             style={{ fontFamily: theme.fontFamily, color: theme.text2Color }}>
//             <RiCloseCircleFill />
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProgramDetailModal;




// // udpate code --> 22 june 2024
// // src/components/admin/EventSetting/ProgramDetailModal.tsx

// import React, { useState } from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import { FaEdit, FaSave, FaMapMarkerAlt, FaCalendarAlt, FaDollarSign, FaListOl, FaInfoCircle, FaUser } from 'react-icons/fa';
// import { RiCloseCircleFill } from "react-icons/ri";
// import { Program } from '../../../hooks/AdminDashboard/useProgram';
// import ProgramListAlert from './ProgramListAlert';

// interface ProgramDetailModalProps {
//   program: Program;
//   fieldValues: Partial<Program>;
//   isEditing: boolean;
//   onClose: () => void;
//   onSave: () => void;
//   onFieldChange: (field: keyof Program, value: any) => void;
//   apiUrl: string;
// }

// const ProgramDetailModal: React.FC<ProgramDetailModalProps> = ({
//   program,
//   fieldValues,
//   isEditing,
//   onClose,
//   onSave,
//   onFieldChange,
//   apiUrl,
// }) => {
//   const [localIsEditing, setIsEditing] = useState(isEditing);
//   const [currency, setCurrency] = useState<'USD' | 'MYR' | 'FREE'>(program.currency || 'USD');
//   const [alertDetails, setAlertDetails] = useState<{
//     title: string;
//     message: string;
//     icon: React.ReactNode;
//     onConfirm: () => void;
//     onCancel: () => void;
//   } | null>(null);

//   const { theme } = useTheme();

//   const formatDate = (date: string | null | undefined) => {
//     if (!date) return '';
//     const d = new Date(date);
//     return d.toISOString().split('T')[0]; // format as yyyy-mm-dd
//   };

//   const handleSaveClick = () => {
//     setAlertDetails({
//       title: 'Confirm Changes',
//       message: `Are you sure you want to save the changes to ${program.name}?`,
//       icon: <FaSave className="h-6 w-6 text-green-600" aria-hidden="true" />,
//       onConfirm: handleConfirmSave,
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleConfirmSave = () => {
//     setAlertDetails(null);
//     onFieldChange('status', 'stopped'); // Set status to stopped after saving
//     onSave();
//     setAlertDetails({
//       title: 'Success',
//       message: 'Changes saved successfully!',
//       icon: <FaSave className="h-6 w-6 text-green-600" aria-hidden="true" />,
//       onConfirm: () => setAlertDetails(null),
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const newCurrency = e.target.value as 'USD' | 'MYR' | 'FREE';
//     setCurrency(newCurrency);
//     onFieldChange('currency', newCurrency);
//     if (newCurrency === 'FREE') {
//       onFieldChange('amount', 0);
//       onFieldChange('points', 0);
//     }
//   };

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="bg-white p-10 rounded shadow-xl w-[600px] relative">
//         {program.image && <img src={`${apiUrl}/uploads/${program.image}`} alt={program.name} className="w-full h-48 object-cover rounded mb-4" />}
//         <table>
//           <tbody>
//             {[
//               { title: 'Title', field: 'name', type: 'text', icon: <FaEdit /> },
//               { title: 'Start Date', field: 'startDate', type: 'date', icon: <FaCalendarAlt /> },
//               { title: 'End Date', field: 'endDate', type: 'date', icon: <FaCalendarAlt /> },
//               { title: 'Location', field: 'location', type: 'text', icon: <FaMapMarkerAlt /> },
//               { title: 'Amount', field: 'amount', type: 'number', icon: <FaDollarSign /> },
//               { title: 'Currency', field: 'currency', type: 'select', icon: <FaDollarSign /> },
//               { title: 'Points', field: 'points', type: 'number', icon: <FaListOl /> },
//               { title: 'Slot', field: 'slot', type: 'number', icon: <FaUser /> }, // Add this line
//               { title: 'Description', field: 'description', type: 'textarea', icon: <FaInfoCircle /> },
//               { title: 'Redeem Number of Times', field: 'redeemNumber', type: 'number', icon: <FaListOl /> }
//             ].map(({ title, field, type, icon }) => (
//               <tr key={field as string} className="border-b py-2">
//                 <td className="font-semibold pr-4 flex items-center">
//                   {icon} <span className="ml-2">{title}:</span>
//                 </td>
//                 <td>
//                   {localIsEditing ? (
//                     type === 'textarea' ? (
//                       <textarea
//                         className="w-full p-4"
//                         value={fieldValues[field as keyof Program] || program[field as keyof Program] || ''}
//                         onChange={(e) => onFieldChange(field as keyof Program, e.target.value)}
//                       />
//                     ) : type === 'select' ? (
//                       <select
//                         className="w-full p-4"
//                         value={currency}
//                         onChange={handleCurrencyChange}
//                       >
//                         <option value="USD">USD</option>
//                         <option value="MYR">MYR</option>
//                         <option value="FREE">Free</option>
//                       </select>
//                     ) : (
//                       <input
//                         type={type}
//                         className="w-full p-4"
//                         value={fieldValues[field as keyof Program] || (field.includes('Date') ? formatDate(program[field as keyof Program] as string) : program[field as keyof Program]) || ''}
//                         onChange={(e) => onFieldChange(field as keyof Program, e.target.value)}
//                       />
//                     )
//                   ) : (
//                     field.includes('Date') ? formatDate(program[field as keyof Program] as string) : program[field as keyof Program]
//                   )}
//                 </td>
//               </tr>
//             ))}
//             <tr className="border-b py-2">
//               <td className="font-semibold pr-4 flex items-center">
//                 <FaUser /> <span className="ml-2 my-2">Created By:</span>
//               </td>
//               <td>{formatDate(program.createdAt)}</td>
//             </tr>
//             <tr className="border-b py-2">
//               <td className="font-semibold pr-4 flex items-center">
//                 <FaInfoCircle /> <span className="ml-2 my-2">Status:</span>
//               </td>
//               <td className={`py-1 px-2 w-1/2 rounded-full text-white text-center ${program.status === 'running' ? 'bg-green-500' : program.status === 'stopped' ? 'bg-red-500' : 'bg-gray-500'}`}>
//                 {program.status.charAt(0).toUpperCase() + program.status.slice(1)}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//         <div className="flex justify-between mt-4 bg-gray-100 p-4 rounded">
//           <button className="px-4 py-2 bg-blue-500 rounded flex items-center space-x-1"
//             onClick={() => { localIsEditing ? handleSaveClick() : setIsEditing(true); }}
//             style={{ backgroundColor: theme.primaryColor, fontFamily: theme.fontFamily, color: theme.text2Color }}>
//             {localIsEditing ? <FaSave /> : <FaEdit />}
//             <span>{localIsEditing ? 'Save' : 'Modify'}</span>
//           </button>
//           <button className="px-4 py-2 bg-red-500 rounded flex items-center space-x-1"
//             onClick={onClose}
//             style={{ fontFamily: theme.fontFamily, color: theme.text2Color }}>
//             <RiCloseCircleFill />
//             Close
//           </button>
//         </div>
//       </div>
//       {alertDetails && (
//         <div className="fixed inset-0 z-50 flex items-center justify-center">
//           <ProgramListAlert
//             title={alertDetails.title}
//             message={alertDetails.message}
//             icon={alertDetails.icon}
//             onConfirm={alertDetails.onConfirm}
//             onCancel={alertDetails.onCancel}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default ProgramDetailModal;






// // update code --> 21 june 2024
// // src/components/admin/EventSetting/ProgramDetailModal.tsx

// import React from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import { FaEdit, FaSave } from 'react-icons/fa';
// import { RiCloseCircleFill } from "react-icons/ri";
// import { Program } from '../../../hooks/AdminDashboard/useProgram';

// interface ProgramDetailModalProps {
//   program: Program;
//   fieldValues: Partial<Program>;
//   isEditing: boolean;
//   onClose: () => void;
//   onSave: () => void;
//   onFieldChange: (field: keyof Program, value: any) => void;
//   apiUrl: string;
// }

// const ProgramDetailModal: React.FC<ProgramDetailModalProps> = ({
//   program,
//   fieldValues,
//   isEditing,
//   onClose,
//   onSave,
//   onFieldChange,
//   apiUrl,
// }) => {
//   const formatDate = (date: string | null | undefined) => {
//     if (!date) return '';
//     const d = new Date(date);
//     return d.toISOString().split('T')[0];
//   };
//   const { theme } = useTheme();

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="bg-white p-6 rounded shadow-xl w-[600px] relative">
//         {program.image && <img src={`${apiUrl}/uploads/${program.image}`} alt={program.name} className="w-full h-48 object-cover rounded mb-4" />}
//         <table>
//           <tbody>
//             <tr>
//               <td className="font-semibold">Title:</td>
//               <td>
//                 {isEditing ? (
//                   <input
//                     type="text"
//                     value={fieldValues.name || program.name}
//                     onChange={(e) => onFieldChange('name', e.target.value)}
//                   />
//                 ) : (
//                   program.name
//                 )}
//               </td>
//             </tr>
//             <tr>
//               <td className="font-semibold">Start Date:</td>
//               <td>
//                 {isEditing ? (
//                   <input
//                     type="date"
//                     value={fieldValues.startDate || formatDate(program.startDate)}
//                     onChange={(e) => onFieldChange('startDate', e.target.value)}
//                   />
//                 ) : (
//                   formatDate(program.startDate)
//                 )}
//               </td>
//             </tr>
//             <tr>
//               <td className="font-semibold">End Date:</td>
//               <td>
//                 {isEditing ? (
//                   <input
//                     type="date"
//                     value={fieldValues.endDate || formatDate(program.endDate)}
//                     onChange={(e) => onFieldChange('endDate', e.target.value)}
//                   />
//                 ) : (
//                   formatDate(program.endDate)
//                 )}
//               </td>
//             </tr>
//             <tr>
//               <td className="font-semibold">Location:</td>
//               <td>
//                 {isEditing ? (
//                   <input
//                     type="text"
//                     value={fieldValues.location || program.location}
//                     onChange={(e) => onFieldChange('location', e.target.value)}
//                   />
//                 ) : (
//                   program.location
//                 )}
//               </td>
//             </tr>
//             <tr>
//               <td className="font-semibold">Amount:</td>
//               <td>
//                 {isEditing ? (
//                   <input
//                     type="number"
//                     value={fieldValues.amount || program.amount}
//                     onChange={(e) => onFieldChange('amount', e.target.value)}
//                   />
//                 ) : (
//                   `$${program.amount}`
//                 )}
//               </td>
//             </tr>
//             <tr>
//               <td className="font-semibold">Points:</td>
//               <td>
//                 {isEditing ? (
//                   <input
//                     type="number"
//                     value={fieldValues.points || program.points}
//                     onChange={(e) => onFieldChange('points', e.target.value)}
//                   />
//                 ) : (
//                   program.points
//                 )}
//               </td>
//             </tr>
//             <tr>
//               <td className="font-semibold">Description:</td>
//               <td>
//                 {isEditing ? (
//                   <textarea
//                     value={fieldValues.description || program.description}
//                     onChange={(e) => onFieldChange('description', e.target.value)}
//                   />
//                 ) : (
//                   program.description
//                 )}
//               </td>
//             </tr>
//             <tr>
//               <td className="font-semibold">Redeem Number of Times:</td>
//               <td>
//                 {isEditing ? (
//                   <input
//                     type="number"
//                     value={fieldValues.redeemNumber || program.redeemNumber}
//                     onChange={(e) => onFieldChange('redeemNumber', e.target.value)}
//                   />
//                 ) : (
//                   program.redeemNumber === 0 ? 'Unlimited' : program.redeemNumber
//                 )}
//               </td>
//             </tr>
//             <tr>
//               <td className="font-semibold">Created Date:</td>
//               <td>{formatDate(program.createdAt)}</td>
//             </tr>
//             <tr>
//               <td className="font-semibold">Status:</td>
//               <td className={`py-1 px-2 rounded-2xl text-white text-center ${program.status === 'running' ? 'bg-green-500' : program.status === 'stopped' ? 'bg-red-500' : 'bg-gray-500'}`}>
//                 {program.status.charAt(0).toUpperCase() + program.status.slice(1)}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//         <div className="flex justify-between mt-4">
//           <button className="px-4 py-2 bg-blue-500 rounded flex items-center space-x-1" 
//             onClick={() => { if (isEditing) onSave(); }}
//             style={{ backgroundColor: theme.primaryColor, fontFamily: theme.fontFamily, color: theme.text2Color }}>
//             {isEditing ? <FaSave /> : <FaEdit />}
//             <span>{isEditing ? 'Save' : 'Modify'}</span>
//           </button>
//           <button className="px-4 py-2 bg-red-500 rounded flex items-center space-x-1" 
//             onClick={onClose}
//             style={{ fontFamily: theme.fontFamily, color: theme.text2Color }}>
//             <RiCloseCircleFill />
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProgramDetailModal;
