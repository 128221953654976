// src/context/RankingContext.tsx

import React, { createContext, useContext, ReactNode } from 'react';
import { useTheme } from './ThemeContext'; // Import the theme context

interface Stage {
  name: string;
}

interface Badge {
  name: string;
}

interface RankingContextProps {
  stages: Stage[];
  badges: Badge[];
  getStageColor: (stage: string) => string;
  getBadgeColor: (badge: string) => string;
}

const RankingContext = createContext<RankingContextProps | undefined>(undefined);

// Define stages and badges without colors
const stagesSteps: Stage[] = [
  { name: 'R One Guest' },
  { name: 'R One Member' },
  { name: 'R One Elite Trader' },
  { name: 'R One Master Trader' },
  { name: 'R One Certified Trader' },
];

const badgesSteps: Badge[] = [
  { name: 'Copper' },
  { name: 'Bronze' },
  { name: 'Silver' },
  { name: 'Gold' },
  { name: 'Diamond' },
];

export const RankingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { theme } = useTheme(); 

  const getStageColor = (stage: string) => {
    switch (stage) {
      case 'R One Guest':
        return theme.stages.guest;
      case 'R One Member':
        return theme.stages.member;
      case 'R One Elite Trader':
        return theme.stages.eliteTrader;
      case 'R One Master Trader':
        return theme.stages.masterTrader;
      case 'R One Certified Trader':
        return theme.stages.certifiedTrader;
      default:
        return 'gray';
    }
  };

  const getBadgeColor = (badge: string) => {
    switch (badge) {
      case 'Copper':
        return theme.badges.copper;
      case 'Bronze':
        return theme.badges.bronze;
      case 'Silver':
        return theme.badges.silver;
      case 'Gold':
        return theme.badges.gold;
      case 'Diamond':
        return theme.badges.diamond;
      default:
        return 'gray';
    }
  };

  return (
    <RankingContext.Provider value={{ stages: stagesSteps, badges: badgesSteps, getStageColor, getBadgeColor }}>
      {children}
    </RankingContext.Provider>
  );
};

export const useRanking = () => {
  const context = useContext(RankingContext);
  if (!context) {
    throw new Error('useRanking must be used within a RankingProvider');
  }
  return context;
};
