// src/components/client/Banner.tsx

import React, { useEffect, useState } from 'react';
import { useTheme } from '../../context/ThemeContext';
import useUserDashboard from '../../hooks/Dashboard/useUserDashboard'; 
import useUserNotification from '../../hooks/Dashboard/useUserNotification'; // Import the new hook
import { motion } from 'framer-motion'; // Import Framer Motion

const NotifyBanner: React.FC = () => {
  const { theme } = useTheme();
  const { user } = useUserDashboard();
  const { notifications } = useUserNotification(); // Get notifications
  const [visible, setVisible] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    if (user) {
      setVisible(true); // Show banner when user is fetched
    }
  }, [user]);

  if (!user || !showBanner) {
    return null; // Render nothing if no user is found or banner is hidden
  }

  // Function to close the banner
  const handleCloseBanner = () => {
    setVisible(false);
    setTimeout(() => setShowBanner(false), 500); // Wait for the transition to complete
  };

  const approvalNotification = notifications.find(notification => notification.type === 'approval');

  const styles = {
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    }
  };

  return (
    <motion.div
      id="notify-banner"
      className={`fixed z-50 flex flex-col justify-between w-[1200px] p-4 bg-white border border-gray-100 rounded-lg shadow-sm lg:w-[1000px] md:w-[700px] sm:w-[400px] xs:w-[385px]`}
      style={{ left: '50%', transform: 'translateX(-50%)', ...styles.text }}
      initial={{ y: '-100%', x: '-50%' }}
      animate={{ y: visible ? '1rem' : '-100%', x: '-50%' }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex flex-col w-full md:flex-col">
        <div className="md:flex-1">
          <h1 className="text-lg font-bold mb-2 md:text-left sm:text-base xs:text-sm" style={{ color: theme.textColor }}>
            Welcome, {user.username}!
          </h1>
        </div>
        <div className="md:flex-2">
          <p className="text-base font-normal md:text-sm sm:text-xs xs:text-xs" style={styles.text}>
            {approvalNotification ? 
              `Your event ticket (${approvalNotification.eventTitle}) has been approved. Check it in the "My Ticket" Mini App -> My Ticket tab.` :
              "We're glad to have you back. Please check your notifications and complete your daily tasks to stay on top of your goals. Have a great day!"
            }
          </p>
        </div>
      </div>

      {/* Close button */}
      <div className="flex items-end justify-end w-full">
        <button
          type="button"
          className="text-cyan-800 border border-cyan-800 rounded-full px-3 py-1 hover:text-white hover:bg-cyan-800 hover:border-white sm:text-sm xs:text-xs"
          onClick={handleCloseBanner}
        >
          Close
        </button>
      </div>
    </motion.div>
  );
};

export default NotifyBanner;




// // udpate code --> 02 july 2024
// // src/components/client/Banner.tsx

// import React, { useEffect, useState } from 'react';
// import { useTheme } from '../../context/ThemeContext';
// import useUserDashboard from '../../hooks/Dashboard/useUserDashboard'; 
// import useUserNotification from '../../hooks/Dashboard/useUserNotification'; // Import the new hook

// const NotifyBanner: React.FC = () => {
//   const { theme } = useTheme();
//   const { user } = useUserDashboard();
//   const { notifications } = useUserNotification(); // Get notifications
//   const [visible, setVisible] = useState(false);
//   const [showBanner, setShowBanner] = useState(true);

//   useEffect(() => {
//     if (user) {
//       setVisible(true); // Show banner when user is fetched
//     }
//   }, [user]);

//   if (!user || !showBanner) {
//     return null; // Render nothing if no user is found or banner is hidden
//   }

//   // Function to close the banner
//   const handleCloseBanner = () => {
//     setVisible(false);
//     setTimeout(() => setShowBanner(false), 500); // Wait for the transition to complete
//   };

//   const approvalNotification = notifications.find(notification => notification.type === 'approval');

//   const styles = {
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   return (
//     <div
//       id="notify-banner"
//       className={`fixed z-50 flex flex-col justify-between w-[calc(100%-2rem)] p-4 -translate-x-1/2 bg-white border border-gray-100 rounded-lg shadow-sm lg:max-w-7xl left-1/2 top-0 mt-6 transition-transform transform ${visible ? 'translate-y-0' : '-translate-y-full'}`}
//       style={styles.text}
//     >
//       <div className="flex flex-col md:flex-row md:items-center w-full">
//         <div className="md:flex-1">
//           <h1 className="text-lg font-bold mb-2 md:mb-0 md:mr-4" style={styles.text}>
//             Welcome, {user.username}!
//           </h1>
//         </div>
//         <div className="md:flex-2">
//           <p className="text-sm font-normal" style={styles.text}>
//             {approvalNotification ? 
//               `Your event ticket (${approvalNotification.eventTitle}) has been approved. Check it in the "My Ticket" -> Used tab.` :
//               "We're glad to have you back. Please check your notifications and complete your daily tasks to stay on top of your goals. Have a great day!"
//             }
//           </p>
//         </div>
//       </div>

//       {/* Close button */}
//       <div className="flex items-end justify-end w-full">
//         <button
//               type="button"
//               className="text-green-300 border border-green-300 rounded-full px-3 py-1 hover:text-gray-900 hover:border-gray-900"
//               onClick={handleCloseBanner}
//             >
//           Close
//         </button>
//       </div>
//     </div>
//   );
// };

// export default NotifyBanner;


// // update code --> 24 june 2204
// // src/components/client/Banner.tsx

// import React, { useEffect, useState } from 'react';
// import { useTheme } from '../../context/ThemeContext';
// import useUserDashboard from '../../hooks/Dashboard/useUserDashboard'; 

// const NotifyBanner: React.FC = () => {
//   const { theme } = useTheme();
//   const { user } = useUserDashboard();
//   const [visible, setVisible] = useState(false);
//   const [showBanner, setShowBanner] = useState(true);

//   useEffect(() => {
//     if (user) {
//       setVisible(true); // Show banner when user is fetched
//     }
//   }, [user]);

//   if (!user || !showBanner) {
//     return null; // Render nothing if no user is found or banner is hidden
//   }

//   // Function to close the banner
//   const handleCloseBanner = () => {
//     setVisible(false);
//     setTimeout(() => setShowBanner(false), 500); // Wait for the transition to complete
//   };

//   return (
//     <div
//       id="notify-banner"
//       className={`fixed z-50 flex flex-col justify-between w-[calc(100%-2rem)] p-4 -translate-x-1/2 bg-white border border-gray-100 rounded-lg shadow-sm lg:max-w-7xl left-1/2 top-0 mt-6 transition-transform transform ${visible ? 'translate-y-0' : '-translate-y-full'}`}
//       style={{ fontFamily: theme.fontFamily }}
//     >
//       <div className="flex flex-col md:flex-row md:items-center w-full">
//         <div className="md:flex-1">
//           <h1 className="text-lg font-bold mb-2 md:mb-0 md:mr-4" style={{ color: theme.textColor }}>
//             Welcome, {user.username}!
//           </h1>
//         </div>
//         <div className="md:flex-2">
//           <p className="text-sm font-normal" style={{ color: theme.textColor }}>
//             We're glad to have you back. Please check your notifications and complete your daily tasks to stay on top of your goals. Have a great day!
//           </p>
//         </div>
//       </div>
//       {/* Close button */}
//       <div className="flex items-end justify-end w-full">
//         <button
//           type="button"
//           className="text-green-300 border border-green-300 rounded-full px-3 py-1 hover:text-gray-900 hover:border-gray-900"
//           onClick={handleCloseBanner}
//         >
//           Close
//         </button>
//       </div>
//     </div>
//   );
// };

// export default NotifyBanner;
