// src/components/admin/admin06_Subscription.tsx

import React from 'react';
import SubscriptionProductPreview from './SubscriptionSetting/SubPreview';
import SubscriptionSettings from './SubscriptionSetting/SubSetting';

const SubSetting: React.FC = () => {
  return (
    <div className="w-auto h-auto max-w-screen flex flex-col p-10 border-2">
        <div className="px-[10px]">
          <h1 className="text-2xl py-2 mx-[200px] font-semibold text-center rounded-full bg-cyan-900 text-white">
            Subscription Product Preview
          </h1>
          <div className="my-2">
            <SubscriptionProductPreview/>
          </div>
        </div>
        <div className="px-[10px]">
          <div className="flex flex-col w-auto h-auto">
            <h1 className="text-2xl py-2 mx-[200px] font-semibold text-center rounded-full bg-cyan-900 text-white">
              Subscription Product Settings
            </h1>
            <div className="my-2">
              <SubscriptionSettings />
            </div>
          </div>
        </div>
    </div>
  );
};

export default SubSetting;

// // udpate code --> 03 Sept 2024
// // src/components/admin/admin06_Subscription.tsx

// import React from 'react';
// import SubscriptionProductPreview from './SubscriptionSetting/SubPreview';
// import SubscriptionSettings from './SubscriptionSetting/SubSetting';

// const SubSetting: React.FC = () => {
//   return (
//     <div className="w-auto min-h-screen flex flex-row justify-center p-10 border-2 lg:flex-col md:flex-col sm:flex-col xs:flex-col">
//         <div className="flex flex-col w-[1000px] lg:w-auto">
//           <h1 className="text-2xl font-semibold">Subscription Product Preview</h1>
//           <SubscriptionProductPreview />
//         </div>
//         <div className="flex justify-between items-center lg:mb-6 ml-8">
//           <div className="flex flex-col w-[1000px] lg:w-[900px]">
//             <h1 className="text-2xl font-semibold">Subscription Product Settings</h1>
//             <SubscriptionSettings />
//           </div>
//         </div>
//     </div>
//   );
// };

// export default SubSetting;