// src/components/client/MiniApp/App01_MyTicketEventDetail.tsx

import React, { useState } from 'react';
// import react-icon
import { FaArrowCircleLeft, FaHouseUser, FaTag } from 'react-icons/fa';
// import components
import MyTicketRegistration from './App01_MyTicketRegisteration';
import MyTicketAlert from './App_AlertMessage';
// import context and hooks
import { useEnv } from '../../../context/EnvContext';
import { useTheme } from '../../../context/ThemeContext';
import useEventData, { Event, getTagColor, convertToShortForm, formatTime, formatAmount } from '../../../hooks/MiniApp/useEventData';
import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
interface EventDetailProps {
  event: Event;
  onBack: () => void;
}

const EventDetail: React.FC<EventDetailProps> = ({ event, onBack }) => {
  const { apiUrl } = useEnv();
  const { theme } = useTheme();
  const { getSlotMessage, fetchUserApprovedTickets, checkMembership  } = useEventData();
  const { user } = useUserDashboard(); // Use the user info from this hook
  const [showRegistration, setShowRegistration] = useState(false);
  const [alert, setAlert] = useState<{ type: 'error' | 'success'; message: string; visible: boolean; onClose: () => void }>({
    type: 'error',
    message: '',
    visible: false,
    onClose: () => {}
  });

  const handleRegisterClick = async () => {
    if (user && user.email) {
      try {
        const userApprovedTickets = await fetchUserApprovedTickets(user.email, event.name);
        const userTicketsCount = userApprovedTickets.length;
  
        if (event.redeemNumber !== 0 && userTicketsCount >= event.redeemNumber) {
          setAlert({
            type: 'error',
            message: `You are only allowed to buy ${event.redeemNumber} ticket${event.redeemNumber > 1 ? 's' : ''} for this event.`,
            visible: true,
            onClose: () => setAlert(prevState => ({ ...prevState, visible: false }))
          });
          return;
        }
  
        const membershipCheck = await checkMembership(user.email, event.MemberType);
  
        if (!membershipCheck.allowed) {
          const formattedMemberTypes = membershipCheck.requiredMemberType.split(', ').map(type => `<b>${type}</b>`).join(', ');
          setAlert({
            type: 'error',
            message: `You do not meet the ${formattedMemberTypes} requirement.`,
            visible: true,
            onClose: () => setAlert(prevState => ({ ...prevState, visible: false }))
          });
          return;
        }
  
        setShowRegistration(true);
      } catch (error) {
        setAlert({
          type: 'error',
          message: 'Failed to fetch your tickets. Please try again later.',
          visible: true,
          onClose: () => setAlert(prevState => ({ ...prevState, visible: false }))
        });
      }
    }
  };          

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  return (
    <div className="p-2 rounded-lg w-auto h-auto mx-auto">
      <MyTicketAlert {...alert} />
      {showRegistration ? (
        <MyTicketRegistration event={event} onBack={() => setShowRegistration(false)} />
      ) : (
        <>
          <div className="overflow-hidden flex flex-col justify-center items-center">
            <img 
              src={event.image ? `${apiUrl}/uploads/forEventTicket/${event.image}` : "https://placehold.co/100x100"} 
              alt={event.name} 
              className="w-[200px] h-auto rounded-lg object-cover md:w-[200px] sm:w-[150px] xs:w-[100px]"
            />
            <h2 className="text-2xl font-bold md:text-xl sm:text-lg xs:text-lg"style={styles.icon}> {event.name} </h2>
            <div className="text-white bg-rose-400 py-1 px-4 rounded-full mb-2 md:text-xs sm:text-xs xs:text-xs">
              {event.redeemNumber === 0
                ? '1 person can buy many tickets.'
                : `1 person can only buy ${event.redeemNumber} ticket${event.redeemNumber > 1 ? 's' : ''}.`}
            </div>
            <div className="overflow-y-auto w-full h-auto max-h-[500px] xl:h-[280px] lg:h-[250px] md:h-[280px] sm:h-[350px] xs:h-[220px]">
              <table className="table-auto w-full">
                <tbody>
                  <tr>
                    <td className="font-bold border-r-2 pr-4 md:text-sm sm:text-xs xs:text-xs">Location:</td>
                    <td className="pl-8 md:text-sm sm:text-xs xs:text-xs">{event.location}</td>
                  </tr>
                  <tr>
                    <td className="font-bold border-r-2 pr-4 md:text-sm sm:text-xs xs:text-xs">Start Date:</td>
                    <td className="pl-8 md:text-sm sm:text-xs xs:text-xs">{new Date(event.startDate).toLocaleDateString()}</td>
                  </tr>
                  <tr>
                    <td className="font-bold border-r-2 pr-4 md:text-sm sm:text-xs xs:text-xs">End Date:</td>
                    <td className="pl-8 md:text-sm sm:text-xs xs:text-xs">{new Date(event.endDate).toLocaleDateString()}</td>
                  </tr>
                  <tr>
                    <td className="font-bold border-r-2 pr-4 md:text-sm sm:text-xs xs:text-xs">Time:</td>
                    <td className="pl-8 md:text-sm sm:text-xs xs:text-xs">{formatTime(event.time)}</td>
                  </tr>
                  <tr>
                    <td className="font-bold border-r-2 pr-4 md:text-sm sm:text-xs xs:text-xs">Amount:</td>
                    <td className="pl-8 md:text-sm sm:text-xs xs:text-xs">
                    {event.amount === 0 
                      ? "Free of Charges" 
                      : `${event.currency} ${formatAmount(event.amount)}`}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border-r-2 pr-4 md:text-sm sm:text-xs xs:text-xs">Requirement:</td>
                    <td>
                      <ul>
                        <li className="pl-8 md:text-sm sm:text-xs xs:text-xs">{event.lotSize === 0 ? 'No Lot Size Required' : `${event.lotSize} lot Size`}</li>
                        <li className="pl-8 md:text-sm sm:text-xs xs:text-xs">{event.deposit === 0 ? 'No Deposit Required' : `${formatAmount(event.deposit)} USD`}</li>
                        <li className="pl-8 md:text-sm sm:text-xs xs:text-xs">{event.broker}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold border-r-2 pr-4 md:text-sm sm:text-xs xs:text-xs">Points:</td>
                    <td className="pl-8 md:text-sm sm:text-xs xs:text-xs">{event.points === 0 
                      ? "Point redemption is not available for this ticket." 
                      : `${event.points} points`}</td>
                  </tr>
                  <tr>
                    <td className="font-bold border-r-2 pr-4 md:text-sm sm:text-xs xs:text-xs">Slots:</td>
                    <td className="pl-8 w-auto text-center md:text-sm sm:text-xs xs:text-xs">{getSlotMessage(event.slot)}</td>
                  </tr>
                  <tr className="mb-2">
                    <td className="font-bold border-r-2 pr-4 pt-4 md:text-sm sm:text-xs xs:text-xs">Member Types:</td>
                    <td className="pl-8">
                      <div className="flex flex-wrap pt-4">
                        {event.MemberType.map((memberType, index) => {
                          const shortForm = convertToShortForm(memberType);
                          const tagColor = getTagColor(shortForm);
                          return (
                            <div key={index} className={`${tagColor} py-1 px-2 rounded-lg text-xs flex items-center space-x-1 xs:text-[10px]`} title={memberType}>
                              <FaTag />
                              <span>{shortForm}</span>
                            </div>
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                  <tr className="mb-10">
                    <td className="font-bold border-r-2 pr-4 md:text-sm sm:text-xs xs:text-xs">Description:</td>
                    <td className="pl-8">
                      <ul className="list-disc list-inside p-4 md:text-sm sm:text-xs xs:text-xs">
                        {event.description.split('\n').map((line, index) => {
                          const match = line.match(/\[(.*?)\]/);
                          if (match) {
                            return <li key={index}>{match[1].trim()}</li>;
                          } else {
                            return <p key={index}>{line.trim()}</p>;
                          }
                        })}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-between items-center my-4">
            <button className="flex items-center space-x-2 bg-cyan-800 text-white rounded-full px-4 py-2" 
              onClick={onBack}>
              <FaArrowCircleLeft /> 
              <div className="ml-2 font-semibold md:text-base sm:text-sm xs:text-xs">Back</div>
            </button>
            <button className="flex items-center space-x-2 bg-cyan-800 text-white rounded-full px-4 py-2" 
              onClick={handleRegisterClick}>
              <FaHouseUser /> 
              <div className='ml-2 font-semibold md:text-base sm:text-sm xs:text-xs'>Register</div>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default EventDetail;


// // update code --> 30 june 2024 v02
// // src/components/client/MiniApp/App01_MyTicketEventDetail.tsx

// import React, { useState } from 'react';
// // import react-icon
// import { FaArrowCircleLeft, FaHouseUser, FaTag } from 'react-icons/fa';
// // import components
// import MyTicketRegistration from './App01_MyTicketRegisteration';
// import MyTicketAlert from './App_AlertMessage';
// // import context and hooks
// import { useEnv } from '../../../context/EnvContext';
// import { useTheme } from '../../../context/ThemeContext';
// import useEventData, { Event, getTagColor, convertToShortForm, formatTime } from '../../../hooks/MiniApp/useEventData';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard'; // Import useUserDashboard to get user info
// interface EventDetailProps {
//   event: Event;
//   onBack: () => void;
// }

// const EventDetail: React.FC<EventDetailProps> = ({ event, onBack }) => {
//   const { apiUrl } = useEnv();
//   const { theme } = useTheme();
//   const { getSlotMessage, fetchUserApprovedTickets } = useEventData();
//   const { user } = useUserDashboard(); // Use the user info from this hook
//   const [showRegistration, setShowRegistration] = useState(false);
//   const [alert, setAlert] = useState<{ type: 'error' | 'success'; message: string; visible: boolean; onClose: () => void }>({
//     type: 'error',
//     message: '',
//     visible: false,
//     onClose: () => {}
//   });

//   const handleRegisterClick = async () => {
//     if (user && user.email) {
//       try {
//         const userApprovedTickets = await fetchUserApprovedTickets(user.email, event.name);
//         const userTicketsCount = userApprovedTickets.length;
  
//         if (event.redeemNumber !== 0 && userTicketsCount >= event.redeemNumber) {
//           setAlert({
//             type: 'error',
//             message: `You are only allowed to buy ${event.redeemNumber} ticket${event.redeemNumber > 1 ? 's' : ''} for this event.`,
//             visible: true,
//             onClose: () => setAlert(prevState => ({ ...prevState, visible: false }))
//           });
//           return;
//         }
  
//         setShowRegistration(true);
//       } catch (error) {
//         setAlert({
//           type: 'error',
//           message: 'Failed to fetch your tickets. Please try again later.',
//           visible: true,
//           onClose: () => setAlert(prevState => ({ ...prevState, visible: false }))
//         });
//       }
//     }
//   };    

//   return (
//     <div className="p-4 bg-white rounded-lg max-w-full sm:max-w-lg mx-auto">
//       <MyTicketAlert {...alert} />
//       {showRegistration ? (
//         <MyTicketRegistration event={event} onBack={() => setShowRegistration(false)} />
//       ) : (
//         <>
//           <div className="overflow-y-auto max-h-[700px] font-sans">
//             <h2 className="text-2xl font-bold mb-4">{event.name}</h2>
//             <div className="text-red-600 mb-2">
//               {event.redeemNumber === 0
//                 ? '1 person can buy many tickets.'
//                 : `1 person can only buy ${event.redeemNumber} ticket${event.redeemNumber > 1 ? 's' : ''}.`}
//             </div>
//             <img 
//               src={event.image ? `${apiUrl}/uploads/forEventTicket/${event.image}` : "https://placehold.co/100x100"} 
//               alt={event.name} 
//               className="w-auto rounded-lg object-cover mb-4" 
//             />
//             <table className="table-auto w-full mb-4">
//               <tbody>
//                 <tr>
//                   <td className="font-bold">Location:</td>
//                   <td className="pl-8">{event.location}</td>
//                 </tr>
//                 <tr>
//                   <td className="font-bold">Start Date:</td>
//                   <td className="pl-8">{new Date(event.startDate).toLocaleDateString()}</td>
//                 </tr>
//                 <tr>
//                   <td className="font-bold">End Date:</td>
//                   <td className="pl-8">{new Date(event.endDate).toLocaleDateString()}</td>
//                 </tr>
//                 <tr>
//                   <td className="font-bold">Amount:</td>
//                   <td className="pl-8">
//                   {event.amount === 0 
//                     ? "Free of Charges" 
//                     : `${event.amount} ${event.currency}`}
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="font-bold">Time:</td>
//                   <td className="pl-8">{formatTime(event.time)}</td>
//                 </tr>
//                 <tr>
//                   <td className="font-bold">Points:</td>
//                   <td className="pl-8">{event.points === 0 
//                     ? "Point redemption is not available for this ticket." 
//                     : `${event.points} points`}</td>
//                 </tr>
//                 <tr>
//                   <td className="font-bold">Slots:</td>
//                   <td className="pl-8 w-auto">{getSlotMessage(event.slot)}</td>
//                 </tr>
//                 <tr className="mb-2">
//                   <td className="font-bold pt-4">Member Types:</td>
//                   <td className="pl-8">
//                     <div className="flex flex-wrap gap-1 pt-4">
//                       {event.MemberType.map((memberType, index) => {
//                         const shortForm = convertToShortForm(memberType);
//                         const tagColor = getTagColor(shortForm);
//                         return (
//                           <div key={index} className={`${tagColor} py-1 px-2 rounded-lg text-xs flex items-center space-x-1`} title={memberType}>
//                             <FaTag />
//                             <span>{shortForm}</span>
//                           </div>
//                         );
//                       })}
//                     </div>
//                   </td>
//                 </tr>
//                 <tr className="mb-10">
//                   <td className="font-bold">Description:</td>
//                   <td className="pl-8">
//                     <ul className="list-disc list-inside p-4">
//                       {event.description.split('\n').map((line, index) => {
//                         const match = line.match(/\[(.*?)\]/);
//                         if (match) {
//                           return <li key={index}>{match[1].trim()}</li>;
//                         } else {
//                           return <p key={index}>{line.trim()}</p>;
//                         }
//                       })}
//                     </ul>
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//           <div className="flex justify-between items-center my-2">
//             <button className="p-2 flex justify-between items-center" 
//               onClick={onBack}
//               style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }} >
//               <FaArrowCircleLeft /> 
//               <div className="ml-2 font-semibold">Back</div>
//             </button>
//             <button className="p-2 flex justify-between items-center" 
//               style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }} 
//               onClick={handleRegisterClick}>
//               <FaHouseUser /> 
//               <div className='ml-2 font-semibold'>Register</div>
//             </button>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default EventDetail;

// // udpate code --> 30 June 2024
// // src/components/client/MiniApp/App01_MyTicketEventDetail.tsx

// import React, { useState } from 'react';
// import { useEnv } from '../../../context/EnvContext';
// import useEventData, { Event } from '../../../hooks/MiniApp/useEventData';
// import { FaArrowCircleLeft, FaHouseUser, FaTag } from 'react-icons/fa';
// import { useTheme } from '../../../context/ThemeContext';
// import MyTicketRegistration from './App01_MyTicketRegisteration';
// import { getTagColor, convertToShortForm, formatTime } from '../../../hooks/MiniApp/useEventData';

// interface EventDetailProps {
//   event: Event;
//   onBack: () => void;
// }

// const EventDetail: React.FC<EventDetailProps> = ({ event, onBack }) => {
//   const { apiUrl } = useEnv();
//   const { theme } = useTheme();
//   const { getSlotMessage } = useEventData();
//   const [showRegistration, setShowRegistration] = useState(false);

//   const handleRegisterClick = () => {
//     setShowRegistration(true);
//   };

//   return (
//     <div className="p-4 bg-white rounded-lg max-w-full sm:max-w-lg mx-auto">
//       {showRegistration ? (
//         <MyTicketRegistration event={event} onBack={() => setShowRegistration(false)} />
//       ) : (
//         <>
//           <div className="overflow-y-auto max-h-[700px] font-sans">
//             <h2 className="text-2xl font-bold mb-4">{event.name}</h2>
//             <img 
//               src={event.image ? `${apiUrl}/uploads/forEventTicket/${event.image}` : "https://placehold.co/100x100"} 
//               alt={event.name} 
//               className="w-auto rounded-lg object-cover mb-4" 
//             />
//             <table className="table-auto w-full mb-4">
//               <tbody>
//                 <tr>
//                   <td className="font-bold">Location:</td>
//                   <td className="pl-8">{event.location}</td>
//                 </tr>
//                 <tr>
//                   <td className="font-bold">Start Date:</td>
//                   <td className="pl-8">{new Date(event.startDate).toLocaleDateString()}</td>
//                 </tr>
//                 <tr>
//                   <td className="font-bold">End Date:</td>
//                   <td className="pl-8">{new Date(event.endDate).toLocaleDateString()}</td>
//                 </tr>
//                 <tr>
//                   <td className="font-bold">Amount:</td>
//                   <td className="pl-8">
//                   {event.amount === 0 
//                     ? "Free of Charges" 
//                     : `${event.amount} ${event.currency}`}
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="font-bold">Time:</td>
//                   <td className="pl-8">{formatTime(event.time)}</td>
//                 </tr>
//                 <tr>
//                   <td className="font-bold">Points:</td>
//                   <td className="pl-8">{event.points === 0 
//                     ? "Point redemption is not available for this ticket." 
//                     : `${event.points} points`}</td>
//                 </tr>
//                 <tr>
//                   <td className="font-bold">Slots:</td>
//                   <td className="pl-8 w-auto">{getSlotMessage(event.slot)}</td>
//                 </tr>
//                 <tr className="mb-2">
//                   <td className="font-bold pt-4">Member Types:</td>
//                   <td className="pl-8">
//                     <div className="flex flex-wrap gap-1 pt-4">
//                       {event.MemberType.map((memberType, index) => {
//                         const shortForm = convertToShortForm(memberType);
//                         const tagColor = getTagColor(shortForm);
//                         return (
//                           <div key={index} className={`${tagColor} py-1 px-2 rounded-lg text-xs flex items-center space-x-1`} title={memberType}>
//                             <FaTag />
//                             <span>{shortForm}</span>
//                           </div>
//                         );
//                       })}
//                     </div>
//                   </td>
//                 </tr>
//                 <tr className="mb-10">
//                   <td className="font-bold">Description:</td>
//                   <td className="pl-8">
//                     <ul className="list-disc list-inside p-4">
//                       {event.description.split('\n').map((line, index) => {
//                         const match = line.match(/\[(.*?)\]/);
//                         if (match) {
//                           return <li key={index}>{match[1].trim()}</li>;
//                         } else {
//                           return <p key={index}>{line.trim()}</p>;
//                         }
//                       })}
//                     </ul>
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//           <div className="flex justify-between items-center my-2">
//             <button className="p-2 flex justify-between items-center" 
//               onClick={onBack}
//               style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }} >
//               <FaArrowCircleLeft /> 
//               <div className="ml-2 font-semibold">Back</div>
//             </button>
//             <button className="p-2 flex justify-between items-center" 
//               style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }} 
//               onClick={handleRegisterClick}>
//               <FaHouseUser /> 
//               <div className='ml-2 font-semibold'>Register</div>
//             </button>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default EventDetail;