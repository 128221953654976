// src/components/modals/ResetModal.tsx

import React, { useEffect, useState } from 'react';

// import react icons
import { FaInfoCircle, FaTimes, FaExclamationCircle } from 'react-icons/fa';

// import file from source
import { useTheme } from '../../context/ThemeContext';

// Define the props for the ResetModal component
interface ResetModalProps {
  visible: boolean; // Determines if the modal is visible
  onClose: () => void; // Function to close the modal
  isValidEmail: boolean; // Determines if the email is valid or not
}

const ResetModal: React.FC<ResetModalProps> = ({ visible, onClose, isValidEmail }) => {
  const { theme } = useTheme(); // Get theme from context
  const [showModal, setShowModal] = useState(false);

  // Effect to handle the animation
  useEffect(() => {
    if (visible) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [visible]);

  // If the modal is not visible, return null
  if (!visible) return null;

  return (
    // Modal container with transition effect
    <div 
      className={`fixed inset-x-0 top-0 transition-transform transform ${showModal ? 'translate-y-0' : '-translate-y-full'} z-50 px-4`}
      style={{ fontFamily: theme.fontFamily }}
    >
      {/* Alert box with conditional styling based on isValidEmail */}
      <div className={`relative flex flex-col p-4 mt-4 mb-4 text-sm rounded-lg max-w-lg mx-auto md:max-w-2xl ${isValidEmail ? 'text-green-800 bg-green-100 border border-green-500' : 'text-red-800 bg-red-100 border border-red-500'}`} role="alert">
        {/* Icon and title */}
        <div className="flex items-center">
          {isValidEmail ? (
            <FaInfoCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" />
          ) : (
            <FaExclamationCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" />
          )}
          <span className="font-medium" style={{ color: theme.textColor }}>
            {isValidEmail ? 'Info alert!' : 'Error alert!'}
          </span>
        </div>
        {/* Message content */}
        <div className={`mt-2 md:ml-4 ${isValidEmail ? 'text-green-700' : 'text-red-700'}`}>
          {isValidEmail ? 'Please check your email to reset your password.' : 'Invalid email address. Please try again.'}
        </div>
        {/* Close icon button */}
        <button 
          onClick={onClose} 
          className={`absolute top-2 right-2 ${isValidEmail ? 'text-green-800 hover:text-green-600' : 'text-red-800 hover:text-red-600'} focus:outline-none`}
        >
          <FaTimes className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default ResetModal;
