// src/hooks/currencyAPI.tsx

import { useState, useEffect } from 'react';

const useCurrencyConversion = (amountInMYR: number, amountInUSD?: number) => {
  const [usdAmount, setUsdAmount] = useState<string>('0.00');
  const [myrAmount, setMyrAmount] = useState<string>('0.00');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchConversionRate = async () => {
      try {
        const response = await fetch('https://latest.currency-api.pages.dev/v1/currencies/myr.json');
        if (!response.ok) {
          throw new Error('Failed to fetch exchange rate');
        }
        const data = await response.json();
        const exchangeRate = data.myr.usd; // Extract the USD exchange rate
        
        // Convert MYR to USD
        const convertedToUSD = (amountInMYR * exchangeRate).toFixed(2);
        setUsdAmount(convertedToUSD);
        
        // If amountInUSD is provided, convert it back to MYR
        if (amountInUSD) {
          const convertedToMYR = (amountInUSD / exchangeRate).toFixed(2);
          setMyrAmount(convertedToMYR);
        }

      } catch (err) {
        setError('Error fetching exchange rate');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchConversionRate();
  }, [amountInMYR, amountInUSD]);

  return { usdAmount, myrAmount, loading, error };
};

export default useCurrencyConversion;


// // udpate code --> 11 sept 2024
// // src/hooks/currencyAPI.tsx

// import { useState, useEffect } from 'react';

// const useCurrencyConversion = (amountInMYR: number) => {
//   const [usdAmount, setUsdAmount] = useState<string>('0.00');
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchConversionRate = async () => {
//       try {
//         const response = await fetch('https://latest.currency-api.pages.dev/v1/currencies/myr.json');
//         if (!response.ok) {
//           throw new Error('Failed to fetch exchange rate');
//         }
//         const data = await response.json();
//         const exchangeRate = data.myr.usd; // Extract the USD exchange rate
//         const convertedAmount = (amountInMYR * exchangeRate).toFixed(2); // Convert to USD
//         setUsdAmount(convertedAmount);
//       } catch (err) {
//         setError('Error fetching exchange rate');
//         console.error('Error:', err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchConversionRate();
//   }, [amountInMYR]);

//   return { usdAmount, loading, error };
// };

// export default useCurrencyConversion;
