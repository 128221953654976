// src/components/admin/EventSetting/ProgramStats.tsx

import React, { useState, useEffect } from 'react';
import useEventStats, {TableData} from '../../../hooks/AdminDashboard/useEventStats';
import { RiRefreshLine, RiSearchLine, RiExportFill  } from 'react-icons/ri';

const ProgramStats: React.FC = () => {
    const { 
      stats, loading, error, refetch, 
      tableData, fetchTableData, 
      tableLoading, tableError, 
      eventTables, fetchEventTables,
      tableStats, 
    } = useEventStats();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [sortColumn, setSortColumn] = useState<keyof TableData | ''>('');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [selectedTable, setSelectedTable] = useState('');

    useEffect(() => {
        fetchEventTables();
    }, [fetchEventTables]);

    useEffect(() => {
      if (selectedTable) {
          fetchTableData(selectedTable);
      }
    }, [selectedTable, fetchTableData]);

    const openModal = async () => {
        await fetchTableData(''); 
        setIsModalOpen(true);
      };      

    const closeModal = () => setIsModalOpen(false);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    };

    const handleExportCSV = () => {
      if (tableData.length === 0) {
          alert("No data to export");
          return;
      }
  
      const headers = Object.keys(tableData[0]).join(',');
      const rows = tableData.map(row =>
          Object.values(row).map(value => `"${value}"`).join(',')
      );
      const csvContent = [headers, ...rows].join('\n');
  
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `${selectedTable}.csv`);
      link.click();
    };

    const handleSort = (column: keyof TableData) => {
      if (sortColumn === column) {
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
      } else {
        setSortColumn(column);
        setSortOrder('asc');
      }
    };

    const filteredData = tableData.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(search.toLowerCase())
      )
    );

    const sortedData = filteredData.sort((a, b) => {
      if (sortColumn) {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (aValue < bValue) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });

    if (loading) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <p>Error: {error}</p>;
    }

    return (
      <div className="mt-4">
        <div className="flex justify-center items-center mb-2">
          <h3 className="font-semibold text-center text-xl">Event Stats</h3>
          <button
            className="text-cyan-800 text-lg ml-2 rounded-full p-1"
            onClick={refetch}
          >
            <RiRefreshLine />
          </button>
        </div>
        <div 
          className="flex justify-center cursor-pointer"
          onClick={openModal} 
        >
          <div className="px-[50px] py-4 rounded-lg border flex space-x-[50px]"> 
            <div className="text-center">
              <p className="font-semibold text-lg">{stats.attend}</p>
              <p className="text-muted-foreground">Total Attended</p>
            </div>
            <div className="text-center">
              <p className="font-semibold text-lg">{stats.notCheckIn}</p>
              <p className="text-muted-foreground">Not Check In</p>
            </div>
            <div className="text-center">
              <p className="font-semibold text-lg">{stats.totalRegistered}</p>
              <p className="text-muted-foreground">Total Registered</p>
            </div>
            <div className="text-center">
              <p className="font-semibold text-lg">{tableStats.totalApprove}</p>
              <p className="text-muted-foreground">Total Approve</p>
            </div>
            <div className="text-center">
              <p className="font-semibold text-lg">{tableStats.totalReject}</p>
              <p className="text-muted-foreground">Total Reject</p>
            </div>
            <div className="text-center">
              <p className="font-semibold text-lg">{tableStats.totalPending}</p>
              <p className="text-muted-foreground">Total Pending</p>
            </div>
          </div>
        </div>

        {/* Event Table Data*/}
        {isModalOpen && (
        <div 
          className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center"
          onClick={closeModal} 
        >
          <div 
            className="bg-white p-4 rounded shadow-lg w-auto max-w-screen h-auto max-h-[600px] flex flex-col"
            onClick={(e) => e.stopPropagation()} 
          >   
              {/* Event Table Selection */}
              <div className="space-x-4 mb-4">
                <div className="flex justify-end items-center">
                  <select 
                    value={selectedTable} 
                    onChange={(e) => setSelectedTable(e.target.value)} 
                    className="p-2 mr-4 border rounded">
                    <option value="">Select Event Table</option>
                    {eventTables.map(table => (
                      <option key={table} value={table}>{table}</option>
                    ))}
                  </select>

                  {/* Event Table Data Search Bar */}
                  <div className="relative">
                      <input
                        type="text"
                        placeholder="Search..."
                        className="p-1.5 border rounded w-full"
                        value={search}
                        onChange={handleSearch}
                      />
                      <RiSearchLine size={24} className="absolute right-2 top-2" />
                  </div>

                  {/* Export CSV Button */}
                  <button className="ml-2 text-cyan-800" onClick={handleExportCSV}>
                    <RiExportFill size={24} />
                  </button>
                </div>
              </div>

              {/* Event Table Error and Loading */}
              {tableLoading ? (
                <p>Loading table data...</p>
              ) : tableError ? (
                <p>Error: {tableError}</p>
              ) : (
                <div className="overflow-auto flex-grow">
                
                {/* Event Table Body */}
                <table className="w-full border-collapse">
                  <thead className="sticky top-0 bg-gray-200">
                    <tr>
                      {[
                        { key: 'ticketId', label: 'Ticket ID' },
                        { key: 'status', label:'Ticket Status' },
                        { key: 'eventTitle', label: 'Event' },
                        { key: 'username', label: 'Username' },
                        { key: 'email', label: 'Email' },
                        { key: 'updatedAt', label: 'Updated Date' },
                        { key: 'eventStatus', label: 'User Status' }
                        
                      ].map(column => (
                        <th
                          key={column.key}
                          className="border p-2 cursor-pointer"
                          onClick={() => handleSort(column.key as keyof TableData)}
                        >
                          {column.label} {sortColumn === column.key && (sortOrder === 'asc' ? '▲' : '▼')}
                        </th>
                      ))}
                    </tr>
                  </thead>
                    <tbody className="h-full overflow-y-auto text-center">
                      {sortedData.map((row, index) => (
                        <tr 
                          key={index}
                          className="hover:bg-gray-100"
                        >
                          <td className="border p-2">{row.ticketId}</td>
                          <td className="border p-2">{row.status}</td>
                          <td className="border p-2">{row.eventTitle}</td>
                          <td className="border p-2">{row.username}</td>
                          <td className="border p-2">{row.email}</td>
                          <td className="border p-2">
                            {row.updatedAt ? new Date(row.updatedAt).toISOString().split('T')[0] : 'N/A'}
                          </td>                          
                          <td className={`border p-2 ${row.eventStatus === 'Not Check-in' ? 'text-yellow-500' : row.eventStatus === 'Attend' ? ' text-green-500' : ''}`}>
                              {row.eventStatus}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                </table>
               </div>
              )}
              {/* Event Table Stats */}
              <div className="flex justify-between items-center my-2 px-8 py-4 border rounded-lg shadow-md">
                <div className="text-center">
                  <p className="text-muted-foreground">Total Register:</p>
                  <p className="font-semibold text-lg">{tableStats.totalRegister}</p>
                </div>
                <div className="text-center">
                  <p className="text-muted-foreground">Total Approve:</p>
                  <p className="font-semibold text-lg">{tableStats.totalApprove}</p>
                </div>
                <div className="text-center">
                  <p className="text-muted-foreground">Total Pending:</p>
                  <p className="font-semibold text-lg">{tableStats.totalPending}</p>
                </div>
                <div className="text-center">
                  <p className="text-muted-foreground">Total Reject:</p>
                  <p className="font-semibold text-lg">{tableStats.totalReject}</p>
                </div>
                <div className="text-center">
                  <p className="text-muted-foreground">Total Not Check-in:</p>
                  <p className="font-semibold text-lg">{tableStats.totalNotCheckIn}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
};

export default ProgramStats;



// // udpate code --> 17 Aug 2024
// // src/components/admin/EventSetting/ProgramStats.tsx

// import React, { useState, useEffect } from 'react';
// import useEventStats from '../../../hooks/AdminDashboard/useEventStats';
// import { formatDateWithTime } from '../../../hooks/MiniApp/useEventData';
// import { RiRefreshLine, RiSearchLine } from 'react-icons/ri';
// import { MdBackupTable } from 'react-icons/md';

// // Define the TableData interface in this file
// interface TableData {
//   ticketId: string;
//   eventTitle: string;
//   username: string;
//   email: string;
//   appointmentDate: string;
//   time: string;
//   paymentMethod: string;
//   eventStatus: string;
//   status: string;
// }

// const ProgramStats: React.FC = () => {
//     const { stats, loading, error, refetch, tableData, fetchTableData, tableLoading, tableError, eventTables, fetchEventTables } = useEventStats();
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [search, setSearch] = useState('');
//     const [sortColumn, setSortColumn] = useState<keyof TableData | ''>('');
//     const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
//     const [selectedTable, setSelectedTable] = useState('');

//     useEffect(() => {
//         fetchEventTables();
//     }, [fetchEventTables]);

//     const openModal = async () => {
//         await fetchTableData(''); 
//         setIsModalOpen(true);
//       };      
      
//       const handleFetchTableData = async () => {
//         await fetchTableData(selectedTable);
//       };

//     const closeModal = () => setIsModalOpen(false);

//     const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
//       setSearch(event.target.value);
//     };

//     const handleSort = (column: keyof TableData) => {
//       if (sortColumn === column) {
//         setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
//       } else {
//         setSortColumn(column);
//         setSortOrder('asc');
//       }
//     };

//     const filteredData = tableData.filter(item =>
//       Object.values(item).some(value =>
//         value.toString().toLowerCase().includes(search.toLowerCase())
//       )
//     );

//     const sortedData = filteredData.sort((a, b) => {
//       if (sortColumn) {
//         const aValue = a[sortColumn];
//         const bValue = b[sortColumn];

//         if (aValue < bValue) {
//           return sortOrder === 'asc' ? -1 : 1;
//         }
//         if (aValue > bValue) {
//           return sortOrder === 'asc' ? 1 : -1;
//         }
//       }
//       return 0;
//     });

//     if (loading) {
//       return <p>Loading...</p>;
//     }

//     if (error) {
//       return <p>Error: {error}</p>;
//     }

//     return (
//       <div className="mt-4">
//         <div className="flex justify-center items-center mb-2">
//           <h3 className="font-semibold text-center text-xl">Event Stats</h3>
//           <button
//             className="text-cyan-800 text-lg ml-2 rounded-full p-1"
//             onClick={refetch}
//           >
//             <RiRefreshLine />
//           </button>
//         </div>
//         <div className="flex justify-center">
//           <div className="px-[50px] py-4 rounded-lg border flex space-x-[200px]">
//             <div className="text-center">
//               <p className="font-semibold text-lg">{stats.attend}</p>
//               <p className="text-muted-foreground">Attended</p>
//             </div>
//             <div className="text-center">
//               <p className="font-semibold text-lg">{stats.notCheckIn}</p>
//               <p className="text-muted-foreground">Not Check In</p>
//             </div>
//             <div className="text-center">
//               <p className="font-semibold text-lg">{stats.totalRegistered}</p>
//               <p className="text-muted-foreground">Total Registered</p>
//             </div>
//           </div>
//           <button className="ml-4 text-2xl" onClick={openModal}>
//             <MdBackupTable />
//           </button>
//         </div>
//         {isModalOpen && (
//         <div 
//             className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center"
//             onClick={closeModal} 
//         >
//             <div 
//             className="bg-white p-4 rounded shadow-lg w-full max-w-4xl h-[80vh] flex flex-col"
//             onClick={(e) => e.stopPropagation()} 
//             >
//               <div className="flex justify-end items-center mb-4">
//                 <select 
//                   value={selectedTable} 
//                   onChange={(e) => setSelectedTable(e.target.value)} 
//                   className="p-2 border rounded">
//                   <option value="">Select Event Table</option>
//                   {eventTables.map(table => (
//                     <option key={table} value={table}>{table}</option>
//                   ))}
//                 </select>
//                 <button className="text-cyan-800 px-4" 
//                     onClick={handleFetchTableData}>
//                     <RiRefreshLine size={24} />
//                 </button>
//                 <div className="relative">
//                     <input
//                       type="text"
//                       placeholder="Search..."
//                       className="p-2 border rounded w-full"
//                       value={search}
//                       onChange={handleSearch}
//                     />
//                     <RiSearchLine size={24} className="absolute right-2 top-2" />
//                 </div>
//               </div>
//               {tableLoading ? (
//                 <p>Loading table data...</p>
//               ) : tableError ? (
//                 <p>Error: {tableError}</p>
//               ) : (
//                 <div className="overflow-auto flex-grow">
//                 <table className="w-full border-collapse">
//                   <thead className="sticky top-0 bg-white">
//                     <tr>
//                       {[
//                         { key: 'ticketId', label: 'Ticket ID' },
//                         { key: 'status', label:'Ticket Status' },
//                         { key: 'eventTitle', label: 'Event' },
//                         { key: 'username', label: 'Username' },
//                         { key: 'email', label: 'Email' },
//                         { key: 'appointmentDate', label: 'Event Date & Time' },
//                         { key: 'eventStatus', label: 'User Status' }
//                       ].map(column => (
//                         <th
//                           key={column.key}
//                           className="border p-2 cursor-pointer"
//                           onClick={() => handleSort(column.key as keyof TableData)}
//                         >
//                           {column.label} {sortColumn === column.key && (sortOrder === 'asc' ? '▲' : '▼')}
//                         </th>
//                       ))}
//                     </tr>
//                   </thead>
//                     <tbody className="h-full overflow-y-auto text-center">
//                       {sortedData.map((row, index) => (
//                         <tr key={index}>
//                           <td className="border p-2">{row.ticketId}</td>
//                           <td className="border p-2">{row.status}</td>
//                           <td className="border p-2">{row.eventTitle}</td>
//                           <td className="border p-2">{row.username}</td>
//                           <td className="border p-2">{row.email}</td>
//                           <td className="border p-2">{formatDateWithTime(row.appointmentDate, row.time)}</td>
//                           <td className={`border p-2 ${row.eventStatus === 'Not Check-in' ? 'text-yellow-500' : row.eventStatus === 'Attend' ? ' text-green-500' : ''}`}>
//                               {row.eventStatus}
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                 </table>
//                </div>
//               )}
//             </div>
//           </div>
//         )}
//       </div>
//     );
// };

// export default ProgramStats;
