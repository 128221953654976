// src/components/client/Dashboard/UserBoard.tsx

import React, { useState } from 'react';
import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
import useCurrencyConversion from '../../../hooks/currencyAPI';
import { useTheme } from '../../../context/ThemeContext';
import { AiOutlineClose } from 'react-icons/ai'; 
import { FaLanguage } from "react-icons/fa6";
import { FaHandPointLeft } from "react-icons/fa";
import { motion, AnimatePresence } from 'framer-motion';

const UserBoard: React.FC = () => {
  const { user } = useUserDashboard();
  const { theme } = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChinese, setIsChinese] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  
  // amount in MYR
  const amountInMYR = 5088; 
  const { usdAmount, loading, error } = useCurrencyConversion(amountInMYR);

  const getRandomRecommendation = () => {
    const recommendations = [
      'Dealer Program',
      '180 Funded Program',
      'Go4Fund Funded Program',
      'R One TraderHub'
    ];
    return recommendations[Math.floor(Math.random() * recommendations.length)];
  };

  const handleLanguageToggle = () => {
    setIsChinese(!isChinese);
  };

  const handleEnrollClick = () => {
    setShowContactInfo(true);
  };

  if (!user) {
    return null;
  }

  return (
    <div className="py-10 px-8 bg-white rounded-lg shadow sm:p-6 xs:p-6"
         style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
      {user.stages === 'R One Guest' ? (
        <h2 className="text-2xl font-normal"
           style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}>
            <strong>Hello {user.username}!</strong><br />
            <span className="text-xl md:text-lg sm:text-lg xs:text-base">
              We're thrilled to have you here. Unlock all R One features by enrolling in our exclusive courses!
            </span>
        </h2>
      ) : (
        <h2 className="text-2xl font-semibold xs:text-xl"
           style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}>
            Welcome Back, {user.username}!
        </h2>
      )}

      {user.stages === 'R One Guest' ? (
        <div>
          <button 
            className="mt-4 px-4 py-2 rounded"
            style={{
              fontFamily: theme.fontFamily,
              backgroundColor: theme.primaryColor,
              color: theme.text2Color,
            }}
            onClick={() => {
              setShowContactInfo(false);
              setIsModalOpen(true);
            }}
          >
            More Details
          </button>
        </div>
      ) : (
        <div>
          <p className="mt-2 md:text-base xs:text-sm" style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}>
            Recommended to R One Program: <strong>{getRandomRecommendation()}</strong>
          </p>
          <div className="flex justify-normal items-center">
            <a 
              href="https://www.r1group.asia/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="mt-4 px-4 py-2 rounded md:text-sm sm:text-base xs:text-sm text-center"
              style={{
                fontFamily: theme.fontFamily,
                backgroundColor: theme.primaryColor,
                color: theme.text2Color,
                display: 'inline-block',
              }}
            >
              Click Here
            </a>
            <div className="ml-4 mt-4 text-cyan-800 hover:underline flex justify-between items-center text-sm">
              <FaHandPointLeft className="mx-2 w-6 h-6" />
               visit R One external website to watch the course video syllabus.
            </div> 
          </div>
        </div>
      )}

      <AnimatePresence>
        {isModalOpen && (
          <motion.div 
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="bg-white rounded-lg p-6 mx-4 max-w-screen w-auto max-h-screen h-auto relative lg:[700px] md:h-[700px] xs:w-[500px]"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
            >
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 p-4"
              >
                <AiOutlineClose size={24} />
              </button>
                <h3 className="text-xl font-semibold mb-4 flex items-center" 
                  style={{ color: theme.textColor }}>
                  📚 {isChinese ? '报名 R ONE 课程' : 'Enroll in R ONE Courses'}
                </h3>
                {showContactInfo ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <h2>Contact:</h2>
                      <p>R One Course Instructor 课程顾问</p>
                      <a href="https://wa.me/message/VUIWWHZESE5BB1" target="_blank" rel="noopener noreferrer" className="text-blue-500">
                        WhatsApp
                      </a>
                    <div className="mt-[400px] md:mt-[490px] sm:mt-[400px] xs:mt-[400px]">
                      <button 
                        className="mt-4 px-4 py-2 rounded w-full" 
                        style={{ backgroundColor: theme.primaryColor, color: theme.text2Color }}
                        onClick={() => setShowContactInfo(false)}
                      >
                        Back
                      </button>
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {isChinese ? (
                      <div className="h-full overflow-y-auto p-2 md:h-[500px] sm:h-[400px] xs:h-[400px]">
                        <div className="flex justify-between items-center">
                          <p><strong>R ONE 课程</strong></p><br/>
                          <button 
                            onClick={handleLanguageToggle}
                            className="ml-2 text-gray-600 hover:text-gray-900 flex items-center"
                            style={{ color:theme.primaryColor }}
                          >
                            {isChinese ? <FaLanguage className="ml-2" /> : <FaLanguage className="ml-2" />}
                            <span className="ml-1">{isChinese ? 'CN' : 'EN'}</span>
                          </button>
                        </div>
                        <ul className="list-disc pl-5">
                          <li>新手必学 10套视频</li>
                          <li>教学攻略现场录制视频 112套 （终身观看）</li>
                          <li>Coaching现场录制视频 174套 （一年观看）</li>
                          <li>每月线上 R One Mastermind Q&A Session  Recording (一年观看）</li>
                          <li>每月线上 R One Mastermind Q&A Session LIVE （限时优惠3个月）</li>
                          <li>每月线下 R One Mastermind Q&A Session LIVE （限时优惠3个月）</li>
                          <li>R One Trader Hub (限时优惠3个月）</li>
                          <li>与Casper老师 Telegram 1 to 1 联系 （限时优惠3个月）</li>
                        </ul><br/>
                        <p><strong>R ONE TRADER HUB</strong></p><br/>
                        <ul className="list-disc pl-5">
                          <li>每周图表分析</li>
                          <li>Mini Coaching 市场分析解答</li>
                          <li>针对性图表Q&A</li>
                          <li>可参与线下活动 (T&C apply)</li>
                          <li>R One Traders' Decoder Program (Casper老师亲自小组授课）</li>
                          <li>180/Go4Funds 备考课程 （只限报名考试学员）</li>
                          <li>R One Traders Connect (旅行交易</li>
                          <li>LIVE TRADE （只限R ONE MASTER TRADER 或以上学员）</li>
                        </ul>
                      </div>
                    ) : (
                      <div className="h-full overflow-y-auto p-2 md:h-[500px] sm:h-[400px] xs:h-[400px]">
                        <div className="flex justify-between items-end">
                          <p><strong>R ONE COURSES</strong></p><br/>
                          <button 
                              onClick={handleLanguageToggle}
                              className="ml-2 text-gray-600 hover:text-gray-900 flex items-center"
                              style={{ color:theme.primaryColor }}
                            >
                              {isChinese ? <FaLanguage className="ml-2" /> : <FaLanguage className="ml-2" />}
                              <span className="ml-1">{isChinese ? 'CN' : 'EN'}</span>
                          </button>
                        </div>
                        <ul className="list-disc pl-5">
                          <li>Essential Videos for Beginners (10 sets)</li>
                          <li>Strategy Tutorial Live Videos (112 sets, Lifetime access)</li>
                          <li>Coaching Live Videos (174 sets, One year access)</li>
                          <li>Monthly Online R One Mastermind Q&A Session Recording (One year access)</li>
                          <li>Monthly Online R One Mastermind Q&A Session LIVE (3 months limited offer)</li>
                          <li>Monthly Offline R One Mastermind Q&A Session LIVE (3 months limited offer)</li>
                          <li>R One Trader Hub (3 months limited offer)</li>
                          <li>1 to 1 contacted with Teacher Casper with Telegram (3 months limited offer)</li>
                        </ul><br/>
                        <p><strong>R ONE TRADER HUB</strong></p><br/>
                        <ul className="list-disc pl-5">
                          <li>Weekly Chart Analysis</li>
                          <li>Mini Coaching Market Analysis</li>
                          <li>Targeted Chart Q&A</li>
                          <li>Participation in Offline Activities (T&C apply)</li>
                          <li>R One Traders' Decoder Program (Group Classes by Casper)</li>
                          <li>180/Go4Funds Exam Preparation Courses (Only for enrolled exam students)</li>
                          <li>R One Traders Connect (Travel Trading)</li>
                          <li>LIVE TRADE (Only for R ONE MASTER TRADER or above)</li>
                        </ul>
                      </div>
                    )}
                     <div className="py-2 px-4 border-2 rounded-2xl ">
                      <p className="text-lg font-semibold">Price: RM 5,088.00</p>
                      {loading ? (
                        <p className="text-lg font-semibold">Converting to USD...</p>
                      ) : error ? (
                        <p className="text-lg font-semibold text-red-500">Error fetching conversion</p>
                      ) : (
                        <p className="text-lg font-semibold">Approx. USD: ${usdAmount}</p>
                      )}
                    </div>
                    <button 
                      className="my-2 px-4 py-2 rounded w-full" 
                      style={{ backgroundColor: theme.primaryColor, color: theme.text2Color }}
                      onClick={handleEnrollClick}
                    >
                      Enroll
                    </button>
                  </motion.div>
                )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserBoard;

// // updatae code --> 11 Sept 2024
// // src/components/client/Dashboard/UserBoard.tsx

// import React, { useState } from 'react';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import { useTheme } from '../../../context/ThemeContext';
// import { AiOutlineClose } from 'react-icons/ai'; 
// import { FaLanguage } from "react-icons/fa6";
// import { FaHandPointLeft } from "react-icons/fa";
// import { motion, AnimatePresence } from 'framer-motion';

// const UserBoard: React.FC = () => {
//   const { user } = useUserDashboard();
//   const { theme } = useTheme();
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isChinese, setIsChinese] = useState(false);
//   const [showContactInfo, setShowContactInfo] = useState(false);

//   const getRandomRecommendation = () => {
//     const recommendations = [
//       'Dealer Program',
//       '180 Funded Program',
//       'Go4Fund Funded Program',
//       'R One TraderHub'
//     ];
//     return recommendations[Math.floor(Math.random() * recommendations.length)];
//   };

//   const convertToUSD = (amountInRM: number) => {
//     const exchangeRate = 4.45; 
//     return (amountInRM / exchangeRate).toFixed(2);
//   };

//   const handleLanguageToggle = () => {
//     setIsChinese(!isChinese);
//   };

//   const handleEnrollClick = () => {
//     setShowContactInfo(true);
//   };

//   if (!user) {
//     return null;
//   }

//   return (
//     <div className="py-10 px-8 bg-white rounded-lg shadow sm:p-6 xs:p-6"
//          style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//       {user.stages === 'R One Guest' ? (
//         <h2 className="text-2xl font-normal"
//            style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}>
//             <strong>Hello {user.username}!</strong><br />
//             <span className="text-xl md:text-lg sm:text-lg xs:text-base">
//               We're thrilled to have you here. Unlock all R One features by enrolling in our exclusive courses!
//             </span>
//         </h2>
//       ) : (
//         <h2 className="text-2xl font-semibold xs:text-xl"
//            style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}>
//             Welcome Back, {user.username}!
//         </h2>
//       )}

//       {user.stages === 'R One Guest' ? (
//         <div>
//           <button 
//             className="mt-4 px-4 py-2 rounded"
//             style={{
//               fontFamily: theme.fontFamily,
//               backgroundColor: theme.primaryColor,
//               color: theme.text2Color,
//             }}
//             onClick={() => {
//               setShowContactInfo(false);
//               setIsModalOpen(true);
//             }}
//           >
//             More Details
//           </button>
//         </div>
//       ) : (
//         <div>
//           <p className="mt-2 md:text-base xs:text-sm" style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}>
//             Recommended to R One Program: <strong>{getRandomRecommendation()}</strong>
//           </p>
//           <div className="flex justify-normal items-center">
//             <a 
//               href="https://www.r1group.asia/" 
//               target="_blank" 
//               rel="noopener noreferrer"
//               className="mt-4 px-4 py-2 rounded md:text-sm sm:text-base xs:text-sm text-center"
//               style={{
//                 fontFamily: theme.fontFamily,
//                 backgroundColor: theme.primaryColor,
//                 color: theme.text2Color,
//                 display: 'inline-block',
//               }}
//             >
//               Click Here
//             </a>
//             <div className="ml-4 mt-4 text-cyan-800 hover:underline flex justify-between items-center text-sm">
//               <FaHandPointLeft className="mx-2 w-6 h-6" />
//                visit R One external website to watch the course video syllabus.
//             </div> 
//           </div>
//         </div>
//       )}

//       <AnimatePresence>
//         {isModalOpen && (
//           <motion.div 
//             className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             exit={{ opacity: 0 }}
//           >
//             <motion.div 
//               className="bg-white rounded-lg p-6 mx-4 max-w-screen w-auto max-h-screen h-auto relative lg:[700px] md:h-[700px] xs:w-[500px]"
//               initial={{ scale: 0.8 }}
//               animate={{ scale: 1 }}
//               exit={{ scale: 0.8 }}
//             >
//               <button
//                 onClick={() => setIsModalOpen(false)}
//                 className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 p-4"
//               >
//                 <AiOutlineClose size={24} />
//               </button>
//                 <h3 className="text-xl font-semibold mb-4 flex items-center" 
//                   style={{ color: theme.textColor }}>
//                   📚 {isChinese ? '报名 R ONE 课程' : 'Enroll in R ONE Courses'}
//                 </h3>
//                 {showContactInfo ? (
//                   <motion.div
//                     initial={{ opacity: 0 }}
//                     animate={{ opacity: 1 }}
//                     exit={{ opacity: 0 }}
//                   >
//                     <h2>Contact:</h2>
//                       <p>R One Course Instructor 课程顾问</p>
//                       <a href="https://wa.me/message/VUIWWHZESE5BB1" target="_blank" rel="noopener noreferrer" className="text-blue-500">
//                         WhatsApp
//                       </a>
//                     <div className="mt-[400px] md:mt-[490px] sm:mt-[400px] xs:mt-[400px]">
//                       <button 
//                         className="mt-4 px-4 py-2 rounded w-full" 
//                         style={{ backgroundColor: theme.primaryColor, color: theme.text2Color }}
//                         onClick={() => setShowContactInfo(false)}
//                       >
//                         Back
//                       </button>
//                     </div>
//                   </motion.div>
//                 ) : (
//                   <motion.div
//                     initial={{ opacity: 0 }}
//                     animate={{ opacity: 1 }}
//                     exit={{ opacity: 0 }}
//                   >
//                     {isChinese ? (
//                       <div className="h-full overflow-y-auto p-2 md:h-[500px] sm:h-[400px] xs:h-[400px]">
//                         <div className="flex justify-between items-center">
//                           <p><strong>R ONE 课程</strong></p><br/>
//                           <button 
//                             onClick={handleLanguageToggle}
//                             className="ml-2 text-gray-600 hover:text-gray-900 flex items-center"
//                             style={{ color:theme.primaryColor }}
//                           >
//                             {isChinese ? <FaLanguage className="ml-2" /> : <FaLanguage className="ml-2" />}
//                             <span className="ml-1">{isChinese ? 'CN' : 'EN'}</span>
//                           </button>
//                         </div>
//                         <ul className="list-disc pl-5">
//                           <li>新手必学 10套视频</li>
//                           <li>教学攻略现场录制视频 112套 （终身观看）</li>
//                           <li>Coaching现场录制视频 174套 （一年观看）</li>
//                           <li>每月线上 R One Mastermind Q&A Session  Recording (一年观看）</li>
//                           <li>每月线上 R One Mastermind Q&A Session LIVE （限时优惠3个月）</li>
//                           <li>每月线下 R One Mastermind Q&A Session LIVE （限时优惠3个月）</li>
//                           <li>R One Trader Hub (限时优惠3个月）</li>
//                           <li>与Casper老师 Telegram 1 to 1 联系 （限时优惠3个月）</li>
//                         </ul><br/>
//                         <p><strong>R ONE TRADER HUB</strong></p><br/>
//                         <ul className="list-disc pl-5">
//                           <li>每周图表分析</li>
//                           <li>Mini Coaching 市场分析解答</li>
//                           <li>针对性图表Q&A</li>
//                           <li>可参与线下活动 (T&C apply)</li>
//                           <li>R One Traders' Decoder Program (Casper老师亲自小组授课）</li>
//                           <li>180/Go4Funds 备考课程 （只限报名考试学员）</li>
//                           <li>R One Traders Connect (旅行交易</li>
//                           <li>LIVE TRADE （只限R ONE MASTER TRADER 或以上学员）</li>
//                         </ul>
//                       </div>
//                     ) : (
//                       <div className="h-full overflow-y-auto p-2 md:h-[500px] sm:h-[400px] xs:h-[400px]">
//                         <div className="flex justify-between items-end">
//                           <p><strong>R ONE COURSES</strong></p><br/>
//                           <button 
//                               onClick={handleLanguageToggle}
//                               className="ml-2 text-gray-600 hover:text-gray-900 flex items-center"
//                               style={{ color:theme.primaryColor }}
//                             >
//                               {isChinese ? <FaLanguage className="ml-2" /> : <FaLanguage className="ml-2" />}
//                               <span className="ml-1">{isChinese ? 'CN' : 'EN'}</span>
//                           </button>
//                         </div>
//                         <ul className="list-disc pl-5">
//                           <li>Essential Videos for Beginners (10 sets)</li>
//                           <li>Strategy Tutorial Live Videos (112 sets, Lifetime access)</li>
//                           <li>Coaching Live Videos (174 sets, One year access)</li>
//                           <li>Monthly Online R One Mastermind Q&A Session Recording (One year access)</li>
//                           <li>Monthly Online R One Mastermind Q&A Session LIVE (3 months limited offer)</li>
//                           <li>Monthly Offline R One Mastermind Q&A Session LIVE (3 months limited offer)</li>
//                           <li>R One Trader Hub (3 months limited offer)</li>
//                           <li>1 to 1 contacted with Teacher Casper with Telegram (3 months limited offer)</li>
//                         </ul><br/>
//                         <p><strong>R ONE TRADER HUB</strong></p><br/>
//                         <ul className="list-disc pl-5">
//                           <li>Weekly Chart Analysis</li>
//                           <li>Mini Coaching Market Analysis</li>
//                           <li>Targeted Chart Q&A</li>
//                           <li>Participation in Offline Activities (T&C apply)</li>
//                           <li>R One Traders' Decoder Program (Group Classes by Casper)</li>
//                           <li>180/Go4Funds Exam Preparation Courses (Only for enrolled exam students)</li>
//                           <li>R One Traders Connect (Travel Trading)</li>
//                           <li>LIVE TRADE (Only for R ONE MASTER TRADER or above)</li>
//                         </ul>
//                       </div>
//                     )}
//                     <div className="py-2 px-4 border-2 rounded-2xl ">
//                       <p className="text-lg font-semibold">Price: RM 5,088.00</p>
//                       <p className="text-lg font-semibold">Approx. USD: ${convertToUSD(5088)}</p>
//                     </div>
//                     <button 
//                       className="my-2 px-4 py-2 rounded w-full" 
//                       style={{ backgroundColor: theme.primaryColor, color: theme.text2Color }}
//                       onClick={handleEnrollClick}
//                     >
//                       Enroll
//                     </button>
//                   </motion.div>
//                 )}
//             </motion.div>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// export default UserBoard;