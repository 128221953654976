// src/hooks/Login/useReset.tsx

import { useState } from 'react';
import { useEnv } from '../../context/EnvContext';

export const useReset = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { apiUrl } = useEnv();

  const resetPassword = async (email: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'An error occurred');
      }

      const data = await response.json();
      setLoading(false);
      return data;
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
      setLoading(false);
    }
  };

  const verifyResetToken = async (token: string, newPassword: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/verify-reset-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, newPassword }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'An error occurred');
      }

      const data = await response.json();
      setLoading(false);
      return data;
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
      setLoading(false);
      throw err; // Re-throw the error so it can be caught in the component
    }
  };

  return { resetPassword, verifyResetToken, loading, error };
};

// // updatae code --> 10 june 2024
// // src/hooks/useReset.tsx

// import { useState } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export const useReset = () => {
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();

//   const resetPassword = async (email: string) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/reset-password`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       if (!response.ok) {
//         const data = await response.json();
//         throw new Error(data.message || 'An error occurred');
//       }

//       const data = await response.json();
//       setLoading(false);
//       return data;
//     } catch (err) {
//       if (err instanceof Error) {
//         setError(err.message);
//       } else {
//         setError('An unknown error occurred');
//       }
//       setLoading(false);
//     }
//   };

//   const verifyResetToken = async (token: string, newPassword: string) => {
//     setLoading(true);
//     setError(null);

//     try {
//       console.log('Sending token:', token); // Log token being sent
//       const response = await fetch(`${apiUrl}/verify-reset-token`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ token, newPassword }),
//       });

//       if (!response.ok) {
//         const data = await response.json();
//         throw new Error(data.message || 'An error occurred');
//       }

//       const data = await response.json();
//       setLoading(false);
//       return data;
//     } catch (err) {
//       if (err instanceof Error) {
//         setError(err.message);
//       } else {
//         setError('An unknown error occurred');
//       }
//       setLoading(false);
//     }
//   };

//   return { resetPassword, verifyResetToken, loading, error };
// };


