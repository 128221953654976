// src/components/SideMenu.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  FaUser, FaClipboardCheck, 
  FaAppStore, FaCog, FaSignOutAlt, 
  FaBell, FaTrophy, FaAlgolia   
} from 'react-icons/fa';
// import { 
//   FaUser, FaWeixin, FaClipboardCheck, 
//   FaAppStore, FaCog, FaSignOutAlt, 
//   FaBell, FaTrophy, FaAlgolia   
// } from 'react-icons/fa';
import { MdSubscriptions } from "react-icons/md";
// import { IoTerminal } from "react-icons/io5";
import r1Logo from '../assets/usrDashboard/logo_r1_dash.png';
import { useTheme } from '../context/ThemeContext';
import { Tooltip } from 'react-tooltip';
import { motion } from 'framer-motion';

interface MenuItemProps {
  key: string;
  icon: React.ReactNode;
  label: string;
  path: string;
  hasBadge?: boolean;
  badgeCount?: number;
}

const menuItems: MenuItemProps[] = [
  { key: 'overview', icon: <FaAppStore />, label: 'Overview', path: '/admin-dashboard/overview' },
  { key: 'client-data', icon: <FaUser />, label: 'Client Data', path: '/admin-dashboard/client-data' },
  // { key: 'support-chat', icon: <FaWeixin />, label: 'Support Chat', path: '/admin-dashboard/support-chat' },
  { key: 'marketing', icon: <FaAlgolia />, label: 'Marketing', path: '/admin-dashboard/marketing' },
  { key: 'program-setting', icon: <FaClipboardCheck />, label: 'Event Setting', path: '/admin-dashboard/program-setting' },
  { key: 'notification', icon: <FaBell />, label: 'Notification', path: '/admin-dashboard/notification' },
  { key: 'subscription', icon: <MdSubscriptions />, label: 'Subscription', path: '/admin-dashboard/subscription' },
  { key: 'trader-program', icon: <FaTrophy />, label: 'Trader Program', path: '/admin-dashboard/trader-program' }

  // { key: 'terminal', icon: <IoTerminal />, label: 'Terminal', path: '/admin-dashboard/terminal' }
];

const SideMenu: React.FC<{ onToggle: (collapsed: boolean) => void }> = ({ onToggle }) => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [collapsed, setCollapsed] = useState(true); 

  const handleToggle = () => {
    const newCollapsedState = !collapsed;
    setCollapsed(newCollapsedState);
    onToggle(newCollapsedState);
  };

  return (
    <motion.aside
      initial={{ width: 80 }}
      animate={{ width: collapsed ? 80 : 256 }}
      transition={{ duration: 0.15 }}
      className="fixed flex flex-col h-screen px-4 py-8 overflow-y-auto bg-white border-r rtl:border-r-0 rtl:border-l transition-width duration-300"
      style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}
    >
      <button onClick={() => navigate('#')} className="flex justify-center items-center">
        <img className="transition-all duration-300 w-full h-full" src={r1Logo} alt="Logo" />
      </button>
      <button
        className="mt-4 text-gray-600"
        onClick={handleToggle}
        style={{ color: theme.primaryColor }}
      >
        {collapsed ? 'Expand' : 'Collapse'}
      </button>
      <div className="border-t border-gray-300 my-4"></div>
      <div className="flex flex-col justify-between flex-1">
        <nav>
          {menuItems.map((item) => (
            <button
              key={item.key}
              className={`flex items-center w-full px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-md dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-100 dark:hover:text-gray-200 hover:text-gray-700 ${collapsed ? 'justify-center' : ''}`}
              style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}
              onClick={() => navigate(item.path)}
              data-tooltip-id={item.key}
              data-tooltip-content={item.label}
            >
              <div className="relative">
                {item.icon}
              </div>
              {!collapsed && <span className="mx-4 font-medium">{item.label}</span>}
            </button>
          ))}
        </nav>
        <div className="mt-5">
          <button
            className="flex items-center w-full px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-md dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-100 dark:hover:text-gray-200 hover:text-gray-700"
            onClick={() => navigate('/admin-dashboard/settings')}
            style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}
          >
            <FaCog />
            {!collapsed && <span className="mx-4 font-medium">Settings</span>}
          </button>
          <button
            className="flex items-center w-full px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-md dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-100 dark:hover:text-gray-200 hover:text-gray-700"
            onClick={() => navigate('/login')}
            style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}
          >
            <FaSignOutAlt />
            {!collapsed && <span className="mx-4 font-medium">Logout</span>}
          </button>
        </div>
      </div>
      {menuItems.map(item => (
        <Tooltip key={item.key} id={item.key} />
      ))}
    </motion.aside>
  );
};

export default SideMenu;




// // udpate code --> 12 july 2024
// // src/components/SideMenu.tsx

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FaUser, FaWeixin, FaClipboardCheck, FaAppStore, FaCog, FaSignOutAlt, FaBell } from 'react-icons/fa';
// import r1Logo from '../assets/usrDashboard/logo_r1_dash.png';
// import { useTheme } from '../context/ThemeContext';
// import { Tooltip } from 'react-tooltip';
// import { motion } from 'framer-motion';

// interface MenuItemProps {
//   key: string;
//   icon: React.ReactNode;
//   label: string;
//   path: string;
//   hasBadge?: boolean;
//   badgeCount?: number;
// }

// const menuItems: MenuItemProps[] = [
//   { key: 'overview', icon: <FaAppStore />, label: 'Overview', path: '/admin-dashboard/overview' },
//   { key: 'client-data', icon: <FaUser />, label: 'Client Data', path: '/admin-dashboard/client-data' },
//   { key: 'support-chat', icon: <FaWeixin />, label: 'Support Chat', path: '/admin-dashboard/support-chat' },
//   { key: 'program-setting', icon: <FaClipboardCheck />, label: 'Program Setting', path: '/admin-dashboard/program-setting' },
//   { key: 'notification', icon: <FaBell />, label: 'Notification', path: '/admin-dashboard/notification', hasBadge: true, badgeCount: 5 },
// ];

// const SideMenu: React.FC = () => {
//   const navigate = useNavigate();
//   const { theme } = useTheme();
//   const [collapsed, setCollapsed] = useState(window.innerWidth < 768); 

//   // Add an event listener to update the state based on window resize
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth < 768) {
//         setCollapsed(true);
//       } else {
//         setCollapsed(false);
//       }
//     };
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return (
//     <motion.aside
//       initial={{ width: window.innerWidth < 768 ? 80 : 256 }}
//       animate={{ width: collapsed ? 80 : 256 }}
//       transition={{ duration: 0.15 }}
//       className={`flex flex-col h-screen px-4 py-8 overflow-y-auto bg-white border-r rtl:border-r-0 rtl:border-l transition-width duration-300`}
//       style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}
//     >
//       <button onClick={() => navigate('#')} className="flex justify-center items-center">
//         <img className="transition-all duration-300 w-full h-full" src={r1Logo} alt="Logo" />
//       </button>
//       <button
//         className="mt-4 text-gray-600"
//         onClick={() => setCollapsed(!collapsed)}
//         style={{ color: theme.primaryColor }}
//       >
//         {collapsed ? 'Expand' : 'Collapse'}
//       </button>
//       <div className="border-t border-gray-300 my-4"></div>
//       <div className="flex flex-col justify-between flex-1">
//         <nav>
//         {menuItems.map((item) => (
//           <button
//             key={item.key}
//             className={`flex items-center w-full px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-md dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-100 dark:hover:text-gray-200 hover:text-gray-700 ${collapsed ? 'justify-center' : ''}`}
//             style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}
//             onClick={() => navigate(item.path)}
//             data-tooltip-id={item.key}
//             data-tooltip-content={item.label}
//           >
//             <div className="relative">
//               {item.icon}
//               {item.hasBadge && item.badgeCount && (
//                 <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
//                   {item.badgeCount}
//                 </span>
//               )}
//             </div>
//             {!collapsed && <span className="mx-4 font-medium">{item.label}</span>}
//           </button>
//         ))}
//         </nav>
//         <div className="mt-5">
//           <button
//             className="flex items-center w-full px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-md dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-100 dark:hover:text-gray-200 hover:text-gray-700"
//             onClick={() => navigate('/admin-dashboard/settings')}
//             style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}
//           >
//             <FaCog />
//             {!collapsed && <span className="mx-4 font-medium">Settings</span>}
//           </button>
//           <button
//             className="flex items-center w-full px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-md dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-100 dark:hover:text-gray-200 hover:text-gray-700"
//             onClick={() => navigate('/login')}
//             style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}
//           >
//             <FaSignOutAlt />
//             {!collapsed && <span className="mx-4 font-medium">Logout</span>}
//           </button>
//         </div>
//       </div>
//       {menuItems.map(item => (
//         <Tooltip key={item.key} id={item.key} />
//       ))}
//     </motion.aside>
//   );
// };

// export default SideMenu;

