// src/context/AuthContext.tsx

import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import AccountExpireAlert from '../components/AccountExpireAlert';
import { useNavigate } from 'react-router-dom';
import { useEnv } from './EnvContext';

interface AuthContextType {
  isAuthenticated: boolean;
  user: { email: string; role: string } | null;
  login: (email: string, password: string) => Promise<{ token: string; role: string } | null>;
  logout: () => void;
  triggerSessionExpired: () => void;
  checkTokenExpiration: (token: string) => boolean;
  setUserFromToken: (token: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<{ email: string; role: string } | null>(null);
  const [isAccountExpireAlertVisible, setIsAccountExpireAlertVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const { apiUrl } = useEnv();

  const checkTokenExpiration = useCallback((token: string): boolean => {
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const currentTime = Math.floor(Date.now() / 1000);
      return currentTime > decodedToken.exp;
    } catch (error) {
      console.error('Token decoding error:', error);
      return true;
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setUser(null);
    setIsAuthenticated(false);
    setIsAccountExpireAlertVisible(false);
    navigate('/login');
  }, [navigate]);

  const setUserFromToken = async (token: string) => {
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setUser({ email: decodedToken.email, role: decodedToken.role });
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Token decoding error:', error);
      logout();
    }
  };

  const login = async (email: string, password: string): Promise<{ token: string; role: string } | null> => {
    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Authentication failed');
      }

      const data = await response.json();
      const { token, role } = data;

      localStorage.setItem('token', token);
      setUser({ email, role });
      setIsAuthenticated(true);
      return { token, role };
    } catch (error) {
      console.error('Login error:', error);
      return null;
    }
  };

  const triggerSessionExpired = () => {
    setIsAccountExpireAlertVisible(true);
  };

  const handleAccountExpireAlertClose = () => {
    logout();
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, triggerSessionExpired, checkTokenExpiration, setUserFromToken }}>
      {children}
      <AccountExpireAlert visible={isAccountExpireAlertVisible} onClose={handleAccountExpireAlertClose} />
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};


// // update code --> 12 july 2024
// // src/context/AuthContext.tsx

// import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
// import AccountExpireAlert from '../components/AccountExpireAlert';
// import { useNavigate } from 'react-router-dom';
// import { useEnv } from './EnvContext';

// interface AuthContextType {
//   isAuthenticated: boolean;
//   user: { email: string; role: string } | null;
//   login: (email: string, password: string) => Promise<{ token: string; role: string } | null>;
//   logout: () => void;
//   triggerSessionExpired: () => void;
//   checkTokenExpiration: (token: string) => boolean;
// }

// interface AuthProviderProps {
//   children: ReactNode;
// }

// const AuthContext = createContext<AuthContextType | undefined>(undefined);

// export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
//   const [user, setUser] = useState<{ email: string; role: string } | null>(null);
//   const [isAccountExpireAlertVisible, setIsAccountExpireAlertVisible] = useState<boolean>(false);
//   const navigate = useNavigate();
//   const { apiUrl } = useEnv();

//   const checkTokenExpiration = useCallback((token: string): boolean => {
//     try {
//       const decodedToken = JSON.parse(atob(token.split('.')[1]));
//       const currentTime = Math.floor(Date.now() / 1000);
//       return currentTime > decodedToken.exp;
//     } catch (error) {
//       console.error('Token decoding error:', error);
//       return true;
//     }
//   }, []);

//   const logout = useCallback(() => {
//     localStorage.removeItem('token');
//     setUser(null);
//     setIsAuthenticated(false);
//     setIsAccountExpireAlertVisible(false);
//     navigate('/login');
//   }, [navigate]);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       if (!checkTokenExpiration(token)) {
//         try {
//           const decodedToken = JSON.parse(atob(token.split('.')[1]));
//           setUser({ email: decodedToken.email, role: decodedToken.role });
//           setIsAuthenticated(true);
//         } catch (error) {
//           console.error('Token decoding error:', error);
//           logout();
//         }
//       } else {
//         logout();
//       }
//     }
//   }, [checkTokenExpiration, logout]);

//   const login = async (email: string, password: string): Promise<{ token: string; role: string } | null> => {
//     try {
//       const response = await fetch(`${apiUrl}/login`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email, password }),
//         credentials: 'include', // Add this line
//       });

//       if (!response.ok) {
//         throw new Error('Authentication failed');
//       }

//       const data = await response.json();
//       const { token, role } = data;

//       localStorage.setItem('token', token);
//       setUser({ email, role });
//       setIsAuthenticated(true);
//       return { token, role };
//     } catch (error) {
//       console.error('Login error:', error);
//       return null;
//     }
//   };

//   const triggerSessionExpired = () => {
//     setIsAccountExpireAlertVisible(true);
//   };

//   const handleAccountExpireAlertClose = () => {
//     logout();
//   };

//   return (
//     <AuthContext.Provider value={{ isAuthenticated, user, login, logout, triggerSessionExpired, checkTokenExpiration }}>
//       {children}
//       <AccountExpireAlert visible={isAccountExpireAlertVisible} onClose={handleAccountExpireAlertClose} />
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };



// // update code --> 07 july 2024
// // src/context/AuthContext.tsx

// import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
// import AccountExpireAlert from '../components/AccountExpireAlert';
// import { useNavigate } from 'react-router-dom';
// import { useEnv } from './EnvContext';

// interface AuthContextType {
//   isAuthenticated: boolean;
//   user: { email: string; role: string } | null;
//   login: (email: string, password: string) => Promise<{ token: string; role: string } | null>;
//   logout: () => void;
//   triggerSessionExpired: () => void;
//   checkTokenExpiration: (token: string) => boolean;
// }

// interface AuthProviderProps {
//   children: ReactNode;
// }

// const AuthContext = createContext<AuthContextType | undefined>(undefined);

// export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
//   const [user, setUser] = useState<{ email: string; role: string } | null>(null);
//   const [isAccountExpireAlertVisible, setIsAccountExpireAlertVisible] = useState<boolean>(false);
//   const navigate = useNavigate();
//   const { apiUrl } = useEnv();

//   const checkTokenExpiration = useCallback((token: string): boolean => {
//     try {
//       const decodedToken = JSON.parse(atob(token.split('.')[1]));
//       const currentTime = Math.floor(Date.now() / 1000);
//       return currentTime > decodedToken.exp;
//     } catch (error) {
//       console.error('Token decoding error:', error);
//       return true;
//     }
//   }, []);

//   const logout = useCallback(() => {
//     localStorage.removeItem('token');
//     setUser(null);
//     setIsAuthenticated(false);
//     setIsAccountExpireAlertVisible(false);
//     navigate('/login');
//   }, [navigate]);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       if (!checkTokenExpiration(token)) {
//         try {
//           const decodedToken = JSON.parse(atob(token.split('.')[1]));
//           setUser({ email: decodedToken.email, role: decodedToken.role });
//           setIsAuthenticated(true);
//         } catch (error) {
//           console.error('Token decoding error:', error);
//           logout();
//         }
//       } else {
//         logout();
//       }
//     }
//   }, [checkTokenExpiration, logout]);

//   const login = async (email: string, password: string): Promise<{ token: string; role: string } | null> => {
//     try {
//       const response = await fetch(`${apiUrl}/login`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email, password }),
//       });

//       if (!response.ok) {
//         throw new Error('Authentication failed');
//       }

//       const data = await response.json();
//       const { token, role } = data;

//       localStorage.setItem('token', token);
//       setUser({ email, role });
//       setIsAuthenticated(true);
//       return { token, role };
//     } catch (error) {
//       console.error('Login error:', error);
//       return null;
//     }
//   };

//   const triggerSessionExpired = () => {
//     setIsAccountExpireAlertVisible(true);
//   };

//   const handleAccountExpireAlertClose = () => {
//     logout();
//   };

//   return (
//     <AuthContext.Provider value={{ isAuthenticated, user, login, logout, triggerSessionExpired, checkTokenExpiration }}>
//       {children}
//       <AccountExpireAlert visible={isAccountExpireAlertVisible} onClose={handleAccountExpireAlertClose} />
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };


// // udpate code --> 05 july 2024
// // src/context/AuthContext.tsx

// import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
// import AccountExpireAlert from '../components/AccountExpireAlert';
// import { useNavigate } from 'react-router-dom';

// interface AuthContextType {
//   isAuthenticated: boolean;
//   user: { email: string; role: string } | null;
//   login: (email: string, password: string) => Promise<{ token: string; role: string } | null>;
//   logout: () => void;
//   triggerSessionExpired: () => void;
//   checkTokenExpiration: (token: string) => boolean;
// }

// interface AuthProviderProps {
//   children: ReactNode;
// }

// const AuthContext = createContext<AuthContextType | undefined>(undefined);

// export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
//   const [user, setUser] = useState<{ email: string; role: string } | null>(null);
//   const [isAccountExpireAlertVisible, setIsAccountExpireAlertVisible] = useState<boolean>(false);
//   const navigate = useNavigate();

//   const checkTokenExpiration = useCallback((token: string): boolean => {
//     try {
//       const decodedToken = JSON.parse(atob(token.split('.')[1]));
//       const currentTime = Math.floor(Date.now() / 1000);
//       return currentTime > decodedToken.exp;
//     } catch (error) {
//       console.error('Token decoding error:', error);
//       return true;
//     }
//   }, []);

//   const logout = useCallback(() => {
//     localStorage.removeItem('token');
//     setUser(null);
//     setIsAuthenticated(false);
//     setIsAccountExpireAlertVisible(false);
//     navigate('/login');
//   }, [navigate]);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       if (!checkTokenExpiration(token)) {
//         try {
//           const decodedToken = JSON.parse(atob(token.split('.')[1]));
//           setUser({ email: decodedToken.email, role: decodedToken.role });
//           setIsAuthenticated(true);
//         } catch (error) {
//           console.error('Token decoding error:', error);
//           logout();
//         }
//       } else {
//         logout();
//       }
//     }
//   }, [checkTokenExpiration, logout]);

//   const login = async (email: string, password: string): Promise<{ token: string; role: string } | null> => {
//     try {
//       const response = await fetch('http://localhost.com:5000/login', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email, password }),
//       });

//       if (!response.ok) {
//         throw new Error('Authentication failed');
//       }

//       const data = await response.json();
//       const { token, role } = data;

//       localStorage.setItem('token', token);
//       setUser({ email, role });
//       setIsAuthenticated(true);
//       return { token, role };
//     } catch (error) {
//       console.error('Login error:', error);
//       return null;
//     }
//   };

//   const triggerSessionExpired = () => {
//     setIsAccountExpireAlertVisible(true);
//   };

//   const handleAccountExpireAlertClose = () => {
//     logout();
//   };

//   return (
//     <AuthContext.Provider value={{ isAuthenticated, user, login, logout, triggerSessionExpired, checkTokenExpiration }}>
//       {children}
//       <AccountExpireAlert visible={isAccountExpireAlertVisible} onClose={handleAccountExpireAlertClose} />
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };


// // udpate code --> 04 july 2024 v02
// // src/context/AuthContext.tsx

// import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
// import AccountExpireAlert from '../components/AccountExpireAlert';
// import { useNavigate } from 'react-router-dom';

// interface AuthContextType {
//   isAuthenticated: boolean;
//   user: { email: string; role: string } | null;
//   login: (email: string, password: string) => Promise<{ token: string; role: string } | null>;
//   logout: () => void;
//   triggerSessionExpired: () => void;
// }

// interface AuthProviderProps {
//   children: ReactNode;
// }

// const AuthContext = createContext<AuthContextType | undefined>(undefined);

// export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
//   const [user, setUser] = useState<{ email: string; role: string } | null>(null);
//   const [isAccountExpireAlertVisible, setIsAccountExpireAlertVisible] = useState<boolean>(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       try {
//         const decodedToken = JSON.parse(atob(token.split('.')[1]));
//         const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

//         if (currentTime < decodedToken.exp) {
//           setUser({ email: decodedToken.email, role: decodedToken.role });
//           setIsAuthenticated(true);
//         } else {
//           localStorage.removeItem('token');
//           setIsAccountExpireAlertVisible(true);
//         }
//       } catch (error) {
//         console.error('Token decoding error:', error);
//         localStorage.removeItem('token');
//         setIsAccountExpireAlertVisible(true);
//       }
//     }
//   }, []);

//   const login = async (email: string, password: string): Promise<{ token: string; role: string } | null> => {
//     try {
//       const response = await fetch('http://localhost:5000/login', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email, password }),
//       });

//       if (!response.ok) {
//         throw new Error('Authentication failed');
//       }

//       const data = await response.json();
//       const { token, role } = data;

//       localStorage.setItem('token', token);
//       setUser({ email, role });
//       setIsAuthenticated(true);
//       return { token, role }; // Ensure this line returns both token and role
//     } catch (error) {
//       console.error('Login error:', error);
//       return null;
//     }
//   };

//   const logout = () => {
//     localStorage.removeItem('token');
//     setUser(null);
//     setIsAuthenticated(false);
//     setIsAccountExpireAlertVisible(false);
//   };

//   const triggerSessionExpired = () => {
//     setIsAccountExpireAlertVisible(true);
//   };

//   const handleAccountExpireAlertClose = () => {
//     logout();
//     navigate('/login');
//   };

//   return (
//     <AuthContext.Provider value={{ isAuthenticated, user, login, logout, triggerSessionExpired }}>
//       {children}
//       <AccountExpireAlert visible={isAccountExpireAlertVisible} onClose={handleAccountExpireAlertClose} />
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };



// // udpate code --> 04 july 2024
// // src/context/AuthContext.tsx

// import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
// import AccountExpireAlert from '../components/AccountExpireAlert';
// import { useNavigate } from 'react-router-dom';

// interface AuthContextType {
//   isAuthenticated: boolean;
//   user: { email: string; role: string } | null;
//   login: (email: string, password: string) => Promise<{ token: string; role: string } | null>;
//   logout: () => void;
// }

// interface AuthProviderProps {
//   children: ReactNode;
// }

// const AuthContext = createContext<AuthContextType | undefined>(undefined);

// export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
//   const [user, setUser] = useState<{ email: string; role: string } | null>(null);
//   const [isAccountExpireAlertVisible, setIsAccountExpireAlertVisible] = useState<boolean>(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       try {
//         const decodedToken = JSON.parse(atob(token.split('.')[1]));
//         const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  
//         if (currentTime < decodedToken.exp) {
//           setUser({ email: decodedToken.email, role: decodedToken.role });
//           setIsAuthenticated(true);
//         } else {
//           localStorage.removeItem('token');
//           setIsAccountExpireAlertVisible(true);
//         }
//       } catch (error) {
//         console.error('Token decoding error:', error);
//         localStorage.removeItem('token');
//         setIsAccountExpireAlertVisible(true);
//       }
//     }
//   }, []);

//   const login = async (email: string, password: string): Promise<{ token: string; role: string } | null> => {
//     try {
//       const response = await fetch('http://localhost:5000/login', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email, password }),
//       });

//       if (!response.ok) {
//         throw new Error('Authentication failed');
//       }

//       const data = await response.json();
//       const { token, role } = data;

//       localStorage.setItem('token', token);
//       setUser({ email, role });
//       setIsAuthenticated(true);
//       return { token, role }; // Ensure this line returns both token and role
//     } catch (error) {
//       console.error('Login error:', error);
//       return null;
//     }
//   };

//   const logout = () => {
//     localStorage.removeItem('token');
//     setUser(null);
//     setIsAuthenticated(false);
//     setIsAccountExpireAlertVisible(false);
//   };

//   const handleAccountExpireAlertClose = () => {
//     logout();
//     navigate('/login');
//   };

//   return (
//     <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
//       {children}
//       <AccountExpireAlert visible={isAccountExpireAlertVisible} onClose={handleAccountExpireAlertClose} />
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };

// // update code --> 03 july 20-24
// // src/context/AuthContext.tsx

// import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
// import AccountExpireAlert from '../components/AccountExpireAlert';
// import { useNavigate } from 'react-router-dom';

// interface AuthContextType {
//   isAuthenticated: boolean;
//   user: { email: string; role: string } | null;
//   login: (email: string, password: string) => Promise<{ token: string; role: string } | null>;
//   logout: () => void;
// }

// interface AuthProviderProps {
//   children: ReactNode;
// }

// const AuthContext = createContext<AuthContextType | undefined>(undefined);

// export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
//   const [user, setUser] = useState<{ email: string; role: string } | null>(null);
//   const [isAccountExpireAlertVisible, setIsAccountExpireAlertVisible] = useState<boolean>(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       const decodedToken = JSON.parse(atob(token.split('.')[1]));
//       const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

//       if (currentTime < decodedToken.exp) {
//         setUser({ email: decodedToken.email, role: decodedToken.role });
//         setIsAuthenticated(true);
//       } else {
//         setIsAccountExpireAlertVisible(true);
//       }
//     }
//   }, []);

//   const login = async (email: string, password: string): Promise<{ token: string; role: string } | null> => {
//     try {
//       const response = await fetch('http://localhost:5000/login', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email, password }),
//       });

//       if (!response.ok) {
//         throw new Error('Authentication failed');
//       }

//       const data = await response.json();
//       const { token, role } = data;

//       localStorage.setItem('token', token);
//       setUser({ email, role });
//       setIsAuthenticated(true);
//       return { token, role }; // Ensure this line returns both token and role
//     } catch (error) {
//       console.error('Login error:', error);
//       return null;
//     }
//   };

//   const logout = () => {
//     localStorage.removeItem('token');
//     setUser(null);
//     setIsAuthenticated(false);
//     setIsAccountExpireAlertVisible(false);
//   };

//   const handleAccountExpireAlertClose = () => {
//     logout();
//     navigate('/login');
//   };

//   return (
//     <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
//       {children}
//       <AccountExpireAlert visible={isAccountExpireAlertVisible} onClose={handleAccountExpireAlertClose} />
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };


