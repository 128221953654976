// src/components/admin/NotificationAlertMessage.tsx

import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FaCheckCircle, FaTimesCircle, FaTimes, FaExclamationTriangle } from 'react-icons/fa';

interface AlertProps {
  type: 'success' | 'error' | 'confirm' | 'warning' | 'check';
  message: string;
  visible: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const AdminNoticeAlertMessage: React.FC<AlertProps> = ({ type, message, visible, onClose, onConfirm, onCancel }) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (visible) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [visible]);

  if (!visible) return null;

  const alertStyles = {
    success: {
      container: 'bg-green-100 border-green-500 text-green-800',
      icon: <FaCheckCircle className="h-6 w-6 text-green-600" />,
    },
    error: {
      container: 'bg-red-100 border-red-500 text-red-800',
      icon: <FaTimesCircle className="h-6 w-6 text-red-600" />,
    },
    confirm: {
      container: 'bg-blue-100 border-blue-500 text-blue-800',
      icon: <FaCheckCircle className="h-6 w-6 text-blue-600" />,
    },
    warning: {
      container: 'bg-red-50 border-red-300 text-red-600',
      icon: <FaExclamationTriangle className="h-6 w-6 text-red-600" />,
    },
    check: {
      container: 'bg-yellow-50 border-yellow-300 text-yellow-600',
      icon: <FaCheckCircle className="h-6 w-6 text-yellow-600" />,
    },
  };

  const getButtonStyles = (alertType: string) => {
    switch (alertType) {
      case 'warning':
        return {
          cancel: 'border-2 border-red-300 text-red-500 hover:bg-red-50',
          confirm: 'bg-red-500 text-white hover:bg-red-600',
        };
      case 'check':
        return {
          cancel: 'border-2 border-yellow-300 text-yellow-500 hover:bg-yellow-50',
          confirm: 'bg-yellow-500 text-white hover:bg-yellow-600',
        };
      default:
        return {
          cancel: 'border-2 border-blue-400 text-blue-500 hover:bg-gray-300',
          confirm: 'bg-blue-400 text-white hover:bg-blue-600',
        };
    }
  };

  const buttonStyles = getButtonStyles(type);

  return (
    <Transition show={showAlert}>
      <Dialog className="relative z-10" onClose={onClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${alertStyles[type].container}`}>
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      {alertStyles[type].icon}
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: message }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {(type === 'confirm' || type === 'warning' || type === 'check') && (
                    <>
                      <button
                        onClick={onCancel}
                        className={`mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto ${buttonStyles.cancel}`}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={onConfirm}
                        className={`mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:mt-0 sm:w-auto ${buttonStyles.confirm}`}
                      >
                        Confirm
                      </button>
                    </>
                  )}
                  <button onClick={onClose} className="absolute top-2 right-2 hover:text-gray-600 focus:outline-none">
                    <FaTimes className="w-4 h-4" />
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AdminNoticeAlertMessage;

