// src/hooks/MiniApp/useEventAttend.tsx

import { useState } from 'react';
import { useEnv } from '../../context/EnvContext';

const useAttendEvent = () => {
    const { apiUrl } = useEnv();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
  
    const attendEvent = async (ticketId: string, eventTitle: string): Promise<void> => {
        setLoading(true);
        setError(null);
      
        try {
          const response = await fetch(`${apiUrl}/api/attend-event`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ticketId, eventTitle }),  // Include eventTitle
          });
      
          if (!response.ok) {
            throw new Error('Failed to update event status');
          }
        } catch (err) {
          setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
          setLoading(false);
        }
      };
      
    return { attendEvent, loading, error };
  };
  
  export default useAttendEvent;
  
