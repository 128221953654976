// src/hooks/AdminDashboard/useAdminNotification.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

interface Notification {
  ticketId: string;
  eventTitle: string;
  username: string;
  status: string;
  createdAt: string;
  isImportant: boolean;
  isRead: boolean;
  deleted: boolean; // Add this line
  eventCategory: string; // Add this line
}

interface TicketDetails extends Notification {
  email: string;
  appointmentDate: string;
  paymentMethod: string;
  amount: number;
  lotsize: number;
  accountId: string;
  accountPassword: string;
  accountServer: string;
  points: number;
  notes: string;
  receiptPath: string | null;
}

export const useAdminNotifications = () => {
  const { apiUrl } = useEnv();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [eventCategories, setEventCategories] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchNotifications = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}/api/admin/notifications`);
      if (!response.ok) {
        throw new Error('Failed to fetch notifications');
      }
      const data = await response.json();
      // Ensure that the data includes all required properties
      const processedNotifications: Notification[] = data.notifications.map((notification: any) => ({
        ...notification,
        eventCategory: notification.eventCategory || 'Unknown', // Provide a default value if missing
      }));
      setNotifications(processedNotifications);
      setEventCategories(data.eventCategories);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  }, [apiUrl]); 

  const fetchTicketDetails = useCallback(async (ticketId: string): Promise<TicketDetails> => {
    const response = await fetch(`${apiUrl}/api/admin/notification/${ticketId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch ticket details');
    }
    return response.json();
  }, [apiUrl]);

  const updateTicketStatus = useCallback(async (ticketId: string, status: 'approved' | 'rejected'): Promise<void> => {
    const response = await fetch(`${apiUrl}/api/admin/notification/${ticketId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status }),
    });
    if (!response.ok) {
      throw new Error('Failed to update ticket status');
    }
    await fetchNotifications(); // Refresh notifications after update
  }, [apiUrl, fetchNotifications]);

  const deleteNotification = useCallback(async (ticketId: string): Promise<void> => {
    const response = await fetch(`${apiUrl}/api/admin/notification/${ticketId}`, {
      method: 'DELETE',
    });
    if (!response.ok) {
      throw new Error('Failed to delete notification');
    }
    await fetchNotifications(); // Refresh notifications after delete
  }, [apiUrl, fetchNotifications]);

  const archiveNotification = useCallback(async (eventCategory: string): Promise<void> => {
    const response = await fetch(`${apiUrl}/api/admin/notification/archive/${eventCategory}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to archive notifications');
    }
    await fetchNotifications(); // Refresh notifications after archiving
  }, [apiUrl, fetchNotifications]);

  const refetchNotifications = useCallback(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return { 
    notifications, 
    eventCategories,
    loading, 
    error, 
    fetchTicketDetails, 
    updateTicketStatus,
    deleteNotification, 
    archiveNotification, 
    refetchNotifications
  };
};



// // udpate code --> 18 kjuly 2024
// // src/hooks/AdminDashboard/useAdminNotification.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface Notification {
//   ticketId: string;
//   eventTitle: string;
//   username: string;
//   status: string;
//   createdAt: string;
//   isImportant: boolean;
//   isRead: boolean;
//   deleted: boolean; // Add this line
// }

// interface TicketDetails extends Notification {
//   email: string;
//   appointmentDate: string;
//   paymentMethod: string;
//   amount: number;
//   lotsize: number;
//   accountId: string;
//   accountPassword: string;
//   accountServer: string;
//   points: number;
//   notes: string;
//   receiptPath: string | null;
// }

// export const useAdminNotifications = () => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [eventCategories, setEventCategories] = useState<string[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   const fetchNotifications = useCallback(async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/api/admin/notifications`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch notifications');
//       }
//       const data = await response.json();
//       setNotifications(data);
//       setError(null);
//     } catch (err) {
//       setError(err instanceof Error ? err.message : 'An error occurred');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const fetchTicketDetails = useCallback(async (ticketId: string): Promise<TicketDetails> => {
//     const response = await fetch(`${apiUrl}/api/admin/notification/${ticketId}`);
//     if (!response.ok) {
//       throw new Error('Failed to fetch ticket details');
//     }
//     return response.json();
//   }, [apiUrl]);

//   const updateTicketStatus = useCallback(async (ticketId: string, status: 'approved' | 'rejected'): Promise<void> => {
//     const response = await fetch(`${apiUrl}/api/admin/notification/${ticketId}`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ status }),
//     });
//     if (!response.ok) {
//       throw new Error('Failed to update ticket status');
//     }
//     await fetchNotifications(); // Refresh notifications after update
//   }, [apiUrl, fetchNotifications]);

//   const deleteNotification = useCallback(async (ticketId: string): Promise<void> => {
//     const response = await fetch(`${apiUrl}/api/admin/notification/${ticketId}`, {
//       method: 'DELETE',
//     });
//     if (!response.ok) {
//       throw new Error('Failed to delete notification');
//     }
//     await fetchNotifications(); // Refresh notifications after delete
//   }, [apiUrl, fetchNotifications]);

//   const archiveNotification = useCallback(async (eventCategory: string): Promise<void> => {
//     const response = await fetch(`${apiUrl}/api/admin/notification/archive/${eventCategory}`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
//     if (!response.ok) {
//       throw new Error('Failed to archive notifications');
//     }
//     await fetchNotifications(); // Refresh notifications after archiving
//   }, [apiUrl, fetchNotifications]);

//   const refetchNotifications = useCallback(() => {
//     fetchNotifications();
//   }, [fetchNotifications]);

//   useEffect(() => {
//     fetchNotifications();
//   }, [fetchNotifications]);

//   // Return the new function from the hook
//   return { 
//     notifications, 
//     loading, 
//     error, 
//     fetchTicketDetails, 
//     updateTicketStatus,
//     deleteNotification, 
//     archiveNotification, 
//     refetchNotifications
//   }
// };


// // update code --> 01 june 2024
// // src/hooks/AdminDashboard/useAdminNotification.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface Notification {
//   ticketId: string;
//   eventTitle: string;
//   username: string;
//   status: string;
//   createdAt: string;
//   isImportant: boolean;
//   isRead: boolean;
//   deleted: boolean; // Add this line
// }

// interface TicketDetails extends Notification {
//   email: string;
//   appointmentDate: string;
//   paymentMethod: string;
//   amount: number;
//   points: number;
//   notes: string;
//   receiptPath: string | null;
// }

// export const useAdminNotifications = () => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   const fetchNotifications = useCallback(async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/api/admin/notifications`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch notifications');
//       }
//       const data = await response.json();
//       setNotifications(data);
//       setError(null);
//     } catch (err) {
//       setError(err instanceof Error ? err.message : 'An error occurred');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const fetchTicketDetails = useCallback(async (ticketId: string): Promise<TicketDetails> => {
//     const response = await fetch(`${apiUrl}/api/admin/notification/${ticketId}`);
//     if (!response.ok) {
//       throw new Error('Failed to fetch ticket details');
//     }
//     return response.json();
//   }, [apiUrl]);

//   const updateTicketStatus = useCallback(async (ticketId: string, status: 'approved' | 'rejected'): Promise<void> => {
//     const response = await fetch(`${apiUrl}/api/admin/notification/${ticketId}`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ status }),
//     });
//     if (!response.ok) {
//       throw new Error('Failed to update ticket status');
//     }
//     await fetchNotifications(); // Refresh notifications after update
//   }, [apiUrl, fetchNotifications]);

//   const deleteNotification = useCallback(async (ticketId: string): Promise<void> => {
//     const response = await fetch(`${apiUrl}/api/admin/notification/${ticketId}`, {
//       method: 'DELETE',
//     });
//     if (!response.ok) {
//       throw new Error('Failed to delete notification');
//     }
//     await fetchNotifications(); // Refresh notifications after delete
//   }, [apiUrl, fetchNotifications]);

//   const refetchNotifications = useCallback(() => {
//     fetchNotifications();
//   }, [fetchNotifications]);

//   useEffect(() => {
//     fetchNotifications();
//   }, [fetchNotifications]);

//   return { notifications, loading, error, fetchTicketDetails, updateTicketStatus, deleteNotification, refetchNotifications };
// };