// src/hooks/AdminDashboard/useDataTable.tsx

 import { useState, useEffect, useCallback } from 'react';
 import { useEnv } from '../../context/EnvContext';
 
 export interface ClientDataProps {
  id: string;
  name: string;
  email: string;
  gender: string;
  phone: string;
  dob: string;
  country: string;
  address: string;
  telegramId?: string;
  discordId?: string;
  profileImage?: string;
  stages?: string; // This should be fetched from the stage
  badges?: string;
  programStats?: string;
  password?: string; // Make password optional
}
export interface ProgramDataProps {
  id: string;
  name: string;
  status: string;
  memberType: string;
  amount: string;
  currency: string;
  slot: string;
  redeemCounts: string;
  startDate: string;
  time: string;
  endDate: string;
  location: string;
}
 
 const useDataTable = () => {
   const [data, setData] = useState<ClientDataProps[]>([]);
   const [programData, setProgramData] = useState<ProgramDataProps[]>([]);
   const [loading, setLoading] = useState<boolean>(true);
   const [error, setError] = useState<string | null>(null);
   const { apiUrl } = useEnv();
 
   const fetchData = useCallback(async () => {
     try {
       const response = await fetch(`${apiUrl}/api/data/User`);
       if (!response.ok) {
         throw new Error('Network response was not ok');
       }
       const result = await response.json();
       setData(result);
     } catch (err) {
       setError('Error fetching data');
       console.error('Error details:', err);
     } finally {
       setLoading(false);
     }
   }, [apiUrl]);
 
   useEffect(() => {
     fetchData();
   }, [fetchData]);
 
   const deleteUsers = async (ids: string[]) => {
     try {
       const response = await fetch(`${apiUrl}/api/data/User`, {
         method: 'DELETE',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({ ids }),
       });
 
       if (!response.ok) {
         throw new Error('Network response was not ok');
       }
 
       await fetchData(); // Refresh the data after deletion
     } catch (err) {
       setError('Error deleting users');
       console.error('Error details:', err);
     }
   };
 
   const addUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
     try {
       const response = await fetch(`${apiUrl}/api/data/User`, {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify(newUser),
       });
   
       if (!response.ok) {
         throw new Error('Failed to add new user');
       }
   
       const addedUser = await response.json();
       setData(prevData => [...prevData, addedUser]);
       return addedUser;
     } catch (err) {
       setError('Error adding new user');
       console.error('Error details:', err);
       throw err;
     }
   };
 
   const updateUser = async (updatedUser: ClientDataProps) => {
     try {
       const response = await fetch(`${apiUrl}/api/data/User/${updatedUser.id}`, {
         method: 'PUT',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify(updatedUser),
       });
 
       if (!response.ok) {
         throw new Error('Failed to update user');
       }
 
       const result = await response.json();
       setData(prevData => prevData.map(user => user.id === updatedUser.id ? result : user));
       return result;
     } catch (err) {
       setError('Error updating user');
       console.error('Error details:', err);
       throw err;
     }
   };

   // Fetch Program Data
  const fetchProgramData = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/api/data/Program`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setProgramData(result);
    } catch (err) {
      setError('Error fetching data');
      console.error('Error details:', err);
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchProgramData();
  }, [fetchProgramData]);

  return { data, setData, programData, setProgramData, loading, error, deleteUsers, addUser, fetchData, fetchProgramData, updateUser };
};

export default useDataTable;
 

// // update code --> 01 july 2024
//  // src/hooks/AdminDashboard/useDataTable.tsx

//  import { useState, useEffect, useCallback } from 'react';
//  import { useEnv } from '../../context/EnvContext';
 
//  export interface ClientDataProps {
//    id: string;
//    name: string;
//    email: string;
//    gender: string;
//    phone: string;
//    dob: string;
//    country: string;
//    address: string;
//    password?: string; // Make password optional
//  }
 
//  const useDataTable = () => {
//    const [data, setData] = useState<ClientDataProps[]>([]);
//    const [loading, setLoading] = useState<boolean>(true);
//    const [error, setError] = useState<string | null>(null);
//    const { apiUrl } = useEnv();
 
//    const fetchData = useCallback(async () => {
//      try {
//        const response = await fetch(`${apiUrl}/api/data/User`);
//        if (!response.ok) {
//          throw new Error('Network response was not ok');
//        }
//        const result = await response.json();
//        setData(result);
//      } catch (err) {
//        setError('Error fetching data');
//        console.error('Error details:', err);
//      } finally {
//        setLoading(false);
//      }
//    }, [apiUrl]);
 
//    useEffect(() => {
//      fetchData();
//    }, [fetchData]);
 
//    const deleteUsers = async (ids: string[]) => {
//      try {
//        const response = await fetch(`${apiUrl}/api/data/User`, {
//          method: 'DELETE',
//          headers: {
//            'Content-Type': 'application/json',
//          },
//          body: JSON.stringify({ ids }),
//        });
 
//        if (!response.ok) {
//          throw new Error('Network response was not ok');
//        }
 
//        await fetchData(); // Refresh the data after deletion
//      } catch (err) {
//        setError('Error deleting users');
//        console.error('Error details:', err);
//      }
//    };
 
//    const addUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
//      try {
//        const response = await fetch(`${apiUrl}/api/data/User`, {
//          method: 'POST',
//          headers: {
//            'Content-Type': 'application/json',
//          },
//          body: JSON.stringify(newUser),
//        });
   
//        if (!response.ok) {
//          throw new Error('Failed to add new user');
//        }
   
//        const addedUser = await response.json();
//        setData(prevData => [...prevData, addedUser]);
//        return addedUser;
//      } catch (err) {
//        setError('Error adding new user');
//        console.error('Error details:', err);
//        throw err;
//      }
//    };
 
//    const updateUser = async (updatedUser: ClientDataProps) => {
//      try {
//        const response = await fetch(`${apiUrl}/api/data/User/${updatedUser.id}`, {
//          method: 'PUT',
//          headers: {
//            'Content-Type': 'application/json',
//          },
//          body: JSON.stringify(updatedUser),
//        });
 
//        if (!response.ok) {
//          throw new Error('Failed to update user');
//        }
 
//        const result = await response.json();
//        setData(prevData => prevData.map(user => user.id === updatedUser.id ? result : user));
//        return result;
//      } catch (err) {
//        setError('Error updating user');
//        console.error('Error details:', err);
//        throw err;
//      }
//    };
 
//    return { data, setData, loading, error, deleteUsers, addUser, fetchData, updateUser };
//  };
 
//  export default useDataTable;
 


// // udpate code --> 25 june 2024
// // src/hooks/AdminDashboard/useDataTable.tsx

// import { useState, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext'; 

// export interface ClientDataProps {
//   id: string;
//   name: string;
//   email: string;
//   gender: string;
//   phone: string;
//   dob: string;
//   country: string;
//   address: string;
// }

// const useDataTable = () => {
//   const [data, setData] = useState<ClientDataProps[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv(); // Access API URL from the environment context

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(`${apiUrl}/api/data/User`);
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         const result = await response.json();
//         setData(result);
//       } catch (err) {
//         setError('Error fetching data');
//         console.error('Error details:', err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [apiUrl]);

//   return { data, setData, loading, error };
// };

// export default useDataTable;