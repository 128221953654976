// src/components/admin/SubscriptionSetting/SubPreview.tsx

import React, { useEffect, useState } from 'react';
import { BsExclamationCircleFill } from "react-icons/bs";
import { useTheme } from '../../../context/ThemeContext';
import useSubSetting from '../../../hooks/AdminDashboard/useSubSetting';
import { useEnv } from '../../../context/EnvContext';
import useSubNotification from '../../../hooks/AdminDashboard/useSubNotification';
import SubNotificationModal from './SubNotificationTable';

const SubscriptionProductPreview: React.FC = () => {
  const { theme } = useTheme();
  const { apiUrl } = useEnv();
  const { products = [], loading, error, fetchProducts  } = useSubSetting();
  const { fetchNotifications, fetchStats } = useSubNotification();
  const [stats, setStats] = useState({ totalSubscribers: 0, activePlans: 0, expiredPlans: 0 });
  const selectedProduct = products.length > 0 ? { ...products[0], plans: products[0].plans || [] } : null;
  const placeholderImage = "https://via.placeholder.com/150"; 
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const getStats = async () => {
      try {
        const stats = await fetchStats();
        setStats(stats); // Ensure this sets the fetched stats
      } catch (error) {
        console.error('Failed to fetch stats', error);
      }
    };
  
    getStats();
  }, [fetchStats]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleStatsClick = () => {
    fetchNotifications();
    setShowModal(true);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const getProductImageUrl = (image: string | undefined) => {
    return image ? `${apiUrl}/uploads/forSub/${image}` : placeholderImage;
  };

  return (
    <div className="bg-card p-6 rounded border mb-6">
      <h3 className="font-semibold mb-4">Subscription Product List Card (Generalize)</h3>
      {selectedProduct ? (
        <div className="flex flex-row justify-evenly p-4 border rounded shadow-sm bg-white">
          <div className='flex justify-left'>
            <img
              src={getProductImageUrl(selectedProduct.image)}
              alt={selectedProduct.productName}
              className="w-[150px] h-auto rounded"
            />
            <div className="text-left mx-10">
              <h4 className="font-semibold">{selectedProduct.productName}</h4>
              <p className="text-muted-foreground">{selectedProduct.productDescription}</p>
            </div>
          </div>
            <div className="flex justify-end items-center mx-4">
              <span className="px-6 py-2 rounded-full text-white bg-cyan-800">
                more details
              </span>
            </div>
          </div>
      ) : (
        <div className="border-dashed border-2 border-muted p-6 rounded">
          <p className="text-center text-muted-foreground">No subscription products to preview</p>
        </div>
      )}

      {selectedProduct && (
        <div className="mt-4">
          <h3 className="font-semibold text-center text-xl mb-4">{selectedProduct.productName} - Subscription Product Detail</h3>
          <div className="p-10 border-4 border-dashed rounded-xl">
            {selectedProduct && selectedProduct.plans && selectedProduct.plans.length > 0 ? (
              <div className="flex flex-row justify-center gap-4 mt-4">
                {selectedProduct.plans.slice().reverse().map((plan, index) => (
                  <div key={index} className="text-center p-4 border rounded shadow-sm flex flex-col justify-between">
                    <div>
                      <h4 className="font-semibold">{plan.planName}</h4>
                      <p className="text-muted-foreground">{plan.planDescription}</p>
                      <ul className="list-disc list-inside">
                        {plan.planFeatures.map((planFeature, i) => (
                          <li key={i}>{planFeature}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="">
                      <div>
                        <p className="flex justify-center items-center text-sm mt-4">
                          Trial info here
                          <button>
                            <BsExclamationCircleFill className="ml-2" style={{ color:theme.primaryColor }}/>
                          </button>
                        </p>
                        <p className="font-semibold mt-0">
                          {plan.planAmount} {plan.planCurrency} / 
                          {Number(plan.planPeriod) === 30 ? 'Monthly' : Number(plan.planPeriod) === 365 ? 'Annually' : `${plan.planPeriod} Days`}
                        </p>
                      </div>
                      <div className="px-4">
                        {Number(plan.planPeriod) === 30 && (
                          <p className="text-rose-600 bg-gray-200 text-xs py-1 rounded-full">
                            Yearly Total: {(Number(plan.planAmount) * 12).toFixed(2)} {plan.planCurrency}
                          </p>
                        )}
                        {Number(plan.planPeriod) === 365 && (
                          <p className="text-rose-600 bg-gray-200 text-xs py-1 rounded-full">
                            {(() => {
                              const monthlyPlan = selectedProduct.plans.find(p => Number(p.planPeriod) === 30);
                              if (monthlyPlan) {
                                const monthlyCost = Number(monthlyPlan.planAmount);
                                const yearlyCost = Number(plan.planAmount)* 12;
                                const yearlyCostIfMonthly = monthlyCost * 12;
                                const savings = yearlyCostIfMonthly - yearlyCost;
                                
                                return `Yearly Save: $${savings.toFixed(2)} ${plan.planCurrency} from monthly plan`;
                              }
                              return 'No monthly plan available for comparison';
                            })()}
                          </p>
                        )}
                      </div>
                      <div className="text-center mt-4">
                        <span className="px-4 py-2 bg-blue-500 text-white rounded"
                          style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}>
                          Subscribe
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-muted-foreground">No plans available for this product.</p>
            )}
          </div>
        </div>
      )}
      <div className="mt-4">
        <div className="flex justify-center items-center">
          <h3 className="font-semibold text-xl">Stats</h3>
          <p className="text-rose-600 text-xs ml-2">( click the stats box to open the table )</p>
        </div>
        <div 
          className="p-4 border rounded shadow-sm flex justify-around" 
          onClick={handleStatsClick}
        >
          <div className="text-center">
              <p className="font-semibold text-lg">{stats.totalSubscribers}</p>
              <p className="text-muted-foreground">Subscribers</p>
          </div>
          <div className="text-center">
              <p className="font-semibold text-lg">{stats.activePlans}</p>
              <p className="text-muted-foreground">Active Plans</p>
          </div>
          <div className="text-center">
              <p className="font-semibold text-lg">{stats.expiredPlans}</p>
              <p className="text-muted-foreground">Expired Plans</p>
          </div>
      </div>
      </div>
      {showModal && (
        <SubNotificationModal onClose={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default SubscriptionProductPreview;


// // update code --> 30 Aug 2024
// // src/components/admin/SubscriptionSetting/SubPreview.tsx

// import React, { useEffect, useState } from 'react';
// import { BsExclamationCircleFill } from "react-icons/bs";
// import { useTheme } from '../../../context/ThemeContext';
// import useSubSetting from '../../../hooks/AdminDashboard/useSubSetting';
// import { useEnv } from '../../../context/EnvContext';
// import useSubNotification from '../../../hooks/AdminDashboard/useSubNotification';
// import SubNotificationModal from './SubNotificationTable';

// const SubscriptionProductPreview: React.FC = () => {
//   const { theme } = useTheme();
//   const { apiUrl } = useEnv();
//   const { products = [], loading, error, fetchProducts  } = useSubSetting();
//   const { fetchNotifications, fetchStats } = useSubNotification();
//   const [stats, setStats] = useState({ totalSubscribers: 0, activePlans: 0, expiredPlans: 0 });
//   const selectedProduct = products.length > 0 ? { ...products[0], plans: products[0].plans || [] } : null;
//   const placeholderImage = "https://via.placeholder.com/150"; 
//   const [showModal, setShowModal] = useState(false);

//   useEffect(() => {
//     fetchProducts();
//   }, [fetchProducts]);

//   useEffect(() => {
//     const getStats = async () => {
//       try {
//         const data = await fetchStats();
//         setStats(data);
//       } catch (error) {
//         console.error('Failed to fetch stats', error);
//       }
//     };
  
//     getStats();
//   }, [fetchStats]);

//   const handleStatsClick = () => {
//     fetchNotifications();
//     setShowModal(true);
//   };

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   const getProductImageUrl = (image: string | undefined) => {
//     return image ? `${apiUrl}/uploads/forSub/${image}` : placeholderImage;
//   };

//   return (
//     <div className="bg-card p-6 rounded shadow mb-6">
//       <h3 className="font-semibold mb-4">Subscription Product List Card (Generalize)</h3>
//       {selectedProduct ? (
//         <div className="flex flex-row p-4 border rounded shadow-sm bg-white">
//           <div className='flex justify-left'>
//             <img
//               src={getProductImageUrl(selectedProduct.image)}
//               alt={selectedProduct.productName}
//               className="w-[150px] h-auto rounded"
//             />
//             <div className="text-left ml-4">
//               <h4 className="font-semibold">{selectedProduct.productName}</h4>
//               <p className="text-muted-foreground">{selectedProduct.productDescription}</p>
//             </div>
//           </div>
//             <div className="flex justify-end items-center">
//               <span className="px-4 py-2 ml-[250px] rounded-full" 
//                 style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}>
//                 more details
//               </span>
//             </div>
//           </div>
//       ) : (
//         <div className="border-dashed border-2 border-muted p-6 rounded">
//           <p className="text-center text-muted-foreground">No subscription products to preview</p>
//         </div>
//       )}

//       {selectedProduct && (
//         <div className="mt-4">
//           <h3 className="font-semibold text-center text-xl mb-4">{selectedProduct.productName} - Subscription Product Detail</h3>
//           <div className="p-10 border-4 border-dashed rounded-xl">
//             {selectedProduct && selectedProduct.plans && selectedProduct.plans.length > 0 ? (
//               <div className="flex flex-row justify-center gap-4 mt-4">
//                 {selectedProduct.plans.slice().reverse().map((plan, index) => (
//                   <div key={index} className="text-center p-4 border rounded shadow-sm flex flex-col justify-between">
//                     <div>
//                       <h4 className="font-semibold">{plan.planName}</h4>
//                       <p className="text-muted-foreground">{plan.planDescription}</p>
//                       <ul className="list-disc list-inside">
//                         {plan.planFeatures.map((planFeature, i) => (
//                           <li key={i}>{planFeature}</li>
//                         ))}
//                       </ul>
//                     </div>
//                     <div className="">
//                       <div>
//                         <p className="flex justify-center items-center text-sm mt-4">
//                           Trial info here
//                           <button>
//                             <BsExclamationCircleFill className="ml-2" style={{ color:theme.primaryColor }}/>
//                           </button>
//                         </p>
//                         <p className="font-semibold mt-0">
//                           {plan.planAmount} {plan.planCurrency} / 
//                           {Number(plan.planPeriod) === 30 ? 'Monthly' : Number(plan.planPeriod) === 365 ? 'Annually' : `${plan.planPeriod} Days`}
//                         </p>
//                       </div>
//                       <div className="px-4">
//                         {Number(plan.planPeriod) === 30 && (
//                           <p className="text-rose-600 bg-gray-200 text-xs py-1 rounded-full">
//                             Yearly Total: {(Number(plan.planAmount) * 12).toFixed(2)} {plan.planCurrency}
//                           </p>
//                         )}
//                         {Number(plan.planPeriod) === 365 && (
//                           <p className="text-rose-600 bg-gray-200 text-xs py-1 rounded-full">
//                             {(() => {
//                               const monthlyPlan = selectedProduct.plans.find(p => Number(p.planPeriod) === 30);
//                               if (monthlyPlan) {
//                                 const monthlyCost = Number(monthlyPlan.planAmount);
//                                 const yearlyCost = Number(plan.planAmount)* 12;
//                                 const yearlyCostIfMonthly = monthlyCost * 12;
//                                 const savings = yearlyCostIfMonthly - yearlyCost;
                                
//                                 return `Yearly Save: $${savings.toFixed(2)} ${plan.planCurrency} from monthly plan`;
//                               }
//                               return 'No monthly plan available for comparison';
//                             })()}
//                           </p>
//                         )}
//                       </div>
//                       <div className="text-center mt-4">
//                         <span className="px-4 py-2 bg-blue-500 text-white rounded"
//                           style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}>
//                           Subscribe
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             ) : (
//               <p className="text-muted-foreground">No plans available for this product.</p>
//             )}
//           </div>
//         </div>
//       )}
//       <div className="mt-4">
//         <div className="flex justify-center items-center">
//           <h3 className="font-semibold text-xl">Stats</h3>
//           <p className="text-rose-600 text-xs ml-2">( click the stats box to open the table )</p>
//         </div>
//         <div 
//           className="p-4 border rounded shadow-sm flex justify-around" 
//           onClick={handleStatsClick}
//         >
//           <div className="text-center">
//               <p className="font-semibold text-lg">{stats.totalSubscribers}</p>
//               <p className="text-muted-foreground">Subscribers</p>
//           </div>
//           <div className="text-center">
//               <p className="font-semibold text-lg">{stats.activePlans}</p>
//               <p className="text-muted-foreground">Active Plans</p>
//           </div>
//           <div className="text-center">
//               <p className="font-semibold text-lg">{stats.expiredPlans}</p>
//               <p className="text-muted-foreground">Expired Plans</p>
//           </div>
//       </div>
//       </div>
//       {showModal && (
//         <SubNotificationModal onClose={() => setShowModal(false)} />
//       )}
//     </div>
//   );
// };

// export default SubscriptionProductPreview;

// // update code --> 18 Aug 2024
// // src/components/admin/SubscriptionSetting/SubPreview.tsx

// import React, { useEffect, useState } from 'react';
// import { BsExclamationCircleFill, BsTable } from "react-icons/bs";
// import { useTheme } from '../../../context/ThemeContext';
// import useSubSetting from '../../../hooks/AdminDashboard/useSubSetting';
// import { useEnv } from '../../../context/EnvContext';
// import useSubNotification from '../../../hooks/AdminDashboard/useSubNotification';
// import SubNotificationModal from './SubNotificationTable';

// const SubscriptionProductPreview: React.FC = () => {
//   const { theme } = useTheme();
//   const { apiUrl } = useEnv();
//   const { products = [], loading, error, fetchProducts  } = useSubSetting();
//   const { fetchNotifications, fetchStats } = useSubNotification();
//   const [stats, setStats] = useState({ totalSubscribers: 0, activePlans: 0, expiredPlans: 0 });
//   const selectedProduct = products.length > 0 ? { ...products[0], plans: products[0].plans || [] } : null;
//   const placeholderImage = "https://via.placeholder.com/150"; 
//   const [showModal, setShowModal] = useState(false);

//   useEffect(() => {
//     fetchProducts();
//   }, [fetchProducts]);

//   useEffect(() => {
//     const getStats = async () => {
//       try {
//         const data = await fetchStats();
//         setStats(data);
//       } catch (error) {
//         console.error('Failed to fetch stats', error);
//       }
//     };
  
//     getStats();
//   }, [fetchStats]);

//   const handleTableIconClick = () => {
//     fetchNotifications();
//     setShowModal(true);
//   };

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   const getProductImageUrl = (image: string | undefined) => {
//     return image ? `${apiUrl}/uploads/forSub/${image}` : placeholderImage;
//   };

//   return (
//     <div className="bg-card p-6 rounded shadow mb-6">
//       <h3 className="font-semibold mb-4">Subscription Product List Card (Generalize)</h3>
//       {selectedProduct ? (
//         <div className="flex flex-row p-4 border rounded shadow-sm bg-white">
//           <div className='flex justify-left'>
//             <img
//               src={getProductImageUrl(selectedProduct.image)}
//               alt={selectedProduct.productName}
//               className="w-[150px] h-auto rounded"
//             />
//             <div className="text-left ml-4">
//               <h4 className="font-semibold">{selectedProduct.productName}</h4>
//               <p className="text-muted-foreground">{selectedProduct.productDescription}</p>
//             </div>
//           </div>
//             <div className="flex justify-end items-center">
//               <span className="px-4 py-2 ml-[250px] rounded-full" 
//                 style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}>
//                 more details
//               </span>
//             </div>
//           </div>
//       ) : (
//         <div className="border-dashed border-2 border-muted p-6 rounded">
//           <p className="text-center text-muted-foreground">No subscription products to preview</p>
//         </div>
//       )}

//       {selectedProduct && (
//         <div className="mt-4">
//           <h3 className="font-semibold text-center text-xl mb-4">{selectedProduct.productName} - Subscription Product Detail</h3>
//           <div className="p-10 border-4 border-dashed rounded-xl">
//             {selectedProduct && selectedProduct.plans && selectedProduct.plans.length > 0 ? (
//               <div className="flex flex-row justify-center gap-4 mt-4">
//                 {selectedProduct.plans.map((plan, index) => (
//                   <div key={index} className="text-center p-4 border rounded shadow-sm flex flex-col justify-between">
//                     <div>
//                       <h4 className="font-semibold">{plan.planName}</h4>
//                       <p className="text-muted-foreground">{plan.planDescription}</p>
//                       <ul className="list-disc list-inside">
//                         {plan.planFeatures.map((planFeature, i) => (
//                           <li key={i}>{planFeature}</li>
//                         ))}
//                       </ul>
//                     </div>
//                     <div className="">
//                       <div>
//                         <p className="flex justify-center items-center text-sm mt-4">
//                           Trial info here
//                           <button>
//                             <BsExclamationCircleFill className="ml-2" style={{ color:theme.primaryColor }}/>
//                           </button>
//                         </p>
//                         <p className="font-semibold mt-0 mb-4">
//                           {plan.planAmount} {plan.planCurrency} / {plan.planPeriod} Days
//                         </p>
//                       </div>
//                       <div className="text-center">
//                         <span className="px-4 py-2 bg-blue-500 text-white rounded"
//                           style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}>
//                           Subscribe
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             ) : (
//               <p className="text-muted-foreground">No plans available for this product.</p>
//             )}
//           </div>
//         </div>
//       )}
//       <div className="mt-4">
//         <div className="flex justify-center">
//           <div className="flex flex-row justify-normal items-center">
//             <h3 className="font-semibold text-xl">Stats</h3>
//             <div className="ml-2">
//               <BsTable className="text-lg cursor-pointer" onClick={handleTableIconClick} />
//             </div>
//           </div>
//         </div>
//         <div className="p-4 border rounded shadow-sm flex justify-around">
//           <div className="text-center">
//               <p className="font-semibold text-lg">{stats.totalSubscribers}</p>
//               <p className="text-muted-foreground">Subscribers</p>
//           </div>
//           <div className="text-center">
//               <p className="font-semibold text-lg">{stats.activePlans}</p>
//               <p className="text-muted-foreground">Active Plans</p>
//           </div>
//           <div className="text-center">
//               <p className="font-semibold text-lg">{stats.expiredPlans}</p>
//               <p className="text-muted-foreground">Expired Plans</p>
//           </div>
//       </div>
//       </div>
//       {showModal && (
//         <SubNotificationModal onClose={() => setShowModal(false)} />
//       )}
//     </div>
//   );
// };

// export default SubscriptionProductPreview;

// // udpate code --> 12 Aug 2024
// // src/components/admin/SubscriptionSetting/SubPreview.tsx

// import React, { useEffect, useState } from 'react';
// import { BsExclamationCircleFill, BsTable } from "react-icons/bs";
// import { useTheme } from '../../../context/ThemeContext';
// import useSubSetting from '../../../hooks/AdminDashboard/useSubSetting';
// import { useEnv } from '../../../context/EnvContext';
// import useSubNotification from '../../../hooks/AdminDashboard/useSubNotification';
// import SubNotificationModal from './SubNotificationTable';

// const SubscriptionProductPreview: React.FC = () => {
//   const { theme } = useTheme();
//   const { apiUrl } = useEnv();
//   const { products = [], loading, error, fetchProducts  } = useSubSetting();
//   const { fetchNotifications } = useSubNotification();
//   const selectedProduct = products.length > 0 ? { ...products[0], plans: products[0].plans || [] } : null;
//   const placeholderImage = "https://via.placeholder.com/150"; 
//   const [showModal, setShowModal] = useState(false);

//   useEffect(() => {
//     fetchProducts();
//   }, [fetchProducts]);

//   const handleTableIconClick = () => {
//     fetchNotifications();
//     setShowModal(true);
//   };

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   const getProductImageUrl = (image: string | undefined) => {
//     return image ? `${apiUrl}/uploads/forSub/${image}` : placeholderImage;
//   };

//   return (
//     <div className="bg-card p-6 rounded shadow mb-6">
//       <h3 className="font-semibold mb-4">Subscription Product List Card (Generalize)</h3>
//       {selectedProduct ? (
//         <div className="flex flex-row p-4 border rounded shadow-sm bg-white">
//           <div className='flex justify-left'>
//             <img
//               src={getProductImageUrl(selectedProduct.image)}
//               alt={selectedProduct.productName}
//               className="w-[150px] h-auto rounded"
//             />
//             <div className="text-left ml-4">
//               <h4 className="font-semibold">{selectedProduct.productName}</h4>
//               <p className="text-muted-foreground">{selectedProduct.productDescription}</p>
//             </div>
//           </div>
//             <div className="flex justify-end items-center">
//               <span className="px-4 py-2 ml-[250px] rounded-full" 
//                 style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}>
//                 more details
//               </span>
//             </div>
//           </div>
//       ) : (
//         <div className="border-dashed border-2 border-muted p-6 rounded">
//           <p className="text-center text-muted-foreground">No subscription products to preview</p>
//         </div>
//       )}

//       {selectedProduct && (
//         <div className="mt-4">
//           <h3 className="font-semibold text-center text-xl mb-4">{selectedProduct.productName} - Subscription Product Detail</h3>
//           <div className="p-10 border-4 border-dashed rounded-xl">
//             {selectedProduct && selectedProduct.plans && selectedProduct.plans.length > 0 ? (
//               <div className="flex flex-row justify-center gap-4 mt-4">
//                 {selectedProduct.plans.map((plan, index) => (
//                   <div key={index} className="text-center p-4 border rounded shadow-sm flex flex-col justify-between">
//                     <div>
//                       <h4 className="font-semibold">{plan.planName}</h4>
//                       <p className="text-muted-foreground">{plan.planDescription}</p>
//                       <ul className="list-disc list-inside">
//                         {plan.planFeatures.map((planFeature, i) => (
//                           <li key={i}>{planFeature}</li>
//                         ))}
//                       </ul>
//                     </div>
//                     <div className="">
//                       <div>
//                         <p className="flex justify-center items-center text-sm mt-4">
//                           Trial info here
//                           <button>
//                             <BsExclamationCircleFill className="ml-2" style={{ color:theme.primaryColor }}/>
//                           </button>
//                         </p>
//                         <p className="font-semibold mt-0 mb-4">
//                           {plan.planAmount} {plan.planCurrency} / {plan.planPeriod} Days
//                         </p>
//                       </div>
//                       <div className="text-center">
//                         <span className="px-4 py-2 bg-blue-500 text-white rounded"
//                           style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}>
//                           Subscribe
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             ) : (
//               <p className="text-muted-foreground">No plans available for this product.</p>
//             )}
//           </div>
//         </div>
//       )}
//       <div className="mt-4">
//         <div className="flex justify-center">
//           <div className="flex flex-row justify-normal items-center">
//             <h3 className="font-semibold text-xl">Stats</h3>
//             <div className="ml-2">
//               <BsTable className="text-lg cursor-pointer" onClick={handleTableIconClick} />
//             </div>
//           </div>
//         </div>
//         <div className="p-4 border rounded shadow-sm flex justify-around">
//           <div className="text-center">
//             <p className="font-semibold text-lg">0</p>
//             <p className="text-muted-foreground">Subscribers</p>
//           </div>
//           <div className="text-center">
//             <p className="font-semibold text-lg">0</p>
//             <p className="text-muted-foreground">Active Plans</p>
//           </div>
//           <div className="text-center">
//             <p className="font-semibold text-lg">0</p>
//             <p className="text-muted-foreground">Expired Plans</p>
//           </div>
//         </div>
//       </div>
//       {showModal && (
//         <SubNotificationModal onClose={() => setShowModal(false)} />
//       )}
//     </div>
//   );
// };

// export default SubscriptionProductPreview;






// // udpate code --> 30 july 2024 
// // src/components/admin/SubscriptionSetting/SubPreview.tsx

// import React, { useEffect } from 'react';
// import { BsExclamationCircleFill } from "react-icons/bs";
// import { useTheme } from '../../../context/ThemeContext';
// import useSubSetting from '../../../hooks/AdminDashboard/useSubSetting';
// import { useEnv } from '../../../context/EnvContext';

// const SubscriptionProductPreview: React.FC = () => {
//   const { theme } = useTheme();
//   const { apiUrl } = useEnv();
//   const { products = [], loading, error, fetchProducts  } = useSubSetting();
//   const selectedProduct = products.length > 0 ? { ...products[0], plans: products[0].plans || [] } : null;
//   const placeholderImage = "https://via.placeholder.com/150"; 

//   useEffect(() => {
//     fetchProducts();
//   }, [fetchProducts]);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   const getProductImageUrl = (image: string | undefined) => {
//     return image ? `${apiUrl}/uploads/forSub/${image}` : placeholderImage;
//   };

//   return (
//     <div className="bg-card p-6 rounded shadow mb-6">
//       <h3 className="font-semibold mb-4">Subscription Product List Card (Generalize)</h3>
//       {selectedProduct ? (
//         <div className="flex flex-row p-4 border rounded shadow-sm bg-white">
//           <div className='flex justify-left'>
//             <img
//               src={getProductImageUrl(selectedProduct.image)}
//               alt={selectedProduct.productName}
//               className="w-[150px] h-auto rounded"
//             />
//             <div className="text-left ml-4">
//               <h4 className="font-semibold">{selectedProduct.productName}</h4>
//               <p className="text-muted-foreground">{selectedProduct.productDescription}</p>
//             </div>
//           </div>
//             <div className="flex justify-end items-center">
//               <span className="px-4 py-2 ml-[250px] rounded-full" 
//                 style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}>
//                 more details
//               </span>
//             </div>
//           </div>
//       ) : (
//         <div className="border-dashed border-2 border-muted p-6 rounded">
//           <p className="text-center text-muted-foreground">No subscription products to preview</p>
//         </div>
//       )}

//       {selectedProduct && (
//         <div className="mt-4">
//           <h3 className="font-semibold text-center text-xl mb-4">{selectedProduct.productName} - Subscription Product Detail</h3>
//           <div className="p-10 border-4 border-dashed rounded-xl">
//             {selectedProduct && selectedProduct.plans && selectedProduct.plans.length > 0 ? (
//               <div className="flex flex-row justify-center gap-4 mt-4">
//                 {selectedProduct.plans.map((plan, index) => (
//                   <div key={index} className="text-center p-4 border rounded shadow-sm flex flex-col justify-between">
//                     <div>
//                       <h4 className="font-semibold">{plan.planName}</h4>
//                       <p className="text-muted-foreground">{plan.planDescription}</p>
//                       <ul className="list-disc list-inside">
//                         {plan.planFeatures.map((planFeature, i) => (
//                           <li key={i}>{planFeature}</li>
//                         ))}
//                       </ul>
//                     </div>
//                     <div className="">
//                       <div>
//                         <p className="flex justify-center items-center text-sm mt-4">
//                           Trial info here
//                           <button>
//                             <BsExclamationCircleFill className="ml-2" style={{ color:theme.primaryColor }}/>
//                           </button>
//                         </p>
//                         <p className="font-semibold mt-0 mb-4">
//                           {plan.planAmount} {plan.planCurrency} / {plan.planPeriod} Days
//                         </p>
//                       </div>
//                       <div className="text-center">
//                         <span className="px-4 py-2 bg-blue-500 text-white rounded"
//                           style={{ color:theme.text2Color, backgroundColor:theme.primaryColor }}>
//                           Subscribe
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             ) : (
//               <p className="text-muted-foreground">No plans available for this product.</p>
//             )}
//           </div>
//         </div>
//       )}
//       <div className="mt-4">
//         <h3 className="font-semibold text-center text-xl mb-4">Stats</h3>
//         <div className="p-4 border rounded shadow-sm flex justify-around">
//           <div className="text-center">
//             <p className="font-semibold text-lg">0</p>
//             <p className="text-muted-foreground">Subscribers</p>
//           </div>
//           <div className="text-center">
//             <p className="font-semibold text-lg">0</p>
//             <p className="text-muted-foreground">Active Plans</p>
//           </div>
//           <div className="text-center">
//             <p className="font-semibold text-lg">0</p>
//             <p className="text-muted-foreground">Expired Plans</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SubscriptionProductPreview;
