// src/hooks/Dashboard/useUserProgramCalendar.tsx

import { useEffect, useState } from 'react';
import { useEnv } from '../../context/EnvContext';

export interface ProgramEvent {
  id: number;
  name: string;
  amount: number;
  currency: string;
  lotSize: number;      // Add this line
  deposit: number;      // Add this line
  broker: string;
  location: string;
  startDate: string;
  endDate: string;
  time: string;
  points: number;
  description: string;
  redeemNumber: number;
  slot: number;
  status: 'running' | 'stopped' | 'expired';
  MemberType: string; 
  image: string | null;
}

export interface ApprovedEvent {
  id: number;
  eventTitle: string;
  email: string;
  status: 'pending' | 'approved' | 'rejected';
  eventStatus: 'Not Check-in' | 'Attend';
  ticketId: string;
  username: string;
  appointmentDate: string;
  paymentMethod: string;
  amount: number;
  points: number;
  receiptPath: string | null;
  termsAccepted: string;
  notes: string | null;
}

const useProgramCalendar = (email: string) => {
  const [programEvents, setProgramEvents] = useState<ProgramEvent[]>([]);
  const [approvedEvents, setApprovedEvents] = useState<ApprovedEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const { apiUrl } = useEnv();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const [programRes, approvedRes] = await Promise.all([
          fetch(`${apiUrl}/api/program-events`).then(res => res.json()),
          fetch(`${apiUrl}/api/approved-events?email=${encodeURIComponent(email)}`).then(res => res.json()),
        ]);

        // Filter out events with status 'stopped'
        const activeProgramEvents = programRes.filter((event: ProgramEvent) => event.status !== 'stopped');

        setProgramEvents(activeProgramEvents);
        setApprovedEvents(approvedRes);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [email, apiUrl]);

  const getStatusStyle = (status: 'running' | 'stopped' | 'expired') => {
    switch (status) {
      case 'running':
        return { backgroundColor: '#36BA98', color: 'white', label: 'Running' };
      case 'expired':
        return { backgroundColor: '#DA7297', color: 'white', label: 'Expired' };
      default:
        return { backgroundColor: 'gray', color: 'white', label: 'Stopped' };
    }
  };

  return { programEvents, approvedEvents, loading, getStatusStyle };
};

export default useProgramCalendar;

