// src/components/admin/admin03_Marketing.tsx

import React from 'react';
import { useEmail } from '../../hooks/AdminDashboard/useEmailSetting';
import OverviewStats from './MarketSetting/OverviewStats';
import CampaignSetting from './MarketSetting/CampingSetting';

const EmailMarketing: React.FC = () => {
  const { email: campaigns } = useEmail();

  return (
    <div className="mx-10 p-4">
      <h1 className="text-2xl font-semibold mb-4">Email Marketing</h1>

      {/* Overview Section */}
      <div className="w-full overflow-x-auto">
      <OverviewStats
        totalCampaigns={campaigns.length}
        scheduledCampaigns={0}
        sentCampaigns={0}
      />

      {/* Campaign Setting Section */}
      <CampaignSetting />
      </div>
    </div>
  );
};

export default EmailMarketing;

