// src/context/SubRegTermContext.tsx

import React, { createContext, useContext, ReactNode } from 'react';

const termsAndConditions = (
  <div>
    <h1><strong>1. Introduction</strong></h1><br />
    <p>Welcome to R One Century Subscription Services. These terms and conditions ("Terms") govern your subscription and trial usage of the products and services provided by R One Century ("Provider"). By subscribing or starting a trial, you agree to comply with and be bound by these Terms. 
       If you do not agree with any part of these Terms, please do not subscribe or use the trial services.</p><br />

    <h1><strong>2. Subscription</strong></h1><br />
    <h2><strong>2.1. Eligibility</strong></h2>
    <p>Subscription is open to individuals who are 18 years old or older. By subscribing, you confirm that you meet the eligibility requirements.</p><br />

    <h2><strong>2.2. Subscription Process</strong></h2>
    <p>To subscribe to our services, you must complete the online subscription form and provide accurate, current, and complete information. The Provider reserves the right to accept or reject any subscription at its sole discretion.</p><br />

    <h2><strong>2.3. Fees</strong></h2>
    <p>Subscription fees must be paid in full at the time of subscription. All fees are non-refundable unless otherwise stated in these Terms or required by applicable law.</p><br />

    <h2><strong>2.4. Confirmation</strong></h2>
    <p>Upon successful subscription and payment, you will receive a confirmation email. This confirmation does not guarantee continued access if the Provider later determines that you do not meet the eligibility criteria or have violated these Terms.</p><br />

    <h1><strong>3. Trial Usage</strong></h1><br />
    <h2><strong>3.1. Trial Period</strong></h2>
    <p>Trial periods are offered at the discretion of the Provider and may vary in length. During the trial period, you will have access to the subscription services without charge.</p><br />

    <h2><strong>3.2. Limitations</strong></h2>
    <p>Trial access may be limited in scope and functionality compared to a full subscription. The Provider reserves the right to modify or terminate the trial at any time without notice.</p><br />

    <h2><strong>3.3. Conversion to Subscription</strong></h2>
    <p>If you wish to continue using the services after the trial period, you must subscribe and pay the applicable fees. Your subscription will begin immediately after the trial period ends.</p><br />

    <h1><strong>4. Usage and Conduct</strong></h1><br />
    <h2><strong>4.1. Acceptable Use</strong></h2>
    <p>Subscribers and trial users must use the services in a respectful and lawful manner. The Provider reserves the right to suspend or terminate access for any behavior deemed inappropriate, disruptive, or harmful.</p><br />

    <h2><strong>4.2. Changes and Cancellations</strong></h2>
    <p>The Provider reserves the right to make changes to the subscription services. In the event of cancellation of services, subscribers will be notified as soon as possible, and any unused portion of fees may be refunded at the Provider's discretion.</p><br />

    <h1><strong>5. Liability</strong></h1><br />
    <h2><strong>5.1. Assumption of Risk</strong></h2>
    <p>Use of the subscription services is at your own risk. The Provider is not responsible for any loss, injury, or damage to persons or property arising from your use of the services.</p><br />

    <h2><strong>5.2. Limitation of Liability</strong></h2>
    <p>To the fullest extent permitted by law, the Provider's liability for any claim arising out of or in connection with these Terms or the services will be limited to the amount of the subscription fee paid by you.</p><br />

    <h1><strong>6. Privacy</strong></h1><br />
    <h2><strong>6.1. Data Collection</strong></h2>
    <p>The Provider collects personal information during the subscription and trial registration process. This information will be used in accordance with the Provider's Privacy Policy.</p><br />

    <h2><strong>6.2. Third-Party Sharing</strong></h2>
    <p>Your information may be shared with third-party service providers for the purpose of managing and delivering the subscription services.</p><br />

    <h1><strong>7. Governing Law</strong></h1>
    <p>These Terms are governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with these Terms will be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</p><br />

    <h1><strong>8. Amendments</strong></h1>
    <p>The Provider reserves the right to modify these Terms at any time. Any changes will be posted on the Provider's website and will become effective upon posting.</p><br />

    <h1><strong>9. Contact Information</strong></h1>
    <p>If you have any questions about these Terms, please contact [Provider Contact Information].</p><br />

    <p><strong>By subscribing or starting a trial, you acknowledge that you have read, understood, and agree to these Terms and Conditions.</strong></p>
  </div>
);

interface EventRegTermProviderProps {
  children: ReactNode;
}

const EventRegTermContext = createContext<JSX.Element>(termsAndConditions);

export const EventRegTermProvider: React.FC<EventRegTermProviderProps> = ({ children }) => {
  return (
    <EventRegTermContext.Provider value={termsAndConditions}>
      {children}
    </EventRegTermContext.Provider>
  );
};

export const useEventRegTerms = () => useContext(EventRegTermContext);
