// src/components/admin/admin02_ClientData.tsx

import React, { useState } from 'react';
import { FaEdit, FaTrash, FaFilter, FaPlus, FaSort, FaSync } from 'react-icons/fa';
import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";
import { useTheme } from '../../context/ThemeContext';
import UserDetailModal from './ClientData/UserDetailModal';
import useDataTable, { ClientDataProps, ProgramDataProps } from '../../hooks/AdminDashboard/useDataTable';
import { filterData } from './ClientData/UserDetailFilter';
import femaleAvatar from '../../assets/usrDashboard/avatar_female.png';
import maleAvatar from '../../assets/usrDashboard/avatar_male.png';

const ClientData: React.FC = () => {
  const { theme } = useTheme();
  const { data, setData, deleteUsers, addUser, fetchData, fetchProgramData, programData } = useDataTable();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<ClientDataProps | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTable, setSelectedTable] = useState('User'); // New state to track selected table
  const filteredData = selectedTable === 'User' ? filterData(data, searchTerm) : programData;
  const maxPage = 5; // Example maximum page, adjust according to your pagination logic
  
  const initialData: ClientDataProps[] = [...data]; // Store initial data for resetting

  const handleTableChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTable(e.target.value);
    if (e.target.value === 'Program') {
      await fetchProgramData();
    } else {
      await fetchData();
    }
  };

  // Function to handle deleting selected rows
  const handleDeleteSelected = () => {
    deleteUsers(selectedRows);
    setSelectedRows([]);
  };

  // Function to handle row selection
  const handleSelectRow = (id: string) => {
    setSelectedRows(prev => prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]);
  };

  // Function to handle sorting data
  const handleSort = (field: keyof ClientDataProps) => {
    const sortedData = [...data].sort((a, b) => {
      const aValue = a[field] ?? '';
      const bValue = b[field] ?? '';
      return aValue > bValue ? 1 : -1;
    });
    setData(sortedData);
  };

  // Function to handle selecting all rows
  const handleSelectAllRows = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map(client => client.id));
    }
  };

  // Function to reset the table to initial state
  const handleResetTable = () => {
    setData(initialData);
    setSelectedRows([]);
  };

  // Function to handle opening the modal
  const handleOpenModal = (client?: ClientDataProps) => {
    setModalData(client || null);
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const handleUpdateUser = (updatedUser: ClientDataProps) => {
    setData(prevData => prevData.map(user => 
      'email' in user && user.id === updatedUser.id ? updatedUser : user
    ));
  };

  const handleDeleteUser = (id: string) => {
    deleteUsers([id]);
  };

  const handleAddUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
    try {
      const addedUser = await addUser(newUser);
      setData(prevData => [...prevData, addedUser]);
      handleCloseModal();
    } catch (error) {
      console.error('Error adding new user:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  // Function to handle search input
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <section className="container px-4 mx-auto my-[100px] w-full" 
      style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
        
      {/* table title and search bar */}
      <div className="flex items-center justify-between gap-x-3">
        <div className="flex items-center gap-x-2">
          <h2 className="text-2xl font-medium text-black-800">Client Data</h2>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            className="px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
      </div>

      {/* filtering, reset, add, delete, and refetch buttons */}
      <div className="flex items-center justify-end gap-x-2 mt-6">
        <select value={selectedTable} onChange={handleTableChange} className="px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300">
          <option value="User">User</option>
          <option value="Program">Program</option>
        </select>
        <button className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
          <FaFilter />
          <span>Filter</span>
        </button>
        <button onClick={handleResetTable} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
          <span>Reset</span>
        </button>
        <button onClick={() => handleOpenModal()} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
          <FaPlus />
          <span>Add</span>
        </button>
        <button onClick={handleDeleteSelected} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
          <FaTrash />
          <span>Delete</span>
        </button>
        <button onClick={fetchData} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
          <FaSync />
        </button>
      </div>

      {/* table title */}
      <div className="flex flex-col mt-6">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
                    <div className="flex items-center gap-x-3">
                      <input
                        type="checkbox"
                        className="text-blue-500 border-gray-300 rounded"
                        checked={selectedRows.length === data.length}
                        onChange={handleSelectAllRows}
                      />
                    </div>
                  </th>
                  <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                    Profile Image
                  </th>
                  <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
                    <div className="flex items-center gap-x-3">
                      <span>Name</span>
                      <button onClick={() => handleSort('name')}>
                        <FaSort />
                      </button>
                    </div>
                  </th>
                    <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                      Member Type
                      <button onClick={() => handleSort('stages')}>
                        <FaSort />
                      </button>
                    </th>
                    <th className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                      Email
                      <button onClick={() => handleSort('email')}>
                        <FaSort />
                      </button>
                    </th>
                    <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                      Gender
                      <button onClick={() => handleSort('gender')}>
                        <FaSort />
                      </button>
                    </th>
                    <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                      Phone
                      <button onClick={() => handleSort('phone')}>
                        <FaSort />
                      </button>
                    </th>
                    <th className="px-4 py-3.5 text-sm font-normal text-gray-500">
                      <div className="flex items-center justify-between">
                        <span>Date of Birth</span>
                        <button onClick={() => handleSort('dob')}>
                          <FaSort />
                        </button>
                      </div>
                    </th>
                    <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                      Country
                      <button onClick={() => handleSort('country')}>
                        <FaSort />
                      </button>
                    </th>
                    <th className="px-4 py-3.5 text-sm font-normal text-gray-500">
                      <div className="flex items-center justify-between">
                        <span>Address</span>
                        <button onClick={() => handleSort('address')}>
                          <FaSort />
                        </button>
                      </div>
                    </th>
                    <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                      Badges
                      <button onClick={() => handleSort('badges')}>
                        <FaSort />
                      </button>
                    </th>
                    <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                      Program Stats
                      <button onClick={() => handleSort('programStats')}>
                        <FaSort />
                      </button>
                    </th>
                    <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                      Telegram ID
                      <button onClick={() => handleSort('telegramId')}>
                        <FaSort />
                      </button>
                    </th>
                    <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                      Discord ID
                      <button onClick={() => handleSort('discordId')}>
                        <FaSort />
                      </button>
                    </th>
                    <th className="relative py-3.5 px-4">
                      <span className="sr-only">Action</span>
                    </th>
                  </tr>
                </thead>

                {/* table body */}
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredData.map((client: ClientDataProps | ProgramDataProps) => (
                    <tr key={client.id} className="hover:bg-gray-100">
                      <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                        <input
                          type="checkbox"
                          className="text-blue-500 border-gray-300 rounded"
                          checked={selectedRows.includes(client.id)}
                          onChange={() => handleSelectRow(client.id)}
                        />
                      </td>
                      {selectedTable === 'User' && (
                        <>
                          <td className="px-4 py-4 text-sm text-gray-800 whitespace-nowrap">
                            <img 
                              src={(client as ClientDataProps).profileImage || ((client as ClientDataProps).gender === 'female' ? femaleAvatar : maleAvatar)} 
                              alt="Profile" 
                              className="w-8 h-8 rounded-full bg-gray-500" 
                            />
                          </td>
                          <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                            <div>
                              <h2 className="font-medium text-gray-800">{(client as ClientDataProps).name}</h2>
                            </div>
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ClientDataProps).stages}</td>
                          <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">{(client as ClientDataProps).email}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ClientDataProps).gender}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ClientDataProps).phone}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                            {(client as ClientDataProps).dob ? new Date((client as ClientDataProps).dob).toISOString().split('T')[0] : 'N/A'}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ClientDataProps).country}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
                            {(client as ClientDataProps).address ? (client as ClientDataProps).address.split(',')[0] : 'N/A'}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ClientDataProps).badges}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ClientDataProps).programStats}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ClientDataProps).telegramId}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ClientDataProps).discordId}</td>
                        </>
                      )}
                      {selectedTable === 'Program' && (
                        <>
                          <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">{(client as ProgramDataProps).name}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ProgramDataProps).status}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ProgramDataProps).memberType}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ProgramDataProps).amount}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ProgramDataProps).currency}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ProgramDataProps).slot}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ProgramDataProps).redeemCounts}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ProgramDataProps).startDate}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ProgramDataProps).time}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ProgramDataProps).endDate}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{(client as ProgramDataProps).location}</td>
                        </>
                      )}
                      <td className="px-4 py-4 text-sm whitespace-nowrap">
                        <div className="flex items-center gap-x-2">
                          <button onClick={() => 'id' in client ? handleOpenModal(client as ClientDataProps) : null} className="text-blue-500 mr-2">
                            <FaEdit />
                          </button>
                          <button onClick={() => handleDeleteUser(client.id)} className="text-red-500">
                            <FaTrash />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      
      {/* next and prev page */}
      <div className="flex items-center justify-center mt-6 gap-x-4">
        <button
          onClick={() => setCurrentPage(current => Math.max(current - 1, 1))}
          className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100"
        >
          <IoIosArrowDropleft className="w-5 h-5 rtl:-scale-x-100" />
          <span>Prev</span>
        </button>
        <div className="flex items-center gap-x-2">
          <span className="text-sm">{currentPage}</span>
          <span className="text-sm">-</span>
          <span className="text-sm">{maxPage}</span>
        </div>
        <button
          onClick={() => setCurrentPage(current => Math.min(current + 1, maxPage))}
          className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100"
        >
          <span>Next</span>
          <IoIosArrowDropright className="w-5 h-5 rtl:-scale-x-100" />
        </button>
      </div>
      
      {/* update modal dialog */}
      {isModalOpen && (
        <UserDetailModal 
          client={modalData || undefined}
          onClose={handleCloseModal}
          onUpdate={handleUpdateUser}
          onDelete={handleDeleteUser}
          onAdd={handleAddUser}
        />
      )}
    </section>
  );
};

export default ClientData;


// // update code --> 01 july 2024
// // src/components/admin/admin02_ClientData.tsx

// import React, { useState } from 'react';
// import { FaEdit, FaTrash, FaFilter, FaPlus, FaSort, FaSync } from 'react-icons/fa';
// import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";
// import { useTheme } from '../../context/ThemeContext';
// import UserDetailModal from './ClientData/UserDetailModal';
// import useDataTable, { ClientDataProps } from '../../hooks/AdminDashboard/useDataTable';
// import { filterData } from './ClientData/UserDetailFilter';
// import femaleAvatar from '../../assets/usrDashboard/avatar_female.png';
// import maleAvatar from '../../assets/usrDashboard/avatar_male.png';

// const ClientData: React.FC = () => {
//   const { theme } = useTheme();
//   const { data, setData, deleteUsers, addUser, fetchData, fetchProgramData  } = useDataTable();
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [modalData, setModalData] = useState<ClientDataProps | null>(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [selectedTable, setSelectedTable] = useState('User'); // New state to track selected table
//   const filteredData = filterData(data, searchTerm); // Filtered data based on search term
//   const maxPage = 5; // Example maximum page, adjust according to your pagination logic
  
//   const initialData: ClientDataProps[] = [...data]; // Store initial data for resetting

//   const handleTableChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
//     setSelectedTable(e.target.value);
//     if (e.target.value === 'Program') {
//       await fetchProgramData();
//     } else {
//       await fetchData();
//     }
//   };

//   // Function to handle deleting selected rows
//   const handleDeleteSelected = () => {
//     deleteUsers(selectedRows);
//     setSelectedRows([]);
//   };

//   // Function to handle row selection
//   const handleSelectRow = (id: string) => {
//     setSelectedRows(prev => prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]);
//   };

//   // Function to handle sorting data
//   const handleSort = (field: keyof ClientDataProps) => {
//     const sortedData = [...data].sort((a, b) => {
//       const aValue = a[field] ?? '';
//       const bValue = b[field] ?? '';
//       return aValue > bValue ? 1 : -1;
//     });
//     setData(sortedData);
//   };

//   // Function to handle selecting all rows
//   const handleSelectAllRows = () => {
//     if (selectedRows.length === data.length) {
//       setSelectedRows([]);
//     } else {
//       setSelectedRows(data.map(client => client.id));
//     }
//   };

//   // Function to reset the table to initial state
//   const handleResetTable = () => {
//     setData(initialData);
//     setSelectedRows([]);
//   };

//   // Function to handle opening the modal
//   const handleOpenModal = (client?: ClientDataProps) => {
//     setModalData(client || null);
//     setIsModalOpen(true);
//   };

//   // Function to handle closing the modal
//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setModalData(null);
//   };

//   const handleUpdateUser = (updatedUser: ClientDataProps) => {
//     setData(prevData => prevData.map(user => user.id === updatedUser.id ? updatedUser : user));
//   };

//   const handleDeleteUser = (id: string) => {
//     deleteUsers([id]);
//   };

//   const handleAddUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
//     try {
//       const addedUser = await addUser(newUser);
//       setData(prevData => [...prevData, addedUser]);
//       handleCloseModal();
//     } catch (error) {
//       console.error('Error adding new user:', error);
//       // Handle error (e.g., show an error message to the user)
//     }
//   };

//   // Function to handle search input
//   const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//   };

//   return (
//     <section className="container px-4 mx-auto my-[100px] w-full" 
//       style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//       {/* table title and search bar */}
//       <div className="flex items-center justify-between gap-x-3">
//         <div className="flex items-center gap-x-2">
//           <h2 className="text-2xl font-medium text-black-800">Client Data</h2>
//           <input
//             type="text"
//             placeholder="Search..."
//             value={searchTerm}
//             onChange={handleSearch}
//             className="px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
//           />
//         </div>
//       </div>

//       {/* filtering, reset, add, delete, and refetch buttons */}
//       <div className="flex items-center justify-end gap-x-2 mt-6">
//         <select value={selectedTable} onChange={handleTableChange} className="px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300">
//           <option value="User">User</option>
//           <option value="Program">Program</option>
//         </select>
//         <button className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//           <FaFilter />
//           <span>Filter</span>
//         </button>
//         <button onClick={handleResetTable} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//           <span>Reset</span>
//         </button>
//         <button onClick={() => handleOpenModal()} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//           <FaPlus />
//           <span>Add</span>
//         </button>
//         <button onClick={handleDeleteSelected} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//           <FaTrash />
//           <span>Delete</span>
//         </button>
//         <button onClick={fetchData} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//           <FaSync />
//         </button>
//       </div>

//       {/* table title */}
//       <div className="flex flex-col mt-6">
//         <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
//           <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
//             <div className="overflow-hidden border border-gray-200 md:rounded-lg">
//             <table className="min-w-full divide-y divide-gray-200">
//               <thead className="bg-gray-50">
//                 <tr>
//                   <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
//                     <div className="flex items-center gap-x-3">
//                       <input
//                         type="checkbox"
//                         className="text-blue-500 border-gray-300 rounded"
//                         checked={selectedRows.length === data.length}
//                         onChange={handleSelectAllRows}
//                       />
//                     </div>
//                   </th>
//                   <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                     Profile Image
//                   </th>
//                   <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
//                     <div className="flex items-center gap-x-3">
//                       <span>Name</span>
//                       <button onClick={() => handleSort('name')}>
//                         <FaSort />
//                       </button>
//                     </div>
//                   </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Member Type
//                       <button onClick={() => handleSort('stages')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Email
//                       <button onClick={() => handleSort('email')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Gender
//                       <button onClick={() => handleSort('gender')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Phone
//                       <button onClick={() => handleSort('phone')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-gray-500">
//                       <div className="flex items-center justify-between">
//                         <span>Date of Birth</span>
//                         <button onClick={() => handleSort('dob')}>
//                           <FaSort />
//                         </button>
//                       </div>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Country
//                       <button onClick={() => handleSort('country')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-gray-500">
//                       <div className="flex items-center justify-between">
//                         <span>Address</span>
//                         <button onClick={() => handleSort('address')}>
//                           <FaSort />
//                         </button>
//                       </div>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Badges
//                       <button onClick={() => handleSort('badges')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Program Stats
//                       <button onClick={() => handleSort('programStats')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Telegram ID
//                       <button onClick={() => handleSort('telegramId')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Discord ID
//                       <button onClick={() => handleSort('discordId')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="relative py-3.5 px-4">
//                       <span className="sr-only">Action</span>
//                     </th>
//                   </tr>
//                 </thead>

//                 {/* table body */}
//                 <tbody className="bg-white divide-y divide-gray-200">
//                   {filteredData.map((client) => (
//                     <tr key={client.id} className="hover:bg-gray-100">
//                       <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
//                         <input
//                           type="checkbox"
//                           className="text-blue-500 border-gray-300 rounded"
//                           checked={selectedRows.includes(client.id)}
//                           onChange={() => handleSelectRow(client.id)}
//                         />
//                       </td>
//                       <td className="px-4 py-4 text-sm text-gray-800 whitespace-nowrap">
//                         <img 
//                           src={client.profileImage || (client.gender === 'female' ? femaleAvatar : maleAvatar)} 
//                           alt="Profile" 
//                           className="w-8 h-8 rounded-full bg-gray-500" 
//                         />
//                       </td>
//                       <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
//                         <div>
//                           <h2 className="font-medium text-gray-800">{client.name}</h2>
//                         </div>
//                       </td>
//                           <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.stages}</td>
//                           <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">{client.email}</td>
//                           <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.gender}</td>
//                           <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.phone}</td>
//                           <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
//                             {client.dob ? new Date(client.dob).toISOString().split('T')[0] : 'N/A'}
//                           </td>
//                           <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.country}</td>
//                           <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
//                             {client.address ? client.address.split(',')[0] : 'N/A'}
//                           </td>
//                           <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.badges}</td>
//                           <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.programStats}</td>
//                           <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.telegramId}</td>
//                           <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.discordId}</td>
//                           <td className="px-4 py-4 text-sm whitespace-nowrap">
//                         <div className="flex items-center gap-x-2">
//                           <button onClick={() => handleOpenModal(client)} className="text-blue-500 mr-2">
//                             <FaEdit />
//                           </button>
//                           <button onClick={() => handleDeleteUser(client.id)} className="text-red-500">
//                             <FaTrash />
//                           </button>
//                         </div>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
      
//       {/* next and prev page */}
//       <div className="flex items-center justify-center mt-6 gap-x-4">
//         <button
//           onClick={() => setCurrentPage(current => Math.max(current - 1, 1))}
//           className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100"
//         >
//           <IoIosArrowDropleft className="w-5 h-5 rtl:-scale-x-100" />
//           <span>Prev</span>
//         </button>
//         <div className="flex items-center gap-x-2">
//           <span className="text-sm">{currentPage}</span>
//           <span className="text-sm">-</span>
//           <span className="text-sm">{maxPage}</span>
//         </div>
//         <button
//           onClick={() => setCurrentPage(current => Math.min(current + 1, maxPage))}
//           className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100"
//         >
//           <span>Next</span>
//           <IoIosArrowDropright className="w-5 h-5 rtl:-scale-x-100" />
//         </button>
//       </div>
      
//       {/* update modal dialog */}
//       {isModalOpen && (
//         <UserDetailModal 
//           client={modalData || undefined}
//           onClose={handleCloseModal}
//           onUpdate={handleUpdateUser}
//           onDelete={handleDeleteUser}
//           onAdd={handleAddUser}
//         />
//       )}
//     </section>
//   );
// };

// export default ClientData;

// // update code --> 25 june 2024 v02
// // src/components/admin/admin02_ClientData.tsx

// import React, { useState } from 'react';
// import { FaEdit, FaTrash, FaFilter, FaPlus, FaSort, FaSync } from 'react-icons/fa';
// import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";
// import { useTheme } from '../../context/ThemeContext';
// import UserDetailModal from './ClientData/UserDetailModal';
// import useDataTable, { ClientDataProps } from '../../hooks/AdminDashboard/useDataTable';
// import { filterData } from './ClientData/UserDetailFilter';

// const ClientData: React.FC = () => {
//   const { theme } = useTheme();
//   const { data, setData, deleteUsers, addUser, fetchData } = useDataTable();
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [modalData, setModalData] = useState<ClientDataProps | null>(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const filteredData = filterData(data, searchTerm); // Filtered data based on search term
//   const maxPage = 5; // Example maximum page, adjust according to your pagination logic
  
//   const initialData: ClientDataProps[] = [...data]; // Store initial data for resetting

//   // Function to handle deleting selected rows
//   const handleDeleteSelected = () => {
//     deleteUsers(selectedRows);
//     setSelectedRows([]);
//   };

//   // Function to handle row selection
//   const handleSelectRow = (id: string) => {
//     setSelectedRows(prev => prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]);
//   };

//   // Function to handle sorting data
//   const handleSort = (field: keyof ClientDataProps) => {
//     const sortedData = [...data].sort((a, b) => {
//       const aValue = a[field] ?? '';
//       const bValue = b[field] ?? '';
//       return aValue > bValue ? 1 : -1;
//     });
//     setData(sortedData);
//   };

//   // Function to handle selecting all rows
//   const handleSelectAllRows = () => {
//     if (selectedRows.length === data.length) {
//       setSelectedRows([]);
//     } else {
//       setSelectedRows(data.map(client => client.id));
//     }
//   };

//   // Function to reset the table to initial state
//   const handleResetTable = () => {
//     setData(initialData);
//     setSelectedRows([]);
//   };

//   // Function to handle opening the modal
//   const handleOpenModal = (client?: ClientDataProps) => {
//     setModalData(client || null);
//     setIsModalOpen(true);
//   };

//   // Function to handle closing the modal
//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setModalData(null);
//   };

//   const handleUpdateUser = (updatedUser: ClientDataProps) => {
//     setData(prevData => prevData.map(user => user.id === updatedUser.id ? updatedUser : user));
//   };

//   const handleDeleteUser = (id: string) => {
//     deleteUsers([id]);
//   };

//   const handleAddUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
//     try {
//       const addedUser = await addUser(newUser);
//       setData(prevData => [...prevData, addedUser]);
//       handleCloseModal();
//     } catch (error) {
//       console.error('Error adding new user:', error);
//       // Handle error (e.g., show an error message to the user)
//     }
//   };

//   // Function to handle search input
//   const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//   };

//   return (
//     <section className="container px-4 mx-auto my-[100px]" 
//       style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
      
//       {/* table title and search bar */}
//       <div className="flex items-center justify-between gap-x-3">
//         <div className="flex items-center gap-x-2">
//           <h2 className="text-2xl font-medium text-black-800">Client Data</h2>
//           <input
//             type="text"
//             placeholder="Search..."
//             value={searchTerm}
//             onChange={handleSearch}
//             className="px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
//           />
//         </div>
//       </div>

//       {/* filtering, reset, add, delete, and refetch buttons */}
//       <div className="flex items-center justify-end gap-x-2 mt-6">
//         <button className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//           <FaFilter />
//           <span>Filter</span>
//         </button>
//         <button onClick={handleResetTable} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//           <span>Reset</span>
//         </button>
//         <button onClick={() => handleOpenModal()} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//           <FaPlus />
//           <span>Add</span>
//         </button>
//         <button onClick={handleDeleteSelected} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//           <FaTrash />
//           <span>Delete</span>
//         </button>
//         <button onClick={fetchData} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//           <FaSync />
//         </button>
//       </div>

//       {/* table title */}
//       <div className="flex flex-col mt-6">
//         <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
//           <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
//             <div className="overflow-hidden border border-gray-200 md:rounded-lg">
//               <table className="min-w-full divide-y divide-gray-200">
//                 <thead className="bg-gray-50">
//                   <tr>
//                     <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       <div className="flex items-center gap-x-3">
//                         <input type="checkbox" className="text-blue-500 border-gray-300 rounded" checked={selectedRows.length === data.length} onChange={handleSelectAllRows} />
//                         <span>Name</span>
//                         <button onClick={() => handleSort('name')}>
//                           <FaSort />
//                         </button>
//                       </div>
//                     </th>
//                     <th className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Email
//                       <button onClick={() => handleSort('email')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Gender
//                       <button onClick={() => handleSort('gender')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Phone
//                       <button onClick={() => handleSort('phone')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-gray-500">
//                       <div className="flex items-center justify-between">
//                         <span>Date of Birth</span>
//                         <button onClick={() => handleSort('dob')}>
//                           <FaSort />
//                         </button>
//                       </div>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Country
//                       <button onClick={() => handleSort('country')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-gray-500">
//                       <div className="flex items-center justify-between">
//                         <span>Address</span>
//                         <button onClick={() => handleSort('address')}>
//                           <FaSort />
//                         </button>
//                       </div>
//                     </th>
//                     <th className="relative py-3.5 px-4">
//                       <span className="sr-only">Action</span>
//                     </th>
//                   </tr>
//                 </thead>

//                 {/* table body */}
//                 <tbody className="bg-white divide-y divide-gray-200">
//                   {filteredData.map((client) => (
//                     <tr key={client.id} className="hover:bg-gray-100">
//                       <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
//                         <div className="inline-flex items-center gap-x-3">
//                           <input
//                             type="checkbox"
//                             className="text-blue-500 border-gray-300 rounded"
//                             checked={selectedRows.includes(client.id)}
//                             onChange={() => handleSelectRow(client.id)}
//                           />
//                           <div>
//                             <h2 className="font-medium text-gray-800">{client.name}</h2>
//                           </div>
//                         </div>
//                       </td>
//                       <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">{client.email}</td>
//                       <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.gender}</td>
//                       <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.phone}</td>
//                       <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
//                         {client.dob ? new Date(client.dob).toISOString().split('T')[0] : 'N/A'}
//                       </td>
//                       <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.country}</td>
//                       <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
//                         {client.address ? client.address.split(',')[0] : 'N/A'}
//                       </td>
//                       <td className="px-4 py-4 text-sm whitespace-nowrap">
//                         <div className="flex items-center gap-x-2">
//                           <button onClick={() => handleOpenModal(client)} className="text-blue-500 mr-2">
//                             <FaEdit />
//                           </button>
//                           <button onClick={() => handleDeleteUser(client.id)} className="text-red-500">
//                             <FaTrash />
//                           </button>
//                         </div>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
      
//       {/* next and prev page */}
//       <div className="flex items-center justify-center mt-6 gap-x-4">
//         <button
//           onClick={() => setCurrentPage(current => Math.max(current - 1, 1))}
//           className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100"
//         >
//           <IoIosArrowDropleft className="w-5 h-5 rtl:-scale-x-100" />
//           <span>Prev</span>
//         </button>
//         <div className="flex items-center gap-x-2">
//           <span className="text-sm">{currentPage}</span>
//           <span className="text-sm">-</span>
//           <span className="text-sm">{maxPage}</span>
//         </div>
//         <button
//           onClick={() => setCurrentPage(current => Math.min(current + 1, maxPage))}
//           className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100"
//         >
//           <span>Next</span>
//           <IoIosArrowDropright className="w-5 h-5 rtl:-scale-x-100" />
//         </button>
//       </div>
      
//       {/* update modal dialog */}
//       {isModalOpen && (
//         <UserDetailModal 
//           client={modalData || undefined}
//           onClose={handleCloseModal}
//           onUpdate={handleUpdateUser}
//           onDelete={handleDeleteUser}
//           onAdd={handleAddUser}
//         />
//       )}
//     </section>
//   );
// };

// export default ClientData;



// // update code --> 25 june 2024
// // src/components/admin/admin02_ClientData.tsx

// import React, { useState } from 'react';
// import { FaEdit, FaTrash, FaFilter, FaPlus, FaSort } from 'react-icons/fa';
// import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";
// import { useTheme } from '../../context/ThemeContext';
// import UserDetailModal from './ClientData/UserDetailModal';
// import useDataTable, { ClientDataProps } from '../../hooks/AdminDashboard/useDataTable';
// import { filterData } from './ClientData/UserDetailFilter';

// const ClientData: React.FC = () => {
//   const { theme } = useTheme();
//   const { data, setData, deleteUsers, addUser } = useDataTable();
//   const [selectedRows, setSelectedRows] = useState<string[]>([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [modalData, setModalData] = useState<ClientDataProps | null>(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const filteredData = filterData(data, searchTerm); // Filtered data based on search term
//   const maxPage = 5; // Example maximum page, adjust according to your pagination logic
  
//   const initialData: ClientDataProps[] = [...data]; // Store initial data for resetting

//   // Function to handle deleting selected rows
//   const handleDeleteSelected = () => {
//     setData(data.filter(client => !selectedRows.includes(client.id)));
//     setSelectedRows([]);
//   };

//   // Function to handle row selection
//   const handleSelectRow = (id: string) => {
//     setSelectedRows(prev => prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]);
//   };

//   // Function to handle sorting data
//   const handleSort = (field: keyof ClientDataProps) => {
//     const sortedData = [...data].sort((a, b) => {
//       const aValue = a[field] ?? '';
//       const bValue = b[field] ?? '';
//       return aValue > bValue ? 1 : -1;
//     });
//     setData(sortedData);
//   };

//   // Function to handle selecting all rows
//   const handleSelectAllRows = () => {
//     if (selectedRows.length === data.length) {
//       setSelectedRows([]);
//     } else {
//       setSelectedRows(data.map(client => client.id));
//     }
//   };

//   // Function to reset the table to initial state
//   const handleResetTable = () => {
//     setData(initialData);
//     setSelectedRows([]);
//   };

//   // Function to handle opening the modal
//   const handleOpenModal = (client?: ClientDataProps) => {
//     setModalData(client || null);
//     setIsModalOpen(true);
//   };

//   // Function to handle closing the modal
//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setModalData(null);
//   };


//   const handleUpdateUser = (updatedUser: ClientDataProps) => {
//     setData(prevData => prevData.map(user => user.id === updatedUser.id ? updatedUser : user));
//   };

//   const handleDeleteUser = (id: string) => {
//     deleteUsers([id]);
//   };

//   const handleAddUser = async (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => {
//     try {
//       const addedUser = await addUser(newUser);
//       setData(prevData => [...prevData, addedUser]);
//       handleCloseModal();
//     } catch (error) {
//       console.error('Error adding new user:', error);
//       // Handle error (e.g., show an error message to the user)
//     }
//   };

//   // Function to handle search input
//   const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(e.target.value);
//   };

//   return (
//     <section className="container px-4 mx-auto my-[100px]" 
//       style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
      
//       {/* table title and search bar */}
//       <div className="flex items-center justify-between gap-x-3">
//         <div className="flex items-center gap-x-2">
//           <h2 className="text-2xl font-medium text-black-800">Client Data</h2>
//           <input
//             type="text"
//             placeholder="Search..."
//             value={searchTerm}
//             onChange={handleSearch}
//             className="px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
//           />
//         </div>

//         {/* filtering fucntion */}
//         <div className="flex items-center gap-x-2">
//           <button className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//             <FaFilter />
//             <span>Filter</span>
//           </button>
//           <button onClick={handleResetTable} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//             <span>Reset</span>
//           </button>
//           <button onClick={() => handleOpenModal()} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//             <FaPlus />
//             <span>Add</span>
//           </button>
//           <button onClick={handleDeleteSelected} className="flex items-center px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100">
//             <FaTrash />
//             <span>Delete</span>
//           </button>
//         </div>
//       </div>

//       {/* table title */}
//       <div className="flex flex-col mt-6">
//         <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
//           <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
//             <div className="overflow-hidden border border-gray-200 md:rounded-lg">
//               <table className="min-w-full divide-y divide-gray-200">
//                 <thead className="bg-gray-50">
//                   <tr>
//                     <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       <div className="flex items-center gap-x-3">
//                         <input type="checkbox" className="text-blue-500 border-gray-300 rounded" checked={selectedRows.length === data.length} onChange={handleSelectAllRows} />
//                         <span>Name</span>
//                         <button onClick={() => handleSort('name')}>
//                           <FaSort />
//                         </button>
//                       </div>
//                     </th>
//                     <th className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Email
//                       <button onClick={() => handleSort('email')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Gender
//                       <button onClick={() => handleSort('gender')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Phone
//                       <button onClick={() => handleSort('phone')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-gray-500">
//                       <div className="flex items-center justify-between">
//                         <span>Date of Birth</span>
//                         <button onClick={() => handleSort('dob')}>
//                           <FaSort />
//                         </button>
//                       </div>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
//                       Country
//                       <button onClick={() => handleSort('country')}>
//                         <FaSort />
//                       </button>
//                     </th>
//                     <th className="px-4 py-3.5 text-sm font-normal text-gray-500">
//                       <div className="flex items-center justify-between">
//                         <span>Address</span>
//                         <button onClick={() => handleSort('address')}>
//                           <FaSort />
//                         </button>
//                       </div>
//                     </th>
//                     <th className="relative py-3.5 px-4">
//                       <span className="sr-only">Action</span>
//                     </th>
//                   </tr>
//                 </thead>

//                 {/* table body */}
//                 <tbody className="bg-white divide-y divide-gray-200">
//                   {filteredData.map((client) => (
//                     <tr key={client.id} className="hover:bg-gray-100">
//                       <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
//                         <div className="inline-flex items-center gap-x-3">
//                           <input
//                             type="checkbox"
//                             className="text-blue-500 border-gray-300 rounded"
//                             checked={selectedRows.includes(client.id)}
//                             onChange={() => handleSelectRow(client.id)}
//                           />
//                           <div>
//                             <h2 className="font-medium text-gray-800">{client.name}</h2>
//                           </div>
//                         </div>
//                       </td>
//                       <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">{client.email}</td>
//                       <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.gender}</td>
//                       <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.phone}</td>
//                       <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
//                         {client.dob ? new Date(client.dob).toISOString().split('T')[0] : 'N/A'}
//                       </td>
//                       <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{client.country}</td>
//                       <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">
//                         {client.address ? client.address.split(',')[0] : 'N/A'}
//                       </td>
//                       <td className="px-4 py-4 text-sm whitespace-nowrap">
//                         <div className="flex items-center gap-x-2">
//                           <button onClick={() => handleOpenModal(client)} className="text-blue-500 mr-2">
//                             <FaEdit />
//                           </button>
//                           <button className="text-red-500">
//                             <FaTrash />
//                           </button>
//                         </div>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
      
//       {/* next and prev page */}
//       <div className="flex items-center justify-center mt-6 gap-x-4">
//         <button
//           onClick={() => setCurrentPage(current => Math.max(current - 1, 1))}
//           className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100"
//         >
//           <IoIosArrowDropleft className="w-5 h-5 rtl:-scale-x-100" />
//           <span>Prev</span>
//         </button>
//         <div className="flex items-center gap-x-2">
//           <span className="text-sm">{currentPage}</span>
//           <span className="text-sm">-</span>
//           <span className="text-sm">{maxPage}</span>
//         </div>
//         <button
//           onClick={() => setCurrentPage(current => Math.min(current + 1, maxPage))}
//           className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100"
//         >
//           <span>Next</span>
//           <IoIosArrowDropright className="w-5 h-5 rtl:-scale-x-100" />
//         </button>
//       </div>
      
//       {/* update modal dialog */}
//       {isModalOpen && (
//         <UserDetailModal 
//           client={modalData || undefined}
//           onClose={handleCloseModal}
//           onUpdate={handleUpdateUser}
//           onDelete={handleDeleteUser}
//           onAdd={handleAddUser}
//         />
//       )}
//     </section>
//   );
// };

// export default ClientData;

