// src/pages/p02_ResetPassword.tsx

// import react components
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import react-icons
import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

// import file from source
import { useTheme } from '../context/ThemeContext';
import { useReset } from '../hooks/Login/useReset';
import { useFormValidation } from '../hooks/Login/useFormValidation';
import ResetAlert from '../components/login/ResetAlert';
import AppFooter from '../components/Footer';

const VerifyPassword: React.FC = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { search } = useLocation();
  const { verifyResetToken, loading, error } = useReset();
  const token = new URLSearchParams(search).get('token');

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

        if (currentTime > decodedToken.exp) {
          navigate('/request-reset-password');
        }
      } catch (error) {
        console.error('Token decoding error:', error);
        navigate('/request-reset-password');
      }
    }
  }, [token, navigate]);

  const formik = useFormValidation(() => setShowAlert(true));

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (formik.values.password !== formik.values.confirmPassword) {
      formik.setErrors({ confirmPassword: 'Passwords must match' });
      return;
    }
  
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const result = await verifyResetToken(token, formik.values.password);
      if (result) {
        setShowAlert(true);
        setTimeout(() => {
          navigate('/login');
        }, 3000); 
      }
    } catch (err) {
      console.error('Password reset error:', err);
    }
  };  

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex-grow flex items-center justify-center px-4">
        <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
          <h2 className="text-center text-xl font-bold mb-4" style={{ color: theme.textColor }}>Reset Your Password</h2>
          <form onSubmit={handlePasswordReset}>
            <div className="mb-4">
              <div className="flex items-center border rounded px-3 py-2">
                <FaLock className="text-gray-500 mr-3" />
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="New Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  name="password"
                  className="w-full py-2 focus:outline-none"
                  style={{ fontFamily: theme.fontFamily }}
                />
                <button type="button" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <FaEyeSlash className="text-gray-500 ml-3" /> : <FaEye className="text-gray-500 ml-3" />}
                </button>
              </div>
              {formik.errors.password && (
                <div className="bg-red-100 border border-red-400 text-red-700 rounded mt-2 px-3 py-2">
                  {formik.errors.password}
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="flex items-center border rounded px-3 py-2">
                <FaLock className="text-gray-500 mr-3" />
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  name="confirmPassword"
                  className="w-full py-2 focus:outline-none"
                  style={{ fontFamily: theme.fontFamily }}
                />
                <button type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? <FaEyeSlash className="text-gray-500 ml-3" /> : <FaEye className="text-gray-500 ml-3" />}
                </button>
              </div>
              {formik.errors.confirmPassword && (
                <div className="bg-red-100 border border-red-400 text-red-700 rounded mt-2 px-3 py-2">
                  {formik.errors.confirmPassword}
                </div>
              )}
            </div>
            <button
              type="submit"
              className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
              style={{ fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
              disabled={loading}
            >
              Reset Password
            </button>
          </form>
          {error && (
            <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{error}</span>
            </div>
          )}
        </div>
      </div>
      <footer 
        className="w-full py-4 mt-auto"
        style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }}
      >
        <AppFooter />
      </footer>
      <ResetAlert visible={showAlert} onClose={() => setShowAlert(false)} />
    </div>
  );
};

export default VerifyPassword;

// // udpate code --> 18 july 2024
// // src/pages/p02_ResetPassword.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';

// // import react-icons
// import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

// // import file from source
// import { useTheme } from '../context/ThemeContext';
// import { useReset } from '../hooks/Login/useReset';
// import { useFormValidation } from '../hooks/Login/useFormValidation';
// import ResetAlert from '../components/login/ResetAlert';
// import AppFooter from '../components/Footer';

// const VerifyPassword: React.FC = () => {
//   const { theme } = useTheme();
//   const navigate = useNavigate();
//   const [showAlert, setShowAlert] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const { search } = useLocation();
//   const { verifyResetToken, loading } = useReset();
//   const token = new URLSearchParams(search).get('token');

//   useEffect(() => {
//     if (token) {
//       try {
//         const decodedToken = JSON.parse(atob(token.split('.')[1]));
//         const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

//         if (currentTime > decodedToken.exp) {
//           navigate('/request-reset-password');
//         }
//       } catch (error) {
//         console.error('Token decoding error:', error);
//         navigate('/request-reset-password');
//       }
//     }
//   }, [token, navigate]);

//   const formik = useFormValidation(() => setShowAlert(true));

//   const handlePasswordReset = async (e: React.FormEvent) => {
//     e.preventDefault();
  
//     if (formik.values.password !== formik.values.confirmPassword) {
//       formik.setErrors({ confirmPassword: 'Passwords must match' });
//       return;
//     }
  
//     const result = await verifyResetToken(token!, formik.values.password);
//     if (result) {
//       setShowAlert(true);
//       setTimeout(() => {
//         navigate('/login');
//       }, 3000); 
//     }
//   };  

//   return (
//     <div className="flex flex-col min-h-screen bg-gray-100">
//       <div className="flex-grow flex items-center justify-center px-4">
//         <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
//           <h2 className="text-center text-xl font-bold mb-4" style={{ color: theme.textColor }}>Reset Your Password</h2>
//           <form onSubmit={handlePasswordReset}>
//             <div className="mb-4">
//               <div className="flex items-center border rounded px-3 py-2">
//                 <FaLock className="text-gray-500 mr-3" />
//                 <input
//                   type={showPassword ? "text" : "password"}
//                   placeholder="New Password"
//                   value={formik.values.password}
//                   onChange={formik.handleChange}
//                   name="password"
//                   className="w-full py-2 focus:outline-none"
//                   style={{ fontFamily: theme.fontFamily }}
//                 />
//                 <button type="button" onClick={() => setShowPassword(!showPassword)}>
//                   {showPassword ? <FaEyeSlash className="text-gray-500 ml-3" /> : <FaEye className="text-gray-500 ml-3" />}
//                 </button>
//               </div>
//               {formik.errors.password && (
//                 <div className="bg-red-100 border border-red-400 text-red-700 rounded mt-2 px-3 py-2">
//                   {formik.errors.password}
//                 </div>
//               )}
//             </div>
//             <div className="mb-4">
//               <div className="flex items-center border rounded px-3 py-2">
//                 <FaLock className="text-gray-500 mr-3" />
//                 <input
//                   type={showConfirmPassword ? "text" : "password"}
//                   placeholder="Confirm Password"
//                   value={formik.values.confirmPassword}
//                   onChange={formik.handleChange}
//                   name="confirmPassword"
//                   className="w-full py-2 focus:outline-none"
//                   style={{ fontFamily: theme.fontFamily }}
//                 />
//                 <button type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
//                   {showConfirmPassword ? <FaEyeSlash className="text-gray-500 ml-3" /> : <FaEye className="text-gray-500 ml-3" />}
//                 </button>
//               </div>
//               {formik.errors.confirmPassword && (
//                 <div className="bg-red-100 border border-red-400 text-red-700 rounded mt-2 px-3 py-2">
//                   {formik.errors.confirmPassword}
//                 </div>
//               )}
//             </div>
//             <button
//               type="submit"
//               className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
//               style={{ fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
//               disabled={loading}
//             >
//               Reset Password
//             </button>
//           </form>
//         </div>
//       </div>
//       <footer 
//         className="w-full py-4 mt-auto"
//         style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }}
//       >
//         <AppFooter />
//       </footer>
//       <ResetAlert visible={showAlert} onClose={() => setShowAlert(false)} />
//     </div>
//   );
// };

// export default VerifyPassword;




// // update code --> 10 june 2024
// // src/pages/p02_ResetPassword.tsx

// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { FaLock } from 'react-icons/fa';
// import { useTheme } from '../context/ThemeContext';
// import { useReset } from '../hooks/Login/useReset';
// import ResetAlert from '../components/login/ResetAlert';

// const VerifyPassword: React.FC = () => {
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [showAlert, setShowAlert] = useState(false);
//   const { search } = useLocation();
//   const navigate = useNavigate();
//   const { theme } = useTheme();
//   const { verifyResetToken, loading, error } = useReset();
//   const token = new URLSearchParams(search).get('token');

//   useEffect(() => {
//     if (token) {
//       try {
//         const decodedToken = JSON.parse(atob(token.split('.')[1]));
//         const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

//         if (currentTime > decodedToken.exp) {
//           navigate('/request-reset-password');
//         }
//       } catch (error) {
//         console.error('Token decoding error:', error);
//         navigate('/request-reset-password');
//       }
//     }
//   }, [token, navigate]);

//   const handlePasswordReset = async (e: React.FormEvent) => {
//     e.preventDefault();
//     if (newPassword !== confirmPassword) {
//       return alert("Passwords do not match");
//     }

//     const result = await verifyResetToken(token!, newPassword);
//     if (result) {
//       setShowAlert(true);
//     }
//   };

//   return (
//     <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
//       <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
//         <h2 className="text-center text-xl font-bold mb-4" style={{ color: theme.textColor }}>Reset Your Password</h2>
//         <form onSubmit={handlePasswordReset}>
//           <div className="mb-4">
//             <div className="flex items-center border rounded px-3 py-2">
//               <FaLock className="text-gray-500 mr-3" />
//               <input
//                 type="password"
//                 placeholder="New Password"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//                 className="w-full py-2 focus:outline-none"
//                 style={{ fontFamily: theme.fontFamily }}
//               />
//             </div>
//             {error && <div className="bg-red-100 border border-red-400 text-red-700 rounded mt-2 px-3 py-2">{error}</div>}
//           </div>
//           <div className="mb-4">
//             <div className="flex items-center border rounded px-3 py-2">
//               <FaLock className="text-gray-500 mr-3" />
//               <input
//                 type="password"
//                 placeholder="Confirm Password"
//                 value={confirmPassword}
//                 onChange={(e) => setConfirmPassword(e.target.value)}
//                 className="w-full py-2 focus:outline-none"
//                 style={{ fontFamily: theme.fontFamily }}
//               />
//             </div>
//             {newPassword !== confirmPassword && <div className="bg-red-100 border border-red-400 text-red-700 rounded mt-2 px-3 py-2">Passwords do not match</div>}
//           </div>
//           <button
//             type="submit"
//             className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
//             style={{ fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
//             disabled={loading}
//           >
//             Reset Password
//           </button>
//         </form>
//       </div>
//       <ResetAlert visible={showAlert} onClose={() => setShowAlert(false)} />
//     </div>
//   );
// };

// export default VerifyPassword;


// // update code --> 10 june 2024
// // src/pages/p02_ResetPassword.tsx

// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { FaLock } from 'react-icons/fa';
// import { useTheme } from '../context/ThemeContext';
// import { useReset } from '../hooks/Login/useReset';
// import ResetAlert from '../components/login/ResetAlert';

// const VerifyPassword: React.FC = () => {
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [showAlert, setShowAlert] = useState(false);
//   const { search } = useLocation();
//   const navigate = useNavigate();
//   const { theme } = useTheme();
//   const { verifyResetToken, loading, error } = useReset();
//   const token = new URLSearchParams(search).get('token');

//   useEffect(() => {
//     if (token) {
//       const decodedToken = JSON.parse(atob(token.split('.')[1]));
//       const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

//       if (currentTime > decodedToken.exp) {
//         navigate('/request-reset-password');
//       }
//     }
//   }, [token, navigate]);

//   const handlePasswordReset = async (e: React.FormEvent) => {
//     e.preventDefault();
//     if (newPassword !== confirmPassword) {
//       return alert("Passwords do not match");
//     }

//     const result = await verifyResetToken(token!, newPassword);
//     if (result) {
//       setShowAlert(true);
//     }
//   };

//   return (
//     <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
//       <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
//         <h2 className="text-center text-xl font-bold mb-4" style={{ color: theme.textColor }}>Reset Your Password</h2>
//         <form onSubmit={handlePasswordReset}>
//           <div className="mb-4">
//             <div className="flex items-center border rounded px-3 py-2">
//               <FaLock className="text-gray-500 mr-3" />
//               <input
//                 type="password"
//                 placeholder="New Password"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//                 className="w-full py-2 focus:outline-none"
//                 style={{ fontFamily: theme.fontFamily }}
//               />
//             </div>
//             {error && <div className="bg-red-100 border border-red-400 text-red-700 rounded mt-2 px-3 py-2">{error}</div>}
//           </div>
//           <div className="mb-4">
//             <div className="flex items-center border rounded px-3 py-2">
//               <FaLock className="text-gray-500 mr-3" />
//               <input
//                 type="password"
//                 placeholder="Confirm Password"
//                 value={confirmPassword}
//                 onChange={(e) => setConfirmPassword(e.target.value)}
//                 className="w-full py-2 focus:outline-none"
//                 style={{ fontFamily: theme.fontFamily }}
//               />
//             </div>
//             {newPassword !== confirmPassword && <div className="bg-red-100 border border-red-400 text-red-700 rounded mt-2 px-3 py-2">Passwords do not match</div>}
//           </div>
//           <button
//             type="submit"
//             className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
//             style={{ fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
//             disabled={loading}
//           >
//             Reset Password
//           </button>
//         </form>
//       </div>
//       <ResetAlert visible={showAlert} onClose={() => setShowAlert(false)} />
//     </div>
//   );
// };

// export default VerifyPassword;
