// src/hooks/useUserStage.tsx

import useUserDashboard from './useUserDashboard';

const useUserStage = () => {
  const { user } = useUserDashboard();
  const isGuest = user?.stages === 'R One Guest';

  return { isGuest };
};

export default useUserStage;
