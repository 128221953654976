// src/context/EnvContext.tsx

import React, { createContext, useContext } from 'react';

interface EnvContextProps {
  apiUrl: string;
}

const EnvContext = createContext<EnvContextProps | undefined>(undefined);

export const EnvProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const envContextValue: EnvContextProps = {
    apiUrl: process.env.REACT_APP_API_URL!
  };

  return <EnvContext.Provider value={envContextValue}>{children}</EnvContext.Provider>;
};

export const useEnv = () => {
  const context = useContext(EnvContext);
  if (!context) {
    throw new Error('useEnv must be used within an EnvProvider');
  }
  return context;
};
