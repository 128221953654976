// src/view/admin/v01_adminDashboard.tsx

import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import SideMenu from '../../components/SideMenu';
import Overview from '../../components/admin/admin01_Overview';
import ClientData from '../../components/admin/admin02_ClientData';
import Marketing from '../../components/admin/admin03_Marketing';
import ProgramSetting from '../../components/admin/admin04_ProgramSetting';
import NotificationSystem from '../../components/admin/admin05_Notification';
import SubscriptionSetting from '../../components/admin/admin06_Subscription';
import TraderProgram from '../../components/admin/admin07_traderprogram';
// import Terminal from '../../components/admin/admin07_Terminal';
import { useTheme } from '../../context/ThemeContext';
import AuthRoute from '../../components/AuthRoute';

const AdminDashboardView: React.FC = () => {
  const { theme } = useTheme(); // Access theme from ThemeContext
  const [collapsed, setCollapsed] = useState(true);

  const handleToggle = (isCollapsed: boolean) => {
    setCollapsed(isCollapsed);
  };

  return (
    <div className="min-h-screen flex" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
      {/* Side menu */}
      <SideMenu onToggle={handleToggle} />

      <div className={`flex-1 flex flex-col transition-all duration-300 ${collapsed ? 'ml-20' : 'ml-64'}`}>
        {/* Main content */}
        <main className="flex-1 p-6 bg-white overflow-y-auto">
          <Routes>
            <Route path="/" 
              element={
                <AuthRoute requiresAuth>
                  <Overview />
                </AuthRoute>
              } />
            <Route path="/overview" 
              element={
                <AuthRoute requiresAuth>
                  <Overview />
                </AuthRoute>
              } />
            <Route path="/client-data" 
              element={
                <AuthRoute requiresAuth>
                  <ClientData />
                </AuthRoute>
              } />
            <Route path="/marketing" 
              element={
                <AuthRoute requiresAuth>
                  <Marketing />
                </AuthRoute>
              } />
            <Route path="/program-setting" 
              element={
                <AuthRoute requiresAuth>
                  <ProgramSetting />
                </AuthRoute>
              } />
            <Route path="/notification" 
              element={
                <AuthRoute requiresAuth>
                  <NotificationSystem />
                </AuthRoute>
              } />
            <Route path="/subscription" 
              element={
                <AuthRoute requiresAuth>
                  <SubscriptionSetting />
                </AuthRoute>
              } />
            <Route path="/trader-program" 
              element={
                <AuthRoute requiresAuth>
                  <TraderProgram />
                </AuthRoute>
              } />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default AdminDashboardView;


// // udpte code --> 12 july 2024
// // src/view/admin/v01_adminDashboard.tsx

// // import react components
// import React from 'react';
// import { Route, Routes } from 'react-router-dom';

// // import custom components from source

// import SideMenu from '../../components/SideMenu';
// import Overview from '../../components/admin/admin01_Overview';
// import ClientData from '../../components/admin/admin02_ClientData';
// import SupportChat from '../../components/admin/admin03_SupportChat';
// import ProgramSetting from '../../components/admin/admin04_ProgramSetting';
// import NotificationSystem from '../../components/admin/admin05_Notification';
// import { useTheme } from '../../context/ThemeContext';

// const AdminDashboardView: React.FC = () => {
//   const { theme } = useTheme(); // Access theme from ThemeContext

//   return (
//     <div className="min-h-screen flex" 
//       style={{ color: theme.textColor, fontFamily: theme.fontFamily }}> 
      
//       {/* Side menu */}
//       <aside className="w-64 bg-white"> 
//         <SideMenu />
//       </aside>
//       <div className="flex-1 flex flex-col">

//         {/* Main content */}
//         <main className="flex-1 p-6 bg-white overflow-y-auto">
//           <Routes>
//             <Route path="/" element={<Overview />} />
//             <Route path="/overview" element={<Overview />} />
//             <Route path="/client-data" element={<ClientData />} />
//             <Route path="/support-chat" element={<SupportChat />} />
//             <Route path="/program-setting" element={<ProgramSetting />} />
//             <Route path="/notification" element={<NotificationSystem />} />
//           </Routes>
//         </main>
//       </div>
//     </div>
//   );
// };

// export default AdminDashboardView;

// // udpte code --> 03 july 2024 
// // src/view/admin/v01_adminDashboard.tsx

// // import react components
// import React from 'react';
// import { Route, Routes } from 'react-router-dom';

// // import custom components from source

// import SideMenu from '../../components/SideMenu';
// import Overview from '../../components/admin/admin01_Overview';
// import ClientData from '../../components/admin/admin02_ClientData';
// import SupportChat from '../../components/admin/admin03_SupportChat';
// import ProgramSetting from '../../components/admin/admin04_ProgramSetting';
// import NotificationSystem from '../../components/admin/admin05_Notification';
// import { useTheme } from '../../context/ThemeContext';

// const AdminDashboardView: React.FC = () => {
//   const { theme } = useTheme(); // Access theme from ThemeContext

//   return (
//     <div className="min-h-screen flex" 
//       style={{ color: theme.textColor, fontFamily: theme.fontFamily }}> 
      
//       {/* Side menu */}
//       <aside className="w-64 bg-white"> 
//         <SideMenu />
//       </aside>
//       <div className="flex-1 flex flex-col">

//         {/* Main content */}
//         <main className="flex-1 p-6 bg-white overflow-y-auto">
//           <Routes>
//             <Route path="/" element={<Overview />} />
//             <Route path="/overview" element={<Overview />} />
//             <Route path="/client-data" element={<ClientData />} />
//             <Route path="/support-chat" element={<SupportChat />} />
//             <Route path="/program-setting" element={<ProgramSetting />} />
//             <Route path="/notification" element={<NotificationSystem />} />
//           </Routes>
//         </main>
//       </div>
//     </div>
//   );
// };

// export default AdminDashboardView;
