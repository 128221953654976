// src/components/client/Dashboard/UserPointsStats.tsx

import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDummy } from '../../../context/dummy';
import { useTheme } from '../../../context/ThemeContext';

const UserPointsStats: React.FC = () => {
  const { getUser } = useDummy();
  const { theme } = useTheme();

  // Replace with the logic to get the currently logged-in user's email
  const user = getUser('zeric2tan@gmail.com');

  // Set up initial program state
  const [currentProgram, setCurrentProgram] = useState('Go4funds Program');

  if (!user) {
    return null; // Render nothing if no user is found
  }

  const totalPoints = user.totalPoints;
  const programData = user.programs[currentProgram];
  const programTitles = Object.keys(user.programs);
  const currentIndex = programTitles.indexOf(currentProgram);

  const nextProgram = () => {
    const nextIndex = (currentIndex + 1) % programTitles.length;
    setCurrentProgram(programTitles[nextIndex]);
  };

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  return (
    // Card container
    <div className="flex flex-row w-full h-full bg-white rounded-lg shadow-md p-4 sm:flex-col xs:flex-col">
      
      {/* User Lotsize */}
      <div className="flex flex-col w-full">
        <div className="flex justify-center items-center">
          <h5 className="text-lg font-semibold mx-4 md:text-lg md:mx-2 sm:text-xl xs:text-xl" 
            style={styles.title}>
            Total Lots Size
          </h5>
          <div className="relative group">
            <AiOutlineInfoCircle
              className="w-4 h-4 text-gray-400 hover:text-gray-900 cursor-pointer"
              aria-hidden="true"
            />
            {/* Tool Tips */}
            <div className="absolute top-full mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white md:w-48 sm:right-[5px] xs:right-[5px]" 
              style={styles.button}>
              lotsize can be used to redeem various rewards such as attending classes and other benefits.
            </div>
          </div>
        </div>

        {/* Total lotsize */}
        <div className="border-solid border-2 rounded-xl my-2 p-8 sm:p-6 xs:p-4">
          <div className="text-6xl font-bold flex justify-center items-center"
            style={styles.title}>
              {totalPoints}
              <p className="text-lg ml-4">Lot</p>
          </div>
        </div>
      </div>

      <hr className="mx-4" />

      {/* User Stats */}
      <div className="flex flex-col w-full">
        <div className="flex justify-center items-center">
          <h5 className="text-lg font-semibold mx-4 md:text-lg md:mx-2 sm:mt-4 xs:mt-4 xs:text-xl" 
            style={styles.title}>
            Program Stats
          </h5>
          <div className="relative group">
            <AiOutlineInfoCircle
              className="w-4 h-4 text-gray-400 hover:text-gray-900 cursor-pointer sm:mt-4 xs:mt-4"
              aria-hidden="true"
            />
            {/* Tool Tips */}
            <div className="absolute top-full mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white md:w-48 md:right-0 sm:right-[5px] xs:right-[5px]"
              style={styles.button}>
              User stats include information about the user's current program stages and funding.
            </div>
          </div>
        </div>

        {/* Program Information */}
        <div className="flex justify-center items-center text-center rounded-xl p-2 mx-2"
          style={{ fontFamily: theme.fontFamily }}
        >
          <button 
            onClick={nextProgram}
            style={{ color: theme.textColor }}>
            <h2 className="text-xl font-bold md:text-base sm:text-base xs:text-base">
              {currentProgram}
            </h2>
          </button>
        </div>

        {/* Stats Section */}
        <div className="py-5 rounded-lg shadow-inner"
          style={{ backgroundColor: theme.primaryColor, color:theme.text2Color }}>
            <div className="text-center">
              <p>Current Stage</p>
              <p className="font-semibold">
                {programData.stage}
              </p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default UserPointsStats;


// // udpate code --> 08 july 2024
// // src/components/client/Dashboard/UserPointsStats.tsx

// import React, { useState } from 'react';
// import { AiOutlineInfoCircle } from 'react-icons/ai';
// import { useDummy } from '../../../context/dummy';
// import { useTheme } from '../../../context/ThemeContext';

// const UserPointsStats: React.FC = () => {
//   const { getUser } = useDummy();
//   const { theme } = useTheme();

//   // Replace with the logic to get the currently logged-in user's email
//   const user = getUser('zeric2tan@gmail.com');

//   // Set up initial program state
//   const [currentProgram, setCurrentProgram] = useState('Go4funds Program');

//   if (!user) {
//     return null; // Render nothing if no user is found
//   }

//   const totalPoints = user.totalPoints;
//   const programData = user.programs[currentProgram];
//   const programTitles = Object.keys(user.programs);
//   const currentIndex = programTitles.indexOf(currentProgram);

//   const nextProgram = () => {
//     const nextIndex = (currentIndex + 1) % programTitles.length;
//     setCurrentProgram(programTitles[nextIndex]);
//   };

//   return (
//     // Card container
//     <div className="max-w-sm w-full h-full bg-white rounded-xl shadow-md p-6 text-center sm:max-w-lg">
      
//       {/* Card header */}
//       <div className="flex justify-center items-start w-full mb-4">
//         <div className="flex items-center">
//           <h5 className="text-lg font-semibold text-zinc-900" 
//             style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//             R One Points
//           </h5>
//           <div className="relative group ml-2">
//             <AiOutlineInfoCircle
//               className="w-4 h-4 text-gray-500 hover:text-gray-900 cursor-pointer"
//               aria-hidden="true"
//             />

//             {/* Tool Tips */}
//             <div className="absolute top-full mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block" 
//                 style={{ backgroundColor: theme.textColor, color: theme.text2Color, fontFamily: theme.fontFamily }}>
//                 User points can be used to redeem various rewards such as attending classes and other benefits.
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Total Points */}
//       <div className="border-solid border-2 border-zinc-100 rounded-xl p-4 mx-12">
//         <div className="text-3xl font-bold text-zinc-900"
//             style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//                 {totalPoints}
//         </div>
//         <p className="text-sm text-zinc-800">points</p>
//       </div>

//       <hr className="my-4" />

//       {/* User Stats */}
//       <div className="flex justify-center items-start w-full mb-4">
//         <div className="flex items-center">
//           <h5 className="text-lg font-semibold text-zinc-900" 
//             style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//             R One Program Stats
//           </h5>
//           <div className="relative group ml-2">
//             <AiOutlineInfoCircle
//               className="w-4 h-4 text-gray-500 hover:text-gray-900 cursor-pointer"
//               aria-hidden="true"
//             />

//             {/* Tool Tips */}
//             <div className="absolute top-full mt-2 hidden w-64 px-4 py-2 bg-gray-800 text-white text-sm rounded-lg shadow-lg group-hover:block"
//               style={{ backgroundColor: theme.textColor, color: theme.text2Color, fontFamily: theme.fontFamily }}>
//               User stats include information about the user's current program stages and funding.
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Program Information */}
//       <div className="flex justify-center items-center text-center mt-2 border-solid border-2 border-zinc-100 rounded-xl p-2 mx-2 cursor-pointer"
//         onClick={nextProgram}
//         style={{ fontFamily: theme.fontFamily }}>
//         <h2 className="text-xl font-bold"
//           style={{ color: theme.textColor }}>
//             {currentProgram}
//         </h2>
//       </div>

//       {/* Stats Section */}
//       <div className="mt-2 p-4 rounded-lg shadow-inner"
//         style={{ backgroundColor: theme.textColor }}>
//         <div className="flex justify-between text-md"
//            style={{ color: theme.text2Color, fontFamily: theme.fontFamily }}>
//           <div className="text-left">
//             <p>Current Stage</p>
//             <p className="font-semibold">{programData.stage}</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserPointsStats;
