// src/hooks/AdminDashboard/useTrialSetting.tsx

import { useState, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

export interface Trial {
  id: number;
  productID: string;
  planID: string;
  eventID: string;
  programID: string;
  trialID: string;
  trialName: string;
  trialDays: string;
  trialMembersType: string[];
  trialRequirement: string[];
  trialStatus: string;
  trialGroupName: string;
  trialGroupMember: string[];
}

const useTrialSetting = () => {
  const { apiUrl } = useEnv();
  const [trials, setTrials] = useState<Trial[]>([]);

  const fetchAPI = useCallback(async (endpoint: string, options?: RequestInit) => {
    try {
      const response = await fetch(`${apiUrl}${endpoint}`, options);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }, [apiUrl]);
  

  const fetchTrials = useCallback(async () => {
    try {
      const data = await fetchAPI('/api/trial-settings');
      setTrials(data);
    } catch (error) {
      console.error('Error fetching trial settings:', error);
    }
  }, [fetchAPI]);

  const saveTrial = async (trialData: any) => {
    try {
      const data = await fetchAPI('/api/save-trial-setting', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ trialData })
      });
      fetchTrials();
      return data;
    } catch (error) {
      console.error('Error saving trial setting:', error);
      throw error;
    }
  };  

  const deleteTrial = async (trialId: number) => {
    try {
      await fetchAPI('/api/delete-trial-setting', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ trialId })
      });
      fetchTrials();
    } catch (error) {
      console.error('Error deleting trial setting:', error);
      throw error;
    }
  };

  return {
    trials,
    fetchTrials,
    saveTrial,
    deleteTrial,
  };
};

export default useTrialSetting;

// // update code --> 20 Aug 2024 v02
// // src/hooks/AdminDashboard/useTrialSetting.tsx
// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Trial {
//   id: number;
//   productID: string;
//   planID: string;
//   eventID: string;
//   programID: string;
//   trialID: string;
//   trialName: string;
//   trialDays: string;
//   trialMembersType: string[];
//   trialRequirement: string[];
//   trialStatus: string;
//   trialGroupName: string;
//   trialGroupMember: string[];
// }

// const useTrialSetting = () => {
//   const { apiUrl } = useEnv();
//   const [trials, setTrials] = useState<Trial[]>([]);

//   const fetchAPI = useCallback(async (endpoint: string, options?: RequestInit) => {
//     try {
//       const response = await fetch(`${apiUrl}${endpoint}`, options);
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       return await response.json();
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       throw error;
//     }
//   }, [apiUrl]);
  

//   const fetchTrials = useCallback(async () => {
//     try {
//       const data = await fetchAPI('/api/trial-settings');
//       setTrials(data);
//     } catch (error) {
//       console.error('Error fetching trial settings:', error);
//     }
//   }, [fetchAPI]);

//   const saveTrial = async (trialData: any) => {
//     try {
//       const data = await fetchAPI('/api/save-trial-setting', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ trialData })
//       });
//       fetchTrials();
//       return data;
//     } catch (error) {
//       console.error('Error saving trial setting:', error);
//       throw error;
//     }
//   };  

//   const deleteTrial = async (trialId: number) => {
//     try {
//       await fetchAPI('/api/delete-trial-setting', {
//         method: 'DELETE',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ trialId })
//       });
//       fetchTrials();
//     } catch (error) {
//       console.error('Error deleting trial setting:', error);
//       throw error;
//     }
//   };

//   return {
//     trials,
//     fetchTrials,
//     saveTrial,
//     deleteTrial,
//   };
// };

// export default useTrialSetting;


// //  update code --> 20 Aug 2024 
// // src/hooks/AdminDashboard/useTrialSetting.ts

// import { useState } from 'react';
// import { useEnv } from '../../context/EnvContext';

// const useTrialSetting = () => {
//   const { apiUrl } = useEnv();
//   const [trials, setTrials] = useState([]);

//   const fetchAPI = async (endpoint: string, options?: RequestInit) => {
//     try {
//       const response = await fetch(`${apiUrl}${endpoint}`, options);
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       return await response.json();
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       throw error;
//     }
//   };

//   const fetchTrials = async () => {
//     try {
//       const data = await fetchAPI('/api/trial-settings');
//       setTrials(data);
//     } catch (error) {
//       console.error('Error fetching trial settings:', error);
//     }
//   };

//   const saveTrial = async (trialData: any) => {
//     try {
//       const data = await fetchAPI('/api/save-trial-setting', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ trialData })
//       });
//       fetchTrials();
//       return data;
//     } catch (error) {
//       console.error('Error saving trial setting:', error);
//     }
//   };

//   const deleteTrial = async (trialId: number) => {
//     try {
//       await fetchAPI('/api/delete-trial-setting', {
//         method: 'DELETE',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ trialId })
//       });
//       fetchTrials();
//     } catch (error) {
//       console.error('Error deleting trial setting:', error);
//     }
//   };

//   return {
//     trials,
//     fetchTrials,
//     saveTrial,
//     deleteTrial,
//   };
// };

// export default useTrialSetting;
