// src/context/dummy.tsx

// for stages will have 5: 
// - R One Guest (stage 1), -> light green
// - R One Member (stage 2), -> rone primary color
// - R One Elite Trader(stage 3), -> neon blue
// - R One Master Trader(stage 4), -> gold color 
// - R One Certified Trader (stage 5) -> neon purple

// for badges will; have 3:
// - R One Guest (stage 1) and R One Member (stage 2) will be bronze 
// - R One Elite Trader(stage 3) will be Silver
// - R One Master Trader(stage 4) will be Gold
// - R One Certified Trader (stage 5) will be Diamond

// import react components 
import React, { createContext, useContext, ReactNode } from 'react';

interface Program {
  title: string;
  stage: string;
  funded: string;
}

interface User {
  name: string;
  email: string;
  phone: string;
  address: string;
  title: string;
  avatar: string;
  badges: number;
  spentPoints: number;
  unspentPoints: number;
  totalPoints: number;
  ranking: string;
  level: number;
  stats: string;
  achievement: string;
  programs: Record<string, Program>;
}

interface DummyContextProps {
  verifyEmail: (inputEmail: string) => boolean;
  verifyCredentials: (inputEmail: string, inputPassword: string) => boolean;
  isAdmin: (inputEmail: string) => boolean;
  getUser: (inputEmail: string) => User | undefined;
}

const DummyContext = createContext<DummyContextProps | undefined>(undefined);

interface DummyProviderProps {
  children: ReactNode;
}

const DummyProvider: React.FC<DummyProviderProps> = ({ children }) => {
  const clientEmail = 'user@gmail.com';
  const adminEmail = 'admin@gmail.com';
  const validPassword = 'abc123';

  const zericTan: User = {
    name: 'Zeric Tan',
    email: 'zeric2tan@gmail.com',
    phone: '+60 173505884',
    title: 'R One Trader',
    address: 'Green Park Residence, Jalan Green Park Residence',
    avatar: 'https://i.imgur.com/AfFp7pu.png',
    badges: 5,
    spentPoints: 0,
    unspentPoints: 0,
    totalPoints: 0,
    ranking: 'Gold',
    level: 8,
    stats: 'Active',
    achievement: 'R One Master Trader',
    programs: {
      'Go4funds Program': { title: 'Go4funds Program', stage: 'Not Joined', funded: '0' },
      '180 Program': { title: '180 Program', stage: 'Not Joined', funded: '0' },
    },
  };

  const user: User = {
    name: 'User',
    email: clientEmail,
    phone: '+60 123456789',
    title: 'Client',
    address: '123 Main Street, Anytown',
    avatar: 'https://i.imgur.com/AfFp7pu.png',
    badges: 2,
    spentPoints: 100,
    unspentPoints: 200,
    totalPoints: 300,
    ranking: 'Silver',
    level: 5,
    stats: 'Active',
    achievement: 'Regular Client',
    programs: {
      'Basic Program': { title: 'Basic Program', stage: 'Initial Stage', funded: '$500' },
    },
  };

  const admin: User = {
    name: 'Admin',
    email: adminEmail,
    phone: '+60 987654321',
    title: 'Administrator',
    address: 'Admin Building, Admin Street',
    avatar: 'https://i.imgur.com/AfFp7pu.png',
    badges: 10,
    spentPoints: 0,
    unspentPoints: 1000,
    totalPoints: 1000,
    ranking: 'Platinum',
    level: 10,
    stats: 'Active',
    achievement: 'System Administrator',
    programs: {},
  };

  const verifyEmail = (inputEmail: string) =>
    inputEmail === clientEmail || inputEmail === adminEmail || inputEmail === zericTan.email;
  const verifyCredentials = (inputEmail: string, inputPassword: string) =>
    (inputEmail === clientEmail || inputEmail === adminEmail || inputEmail === zericTan.email) && inputPassword === validPassword;
  const isAdmin = (inputEmail: string) => inputEmail === adminEmail;
  const getUser = (inputEmail: string): User | undefined => {
    if (inputEmail === zericTan.email) return zericTan;
    if (inputEmail === user.email) return user;
    if (inputEmail === admin.email) return admin;
    return undefined;
  };

  return (
    <DummyContext.Provider value={{ verifyEmail, verifyCredentials, isAdmin, getUser }}>
      {children}
    </DummyContext.Provider>
  );
};

const useDummy = (): DummyContextProps => {
  const context = useContext(DummyContext);
  if (context === undefined) {
    throw new Error('useDummy must be used within a DummyProvider');
  }
  return context;
};

export { DummyProvider, useDummy };


// // udpate code --> 29 May 2024
// // src/context/dummy.tsx

// // Import React components and types
// import React, { createContext, useContext, ReactNode } from 'react';

// interface Program {
//   title: string;
//   stage: string;
//   funded: string;
// }

// interface User {
//   name: string;
//   email: string;
//   phone: string;
//   address: string;
//   title: string;
//   avatar: string;
//   badges: number;
//   spentPoints: number;
//   unspentPoints: number;
//   totalPoints: number;
//   ranking: string;
//   level: number;
//   stats: string;
//   achievement: string;
//   programs: Record<string, Program>;
// }

// interface DummyContextProps {
//   verifyEmail: (inputEmail: string) => boolean;
//   verifyCredentials: (inputEmail: string, inputPassword: string) => boolean;
//   isAdmin: (inputEmail: string) => boolean;
//   user: User;
// }

// const DummyContext = createContext<DummyContextProps | undefined>(undefined);

// interface DummyProviderProps {
//   children: ReactNode;
// }

// const DummyProvider: React.FC<DummyProviderProps> = ({ children }) => {
//   const clientEmail = 'user@gmail.com';
//   const adminEmail = 'admin@gmail.com';
//   const password = 'abc123';

//   const user: User = {
//     name: 'Zeric Tan',
//     email: 'zeric2tan@gmail.com',
//     phone: '+60 173505884',
//     title: 'R One Trader',
//     address: 'Green Park Residence, Jalan Green Park Residence',
//     avatar: 'https://i.imgur.com/AfFp7pu.png',
//     badges: 5,
//     spentPoints: 400,
//     unspentPoints: 800,
//     totalPoints: 1200,
//     ranking: 'Gold',
//     level: 8,
//     stats: 'Active',
//     achievement: 'R One Trader Coach',
//     programs: {
//       'Go4funds Program': { title: 'Go4funds Program', stage: 'Funded Trader', funded: '$100,000' },
//       '180 Program': { title: '180 Program', stage: 'Evaluation Stage 2', funded: '$1,000' },
//       'Trader Coach Program': { title: 'Trained Coach Trader', stage: 'R One Trader Coach', funded: '$10,000' },
//     },
//   };

//   const verifyEmail = (inputEmail: string) => inputEmail === clientEmail || inputEmail === adminEmail;
//   const verifyCredentials = (inputEmail: string, inputPassword: string) =>
//     (inputEmail === clientEmail || inputEmail === adminEmail) && inputPassword === password;
//   const isAdmin = (inputEmail: string) => inputEmail === adminEmail;

//   return (
//     <DummyContext.Provider value={{ verifyEmail, verifyCredentials, isAdmin, user }}>
//       {children}
//     </DummyContext.Provider>
//   );
// };

// const useDummy = (): DummyContextProps => {
//   const context = useContext(DummyContext);
//   if (context === undefined) {
//     throw new Error('useDummy must be used within a DummyProvider');
//   }
//   return context;
// };

// export { DummyProvider, useDummy };