// src/pages/p02RequestResetPassword.tsx

// import react components
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import file from source
import { useTheme } from '../context/ThemeContext';
import { useReset } from '../hooks/Login/useReset';
import AppFooter from '../components/Footer'; 
import ResendResetPasswordAlert from '../components/login/resendResetPasswordAlert'; 
import r1bg from '../assets/login/bg_r1.png' 

const RequestResetPassword: React.FC = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { resetPassword } = useReset();
  const [email, setEmail] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleRequest = async () => {
    const success = await resetPassword(email);
    setIsSuccess(!!success);
    setAlertVisible(true);
    if (success) {
      setTimeout(() => {
        navigate('/login'); // Redirect to login page
      }, 3000); // Redirect after 3 seconds
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-cover bg-center" 
      style={{ 
        backgroundImage: `url(${r1bg})`, 
        backgroundSize: 'cover' 
      }}>
      <div className="flex-grow flex items-center justify-center bg-gray-100 px-4">
        <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
          <h2 className="text-center text-xl font-bold mb-4"
            style={{ fontFamily: theme.fontFamily, color: theme.textColor }}>
              Reset Link Expired
          </h2>
          <p className="mb-4" style={{ fontFamily: theme.fontFamily, color: theme.textColor }}>
            Your reset link has expired. Please enter your email below to request a new password reset email.
          </p>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-full p-2 mb-4 border rounded"
            style={{ fontFamily: theme.fontFamily, color: theme.textColor }}
          />
          <button
            className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
            onClick={handleRequest}
            style={{ fontFamily: theme.fontFamily, color: theme.text2Color, backgroundColor: theme.primaryColor }}
          >
            Request Password Reset
          </button>
        </div>
      </div>
      <footer 
        className="w-full py-4"
        style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }}
      >
        <AppFooter />
      </footer>
      <ResendResetPasswordAlert visible={alertVisible} onClose={() => setAlertVisible(false)} isSuccess={isSuccess} /> {/* Add the resend alert */}
    </div>
  );
};

export default RequestResetPassword;

