// src/context/ThemeContext.tsx

import React, { 
  createContext, useState, useContext, 
  ReactNode, CSSProperties, useEffect 
} from 'react';

interface Theme {
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
  text2Color: string;
  backgroundColor: string;
  fontFamily: string;
  fontWeightRegular: number;
  fontWeightMedium: number;
  stages: {
    guest: string;
    member: string;
    eliteTrader: string;
    masterTrader: string;
    certifiedTrader: string;
  };
  badges: {
    copper: string;
    bronze: string;
    silver: string;
    gold: string;
    diamond: string;
  };
}

interface ThemeContextType {
  theme: Theme;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

const themes = {
  light: {
    primaryColor: '#0f5469',
    secondaryColor: '#428D86',
    textColor: '#062e3f',
    text2Color: 'white',
    backgroundColor: 'white',
    fontFamily: 'Noto Sans, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    stages: { // Modification: Define stage colors
      guest: '#D2B48C', // light brown
      member: '#0C4657', // rone primary color
      eliteTrader: '#0AFFEF', // neon blue
      masterTrader: '#FFD700', // gold color
      certifiedTrader: '#DA70D6', // neon purple
    },
    badges: { // Modification: Define badge colors
      copper: '#B87333', // copper
      bronze: '#CD7F32', // bronze
      silver: '#C0C0C0', // silver
      gold: '#FFD700', // gold
      diamond: '#B9F2FF', // diamond
    },
  },
  dark: {
    primaryColor: '#0f5469',
    secondaryColor: '#428D86',
    textColor: 'white',
    text2Color: '#062e3f',
    backgroundColor: '#001529',
    fontFamily: 'Noto Sans, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    stages: { // Modification: Define stage colors
      guest: '#FCF8F3', // light brown
      member: '#0f5469', // rone primary color
      eliteTrader: '#0AFFEF', // neon blue
      masterTrader: '#FFD700', // gold color
      certifiedTrader: '#DA70D6', // neon purple
    },
    badges: { // Modification: Define badge colors
      copper: '#B87333', // copper
      bronze: '#CD7F32', // bronze
      silver: '#C0C0C0', // silver
      gold: '#FFD700', // gold
      diamond: '#B9F2FF', // diamond
    },
  },
};

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(themes.light);

  const toggleTheme = () => {
    setTheme(theme === themes.light ? themes.dark : themes.light);
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      /* Hide scrollbar for Chrome, Safari, and Opera */
      ::-webkit-scrollbar {
        display: none;
      }
      /* Hide scrollbar for IE, Edge, and Firefox */
      body {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div
        style={{
          '--primary-color': theme.primaryColor,
          '--text-color': theme.textColor,
          '--text2-color': theme.text2Color,
          '--background-color': theme.backgroundColor,
          '--font-family': theme.fontFamily,
          '--font-weight-regular': theme.fontWeightRegular.toString(),
          '--font-weight-medium': theme.fontWeightMedium.toString(),
        } as CSSProperties}
        className="font-sans text-base"
      >
        {children}
      </div>
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};


// // udpate code --> 12 june 2024
// // src/context/ThemeContext.tsx

// import React, { createContext, useState, useContext, ReactNode, CSSProperties, useEffect } from 'react';

// interface Theme {
//   primaryColor: string;
//   secondaryColor: string;
//   textColor: string;
//   text2Color: string;
//   backgroundColor: string;
//   fontFamily: string;
//   fontWeightRegular: number;
//   fontWeightMedium: number;
// }

// interface ThemeContextType {
//   theme: Theme;
//   toggleTheme: () => void;
// }

// const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

// const themes = {
//   light: {
//     primaryColor: '#0f5469',
//     secondaryColor: '#428D86',
//     textColor: '#062e3f',
//     text2Color: 'white',
//     backgroundColor: 'white',
//     fontFamily: 'Noto Sans, sans-serif',
//     fontWeightRegular: 400,
//     fontWeightMedium: 500,
//   },
//   dark: {
//     primaryColor: '#0f5469',
//     secondaryColor: '#428D86',
//     textColor: 'white',
//     text2Color: '#062e3f',
//     backgroundColor: '#001529',
//     fontFamily: 'Noto Sans, sans-serif',
//     fontWeightRegular: 400,
//     fontWeightMedium: 500,
//   },
// };

// interface ThemeProviderProps {
//   children: ReactNode;
// }

// export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
//   const [theme, setTheme] = useState<Theme>(themes.light);

//   const toggleTheme = () => {
//     setTheme(theme === themes.light ? themes.dark : themes.light);
//   };

//   useEffect(() => {
//     const style = document.createElement('style');
//     style.innerHTML = `
//       /* Hide scrollbar for Chrome, Safari, and Opera */
//       ::-webkit-scrollbar {
//         display: none;
//       }
//       /* Hide scrollbar for IE, Edge, and Firefox */
//       body {
//         -ms-overflow-style: none;  /* IE and Edge */
//         scrollbar-width: none;  /* Firefox */
//       }
//     `;
//     document.head.appendChild(style);
//     return () => {
//       document.head.removeChild(style);
//     };
//   }, []);

//   return (
//     <ThemeContext.Provider value={{ theme, toggleTheme }}>
//       <div
//         style={{
//           '--primary-color': theme.primaryColor,
//           '--text-color': theme.textColor,
//           '--text2-color': theme.text2Color,
//           '--background-color': theme.backgroundColor,
//           '--font-family': theme.fontFamily,
//           '--font-weight-regular': theme.fontWeightRegular.toString(),
//           '--font-weight-medium': theme.fontWeightMedium.toString(),
//         } as CSSProperties}
//         className="font-sans text-base"
//       >
//         {children}
//       </div>
//     </ThemeContext.Provider>
//   );
// };

// export const useTheme = (): ThemeContextType => {
//   const context = useContext(ThemeContext);
//   if (context === undefined) {
//     throw new Error('useTheme must be used within a ThemeProvider');
//   }
//   return context;
// };