// src/hooks/Subscription/useSubSettingGroupSet.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

export const useSubscriptionGroupSet = () => {
  const { apiUrl } = useEnv();
  const [emails, setEmails] = useState<string[]>([]);
  const [filteredEmails, setFilteredEmails] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  // Memoize fetchEmails to prevent unnecessary re-renders
  const fetchEmails = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/api/fetch-emails`);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch emails: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      setEmails(data);
      setFilteredEmails(data);
  
    } catch (error) {
      console.error('Error fetching emails:', error);
    }
  }, [apiUrl]); // Dependency on apiUrl

  useEffect(() => {
    fetchEmails();
  }, [fetchEmails]); // Dependency on fetchEmails

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
    setFilteredEmails(emails.filter(email => email.toLowerCase().includes(query.toLowerCase())));
  };

  return {
    emails,
    filteredEmails,
    setFilteredEmails,
    searchQuery,
    handleSearchChange,
  };
};

