// src/componentst/chat/ChatData.tsx

export const topics = [
    'General',
    'Login Credentials',
    'Registration',
    'Contact Support'
  ];
  
  export const questionsAndAnswers: Record<string, Record<string, string>> = {
    'General': {
      'What is this platform?': 'R One Century is a premier financial service and education platform. We empower clients with expert resources and tools for financial solution.',
      'How to use this platform?': 'You can start by registering an account on login page by clicking <strong>register here</strong> .'
    },
    'Login Credentials': {
      'Forget your password?': 'You can click on the "Forgot Password" to reset your password',
    },
    'Registration': {
      'How to register?': 'Go to the registration page and fill in the required details...',
    },
    'Contact Support': {
      'Need technical support?': 'For technical issues, please email <a href="mailto:general@r1trader.com" style="color:blue; text-decoration:underline; text-bold;">general@r1trader.com</a>',
      'Need chat support?': 'Please contact <strong>@SkyWoon</strong> via Telegram or email <a href="mailto:skytl@r1trader.com" style="color:blue; text-decoration:underline; text-bold;">skytl@r1trader.com</a>',
      'What are the support hours?': 'Our support team is available Mon-Fri: 9.00am to 5.00pm (GST+8)...'
    },
  };
        