// src/components/admin/MarketSetting/CampingLogic.tsx

import React, { useState, useEffect } from 'react';
import { IoIosRemoveCircle, IoMdClose } from "react-icons/io";
import { BiSolidDownArrow } from "react-icons/bi";
import { RiSettingsFill } from "react-icons/ri";
import { LuWorkflow } from "react-icons/lu";
import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';  

// Interface definitions
interface CampaignNode {
  id: string;
  content: string;
  settings?: any;
}

interface NodeType {
  id: string;
  content: string;
}

const nodeTypes: NodeType[] = [
  { id: '2', content: 'setFollowUp' },
  { id: '4', content: 'setDate' },
  { id: '5', content: 'setTime' },
  { id: '6', content: 'setMailContent' },
  { id: '7', content: 'setCondition' },
  { id: '8', content: 'setEnd' },
  { id: '9', content: 'setClicked'}
];

// Helper function to format time in hh:mm am/pm
const formatTime = (timeString: string) => {
  const [hoursStr, minutes] = timeString.split(':'); 
  let hours = parseInt(hoursStr, 10);
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12; 
  const minutesStr = minutes.padStart(2, '0'); 
  return `${hours}:${minutesStr} ${ampm}`;
};

const CampaignLogic: React.FC<{ workflowName: string, closeWorkflow: () => void }> = ({ workflowName, closeWorkflow  }) => {
  // Use your custom hook to manage nodes
  const { addNode, deleteNode, updateNode, fetchNodes } = useEmail();
  const [workflowNodes, setWorkflowNodes] = useState<CampaignNode[]>([
    { id: '1', content: 'setStart' },
  ]);
  const [showNodeList, setShowNodeList] = useState(false);
  const [selectedNode, setSelectedNode] = useState<CampaignNode | null>(null);
  // Node setting state
  const [startEnableSettings, setStartEnableSettings] = useState({ start:'', });
  const [endEnableSettings, setEndEnableSettings] = useState({ end:'' });
  const [mailContentSettings, setMailContentSettings] = useState({ template: '', });
  const [inputDateSettings, setInputDateSettings] = useState({ date: '' });
  const [inputTimeSettings, setInputTimeSettings] = useState({ time: '' });
  const [inputFollowUpSettings, setInputFollowUpSettings] = useState({ followup: '' });
  const [conditionSettings, setConditionSettings] = useState({ ifCondition: '', elseCondition: '' });

  useEffect(() => {
    // Fetch the nodes for the current workflow
    fetchNodes(1); // Assuming a fixed workflowId of 1
  }, [fetchNodes]);

  const handleNodeClick = (nodeType: NodeType) => {
    const newNode = {
      workflowId: 1, 
      workflowName: "Example Workflow", 
      workflowStatus: "active", 
      clickedStats: "0", 
      nodeId: Math.random(), 
      nodeType: nodeType.content,
      nodePosX: Math.random() * 200, 
      nodePosY: Math.random() * 200,
      nodeData: { [nodeType.content]: '' },
      createdAt: new Date()
    };
  
    addNode(newNode); // Now it should be valid
    setWorkflowNodes((prevNodes) => [...prevNodes, { id: `${Math.random()}`, content: nodeType.content }]);
    setShowNodeList(false);
  };
  
  const handleRemoveNode = (id: string) => {
    // Remove from both local state and API using deleteNode
    setWorkflowNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
    deleteNode(Number(id));
  };

  const openSettingsModal = (node: CampaignNode) => {
    setSelectedNode(node);
  };

  const closeModal = () => {
    setShowNodeList(false);
    setSelectedNode(null);
  };

  const saveNodeSettings = (settingsKey: string, settingsValue: any) => {
    if (selectedNode) {
      const updatedNode = {
        ...selectedNode,
        settings: {
          ...selectedNode.settings,
          [settingsKey]: settingsValue,
        },
      };
  
      const updatedNodeData = { [selectedNode.content]: settingsValue };
  
      setWorkflowNodes((prevNodes) =>
        prevNodes.map((node) => (node.id === selectedNode.id ? updatedNode : node))
      );
  
      updateNode({
        id: Number(updatedNodeData.id), // Node ID
        workflowId: 1, // Assuming a fixed workflowId for now
        workflowName: updatedNodeData.workflowName || 'Default Workflow Name', 
        workflowStatus: updatedNodeData.workflowStatus || 'stop', 
        clickedStats: updatedNodeData.clickedStats || '0',
        nodeId: updatedNodeData.nodeId || 1, 
        nodeType: updatedNodeData.content, 
        nodePosX: updatedNodeData.nodePosX || Math.random() * 200, 
        nodePosY: updatedNodeData.nodePosY || Math.random() * 200, 
        nodeData: updatedNodeData,
        createdAt: updatedNodeData.createdAt
      });
    }
    closeModal();
  };  
  
  const saveStartEnableSettings = () => {
    saveNodeSettings('start', startEnableSettings.start);
  };

  const saveEndEnableSettings = () => {
    saveNodeSettings('end', endEnableSettings.end);
  };

  const saveMailContentSettings = () => {
    saveNodeSettings('template', mailContentSettings.template);
  };

  const saveInputDateSettings = () => {
    saveNodeSettings('date', inputDateSettings.date);
  };

  const saveInputTimeSettings = () => {
    saveNodeSettings('time', inputTimeSettings.time);
  };

  const saveInputFollowUpSettings = () => {
    saveNodeSettings('followup', inputFollowUpSettings.followup);
  };

  const saveConditionSettings = () => {
    saveNodeSettings('ifCondition', conditionSettings.ifCondition);
    saveNodeSettings('elseCondition', conditionSettings.elseCondition);
  };

  const renderSettingsModal = () => {
    if (!selectedNode) return null;

    switch (selectedNode.content) {
      case 'setMailContent':
        return (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
              <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
                Set Mail Content
              </h3>
              <div className="py-6">
                <label className="block mx-2 text-sm text-cyan-800 font-semibold">
                  Select Mail Template:
                </label>
                <select
                  className="w-full p-2 border rounded text-sm"
                  value={mailContentSettings.template}
                  onChange={(e) => setMailContentSettings((prev) => ({ ...prev, template: e.target.value }))}
                >
                  <option value="">Select a template</option>
                  <option value="template1">Template 1</option>
                  <option value="template2">Template 2</option>
                </select>
              </div>
              <div className="flex justify-end items-center space-x-2">
                <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
                  Close
                </button>
                <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveMailContentSettings}>
                  Save
                </button>
              </div>
            </div>
          </div>
        );
      case 'setDate':
        return (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
              <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
                Set Date
              </h3>
              <div className="flex flex-col my-4">
                <div className="mx-2 font-bold text-cyan-800 text-sm">Set a executed date:</div>
                <input
                  type="date"
                  className="w-full p-2 border rounded"
                  value={inputDateSettings.date}
                  onChange={(e) => 
                    setInputDateSettings({ date:e.target.value, })
                  }
                />
              </div>
              <div className="flex justify-end items-center space-x-2">
                <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
                  Close
                </button>
                <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputDateSettings}>
                  Save
                </button>
              </div>
            </div>
          </div>
        );
      case 'setTime':
        return (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
              <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
                Set Time
              </h3>
              <div className="flex flex-col my-4">
                <div className="mx-2 font-bold text-cyan-800 text-sm">Set a executed time:</div>
                <input
                  type="time"
                  className="w-full p-2 border rounded"
                  value={inputTimeSettings.time}
                  onChange={(e) => 
                    setInputTimeSettings({ time:e.target.value })
                  }
                />
              </div>
              <div className="flex justify-end items-center space-x-2">
                <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
                  Close
                </button>
                <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputTimeSettings}>
                  Save
                </button>
              </div>
            </div>
          </div>
        );
      case 'setStart':
        return (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
              <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
                Set Start
              </h3>
              <div className="flex justify-between px-4 py-2 border rounded">
                <div className="flex justify-normal items-center">
                  <input
                    type="checkbox"
                    className="mr-1 accent-cyan-800"
                    onChange={(e) => {
                      const enabled = e.target.checked ? 'enabled' : 'disabled';
                      setStartEnableSettings({
                        start: enabled,
                      });
                    }}
                  />
                  <div className="text-cyan-800 text-sm font-bold">Set start flow</div>
                </div>
                <span
                  className={`text-sm ${
                    startEnableSettings.start === 'enabled'
                      ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
                      : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
                  }`}
                >
                  {startEnableSettings.start === 'enabled' ? 'Enabled' : 'Disabled'}
                </span>
              </div>
              <div className="flex justify-end items-center space-x-2 my-2">
                <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
                  Close
                </button>
                <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveStartEnableSettings}>
                  Save
                </button>
              </div>
            </div>
          </div>
        );
      case 'setFollowUp':
        return (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
              <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
                Set Follow-Up
              </h3>
              <div className="flex flex-col items-start p-2">
                <div className="text-cyan-800 text-sm font-bold mx-4">Follow-Up Period:</div>
                <div className="flex justify-normal w-full">
                  <input
                    type="number"
                    className="w-full p-2 border rounded-l"
                    value={inputFollowUpSettings.followup}
                    onChange={(e) => setInputFollowUpSettings({ followup: e.target.value })}
                  />
                  <span className="text-base border p-2 rounded-r">Days</span>
                </div>
              </div>
              <div className="flex justify-end items-center space-x-2 my-2">
                <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
                  Close
                </button>
                <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputFollowUpSettings}>
                  Save
                </button>
              </div>
            </div>
          </div>
        );
      case 'setCondition':
        return (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <div className="w-1/3 p-6 bg-white border border-gray-300 rounded shadow-md relative">
              <h3 className="text-lg text-center text-white font-semibold bg-cyan-800 rounded-full mx-20 px-4 py-1 mb-4">
                Set Condition
              </h3>

              {/* Enable All/Disable All Checkbox */}
              <div className="mb-4 flex items-center">
                <input
                  type="checkbox"
                  className="mr-2 accent-cyan-800"
                  onChange={(e) => {
                    const enabled = e.target.checked ? 'enabled' : 'disabled';
                    setConditionSettings({
                      ifCondition: enabled,
                      elseCondition: enabled,
                    });
                  }}
                />
                <label className="block text-sm">Enable All</label>
              </div>

              {/* If Condition */}
              <div className="mb-2 flex items-center">
                <input
                  type="checkbox"
                  className="mr-2 accent-cyan-800"
                  checked={conditionSettings.ifCondition === 'enabled'}
                  onChange={(e) =>
                    setConditionSettings({
                      ...conditionSettings,
                      ifCondition: e.target.checked ? 'enabled' : 'disabled',
                    })
                  }
                />
                <label className="mr-auto">If Condition</label>
                <span
                  className={`${
                    conditionSettings.ifCondition === 'enabled'
                      ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
                      : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
                  }`}
                >
                  {conditionSettings.ifCondition === 'enabled' ? 'Enabled' : 'Disabled'}
                </span>
              </div>

              {/* Else Condition */}
              <div className="mb-2 flex items-center">
                <input
                  type="checkbox"
                  className="mr-2 accent-cyan-800"
                  checked={conditionSettings.elseCondition === 'enabled'}
                  onChange={(e) =>
                    setConditionSettings({
                      ...conditionSettings,
                      elseCondition: e.target.checked ? 'enabled' : 'disabled',
                    })
                  }
                />
                <label className="mr-auto">Else Condition</label>
                <span
                  className={`${
                    conditionSettings.elseCondition === 'enabled'
                      ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
                      : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
                  }`}
                >
                  {conditionSettings.elseCondition === 'enabled' ? 'Enabled' : 'Disabled'}
                </span>
              </div>

              <div className="flex justify-end items-center space-x-2 mt-4 p-2">
                <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
                  Close
                </button>
                <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveConditionSettings}>
                  Save
                </button>
              </div>
            </div>
          </div>
        );
      case 'setEnd':
        return (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
              <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
                Set End
              </h3>
              <div className="flex justify-between px-4 py-2 border rounded">
                <div className="flex justify-normal items-center">
                  <input
                    type="checkbox"
                    className="mr-1 accent-cyan-800"
                    onChange={(e) => {
                      const enabled = e.target.checked ? 'enabled' : 'disabled';
                      setEndEnableSettings({
                        end: enabled,
                      });
                    }}
                  />
                  <div className="text-cyan-800 text-sm font-bold">Set end flow</div>
                </div>
                <span
                  className={`text-sm ${
                    endEnableSettings.end === 'enabled'
                      ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
                      : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
                  }`}
                >
                  {endEnableSettings.end === 'enabled' ? 'Enabled' : 'Disabled'}
                </span>
              </div>
              <div className="flex justify-end items-center space-x-2 my-2">
                <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
                  Close
                </button>
                <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveEndEnableSettings}>
                  Save
                </button>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  // Helper function to render node settings based on node content
  const renderNodeSettings = (node: CampaignNode) => {
    if (!node.settings) return null;

    switch (node.content) {
      case 'setFollowUp':
        return (
          <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
            Period:<span className="font-bold ml-2">{node.settings.followup} Days</span>  
          </div>
        );
      case 'setDate':
        return (
          <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
            <div className="flex justify-normal">
              Date:<span className="font-bold ml-2">{node.settings.date}</span>
            </div>
          </div>
        );
      case 'setTime':
        return (
          <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
            <div className="flex justify-normal">
              Time:<span className="font-bold ml-2">{formatTime(node.settings.time)}</span>
            </div>
          </div>
        );
      case 'setMailContent':
        return (
          <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
            <div>
              Template:<span className="font-semibold ml-2">{node.settings.template}</span>
            </div>
          </div>
        );
      case 'setCondition':
        return (
          <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
            <div className="flex justify-normal">
              if Condition:<span className="font-bold ml-2">{node.settings.ifCondition}</span>
            </div>
            <div className="flex justify-normal">
              else Condition:<span className="font-bold ml-2">{node.settings.elseCondition}</span>
            </div>
          </div>
        );
      case 'setStart':
        return (
          <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
            Status:<span className="font-bold ml-2">{node.settings.start}</span>
          </div>
        );
      case 'setEnd':
        return (
          <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
            Status:<span className="font-bold ml-2">{node.settings.end}</span>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mb-4">
      {/* workflow area */}
      <div className="flex justify-between items-center">
        <div className="flex justify-normal items-center mb-2">
          <LuWorkflow className="text-cyan-800 text-2xl mr-2"/>
          <h3 className="text-2xl font-semibold text-cyan-800">Workflow Area: {workflowName}</h3>
        </div>
        <select
          className="px-4 py-1"
        >
          <option value="">Select a workflow</option>
          <option value="">Follow Up Mail</option>
          <option value="">Ended Mail</option>
        </select>
      </div>
      <div className="relative w-auto h-auto overflow-y-auto max-h-[600px] border-cyan-800 border-2 rounded-xl">
        <div
          className="p-8 rounded overflow-hidden relative min-h-[500px]"
          style={{
            backgroundImage:
              'url("data:image/svg+xml,%3Csvg width=\'40\' height=\'40\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'none\' stroke=\'%23d3d3d3\' stroke-width=\'1\'%3E%3Cpath d=\'M40 0L0 0 0 40\'/%3E%3C/g%3E%3C/svg%3E")',
            backgroundSize: '40px 40px',
          }}
        >
          <div className="flex flex-col items-center">
            {workflowNodes.map((node, index) => (
              <div key={node.id} className="flex flex-col items-center">
                {index > 0 && <div className="border-x border-cyan-600 h-10"></div>}
                <BiSolidDownArrow className="cursor-pointer text-cyan-800 text-xs" />
                <div className="text-sm relative px-4 py-4 border-black-300 border rounded bg-white shadow-lg min-w-[250px]">
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col">
                      {node.content}
                      {renderNodeSettings(node)}
                    </div>
                      <RiSettingsFill
                        className="cursor-pointer text-cyan-800 text-xl"
                        onClick={() => openSettingsModal(node)}
                      />
                  </div>
                </div>
                {node.id !== '1' && (
                  <IoIosRemoveCircle
                    className="cursor-pointer text-rose-500"
                    onClick={() => handleRemoveNode(node.id)}
                  />
                )}
              </div>
            ))}
            <div className="flex flex-col items-center">
              <div className="border-x border-cyan-600 h-10"></div>
              <div
                className="px-4 py-2 border-2 border-dashed border-gray-300 text-gray-400 rounded bg-white shadow-md min-w-[150px] text-center text-sm cursor-pointer"
                onClick={() => setShowNodeList(true)}
              >
                add node
              </div>
            </div>
          </div>
        </div>

        {/* show node setting */}
        {showNodeList && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Select a Node</h3>
                <IoMdClose
                  size={24}
                  className="cursor-pointer"
                  onClick={closeModal}
                />
              </div>
              <input
                type="text"
                placeholder="Search for nodes..."
                className="w-full mb-2 p-2 border rounded"
              />
              <div className="flex flex-col space-y-2 border-2 p-2 overflow-y-auto h-[340px]">
                {nodeTypes.map((nodeType) => (
                  <div
                    key={nodeType.id}
                    onClick={() => handleNodeClick(nodeType)}
                    className="p-4 border border-cyan-600 rounded bg-white shadow-md cursor-pointer"
                  >
                    {nodeType.content}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {renderSettingsModal()}
      </div>
      <div className="flex justify-center items-center space-x-4">
        <button
          className="bg-gray-200 text-gray-500 px-4 py-1 rounded-full mt-4"
          onClick={closeWorkflow}
        >
          Close Workflow
        </button>
        <button
          className="bg-cyan-800 text-white px-4 py-1 rounded-full mt-4"
        >
          Save Workflow
        </button>
      </div>
    </div>
  );
};

export default CampaignLogic;

// // update code --> 13 sept 2024
// // src/components/admin/MarketSetting/CampingLogic.tsx

// import React, { useState, useEffect } from 'react';
// import { IoIosRemoveCircle, IoMdClose } from "react-icons/io";
// import { BiSolidDownArrow } from "react-icons/bi";
// import { RiSettingsFill } from "react-icons/ri";
// import { LuWorkflow } from "react-icons/lu";
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';  // import your custom hook

// // Interface definitions
// interface CampaignNode {
//   id: string;
//   content: string;
//   settings?: any;
// }

// interface NodeType {
//   id: string;
//   content: string;
// }

// const nodeTypes: NodeType[] = [
//   { id: '2', content: 'setFollowUp' },
//   { id: '4', content: 'setDate' },
//   { id: '5', content: 'setTime' },
//   { id: '6', content: 'setMailContent' },
//   { id: '7', content: 'setCondition' },
//   { id: '8', content: 'setEnd' },
//   { id: '9', content: 'setClicked'}
// ];

// // Helper function to format time in hh:mm am/pm
// const formatTime = (timeString: string) => {
//   const [hoursStr, minutes] = timeString.split(':'); 
//   let hours = parseInt(hoursStr, 10);
//   const ampm = hours >= 12 ? 'pm' : 'am';
//   hours = hours % 12 || 12; 
//   const minutesStr = minutes.padStart(2, '0'); 
//   return `${hours}:${minutesStr} ${ampm}`;
// };

// const CampaignLogic: React.FC<{ workflowName: string }> = ({ workflowName }) => {
//   // Use your custom hook to manage nodes
//   const { addNode, deleteNode, updateNode, fetchNodes } = useEmail();
//   const [workflowNodes, setWorkflowNodes] = useState<CampaignNode[]>([
//     { id: '1', content: 'setStart' },
//   ]);
//   const [showNodeList, setShowNodeList] = useState(false);
//   const [selectedNode, setSelectedNode] = useState<CampaignNode | null>(null);
//   // Node setting state
//   const [startEnableSettings, setStartEnableSettings] = useState({ start:'', });
//   const [endEnableSettings, setEndEnableSettings] = useState({ end:'' });
//   const [mailContentSettings, setMailContentSettings] = useState({ template: '', });
//   const [inputDateSettings, setInputDateSettings] = useState({ date: '' });
//   const [inputTimeSettings, setInputTimeSettings] = useState({ time: '' });
//   const [inputFollowUpSettings, setInputFollowUpSettings] = useState({ followup: '' });
//   const [conditionSettings, setConditionSettings] = useState({ ifCondition: '', elseCondition: '' });

//   useEffect(() => {
//     // Fetch the nodes for the current workflow
//     fetchNodes(1); // Assuming a fixed workflowId of 1
//   }, [fetchNodes]);

//   const handleNodeClick = (nodeType: NodeType) => {
//     const newNode = {
//       workflowId: 1, // Fixed workflowId for now
//       workflowName: "Example Workflow", // Add this
//       workflowStatus: "active", // Add this (or any status you want)
//       clickedStats: "0", // Add this (default stats)
//       nodeId: Math.random(), // You may want to auto-generate or fetch an ID for the node
//       nodeType: nodeType.content,
//       nodePosX: Math.random() * 200,  // Example positioning
//       nodePosY: Math.random() * 200,
//       nodeData: { [nodeType.content]: '' }
//     };
  
//     addNode(newNode); // Now it should be valid
//     setWorkflowNodes((prevNodes) => [...prevNodes, { id: `${Math.random()}`, content: nodeType.content }]);
//     setShowNodeList(false);
//   };
  
//   const handleRemoveNode = (id: string) => {
//     // Remove from both local state and API using deleteNode
//     setWorkflowNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
//     deleteNode(Number(id));
//   };

//   const openSettingsModal = (node: CampaignNode) => {
//     setSelectedNode(node);
//   };

//   const closeModal = () => {
//     setShowNodeList(false);
//     setSelectedNode(null);
//   };

//   const saveNodeSettings = (settingsKey: string, settingsValue: any) => {
//     if (selectedNode) {
//       const updatedNode = {
//         ...selectedNode,
//         settings: {
//           ...selectedNode.settings,
//           [settingsKey]: settingsValue,
//         },
//       };
  
//       const updatedNodeData = { [selectedNode.content]: settingsValue };
  
//       setWorkflowNodes((prevNodes) =>
//         prevNodes.map((node) => (node.id === selectedNode.id ? updatedNode : node))
//       );
  
//       updateNode({
//         id: Number(updatedNodeData.id), // Node ID
//         workflowId: 1, // Assuming a fixed workflowId for now
//         workflowName: updatedNodeData.workflowName || 'Default Workflow Name', 
//         workflowStatus: updatedNodeData.workflowStatus || 'stop', 
//         clickedStats: updatedNodeData.clickedStats || '0',
//         nodeId: updatedNodeData.nodeId || 1, 
//         nodeType: updatedNodeData.content, 
//         nodePosX: updatedNodeData.nodePosX || Math.random() * 200, 
//         nodePosY: updatedNodeData.nodePosY || Math.random() * 200, 
//         nodeData: updatedNodeData, // Updated node data
//       });
//     }
//     closeModal();
//   };  
  
//   const saveStartEnableSettings = () => {
//     saveNodeSettings('start', startEnableSettings.start);
//   };

//   const saveEndEnableSettings = () => {
//     saveNodeSettings('end', endEnableSettings.end);
//   };

//   const saveMailContentSettings = () => {
//     saveNodeSettings('template', mailContentSettings.template);
//   };

//   const saveInputDateSettings = () => {
//     saveNodeSettings('date', inputDateSettings.date);
//   };

//   const saveInputTimeSettings = () => {
//     saveNodeSettings('time', inputTimeSettings.time);
//   };

//   const saveInputFollowUpSettings = () => {
//     saveNodeSettings('followup', inputFollowUpSettings.followup);
//   };

//   const saveConditionSettings = () => {
//     saveNodeSettings('ifCondition', conditionSettings.ifCondition);
//     saveNodeSettings('elseCondition', conditionSettings.elseCondition);
//   };

//   const renderSettingsModal = () => {
//     if (!selectedNode) return null;

//     switch (selectedNode.content) {
//       case 'setMailContent':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
//                 Set Mail Content
//               </h3>
//               <div className="py-6">
//                 <label className="block mx-2 text-sm text-cyan-800 font-semibold">
//                   Select Mail Template:
//                 </label>
//                 <select
//                   className="w-full p-2 border rounded text-sm"
//                   value={mailContentSettings.template}
//                   onChange={(e) => setMailContentSettings((prev) => ({ ...prev, template: e.target.value }))}
//                 >
//                   <option value="">Select a template</option>
//                   <option value="template1">Template 1</option>
//                   <option value="template2">Template 2</option>
//                 </select>
//               </div>
//               <div className="flex justify-end items-center space-x-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveMailContentSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setDate':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
//                 Set Date
//               </h3>
//               <div className="flex flex-col my-4">
//                 <div className="mx-2 font-bold text-cyan-800 text-sm">Set a executed date:</div>
//                 <input
//                   type="date"
//                   className="w-full p-2 border rounded"
//                   value={inputDateSettings.date}
//                   onChange={(e) => 
//                     setInputDateSettings({ date:e.target.value, })
//                   }
//                 />
//               </div>
//               <div className="flex justify-end items-center space-x-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputDateSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setTime':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
//                 Set Time
//               </h3>
//               <div className="flex flex-col my-4">
//                 <div className="mx-2 font-bold text-cyan-800 text-sm">Set a executed time:</div>
//                 <input
//                   type="time"
//                   className="w-full p-2 border rounded"
//                   value={inputTimeSettings.time}
//                   onChange={(e) => 
//                     setInputTimeSettings({ time:e.target.value })
//                   }
//                 />
//               </div>
//               <div className="flex justify-end items-center space-x-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputTimeSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setStart':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
//                 Set Start
//               </h3>
//               <div className="flex justify-between px-4 py-2 border rounded">
//                 <div className="flex justify-normal items-center">
//                   <input
//                     type="checkbox"
//                     className="mr-1 accent-cyan-800"
//                     onChange={(e) => {
//                       const enabled = e.target.checked ? 'enabled' : 'disabled';
//                       setStartEnableSettings({
//                         start: enabled,
//                       });
//                     }}
//                   />
//                   <div className="text-cyan-800 text-sm font-bold">Set start flow</div>
//                 </div>
//                 <span
//                   className={`text-sm ${
//                     startEnableSettings.start === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {startEnableSettings.start === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>
//               <div className="flex justify-end items-center space-x-2 my-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveStartEnableSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setFollowUp':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
//                 Set Follow-Up
//               </h3>
//               <div className="flex flex-col items-start p-2">
//                 <div className="text-cyan-800 text-sm font-bold mx-4">Follow-Up Period:</div>
//                 <div className="flex justify-normal w-full">
//                   <input
//                     type="number"
//                     className="w-full p-2 border rounded-l"
//                     value={inputFollowUpSettings.followup}
//                     onChange={(e) => setInputFollowUpSettings({ followup: e.target.value })}
//                   />
//                   <span className="text-base border p-2 rounded-r">Days</span>
//                 </div>
//               </div>
//               <div className="flex justify-end items-center space-x-2 my-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputFollowUpSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setCondition':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-6 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg text-center text-white font-semibold bg-cyan-800 rounded-full mx-20 px-4 py-1 mb-4">
//                 Set Condition
//               </h3>

//               {/* Enable All/Disable All Checkbox */}
//               <div className="mb-4 flex items-center">
//                 <input
//                   type="checkbox"
//                   className="mr-2 accent-cyan-800"
//                   onChange={(e) => {
//                     const enabled = e.target.checked ? 'enabled' : 'disabled';
//                     setConditionSettings({
//                       ifCondition: enabled,
//                       elseCondition: enabled,
//                     });
//                   }}
//                 />
//                 <label className="block text-sm">Enable All</label>
//               </div>

//               {/* If Condition */}
//               <div className="mb-2 flex items-center">
//                 <input
//                   type="checkbox"
//                   className="mr-2 accent-cyan-800"
//                   checked={conditionSettings.ifCondition === 'enabled'}
//                   onChange={(e) =>
//                     setConditionSettings({
//                       ...conditionSettings,
//                       ifCondition: e.target.checked ? 'enabled' : 'disabled',
//                     })
//                   }
//                 />
//                 <label className="mr-auto">If Condition</label>
//                 <span
//                   className={`${
//                     conditionSettings.ifCondition === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {conditionSettings.ifCondition === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>

//               {/* Else Condition */}
//               <div className="mb-2 flex items-center">
//                 <input
//                   type="checkbox"
//                   className="mr-2 accent-cyan-800"
//                   checked={conditionSettings.elseCondition === 'enabled'}
//                   onChange={(e) =>
//                     setConditionSettings({
//                       ...conditionSettings,
//                       elseCondition: e.target.checked ? 'enabled' : 'disabled',
//                     })
//                   }
//                 />
//                 <label className="mr-auto">Else Condition</label>
//                 <span
//                   className={`${
//                     conditionSettings.elseCondition === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {conditionSettings.elseCondition === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>

//               <div className="flex justify-end items-center space-x-2 mt-4 p-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveConditionSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setEnd':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
//                 Set End
//               </h3>
//               <div className="flex justify-between px-4 py-2 border rounded">
//                 <div className="flex justify-normal items-center">
//                   <input
//                     type="checkbox"
//                     className="mr-1 accent-cyan-800"
//                     onChange={(e) => {
//                       const enabled = e.target.checked ? 'enabled' : 'disabled';
//                       setEndEnableSettings({
//                         end: enabled,
//                       });
//                     }}
//                   />
//                   <div className="text-cyan-800 text-sm font-bold">Set end flow</div>
//                 </div>
//                 <span
//                   className={`text-sm ${
//                     endEnableSettings.end === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {endEnableSettings.end === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>
//               <div className="flex justify-end items-center space-x-2 my-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveEndEnableSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   // Helper function to render node settings based on node content
//   const renderNodeSettings = (node: CampaignNode) => {
//     if (!node.settings) return null;

//     switch (node.content) {
//       case 'setFollowUp':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             Period:<span className="font-bold ml-2">{node.settings.followup} Days</span>  
//           </div>
//         );
//       case 'setDate':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div className="flex justify-normal">
//               Date:<span className="font-bold ml-2">{node.settings.date}</span>
//             </div>
//           </div>
//         );
//       case 'setTime':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div className="flex justify-normal">
//               Time:<span className="font-bold ml-2">{formatTime(node.settings.time)}</span>
//             </div>
//           </div>
//         );
//       case 'setMailContent':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div>
//               Template:<span className="font-semibold ml-2">{node.settings.template}</span>
//             </div>
//           </div>
//         );
//       case 'setCondition':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div className="flex justify-normal">
//               if Condition:<span className="font-bold ml-2">{node.settings.ifCondition}</span>
//             </div>
//             <div className="flex justify-normal">
//               else Condition:<span className="font-bold ml-2">{node.settings.elseCondition}</span>
//             </div>
//           </div>
//         );
//       case 'setStart':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             Status:<span className="font-bold ml-2">{node.settings.start}</span>
//           </div>
//         );
//       case 'setEnd':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             Status:<span className="font-bold ml-2">{node.settings.end}</span>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="mb-4">
//       {/* workflow area */}
//       <div className="flex justify-between items-center">
//         <div className="flex justify-normal items-center mb-2">
//           <LuWorkflow className="text-cyan-800 text-2xl mr-2"/>
//           <h3 className="text-2xl font-semibold text-cyan-800">Workflow Area: {workflowName}</h3>
//         </div>
//         <select
//           className="px-4 py-1"
//         >
//           <option value="">Select a workflow</option>
//           <option value="">Follow Up Mail</option>
//           <option value="">Ended Mail</option>
//         </select>
//       </div>
//       <div className="relative w-auto h-auto overflow-y-auto max-h-[600px] border-cyan-800 border-2 rounded-xl">
//         <div
//           className="p-8 rounded overflow-hidden relative min-h-[500px]"
//           style={{
//             backgroundImage:
//               'url("data:image/svg+xml,%3Csvg width=\'40\' height=\'40\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'none\' stroke=\'%23d3d3d3\' stroke-width=\'1\'%3E%3Cpath d=\'M40 0L0 0 0 40\'/%3E%3C/g%3E%3C/svg%3E")',
//             backgroundSize: '40px 40px',
//           }}
//         >
//           <div className="flex flex-col items-center">
//             {workflowNodes.map((node, index) => (
//               <div key={node.id} className="flex flex-col items-center">
//                 {index > 0 && <div className="border-x border-cyan-600 h-10"></div>}
//                 <BiSolidDownArrow className="cursor-pointer text-cyan-800 text-xs" />
//                 <div className="text-sm relative px-4 py-4 border-black-300 border rounded bg-white shadow-lg min-w-[250px]">
//                   <div className="flex justify-between items-center">
//                     <div className="flex flex-col">
//                       {node.content}
//                       {renderNodeSettings(node)}
//                     </div>
//                       <RiSettingsFill
//                         className="cursor-pointer text-cyan-800 text-xl"
//                         onClick={() => openSettingsModal(node)}
//                       />
//                   </div>
//                 </div>
//                 {node.id !== '1' && (
//                   <IoIosRemoveCircle
//                     className="cursor-pointer text-rose-500"
//                     onClick={() => handleRemoveNode(node.id)}
//                   />
//                 )}
//               </div>
//             ))}
//             <div className="flex flex-col items-center">
//               <div className="border-x border-cyan-600 h-10"></div>
//               <div
//                 className="px-4 py-2 border-2 border-dashed border-gray-300 text-gray-400 rounded bg-white shadow-md min-w-[150px] text-center text-sm cursor-pointer"
//                 onClick={() => setShowNodeList(true)}
//               >
//                 add node
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* show node setting */}
//         {showNodeList && (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <div className="flex justify-between items-center mb-4">
//                 <h3 className="text-lg font-semibold">Select a Node</h3>
//                 <IoMdClose
//                   size={24}
//                   className="cursor-pointer"
//                   onClick={closeModal}
//                 />
//               </div>
//               <input
//                 type="text"
//                 placeholder="Search for nodes..."
//                 className="w-full mb-2 p-2 border rounded"
//               />
//               <div className="flex flex-col space-y-2 border-2 p-2 overflow-y-auto h-[340px]">
//                 {nodeTypes.map((nodeType) => (
//                   <div
//                     key={nodeType.id}
//                     onClick={() => handleNodeClick(nodeType)}
//                     className="p-4 border border-cyan-600 rounded bg-white shadow-md cursor-pointer"
//                   >
//                     {nodeType.content}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         )}
//         {renderSettingsModal()}
//       </div>
//       <div className="flex justify-center items-center space-x-4">
//         <button
//           className="bg-gray-200 text-gray-500 px-4 py-1 rounded-full mt-4"
//         >
//           Cancel Workflow
//         </button>
//         <button
//           className="bg-cyan-800 text-white px-4 py-1 rounded-full mt-4"
//         >
//           Create Workflow
//         </button>
//       </div>
//     </div>
//   );
// };

// export default CampaignLogic;

// // update code --> 12 Sept 2024
// // src/components/admin/MarketSetting/CampingLogic.tsx

// import React, { useState, useEffect } from 'react';
// import { IoIosRemoveCircle, IoMdClose } from "react-icons/io";
// import { BiSolidDownArrow } from "react-icons/bi";
// import { RiSettingsFill } from "react-icons/ri";
// import { LuWorkflow } from "react-icons/lu";
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';  // import your custom hook

// // Interface definitions
// interface CampaignNode {
//   id: string;
//   content: string;
//   settings?: any;
// }

// interface NodeType {
//   id: string;
//   content: string;
// }

// const nodeTypes: NodeType[] = [
//   { id: '2', content: 'setFollowUp' },
//   { id: '4', content: 'setDate' },
//   { id: '5', content: 'setTime' },
//   { id: '6', content: 'setMailContent' },
//   { id: '7', content: 'setCondition' },
//   { id: '8', content: 'setEnd' },
//   { id: '9', content: 'setClicked'}
// ];

// // Helper function to format time in hh:mm am/pm
// const formatTime = (timeString: string) => {
//   const [hoursStr, minutes] = timeString.split(':'); 
//   let hours = parseInt(hoursStr, 10);
//   const ampm = hours >= 12 ? 'pm' : 'am';
//   hours = hours % 12 || 12; 
//   const minutesStr = minutes.padStart(2, '0'); 
//   return `${hours}:${minutesStr} ${ampm}`;
// };

// const CampaignLogic: React.FC = () => {
//   // Use your custom hook to manage nodes
//   const { addNode, deleteNode, updateNode, fetchNodes } = useEmail();
//   const [workflowNodes, setWorkflowNodes] = useState<CampaignNode[]>([
//     { id: '1', content: 'setStart' },
//   ]);
//   const [showNodeList, setShowNodeList] = useState(false);
//   const [selectedNode, setSelectedNode] = useState<CampaignNode | null>(null);
//   // Node setting state
//   const [startEnableSettings, setStartEnableSettings] = useState({ start:'', });
//   const [endEnableSettings, setEndEnableSettings] = useState({ end:'' });
//   const [mailContentSettings, setMailContentSettings] = useState({ template: '', });
//   const [inputDateSettings, setInputDateSettings] = useState({ date: '' });
//   const [inputTimeSettings, setInputTimeSettings] = useState({ time: '' });
//   const [inputFollowUpSettings, setInputFollowUpSettings] = useState({ followup: '' });
//   const [conditionSettings, setConditionSettings] = useState({ ifCondition: '', elseCondition: '' });

//   useEffect(() => {
//     // Fetch the nodes for the current workflow
//     fetchNodes(1); // Assuming a fixed workflowId of 1
//   }, [fetchNodes]);

//   const handleNodeClick = (nodeType: NodeType) => {
//     const newNode = {
//       workflowid: 1, // Fixed workflowId for now
//       nodeType: nodeType.content,
//       nodePosX: Math.random() * 200,  // Example positioning
//       nodePosY: Math.random() * 200,
//       nodeData: { [nodeType.content]: '' }
//     };

//     // Save the new node to the database using addNode from the hook
//     addNode(newNode);
//     setWorkflowNodes((prevNodes) => [...prevNodes, { id: `${Math.random()}`, content: nodeType.content }]);
//     setShowNodeList(false);
//   };

//   const handleRemoveNode = (id: string) => {
//     // Remove from both local state and API using deleteNode
//     setWorkflowNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
//     deleteNode(Number(id));
//   };

//   const openSettingsModal = (node: CampaignNode) => {
//     setSelectedNode(node);
//   };

//   const closeModal = () => {
//     setShowNodeList(false);
//     setSelectedNode(null);
//   };

//   const saveNodeSettings = (settingsKey: string, settingsValue: any) => {
//     if (selectedNode) {
//       const updatedNode = {
//         ...selectedNode,
//         settings: {
//           ...selectedNode.settings,
//           [settingsKey]: settingsValue,
//         },
//       };
  
//       const updatedNodeData = { [selectedNode.content]: settingsValue };
  
//       setWorkflowNodes((prevNodes) =>
//         prevNodes.map((node) => (node.id === selectedNode.id ? updatedNode : node))
//       );
  
//       updateNode({
//         ...updatedNode,
//         id: Number(selectedNode.id),
//         workflowid: 1,
//         nodeType: updatedNode.content,
//         nodePosX: Math.random() * 200,
//         nodePosY: Math.random() * 200,
//         nodeData: updatedNodeData, 
//       });
//     }
//     closeModal();
//   };

//   const saveStartEnableSettings = () => {
//     saveNodeSettings('start', startEnableSettings.start);
//   };

//   const saveEndEnableSettings = () => {
//     saveNodeSettings('end', endEnableSettings.end);
//   };

//   const saveMailContentSettings = () => {
//     saveNodeSettings('template', mailContentSettings.template);
//   };

//   const saveInputDateSettings = () => {
//     saveNodeSettings('date', inputDateSettings.date);
//   };

//   const saveInputTimeSettings = () => {
//     saveNodeSettings('time', inputTimeSettings.time);
//   };

//   const saveInputFollowUpSettings = () => {
//     saveNodeSettings('followup', inputFollowUpSettings.followup);
//   };

//   const saveConditionSettings = () => {
//     saveNodeSettings('ifCondition', conditionSettings.ifCondition);
//     saveNodeSettings('elseCondition', conditionSettings.elseCondition);
//   };

//   const renderSettingsModal = () => {
//     if (!selectedNode) return null;

//     switch (selectedNode.content) {
//       case 'setMailContent':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
//                 Set Mail Content
//               </h3>
//               <div className="py-6">
//                 <label className="block mx-2 text-sm text-cyan-800 font-semibold">
//                   Select Mail Template:
//                 </label>
//                 <select
//                   className="w-full p-2 border rounded text-sm"
//                   value={mailContentSettings.template}
//                   onChange={(e) => setMailContentSettings((prev) => ({ ...prev, template: e.target.value }))}
//                 >
//                   <option value="">Select a template</option>
//                   <option value="template1">Template 1</option>
//                   <option value="template2">Template 2</option>
//                 </select>
//               </div>
//               <div className="flex justify-end items-center space-x-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveMailContentSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setDate':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
//                 Set Date
//               </h3>
//               <div className="flex flex-col my-4">
//                 <div className="mx-2 font-bold text-cyan-800 text-sm">Set a executed date:</div>
//                 <input
//                   type="date"
//                   className="w-full p-2 border rounded"
//                   value={inputDateSettings.date}
//                   onChange={(e) => 
//                     setInputDateSettings({ date:e.target.value, })
//                   }
//                 />
//               </div>
//               <div className="flex justify-end items-center space-x-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputDateSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setTime':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
//                 Set Time
//               </h3>
//               <div className="flex flex-col my-4">
//                 <div className="mx-2 font-bold text-cyan-800 text-sm">Set a executed time:</div>
//                 <input
//                   type="time"
//                   className="w-full p-2 border rounded"
//                   value={inputTimeSettings.time}
//                   onChange={(e) => 
//                     setInputTimeSettings({ time:e.target.value })
//                   }
//                 />
//               </div>
//               <div className="flex justify-end items-center space-x-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputTimeSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setStart':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
//                 Set Start
//               </h3>
//               <div className="flex justify-between px-4 py-2 border rounded">
//                 <div className="flex justify-normal items-center">
//                   <input
//                     type="checkbox"
//                     className="mr-1 accent-cyan-800"
//                     onChange={(e) => {
//                       const enabled = e.target.checked ? 'enabled' : 'disabled';
//                       setStartEnableSettings({
//                         start: enabled,
//                       });
//                     }}
//                   />
//                   <div className="text-cyan-800 text-sm font-bold">Set start flow</div>
//                 </div>
//                 <span
//                   className={`text-sm ${
//                     startEnableSettings.start === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {startEnableSettings.start === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>
//               <div className="flex justify-end items-center space-x-2 my-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveStartEnableSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setFollowUp':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
//                 Set Follow-Up
//               </h3>
//               <div className="flex flex-col items-start p-2">
//                 <div className="text-cyan-800 text-sm font-bold mx-4">Follow-Up Period:</div>
//                 <div className="flex justify-normal w-full">
//                   <input
//                     type="number"
//                     className="w-full p-2 border rounded-l"
//                     value={inputFollowUpSettings.followup}
//                     onChange={(e) => setInputFollowUpSettings({ followup: e.target.value })}
//                   />
//                   <span className="text-base border p-2 rounded-r">Days</span>
//                 </div>
//               </div>
//               <div className="flex justify-end items-center space-x-2 my-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputFollowUpSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setCondition':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-6 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg text-center text-white font-semibold bg-cyan-800 rounded-full mx-20 px-4 py-1 mb-4">
//                 Set Condition
//               </h3>

//               {/* Enable All/Disable All Checkbox */}
//               <div className="mb-4 flex items-center">
//                 <input
//                   type="checkbox"
//                   className="mr-2 accent-cyan-800"
//                   onChange={(e) => {
//                     const enabled = e.target.checked ? 'enabled' : 'disabled';
//                     setConditionSettings({
//                       ifCondition: enabled,
//                       elseCondition: enabled,
//                     });
//                   }}
//                 />
//                 <label className="block text-sm">Enable All</label>
//               </div>

//               {/* If Condition */}
//               <div className="mb-2 flex items-center">
//                 <input
//                   type="checkbox"
//                   className="mr-2 accent-cyan-800"
//                   checked={conditionSettings.ifCondition === 'enabled'}
//                   onChange={(e) =>
//                     setConditionSettings({
//                       ...conditionSettings,
//                       ifCondition: e.target.checked ? 'enabled' : 'disabled',
//                     })
//                   }
//                 />
//                 <label className="mr-auto">If Condition</label>
//                 <span
//                   className={`${
//                     conditionSettings.ifCondition === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {conditionSettings.ifCondition === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>

//               {/* Else Condition */}
//               <div className="mb-2 flex items-center">
//                 <input
//                   type="checkbox"
//                   className="mr-2 accent-cyan-800"
//                   checked={conditionSettings.elseCondition === 'enabled'}
//                   onChange={(e) =>
//                     setConditionSettings({
//                       ...conditionSettings,
//                       elseCondition: e.target.checked ? 'enabled' : 'disabled',
//                     })
//                   }
//                 />
//                 <label className="mr-auto">Else Condition</label>
//                 <span
//                   className={`${
//                     conditionSettings.elseCondition === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {conditionSettings.elseCondition === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>

//               <div className="flex justify-end items-center space-x-2 mt-4 p-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveConditionSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setEnd':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
//                 Set End
//               </h3>
//               <div className="flex justify-between px-4 py-2 border rounded">
//                 <div className="flex justify-normal items-center">
//                   <input
//                     type="checkbox"
//                     className="mr-1 accent-cyan-800"
//                     onChange={(e) => {
//                       const enabled = e.target.checked ? 'enabled' : 'disabled';
//                       setEndEnableSettings({
//                         end: enabled,
//                       });
//                     }}
//                   />
//                   <div className="text-cyan-800 text-sm font-bold">Set end flow</div>
//                 </div>
//                 <span
//                   className={`text-sm ${
//                     endEnableSettings.end === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {endEnableSettings.end === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>
//               <div className="flex justify-end items-center space-x-2 my-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveEndEnableSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   // Helper function to render node settings based on node content
//   const renderNodeSettings = (node: CampaignNode) => {
//     if (!node.settings) return null;

//     switch (node.content) {
//       case 'setFollowUp':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             Period:<span className="font-bold ml-2">{node.settings.followup} Days</span>  
//           </div>
//         );
//       case 'setDate':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div className="flex justify-normal">
//               Date:<span className="font-bold ml-2">{node.settings.date}</span>
//             </div>
//           </div>
//         );
//       case 'setTime':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div className="flex justify-normal">
//               Time:<span className="font-bold ml-2">{formatTime(node.settings.time)}</span>
//             </div>
//           </div>
//         );
//       case 'setMailContent':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div>
//               Template:<span className="font-semibold ml-2">{node.settings.template}</span>
//             </div>
//           </div>
//         );
//       case 'setCondition':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div className="flex justify-normal">
//               if Condition:<span className="font-bold ml-2">{node.settings.ifCondition}</span>
//             </div>
//             <div className="flex justify-normal">
//               else Condition:<span className="font-bold ml-2">{node.settings.elseCondition}</span>
//             </div>
//           </div>
//         );
//       case 'setStart':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             Status:<span className="font-bold ml-2">{node.settings.start}</span>
//           </div>
//         );
//       case 'setEnd':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             Status:<span className="font-bold ml-2">{node.settings.end}</span>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="mb-4">
//       {/* workflow area */}
//       <div className="flex justify-between items-center">
//         <div className="flex justify-normal items-center mb-2">
//           <LuWorkflow className="text-cyan-800 text-2xl mr-2"/>
//           <h3 className="text-2xl font-semibold text-cyan-800">Workflow Area</h3>
//         </div>
//         <select
//           className="px-4 py-1"
//         >
//           <option value="">Select a workflow</option>
//           <option value="">Follow Up Mail</option>
//           <option value="">Ended Mail</option>
//         </select>
//       </div>
//       <div className="relative w-auto h-auto overflow-y-auto max-h-[600px] border-cyan-800 border-2 rounded-xl">
//         <div
//           className="p-8 rounded overflow-hidden relative min-h-[500px]"
//           style={{
//             backgroundImage:
//               'url("data:image/svg+xml,%3Csvg width=\'40\' height=\'40\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'none\' stroke=\'%23d3d3d3\' stroke-width=\'1\'%3E%3Cpath d=\'M40 0L0 0 0 40\'/%3E%3C/g%3E%3C/svg%3E")',
//             backgroundSize: '40px 40px',
//           }}
//         >
//           <div className="flex flex-col items-center">
//             {workflowNodes.map((node, index) => (
//               <div key={node.id} className="flex flex-col items-center">
//                 {index > 0 && <div className="border-x border-cyan-600 h-10"></div>}
//                 <BiSolidDownArrow className="cursor-pointer text-cyan-800 text-xs" />
//                 <div className="text-sm relative px-4 py-4 border-black-300 border rounded bg-white shadow-lg min-w-[250px]">
//                   <div className="flex justify-between items-center">
//                     <div className="flex flex-col">
//                       {node.content}
//                       {renderNodeSettings(node)}
//                     </div>
//                       <RiSettingsFill
//                         className="cursor-pointer text-cyan-800 text-xl"
//                         onClick={() => openSettingsModal(node)}
//                       />
//                   </div>
//                 </div>
//                 {node.id !== '1' && (
//                   <IoIosRemoveCircle
//                     className="cursor-pointer text-rose-500"
//                     onClick={() => handleRemoveNode(node.id)}
//                   />
//                 )}
//               </div>
//             ))}
//             <div className="flex flex-col items-center">
//               <div className="border-x border-cyan-600 h-10"></div>
//               <div
//                 className="px-4 py-2 border-2 border-dashed border-gray-300 text-gray-400 rounded bg-white shadow-md min-w-[150px] text-center text-sm cursor-pointer"
//                 onClick={() => setShowNodeList(true)}
//               >
//                 add node
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* show node setting */}
//         {showNodeList && (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <div className="flex justify-between items-center mb-4">
//                 <h3 className="text-lg font-semibold">Select a Node</h3>
//                 <IoMdClose
//                   size={24}
//                   className="cursor-pointer"
//                   onClick={closeModal}
//                 />
//               </div>
//               <input
//                 type="text"
//                 placeholder="Search for nodes..."
//                 className="w-full mb-2 p-2 border rounded"
//               />
//               <div className="flex flex-col space-y-2 border-2 p-2 overflow-y-auto h-[340px]">
//                 {nodeTypes.map((nodeType) => (
//                   <div
//                     key={nodeType.id}
//                     onClick={() => handleNodeClick(nodeType)}
//                     className="p-4 border border-cyan-600 rounded bg-white shadow-md cursor-pointer"
//                   >
//                     {nodeType.content}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         )}
//         {renderSettingsModal()}
//       </div>
//       <div className="flex justify-center items-center space-x-4">
//         <button
//           className="bg-gray-200 text-gray-500 px-4 py-1 rounded-full mt-4"
//         >
//           Cancel Workflow
//         </button>
//         <button
      
//           className="bg-cyan-800 text-white px-4 py-1 rounded-full mt-4"
//         >
//           Create Workflow
//         </button>
//       </div>
//     </div>
//   );
// };

// export default CampaignLogic;


// // update copde --> 11 sept 2024
// // src/components/admin/MarketSetting/CampingLogic.tsx

// import React, { useState, useEffect } from 'react';
// import { IoIosRemoveCircle, IoMdClose } from "react-icons/io";
// import { BiSolidDownArrow } from "react-icons/bi";
// import { RiSettingsFill } from "react-icons/ri";
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';  // import your custom hook

// // Interface definitions
// interface CampaignNode {
//   id: string;
//   content: string;
//   settings?: any;
// }

// interface NodeType {
//   id: string;
//   content: string;
// }

// const nodeTypes: NodeType[] = [
//   { id: '2', content: 'setFollowUp' },
//   { id: '4', content: 'setDate' },
//   { id: '5', content: 'setTime' },
//   { id: '6', content: 'setMailContent' },
//   { id: '7', content: 'setCondition' },
//   { id: '8', content: 'setEnd' },
//   { id: '9', content: 'setClicked'}
// ];

// // Helper function to format time in hh:mm am/pm
// const formatTime = (timeString: string) => {
//   const [hoursStr, minutes] = timeString.split(':'); 
//   let hours = parseInt(hoursStr, 10);
//   const ampm = hours >= 12 ? 'pm' : 'am';
//   hours = hours % 12 || 12; 
//   const minutesStr = minutes.padStart(2, '0'); 
//   return `${hours}:${minutesStr} ${ampm}`;
// };

// const CampaignLogic: React.FC = () => {
//   // Use your custom hook to manage nodes
//   const { addNode, deleteNode, updateNode, fetchNodes } = useEmail();
//   const [workflowNodes, setWorkflowNodes] = useState<CampaignNode[]>([
//     { id: '1', content: 'setStart' },
//   ]);
//   const [showNodeList, setShowNodeList] = useState(false);
//   const [selectedNode, setSelectedNode] = useState<CampaignNode | null>(null);
//   // Node setting state
//   const [startEnableSettings, setStartEnableSettings] = useState({ start:'', });
//   const [endEnableSettings, setEndEnableSettings] = useState({ end:'' });
//   const [mailContentSettings, setMailContentSettings] = useState({ template: '', });
//   const [inputDateSettings, setInputDateSettings] = useState({ date: '' });
//   const [inputTimeSettings, setInputTimeSettings] = useState({ time: '' });
//   const [inputFollowUpSettings, setInputFollowUpSettings] = useState({ followup: '' });
//   const [conditionSettings, setConditionSettings] = useState({ ifCondition: '', elseCondition: '' });

//   useEffect(() => {
//     // Fetch the nodes for the current workflow
//     fetchNodes(1); // Assuming a fixed workflowId of 1
//   }, [fetchNodes]);

//   const handleNodeClick = (nodeType: NodeType) => {
//     const newNode = {
//       workflowid: 1, // Fixed workflowId for now
//       nodeType: nodeType.content,
//       nodePosX: Math.random() * 200,  // Example positioning
//       nodePosY: Math.random() * 200,
//       nodeData: { content: nodeType.content }
//     };

//     // Save the new node to the database using addNode from the hook
//     addNode(newNode);
//     setWorkflowNodes((prevNodes) => [...prevNodes, { id: `${Math.random()}`, content: nodeType.content }]);
//     setShowNodeList(false);
//   };

//   const handleRemoveNode = (id: string) => {
//     // Remove from both local state and API using deleteNode
//     setWorkflowNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
//     deleteNode(Number(id));
//   };

//   const openSettingsModal = (node: CampaignNode) => {
//     setSelectedNode(node);
//   };

//   const closeModal = () => {
//     setShowNodeList(false);
//     setSelectedNode(null);
//   };

//   const saveNodeSettings = (settingsKey: string, settingsValue: any) => {
//     if (selectedNode) {
//       const updatedNode = {
//         ...selectedNode,
//         settings: {
//           ...selectedNode.settings,
//           [settingsKey]: settingsValue,
//         },
//       };
//       setWorkflowNodes((prevNodes) =>
//         prevNodes.map((node) => (node.id === selectedNode.id ? updatedNode : node))
//       );
//       updateNode({
//         ...updatedNode,
//         id: Number(selectedNode.id),
//         workflowid: 1,
//         nodeType: updatedNode.content,
//         nodePosX: Math.random() * 200,
//         nodePosY: Math.random() * 200,
//         nodeData: updatedNode.settings,
//       });
//     }
//     closeModal();
//   };

//   const saveStartEnableSettings = () => {
//     saveNodeSettings('start', startEnableSettings.start);
//   };

//   const saveEndEnableSettings = () => {
//     saveNodeSettings('end', endEnableSettings.end);
//   };

//   const saveMailContentSettings = () => {
//     saveNodeSettings('template', mailContentSettings.template);
//   };

//   const saveInputDateSettings = () => {
//     saveNodeSettings('date', inputDateSettings.date);
//   };

//   const saveInputTimeSettings = () => {
//     saveNodeSettings('time', inputTimeSettings.time);
//   };

//   const saveInputFollowUpSettings = () => {
//     saveNodeSettings('followup', inputFollowUpSettings.followup);
//   };

//   const saveConditionSettings = () => {
//     saveNodeSettings('ifCondition', conditionSettings.ifCondition);
//     saveNodeSettings('elseCondition', conditionSettings.elseCondition);
//   };

//   const renderSettingsModal = () => {
//     if (!selectedNode) return null;

//     switch (selectedNode.content) {
//       case 'setMailContent':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
//                 Set Mail Content
//               </h3>
//               <div className="py-6">
//                 <label className="block mx-2 text-sm text-cyan-800 font-semibold">
//                   Select Mail Template:
//                 </label>
//                 <select
//                   className="w-full p-2 border rounded text-sm"
//                   value={mailContentSettings.template}
//                   onChange={(e) => setMailContentSettings((prev) => ({ ...prev, template: e.target.value }))}
//                 >
//                   <option value="">Select a template</option>
//                   <option value="template1">Template 1</option>
//                   <option value="template2">Template 2</option>
//                 </select>
//               </div>
//               <div className="flex justify-end items-center space-x-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveMailContentSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setDate':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
//                 Set Date
//               </h3>
//               <div className="flex flex-col my-4">
//                 <div className="mx-2 font-bold text-cyan-800 text-sm">Set a executed date:</div>
//                 <input
//                   type="date"
//                   className="w-full p-2 border rounded"
//                   value={inputDateSettings.date}
//                   onChange={(e) => 
//                     setInputDateSettings({ date:e.target.value, })
//                   }
//                 />
//               </div>
//               <div className="flex justify-end items-center space-x-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputDateSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setTime':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
//                 Set Time
//               </h3>
//               <div className="flex flex-col my-4">
//                 <div className="mx-2 font-bold text-cyan-800 text-sm">Set a executed time:</div>
//                 <input
//                   type="time"
//                   className="w-full p-2 border rounded"
//                   value={inputTimeSettings.time}
//                   onChange={(e) => 
//                     setInputTimeSettings({ time:e.target.value })
//                   }
//                 />
//               </div>
//               <div className="flex justify-end items-center space-x-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputTimeSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setStart':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
//                 Set Start
//               </h3>
//               <div className="flex justify-between px-4 py-2 border rounded">
//                 <div className="flex justify-normal items-center">
//                   <input
//                     type="checkbox"
//                     className="mr-1 accent-cyan-800"
//                     onChange={(e) => {
//                       const enabled = e.target.checked ? 'enabled' : 'disabled';
//                       setStartEnableSettings({
//                         start: enabled,
//                       });
//                     }}
//                   />
//                   <div className="text-cyan-800 text-sm font-bold">Set start flow</div>
//                 </div>
//                 <span
//                   className={`text-sm ${
//                     startEnableSettings.start === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {startEnableSettings.start === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>
//               <div className="flex justify-end items-center space-x-2 my-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveStartEnableSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setFollowUp':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
//                 Set Follow-Up
//               </h3>
//               <div className="flex flex-col items-start p-2">
//                 <div className="text-cyan-800 text-sm font-bold mx-4">Follow-Up Period:</div>
//                 <div className="flex justify-normal w-full">
//                   <input
//                     type="number"
//                     className="w-full p-2 border rounded-l"
//                     value={inputFollowUpSettings.followup}
//                     onChange={(e) => setInputFollowUpSettings({ followup: e.target.value })}
//                   />
//                   <span className="text-base border p-2 rounded-r">Days</span>
//                 </div>
//               </div>
//               <div className="flex justify-end items-center space-x-2 my-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputFollowUpSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setCondition':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-6 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg text-center text-white font-semibold bg-cyan-800 rounded-full mx-20 px-4 py-1 mb-4">
//                 Set Condition
//               </h3>

//               {/* Enable All/Disable All Checkbox */}
//               <div className="mb-4 flex items-center">
//                 <input
//                   type="checkbox"
//                   className="mr-2 accent-cyan-800"
//                   onChange={(e) => {
//                     const enabled = e.target.checked ? 'enabled' : 'disabled';
//                     setConditionSettings({
//                       ifCondition: enabled,
//                       elseCondition: enabled,
//                     });
//                   }}
//                 />
//                 <label className="block text-sm">Enable All</label>
//               </div>

//               {/* If Condition */}
//               <div className="mb-2 flex items-center">
//                 <input
//                   type="checkbox"
//                   className="mr-2 accent-cyan-800"
//                   checked={conditionSettings.ifCondition === 'enabled'}
//                   onChange={(e) =>
//                     setConditionSettings({
//                       ...conditionSettings,
//                       ifCondition: e.target.checked ? 'enabled' : 'disabled',
//                     })
//                   }
//                 />
//                 <label className="mr-auto">If Condition</label>
//                 <span
//                   className={`${
//                     conditionSettings.ifCondition === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {conditionSettings.ifCondition === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>

//               {/* Else Condition */}
//               <div className="mb-2 flex items-center">
//                 <input
//                   type="checkbox"
//                   className="mr-2 accent-cyan-800"
//                   checked={conditionSettings.elseCondition === 'enabled'}
//                   onChange={(e) =>
//                     setConditionSettings({
//                       ...conditionSettings,
//                       elseCondition: e.target.checked ? 'enabled' : 'disabled',
//                     })
//                   }
//                 />
//                 <label className="mr-auto">Else Condition</label>
//                 <span
//                   className={`${
//                     conditionSettings.elseCondition === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {conditionSettings.elseCondition === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>

//               <div className="flex justify-end items-center space-x-2 mt-4 p-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveConditionSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setEnd':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
//                 Set End
//               </h3>
//               <div className="flex justify-between px-4 py-2 border rounded">
//                 <div className="flex justify-normal items-center">
//                   <input
//                     type="checkbox"
//                     className="mr-1 accent-cyan-800"
//                     onChange={(e) => {
//                       const enabled = e.target.checked ? 'enabled' : 'disabled';
//                       setEndEnableSettings({
//                         end: enabled,
//                       });
//                     }}
//                   />
//                   <div className="text-cyan-800 text-sm font-bold">Set end flow</div>
//                 </div>
//                 <span
//                   className={`text-sm ${
//                     endEnableSettings.end === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {endEnableSettings.end === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>
//               <div className="flex justify-end items-center space-x-2 my-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveEndEnableSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   // Helper function to render node settings based on node content
//   const renderNodeSettings = (node: CampaignNode) => {
//     if (!node.settings) return null;

//     switch (node.content) {
//       case 'setFollowUp':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             Period:<span className="font-bold ml-2">{node.settings.followup} Days</span>  
//           </div>
//         );
//       case 'setDate':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div className="flex justify-normal">
//               Date:<span className="font-bold ml-2">{node.settings.date}</span>
//             </div>
//           </div>
//         );
//       case 'setTime':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div className="flex justify-normal">
//               Time:<span className="font-bold ml-2">{formatTime(node.settings.time)}</span>
//             </div>
//           </div>
//         );
//       case 'setMailContent':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div>
//               Template:<span className="font-semibold ml-2">{node.settings.template}</span>
//             </div>
//           </div>
//         );
//       case 'setCondition':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div className="flex justify-normal">
//               if Condition:<span className="font-bold ml-2">{node.settings.ifCondition}</span>
//             </div>
//             <div className="flex justify-normal">
//               else Condition:<span className="font-bold ml-2">{node.settings.elseCondition}</span>
//             </div>
//           </div>
//         );
//       case 'setStart':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             Status:<span className="font-bold ml-2">{node.settings.start}</span>
//           </div>
//         );
//       case 'setEnd':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             Status:<span className="font-bold ml-2">{node.settings.end}</span>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="mb-4">
//       {/* workflpw area */}
//       <h3 className="text-2xl font-semibold mb-2 text-cyan-800">Workflow Area</h3>
//       <div className="relative w-auto h-auto overflow-y-auto max-h-[600px] border-cyan-800 border-2 rounded-xl">
//         <div
//           className="p-8 rounded overflow-hidden relative min-h-[500px]"
//           style={{
//             backgroundImage:
//               'url("data:image/svg+xml,%3Csvg width=\'40\' height=\'40\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'none\' stroke=\'%23d3d3d3\' stroke-width=\'1\'%3E%3Cpath d=\'M40 0L0 0 0 40\'/%3E%3C/g%3E%3C/svg%3E")',
//             backgroundSize: '40px 40px',
//           }}
//         >
//           <div className="flex flex-col items-center">
//             {workflowNodes.map((node, index) => (
//               <div key={node.id} className="flex flex-col items-center">
//                 {index > 0 && <div className="border-x border-cyan-600 h-10"></div>}
//                 <BiSolidDownArrow className="cursor-pointer text-cyan-800 text-xs" />
//                 <div className="text-sm relative px-4 py-4 border-black-300 border rounded bg-white shadow-lg min-w-[250px]">
//                   <div className="flex justify-between items-center">
//                     <div className="flex flex-col">
//                       {node.content}
//                       {renderNodeSettings(node)}
//                     </div>
//                       <RiSettingsFill
//                         className="cursor-pointer text-cyan-800 text-xl"
//                         onClick={() => openSettingsModal(node)}
//                       />
//                   </div>
//                 </div>
//                 {node.id !== '1' && (
//                   <IoIosRemoveCircle
//                     className="cursor-pointer text-rose-500"
//                     onClick={() => handleRemoveNode(node.id)}
//                   />
//                 )}
//               </div>
//             ))}
//             <div className="flex flex-col items-center">
//               <div className="border-x border-cyan-600 h-10"></div>
//               <div
//                 className="px-4 py-2 border-2 border-dashed border-gray-300 text-gray-400 rounded bg-white shadow-md min-w-[150px] text-center text-sm cursor-pointer"
//                 onClick={() => setShowNodeList(true)}
//               >
//                 add node
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* show node setting */}
//         {showNodeList && (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <div className="flex justify-between items-center mb-4">
//                 <h3 className="text-lg font-semibold">Select a Node</h3>
//                 <IoMdClose
//                   size={24}
//                   className="cursor-pointer"
//                   onClick={closeModal}
//                 />
//               </div>
//               <input
//                 type="text"
//                 placeholder="Search for nodes..."
//                 className="w-full mb-2 p-2 border rounded"
//               />
//               <div className="flex flex-col space-y-2 border-2 p-2 overflow-y-auto h-[340px]">
//                 {nodeTypes.map((nodeType) => (
//                   <div
//                     key={nodeType.id}
//                     onClick={() => handleNodeClick(nodeType)}
//                     className="p-4 border border-cyan-600 rounded bg-white shadow-md cursor-pointer"
//                   >
//                     {nodeType.content}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         )}
//         {renderSettingsModal()}
//       </div>
//     </div>
//   );
// };

// export default CampaignLogic;


// // udpate code --> 10 Sept 2024
// // src/components/admin/MarketSetting/CampingLogic.tsx

// import React, { useState } from 'react';
// import { IoIosRemoveCircle, IoMdClose } from "react-icons/io";
// import { BiSolidDownArrow } from "react-icons/bi";
// import { RiSettingsFill } from "react-icons/ri";

// // Interface definitions
// interface CampaignNode {
//   id: string;
//   content: string;
//   settings?: any;
// }

// interface NodeType {
//   id: string;
//   content: string;
// }

// const nodeTypes: NodeType[] = [
//   { id: '2', content: 'setFollowUp' },
//   { id: '4', content: 'setDate' },
//   { id: '5', content: 'setTime' },
//   { id: '6', content: 'setMailContent' },
//   { id: '7', content: 'setCondition' },
//   { id: '8', content: 'setEnd' },
//   { id: '9', content: 'setClicked'}
// ];

// // Helper function to format time in hh:mm am/pm
// const formatTime = (timeString: string) => {
//   const [hoursStr, minutes] = timeString.split(':'); 
//   let hours = parseInt(hoursStr, 10);
//   const ampm = hours >= 12 ? 'pm' : 'am';
//   hours = hours % 12 || 12; 
//   const minutesStr = minutes.padStart(2, '0'); 
//   return `${hours}:${minutesStr} ${ampm}`;
// };

// const CampaignLogic: React.FC = () => {
//   const [workflowNodes, setWorkflowNodes] = useState<CampaignNode[]>([
//     { id: '1', content: 'setStart' },
//   ]);
//   const [showNodeList, setShowNodeList] = useState(false);
//   const [selectedNode, setSelectedNode] = useState<CampaignNode | null>(null);
//   // Node setting state
//   const [startEnableSettings, setStartEnableSettings] = useState({ start:'', });
//   const [endEnableSettings, setEndEnableSettings] = useState({ end:'' });
//   const [mailContentSettings, setMailContentSettings] = useState({ template: '', });
//   const [inputDateSettings, setInputDateSettings] = useState({ date: '' });
//   const [inputTimeSettings, setInputTimeSettings] = useState({ time: '' });
//   const [inputFollowUpSettings, setInputFollowUpSettings] = useState({ followup: '' });
//   const [conditionSettings, setConditionSettings] = useState({ ifCondition: '', elseCondition: '' });

//   const handleNodeClick = (nodeType: NodeType) => {
//     const newNode: CampaignNode = {
//       id: `${Math.random()}`, // Generate a unique ID
//       content: nodeType.content,
//     };
//     setWorkflowNodes((prevNodes) => [...prevNodes, newNode]);
//     setShowNodeList(false);
//   };

//   const handleRemoveNode = (id: string) => {
//     setWorkflowNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
//   };

//   const openSettingsModal = (node: CampaignNode) => {
//     setSelectedNode(node);
//   };

//   const closeModal = () => {
//     setShowNodeList(false);
//     setSelectedNode(null);
//   };

//   const saveNodeSettings = (settingsKey: string, settingsValue: any) => {
//     if (selectedNode) {
//       const updatedNode = {
//         ...selectedNode,
//         settings: {
//           ...selectedNode.settings,
//           [settingsKey]: settingsValue,
//         },
//       };
//       setWorkflowNodes((prevNodes) =>
//         prevNodes.map((node) => (node.id === selectedNode.id ? updatedNode : node))
//       );
//     }
//     closeModal();
//   };

//   const saveStartEnableSettings = () => {
//     saveNodeSettings('start', startEnableSettings.start)
//     closeModal();
//   }

//   const saveEndEnableSettings = () => {
//     saveNodeSettings('end', endEnableSettings.end)
//     closeModal();
//   }

//   const saveMailContentSettings = () => {
//     saveNodeSettings('template', mailContentSettings.template);
//     closeModal();
//   };
  
//   const saveInputDateSettings = () => {
//     saveNodeSettings('date', inputDateSettings.date);
//     closeModal();
//   };

//   const saveInputTimeSettings = () => {
//     saveNodeSettings('time', inputTimeSettings.time);
//     closeModal();
//   };

//   const saveInputFollowUpSettings = () => {
//     saveNodeSettings('followup', inputFollowUpSettings.followup);
//     closeModal();
//   }

//   const saveConditionSettings = () => {
//     saveNodeSettings('ifCondition', conditionSettings.ifCondition);
//     saveNodeSettings('elseCondition', conditionSettings.elseCondition);
//     closeModal();
//   }

//   const renderSettingsModal = () => {
//     if (!selectedNode) return null;

//     switch (selectedNode.content) {
//       case 'setMailContent':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
//                 Set Mail Content
//               </h3>
//               <div className="py-6">
//                 <label className="block mx-2 text-sm text-cyan-800 font-semibold">
//                   Select Mail Template:
//                 </label>
//                 <select
//                   className="w-full p-2 border rounded text-sm"
//                   value={mailContentSettings.template}
//                   onChange={(e) => setMailContentSettings((prev) => ({ ...prev, template: e.target.value }))}
//                 >
//                   <option value="">Select a template</option>
//                   <option value="template1">Template 1</option>
//                   <option value="template2">Template 2</option>
//                 </select>
//               </div>
//               <div className="flex justify-end items-center space-x-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveMailContentSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setDate':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
//                 Set Date
//               </h3>
//               <div className="flex flex-col my-4">
//                 <div className="mx-2 font-bold text-cyan-800 text-sm">Set a executed date:</div>
//                 <input
//                   type="date"
//                   className="w-full p-2 border rounded"
//                   value={inputDateSettings.date}
//                   onChange={(e) => 
//                     setInputDateSettings({ date:e.target.value, })
//                   }
//                 />
//               </div>
//               <div className="flex justify-end items-center space-x-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputDateSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setTime':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold bg-cyan-800 text-white mx-10 text-center rounded-full py-2">
//                 Set Time
//               </h3>
//               <div className="flex flex-col my-4">
//                 <div className="mx-2 font-bold text-cyan-800 text-sm">Set a executed time:</div>
//                 <input
//                   type="time"
//                   className="w-full p-2 border rounded"
//                   value={inputTimeSettings.time}
//                   onChange={(e) => 
//                     setInputTimeSettings({ time:e.target.value })
//                   }
//                 />
//               </div>
//               <div className="flex justify-end items-center space-x-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputTimeSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setStart':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
//                 Set Start
//               </h3>
//               <div className="flex justify-between px-4 py-2 border rounded">
//                 <div className="flex justify-normal items-center">
//                   <input
//                     type="checkbox"
//                     className="mr-1 accent-cyan-800"
//                     onChange={(e) => {
//                       const enabled = e.target.checked ? 'enabled' : 'disabled';
//                       setStartEnableSettings({
//                         start: enabled,
//                       });
//                     }}
//                   />
//                   <div className="text-cyan-800 text-sm font-bold">Set start flow</div>
//                 </div>
//                 <span
//                   className={`text-sm ${
//                     startEnableSettings.start === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {startEnableSettings.start === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>
//               <div className="flex justify-end items-center space-x-2 my-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveStartEnableSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setFollowUp':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
//                 Set Follow-Up
//               </h3>
//               <div className="flex flex-col items-start p-2">
//                 <div className="text-cyan-800 text-sm font-bold mx-4">Follow-Up Period:</div>
//                 <div className="flex justify-normal w-full">
//                   <input
//                     type="number"
//                     className="w-full p-2 border rounded-l"
//                     value={inputFollowUpSettings.followup}
//                     onChange={(e) => setInputFollowUpSettings({ followup: e.target.value })}
//                   />
//                   <span className="text-base border p-2 rounded-r">Days</span>
//                 </div>
//               </div>
//               <div className="flex justify-end items-center space-x-2 my-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveInputFollowUpSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setCondition':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-6 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg text-center text-white font-semibold bg-cyan-800 rounded-full mx-20 px-4 py-1 mb-4">
//                 Set Condition
//               </h3>

//               {/* Enable All/Disable All Checkbox */}
//               <div className="mb-4 flex items-center">
//                 <input
//                   type="checkbox"
//                   className="mr-2 accent-cyan-800"
//                   onChange={(e) => {
//                     const enabled = e.target.checked ? 'enabled' : 'disabled';
//                     setConditionSettings({
//                       ifCondition: enabled,
//                       elseCondition: enabled,
//                     });
//                   }}
//                 />
//                 <label className="block text-sm">Enable All</label>
//               </div>

//               {/* If Condition */}
//               <div className="mb-2 flex items-center">
//                 <input
//                   type="checkbox"
//                   className="mr-2 accent-cyan-800"
//                   checked={conditionSettings.ifCondition === 'enabled'}
//                   onChange={(e) =>
//                     setConditionSettings({
//                       ...conditionSettings,
//                       ifCondition: e.target.checked ? 'enabled' : 'disabled',
//                     })
//                   }
//                 />
//                 <label className="mr-auto">If Condition</label>
//                 <span
//                   className={`${
//                     conditionSettings.ifCondition === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {conditionSettings.ifCondition === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>

//               {/* Else Condition */}
//               <div className="mb-2 flex items-center">
//                 <input
//                   type="checkbox"
//                   className="mr-2 accent-cyan-800"
//                   checked={conditionSettings.elseCondition === 'enabled'}
//                   onChange={(e) =>
//                     setConditionSettings({
//                       ...conditionSettings,
//                       elseCondition: e.target.checked ? 'enabled' : 'disabled',
//                     })
//                   }
//                 />
//                 <label className="mr-auto">Else Condition</label>
//                 <span
//                   className={`${
//                     conditionSettings.elseCondition === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {conditionSettings.elseCondition === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>

//               <div className="flex justify-end items-center space-x-2 mt-4 p-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveConditionSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       case 'setEnd':
//         return (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <h3 className="text-lg font-semibold text-white text-center my-4 mx-20 bg-cyan-800 px-4 py-1 rounded-full">
//                 Set End
//               </h3>
//               <div className="flex justify-between px-4 py-2 border rounded">
//                 <div className="flex justify-normal items-center">
//                   <input
//                     type="checkbox"
//                     className="mr-1 accent-cyan-800"
//                     onChange={(e) => {
//                       const enabled = e.target.checked ? 'enabled' : 'disabled';
//                       setEndEnableSettings({
//                         end: enabled,
//                       });
//                     }}
//                   />
//                   <div className="text-cyan-800 text-sm font-bold">Set end flow</div>
//                 </div>
//                 <span
//                   className={`text-sm ${
//                     endEnableSettings.end === 'enabled'
//                       ? 'bg-cyan-800 text-white px-4 py-1 rounded-full'
//                       : 'bg-gray-200 text-gray-400 px-4 py-1 rounded-full'
//                   }`}
//                 >
//                   {endEnableSettings.end === 'enabled' ? 'Enabled' : 'Disabled'}
//                 </span>
//               </div>
//               <div className="flex justify-end items-center space-x-2 my-2">
//                 <button className="px-4 py-1 bg-gray-200 text-gray-400 rounded" onClick={closeModal}>
//                   Close
//                 </button>
//                 <button className="px-4 py-1 bg-cyan-800 text-white rounded" onClick={saveEndEnableSettings}>
//                   Save
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   // Helper function to render node settings based on node content
//   const renderNodeSettings = (node: CampaignNode) => {
//     if (!node.settings) return null;

//     switch (node.content) {
//       case 'setFollowUp':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             Period:<span className="font-bold ml-2">{node.settings.followup} Days</span>  
//           </div>
//         );
//       case 'setDate':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div className="flex justify-normal">
//               Date:<span className="font-bold ml-2">{node.settings.date}</span>
//             </div>
//           </div>
//         );
//       case 'setTime':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div className="flex justify-normal">
//               Time:<span className="font-bold ml-2">{formatTime(node.settings.time)}</span>
//             </div>
//           </div>
//         );
//       case 'setMailContent':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div>
//               Template:<span className="font-semibold ml-2">{node.settings.template}</span>
//             </div>
//           </div>
//         );
//       case 'setCondition':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             <div className="flex justify-normal">
//               if Condition:<span className="font-bold ml-2">{node.settings.ifCondition}</span>
//             </div>
//             <div className="flex justify-normal">
//               else Condition:<span className="font-bold ml-2">{node.settings.elseCondition}</span>
//             </div>
//           </div>
//         );
//       case 'setStart':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             Status:<span className="font-bold ml-2">{node.settings.start}</span>
//           </div>
//         );
//       case 'setEnd':
//         return (
//           <div className="border rounded bg-cyan-800 text-white pl-1 px-2 py-1">
//             Status:<span className="font-bold ml-2">{node.settings.end}</span>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="mb-4">
//       {/* workflpw area */}
//       <h3 className="text-2xl font-semibold mb-2 text-cyan-800">Workflow Area</h3>
//       <div className="relative w-auto h-auto overflow-y-auto max-h-[600px] border-cyan-800 border-2 rounded-xl">
//         <div
//           className="p-8 rounded overflow-hidden relative min-h-[500px]"
//           style={{
//             backgroundImage:
//               'url("data:image/svg+xml,%3Csvg width=\'40\' height=\'40\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'none\' stroke=\'%23d3d3d3\' stroke-width=\'1\'%3E%3Cpath d=\'M40 0L0 0 0 40\'/%3E%3C/g%3E%3C/svg%3E")',
//             backgroundSize: '40px 40px',
//           }}
//         >
//           <div className="flex flex-col items-center">
//             {workflowNodes.map((node, index) => (
//               <div key={node.id} className="flex flex-col items-center">
//                 {index > 0 && <div className="border-x border-cyan-600 h-10"></div>}
//                 <BiSolidDownArrow className="cursor-pointer text-cyan-800 text-xs" />
//                 <div className="text-sm relative px-4 py-4 border-black-300 border rounded bg-white shadow-lg min-w-[250px]">
//                   <div className="flex justify-between items-center">
//                     <div className="flex flex-col">
//                       {node.content}
//                       {renderNodeSettings(node)}
//                     </div>
//                       <RiSettingsFill
//                         className="cursor-pointer text-cyan-800 text-xl"
//                         onClick={() => openSettingsModal(node)}
//                       />
//                   </div>
//                 </div>
//                 {node.id !== '1' && (
//                   <IoIosRemoveCircle
//                     className="cursor-pointer text-rose-500"
//                     onClick={() => handleRemoveNode(node.id)}
//                   />
//                 )}
//               </div>
//             ))}
//             <div className="flex flex-col items-center">
//               <div className="border-x border-cyan-600 h-10"></div>
//               <div
//                 className="px-4 py-2 border-2 border-dashed border-gray-300 text-gray-400 rounded bg-white shadow-md min-w-[150px] text-center text-sm cursor-pointer"
//                 onClick={() => setShowNodeList(true)}
//               >
//                 add node
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* show node setting */}
//         {showNodeList && (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
//             <div className="w-1/3 p-4 bg-white border border-gray-300 rounded shadow-md relative">
//               <div className="flex justify-between items-center mb-4">
//                 <h3 className="text-lg font-semibold">Select a Node</h3>
//                 <IoMdClose
//                   size={24}
//                   className="cursor-pointer"
//                   onClick={closeModal}
//                 />
//               </div>
//               <input
//                 type="text"
//                 placeholder="Search for nodes..."
//                 className="w-full mb-2 p-2 border rounded"
//               />
//               <div className="flex flex-col space-y-2 border-2 p-2 overflow-y-auto h-[340px]">
//                 {nodeTypes.map((nodeType) => (
//                   <div
//                     key={nodeType.id}
//                     onClick={() => handleNodeClick(nodeType)}
//                     className="p-4 border border-cyan-600 rounded bg-white shadow-md cursor-pointer"
//                   >
//                     {nodeType.content}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         )}
//         {renderSettingsModal()}
//       </div>
//     </div>
//   );
// };

// export default CampaignLogic;
