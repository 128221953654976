// src/context/EventRegTermContext.tsx

import React, { createContext, useContext, ReactNode } from 'react';

const termsAndConditions = (
  <div>
    <h1><strong>1. Introduction</strong></h1><br />
    <p>Welcome to R One Century Event Organizer. These terms and conditions ("Terms") govern your registration and participation in the Event organized by R One Century ("Organizer"). By registering for the Event, you agree to comply with and be bound by these Terms. 
       If you do not agree with any part of these Terms, please do not register for or attend the Event.</p><br />

    <h1><strong>2. Registration</strong></h1><br />
    <h2><strong>2.1. Eligibility</strong></h2>
    <p>Registration is open to individuals who are 20 years old or older. By registering, you confirm that you meet the eligibility requirements.</p><br />

    <h2><strong>2.2. Registration Process</strong></h2>
    <p>To register for the Event, you must complete the online registration form and provide accurate, current, and complete information. The Organizer reserves the right to accept or reject any registration at its sole discretion.</p><br />

    <h2><strong>2.3. Fees</strong></h2>
    <p>Registration fees (if applicable) must be paid in full at the time of registration. All fees are non-refundable unless otherwise stated in these Terms or required by applicable law.</p><br />

    <h2><strong>2.4. Confirmation</strong></h2>
    <p>Upon successful registration and payment, you will receive a confirmation email. This confirmation does not guarantee admission to the Event if the Organizer later determines that you do not meet the eligibility criteria or have violated these Terms.</p><br />

    <h1><strong>3. Event Participation</strong></h1><br />
    <h2><strong>3.1. Code of Conduct</strong></h2>
    <p>All participants are expected to behave in a respectful and professional manner. The Organizer reserves the right to remove any participant whose behavior is deemed inappropriate, disruptive, or harmful.</p><br />

    <h2><strong>3.2. Attendance</strong></h2>
    <p>You are responsible for your own travel, accommodation, and other related expenses unless otherwise specified by the Organizer. The Organizer will not be liable for any costs incurred by participants.</p><br />

    <h2><strong>3.3. Changes and Cancellations</strong></h2>
    <p>The Organizer reserves the right to make changes to the Event schedule, speakers, content, and location. In the event of cancellation, participants will be notified as soon as possible, and registration fees (if applicable) will be refunded.</p><br />

    <h2><strong>3.4. Photography and Recording</strong></h2>
    <p>By attending the Event, you consent to being photographed, filmed, or recorded. The Organizer may use these materials for promotional purposes without compensation to you.</p><br />

    <h1><strong>4. Liability</strong></h1><br />
    <h2><strong>4.1. Assumption of Risk</strong></h2>
    <p>Participation in the Event is at your own risk. The Organizer is not responsible for any loss, injury, or damage to persons or property arising from your attendance at the Event.</p><br />

    <h2><strong>4.2. Limitation of Liability</strong></h2>
    <p>To the fullest extent permitted by law, the Organizer's liability for any claim arising out of or in connection with these Terms or the Event will be limited to the amount of the registration fee paid by you.</p><br />

    <h1><strong>5. Privacy</strong></h1><br />
    <h2><strong>5.1. Data Collection</strong></h2>
    <p>The Organizer collects personal information during the registration process. This information will be used in accordance with the Organizer's Privacy Policy.</p><br />

    <h2><strong>5.2. Third-Party Sharing</strong></h2>
    <p>Your information may be shared with third-party service providers for the purpose of organizing and managing the Event.</p><br />

    <h1><strong>6. Governing Law</strong></h1>
    <p>These Terms are governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with these Terms will be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</p><br />

    <h1><strong>7. Amendments</strong></h1>
    <p>The Organizer reserves the right to modify these Terms at any time. Any changes will be posted on the Event website and will become effective upon posting.</p><br />

    <h1><strong>8. Contact Information</strong></h1>
    <p>If you have any questions about these Terms, please contact [Organizer Contact Information].</p><br />

    <p><strong>By registering for the Event, you acknowledge that you have read, understood, and agree to these Terms and Conditions.</strong></p>
  </div>
);

interface EventRegTermProviderProps {
  children: ReactNode;
}

const EventRegTermContext = createContext<JSX.Element>(termsAndConditions);

export const EventRegTermProvider: React.FC<EventRegTermProviderProps> = ({ children }) => {
  return (
    <EventRegTermContext.Provider value={termsAndConditions}>
      {children}
    </EventRegTermContext.Provider>
  );
};

export const useEventRegTerms = () => useContext(EventRegTermContext);
