// src/components/client/MiniApp/App_AlertMessage.tsx

import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimesCircle, FaTimes, FaExclamationTriangle } from 'react-icons/fa';
import { useTheme } from '../../../context/ThemeContext';

interface AlertProps {
  type: 'success' | 'error' | 'confirm' | 'warning' | 'check';
  title?: string; 
  message: string;
  visible: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
}

const MyTicketAlert: React.FC<AlertProps> = ({ type, title, message, visible, onClose, onConfirm, onCancel, isLoading  }) => {
  const { theme } = useTheme();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (visible) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [visible]);

  if (!visible) return null;

  const alertStyles = {
    success: {
      container: 'bg-green-100 border-green-500 text-green-800',
      icon: <FaCheckCircle className="w-4 h-4 mr-2" />,
    },
    error: {
      container: 'bg-red-100 border-red-500 text-red-800',
      icon: <FaTimesCircle className="w-4 h-4 mr-2" />,
    },
    confirm: {
      container: 'bg-blue-100 border-blue-500 text-blue-800',
      icon: <FaCheckCircle className="w-4 h-4 mr-2" />,
    },
    warning: {
      container: 'bg-red-50 border-red-300 text-red-600',
      icon: <FaExclamationTriangle className="w-4 h-4 mr-2" />,
    },
    check: {
      container: 'bg-yellow-50 border-yellow-300 text-yellow-600',
      icon: <FaCheckCircle className="w-4 h-4 mr-2" />,
    },
  };

  const getButtonStyles = (alertType: string) => {
    switch (alertType) {
      case 'warning':
        return {
          cancel: 'border-2 border-red-300 text-red-500 hover:bg-red-50',
          confirm: 'bg-red-500 text-white hover:bg-red-600',
        };
      case 'check':
        return {
          cancel: 'border-2 border-yellow-300 text-yellow-500 hover:bg-yellow-50',
          confirm: 'bg-yellow-500 text-white hover:bg-yellow-600',
        };
      default:
        return {
          cancel: 'border-2 border-blue-400 text-blue-500 hover:bg-gray-300',
          confirm: 'bg-blue-400 text-white hover:bg-blue-600',
        };
    }
  };

  const buttonStyles = getButtonStyles(type);

  return (
    <div className={`fixed inset-x-0 top-0 transition-transform transform ${showAlert ? 'translate-y-0' : '-translate-y-full'} z-50 px-4`} style={{ fontFamily: theme.fontFamily }}>
      <div className={`relative flex flex-col p-4 mt-4 mb-4 text-sm rounded-lg ${alertStyles[type].container} border max-w-lg mx-auto md:max-w-2xl`} role="alert">
        <div className="flex items-center">
          {alertStyles[type].icon}
          <span className="font-medium" style={{ color: theme.textColor }}>
            {title || type.charAt(0).toUpperCase() + type.slice(1)}
          </span>
        </div>
        <div className="mt-2 md:ml-4" dangerouslySetInnerHTML={{ __html: message }}></div>
        <div className="flex justify-end mt-4">
          {(type === 'confirm' || type === 'warning' || type === 'check') && (
            <>
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <span className="ml-2">Processing<span className="loading-dots">...</span></span>
                </div>
              ) : (
                <>
                <button
                  onClick={onCancel}
                  className={`px-4 py-2 mr-2 text-sm font-medium rounded-lg ${buttonStyles.cancel}`}
                >
                  Cancel
                </button>
                <button
                  onClick={onConfirm}
                  className={`px-4 py-2 text-sm font-medium rounded-lg ${buttonStyles.confirm}`}
                >
                  Confirm
                </button>
              </>
             )}
            </>
          )}
          <button 
            onClick={(e) => {
              e.stopPropagation(); // Prevents the click event from propagating to parent elements
              onClose(); // Calls the onClose function passed as a prop
            }}
            className="absolute top-2 right-2 hover:text-gray-600 focus:outline-none">
            <FaTimes className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyTicketAlert;


// // udpate code --> 30 june 2024
// // src/components/client/MiniApp/App_AlertMessage.tsx

// import React, { useEffect, useState } from 'react';
// import { FaCheckCircle, FaTimesCircle, FaTimes, FaExclamationTriangle } from 'react-icons/fa';
// import { useTheme } from '../../../context/ThemeContext';

// interface AlertProps {
//   type: 'success' | 'error' | 'confirm' | 'warning' | 'check';
//   message: string;
//   visible: boolean;
//   onClose: () => void;
//   onConfirm?: () => void;
//   onCancel?: () => void;
// }

// const MyTicketAlert: React.FC<AlertProps> = ({ type, message, visible, onClose, onConfirm, onCancel }) => {
//   const { theme } = useTheme();
//   const [showAlert, setShowAlert] = useState(false);

//   useEffect(() => {
//     if (visible) {
//       setShowAlert(true);
//     } else {
//       setShowAlert(false);
//     }
//   }, [visible]);

//   if (!visible) return null;

//   const alertStyles = {
//     success: {
//       container: 'bg-green-100 border-green-500 text-green-800',
//       icon: <FaCheckCircle className="w-4 h-4 mr-2" />,
//     },
//     error: {
//       container: 'bg-red-100 border-red-500 text-red-800',
//       icon: <FaTimesCircle className="w-4 h-4 mr-2" />,
//     },
//     confirm: {
//       container: 'bg-blue-100 border-blue-500 text-blue-800',
//       icon: <FaCheckCircle className="w-4 h-4 mr-2" />,
//     },
//     warning: {
//       container: 'bg-red-50 border-red-300 text-red-600',
//       icon: <FaExclamationTriangle className="w-4 h-4 mr-2" />,
//     },
//     check: {
//       container: 'bg-yellow-50 border-yellow-300 text-yellow-600',
//       icon: <FaCheckCircle className="w-4 h-4 mr-2" />,
//     },
//   };

//   const getButtonStyles = (alertType: string) => {
//     switch (alertType) {
//       case 'warning':
//         return {
//           cancel: 'border-2 border-red-300 text-red-500 hover:bg-red-50',
//           confirm: 'bg-red-500 text-white hover:bg-red-600',
//         };
//       case 'check':
//         return {
//           cancel: 'border-2 border-yellow-300 text-yellow-500 hover:bg-yellow-50',
//           confirm: 'bg-yellow-500 text-white hover:bg-yellow-600',
//         };
//       default:
//         return {
//           cancel: 'border-2 border-blue-400 text-blue-500 hover:bg-gray-300',
//           confirm: 'bg-blue-400 text-white hover:bg-blue-600',
//         };
//     }
//   };

//   const buttonStyles = getButtonStyles(type);

//   return (
//     <div className={`fixed inset-x-0 top-0 transition-transform transform ${showAlert ? 'translate-y-0' : '-translate-y-full'} z-50 px-4`} style={{ fontFamily: theme.fontFamily }}>
//       <div className={`relative flex flex-col p-4 mt-4 mb-4 text-sm rounded-lg ${alertStyles[type].container} border max-w-lg mx-auto md:max-w-2xl`} role="alert">
//         <div className="flex items-center">
//           {alertStyles[type].icon}
//           <span className="font-medium" style={{ color: theme.textColor }}>
//             {type.charAt(0).toUpperCase() + type.slice(1)}
//           </span>
//         </div>
//         <div className="mt-2 md:ml-4">
//           {message}
//         </div>
//         <div className="flex justify-end mt-4">
//           {(type === 'confirm' || type === 'warning' || type === 'check') && (
//             <>
//               <button
//                 onClick={onCancel}
//                 className={`px-4 py-2 mr-2 text-sm font-medium rounded-lg ${buttonStyles.cancel}`}
//               >
//                 Cancel
//               </button>
//               <button
//                 onClick={onConfirm}
//                 className={`px-4 py-2 text-sm font-medium rounded-lg ${buttonStyles.confirm}`}
//               >
//                 Confirm
//               </button>
//             </>
//           )}
//           <button onClick={onClose} className="absolute top-2 right-2 hover:text-gray-600 focus:outline-none">
//             <FaTimes className="w-4 h-4" />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MyTicketAlert;
