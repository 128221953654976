// src/hooks/AdminDashboard/useSubNotification.tsx

import { useState, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

export interface Plan {
  id: string;
  planID: string;
  planName: string;
  planAmount: string;
}

export interface Notification {
  // fields for AlgoMatrix
  orderID: string;
  productName: string;
  planID: string;
  planName: string;
  userName: string;
  email: string;
  planType: string;
  planAmount: string;
  planSubDate: string;
  planPeriod: string;
  planExpiredDate: string;
  planDays: string;
  planTrialPeriod: string;
  planExpiredTrialDate: string;
  planTrialDays: string;
  accountID: string;
  accountType: string;
  accountServer: string;
  accountNumber: string;
  accountPassword: string;
  accountStatus: string;
  accountSubCheck: string;
  accountTrialCheck: string;
  accountVoucherCheck: string;
  PaymentMethod: string;
  PaymentReceipts: string;
  notes: string;
  termAndCondition: string;
  createdAt: string;
  // fields for TraderHub
  name: string;
  phone: string;
  planNameHub?: string;
  planTypeHub?: string;
  planPeriodHub?: string;
  planAmountHub?: string;
  renewalDate: string;
  expiryDate: string;
  expireDays: string;
  termAgreed: string;
  status: string;
  PaymentMethodHub: string;
  PaymentReceiptsHub: string;
  updatedAt: string;
}

const useSubNotification = (): {
  notifications: Notification[];
  loading: boolean;
  error: string | null;
  fetchNotifications: () => Promise<void>;
  removeNotification: (orderID: string, PaymentReceipts: string) => Promise<void>;
  modifyNotification: (notification: Notification) => Promise<void>;
  fetchProductNames: () => Promise<string[]>;
  fetchPlans: (orderType: 'sub' | 'hub') => Promise<Plan[]>;
  fetchStats: () => Promise<{ totalSubscribers: number, activePlans: number, expiredPlans: number }>;
  formatDate: (date: string) => string;
  formatData: (data: string | null | undefined) => string;
  getStatusClass: (status: string) => string;
  getStatusHubClass: (status: string) => string;
  approveNotification: (notification: Notification) => Promise<void>;
  rejectNotification: (notification: Notification) => Promise<void>;
  addNewNotification: (notification: Notification) => Promise<void>;
} => {
  const { apiUrl } = useEnv();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const formatDate = (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    return new Date(date).toLocaleDateString('en-CA', options);
  };

  const formatData = (data: string | null | undefined) => {
    return data ? data : "No Data";
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case "Pending":
        return "bg-yellow-400 text-black";
      case "Active":
        return "bg-green-500 text-white";
      case "Expire":
        return "bg-rose-500 text-white";
      case "Deactive":
        return "bg-gray-200 text-gray-400";
      default:
        return "bg-gray-200 text-gray-400";
    }
  };

  const getStatusHubClass = (status: string) => {
    switch (status) {
      case "Pending":
        return "bg-yellow-400 text-black";
      case "Active":
        return "bg-green-500 text-white";
      case "Expire":
        return "bg-rose-500 text-white";
      case "Deactive":
        return "bg-gray-300 text-gray-400";
      default:
        return "bg-gray-300 text-gray-400";
    }
  };

  const fetchNotifications = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiUrl}/api/sub-notifications`);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data: Notification[] = await response.json();
  
      // Normalize TraderHub statuses to match AlgoMarix statuses
      const normalizedData = data.map(notification => {
        if (notification.planTypeHub) {
          // Normalize TraderHub status to match AlgoMarix status naming
          notification.productName = 'Trader Hub';
          notification.accountStatus = notification.status || ''; 
          notification.userName = notification.name || '';
          notification.planName = notification.planNameHub || '';
          notification.planType = notification.planTypeHub || '';
          notification.planPeriod = notification.planPeriodHub || ''; 
          notification.planAmount = notification.planAmountHub || '';
          notification.planSubDate = notification.renewalDate || '';
          notification.planExpiredDate = notification.expiryDate || '';
          notification.planDays = notification.expireDays || '';
          notification.termAndCondition = notification.termAgreed || ''; 
          notification.PaymentMethod = notification.PaymentMethodHub || '';
          notification.PaymentReceipts = notification.PaymentReceiptsHub;
        }
        return notification;
      });
  
      setNotifications(normalizedData);
    } catch (error: unknown) {
      console.error('Failed to fetch notifications', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Failed to fetch notifications');
      }
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);  

  const addNewNotification = async (notification: Notification) => {
    try {
      // Handle Trial Plan: Ensure that planPeriod is duplicated into planTrialPeriod and planTrialDays
      if (notification.planType === 'Trial') {
        notification.planTrialPeriod = notification.planPeriod;
        notification.planTrialDays = notification.planPeriod;
      }

      const response = await fetch(`${apiUrl}/api/add-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(notification),
      });
  
      if (!response.ok) {
        throw new Error('Failed to add subscription');
      }
  
      fetchNotifications(); // Refresh the notifications
    } catch (error) {
      console.error('Failed to add subscription:', error);
    }
  };
    
  const removeNotification = useCallback(async (orderID: string, PaymentReceipts: string) => {
    try {
  
      const response = await fetch(`${apiUrl}/api/remove-subscription`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderID, PaymentReceipt: PaymentReceipts }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to remove subscription');
      }
  
      fetchNotifications(); // Refresh the notifications
    } catch (error) {
      console.error('Failed to remove subscription', error);
    }
  }, [apiUrl, fetchNotifications]);  

  const modifyNotification = useCallback(async (notification: Notification) => {
    try {
      // Adjust the notification structure if it's a Trader Hub product
      if (notification.productName === 'Trader Hub') {
        notification.accountStatus = notification.status || '';
        notification.planType = notification.planTypeHub || '';
        notification.planPeriod = notification.planPeriodHub || '';
        notification.planAmount = notification.planAmountHub || '';
        notification.planSubDate = notification.updatedAt || '';
        notification.planExpiredDate = notification.expiryDate || '';
        notification.planDays = notification.expireDays || '';
        notification.PaymentMethod = notification.PaymentMethodHub || '';
        notification.PaymentReceipts = notification.PaymentReceiptsHub || '';
      }

      const response = await fetch(`${apiUrl}/api/modify-subscription`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(notification),
      });

      if (!response.ok) {
        throw new Error('Failed to modify subscription');
      }

      fetchNotifications(); 
    } catch (error) {
      console.error('Failed to modify subscription', error);
    }
  }, [apiUrl, fetchNotifications]);

  const approveNotification = async (notification: Notification) => {
    try {
      const response = await fetch(`${apiUrl}/api/approve-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notification }),
      });

      if (!response.ok) {
        throw new Error('Failed to approve subscription');
      }

      fetchNotifications();
    } catch (error) {
      console.error('Failed to approve subscription', error);
    }
  };

  const rejectNotification = async (notification: Notification) => {
    try {
      const response = await fetch(`${apiUrl}/api/reject-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notification }),
      });

      if (!response.ok) {
        throw new Error('Failed to reject subscription');
      }

      fetchNotifications(); 
    } catch (error) {
      console.error('Failed to reject subscription', error);
    }
  }; 

  const fetchProductNames = async (): Promise<string[]> => {
    try {
      const response = await fetch(`${apiUrl}/api/product-names`);
      if (!response.ok) {
        throw new Error('Failed to fetch product names');
      }
      const productNames: string[] = await response.json();

      return productNames;
    } catch (error) {
      console.error('Error fetching product names:', error);
      return [];
    }
  };  

  const fetchPlans = async (orderType: 'sub' | 'hub'): Promise<Plan[]> => {
    try {
      const response = await fetch(`${apiUrl}/api/plans?type=${orderType}`);
      if (!response.ok) {
        throw new Error('Failed to fetch plans');
      }
      const plans: Plan[] = await response.json();
      
      return plans.map(plan => ({
        id: plan.id,
        planID: plan.planID,
        planName: plan.planName,
        planAmount: plan.planAmount,
      }));
    } catch (error) {
      console.error('Error fetching plans:', error);
      return [];
    }
  };

  const fetchStats = useCallback(async () => {
    try {
        const response = await fetch(`${apiUrl}/api/stats`);
        if (!response.ok) {
            throw new Error('Failed to fetch stats');
        }
        const data = await response.json();

        const totalSubscribers = data.totalSubscribers;
        const activePlans = data.activePlans;
        const expiredPlans = data.expiredPlans;

        return { totalSubscribers, activePlans, expiredPlans };
    } catch (error) {
        console.error('Failed to fetch stats', error);
        throw error;
    }
  }, [apiUrl]);


  return { 
    notifications, 
    loading, error,
    fetchProductNames,
    fetchPlans, 
    fetchStats, 
    formatDate, 
    formatData, 
    getStatusClass,
    getStatusHubClass,
    fetchNotifications,
    removeNotification,
    modifyNotification,
    approveNotification, 
    rejectNotification,
    addNewNotification, 
  };
};

export default useSubNotification;


// // udpate code --> 12 Sept 2204
// // src/hooks/AdminDashboard/useSubNotification.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Plan {
//   id: string;
//   planID: string;
//   planName: string;
//   planAmount: string;
// }

// export interface Notification {
//   // fields for AlgoMatrix
//   orderID: string;
//   productName: string;
//   planID: string;
//   planName: string;
//   userName: string;
//   email: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planPeriod: string;
//   planExpiredDate: string;
//   planDays: string;
//   planTrialPeriod: string;
//   planExpiredTrialDate: string;
//   planTrialDays: string;
//   accountID: string;
//   accountType: string;
//   accountServer: string;
//   accountNumber: string;
//   accountPassword: string;
//   accountStatus: string;
//   accountSubCheck: string;
//   accountTrialCheck: string;
//   accountVoucherCheck: string;
//   PaymentMethod: string;
//   PaymentReceipts: string;
//   notes: string;
//   termAndCondition: string;
//   createdAt: string;
//   // fields for TraderHub
//   name: string;
//   phone: string;
//   planNameHub?: string;
//   planTypeHub?: string;
//   planPeriodHub?: string;
//   planAmountHub?: string;
//   renewalDate: string;
//   expiryDate: string;
//   expireDays: string;
//   termAgreed: string;
//   status: string;
//   PaymentMethodHub: string;
//   PaymentReceiptsHub: string;
//   updatedAt: string;
// }

// const useSubNotification = (): {
//   notifications: Notification[];
//   loading: boolean;
//   error: string | null;
//   fetchNotifications: () => Promise<void>;
//   removeNotification: (orderID: string, PaymentReceipts: string) => Promise<void>;
//   modifyNotification: (notification: Notification) => Promise<void>;
//   fetchProductNames: () => Promise<string[]>;
//   fetchPlans: (orderType: 'sub' | 'hub') => Promise<Plan[]>;
//   fetchStats: () => Promise<{ totalSubscribers: number, activePlans: number, expiredPlans: number }>;
//   formatDate: (date: string) => string;
//   formatData: (data: string | null | undefined) => string;
//   getStatusClass: (status: string) => string;
//   getStatusHubClass: (status: string) => string;
//   approveNotification: (notification: Notification) => Promise<void>;
//   rejectNotification: (notification: Notification) => Promise<void>;
//   addNewNotification: (notification: Notification) => Promise<void>;
// } => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const formatDate = (date: string) => {
//     const options: Intl.DateTimeFormatOptions = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     };
//     return new Date(date).toLocaleDateString('en-CA', options);
//   };

//   const formatData = (data: string | null | undefined) => {
//     return data ? data : "No Data";
//   };

//   const getStatusClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "Expire":
//         return "bg-rose-500 text-white";
//       case "Deactive":
//         return "bg-gray-200 text-gray-400";
//       default:
//         return "bg-gray-200 text-gray-400";
//     }
//   };

//   const getStatusHubClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "Expire":
//         return "bg-rose-500 text-white";
//       case "Deactive":
//         return "bg-gray-300 text-gray-400";
//       default:
//         return "bg-gray-300 text-gray-400";
//     }
//   };

//   const fetchNotifications = useCallback(async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/sub-notifications`);
//       if (!response.ok) {
//         throw new Error(`Network response was not ok: ${response.statusText}`);
//       }
//       const data: Notification[] = await response.json();
  
//       // Normalize TraderHub statuses to match AlgoMarix statuses
//       const normalizedData = data.map(notification => {
//         if (notification.planTypeHub) {
//           // Normalize TraderHub status to match AlgoMarix status naming
//           notification.productName = 'Trader Hub';
//           notification.accountStatus = notification.status || ''; 
//           notification.userName = notification.name || '';
//           notification.planName = notification.planNameHub || '';
//           notification.planType = notification.planTypeHub || '';
//           notification.planPeriod = notification.planPeriodHub || ''; 
//           notification.planAmount = notification.planAmountHub || '';
//           notification.planSubDate = notification.renewalDate || '';
//           notification.planExpiredDate = notification.expiryDate || '';
//           notification.planDays = notification.expireDays || '';
//           notification.termAndCondition = notification.termAgreed || ''; 
//           notification.PaymentMethod = notification.PaymentMethodHub || '';
//           notification.PaymentReceipts = notification.PaymentReceiptsHub;
//         }
//         return notification;
//       });
  
//       setNotifications(normalizedData);
//     } catch (error: unknown) {
//       console.error('Failed to fetch notifications', error);
//       if (error instanceof Error) {
//         setError(error.message);
//       } else {
//         setError('Failed to fetch notifications');
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);  

//   const addNewNotification = async (notification: Notification) => {
//     try {
//       // Handle Trial Plan: Ensure that planPeriod is duplicated into planTrialPeriod and planTrialDays
//       if (notification.planType === 'Trial') {
//         notification.planTrialPeriod = notification.planPeriod;
//         notification.planTrialDays = notification.planPeriod;
//       }

//       const response = await fetch(`${apiUrl}/api/add-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(notification),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to add subscription');
//       }
  
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to add subscription:', error);
//     }
//   };
    
//   const removeNotification = useCallback(async (orderID: string, PaymentReceipts: string) => {
//     try {
  
//       const response = await fetch(`${apiUrl}/api/remove-subscription`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ orderID, PaymentReceipt: PaymentReceipts }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to remove subscription');
//       }
  
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to remove subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);  

//   const modifyNotification = useCallback(async (notification: Notification) => {
//     try {
//       // Adjust the notification structure if it's a Trader Hub product
//       if (notification.productName === 'Trader Hub') {
//         notification.accountStatus = notification.status || '';
//         notification.planType = notification.planTypeHub || '';
//         notification.planPeriod = notification.planPeriodHub || '';
//         notification.planAmount = notification.planAmountHub || '';
//         notification.planSubDate = notification.updatedAt || '';
//         notification.planExpiredDate = notification.expiryDate || '';
//         notification.planDays = notification.expireDays || '';
//         notification.PaymentMethod = notification.PaymentMethodHub || '';
//         notification.PaymentReceipts = notification.PaymentReceiptsHub || '';
//       }

//       const response = await fetch(`${apiUrl}/api/modify-subscription`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(notification),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to modify subscription');
//       }

//       fetchNotifications(); 
//     } catch (error) {
//       console.error('Failed to modify subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);

//   const approveNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/approve-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to approve subscription');
//       }

//       fetchNotifications();
//     } catch (error) {
//       console.error('Failed to approve subscription', error);
//     }
//   };

//   const rejectNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/reject-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to reject subscription');
//       }

//       fetchNotifications(); 
//     } catch (error) {
//       console.error('Failed to reject subscription', error);
//     }
//   }; 

//   const fetchProductNames = async (): Promise<string[]> => {
//     try {
//       const response = await fetch(`${apiUrl}/api/product-names`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch product names');
//       }
//       const productNames: string[] = await response.json();

//       return productNames;
//     } catch (error) {
//       console.error('Error fetching product names:', error);
//       return [];
//     }
//   };  

//   const fetchPlans = async (orderType: 'sub' | 'hub'): Promise<Plan[]> => {
//     try {
//       const response = await fetch(`${apiUrl}/api/plans?type=${orderType}`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch plans');
//       }
//       const plans: Plan[] = await response.json();
      
//       return plans.map(plan => ({
//         id: plan.id,
//         planID: plan.planID,
//         planName: plan.planName,
//         planAmount: plan.planAmount,
//       }));
//     } catch (error) {
//       console.error('Error fetching plans:', error);
//       return [];
//     }
//   };

//   const fetchStats = useCallback(async () => {
//     try {
//         const response = await fetch(`${apiUrl}/api/stats`);
//         if (!response.ok) {
//             throw new Error('Failed to fetch stats');
//         }
//         const data = await response.json();

//         const totalSubscribers = data.totalSubscribers;
//         const activePlans = data.activePlans;
//         const expiredPlans = data.expiredPlans;

//         return { totalSubscribers, activePlans, expiredPlans };
//     } catch (error) {
//         console.error('Failed to fetch stats', error);
//         throw error;
//     }
//   }, [apiUrl]);


//   return { 
//     notifications, 
//     loading, error,
//     fetchProductNames,
//     fetchPlans, 
//     fetchStats, 
//     formatDate, 
//     formatData, 
//     getStatusClass,
//     getStatusHubClass,
//     fetchNotifications,
//     removeNotification,
//     modifyNotification,
//     approveNotification, 
//     rejectNotification,
//     addNewNotification, 
//   };
// };

// export default useSubNotification;

// // update code --> 05 Sept 2024
// // src/hooks/AdminDashboard/useSubNotification.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Plan {
//   id: string;
//   planID: string;
//   planName: string;
//   planAmount: string;
// }

// export interface Notification {
//   // fields for AlgoMatrix
//   orderID: string;
//   productName: string;
//   planID: string;
//   planName: string;
//   userName: string;
//   email: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planPeriod: string;
//   planExpiredDate: string;
//   planDays: string;
//   planTrialPeriod: string;
//   planExpiredTrialDate: string;
//   planTrialDays: string;
//   accountID: string;
//   accountType: string;
//   accountServer: string;
//   accountNumber: string;
//   accountPassword: string;
//   accountStatus: string;
//   accountSubCheck: string;
//   accountTrialCheck: string;
//   accountVoucherCheck: string;
//   PaymentMethod: string;
//   PaymentReceipts: string;
//   notes: string;
//   termAndCondition: string;
//   createdAt: string;
//   // fields for TraderHub
//   name: string;
//   planNameHub?: string;
//   planTypeHub?: string;
//   planPeriodHub?: string;
//   planAmountHub?: string;
//   renewalDate: string;
//   expiryDate: string;
//   expireDays: string;
//   termAgreed: string;
//   status: string;
//   PaymentMethodHub: string;
//   PaymentReceiptsHub: string;
//   updatedAt: string;
// }

// const useSubNotification = (): {
//   notifications: Notification[];
//   loading: boolean;
//   error: string | null;
//   fetchNotifications: () => Promise<void>;
//   removeNotification: (orderID: string, PaymentReceipts: string) => Promise<void>;
//   modifyNotification: (notification: Notification) => Promise<void>;
//   fetchProductNames: () => Promise<string[]>;
//   fetchPlans: (orderType: 'sub' | 'hub') => Promise<Plan[]>;
//   fetchStats: () => Promise<{ totalSubscribers: number, activePlans: number, expiredPlans: number }>;
//   formatDate: (date: string) => string;
//   formatData: (data: string | null | undefined) => string;
//   getStatusClass: (status: string) => string;
//   getStatusHubClass: (status: string) => string;
//   approveNotification: (notification: Notification) => Promise<void>;
//   rejectNotification: (notification: Notification) => Promise<void>;
//   addNewNotification: (notification: Notification) => Promise<void>;
// } => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const formatDate = (date: string) => {
//     const options: Intl.DateTimeFormatOptions = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     };
//     return new Date(date).toLocaleDateString('en-CA', options);
//   };

//   const formatData = (data: string | null | undefined) => {
//     return data ? data : "No Data";
//   };

//   const getStatusClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "Expire":
//         return "bg-rose-500 text-white";
//       case "Deactive":
//         return "bg-gray-200 text-gray-400";
//       default:
//         return "bg-gray-200 text-gray-400";
//     }
//   };

//   const getStatusHubClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "Expire":
//         return "bg-rose-500 text-white";
//       case "Deactive":
//         return "bg-gray-300 text-gray-400";
//       default:
//         return "bg-gray-300 text-gray-400";
//     }
//   };

//   const fetchNotifications = useCallback(async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/sub-notifications`);
//       if (!response.ok) {
//         throw new Error(`Network response was not ok: ${response.statusText}`);
//       }
//       const data: Notification[] = await response.json();
  
//       // Normalize TraderHub statuses to match AlgoMarix statuses
//       const normalizedData = data.map(notification => {
//         if (notification.planTypeHub) {
//           // Normalize TraderHub status to match AlgoMarix status naming
//           notification.productName = 'Trader Hub';
//           notification.accountStatus = notification.status || ''; 
//           notification.userName = notification.name || '';
//           notification.planName = notification.planNameHub || '';
//           notification.planType = notification.planTypeHub || '';
//           notification.planPeriod = notification.planPeriodHub || ''; 
//           notification.planAmount = notification.planAmountHub || '';
//           notification.planSubDate = notification.renewalDate || '';
//           notification.planExpiredDate = notification.expiryDate || '';
//           notification.planDays = notification.expireDays || '';
//           notification.termAndCondition = notification.termAgreed || ''; 
//           notification.PaymentMethod = notification.PaymentMethodHub || '';
//           notification.PaymentReceipts = notification.PaymentReceiptsHub;
//         }
//         return notification;
//       });
  
//       setNotifications(normalizedData);
//     } catch (error: unknown) {
//       console.error('Failed to fetch notifications', error);
//       if (error instanceof Error) {
//         setError(error.message);
//       } else {
//         setError('Failed to fetch notifications');
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);  

//   const addNewNotification = async (notification: Notification) => {
//     try {
//       // Check for required fields here
//       if (!notification.planName || !notification.email || !notification.planID) {
//         throw new Error("Required fields are missing");
//       }

//       const response = await fetch(`${apiUrl}/api/add-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(notification),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to add subscription');
//       }
  
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to add subscription:', error);
//     }
//   };
    
//   const removeNotification = useCallback(async (orderID: string, PaymentReceipts: string) => {
//     try {
  
//       const response = await fetch(`${apiUrl}/api/remove-subscription`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ orderID, PaymentReceipt: PaymentReceipts }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to remove subscription');
//       }
  
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to remove subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);  

//   const modifyNotification = useCallback(async (notification: Notification) => {
//     try {
//       // Adjust the notification structure if it's a Trader Hub product
//       if (notification.productName === 'Trader Hub') {
//         notification.accountStatus = notification.status || '';
//         notification.planType = notification.planTypeHub || '';
//         notification.planPeriod = notification.planPeriodHub || '';
//         notification.planAmount = notification.planAmountHub || '';
//         notification.planSubDate = notification.updatedAt || '';
//         notification.planExpiredDate = notification.expiryDate || '';
//         notification.planDays = notification.expireDays || '';
//         notification.PaymentMethod = notification.PaymentMethodHub || '';
//         notification.PaymentReceipts = notification.PaymentReceiptsHub || '';
//       }

//       const response = await fetch(`${apiUrl}/api/modify-subscription`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(notification),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to modify subscription');
//       }

//       fetchNotifications(); 
//     } catch (error) {
//       console.error('Failed to modify subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);

//   const approveNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/approve-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to approve subscription');
//       }

//       fetchNotifications();
//     } catch (error) {
//       console.error('Failed to approve subscription', error);
//     }
//   };

//   const rejectNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/reject-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to reject subscription');
//       }

//       fetchNotifications(); 
//     } catch (error) {
//       console.error('Failed to reject subscription', error);
//     }
//   }; 

//   const fetchProductNames = async (): Promise<string[]> => {
//     try {
//       const response = await fetch(`${apiUrl}/api/product-names`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch product names');
//       }
//       const productNames: string[] = await response.json();

//       return productNames;
//     } catch (error) {
//       console.error('Error fetching product names:', error);
//       return [];
//     }
//   };  

//   const fetchPlans = async (orderType: 'sub' | 'hub'): Promise<Plan[]> => {
//     try {
//       const response = await fetch(`${apiUrl}/api/plans?type=${orderType}`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch plans');
//       }
//       const plans: Plan[] = await response.json();
      
//       return plans.map(plan => ({
//         id: plan.id,
//         planID: plan.planID,
//         planName: plan.planName,
//         planAmount: plan.planAmount,
//       }));
//     } catch (error) {
//       console.error('Error fetching plans:', error);
//       return [];
//     }
//   };

//   const fetchStats = useCallback(async () => {
//     try {
//         const response = await fetch(`${apiUrl}/api/stats`);
//         if (!response.ok) {
//             throw new Error('Failed to fetch stats');
//         }
//         const data = await response.json();

//         // Assuming data.totalSubscribers, data.activePlans, and data.expiredPlans are numbers, not arrays
//         const totalSubscribers = data.totalSubscribers;
//         const activePlans = data.activePlans;
//         const expiredPlans = data.expiredPlans;

//         return { totalSubscribers, activePlans, expiredPlans };
//     } catch (error) {
//         console.error('Failed to fetch stats', error);
//         throw error;
//     }
//   }, [apiUrl]);


//   return { 
//     notifications, 
//     loading, error,
//     fetchProductNames,
//     fetchPlans, 
//     fetchStats, 
//     formatDate, 
//     formatData, 
//     getStatusClass,
//     getStatusHubClass,
//     fetchNotifications,
//     removeNotification,
//     modifyNotification,
//     approveNotification, 
//     rejectNotification,
//     addNewNotification, 
//   };
// };

// export default useSubNotification;

// // updatree code --> 04 Sept 2024
// // src/hooks/AdminDashboard/useSubNotification.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Notification {
//   // fields for AlgoMatrix
//   orderID: string;
//   productName: string;
//   planID: string;
//   planName: string;
//   userName: string;
//   email: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planPeriod: string;
//   planExpiredDate: string;
//   planDays: string;
//   planTrialPeriod: string;
//   planExpiredTrialDate: string;
//   planTrialDays: string;
//   accountID: string;
//   accountType: string;
//   accountServer: string;
//   accountNumber: string;
//   accountPassword: string;
//   accountStatus: string;
//   accountSubCheck: string;
//   accountTrialCheck: string;
//   accountVoucherCheck: string;
//   PaymentMethod: string;
//   PaymentReceipts: string;
//   notes: string;
//   termAndCondition: string;
//   createdAt: string;
//   // fields for TraderHub
//   name: string;
//   planNameHub?: string;
//   planTypeHub?: string;
//   planPeriodHub?: string;
//   planAmountHub?: string;
//   renewalDate: string;
//   expiryDate: string;
//   expireDays: string;
//   termAgreed: string;
//   status: string;
//   PaymentMethodHub: string;
//   PaymentReceiptsHub: string;
//   updatedAt: string;
// }

// const useSubNotification = (): {
//   notifications: Notification[];
//   loading: boolean;
//   error: string | null;
//   fetchNotifications: () => Promise<void>;
//   removeNotification: (orderID: string, PaymentReceipts: string) => Promise<void>;
//   modifyNotification: (notification: Notification) => Promise<void>;
//   fetchPlans: (orderType: 'sub' | 'hub') => Promise<{ id: string; name: string }[]>;
//   fetchStats: () => Promise<{ totalSubscribers: number, activePlans: number, expiredPlans: number }>;
//   formatDate: (date: string) => string;
//   formatData: (data: string | null | undefined) => string;
//   getStatusClass: (status: string) => string;
//   getStatusHubClass: (status: string) => string;
//   approveNotification: (notification: Notification) => Promise<void>;
//   rejectNotification: (notification: Notification) => Promise<void>;
//   addNewNotification: (notification: Notification) => Promise<void>;
// } => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const formatDate = (date: string) => {
//     const options: Intl.DateTimeFormatOptions = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     };
//     return new Date(date).toLocaleDateString('en-CA', options);
//   };

//   const formatData = (data: string | null | undefined) => {
//     return data ? data : "No Data";
//   };

//   const getStatusClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "Expire":
//         return "bg-rose-500 text-white";
//       case "Deactive":
//         return "bg-gray-200 text-gray-400";
//       default:
//         return "bg-gray-200 text-gray-400";
//     }
//   };

//   const getStatusHubClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "Expire":
//         return "bg-rose-500 text-white";
//       case "Deactive":
//         return "bg-gray-300 text-gray-400";
//       default:
//         return "bg-gray-300 text-gray-400";
//     }
//   };

//   const fetchNotifications = useCallback(async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/sub-notifications`);
//       if (!response.ok) {
//         throw new Error(`Network response was not ok: ${response.statusText}`);
//       }
//       const data: Notification[] = await response.json();
  
//       // Normalize TraderHub statuses to match AlgoMarix statuses
//       const normalizedData = data.map(notification => {
//         if (notification.planTypeHub) {
//           // Normalize TraderHub status to match AlgoMarix status naming
//           notification.productName = 'Trader Hub';
//           notification.accountStatus = notification.status || ''; 
//           notification.userName = notification.name || '';
//           notification.planName = notification.planNameHub || '';
//           notification.planType = notification.planTypeHub || '';
//           notification.planPeriod = notification.planPeriodHub || ''; 
//           notification.planAmount = notification.planAmountHub || '';
//           notification.planSubDate = notification.renewalDate || '';
//           notification.planExpiredDate = notification.expiryDate || '';
//           notification.planDays = notification.expireDays || '';
//           notification.termAndCondition = notification.termAgreed || ''; 
//           notification.PaymentMethod = notification.PaymentMethodHub || '';
//           notification.PaymentReceipts = notification.PaymentReceiptsHub;
//         }
//         return notification;
//       });
  
//       setNotifications(normalizedData);
//     } catch (error: unknown) {
//       console.error('Failed to fetch notifications', error);
//       if (error instanceof Error) {
//         setError(error.message);
//       } else {
//         setError('Failed to fetch notifications');
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);  

//   const addNewNotification = async (notification: Notification) => {
//     try {
//       console.log("Sending the following notification data to the server:", notification); // Log the notification data
  
//       const response = await fetch(`${apiUrl}/api/add-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(notification),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to add subscription');
//       }
  
//       console.log("Subscription successfully added to the server.");
  
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to add subscription:', error);
//     }
//   };
    
//   const removeNotification = useCallback(async (orderID: string, PaymentReceipts: string) => {
//     try {
  
//       const response = await fetch(`${apiUrl}/api/remove-subscription`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ orderID, PaymentReceipt: PaymentReceipts }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to remove subscription');
//       }
  
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to remove subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);  

//   const modifyNotification = useCallback(async (notification: Notification) => {
//     try {
//       // Adjust the notification structure if it's a Trader Hub product
//       if (notification.productName === 'Trader Hub') {
//         notification.accountStatus = notification.status || '';
//         notification.planType = notification.planTypeHub || '';
//         notification.planPeriod = notification.planPeriodHub || '';
//         notification.planAmount = notification.planAmountHub || '';
//         notification.planSubDate = notification.updatedAt || '';
//         notification.planExpiredDate = notification.expiryDate || '';
//         notification.planDays = notification.expireDays || '';
//         notification.PaymentMethod = notification.PaymentMethodHub || '';
//         notification.PaymentReceipts = notification.PaymentReceiptsHub || '';
//       }

//       const response = await fetch(`${apiUrl}/api/modify-subscription`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(notification),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to modify subscription');
//       }

//       fetchNotifications(); 
//     } catch (error) {
//       console.error('Failed to modify subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);

//   const approveNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/approve-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to approve subscription');
//       }

//       fetchNotifications();
//     } catch (error) {
//       console.error('Failed to approve subscription', error);
//     }
//   };

//   const rejectNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/reject-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to reject subscription');
//       }

//       fetchNotifications(); 
//     } catch (error) {
//       console.error('Failed to reject subscription', error);
//     }
//   };

//   const fetchPlans = async (orderType: 'sub' | 'hub') => {
//     try {
//       const response = await fetch(`${apiUrl}/api/plans?type=${orderType}`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch plans');
//       }
//       return await response.json();
//     } catch (error) {
//       console.error('Error fetching plans:', error);
//       return [];
//     }
//   };  
  
//   const fetchStats = useCallback(async () => {
//     try {
//         const response = await fetch(`${apiUrl}/api/stats`);
//         if (!response.ok) {
//             throw new Error('Failed to fetch stats');
//         }
//         const data = await response.json();

//         // Assuming data.totalSubscribers, data.activePlans, and data.expiredPlans are numbers, not arrays
//         const totalSubscribers = data.totalSubscribers;
//         const activePlans = data.activePlans;
//         const expiredPlans = data.expiredPlans;

//         return { totalSubscribers, activePlans, expiredPlans };
//     } catch (error) {
//         console.error('Failed to fetch stats', error);
//         throw error;
//     }
//   }, [apiUrl]);


//   return { 
//     notifications, 
//     loading, error,
//     fetchPlans, 
//     fetchStats, 
//     formatDate, 
//     formatData, 
//     getStatusClass,
//     getStatusHubClass,
//     fetchNotifications,
//     removeNotification,
//     modifyNotification,
//     approveNotification, 
//     rejectNotification,
//     addNewNotification, 
//   };
// };

// export default useSubNotification;

// // update code --> 02 sept 2024
// // src/hooks/AdminDashboard/useSubNotification.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Notification {
//   // fields for AlgoMatrix
//   orderID: string;
//   productName: string;
//   planName: string;
//   userName: string;
//   email: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planPeriod: string;
//   planExpiredDate: string;
//   planDays: string;
//   planTrialPeriod: string;
//   planExpiredTrialDate: string;
//   planTrialDays: string;
//   accountID: string;
//   accountType: string;
//   accountServer: string;
//   accountNumber: string;
//   accountPassword: string;
//   accountStatus: string;
//   accountSubCheck: string;
//   accountTrialCheck: string;
//   accountVoucherCheck: string;
//   PaymentMethod: string;
//   PaymentReceipts: string;
//   notes: string;
//   termAndCondition: string;
//   createdAt: string;
//   // fields for TraderHub
//   name: string;
//   planNameHub?: string;
//   planTypeHub?: string;
//   planPeriodHub?: string;
//   planAmountHub?: string;
//   renewalDate: string;
//   expiryDate: string;
//   expireDays: string;
//   termAgreed: string;
//   status: string;
//   PaymentMethodHub: string;
//   PaymentReceiptsHub: string;
//   updatedAt: string;
// }

// const useSubNotification = (): {
//   notifications: Notification[];
//   loading: boolean;
//   error: string | null;
//   fetchNotifications: () => Promise<void>;
//   removeNotification: (orderID: string, PaymentReceipts: string) => Promise<void>;
//   modifyNotification: (notification: Notification) => Promise<void>;
//   fetchStats: () => Promise<{ totalSubscribers: number, activePlans: number, expiredPlans: number }>;
//   formatDate: (date: string) => string;
//   formatData: (data: string | null | undefined) => string;
//   getStatusClass: (status: string) => string;
//   getStatusHubClass: (status: string) => string;
//   approveNotification: (notification: Notification) => Promise<void>;
//   rejectNotification: (notification: Notification) => Promise<void>;
//   addNewNotification: (notification: Notification) => Promise<void>;
// } => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const formatDate = (date: string) => {
//     const options: Intl.DateTimeFormatOptions = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     };
//     return new Date(date).toLocaleDateString('en-CA', options);
//   };

//   const formatData = (data: string | null | undefined) => {
//     return data ? data : "No Data";
//   };

//   const getStatusClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "Expire":
//         return "bg-rose-500 text-white";
//       case "Deactive":
//         return "bg-gray-200 text-gray-400";
//       default:
//         return "bg-gray-200 text-gray-400";
//     }
//   };

//   const getStatusHubClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "Expire":
//         return "bg-rose-500 text-white";
//       case "Deactive":
//         return "bg-gray-300 text-gray-400";
//       default:
//         return "bg-gray-300 text-gray-400";
//     }
//   };

//   const fetchNotifications = useCallback(async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/sub-notifications`);
//       if (!response.ok) {
//         throw new Error(`Network response was not ok: ${response.statusText}`);
//       }
//       const data: Notification[] = await response.json();
  
//       // Normalize TraderHub statuses to match AlgoMarix statuses
//       const normalizedData = data.map(notification => {
//         if (notification.planTypeHub) {
//           // Normalize TraderHub status to match AlgoMarix status naming
//           notification.productName = 'Trader Hub';
//           notification.accountStatus = notification.status || ''; 
//           notification.userName = notification.name || '';
//           notification.planName = notification.planNameHub || '';
//           notification.planType = notification.planTypeHub || '';
//           notification.planPeriod = notification.planPeriodHub || ''; 
//           notification.planAmount = notification.planAmountHub || '';
//           notification.planSubDate = notification.renewalDate || '';
//           notification.planExpiredDate = notification.expiryDate || '';
//           notification.planDays = notification.expireDays || '';
//           notification.termAndCondition = notification.termAgreed || ''; 
//           notification.PaymentMethod = notification.PaymentMethodHub || '';
//           notification.PaymentReceipts = notification.PaymentReceiptsHub;
//         }
//         return notification;
//       });
  
//       setNotifications(normalizedData);
//     } catch (error: unknown) {
//       console.error('Failed to fetch notifications', error);
//       if (error instanceof Error) {
//         setError(error.message);
//       } else {
//         setError('Failed to fetch notifications');
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);  

//   const addNewNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/add-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(notification),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to add subscription');
//       }
  
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to add subscription', error);
//     }
//   };  

//   const removeNotification = useCallback(async (orderID: string, PaymentReceipts: string) => {
//     try {
  
//       const response = await fetch(`${apiUrl}/api/remove-subscription`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ orderID, PaymentReceipt: PaymentReceipts }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to remove subscription');
//       }
  
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to remove subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);  

//   const modifyNotification = useCallback(async (notification: Notification) => {
//     try {
//       // Adjust the notification structure if it's a Trader Hub product
//       if (notification.productName === 'Trader Hub') {
//         notification.accountStatus = notification.status || '';
//         notification.planType = notification.planTypeHub || '';
//         notification.planPeriod = notification.planPeriodHub || '';
//         notification.planAmount = notification.planAmountHub || '';
//         notification.planSubDate = notification.updatedAt || '';
//         notification.planExpiredDate = notification.expiryDate || '';
//         notification.planDays = notification.expireDays || '';
//         notification.PaymentMethod = notification.PaymentMethodHub || '';
//         notification.PaymentReceipts = notification.PaymentReceiptsHub || '';
//       }

//       const response = await fetch(`${apiUrl}/api/modify-subscription`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(notification),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to modify subscription');
//       }

//       fetchNotifications(); 
//     } catch (error) {
//       console.error('Failed to modify subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);

//   const approveNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/approve-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to approve subscription');
//       }

//       fetchNotifications();
//     } catch (error) {
//       console.error('Failed to approve subscription', error);
//     }
//   };

//   const rejectNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/reject-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to reject subscription');
//       }

//       fetchNotifications(); 
//     } catch (error) {
//       console.error('Failed to reject subscription', error);
//     }
//   };
  
//   const fetchStats = useCallback(async () => {
//     try {
//         const response = await fetch(`${apiUrl}/api/stats`);
//         if (!response.ok) {
//             throw new Error('Failed to fetch stats');
//         }
//         const data = await response.json();

//         // Assuming data.totalSubscribers, data.activePlans, and data.expiredPlans are numbers, not arrays
//         const totalSubscribers = data.totalSubscribers;
//         const activePlans = data.activePlans;
//         const expiredPlans = data.expiredPlans;

//         return { totalSubscribers, activePlans, expiredPlans };
//     } catch (error) {
//         console.error('Failed to fetch stats', error);
//         throw error;
//     }
//   }, [apiUrl]);


//   return { 
//     notifications, 
//     loading, error, 
//     fetchStats, 
//     formatDate, 
//     formatData, 
//     getStatusClass,
//     getStatusHubClass,
//     fetchNotifications,
//     removeNotification,
//     modifyNotification,
//     approveNotification, 
//     rejectNotification,
//     addNewNotification, 
//   };
// };

// export default useSubNotification;


// // udpatae code --> 30 Aug 2024
// // src/hooks/AdminDashboard/useSubNotification.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Notification {
//   // fields for AlgoMatrix
//   orderID: string;
//   planName: string;
//   userName: string;
//   email: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planPeriod: string;
//   planExpiredDate: string;
//   planDays: string;
//   planTrialPeriod: string;
//   planExpiredTrialDate: string;
//   planTrialDays: string;
//   accountID: string;
//   accountType: string;
//   accountServer: string;
//   accountNumber: string;
//   accountPassword: string;
//   accountStatus: string;
//   accountSubCheck: string;
//   accountTrialCheck: string;
//   accountVoucherCheck: string;
//   PaymentMethod: string;
//   PaymentReceipts: string;
//   notes: string;
//   termAndCondition: string;
//   createdAt: string;
//   // fields for TraderHub
//   name: string;
//   planNameHub?: string;
//   planTypeHub?: string;
//   planPeriodHub?: string;
//   planAmountHub?: string;
//   renewalDate: string;
//   expiryDate: string;
//   expireDays: string;
//   termAgreed: string;
//   status: string;
//   PaymentMethodHub: string;
//   PaymentReceiptsHub: string;
//   updatedAt: string;
// }

// const useSubNotification = (): {
//   notifications: Notification[];
//   loading: boolean;
//   error: string | null;
//   fetchNotifications: () => Promise<void>;
//   removeNotification: (orderID: string, PaymentReceipts: string) => Promise<void>;
//   modifyNotification: (notification: Notification) => Promise<void>;
//   fetchStats: () => Promise<{ totalSubscribers: number, activePlans: number, expiredPlans: number }>;
//   formatDate: (date: string) => string;
//   formatData: (data: string | null | undefined) => string;
//   getStatusClass: (status: string) => string;
//   getStatusHubClass: (status: string) => string;
//   approveNotification: (notification: Notification) => Promise<void>;
//   rejectNotification: (notification: Notification) => Promise<void>;
// } => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const formatDate = (date: string) => {
//     const options: Intl.DateTimeFormatOptions = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     };
//     return new Date(date).toLocaleDateString('en-CA', options);
//   };

//   const formatData = (data: string | null | undefined) => {
//     return data ? data : "No Data";
//   };

//   const getStatusClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "Expire":
//         return "bg-rose-500 text-white";
//       case "Deactive":
//         return "bg-gray-200 text-gray-400";
//       default:
//         return "bg-gray-200 text-gray-400";
//     }
//   };

//   const getStatusHubClass = (status: string) => {
//     switch (status) {
//       case "pending":
//         return "bg-yellow-400 text-black";
//       case "running":
//         return "bg-green-500 text-white";
//       case "expired":
//         return "bg-rose-500 text-white";
//       case "stopped":
//         return "bg-gray-200 text-gray-400";
//       default:
//         return "bg-gray-200 text-gray-400";
//     }
//   };

//   const fetchNotifications = useCallback(async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/sub-notifications`);
//       if (!response.ok) {
//         throw new Error(`Network response was not ok: ${response.statusText}`);
//       }
//       const data: Notification[] = await response.json();
  
//       // Normalize TraderHub statuses to match AlgoMarix statuses
//       const normalizedData = data.map(notification => {
//         if (notification.planTypeHub) {
//           // Normalize TraderHub status to match AlgoMarix status naming
//           notification.accountStatus = notification.status || ''; 
//           notification.userName = notification.name || '';
//           notification.planName = notification.planNameHub || '';
//           notification.planType = notification.planTypeHub || '';
//           notification.planPeriod = notification.planPeriodHub || ''; 
//           notification.planAmount = notification.planAmountHub || '';
//           notification.planSubDate = notification.updatedAt || '';
//           notification.planExpiredDate = notification.renewalDate || '';
//           notification.planDays = notification.expireDays || '';
//           notification.termAndCondition = notification.termAgreed || ''; 
//           notification.PaymentMethod = notification.PaymentMethodHub || '';
//           notification.PaymentReceipts = notification.PaymentReceiptsHub;
//         }
//         return notification;
//       });
  
//       setNotifications(normalizedData);
//     } catch (error: unknown) {
//       console.error('Failed to fetch notifications', error);
//       if (error instanceof Error) {
//         setError(error.message);
//       } else {
//         setError('Failed to fetch notifications');
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);  

//   const removeNotification = useCallback(async (orderID: string, PaymentReceipts: string) => {
//     try {
  
//       const response = await fetch(`${apiUrl}/api/remove-subscription`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ orderID, PaymentReceipt: PaymentReceipts }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to remove subscription');
//       }
  
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to remove subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);  

//   const modifyNotification = useCallback(async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/modify-subscription`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(notification),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to modify subscription');
//       }

//       fetchNotifications(); 
//     } catch (error) {
//       console.error('Failed to modify subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);

//   const approveNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/approve-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to approve subscription');
//       }

//       fetchNotifications();
//     } catch (error) {
//       console.error('Failed to approve subscription', error);
//     }
//   };

//   const rejectNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/reject-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to reject subscription');
//       }

//       fetchNotifications(); 
//     } catch (error) {
//       console.error('Failed to reject subscription', error);
//     }
//   };

//   const fetchStats = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/api/stats`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch stats');
//       }
//       const data = await response.json();
//       return {
//         totalSubscribers: data.totalSubscribers,
//         activePlans: data.activePlans,
//         expiredPlans: data.expiredPlans,
//       };
//     } catch (error) {
//       console.error('Failed to fetch stats', error);
//       throw error;
//     }
//   }, [apiUrl]);

//   return { 
//     notifications, 
//     loading, error, 
//     fetchStats, 
//     formatDate, 
//     formatData, 
//     getStatusClass,
//     getStatusHubClass,
//     fetchNotifications,
//     removeNotification,
//     modifyNotification,
//     approveNotification, 
//     rejectNotification 
//   };
// };

// export default useSubNotification;

// // updatae code --> 29 Aug 2024
// // src/hooks/AdminDashboard/useSubNotification.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Notification {
//   orderID: string;
//   planName: string;
//   userName: string;
//   email: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planPeriod: string;
//   planExpiredDate: string;
//   planDays: string;
//   planTrialPeriod: string;
//   planExpiredTrialDate: string;
//   planTrialDays: string;
//   accountID: string;
//   accountType: string;
//   accountServer: string;
//   accountNumber: string;
//   accountPassword: string;
//   accountStatus: string;
//   accountSubCheck: string;
//   accountTrialCheck: string;
//   accountVoucherCheck: string;
//   PaymentMethod: string;
//   PaymentReceipts: string;
//   notes: string;
//   termAndCondition: string;
//   createdAt: string;
// }

// const useSubNotification = (): {
//   notifications: Notification[];
//   loading: boolean;
//   error: string | null;
//   fetchNotifications: () => Promise<void>;
//   removeNotification: (orderID: string, PaymentReceipts: string) => Promise<void>;
//   modifyNotification: (notification: Notification) => Promise<void>;
//   fetchStats: () => Promise<{ totalSubscribers: number, activePlans: number, expiredPlans: number }>;
//   formatDate: (date: string) => string;
//   formatData: (data: string | null | undefined) => string;
//   getStatusClass: (status: string) => string;
//   approveNotification: (notification: Notification) => Promise<void>;
//   rejectNotification: (notification: Notification) => Promise<void>;
// } => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const formatDate = (date: string) => {
//     const options: Intl.DateTimeFormatOptions = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     };
//     return new Date(date).toLocaleDateString('en-CA', options);
//   };

//   const formatData = (data: string | null | undefined) => {
//     return data ? data : "None Data";
//   };

//   const getStatusClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "Expire":
//         return "bg-rose-500 text-white";
//       case "Deactive":
//         return "bg-purple-500 text-white";
//       default:
//         return "bg-gray-400 text-white";
//     }
//   };

//   const fetchNotifications = useCallback(async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/sub-notifications`);
//       if (!response.ok) {
//         throw new Error(`Network response was not ok: ${response.statusText}`);
//       }
//       const data: Notification[] = await response.json();
//       setNotifications(data);
//     } catch (error: unknown) {
//       console.error('Failed to fetch notifications', error);
//       if (error instanceof Error) {
//         setError(error.message);
//       } else {
//         setError('Failed to fetch notifications');
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const removeNotification = useCallback(async (orderID: string, PaymentReceipts: string) => {
//     try {
  
//       const response = await fetch(`${apiUrl}/api/remove-subscription`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ orderID, PaymentReceipt: PaymentReceipts }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to remove subscription');
//       }
  
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to remove subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);  

//   const modifyNotification = useCallback(async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/modify-subscription`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(notification),
//       });
//       if (!response.ok) {
//         throw new Error('Failed to modify subscription');
//       }
//       fetchNotifications(); 
//     } catch (error) {
//       console.error('Failed to modify subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);

//   const approveNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/approve-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to approve subscription');
//       }
//     } catch (error) {
//       console.error('Failed to approve subscription', error);
//     }
//   };

//   const rejectNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/reject-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to reject subscription');
//       }
//     } catch (error) {
//       console.error('Failed to reject subscription', error);
//     }
//   };

//   const fetchStats = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/api/stats`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch stats');
//       }
//       const data = await response.json();
//       return {
//         totalSubscribers: data.totalSubscribers,
//         activePlans: data.activePlans,
//         expiredPlans: data.expiredPlans,
//       };
//     } catch (error) {
//       console.error('Failed to fetch stats', error);
//       throw error;
//     }
//   }, [apiUrl]);

//   return { 
//     notifications, 
//     loading, error, 
//     fetchStats, 
//     formatDate, 
//     formatData, 
//     getStatusClass, 
//     fetchNotifications,
//     removeNotification,
//     modifyNotification,
//     approveNotification, 
//     rejectNotification 
//   };
// };

// export default useSubNotification;

// // udpate code --> 18 Aug 2024
// // src/hooks/AdminDashboard/useSubNotification.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Notification {
//   orderID: string;
//   planName: string;
//   userName: string;
//   email: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planPeriod: string;
//   planExpiredDate: string;
//   planDays: string;
//   planTrialPeriod: string;
//   planExpiredTrialDate: string;
//   planTrialDays: string;
//   accountID: string;
//   accountType: string;
//   accountServer: string;
//   accountNumber: string;
//   accountPassword: string;
//   accountStatus: string;
//   accountSubCheck: string;
//   accountTrialCheck: string;
//   accountVoucherCheck: string;
//   PaymentMethod: string;
//   PaymentReceipts: string;
//   notes: string;
//   termAndCondition: string;
//   createdAt: string;
// }

// const useSubNotification = (): {
//   notifications: Notification[];
//   loading: boolean;
//   error: string | null;
//   fetchNotifications: () => Promise<void>;
//   removeNotification: (orderID: string, PaymentReceipts: string) => Promise<void>;
//   modifyNotification: (notification: Notification) => Promise<void>;
//   fetchStats: () => Promise<{ totalSubscribers: number, activePlans: number, expiredPlans: number }>;
//   formatDate: (date: string) => string;
//   formatData: (data: string | null | undefined) => string;
//   getStatusClass: (status: string) => string;
//   approveNotification: (notification: Notification) => Promise<void>;
//   rejectNotification: (notification: Notification) => Promise<void>;
// } => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const formatDate = (date: string) => {
//     const options: Intl.DateTimeFormatOptions = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     };
//     return new Date(date).toLocaleDateString('en-CA', options);
//   };

//   const formatData = (data: string | null | undefined) => {
//     return data ? data : "None Data";
//   };

//   const getStatusClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "Expire":
//         return "bg-rose-500 text-white";
//       case "Deactive":
//         return "bg-purple-500 text-white";
//       default:
//         return "bg-gray-400 text-white";
//     }
//   };

//   const fetchNotifications = useCallback(async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/sub-notifications`);
//       if (!response.ok) {
//         throw new Error(`Network response was not ok: ${response.statusText}`);
//       }
//       const data: Notification[] = await response.json();
//       setNotifications(data);
//     } catch (error: unknown) {
//       console.error('Failed to fetch notifications', error);
//       if (error instanceof Error) {
//         setError(error.message);
//       } else {
//         setError('Failed to fetch notifications');
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const removeNotification = useCallback(async (orderID: string, PaymentReceipts: string) => {
//     try {
//       console.log('Attempting to delete notification with orderID:', orderID);
  
//       const response = await fetch(`${apiUrl}/api/remove-subscription`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ orderID, PaymentReceipt: PaymentReceipts }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to remove subscription');
//       }
  
//       console.log('Notification deleted successfully, fetching updated notifications.');
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to remove subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);  

//   const modifyNotification = useCallback(async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/modify-subscription`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(notification),
//       });
//       if (!response.ok) {
//         throw new Error('Failed to modify subscription');
//       }
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to modify subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);

//   const approveNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/approve-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to approve subscription');
//       }
//     } catch (error) {
//       console.error('Failed to approve subscription', error);
//     }
//   };

//   const rejectNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/reject-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to reject subscription');
//       }
//     } catch (error) {
//       console.error('Failed to reject subscription', error);
//     }
//   };

//   const fetchStats = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/api/stats`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch stats');
//       }
//       const data = await response.json();
//       return {
//         totalSubscribers: data.totalSubscribers,
//         activePlans: data.activePlans,
//         expiredPlans: data.expiredPlans,
//       };
//     } catch (error) {
//       console.error('Failed to fetch stats', error);
//       throw error;
//     }
//   }, [apiUrl]);

//   return { 
//     notifications, 
//     loading, error, 
//     fetchStats, 
//     formatDate, 
//     formatData, 
//     getStatusClass, 
//     fetchNotifications,
//     removeNotification,
//     modifyNotification,
//     approveNotification, 
//     rejectNotification 
//   };
// };

// export default useSubNotification;

// // udpate code --> 13 Aug 2024
// // src/hooks/AdminDashboard/useSubNotification.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Notification {
//   orderID: string;
//   planName: string;
//   userName: string;
//   email: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planPeriod: string;
//   planExpiredDate: string;
//   planDays: string;
//   planTrialPeriod: string;
//   planExpiredTrialDate: string;
//   planTrialDays: string;
//   accountID: string;
//   accountType: string;
//   accountServer: string;
//   accountNumber: string;
//   accountPassword: string;
//   accountStatus: string;
//   accountSubCheck: string;
//   accountTrialCheck: string;
//   accountVoucherCheck: string;
//   PaymentMethod: string;
//   PaymentReceipts: string;
//   notes: string;
//   termAndCondition: string;
//   createdAt: string;
// }

// const useSubNotification = (): {
//   notifications: Notification[];
//   loading: boolean;
//   error: string | null;
//   fetchNotifications: () => Promise<void>;
//   removeNotification: (orderID: string) => Promise<void>;
//   modifyNotification: (notification: Notification) => Promise<void>;
//   fetchStats: () => Promise<{ totalSubscribers: number, activePlans: number, expiredPlans: number }>;
//   formatDate: (date: string) => string;
//   formatData: (data: string | null | undefined) => string;
//   getStatusClass: (status: string) => string;
//   approveNotification: (notification: Notification) => Promise<void>;
//   rejectNotification: (notification: Notification) => Promise<void>;
// } => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const formatDate = (date: string) => {
//     const options: Intl.DateTimeFormatOptions = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     };
//     return new Date(date).toLocaleDateString('en-CA', options);
//   };

//   const formatData = (data: string | null | undefined) => {
//     return data ? data : "None Data";
//   };

//   const getStatusClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "Expire":
//         return "bg-rose-500 text-white";
//       case "Deactive":
//         return "bg-purple-500 text-white";
//       default:
//         return "bg-gray-400 text-white";
//     }
//   };

//   const fetchNotifications = useCallback(async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/sub-notifications`);
//       if (!response.ok) {
//         throw new Error(`Network response was not ok: ${response.statusText}`);
//       }
//       const data: Notification[] = await response.json();
//       setNotifications(data);
//     } catch (error: unknown) {
//       console.error('Failed to fetch notifications', error);
//       if (error instanceof Error) {
//         setError(error.message);
//       } else {
//         setError('Failed to fetch notifications');
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const removeNotification = useCallback(async (orderID: string) => {
//     try {
//       console.log('Attempting to delete notification with orderID:', orderID);
  
//       const response = await fetch(`${apiUrl}/api/remove-subscription`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ orderID }),
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to remove subscription');
//       }
  
//       console.log('Notification deleted successfully, fetching updated notifications.');
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to remove subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);  

//   const modifyNotification = useCallback(async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/modify-subscription`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(notification),
//       });
//       if (!response.ok) {
//         throw new Error('Failed to modify subscription');
//       }
//       fetchNotifications(); // Refresh the notifications
//     } catch (error) {
//       console.error('Failed to modify subscription', error);
//     }
//   }, [apiUrl, fetchNotifications]);

//   const approveNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/approve-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to approve subscription');
//       }
//     } catch (error) {
//       console.error('Failed to approve subscription', error);
//     }
//   };

//   const rejectNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/reject-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to reject subscription');
//       }
//     } catch (error) {
//       console.error('Failed to reject subscription', error);
//     }
//   };

//   const fetchStats = useCallback(async () => {
//     try {
//       const response = await fetch(`${apiUrl}/api/stats`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch stats');
//       }
//       const data = await response.json();
//       return {
//         totalSubscribers: data.totalSubscribers,
//         activePlans: data.activePlans,
//         expiredPlans: data.expiredPlans,
//       };
//     } catch (error) {
//       console.error('Failed to fetch stats', error);
//       throw error;
//     }
//   }, [apiUrl]);

//   return { 
//     notifications, 
//     loading, error, 
//     fetchStats, 
//     formatDate, 
//     formatData, 
//     getStatusClass, 
//     fetchNotifications,
//     removeNotification,
//     modifyNotification,
//     approveNotification, 
//     rejectNotification 
//   };
// };

// export default useSubNotification;


// // update code --> 12 Aug 2024
// // src/hooks/AdminDashboard/useSubNotification.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface Notification {
//   orderID: string;
//   planName: string;
//   userName: string;
//   email: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planPeriod: string;
//   planExpiredDate: string;
//   planDays: string;
//   planTrialPeriod: string;
//   planExpiredTrialDate: string;
//   planTrialDays: string;
//   accountID: string;
//   accountType: string;
//   accountServer: string;
//   accountNumber: string;
//   accountPassword: string;
//   accountStatus: string;
//   accountSubCheck: string;
//   accountTrialCheck: string;
//   accountVoucherCheck: string;
//   PaymentMethod: string;
//   PaymentReceipts: string;
//   notes: string;
//   termAndCondition: string;
//   createdAt: string;
// }

// const useSubNotification = (): {
//   notifications: Notification[];
//   loading: boolean;
//   error: string | null;
//   fetchNotifications: () => Promise<void>;
//   formatDate: (date: string) => string;
//   formatData: (data: string | null | undefined) => string;
//   getStatusClass: (status: string) => string;
//   approveNotification: (notification: Notification) => Promise<void>;
//   rejectNotification: (notification: Notification) => Promise<void>;
// } => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const fetchNotifications = useCallback(async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/sub-notifications`);
//       if (!response.ok) {
//         throw new Error(`Network response was not ok: ${response.statusText}`);
//       }
//       const data: Notification[] = await response.json();
//       setNotifications(data);
//     } catch (error: unknown) {
//       console.error('Failed to fetch notifications', error);
//       if (error instanceof Error) {
//         setError(error.message);
//       } else {
//         setError('Failed to fetch notifications');
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const formatDate = (date: string) => {
//     const options: Intl.DateTimeFormatOptions = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     };
//     return new Date(date).toLocaleDateString('en-CA', options);
//   };

//   const formatData = (data: string | null | undefined) => {
//     return data ? data : "None Data";
//   };

//   const getStatusClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "expire":
//         return "bg-rose-500 text-white";
//       case "deactive":
//         return "bg-purple-500 text-white";
//       default:
//         return "bg-gray-400 text-white";
//     }
//   };

//   const approveNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/approve-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to approve subscription');
//       }
//     } catch (error) {
//       console.error('Failed to approve subscription', error);
//     }
//   };

//   const rejectNotification = async (notification: Notification) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/reject-subscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ notification }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to reject subscription');
//       }
//     } catch (error) {
//       console.error('Failed to reject subscription', error);
//     }
//   };

//   return { notifications, loading, error, fetchNotifications, formatDate, formatData, getStatusClass, approveNotification, rejectNotification };
// };

// export default useSubNotification;



// // update code --> 30 july 2024 v02
// // // src/hooks/AdminDashboard/useSubNotification.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface Notification {
//   orderID: string;
//   planName: string;
//   userName: string;
//   email: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planPeriod: string;
//   planTrialPeriod: string;
//   planTrialDays: string;
//   accountID:string;
//   accountType:string;
//   accountServer:string;
//   accountNumber:string;
//   accountPassword:string;
//   accountStatus:string;
//   accountSubCheck:string;
//   accountTrialCheck:string;
//   PaymentMethod: string;
//   PaymentReceipts: string;
//   notes: string;
//   termAndCondition: string;
//   createdAt: string;
// }

// const useSubNotification = (): {
//   notifications: Notification[];
//   loading: boolean;
//   error: string | null;
//   fetchNotifications: () => Promise<void>;
//   formatDate: (date: string) => string;
//   formatData: (data: string | null | undefined) => string;
//   getStatusClass: (status: string) => string;
// } => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const fetchNotifications = useCallback(async () => {
//     setLoading(true);
//     setError(null); // Reset error state before fetching
//     try {
//       const response = await fetch(`${apiUrl}/api/sub-notifications`);
//       if (!response.ok) {
//         throw new Error(`Network response was not ok: ${response.statusText}`);
//       }
//       const data: Notification[] = await response.json();
//       setNotifications(data);
//     } catch (error: unknown) {
//       console.error('Failed to fetch notifications', error);
//       if (error instanceof Error) {
//         setError(error.message);
//       } else {
//         setError('Failed to fetch notifications');
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const formatDate = (date: string) => {
//     const options: Intl.DateTimeFormatOptions = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//     };
//     return new Date(date).toLocaleDateString('en-CA', options); // 'en-CA' for yyyy-mm-dd format
//   };

//   const formatData = (data: string | null | undefined) => {
//     return data ? data : "None Data";
//   };

//   const getStatusClass = (status: string) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-400 text-black";
//       case "Active":
//         return "bg-green-500 text-white";
//       case "expire":
//         return "bg-rose-500 text-white";
//       case "deactive":
//         return "bg-purple-500 text-white";
//       default:
//         return "bg-gray-400 text-white";
//     }
//   };

//   return { notifications, loading, error, fetchNotifications, formatDate, formatData, getStatusClass };
// };

// export default useSubNotification;




// // update code --> 30 july 0224
// // src/hooks/AdminDashboard/useSubNotification.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface Notification {
//   orderID: string;
//   planName: string;
//   userName: string;
//   email: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planPeriod: string;
//   planTrialPeriod: string;
//   planTrialDays: string;
//   paymentReceipts: string;
//   actionStats: string;
//   requirementScreenshot?: string;
//   createdAt:string;
// }

// const useSubNotification = (): {
//   notifications: Notification[];
//   loading: boolean;
//   error: string | null;
//   fetchNotifications: () => Promise<void>;
// } => {
//   const { apiUrl } = useEnv();
//   const [notifications, setNotifications] = useState<Notification[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const fetchNotifications = useCallback(async () => {
//     setLoading(true);
//     setError(null); // Reset error state before fetching
//     try {
//       const response = await fetch(`${apiUrl}/api/sub-notifications`);
//       if (!response.ok) {
//         throw new Error(`Network response was not ok: ${response.statusText}`);
//       }
//       const data: Notification[] = await response.json();
//       setNotifications(data);
//     } catch (error: unknown) {
//       console.error('Failed to fetch notifications', error); 
//       if (error instanceof Error) {
//         setError(error.message);
//       } else {
//         setError('Failed to fetch notifications');
//       }
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);  

//   return { notifications, loading, error, fetchNotifications };
// };

// export default useSubNotification;


