// src/components/login/ResetAlert.tsx

import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { useTheme } from '../../context/ThemeContext'; 
import { useNavigate } from 'react-router-dom'; 

interface ResetAlertProps {
  visible: boolean;
  onClose: () => void;
}

const ResetAlert: React.FC<ResetAlertProps> = ({ visible, onClose }) => {
  const { theme } = useTheme();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (visible) {
      setShowModal(true);
      const timer = setTimeout(() => {
        onClose();
        navigate('/login');
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      setShowModal(false);
    }
  }, [visible, navigate, onClose]);

  if (!visible) return null;

  return (
    <div 
      className={`fixed inset-x-0 top-0 transition-transform transform ${showModal ? 'translate-y-0' : '-translate-y-full'} z-50 px-4`}
      style={{ fontFamily: theme.fontFamily }}
    >
      <div className="relative flex flex-col p-4 mt-4 mb-4 text-sm text-green-800 rounded-lg bg-green-100 border border-green-500 max-w-lg mx-auto md:max-w-2xl" role="alert">
        <div className="flex items-center">
          <FaCheckCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" />
          <span className="font-medium" style={{ color: theme.textColor }}>
            Password Reset Successful!
          </span>
        </div>
        <div className="mt-2 text-green-700 md:ml-4">
          Your password has been successfully reset. You will be redirected to the login page shortly.
        </div>
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-green-800 hover:text-green-600 focus:outline-none"
        >
          <FaTimes className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default ResetAlert;
