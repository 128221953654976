// src/components/client/TopMenu/AccountSettingsDrawer.tsx

import React from 'react';
import { useTheme } from '../../../context/ThemeContext';
import { motion } from 'framer-motion';
import { RxHamburgerMenu } from "react-icons/rx";
import { FaCog, FaUser, FaBell, FaMoon } from 'react-icons/fa';

interface AccountSettingsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const AccountSettingsDrawer: React.FC<AccountSettingsDrawerProps> = ({ isOpen, onClose }) => {
  const { theme } = useTheme();
  
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40" 
          onClick={onClose}>
        </div>
      )}
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: isOpen ? 0 : '100%' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        className="fixed top-0 right-0 w-80 h-full bg-white shadow-lg z-50"
      >
        <div className="flex justify-between items-center p-4 border-b bg-gray-100">
          <h2 className="text-xl font-semibold" 
            style={{ color:theme.primaryColor, fontFamily:theme.fontFamily }}>
              Account Settings
          </h2>
          <button 
            onClick={onClose} 
            className="text-gray-600 hover:text-gray-800 focus:outline-none">
            <RxHamburgerMenu 
              className="w-5 h-5" />
          </button>
        </div>

        {/* Profile Section Setting */}
        <div className="p-6 space-y-6">
          <div className="space-y-4">
            <div className="flex justify-between items-center border-b pb-2">
              <h3 className="text-lg font-medium">
                Profile
              </h3>
            </div>
            <div className="flex items-center justify-between">
              <span className="flex items-center space-x-2">
                <FaUser className="w-5 h-5" />
                <span>Setting 01</span>
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" disabled />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:bg-blue-600 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-white dark:border-gray-600"></div>
              </label>
            </div>
          </div>

          {/* Setting Section Setting */}
          <div className="space-y-4">
            <div className="flex justify-between items-center border-b pb-2">
              <h3 className="text-lg font-medium">
                Settings
              </h3>
            </div>
            <div className="flex items-center justify-between">
              <span className="flex items-center space-x-2">
                <FaMoon className="w-5 h-5" />
                <span>Setting 01</span>
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" disabled />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:bg-blue-600 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-white dark:border-gray-600"></div>
              </label>
            </div>
            <div className="flex items-center justify-between">
              <span className="flex items-center space-x-2">
                <FaBell className="w-5 h-5" />
                <span>Setting 02</span>
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" disabled />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:bg-blue-600 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-white dark:border-gray-600"></div>
              </label>
            </div>
          </div>

          {/* Security Section Setting */}
          <div className="space-y-4">
            <div className="flex justify-between items-center border-b pb-2">
              <h3 className="text-lg font-medium">
                Security
              </h3>
            </div>
            <div className="flex items-center justify-between">
              <span className="flex items-center space-x-2">
                <FaCog className="w-5 h-5" />
                <span>Security 01</span>
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" disabled />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:bg-blue-600 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-white dark:border-gray-600"></div>
              </label>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default AccountSettingsDrawer;





// // src/components/client/TopMenu/UserDropdown.tsx

// import React, { useState } from 'react';
// import { FaUser } from 'react-icons/fa';
// import { useTheme } from '../../../context/ThemeContext';
// import { useNavigate } from 'react-router-dom';

// const UserDropdown: React.FC = () => {
//   const { theme } = useTheme();
//   const [isOpen, setIsOpen] = useState(false);
//   const navigate = useNavigate();

//   return (
//     <div className="relative inline-block">
//       <button
//         onClick={() => setIsOpen(!isOpen)}
//         className="relative z-10 block p-2 text-gray-700"
//       >
//         <FaUser className="w-5 h-5" style={{ color: theme.textColor }} />
//       </button>

//       {isOpen && (
//         <div
//           onClick={() => setIsOpen(false)}
//           className="fixed inset-0 z-10 w-full h-full"
//         />
//       )}
//       <div
//         className={`absolute z-20 w-48 mt-2 mr-2 overflow-hidden origin-top-right bg-white rounded-md shadow-lg sm:w-60 ${
//           isOpen ? 'block' : 'hidden'
//         }`}
//       >
//         <div className="py-2">
//           <button
//             className="flex text-left w-full px-4 py-2 transition-colors duration-300 transform border-b border-gray-100 hover:bg-gray-50"
//             onClick={() => navigate('/profile')}
//           >
//             <p className="text-sm text-gray-600">Profile</p>
//           </button>
//           <button
//             className="flex text-left w-full px-4 py-2 transition-colors duration-300 transform border-b border-gray-100 hover:bg-gray-50"
//             onClick={() => navigate('/settings')}
//           >
//             <p className="text-sm text-gray-600">Settings</p>
//           </button>
//           <button
//             className="flex text-left w-full px-4 py-2 transition-colors duration-300 transform hover:bg-gray-100"
//             onClick={() => navigate('/logout')}
//           >
//             <p className="text-sm text-gray-600">Logout</p>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserDropdown;
