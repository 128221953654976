// src/components/admin/EventSetting/ProgramList.tsx

import React, { useState } from 'react';

// import context file
import { useTheme } from '../../../context/ThemeContext';
import { useEnv } from '../../../context/EnvContext';

// import hook files
import useProgram, { Program, convertToShortForm, getTagColor, formatTimeTo12Hour, formatAmount } from '../../../hooks/AdminDashboard/useProgram';

// import react-icons
import { FaCirclePlay, FaCirclePause, FaCalendarMinus } from "react-icons/fa6";
import { RiRefreshFill } from "react-icons/ri";
import { 
  FaSave, FaEdit, FaUndo, FaTrash, 
  FaPlayCircle, FaPauseCircle, FaExclamationTriangle,
  FaTag 
} from 'react-icons/fa';

// import file source
import SearchFilter from '../EventSetting/ProgramFilter';
import ProgramDetailModal from './ProgramDetailModal';
import ProgramListAlert from './ProgramListAlert';
import ProgramStats from './ProgramStats'; 

const ProgramList: React.FC = () => {
  const { apiUrl } = useEnv();
  const { theme } = useTheme();
  const { programs, setPrograms, loading, error, updateProgramStatus, deleteProgram, updateProgramDetails, fetchPrograms } = useProgram();
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
  const [activeTab, setActiveTab] = useState<'latest' | 'expired'>('latest');
  const [isEditing, setIsEditing] = useState(false);
  const [fieldValues, setFieldValues] = useState<Partial<Program>>({});
  const [searchText, setSearchText] = useState('');
  const [filterDates, setFilterDates] = useState<{ startDate: string; endDate: string }>({ startDate: '', endDate: '' });
  const [filterStatus, setFilterStatus] = useState<'all' | 'running' | 'stopped'>('all');
  const [alertDetails, setAlertDetails] = useState<{
    title: string;
    message: string;
    icon: React.ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
  } | null>(null);

  const handleApply = (programId: number) => {
    setAlertDetails({
      title: 'Start Program',
      message: 'Are you sure you want to start this program?',
      icon: <FaPlayCircle className="h-6 w-6 text-green-600" aria-hidden="true" />,
      onConfirm: async () => {
        await updateProgramStatus(programId, 'running');
        setAlertDetails(null);
        fetchPrograms();
      },
      onCancel: () => setAlertDetails(null),
    });
  };

  const handleCancel = (programId: number) => {
    setAlertDetails({
      title: 'Stop Program',
      message: 'Are you sure you want to stop this program?',
      icon: <FaPauseCircle className="h-6 w-6 text-red-600" aria-hidden="true" />,
      onConfirm: async () => {
        await updateProgramStatus(programId, 'stopped');
        setAlertDetails(null);
        fetchPrograms();
      },
      onCancel: () => setAlertDetails(null),
    });
  };

  const handleDelete = (programId: number) => {
    setAlertDetails({
      title: 'Delete Program',
      message: 'Are you sure you want to delete this program? This action cannot be undone.',
      icon: <FaExclamationTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />,
      onConfirm: async () => {
        await deleteProgram(programId);
        setAlertDetails(null);
        fetchPrograms();
      },
      onCancel: () => setAlertDetails(null),
    });
  };

  const handleRevoke = (programId: number) => {
    setAlertDetails({
      title: 'Revoke Program',
      message: 'Are you sure you want to revoke this program?',
      icon: <FaUndo className="h-6 w-6 text-yellow-600" aria-hidden="true" />,
      onConfirm: async () => {
        await updateProgramStatus(programId, 'stopped');
        setAlertDetails(null);
        fetchPrograms();
      },
      onCancel: () => setAlertDetails(null),
    });
  };

  const handleExpire = (programId: number) => {
    setAlertDetails({
      title: 'Expire Program',
      message: 'Are you sure you want to mark this program as expired?',
      icon: <FaCalendarMinus className="h-6 w-6 text-yellow-600" aria-hidden="true" />,
      onConfirm: async () => {
        try {
          const updatedProgram = await updateProgramStatus(programId, 'expired');
          if (updatedProgram) {
            setPrograms((prevPrograms) =>
              prevPrograms.map((program) =>
                program.id === programId ? { ...program, status: 'expired' } : program
              )
            );
            // Switch to the expired tab
            setActiveTab('expired');
          }
        } catch (err) {
          console.error('Error expiring program:', err);
        }
        setAlertDetails(null);
      },
      onCancel: () => setAlertDetails(null),
    });
  };  

  const formatDate = (date: string | null | undefined) => {
    if (!date) return '';
    const d = new Date(date);
    return d.toISOString().split('T')[0];
  };

  const handleProgramClick = (program: Program) => {
    setSelectedProgram(program);
    setIsEditing(false);
    setFieldValues({});
  };

  const handleFieldChange = (field: keyof Program, value: any) => {
    setFieldValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    if (selectedProgram) {
      setAlertDetails({
        title: 'Confirm Save',
        message: `Are you sure you want to save the changes to ${selectedProgram.name}?`,
        icon: <FaSave className="h-6 w-6 text-green-600" aria-hidden="true" />,
        onConfirm: async () => {
          await updateProgramDetails(selectedProgram.id, fieldValues);
          setSelectedProgram(null);
          fetchPrograms();
          setAlertDetails({
            title: 'Save Successful',
            message: `The program ${selectedProgram.name} has been updated successfully.`,
            icon: <FaSave className="h-6 w-6 text-green-600" aria-hidden="true" />,
            onConfirm: () => setAlertDetails(null),
            onCancel: () => setAlertDetails(null),
          });
        },
        onCancel: () => setAlertDetails(null),
      });
    }
  };  

  const handleSearch = (text: string) => {
    setSearchText(text);
  };

  const handleFilter = (startDate: string, endDate: string, status: 'all' | 'running' | 'stopped') => {
    setFilterDates({ startDate, endDate });
    setFilterStatus(status);
  };

  const filteredPrograms = programs.filter(program => {
    const matchesTab = activeTab === 'latest' ? program.status !== 'expired' : program.status === 'expired';
    const matchesSearch = program.name?.toLowerCase().includes(searchText.toLowerCase()) ?? false;
    const matchesFilterDates = (!filterDates.startDate || new Date(program.startDate!) >= new Date(filterDates.startDate)) &&
                              (!filterDates.endDate || new Date(program.endDate!) <= new Date(filterDates.endDate));
    const matchesFilterStatus = filterStatus === 'all' || program.status === filterStatus;
    return matchesTab && matchesSearch && matchesFilterDates && matchesFilterStatus;
  });    

  // Modify the renderOverlay function in ProgramList component
  const renderOverlay = (program: Program) => {
    if (program.status === 'expired') {
      return (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center text-white">
          <div className="text-center p-2 bg-red-500 rounded">
            <p>Expired: {formatDate(program.endDate)}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-[850px] mr-4 py-10">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold" 
          style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}>
          Event List
        </h2>
      </div>
      <div className="flex mb-2">
      <button
          className="px-4 py-2 rounded-t-xl"
          onClick={() => setActiveTab('latest')}
          style={{
            fontFamily: theme.fontFamily,
            color: activeTab === 'latest' ? theme.text2Color : theme.textColor,
            backgroundColor: activeTab === 'latest' ? theme.primaryColor : '#E5E7EB', // bg-gray-200 color code
          }}
        >
          Latest Events
        </button>
        <button
          className="px-4 py-2 rounded-t-xl"
          onClick={() => setActiveTab('expired')}
          style={{
            fontFamily: theme.fontFamily,
            color: activeTab === 'expired' ? theme.text2Color : theme.textColor,
            backgroundColor: activeTab === 'expired' ? theme.primaryColor : '#E5E7EB', // bg-gray-200 color code
          }}
        >
          Expired Events
        </button>
        <button
          className="px-4 py-2 text-gray-700"
          onClick={fetchPrograms}
          style={{ fontFamily: theme.fontFamily }}
        >
          <RiRefreshFill className="inline-block mr-4 w-8 h-8" 
            style={{ color:theme.primaryColor }} />
        </button>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {alertDetails && (
        <ProgramListAlert
          title={alertDetails.title}
          message={alertDetails.message}
          icon={alertDetails.icon}
          onConfirm={alertDetails.onConfirm}
          onCancel={alertDetails.onCancel}
        />
      )}
       {filteredPrograms.length === 0 ? (
        <div className="border-2 border-dashed border-gray-300 p-8 text-center font-bold"
          style={{ color: theme.primaryColor,fontFamily:theme.fontFamily }}>
          {activeTab === 'latest' ? 'No events listed' : 'No expired events listed'}
        </div>
      ) : (
        <div className="p-4 bg-white rounded-t shadow-lg border-gray-300 shadow-ml h-[935px] overflow-y-auto" 
          style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
          <ul>
            {filteredPrograms.map((program) => (
              <li key={program.id} className="mb-4 p-4 bg-gray-100 rounded flex justify-between items-center relative">
                <div className="flex items-center space-x-4">
                  {program.image && <img src={`${apiUrl}/uploads/forEventTicket/${program.image}`} alt={program.name} className="object-cover rounded" 
                    style={{ width: '250px', height: '250px' }} />}
                  <div>
                    <h3 className="font-semibold" 
                      style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
                      {program.name}
                      <span className={`ml-2 px-2 py-1 rounded ${program.status === 'running' ? 'bg-green-500' : program.status === 'stopped' ? 'bg-red-500' : 'bg-gray-500'} text-white`}>
                        {program.status.charAt(0).toUpperCase() + program.status.slice(1)}
                      </span>
                    </h3>
                    <div className="flex space-x-2 mt-2">
                      {program.memberType && Array.isArray(program.memberType) && program.memberType.map((type, index) => {
                        const shortForm = convertToShortForm(type);
                        const tagColor = getTagColor(shortForm);
                        return (
                          <span key={index} className={`flex items-center px-2 py-1 rounded ${tagColor} bg-opacity-20`}>
                            <FaTag className={`mr-1 ${tagColor}`} />
                            {shortForm}
                          </span>
                        );
                      })}
                    </div>
                    <div className="flex flex-col space-y-1">
                      <p>
                        <span className="font-bold">Event Date:</span> {formatDate(program.startDate)} on {program.time ? formatTimeTo12Hour(program.time) : 'N/A'}
                      </p>
                      <p>
                        <span className="font-bold">Amount:</span> {program.currency === 'FREE' ? 'Free of charge' : `${formatAmount(program.amount)} ${program.currency}`}
                      </p>
                      <p className="font-bold">Setting:</p>
                      <ul className="list-disc ml-5 text-sm">
                        <li>{program.slot === -1 || program.initialSlot === -1 ? 'Unlimited Slots' : `${program.slot} out of ${program.initialSlot} Slots`}</li>
                        <li>Redeem with {program.redeemNumber === 0 ? 'Unlimited' : program.redeemNumber} Count</li>
                        <li>{program.broker}, {program.lotSize} lot Size, {formatAmount(program.deposit)} USD</li>
                      </ul>
                      <p className="mt-2 font-semibold">Created Date: {formatDate(program.createdAt)}</p>
                    </div>
                  </div>
                </div>
                {renderOverlay(program)}
                {program.status !== 'expired' && (
                  <div className="absolute top-4 right-4 flex space-x-2">
                    <FaEdit className="cursor-pointer text-2xl" style={{ color: theme.primaryColor }} onClick={() => handleProgramClick(program)} />
                    <FaTrash className="cursor-pointer text-2xl" style={{ color: theme.primaryColor }} onClick={() => handleDelete(program.id)} />
                  </div>
                )}
                <div className="absolute bottom-4 right-4 flex space-x-2">
                  {program.status === 'expired' ? (
                    <>
                      <button onClick={() => handleRevoke(program.id)} className="flex items-center space-x-1 px-2 py-1 bg-green-500 text-white rounded">
                        <FaUndo />
                        <span>Revoke</span>
                      </button>
                      <button onClick={() => handleDelete(program.id)} className="flex items-center space-x-1 px-2 py-1 bg-red-500 text-white rounded">
                        <FaTrash />
                        <span>Remove</span>
                      </button>
                    </>
                  ) : (
                    <>
                      <button onClick={() => handleApply(program.id)} className="flex items-center space-x-1 px-2 py-1 text-green-700 rounded">
                        <FaCirclePlay />
                        <span>Start</span>
                      </button>
                      <button onClick={() => handleCancel(program.id)} className="flex items-center space-x-1 px-2 py-1 text-red-700 rounded">
                        <FaCirclePause />
                        <span>Stopped</span>
                      </button>
                      <button onClick={() => handleExpire(program.id)} className="flex items-center space-x-1 px-2 py-1 text-yellow-700 rounded">
                        <FaCalendarMinus />
                        <span>Expire</span>
                      </button>
                    </>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {selectedProgram && (
        <ProgramDetailModal
          program={selectedProgram}
          fieldValues={fieldValues}
          isEditing={isEditing}
          onClose={() => setSelectedProgram(null)}
          onSave={handleSave}
          onFieldChange={handleFieldChange}
          apiUrl={apiUrl}
        />
      )}
      <div className="p-4 bg-white rounded-md shadow-md my-4  z-10">
        <div className="flex items-center space-x-4">
          <SearchFilter onSearch={handleSearch} onFilter={handleFilter} />
        </div>
      </div>
      <ProgramStats />
    </div>
  );
};

export default ProgramList;

// // udpate copde --> 18 Aug 2024
// // src/components/admin/EventSetting/ProgramList.tsx

// import React, { useState } from 'react';

// // import context file
// import { useTheme } from '../../../context/ThemeContext';
// import { useEnv } from '../../../context/EnvContext';

// // import hook files
// import useProgram, { Program, convertToShortForm, getTagColor, formatTimeTo12Hour, formatAmount } from '../../../hooks/AdminDashboard/useProgram';

// // import react-icons
// import { FaCirclePlay, FaCirclePause, FaCalendarMinus } from "react-icons/fa6";
// import { RiRefreshFill } from "react-icons/ri";
// import { 
//   FaSave, FaEdit, FaUndo, FaTrash, 
//   FaPlayCircle, FaPauseCircle, FaExclamationTriangle,
//   FaTag 
// } from 'react-icons/fa';

// // import file source
// import SearchFilter from '../EventSetting/ProgramFilter';
// import ProgramDetailModal from './ProgramDetailModal';
// import ProgramListAlert from './ProgramListAlert';
// import ProgramStats from './ProgramStats'; // Import ProgramStats

// const ProgramList: React.FC = () => {
//   const { apiUrl } = useEnv();
//   const { theme } = useTheme();
//   const { programs, setPrograms, loading, error, updateProgramStatus, deleteProgram, updateProgramDetails, fetchPrograms } = useProgram();
//   const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
//   const [activeTab, setActiveTab] = useState<'latest' | 'expired'>('latest');
//   const [isEditing, setIsEditing] = useState(false);
//   const [fieldValues, setFieldValues] = useState<Partial<Program>>({});
//   const [searchText, setSearchText] = useState('');
//   const [filterDates, setFilterDates] = useState<{ startDate: string; endDate: string }>({ startDate: '', endDate: '' });
//   const [filterStatus, setFilterStatus] = useState<'all' | 'running' | 'stopped'>('all');
//   const [alertDetails, setAlertDetails] = useState<{
//     title: string;
//     message: string;
//     icon: React.ReactNode;
//     onConfirm: () => void;
//     onCancel: () => void;
//   } | null>(null);

//   const handleApply = (programId: number) => {
//     setAlertDetails({
//       title: 'Start Program',
//       message: 'Are you sure you want to start this program?',
//       icon: <FaPlayCircle className="h-6 w-6 text-green-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         await updateProgramStatus(programId, 'running');
//         setAlertDetails(null);
//         fetchPrograms();
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleCancel = (programId: number) => {
//     setAlertDetails({
//       title: 'Stop Program',
//       message: 'Are you sure you want to stop this program?',
//       icon: <FaPauseCircle className="h-6 w-6 text-red-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         await updateProgramStatus(programId, 'stopped');
//         setAlertDetails(null);
//         fetchPrograms();
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleDelete = (programId: number) => {
//     setAlertDetails({
//       title: 'Delete Program',
//       message: 'Are you sure you want to delete this program? This action cannot be undone.',
//       icon: <FaExclamationTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         await deleteProgram(programId);
//         setAlertDetails(null);
//         fetchPrograms();
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleRevoke = (programId: number) => {
//     setAlertDetails({
//       title: 'Revoke Program',
//       message: 'Are you sure you want to revoke this program?',
//       icon: <FaUndo className="h-6 w-6 text-yellow-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         await updateProgramStatus(programId, 'stopped');
//         setAlertDetails(null);
//         fetchPrograms();
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleExpire = (programId: number) => {
//     setAlertDetails({
//       title: 'Expire Program',
//       message: 'Are you sure you want to mark this program as expired?',
//       icon: <FaCalendarMinus className="h-6 w-6 text-yellow-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         try {
//           const updatedProgram = await updateProgramStatus(programId, 'expired');
//           if (updatedProgram) {
//             setPrograms((prevPrograms) =>
//               prevPrograms.map((program) =>
//                 program.id === programId ? { ...program, status: 'expired' } : program
//               )
//             );
//             // Switch to the expired tab
//             setActiveTab('expired');
//           }
//         } catch (err) {
//           console.error('Error expiring program:', err);
//         }
//         setAlertDetails(null);
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };  

//   const formatDate = (date: string | null | undefined) => {
//     if (!date) return '';
//     const d = new Date(date);
//     return d.toISOString().split('T')[0];
//   };

//   const handleProgramClick = (program: Program) => {
//     setSelectedProgram(program);
//     setIsEditing(false);
//     setFieldValues({});
//   };

//   const handleFieldChange = (field: keyof Program, value: any) => {
//     setFieldValues((prev) => ({ ...prev, [field]: value }));
//   };

//   const handleSave = async () => {
//     if (selectedProgram) {
//       setAlertDetails({
//         title: 'Confirm Save',
//         message: `Are you sure you want to save the changes to ${selectedProgram.name}?`,
//         icon: <FaSave className="h-6 w-6 text-green-600" aria-hidden="true" />,
//         onConfirm: async () => {
//           await updateProgramDetails(selectedProgram.id, fieldValues);
//           setSelectedProgram(null);
//           fetchPrograms();
//           setAlertDetails({
//             title: 'Save Successful',
//             message: `The program ${selectedProgram.name} has been updated successfully.`,
//             icon: <FaSave className="h-6 w-6 text-green-600" aria-hidden="true" />,
//             onConfirm: () => setAlertDetails(null),
//             onCancel: () => setAlertDetails(null),
//           });
//         },
//         onCancel: () => setAlertDetails(null),
//       });
//     }
//   };  

//   const handleSearch = (text: string) => {
//     setSearchText(text);
//   };

//   const handleFilter = (startDate: string, endDate: string, status: 'all' | 'running' | 'stopped') => {
//     setFilterDates({ startDate, endDate });
//     setFilterStatus(status);
//   };

//   const filteredPrograms = programs.filter(program => {
//     const matchesTab = activeTab === 'latest' ? program.status !== 'expired' : program.status === 'expired';
//     const matchesSearch = program.name?.toLowerCase().includes(searchText.toLowerCase()) ?? false;
//     const matchesFilterDates = (!filterDates.startDate || new Date(program.startDate!) >= new Date(filterDates.startDate)) &&
//                               (!filterDates.endDate || new Date(program.endDate!) <= new Date(filterDates.endDate));
//     const matchesFilterStatus = filterStatus === 'all' || program.status === filterStatus;
//     return matchesTab && matchesSearch && matchesFilterDates && matchesFilterStatus;
//   });    

//   // Modify the renderOverlay function in ProgramList component
//   const renderOverlay = (program: Program) => {
//     if (program.status === 'expired') {
//       return (
//         <div className="absolute inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center text-white">
//           <div className="text-center p-2 bg-red-500 rounded">
//             <p>Expired: {formatDate(program.endDate)}</p>
//           </div>
//         </div>
//       );
//     }
//     return null;
//   };

//   return (
//     <div className="w-[850px] mr-4 py-10">
//       <div className="flex justify-between items-center mb-4">
//         <h2 className="text-2xl font-bold" 
//           style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}>
//           Event List
//         </h2>
//       </div>
//       <div className="flex mb-2">
//       <button
//           className="px-4 py-2 rounded-t-xl"
//           onClick={() => setActiveTab('latest')}
//           style={{
//             fontFamily: theme.fontFamily,
//             color: activeTab === 'latest' ? theme.text2Color : theme.textColor,
//             backgroundColor: activeTab === 'latest' ? theme.primaryColor : '#E5E7EB', // bg-gray-200 color code
//           }}
//         >
//           Latest Events
//         </button>
//         <button
//           className="px-4 py-2 rounded-t-xl"
//           onClick={() => setActiveTab('expired')}
//           style={{
//             fontFamily: theme.fontFamily,
//             color: activeTab === 'expired' ? theme.text2Color : theme.textColor,
//             backgroundColor: activeTab === 'expired' ? theme.primaryColor : '#E5E7EB', // bg-gray-200 color code
//           }}
//         >
//           Expired Events
//         </button>
//         <button
//           className="px-4 py-2 text-gray-700"
//           onClick={fetchPrograms}
//           style={{ fontFamily: theme.fontFamily }}
//         >
//           <RiRefreshFill className="inline-block mr-4 w-8 h-8" 
//             style={{ color:theme.primaryColor }} />
//         </button>
//       </div>
//       {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}
//       {alertDetails && (
//         <ProgramListAlert
//           title={alertDetails.title}
//           message={alertDetails.message}
//           icon={alertDetails.icon}
//           onConfirm={alertDetails.onConfirm}
//           onCancel={alertDetails.onCancel}
//         />
//       )}
//        {filteredPrograms.length === 0 ? (
//         <div className="border-2 border-dashed border-gray-300 p-8 text-center font-bold"
//           style={{ color: theme.primaryColor,fontFamily:theme.fontFamily }}>
//           {activeTab === 'latest' ? 'No events listed' : 'No expired events listed'}
//         </div>
//       ) : (
//         <div className="p-4 bg-white rounded-t shadow-lg border-gray-300 shadow-ml h-[935px] overflow-y-auto" 
//           style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//           <ul>
//             {filteredPrograms.map((program) => (
//               <li key={program.id} className="mb-4 p-4 bg-gray-100 rounded flex justify-between items-center relative">
//                 <div className="flex items-center space-x-4">
//                   {program.image && <img src={`${apiUrl}/uploads/forEventTicket/${program.image}`} alt={program.name} className="object-cover rounded" 
//                     style={{ width: '250px', height: '250px' }} />}
//                   <div>
//                     <h3 className="font-semibold" 
//                       style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//                       {program.name}
//                       <span className={`ml-2 px-2 py-1 rounded ${program.status === 'running' ? 'bg-green-500' : program.status === 'stopped' ? 'bg-red-500' : 'bg-gray-500'} text-white`}>
//                         {program.status.charAt(0).toUpperCase() + program.status.slice(1)}
//                       </span>
//                     </h3>
//                     <div className="flex space-x-2 mt-2">
//                       {program.memberType && Array.isArray(program.memberType) && program.memberType.map((type, index) => {
//                         const shortForm = convertToShortForm(type);
//                         const tagColor = getTagColor(shortForm);
//                         return (
//                           <span key={index} className={`flex items-center px-2 py-1 rounded ${tagColor} bg-opacity-20`}>
//                             <FaTag className={`mr-1 ${tagColor}`} />
//                             {shortForm}
//                           </span>
//                         );
//                       })}
//                     </div>
//                     <div className="flex flex-col space-y-1">
//                       <p>
//                         <span className="font-bold">Event Date:</span> {formatDate(program.startDate)} on {program.time ? formatTimeTo12Hour(program.time) : 'N/A'}
//                       </p>
//                       <p>
//                         <span className="font-bold">Amount:</span> {program.currency === 'FREE' ? 'Free of charge' : `${formatAmount(program.amount)} ${program.currency}`}
//                       </p>
//                       <p className="font-bold">Setting:</p>
//                       <ul className="list-disc ml-5 text-sm">
//                         <li>{program.slot === -1 ? 'Unlimited' : program.slot} Slot</li>
//                         <li>Redeem with {program.redeemNumber === 0 ? 'Unlimited' : program.redeemNumber} Count</li>
//                         <li>{program.broker}, {program.lotSize} lot Size, {formatAmount(program.deposit)} USD</li>
//                       </ul>
//                       <p className="mt-2 font-semibold">Created Date: {formatDate(program.createdAt)}</p>
//                     </div>
//                   </div>
//                 </div>
//                 {renderOverlay(program)}
//                 {program.status !== 'expired' && (
//                   <div className="absolute top-4 right-4 flex space-x-2">
//                     <FaEdit className="cursor-pointer text-2xl" style={{ color: theme.primaryColor }} onClick={() => handleProgramClick(program)} />
//                     <FaTrash className="cursor-pointer text-2xl" style={{ color: theme.primaryColor }} onClick={() => handleDelete(program.id)} />
//                   </div>
//                 )}
//                 <div className="absolute bottom-4 right-4 flex space-x-2">
//                   {program.status === 'expired' ? (
//                     <>
//                       <button onClick={() => handleRevoke(program.id)} className="flex items-center space-x-1 px-2 py-1 bg-green-500 text-white rounded">
//                         <FaUndo />
//                         <span>Revoke</span>
//                       </button>
//                       <button onClick={() => handleDelete(program.id)} className="flex items-center space-x-1 px-2 py-1 bg-red-500 text-white rounded">
//                         <FaTrash />
//                         <span>Remove</span>
//                       </button>
//                     </>
//                   ) : (
//                     <>
//                       <button onClick={() => handleApply(program.id)} className="flex items-center space-x-1 px-2 py-1 text-green-700 rounded">
//                         <FaCirclePlay />
//                         <span>Start</span>
//                       </button>
//                       <button onClick={() => handleCancel(program.id)} className="flex items-center space-x-1 px-2 py-1 text-red-700 rounded">
//                         <FaCirclePause />
//                         <span>Stopped</span>
//                       </button>
//                       <button onClick={() => handleExpire(program.id)} className="flex items-center space-x-1 px-2 py-1 text-yellow-700 rounded">
//                         <FaCalendarMinus />
//                         <span>Expire</span>
//                       </button>
//                     </>
//                   )}
//                 </div>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//       {selectedProgram && (
//         <ProgramDetailModal
//           program={selectedProgram}
//           fieldValues={fieldValues}
//           isEditing={isEditing}
//           onClose={() => setSelectedProgram(null)}
//           onSave={handleSave}
//           onFieldChange={handleFieldChange}
//           apiUrl={apiUrl}
//         />
//       )}
//       <div className="p-4 bg-white rounded-md shadow-md my-4  z-10">
//         <div className="flex items-center space-x-4">
//           <SearchFilter onSearch={handleSearch} onFilter={handleFilter} />
//         </div>
//       </div>
//       <ProgramStats />
//     </div>
//   );
// };

// export default ProgramList;


// // udpate code --> 03 july 2024
// // src/components/admin/EventSetting/ProgramList.tsx

// import React, { useState } from 'react';

// // import context file
// import { useTheme } from '../../../context/ThemeContext';
// import { useEnv } from '../../../context/EnvContext';

// // import hook files
// import useProgram, { Program, convertToShortForm, getTagColor } from '../../../hooks/AdminDashboard/useProgram';

// // import react-icons
// import { FaCirclePlay, FaCirclePause, FaCalendarMinus } from "react-icons/fa6";
// import { RiRefreshFill } from "react-icons/ri";
// import { 
//   FaSave, FaEdit, FaUndo, FaTrash, 
//   FaPlayCircle, FaPauseCircle, FaExclamationTriangle,
//   FaTag 
// } from 'react-icons/fa';

// // import file source
// import SearchFilter from '../EventSetting/ProgramFilter';
// import ProgramDetailModal from './ProgramDetailModal';
// import ProgramListAlert from './ProgramListAlert';

// const ProgramList: React.FC = () => {
//   const { apiUrl } = useEnv();
//   const { theme } = useTheme();
//   const { programs, setPrograms, loading, error, updateProgramStatus, deleteProgram, updateProgramDetails, fetchPrograms } = useProgram();
//   const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
//   const [activeTab, setActiveTab] = useState<'latest' | 'expired'>('latest');
//   const [isEditing, setIsEditing] = useState(false);
//   const [fieldValues, setFieldValues] = useState<Partial<Program>>({});
//   const [searchText, setSearchText] = useState('');
//   const [filterDates, setFilterDates] = useState<{ startDate: string; endDate: string }>({ startDate: '', endDate: '' });
//   const [filterStatus, setFilterStatus] = useState<'all' | 'running' | 'stopped'>('all');
//   const [alertDetails, setAlertDetails] = useState<{
//     title: string;
//     message: string;
//     icon: React.ReactNode;
//     onConfirm: () => void;
//     onCancel: () => void;
//   } | null>(null);

//   const handleApply = (programId: number) => {
//     setAlertDetails({
//       title: 'Start Program',
//       message: 'Are you sure you want to start this program?',
//       icon: <FaPlayCircle className="h-6 w-6 text-green-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         await updateProgramStatus(programId, 'running');
//         setAlertDetails(null);
//         fetchPrograms();
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleCancel = (programId: number) => {
//     setAlertDetails({
//       title: 'Stop Program',
//       message: 'Are you sure you want to stop this program?',
//       icon: <FaPauseCircle className="h-6 w-6 text-red-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         await updateProgramStatus(programId, 'stopped');
//         setAlertDetails(null);
//         fetchPrograms();
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleDelete = (programId: number) => {
//     setAlertDetails({
//       title: 'Delete Program',
//       message: 'Are you sure you want to delete this program? This action cannot be undone.',
//       icon: <FaExclamationTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         await deleteProgram(programId);
//         setAlertDetails(null);
//         fetchPrograms();
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleRevoke = (programId: number) => {
//     setAlertDetails({
//       title: 'Revoke Program',
//       message: 'Are you sure you want to revoke this program?',
//       icon: <FaUndo className="h-6 w-6 text-yellow-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         await updateProgramStatus(programId, 'stopped');
//         setAlertDetails(null);
//         fetchPrograms();
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };

//   const handleExpire = (programId: number) => {
//     setAlertDetails({
//       title: 'Expire Program',
//       message: 'Are you sure you want to mark this program as expired?',
//       icon: <FaCalendarMinus className="h-6 w-6 text-yellow-600" aria-hidden="true" />,
//       onConfirm: async () => {
//         try {
//           const updatedProgram = await updateProgramStatus(programId, 'expired');
//           if (updatedProgram) {
//             setPrograms((prevPrograms) =>
//               prevPrograms.map((program) =>
//                 program.id === programId ? { ...program, status: 'expired' } : program
//               )
//             );
//             // Switch to the expired tab
//             setActiveTab('expired');
//           }
//         } catch (err) {
//           console.error('Error expiring program:', err);
//         }
//         setAlertDetails(null);
//       },
//       onCancel: () => setAlertDetails(null),
//     });
//   };  

//   const formatDate = (date: string | null | undefined) => {
//     if (!date) return '';
//     const d = new Date(date);
//     return d.toISOString().split('T')[0];
//   };

//   const handleProgramClick = (program: Program) => {
//     setSelectedProgram(program);
//     setIsEditing(false);
//     setFieldValues({});
//   };

//   const handleFieldChange = (field: keyof Program, value: any) => {
//     setFieldValues((prev) => ({ ...prev, [field]: value }));
//   };

//   const handleSave = async () => {
//     if (selectedProgram) {
//       setAlertDetails({
//         title: 'Confirm Save',
//         message: `Are you sure you want to save the changes to ${selectedProgram.name}?`,
//         icon: <FaSave className="h-6 w-6 text-green-600" aria-hidden="true" />,
//         onConfirm: async () => {
//           await updateProgramDetails(selectedProgram.id, fieldValues);
//           setSelectedProgram(null);
//           fetchPrograms();
//           setAlertDetails({
//             title: 'Save Successful',
//             message: `The program ${selectedProgram.name} has been updated successfully.`,
//             icon: <FaSave className="h-6 w-6 text-green-600" aria-hidden="true" />,
//             onConfirm: () => setAlertDetails(null),
//             onCancel: () => setAlertDetails(null),
//           });
//         },
//         onCancel: () => setAlertDetails(null),
//       });
//     }
//   };  

//   const handleSearch = (text: string) => {
//     setSearchText(text);
//   };

//   const handleFilter = (startDate: string, endDate: string, status: 'all' | 'running' | 'stopped') => {
//     setFilterDates({ startDate, endDate });
//     setFilterStatus(status);
//   };  

//   const formatTimeTo12Hour = (time: string) => {
//     const [hours, minutes] = time.split(':').map(Number);
//     const suffix = hours >= 12 ? 'PM' : 'AM';
//     const adjustedHours = hours % 12 || 12;
//     return `${adjustedHours}:${minutes < 10 ? '0' : ''}${minutes} ${suffix}`;
//   };

//   const filteredPrograms = programs.filter(program => {
//     const matchesTab = activeTab === 'latest' ? program.status !== 'expired' : program.status === 'expired';
//     const matchesSearch = program.name?.toLowerCase().includes(searchText.toLowerCase()) ?? false;
//     const matchesFilterDates = (!filterDates.startDate || new Date(program.startDate!) >= new Date(filterDates.startDate)) &&
//                               (!filterDates.endDate || new Date(program.endDate!) <= new Date(filterDates.endDate));
//     const matchesFilterStatus = filterStatus === 'all' || program.status === filterStatus;
//     return matchesTab && matchesSearch && matchesFilterDates && matchesFilterStatus;
//   });    

//   // Modify the renderOverlay function in ProgramList component
//   const renderOverlay = (program: Program) => {
//     if (program.status === 'expired') {
//       return (
//         <div className="absolute inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center text-white">
//           <div className="text-center p-2 bg-red-500 rounded">
//             <p>Expired: {formatDate(program.endDate)}</p>
//           </div>
//         </div>
//       );
//     }
//     return null;
//   };

//   return (
//     <div className="w-[850px] mr-4 py-10">
//       <div className="flex justify-between items-center mb-4">
//         <h2 className="text-2xl font-bold" 
//           style={{ fontFamily: theme.fontFamily, color: theme.textColor }}>
//           Program List
//         </h2>
//       </div>
//       <div className="flex mb-2">
//       <button
//           className="px-4 py-2 rounded-t-xl"
//           onClick={() => setActiveTab('latest')}
//           style={{
//             fontFamily: theme.fontFamily,
//             color: activeTab === 'latest' ? theme.text2Color : theme.textColor,
//             backgroundColor: activeTab === 'latest' ? theme.primaryColor : '#E5E7EB', // bg-gray-200 color code
//           }}
//         >
//           Latest Events
//         </button>
//         <button
//           className="px-4 py-2 rounded-t-xl"
//           onClick={() => setActiveTab('expired')}
//           style={{
//             fontFamily: theme.fontFamily,
//             color: activeTab === 'expired' ? theme.text2Color : theme.textColor,
//             backgroundColor: activeTab === 'expired' ? theme.primaryColor : '#E5E7EB', // bg-gray-200 color code
//           }}
//         >
//           Expired Events
//         </button>
//         <button
//           className="px-4 py-2 text-gray-700"
//           onClick={fetchPrograms}
//           style={{ fontFamily: theme.fontFamily }}
//         >
//           <RiRefreshFill className="inline-block mr-4 w-8 h-8" 
//             style={{ color:theme.primaryColor }} />
//         </button>
//       </div>
//       {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}
//       {alertDetails && (
//         <ProgramListAlert
//           title={alertDetails.title}
//           message={alertDetails.message}
//           icon={alertDetails.icon}
//           onConfirm={alertDetails.onConfirm}
//           onCancel={alertDetails.onCancel}
//         />
//       )}
//        {filteredPrograms.length === 0 ? (
//         <div className="border-2 border-dashed border-gray-300 p-8 text-center font-bold"
//           style={{ color: theme.primaryColor,fontFamily:theme.fontFamily }}>
//           {activeTab === 'latest' ? 'No events listed' : 'No expired events listed'}
//         </div>
//       ) : (
//         <div className="p-4 bg-white rounded-t shadow-lg border-gray-300 shadow-ml h-[935px] overflow-y-auto" 
//           style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//           <ul>
//             {filteredPrograms.map((program) => (
//               <li key={program.id} className="mb-4 p-4 bg-gray-100 rounded flex justify-between items-center relative">
//                 <div className="flex items-center space-x-4">
//                   {program.image && <img src={`${apiUrl}/uploads/forEventTicket/${program.image}`} alt={program.name} className="object-cover rounded" 
//                     style={{ width: '150px', height: '150px' }} />}
//                   <div>
//                     <h3 className="font-semibold" 
//                       style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//                       {program.name}
//                       <span className={`ml-2 px-2 py-1 rounded ${program.status === 'running' ? 'bg-green-500' : program.status === 'stopped' ? 'bg-red-500' : 'bg-gray-500'} text-white`}>
//                         {program.status.charAt(0).toUpperCase() + program.status.slice(1)}
//                       </span>
//                     </h3>
//                     <div className="flex space-x-2 mt-2">
//                       {program.memberType && Array.isArray(program.memberType) && program.memberType.map((type, index) => {
//                         const shortForm = convertToShortForm(type);
//                         const tagColor = getTagColor(shortForm);
//                         return (
//                           <span key={index} className={`flex items-center px-2 py-1 rounded ${tagColor} bg-opacity-20`}>
//                             <FaTag className={`mr-1 ${tagColor}`} />
//                             {shortForm}
//                           </span>
//                         );
//                       })}
//                     </div>
//                     <p>Event Date: {formatDate(program.startDate)} on {program.time ? formatTimeTo12Hour(program.time) : 'N/A'}</p>
//                     <p>
//                       Amount: {program.currency === 'FREE' 
//                         ? 'Free of charge' 
//                         : `${program.amount} ${program.currency}`}
//                     </p>
//                     <p>Slot: {program.slot === -1 ? 'Unlimited' : program.slot}</p>
//                     <p>Redeem Count: {program.redeemNumber === 0 ? 'Unlimited' : program.redeemNumber}</p>
//                     <p className="mt-2 font-semibold">Created Date: {formatDate(program.createdAt)}</p>
//                   </div>
//                 </div>
//                 {renderOverlay(program)}
//                 {program.status !== 'expired' && (
//                   <div className="absolute top-4 right-4 flex space-x-2">
//                     <FaEdit className="cursor-pointer text-2xl" style={{ color: theme.primaryColor }} onClick={() => handleProgramClick(program)} />
//                     <FaTrash className="cursor-pointer text-2xl" style={{ color: theme.primaryColor }} onClick={() => handleDelete(program.id)} />
//                   </div>
//                 )}
//                 <div className="absolute bottom-4 right-4 flex space-x-2">
//                   {program.status === 'expired' ? (
//                     <>
//                       <button onClick={() => handleRevoke(program.id)} className="flex items-center space-x-1 px-2 py-1 bg-green-500 text-white rounded">
//                         <FaUndo />
//                         <span>Revoke</span>
//                       </button>
//                       <button onClick={() => handleDelete(program.id)} className="flex items-center space-x-1 px-2 py-1 bg-red-500 text-white rounded">
//                         <FaTrash />
//                         <span>Remove</span>
//                       </button>
//                     </>
//                   ) : (
//                     <>
//                       <button onClick={() => handleApply(program.id)} className="flex items-center space-x-1 px-2 py-1 text-green-700 rounded">
//                         <FaCirclePlay />
//                         <span>Start</span>
//                       </button>
//                       <button onClick={() => handleCancel(program.id)} className="flex items-center space-x-1 px-2 py-1 text-red-700 rounded">
//                         <FaCirclePause />
//                         <span>Stopped</span>
//                       </button>
//                       <button onClick={() => handleExpire(program.id)} className="flex items-center space-x-1 px-2 py-1 text-yellow-700 rounded">
//                         <FaCalendarMinus />
//                         <span>Expire</span>
//                       </button>
//                     </>
//                   )}
//                 </div>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//       {selectedProgram && (
//         <ProgramDetailModal
//           program={selectedProgram}
//           fieldValues={fieldValues}
//           isEditing={isEditing}
//           onClose={() => setSelectedProgram(null)}
//           onSave={handleSave}
//           onFieldChange={handleFieldChange}
//           apiUrl={apiUrl}
//         />
//       )}
//       <div className="p-4 bg-white rounded-md shadow-md my-4  z-10">
//         <div className="flex items-center space-x-4">
//           <SearchFilter onSearch={handleSearch} onFilter={handleFilter} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProgramList;


// // update code --> 21 June 2024
// // src/components/admin/EventSetting/ProgramList.tsx

// import React, { useState } from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import { useEnv } from '../../../context/EnvContext';
// import useProgram, { Program } from '../../../hooks/AdminDashboard/useProgram';
// import { FaCirclePlay, FaCirclePause, FaCalendarMinus  } from "react-icons/fa6";
// import { FaEdit, FaUndo, FaTrash } from 'react-icons/fa';
// import SearchFilter from '../EventSetting/ProgramFilter';
// import ProgramDetailModal from './ProgramDetailModal';

// const ProgramList: React.FC = () => {
//   const { apiUrl } = useEnv();
//   const { theme } = useTheme();
//   const { programs, setPrograms, loading, error, updateProgramStatus, deleteProgram, updateProgramDetails, fetchPrograms } = useProgram();
//   const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
//   const [activeTab, setActiveTab] = useState<'latest' | 'expired'>('latest');
//   const [isEditing, setIsEditing] = useState(false);
//   const [fieldValues, setFieldValues] = useState<Partial<Program>>({});
//   const [searchText, setSearchText] = useState('');
//   const [filterDates, setFilterDates] = useState<{ startDate: string; endDate: string }>({ startDate: '', endDate: '' });

//   const handleApply = (programId: number) => {
//     updateProgramStatus(programId, 'running');
//   };

//   const handleCancel = (programId: number) => {
//     updateProgramStatus(programId, 'stopped');
//   };

//   const handleDelete = async (programId: number) => {
//     try {
//       await deleteProgram(programId);
//       setSelectedProgram(null);
//     } catch (err) {
//       console.error('Error deleting program:', err);
//     }
//   };

//   const handleRevoke = (programId: number) => {
//     updateProgramStatus(programId, 'stopped');
//   };

//   const handleExpire = async (programId: number) => {
//     try {
//       const updatedProgram = await updateProgramStatus(programId, 'expired');
//       if (updatedProgram) {
//         setPrograms((prevPrograms) =>
//           prevPrograms.map((program) =>
//             program.id === programId ? { ...program, status: 'expired' } : program
//           )
//         );
//         // Switch to the expired tab
//         setActiveTab('expired');
//       }
//     } catch (err) {
//       console.error('Error expiring program:', err);
//     }
//   };

//   const formatDate = (date: string | null | undefined) => {
//     if (!date) return '';
//     const d = new Date(date);
//     return d.toISOString().split('T')[0];
//   };

//   const handleProgramClick = (program: Program) => {
//     setSelectedProgram(program);
//     setIsEditing(false);
//     setFieldValues({});
//   };

//   const handleFieldChange = (field: keyof Program, value: any) => {
//     setFieldValues((prev) => ({ ...prev, [field]: value }));
//   };

//   const handleSave = async () => {
//     if (selectedProgram) {
//       await updateProgramDetails(selectedProgram.id, fieldValues);
//       setSelectedProgram(null);
//       fetchPrograms();
//     }
//   };

//   const handleSearch = (text: string) => {
//     setSearchText(text);
//   };

//   const handleFilter = (startDate: string, endDate: string) => {
//     setFilterDates({ startDate, endDate });
//   };

//   const filteredPrograms = programs.filter(program => {
//     const matchesTab = activeTab === 'latest' ? program.status !== 'expired' : program.status === 'expired';
//     const matchesSearch = program.name.toLowerCase().includes(searchText.toLowerCase());
//     const matchesFilter = (!filterDates.startDate || new Date(program.startDate!) >= new Date(filterDates.startDate)) &&
//                           (!filterDates.endDate || new Date(program.endDate!) <= new Date(filterDates.endDate));
//     return matchesTab && matchesSearch && matchesFilter;
//   });

//   // Modify the renderOverlay function in ProgramList component
//   const renderOverlay = (program: Program) => {
//     if (program.status === 'expired') {
//       return (
//         <div className="absolute inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center text-white">
//           <div className="text-center p-2 bg-red-500 rounded">
//             <p>Expired: {formatDate(program.endDate)}</p>
//           </div>
//         </div>
//       );
//     }
//     return null;
//   };

//   return (
//     <div className="w-[800px] mr-4 py-10">
//       <div className="flex justify-between items-center mb-4">
//         <h2 className="text-2xl font-bold" style={{ fontFamily: theme.fontFamily, color: theme.textColor }}>
//           Program List
//         </h2>
//       </div>
//       <div className="flex mb-2">
//         <button
//           className={`px-4 py-2 rounded-t ${activeTab === 'latest' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
//           onClick={() => setActiveTab('latest')}
//           style={{ fontFamily: theme.fontFamily, color: activeTab === 'latest' ? 'white' : theme.text2Color }}
//         >
//           Latest Events
//         </button>
//         <button
//           className={`px-4 py-2 rounded-t ${activeTab === 'expired' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
//           onClick={() => setActiveTab('expired')}
//           style={{ fontFamily: theme.fontFamily, color: activeTab === 'expired' ? 'white' : theme.text2Color }}
//         >
//           Expired Events
//         </button>
//       </div>
//       {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}
//       {filteredPrograms.length === 0 ? (
//         <p>No event listed</p>
//       ) : (
//         <div className="p-4 bg-white rounded-t shadow-lg border-gray-300 shadow-ml h-[620px] overflow-y-auto" style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//           <ul>
//             {filteredPrograms.map((program) => (
//               <li key={program.id} className="mb-4 p-4 bg-gray-100 rounded flex justify-between items-center relative">
//                 <div className="flex items-center space-x-4">
//                   {program.image && <img src={`${apiUrl}/uploads/${program.image}`} alt={program.name} className="object-cover rounded" style={{ width: '150px', height: '150px' }} />}
//                   <div>
//                     <h3 className="font-semibold" style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//                       {program.name}
//                       <span className={`ml-2 px-2 py-1 rounded ${program.status === 'running' ? 'bg-green-500' : program.status === 'stopped' ? 'bg-red-500' : 'bg-gray-500'} text-white`}>
//                         {program.status.charAt(0).toUpperCase() + program.status.slice(1)}
//                       </span>
//                     </h3>
//                     <p>Event Date: {formatDate(program.startDate)}</p>
//                     <p>Created Date: {formatDate(program.createdAt)}</p>
//                     <p>Amount: ${program.amount}</p>
//                   </div>
//                 </div>
//                 {renderOverlay(program)}
//                 {program.status !== 'expired' && (
//                   <div className="absolute top-4 right-4 flex space-x-2">
//                     <FaEdit className="cursor-pointer text-2xl" style={{ color: theme.primaryColor }} onClick={() => handleProgramClick(program)} />
//                     <FaTrash className="cursor-pointer text-2xl" style={{ color: theme.primaryColor }} onClick={() => handleDelete(program.id)} />
//                   </div>
//                 )}
//                 <div className="absolute bottom-4 right-4 flex space-x-2">
//                   {program.status === 'expired' ? (
//                     <>
//                       <button onClick={() => handleRevoke(program.id)} className="flex items-center space-x-1 px-2 py-1 bg-green-500 text-white rounded">
//                         <FaUndo />
//                         <span>Revoke</span>
//                       </button>
//                       <button onClick={() => handleDelete(program.id)} className="flex items-center space-x-1 px-2 py-1 bg-red-500 text-white rounded">
//                         <FaTrash />
//                         <span>Remove</span>
//                       </button>
//                     </>
//                   ) : (
//                     <>
//                       <button onClick={() => handleApply(program.id)} className="flex items-center space-x-1 px-2 py-1 bg-green-500 text-white rounded">
//                         <FaCirclePlay />
//                         <span>Start</span>
//                       </button>
//                       <button onClick={() => handleCancel(program.id)} className="flex items-center space-x-1 px-2 py-1 bg-rose-500 text-white rounded">
//                         <FaCirclePause />
//                         <span>Stopped</span>
//                       </button>
//                       <button onClick={() => handleExpire(program.id)} className="flex items-center space-x-1 px-2 py-1 bg-yellow-500 text-white rounded">
//                         <FaCalendarMinus  />
//                         <span>Expire</span>
//                       </button>
//                     </>
//                   )}
//                 </div>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//       {selectedProgram && (
//         <ProgramDetailModal
//           program={selectedProgram}
//           fieldValues={fieldValues}
//           isEditing={isEditing}
//           onClose={() => setSelectedProgram(null)}
//           onSave={handleSave}
//           onFieldChange={handleFieldChange}
//           apiUrl={apiUrl}
//         />
//       )}
//       <div className="p-4 bg-white rounded-md shadow-md mb-4 z-10">
//         <div className="flex items-center space-x-4">
//           <SearchFilter onSearch={handleSearch} onFilter={handleFilter} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProgramList;








// // update code --> 20 june 2024
// // src/components/admin/ProgramList.tsx

// import React from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import useProgram from '../../../hooks/AdminDashboard/useProgram';
// import { FaEdit } from 'react-icons/fa'; // Import modify icon

// const ProgramList: React.FC = () => {
//     const { theme } = useTheme();
//     const { programs, loading, error, updateProgramStatus } = useProgram();
  
//     const handleApply = (programId: number) => {
//       updateProgramStatus(programId, 'running');
//     };
  
//     const handleCancel = (programId: number) => {
//       updateProgramStatus(programId, 'stopped');
//     };
  
//     const formatDate = (date: string | null) => {
//       if (!date) return '';
//       const d = new Date(date);
//       return d.toISOString().split('T')[0]; // Format as yyyy-mm-dd
//     };
  
//     const calculateDays = (start: string | null, end: string | null) => {
//       if (!start || !end) return 0;
//       const startDate = new Date(start);
//       const endDate = new Date(end);
//       const timeDiff = endDate.getTime() - startDate.getTime();
//       const daysDiff = timeDiff / (1000 * 3600 * 24);
//       return daysDiff;
//     };
  
//     return (
//       <div className="w-[800px] mr-4 py-10">
//         <h2 className="text-2xl font-bold mb-4" style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}>
//           Program List
//         </h2>
//         {loading && <p>Loading...</p>}
//         {error && <p>{error}</p>}
//         {programs.length === 0 ? (
//           <p>No event listed</p>
//         ) : (
//           <div className="p-4 bg-white rounded border-2 border-gray-300 shadow-ml" style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//             <ul>
//               {programs.map((program) => (
//                 <li key={program.id} className="mb-4 p-4 bg-gray-100 rounded flex justify-between items-center">
//                   <div>
//                     {program.image && <img src={`/uploads/${program.image}`} alt={program.name} className="w-32 h-32 object-cover rounded mb-4" />}
//                     <h3 className="font-semibold" style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//                       {program.name}
//                     </h3>
//                     <p>Event Date: {formatDate(program.startDate)} - {formatDate(program.endDate)}</p>
//                     <p>Event Days: {calculateDays(program.startDate, program.endDate)} days</p>
//                     <p>Location: {program.location}</p>
//                     <p>Amount: ${program.amount}</p>
//                     <p>Points: {program.points}</p>
//                     <p>Description: {program.description}</p>
//                     <p>Redeem Number of Times: {program.redeemNumber === 0 ? 'Unlimited' : program.redeemNumber}</p>
//                     <p>Status: {program.status}</p>
//                   </div>
//                   <div className="flex items-center">
//                     <button className="mr-2 px-6 py-2 rounded font-medium" onClick={() => handleApply(program.id)} style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }}>
//                       Release
//                     </button>
//                     <button className="mr-2 px-4 py-2 bg-rose-500 rounded font-medium" onClick={() => handleCancel(program.id)} style={{ color: theme.text2Color, fontFamily: theme.fontFamily }}>
//                       Stopped
//                     </button>
//                     <FaEdit className="cursor-pointer text-2xl" style={{ color: theme.primaryColor }} />
//                   </div>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         )}
//       </div>
//     );
//   };
  
//   export default ProgramList;

// // update code --> 19 june 2024  
// // src/components/admin/ProgramList.tsx

// import React from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import useProgram from '../../../hooks/AdminDashboard/useProgram';

// const ProgramList: React.FC = () => {
//   const { theme } = useTheme();
//   const { programs, loading, error, updateProgramStatus } = useProgram();

//   const handleApply = (programId: number) => {
//     updateProgramStatus(programId, 'running');
//   };

//   const handleCancel = (programId: number) => {
//     updateProgramStatus(programId, 'stopped');
//   };

//   return (
//     <div className="w-[800px] mr-4 py-10">
//       <h2 className="text-2xl font-bold mb-4" style={{ fontFamily: theme.fontFamily, color: theme.primaryColor }}>
//         Program List
//       </h2>
//       {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}
//       {programs.length === 0 ? (
//         <p>No event listed</p>
//       ) : (
//         <div className="p-4 bg-white rounded border-2 border-gray-300 shadow-ml" style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//           <ul>
//             {programs.map((program) => (
//               <li key={program.id} className="mb-4 p-4 bg-gray-100 rounded">
//                 <div className="flex justify-between items-center">
//                   <div>
//                     <h3 className="font-semibold" style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//                       {program.name}
//                     </h3>
//                     <p>Event Date: {program.startDate} - {program.endDate}</p>
//                     <p>Location: {program.location}</p>
//                     <p>Amount: ${program.amount}</p>
//                     <p>Points: {program.points}</p>
//                     <p>Description: {program.description}</p>
//                     <p>Redeem Number of Times: {program.redeemNumber === 0 ? 'Unlimited' : program.redeemNumber}</p>
//                   </div>
//                   <span className={`px-2 py-1 rounded ${program.status === 'running' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
//                     {program.status === 'running' ? 'Running' : 'Stopped'}
//                   </span>
//                 </div>

//                 {/* Program List Button */}
//                 <div className="flex mt-4">
//                   <button className="mr-2 px-6 py-2 rounded font-medium" onClick={() => handleApply(program.id)} style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }}>
//                     Release
//                   </button>
//                   <button className="mr-2 px-4 py-2 bg-rose-500 rounded font-medium" onClick={() => handleCancel(program.id)} style={{ color: theme.text2Color, fontFamily: theme.fontFamily }}>
//                     Stopped
//                   </button>
//                 </div>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ProgramList;