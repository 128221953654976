// src/components/client/Dashboard/UserInfoEdit.tsx

import React, { ChangeEvent, useState } from 'react';
import { FaPencilAlt, FaSave } from 'react-icons/fa';
import { useTheme } from '../../../context/ThemeContext';
import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
import maleAvatar from '../../../assets/usrDashboard/avatar_male.png';
import femaleAvatar from '../../../assets/usrDashboard/avatar_female.png';
import UserUpdateAlert from './UserInfoAlert';

interface UserInfoEditProps {
  formValues: {
    name: string;
    email: string;
    phone: string;
    address: string;
    avatar: string;
    telegram_id: string;
    discord_id: string;
    gender: string;
  };
  setFormValues: (values: any) => void;
  isEditing: {
    name: boolean;
    phone: boolean;
    address: boolean;
    telegram_id: boolean;
    discord_id: boolean;
  };
  setIsEditing: (values: any) => void;
  handleSave: (field: string) => void;
  handleSubmit: (e: React.FormEvent) => void;
  setIsModalVisible: (visible: boolean) => void;
}

const UserInfoEdit: React.FC<UserInfoEditProps> = ({
  formValues,
  setFormValues,
  isEditing,
  setIsEditing,
  handleSubmit,
  handleSave,
  setIsModalVisible,
}) => {
  const { theme } = useTheme();
  const { updateUser } = useUserDashboard();
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('phone', formValues.phone);
    formData.append('address', formValues.address);
    formData.append('telegram_id', formValues.telegram_id);
    formData.append('discord_id', formValues.discord_id);
    if (formValues.avatar) {
      formData.append('profile_image', formValues.avatar);
    }

    try {
      await updateUser(formData);
      setAlertSuccess(true);
    } catch (error) {
      setAlertSuccess(false);
    } finally {
      setAlertVisible(true);
      setIsModalVisible(false);
    }
  };

  const closeAlert = () => {
    setAlertVisible(false);
  };

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex flex-col items-center justify-center z-50 px-4">
      <div className="bg-white rounded-xl w-full max-w-xl p-6 h-auto flex flex-col xs:h-[600px]">
        <div className="px-4 py-4 sticky top-0 bg-white z-10 sm:px-6">
          <h3 className="text-base font-semibold leading-7"
            style={styles.title}>
            Edit Profile
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-6"
            style={styles.text}>
            Personal details and application
          </p>
        </div>

        <div className="flex-grow overflow-y-auto mt-6 border-t border-gray-100 max-h-[70vh] xs:h-[200px]">
          <dl className="divide-y divide-gray-100">
            {/* Name field */}
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-bold leading-6"
                style={styles.title}>Name</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
                <input
                  type="text"
                  name="name"
                  value={formValues.name}
                  disabled
                  className="mt-1 w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </dd>
            </div>

            {/* Email field */}
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-bold leading-6"
                style={styles.title}>Email</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
                <input
                  type="email"
                  name="email"
                  value={formValues.email}
                  disabled
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </dd>
            </div>

            {/* Phone field */}
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-bold leading-6"
                style={styles.title}>Phone</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
                <input
                  type="text"
                  name="phone"
                  value={formValues.phone}
                  disabled
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </dd>
            </div>

            {/* Address Field */}
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-bold leading-6"
                style={styles.title}>Address</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
                <input
                  type="text"
                  name="address"
                  value={formValues.address}
                  onChange={handleChange}
                  disabled={!isEditing.address}
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <button
                  type="button"
                  onClick={() => setIsEditing({ ...isEditing, address: true })}
                  className={`ml-2 flex items-center text-gray-500 ${isEditing.address && 'hidden'}`}
                >
                  <FaPencilAlt />
                </button>
                <button
                  type="button"
                  onClick={() => handleSave('address')}
                  className={`ml-2 flex items-center text-gray-500 ${!isEditing.address && 'hidden'}`}
                >
                  <FaSave />
                </button>
              </dd>
            </div>

            {/* Telegram id field */}
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-bold leading-6"
                style={styles.title}>Telegram Username</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
                <input
                  type="text"
                  name="telegram_id"
                  value={formValues.telegram_id}
                  onChange={handleChange}
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <button
                  type="button"
                  onClick={() => setIsEditing({ ...isEditing, telegram_id: true })}
                  className={`ml-2 flex items-center text-gray-500 ${isEditing.telegram_id && 'hidden'}`}
                >
                  <FaPencilAlt />
                </button>
                <button
                  type="button"
                  onClick={() => handleSave('telegram_id')}
                  className={`ml-2 flex items-center text-gray-500 ${!isEditing.telegram_id && 'hidden'}`}
                >
                  <FaSave />
                </button>
              </dd>
            </div>

            {/* Discord id Field */}
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-bold leading-6"
                style={styles.title}>Discord Name</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
                <input
                  type="text"
                  name="discord_id"
                  value={formValues.discord_id}
                  onChange={handleChange}
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <button
                  type="button"
                  onClick={() => setIsEditing({ ...isEditing, discord_id: true })}
                  className={`ml-2 flex items-center text-gray-500 ${isEditing.discord_id && 'hidden'}`}
                >
                  <FaPencilAlt />
                </button>
                <button
                  type="button"
                  onClick={() => handleSave('discord_id')}
                  className={`ml-2 flex items-center text-gray-500 ${!isEditing.discord_id && 'hidden'}`}
                >
                  <FaSave />
                </button>
              </dd>
            </div>

            {/* Profile picture image display field */}
            {formValues.avatar ? (
              <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-bold leading-6"
                  style={styles.title}>Profile Avatar</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
                  <img
                    src={maleAvatar}
                    alt="Profile"
                    className="h-25 w-25 p-8 bg-zinc-800 rounded-[50px]"
                  />
                </dd>
              </div>
            ) : (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
                  <img
                    src={formValues.gender === 'male' ? maleAvatar : femaleAvatar}
                    alt="Default Avatar"
                    className="h-25 w-25 rounded-full"
                  />
                </dd>
              </div>
            )}
          </dl>
        </div>

        {/* cancel and save button field*/}
        <div className="flex justify-end space-x-4 mt-4 p-4 sticky bottom-0 bg-white xs:text-sm">
          <button
            type="button"
            onClick={() => setIsModalVisible(false)}
            className="bg-gray-300 py-2 px-4 rounded"
            style={styles.title}>
            Cancel
          </button>
          <button
            type="submit"
            onClick={handleFormSubmit}
            className="py-2 px-6 rounded-md"
            style={styles.button}>
            Save
          </button>
        </div>

        <UserUpdateAlert visible={alertVisible} onClose={closeAlert} success={alertSuccess} />
      </div>
    </div>
  );
};

export default UserInfoEdit;


// // udpate code --> 10 july 2024 (must keep)
// // src/components/client/Dashboard/UserInfoEdit.tsx

// import React, { ChangeEvent, useState } from 'react';
// import { FaPencilAlt, FaSave } from 'react-icons/fa';
// import { useTheme } from '../../../context/ThemeContext';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import maleAvatar from '../../../assets/usrDashboard/avatar_male.png';
// import femaleAvatar from '../../../assets/usrDashboard/avatar_female.png';
// import UserUpdateAlert from './UserInfoAlert';

// interface UserInfoEditProps {
//   formValues: {
//     name: string;
//     email: string;
//     phone: string;
//     address: string;
//     avatar: string;
//     telegram_id: string;
//     discord_id: string;
//     gender: string;
//   };
//   setFormValues: (values: any) => void;
//   isEditing: {
//     name: boolean;
//     phone: boolean;
//     address: boolean;
//     telegram_id: boolean;
//     discord_id: boolean; 
//   };
//   setIsEditing: (values: any) => void;
//   handleSave: (field: string) => void;
//   handleSubmit: (e: React.FormEvent) => void;
//   setIsModalVisible: (visible: boolean) => void;
// }

// const UserInfoEdit: React.FC<UserInfoEditProps> = ({
//   formValues,
//   setFormValues,
//   isEditing,
//   setIsEditing,
//   handleSubmit,
//   handleSave,
//   setIsModalVisible,
// }) => {
//   const { theme } = useTheme();
//   const { updateUser } = useUserDashboard();
//   const [alertVisible, setAlertVisible] = useState(false);
//   const [alertSuccess, setAlertSuccess] = useState(false);

//   const handleProfilePictureChange = (e: ChangeEvent<HTMLInputElement>) => {
//     if (e.target.files && e.target.files[0]) {
//       const file = e.target.files[0];
//       const reader = new FileReader();
//       reader.onload = () => {
//         setFormValues({ ...formValues, avatar: reader.result as string });
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
//     setFormValues({ ...formValues, [e.target.name]: e.target.value });
//   };

//   const handleFormSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('name', formValues.name);
//     formData.append('phone', formValues.phone);
//     formData.append('address', formValues.address);
//     formData.append('telegram_id', formValues.telegram_id);
//     formData.append('discord_id', formValues.discord_id);
//     if (formValues.avatar) {
//       formData.append('profile_image', formValues.avatar);
//     }

//     try {
//       await updateUser(formData);
//       setAlertSuccess(true);
//     } catch (error) {
//       setAlertSuccess(false);
//     } finally {
//       setAlertVisible(true);
//       setIsModalVisible(false);
//     }
//   };

//   const closeAlert = () => {
//     setAlertVisible(false);
//   };

//   return (
//     <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50 px-4">
//       <div className="bg-white rounded-lg shadow-md w-full max-w-xl p-8 ">
//         <div className="px-4 py-4 sm:px-6 sticky top-0 bg-white z-10">
//           <h3 className="text-base font-semibold leading-7" 
//             style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//               Edit Profile
//           </h3>
//           <p className="mt-1 max-w-2xl text-sm leading-6"
//             style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//               Personal details and application.
//           </p>
//         </div>

//         {/* Name field */}
//         <div className="mt-6 border-t border-gray-100 max-h-[70vh] overflow-y-auto">
//           <dl className="divide-y divide-gray-100">
//             <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
//               <dt className="text-sm font-bold leading-6"
//                 style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>Name</dt>
//               <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
//                 <input
//                   type="text"
//                   name="name"
//                   value={formValues.name}
//                   onChange={handleChange}
//                   disabled={!isEditing.name}
//                   className="mt-1 w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
//                 />
//                 <button
//                   type="button"
//                   onClick={() => setIsEditing({ ...isEditing, name: true })}
//                   className={`ml-2 flex items-center text-gray-500 ${isEditing.name && 'hidden'}`}
//                 >
//                   <FaPencilAlt />
//                 </button>
//                 <button
//                   type="button"
//                   onClick={() => handleSave('name')}
//                   className={`ml-2 flex items-center text-gray-500 ${!isEditing.name && 'hidden'}`}
//                 >
//                   <FaSave />
//                 </button>
//               </dd>
//             </div>

//             {/* Email field */}
//             <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
//               <dt className="text-sm font-bold leading-6" 
//                 style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>Email</dt>
//               <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
//                 <input
//                   type="email"
//                   name="email"
//                   value={formValues.email}
//                   disabled
//                   className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
//                 />
//               </dd>
//             </div>

//             {/* Phone field */}
//             <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
//               <dt className="text-sm font-bold leading-6" 
//                 style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>Phone</dt>
//               <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
//                 <input
//                   type="text"
//                   name="phone"
//                   value={formValues.phone}
//                   disabled
//                   className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
//                 />
//               </dd>
//             </div>

//             {/* Address Field */}
//             <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
//               <dt className="text-sm font-bold leading-6" 
//                 style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>Address</dt>
//               <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
//                 <input
//                   type="text"
//                   name="address"
//                   value={formValues.address}
//                   onChange={handleChange}
//                   disabled={!isEditing.address}
//                   className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
//                 />
//                 <button
//                   type="button"
//                   onClick={() => setIsEditing({ ...isEditing, address: true })}
//                   className={`ml-2 flex items-center text-gray-500 ${isEditing.address && 'hidden'}`}
//                 >
//                   <FaPencilAlt />
//                 </button>
//                 <button
//                   type="button"
//                   onClick={() => handleSave('address')}
//                   className={`ml-2 flex items-center text-gray-500 ${!isEditing.address && 'hidden'}`}
//                 >
//                   <FaSave />
//                 </button>
//               </dd>
//             </div>

//             {/* Telegram id field */}
//             <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
//               <dt className="text-sm font-bold leading-6" 
//                 style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>Telegram ID</dt>
//               <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
//                 <input
//                   type="text"
//                   name="telegram_id"
//                   value={formValues.telegram_id}
//                   onChange={handleChange}
//                   className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
//                 />
//                 <button
//                   type="button"
//                   onClick={() => setIsEditing({ ...isEditing, telegram_id: true })}
//                   className={`ml-2 flex items-center text-gray-500 ${isEditing.telegram_id && 'hidden'}`}
//                 >
//                   <FaPencilAlt />
//                 </button>
//                 <button
//                   type="button"
//                   onClick={() => handleSave('telegram_id')}
//                   className={`ml-2 flex items-center text-gray-500 ${!isEditing.telegram_id && 'hidden'}`}
//                 >
//                   <FaSave />
//                 </button>
//               </dd>
//             </div>

//             {/* Discord id Field */}
//             <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
//               <dt className="text-sm font-bold leading-6" 
//                 style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>Discord ID</dt>
//               <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
//                 <input
//                   type="text"
//                   name="discord_id"
//                   value={formValues.discord_id}
//                   onChange={handleChange}
//                   className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
//                 />
//                 <button
//                   type="button"
//                   onClick={() => setIsEditing({ ...isEditing, discord_id: true })}
//                   className={`ml-2 flex items-center text-gray-500 ${isEditing.discord_id && 'hidden'}`}
//                 >
//                   <FaPencilAlt />
//                 </button>
//                 <button
//                   type="button"
//                   onClick={() => handleSave('discord_id')}
//                   className={`ml-2 flex items-center text-gray-500 ${!isEditing.discord_id && 'hidden'}`}
//                 >
//                   <FaSave />
//                 </button>
//               </dd>
//             </div>

//             {/* Profile picture upload field */}
//             <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
//               <dt className="text-sm font-bold leading-6" 
//                 style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>Profile Picture</dt>
//               <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
//                 <input
//                   type="file"
//                   accept="image/*"
//                   onChange={handleProfilePictureChange}
//                   className="mt-1 block w-full sm:text-sm"
//                 />
//               </dd>
//             </div>

//             {/* Profile picture image display field */}
//             {formValues.avatar ? (
//             <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
//               <dt className="text-sm font-bold leading-6" 
//                 style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>Current Profile Picture</dt>
//               <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
//                 <img 
//                   src={maleAvatar} 
//                   alt="Profile" 
//                   className="h-25 w-25 p-8 bg-zinc-800 rounded-[50px]" 
//                 />
//               </dd>
//             </div>
//           ) : (
//             <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
//               <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center">
//                 <img
//                   src={formValues.gender === 'male' ? maleAvatar : femaleAvatar}
//                   alt="Default Avatar"
//                   className="h-25 w-25 rounded-full"
//                 />
//               </dd>
//             </div>
//           )}
//           </dl>
//         </div>

//         {/* cancel and save button field*/}
//         <div className="flex justify-end space-x-4 mt-4 p-4">
//           <button
//             type="button"
//             onClick={() => setIsModalVisible(false)}
//             className="bg-gray-300 py-2 px-4 rounded"
//             style={{ fontFamily: theme.fontFamily, color: theme.textColor }}
//           >
//             Cancel
//           </button>
//           <button
//             type="submit"
//             onClick={handleFormSubmit} // Modification: Use handleFormSubmit for form submission
//             className="py-2 px-6 rounded-md"
//             style={{ backgroundColor: theme.primaryColor, fontFamily: theme.fontFamily, color: theme.text2Color }}
//           >
//             Save
//           </button>
//         </div>
//       </div>
//       <UserUpdateAlert visible={alertVisible} onClose={closeAlert} success={alertSuccess} />
//     </div>
//   );
// };

// export default UserInfoEdit;