// src/pages/p02_ResendVerification.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext'; // Adjust the import path as necessary
import AppFooter from '../components/Footer'; // Adjust the import path as necessary
import ResendAlert from '../components/login/resendVeriflyAlert'; // Import the resend alert component
import { useVerifly } from '../hooks/Login/useVerifly'; // Import the useVerifly hook
import r1bg from '../assets/login/bg_r1.png'; // Adjust the path as necessary

const ResendVerification: React.FC = () => {
  const { theme } = useTheme();
  const { resendVerificationEmail, error } = useVerifly();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleResend = async () => {
    const success = await resendVerificationEmail(email);
    setIsSuccess(success);
    setAlertVisible(true);
    if (success) {
      setTimeout(() => {
        navigate('/login'); // Redirect to login page
      }, 3000); // Redirect after 3 seconds
    } else {
      console.error('Error:', error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen" 
      style={{ 
        backgroundImage: `url(${r1bg})`, 
        backgroundSize: 'cover' 
      }}>
      <div className="flex-grow flex items-center justify-center bg-gray-100 px-4">
        <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
          <h2 className="text-center text-xl font-bold mb-4"
            style={{ fontFamily: theme.fontFamily, color: theme.textColor }}>
              Verification Link Expired
          </h2>
          <p className="mb-4" style={{ fontFamily: theme.fontFamily, color: theme.textColor }}>
            Your verification link has expired. Please enter your email below to resend the verification email.
          </p>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-full p-2 mb-4 border rounded"
            style={{ fontFamily: theme.fontFamily, color: theme.textColor }}
          />
          <button
            className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
            onClick={handleResend}
            style={{ fontFamily: theme.fontFamily, color: theme.text2Color, backgroundColor: theme.primaryColor }}
          >
            Resend Verification Email
          </button>
        </div>
      </div>
      <footer 
        className="w-full py-4"
        style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }}
      >
        <AppFooter />
      </footer>
      <ResendAlert visible={alertVisible} onClose={() => setAlertVisible(false)} isSuccess={isSuccess} />
    </div>
  );
};

export default ResendVerification;

