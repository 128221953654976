// src/components/admin/SubscriptionSetting/SubNotificationTable.tsx

import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { AiOutlineClose, AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { RiRefreshFill, RiAddCircleFill } from "react-icons/ri";
import { useEnv } from '../../../context/EnvContext';
import useSubNotification from '../../../hooks/AdminDashboard/useSubNotification';
import { useNotificationFilter } from '../../../hooks/AdminDashboard/useSubNotificationFilter'; 
import SubNotificationSearchFilter from './SubNotificationSearchFilter';
import ModifyNotificationModal from './SubNotificationModal';
import ConfirmAlertMessage from '../alertMessage';

interface SubNotificationTableProps {
  onClose: () => void;
}

const SubNotificationTable: React.FC<SubNotificationTableProps> = ({ onClose }) => {
  const { apiUrl } = useEnv();
  const { 
    notifications, 
    formatDate, 
    formatData, 
    getStatusClass,
    getStatusHubClass,
    fetchNotifications, 
    approveNotification, 
    rejectNotification,
    removeNotification, 
  } = useSubNotification();

  const {
    statusFilter,
    setStatusFilter,
    orderIdFilter,
    setOrderIdFilter,
    filterNotifications,
    resetFilters,
  } = useNotificationFilter();

  const [activeTab, setActiveTab] = useState('Pending');
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [disableButtons, setDisableButtons] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModifyModal, setShowModifyModal] = useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] = useState<any>(null);
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
  // const [isAddMode, setIsAddMode] = useState(false); 

  // alert for delete confirmation
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState<any>(null);
  
  // alert for approve confirmation
  const [showApproveAlert, setShowApproveAlert] = useState(false);
  const [notificationToApprove, setNotificationToApprove] = useState<any>(null);
  
  // alert for reject confrimation
  const [showRejectAlert, setShowRejectAlert] = useState(false);
  const [notificationToReject, setNotificationToReject] = useState<any>(null);
  
  // set state for alert message
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertType, setAlertType] = useState<'confirm' | 'success' | 'warning' | 'error'>('success');

  const placeholderImage = "https://via.placeholder.com/150";

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const calculateDaysCount = (expirationDate: string) => {
    const currentDate = moment().tz('Asia/Kuala_Lumpur').startOf('day');
    const expiredDate = moment.tz(expirationDate, 'Asia/Kuala_Lumpur').startOf('day');
    return expiredDate.diff(currentDate, 'days');
  };

  const handleRowClick = (index: number, notification: any) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  
    if (notification.planType === 'Trial') {
      const trialDays = calculateDaysCount(notification.planExpiredTrialDate);
      notification.planTrialDays = trialDays >= 0 ? trialDays : 0;
    } else if (notification.planType === 'TraderHub') {
      const traderHubDays = calculateDaysCount(notification.expiryDate);
      notification.expireDays = traderHubDays >= 0 ? traderHubDays : 0;
    } else {
      const planDays = calculateDaysCount(notification.planExpiredDate);
      notification.planDays = planDays >= 0 ? planDays : 0;
    }
  
    setSelectedNotification(notification);
  };  

  const getProductImageUrl = (image: string | undefined, productName: string | undefined) => {
    if (productName === "Trader Hub") {
      return image ? `${apiUrl}/uploads/forTraderHubReceipts/${image}` : placeholderImage;
    } else {
      return image ? `${apiUrl}/uploads/forAlgoMatrixReceipts/${image}` : placeholderImage;
    }
  };

  const handleAddNewNotification = () => {
    setSelectedNotification({
      orderID: '',
      productName: '',
      planName: '',
      userName: '',
      email: '',
      planType: '',
      planAmount: '',
      planSubDate: '',
      planPeriod: '',
      planExpiredDate: '',
      planDays: '',
      planTrialPeriod: '',
      planExpiredTrialDate: '',
      planTrialDays: '',
      accountID: '',
      accountType: '',
      accountServer: '',
      accountNumber: '',
      accountPassword: '',
      accountStatus: '',
      accountSubCheck: '',
      accountTrialCheck: '',
      accountVoucherCheck: '',
      PaymentMethod: '',
      PaymentReceipts: '',
      notes: '',
      termAndCondition: '',
      createdAt: '',
    });
    // setIsAddMode(true); 
    setShowModifyModal(true);
  };  

  const handleApprove = (notification: any, index: number) => {
    setDisableButtons(index);
    setNotificationToApprove({ notification, index });
    setShowApproveAlert(true);
    setAlertType('warning');
    setAlertMessage('Are you sure you want to approve this notification?');
  };
  
  const handleApproveConfirm = async () => {
    if (!notificationToApprove) return;

    const { notification } = notificationToApprove;

    try {
        if (notification.planType === 'Trial') {
            const currentDate = new Date();
            const planTrialPeriod = parseInt(notification.planTrialPeriod, 10);
            const planTrialExpiredDate = new Date(currentDate.setDate(currentDate.getDate() + planTrialPeriod)).toISOString().split('T')[0];
            notification.planTrialExpiredDate = planTrialExpiredDate;
            notification.planTrialDays = planTrialPeriod;
        }
        await approveNotification(notification);
        setAlertType('success');
        setAlertMessage('Notification approved successfully for order ID: ' + notification.orderID);
    } catch (error) {
        setAlertType('error');
        setAlertMessage('Failed to approve notification for order ID: ' + notification.orderID + '. Please try again.');
    } finally {
        setShowApproveAlert(true); 
        setDisableButtons(null);
        fetchNotifications();
    }
  };

  const handleReject = (notification: any, index: number) => {
    setDisableButtons(index);
    setNotificationToReject({ notification, index });
    setShowRejectAlert(true);
    setAlertType('warning');
    setAlertMessage('Are you sure you want to reject this notification?');
  };

  const handleRejectConfirm = async () => {
    if (!notificationToReject) return;

    const { notification } = notificationToReject;

    try {
        await rejectNotification(notification);
        setAlertType('success');
        setAlertMessage('Notification rejected successfully for order ID: ' + notification.orderID);
    } catch (error) {
        setAlertType('error');
        setAlertMessage('Failed to reject notification for order ID: ' + notification.orderID + '. Please try again.');
    } finally {
        setShowRejectAlert(true); 
        setDisableButtons(null);
        fetchNotifications();
    }
  };


  const handleRemove = (notification: any) => {
    setNotificationToDelete(notification);
    setShowConfirmAlert(true);
  };  

  const handleModify = (notification: any) => {
    setSelectedNotification(notification);
    setShowModifyModal(true);
  };

  const handleConfirmDelete = async () => {
    if (notificationToDelete) {
      await removeNotification(notificationToDelete.orderID, notificationToDelete.PaymentReceipts);
      fetchNotifications();
      setShowConfirmAlert(false);
      setNotificationToDelete(null);
    }
  };
  
  // Filter notifications based on orderIdFilter
  const filteredNotificationsByOrderID = notifications.filter(notification => {
    if (orderIdFilter === 'sub') {
      return notification.orderID.startsWith('sub-');
    } else if (orderIdFilter === 'hub') {
      return notification.orderID.startsWith('hub-');
    }
    return notification.orderID.startsWith('sub-') || notification.orderID.startsWith('hub-'); 
    // Default case shows both 'sub-' and 'hub-' orders
  });

  // Calculate stats based on filtered notifications
  const allDataStats = {
    totalSubscribed: filteredNotificationsByOrderID.length,
    totalActive: filteredNotificationsByOrderID.filter(notification => 
      (notification.orderID.startsWith('sub-') && notification.accountStatus === 'Active') ||
      (notification.orderID.startsWith('hub-') && notification.accountStatus === 'Active')
    ).length,
    totalExpired: filteredNotificationsByOrderID.filter(notification => 
      (notification.orderID.startsWith('sub-') && notification.accountStatus === 'Expire') ||
      (notification.orderID.startsWith('hub-') && notification.accountStatus === 'Expire')
    ).length,
    totalPending: filteredNotificationsByOrderID.filter(notification => 
      (notification.orderID.startsWith('sub-') && notification.accountStatus === 'Pending') ||
      (notification.orderID.startsWith('hub-') && notification.accountStatus === 'Pending')
    ).length,
    totalRejected: filteredNotificationsByOrderID.filter(notification => 
      (notification.orderID.startsWith('sub-') && notification.accountStatus === 'Deactive') ||
      (notification.orderID.startsWith('hub-') && notification.accountStatus === 'Deactive')
    ).length,
  };

  const filteredNotifications = notifications.filter(notification => {
    const isInSearch = Object.values(notification).some(value =>
      typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    // Handle filtering based on activeTab
    if (activeTab === 'Approve') {
      return (notification.accountStatus === 'Active' || notification.accountStatus === 'Active') && isInSearch;
    } else if (activeTab === 'Reject') {
      return (notification.accountStatus === 'Deactive' || notification.accountStatus === 'Deactive') && isInSearch;
    } else if (activeTab === 'All Data') {
      return ['Active', 'Deactive', 'Pending', 'Expire'].includes(notification.accountStatus) && isInSearch;
    }
    return (notification.accountStatus === 'Pending' || notification.accountStatus === 'Pending') && isInSearch;
  });
  

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      {showModifyModal && selectedNotification && (
        <ModifyNotificationModal
          notification={selectedNotification}
          onClose={() => {
            setShowModifyModal(false);
            setSelectedNotification(null);
            fetchNotifications();
          }}
          // isAddMode={isAddMode} 
        />
      )}

      {enlargedImage && (
        <div 
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={() => setEnlargedImage(null)}
        >
          <img src={enlargedImage} alt="Enlarged Receipt" className="w-auto h-auto max-w-lg max-h-lg bg-white" />
        </div>
      )}
      <div className="bg-white p-6 rounded-lg shadow-lg w-auto max-w-screen h-auto max-h-screen">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">AlgoMatrix Table Data & Subscription Notification</h2>
          <button onClick={onClose}>
            <AiOutlineClose className="text-xl" />
          </button>
        </div>
        <div>
          <div className="flex mb-1 items-center">
            <div className="flex">
              <button
                className={`px-4 py-2 ${activeTab === 'Pending' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveTab('Pending')}
              >
                Pending
              </button>
              <button
                className={`px-4 py-2 ${activeTab === 'Approve' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveTab('Approve')}
              >
                Approve
              </button>
              <button
                className={`px-4 py-2 ${activeTab === 'Reject' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveTab('Reject')}
              >
                Reject
              </button>
              <button
                className={`px-4 py-2 ${activeTab === 'All Data' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveTab('All Data')}
              >
                All Data
              </button>
            </div>

            {/* Add status dropdown */}
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="ml-2 px-2 py-1 bg-white border rounded"
            >
              <option value="">Status</option>
              <option value="Pending">Pending</option>
              <option value="Active">Active</option>
              <option value="Deactive">Deactive</option>
              <option value="Expire">Expire</option>
            </select>

            {/* Add order ID filter */}
            <div className="mx-2 px-2 flex items-center border rounded-sm">
              <label className="mr-2">Order ID:</label>
              <label className="flex items-center mr-4">
                <input
                  type="radio"
                  value="sub"
                  checked={orderIdFilter === 'sub'}
                  onChange={() => setOrderIdFilter('sub')}
                  className="mr-1 accent-cyan-800"
                />
                Sub
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  value="hub"
                  checked={orderIdFilter === 'hub'}
                  onChange={() => setOrderIdFilter('hub')}
                  className="mr-1 accent-cyan-800"
                />
                Hub
              </label>
            </div>

            {/* Reset button/icon */}
            <button
              onClick={resetFilters}
              className="mr-2 text-cyan-800 hover:text-black"
            >
              <RiRefreshFill size={24} />
            </button>

            <button
              onClick={handleAddNewNotification}
              className="mr-2 text-cyan-800 hover:text-black"
            >
              <RiAddCircleFill size={24} />
            </button>

            <SubNotificationSearchFilter
              searchTerm={searchTerm}
              onSearchChange={setSearchTerm}
            />
          </div>

          {/* Apply the filter to the notifications */}
          {filteredNotifications && filterNotifications(filteredNotifications).length > 0 ? (
            <div className="overflow-y-auto h-auto max-h-[500px] text-center">
              <table className="text-sm w-auto bg-white border">
                <thead>
                  <tr className="bg-gray-300">
                    <th className="py-2 px-4 border-b">Order ID</th>
                    <th className="py-2 px-4 border-b">Order Title</th>
                    <th className="py-2 px-4 border-b">Name</th>
                    <th className="py-2 px-4 border-b">Email</th>
                    <th className="py-2 px-4 border-b">Type</th>
                    <th className="py-2 px-4 border-b">Amount</th>
                    <th className="py-2 px-4 border-b">Period</th>
                    <th className="py-2 px-4 border-b">Status</th>
                    <th className="py-2 px-4 border-b">Sended Date</th>
                  </tr>
                </thead>
                <tbody>
                  {filterNotifications(filteredNotifications).map((notification, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className="hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleRowClick(index, notification)}
                      >
                        <td className="py-2 px-4 border-b">{formatData(notification.orderID)}</td>
                        <td className="py-2 px-4 border-b">{formatData(notification.planName)}</td>
                        <td className="py-2 px-4 border-b">{formatData(notification.userName)}</td>
                        <td className="py-2 px-4 border-b">{formatData(notification.email)}</td>
                        <td className="py-2 px-4 border-b">{formatData(notification.planType)}</td>
                        <td className="py-2 px-4 border-b">{formatData(notification.planAmount)}</td>
                        <td className="py-2 px-4 border-b">
                          {notification.planType === 'Trial' ? formatData(notification.planTrialPeriod) : formatData(notification.planPeriod)}
                        </td>
                          <span className="flex items-center py-3 px-4 border-b">
                            <td className={`py-2 px-4 rounded-full text-xs ${notification.planTypeHub ? getStatusHubClass(notification.accountStatus) : getStatusClass(notification.accountStatus)}`}>{formatData(notification.accountStatus)}</td>
                          </span>                        
                        <td className="py-2 px-4 border-b">{formatDate(notification.createdAt)}</td>
                      </tr>
                      {expandedRowIndex === index && (
                        <tr>
                          <td colSpan={9} className="py-4 px-10 border-b bg-gray-100">
                            <div className="flex flex-row justify-center items-center p-4">
                              <h4 className="text-lg font-semibold mr-2">STATUS:</h4>
                              <div className={`text-sm font-semibold px-4 py-1 rounded-full ${notification.planTypeHub ? getStatusHubClass(notification.accountStatus) : getStatusClass(notification.accountStatus)}`}>{formatData(notification.accountStatus)}</div>
                              <div className="text-sm font-semibold px-4">
                                {notification.accountStatus === 'Pending' ? (
                                  `0 Expired Days`
                                ) : notification.planType === 'Trial' ? (
                                  `${formatData(notification.planTrialDays)} Expired Days`
                                ) : (
                                  `${formatData(notification.planDays)} Expired Days`
                                )}
                              </div>
                              <div className="flex space-x-2">
                                <button 
                                  className="text-cyan-800"
                                  onClick={() => handleModify(notification)}
                                >
                                  <AiFillEdit className="w-5 h-5"/>
                                </button>
                                <button 
                                  className="text-cyan-800"
                                  onClick={() => handleRemove(notification)}
                                >
                                  <AiFillDelete className="w-5 h-5"/>
                                </button>
                              </div>
                            </div>
                            <div className="flex flex-row space-x-8">
                              <div className="flex flex-row text-left">
                                <div className="space-2 font-bold">
                                  <p>Order ID:</p>
                                  <p>Requested By:</p>
                                  <p>Email:</p>
                                  <p>Note:</p>
                                  <p>T&C Apply:</p>
                                  <div className="border border-gray-300 my-4"></div>
                                  <p>Account ID:</p>
                                  <p>Account Number:</p>
                                  <p>Account Password:</p>
                                  <p>Account Server:</p>
                                </div>
                                <div className="space-2 ml-2">
                                  <p>{formatData(notification.orderID)}</p>
                                  <p>{formatData(notification.userName)}</p>
                                  <p>{formatData(notification.email)}</p>
                                  <p>{formatData(notification.notes)}</p>
                                  <p>{formatData(notification.termAndCondition)}</p>
                                  <div className="border border-gray-300 my-4"></div>
                                  <p>{formatData(notification.accountID)}</p>
                                  <p>{formatData(notification.accountNumber)}</p>
                                  <p>{formatData(notification.accountPassword)}</p>
                                  <p>{formatData(notification.accountServer)}</p>
                                </div>
                              </div>
                              <div className="flex flex-row text-left">
                                <div className="space-2 font-bold">
                                  <p>Plan Type:</p>
                                  <p>Plan Amount:</p>
                                  <p>Apply Date:</p>
                                  <p>Plan Period:</p>
                                  <p>Plan Trial Period:</p>
                                  <div className="border border-gray-300 my-4"></div>
                                  <p>Payment Method:</p>
                                  <p>Account Type:</p>
                                  <p>Trial Check</p>
                                  <p>Voucher Check</p>
                                </div>
                                <div className="space-2 ml-2">
                                  <p>{formatData(notification.planType)}</p>
                                  <p>{formatData(notification.planAmount)}</p>
                                  <p>{formatDate(notification.planSubDate)}</p>
                                  <p>{notification.planType === 'Trial' ? 'N/A' : `${formatData(notification.planPeriod)} Days (Expires on ${formatDate(notification.planExpiredDate)})`}</p>
                                  <p>{notification.planType === 'Trial' ? `${formatData(notification.planTrialPeriod)} Days (Expires on ${formatDate(notification.planExpiredTrialDate)})` : 'N/A'}</p>
                                  <div className="border border-gray-300 my-4"></div>
                                  <p>{formatData(notification.PaymentMethod)}</p>
                                  <p>{formatData(notification.accountType)}</p>
                                  <p>{formatData(notification.accountTrialCheck)}</p>
                                  <p>{formatData(notification.accountVoucherCheck)}</p>
                                </div>
                                <div className="ml-8">
                                <img
                                    src={getProductImageUrl(notification.PaymentReceipts, notification.productName)}
                                    alt="proofImage"
                                    className="w-[200px] h-[200px] rounded cursor-pointer"
                                    onClick={() => setEnlargedImage(getProductImageUrl(notification.PaymentReceipts, notification.productName))}
                                  />
                                </div>
                              </div>
                            </div>
                            {activeTab === 'Pending' && (
                              <div className="flex justify-end mt-4 space-x-2">
                                <button 
                                  className="bg-red-500 text-white px-6 py-2 rounded disabled:bg-gray-500"
                                  onClick={() => handleReject(notification, index)}
                                  disabled={disableButtons === index}
                                >
                                  Reject
                                </button>
                                <button 
                                  className="bg-green-500 text-white px-4 py-2 rounded disabled:bg-gray-500"
                                  onClick={() => handleApprove(notification, index)}
                                  disabled={disableButtons === index}
                                >
                                  Approve
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="p-4 border rounded bg-gray-100">
              <p className="text-center text-gray-600">No notifications available.</p>
            </div>
          )}
        </div>
        {/* Render the stats section always, regardless of the active tab */}
        <div className="flex justify-around p-4 border shadow-lg rounded-md my-2">
          <div className="text-center">
            <p className="font-semibold text-lg">Total Subscribed</p>
            <p className="text-lg">{allDataStats.totalSubscribed}</p>
          </div>
          <div className="text-center">
            <p className="font-semibold text-lg">Total Active</p>
            <p className="text-lg">{allDataStats.totalActive}</p>
          </div>
          <div className="text-center">
            <p className="font-semibold text-lg">Total Expired</p>
            <p className="text-lg">{allDataStats.totalExpired}</p>
          </div>
          <div className="text-center">
            <p className="font-semibold text-lg">Total Pending</p>
            <p className="text-lg">{allDataStats.totalPending}</p>
          </div>
          <div className="text-center">
            <p className="font-semibold text-lg">Total Rejected</p>
            <p className="text-lg">{allDataStats.totalRejected}</p>
          </div>
        </div>
      </div>
      {/* Confirm Warning Alert Message */}
      {showConfirmAlert && (
        <ConfirmAlertMessage
          type="warning"
          message="Are you sure you want to delete this notification? This action cannot be undo."
          visible={showConfirmAlert}
          onClose={() => setShowConfirmAlert(false)}
          onConfirm={handleConfirmDelete}
        />
      )}
      {showApproveAlert && (
        <ConfirmAlertMessage
          type={alertType}
          message={alertMessage}
          visible={showApproveAlert}
          onClose={() => setShowApproveAlert(false)}
          onConfirm={handleApproveConfirm}
        />
      )}
      {showRejectAlert && (
        <ConfirmAlertMessage
          type={alertType}
          message={alertMessage}
          visible={showRejectAlert}
          onClose={() => setShowRejectAlert(false)}
          onConfirm={handleRejectConfirm}
        />
      )}
    </div>
  );
};

export default SubNotificationTable;


// // udpate code --> 12 Sept 2024
// // src/components/admin/SubscriptionSetting/SubNotificationTable.tsx

// import React, { useEffect, useState } from 'react';
// import moment from 'moment-timezone';
// import { AiOutlineClose, AiFillEdit, AiFillDelete } from 'react-icons/ai';
// import { RiRefreshFill, RiAddCircleFill } from "react-icons/ri";
// import { useEnv } from '../../../context/EnvContext';
// import useSubNotification from '../../../hooks/AdminDashboard/useSubNotification';
// import { useNotificationFilter } from '../../../hooks/AdminDashboard/useSubNotificationFilter'; 
// import SubNotificationSearchFilter from './SubNotificationSearchFilter';
// import ModifyNotificationModal from './SubNotificationModal';
// import ConfirmAlertMessage from '../alertMessage';

// interface SubNotificationTableProps {
//   onClose: () => void;
// }

// const SubNotificationTable: React.FC<SubNotificationTableProps> = ({ onClose }) => {
//   const { apiUrl } = useEnv();
//   const { 
//     notifications, 
//     formatDate, 
//     formatData, 
//     getStatusClass,
//     getStatusHubClass,
//     fetchNotifications, 
//     approveNotification, 
//     rejectNotification,
//     removeNotification, 
//   } = useSubNotification();

//   const {
//     statusFilter,
//     setStatusFilter,
//     orderIdFilter,
//     setOrderIdFilter,
//     filterNotifications,
//     resetFilters,
//   } = useNotificationFilter();

//   const [activeTab, setActiveTab] = useState('Pending');
//   const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
//   const [disableButtons, setDisableButtons] = useState<number | null>(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [showModifyModal, setShowModifyModal] = useState<boolean>(false);
//   const [selectedNotification, setSelectedNotification] = useState<any>(null);
//   const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
//   // const [isAddMode, setIsAddMode] = useState(false); 

//   // alert for delete confirmation
//   const [showConfirmAlert, setShowConfirmAlert] = useState(false);
//   const [notificationToDelete, setNotificationToDelete] = useState<any>(null);
  
//   // alert for approve confirmation
//   const [showApproveAlert, setShowApproveAlert] = useState(false);
//   const [notificationToApprove, setNotificationToApprove] = useState<any>(null);
  
//   // alert for reject confrimation
//   const [showRejectAlert, setShowRejectAlert] = useState(false);
//   const [notificationToReject, setNotificationToReject] = useState<any>(null);
  
//   // set state for alert message
//   const [alertMessage, setAlertMessage] = useState<string>('');
//   const [alertType, setAlertType] = useState<'confirm' | 'success' | 'warning' | 'error'>('success');

//   const placeholderImage = "https://via.placeholder.com/150";

//   useEffect(() => {
//     fetchNotifications();
//   }, [fetchNotifications]);

//   const calculateDaysCount = (expirationDate: string) => {
//     const currentDate = moment().tz('Asia/Kuala_Lumpur').startOf('day');
//     const expiredDate = moment.tz(expirationDate, 'Asia/Kuala_Lumpur').startOf('day');
//     return expiredDate.diff(currentDate, 'days');
//   };

//   const handleRowClick = (index: number, notification: any) => {
//     setExpandedRowIndex(expandedRowIndex === index ? null : index);
  
//     if (notification.planType === 'Trial') {
//       const trialDays = calculateDaysCount(notification.planExpiredTrialDate);
//       notification.planTrialDays = trialDays >= 0 ? trialDays : 0;
//     } else if (notification.planType === 'TraderHub') {
//       const traderHubDays = calculateDaysCount(notification.expiryDate);
//       notification.expireDays = traderHubDays >= 0 ? traderHubDays : 0;
//     } else {
//       const planDays = calculateDaysCount(notification.planExpiredDate);
//       notification.planDays = planDays >= 0 ? planDays : 0;
//     }
  
//     setSelectedNotification(notification);
//   };  

//   const getProductImageUrl = (image: string | undefined, productName: string | undefined) => {
//     if (productName === "Trader Hub") {
//       return image ? `${apiUrl}/uploads/forTraderHubReceipts/${image}` : placeholderImage;
//     } else {
//       return image ? `${apiUrl}/uploads/forAlgoMatrixReceipts/${image}` : placeholderImage;
//     }
//   };

//   const handleAddNewNotification = () => {
//     setSelectedNotification({
//       orderID: '',
//       productName: '',
//       planName: '',
//       userName: '',
//       email: '',
//       planType: '',
//       planAmount: '',
//       planSubDate: '',
//       planPeriod: '',
//       planExpiredDate: '',
//       planDays: '',
//       planTrialPeriod: '',
//       planExpiredTrialDate: '',
//       planTrialDays: '',
//       accountID: '',
//       accountType: '',
//       accountServer: '',
//       accountNumber: '',
//       accountPassword: '',
//       accountStatus: '',
//       accountSubCheck: '',
//       accountTrialCheck: '',
//       accountVoucherCheck: '',
//       PaymentMethod: '',
//       PaymentReceipts: '',
//       notes: '',
//       termAndCondition: '',
//       createdAt: '',
//     });
//     // setIsAddMode(true); 
//     setShowModifyModal(true);
//   };  

//   const handleApprove = (notification: any, index: number) => {
//     setDisableButtons(index);
//     setNotificationToApprove({ notification, index });
//     setShowApproveAlert(true);
//     setAlertType('warning');
//     setAlertMessage('Are you sure you want to approve this notification?');
//   };
  
//   const handleApproveConfirm = async () => {
//     if (!notificationToApprove) return;

//     const { notification } = notificationToApprove;

//     try {
//         if (notification.planType === 'Trial') {
//             const currentDate = new Date();
//             const planTrialPeriod = parseInt(notification.planTrialPeriod, 10);
//             const planTrialExpiredDate = new Date(currentDate.setDate(currentDate.getDate() + planTrialPeriod)).toISOString().split('T')[0];
//             notification.planTrialExpiredDate = planTrialExpiredDate;
//             notification.planTrialDays = planTrialPeriod;
//         }
//         await approveNotification(notification);
//         setAlertType('success');
//         setAlertMessage('Notification approved successfully for order ID: ' + notification.orderID);
//     } catch (error) {
//         setAlertType('error');
//         setAlertMessage('Failed to approve notification for order ID: ' + notification.orderID + '. Please try again.');
//     } finally {
//         setShowApproveAlert(true); 
//         setDisableButtons(null);
//         fetchNotifications();
//     }
//   };

//   const handleReject = (notification: any, index: number) => {
//     setDisableButtons(index);
//     setNotificationToReject({ notification, index });
//     setShowRejectAlert(true);
//     setAlertType('warning');
//     setAlertMessage('Are you sure you want to reject this notification?');
//   };

//   const handleRejectConfirm = async () => {
//     if (!notificationToReject) return;

//     const { notification } = notificationToReject;

//     try {
//         await rejectNotification(notification);
//         setAlertType('success');
//         setAlertMessage('Notification rejected successfully for order ID: ' + notification.orderID);
//     } catch (error) {
//         setAlertType('error');
//         setAlertMessage('Failed to reject notification for order ID: ' + notification.orderID + '. Please try again.');
//     } finally {
//         setShowRejectAlert(true); 
//         setDisableButtons(null);
//         fetchNotifications();
//     }
//   };


//   const handleRemove = (notification: any) => {
//     setNotificationToDelete(notification);
//     setShowConfirmAlert(true);
//   };  

//   const handleModify = (notification: any) => {
//     setSelectedNotification(notification);
//     setShowModifyModal(true);
//   };

//   const handleConfirmDelete = async () => {
//     if (notificationToDelete) {
//       await removeNotification(notificationToDelete.orderID, notificationToDelete.PaymentReceipts);
//       fetchNotifications();
//       setShowConfirmAlert(false);
//       setNotificationToDelete(null);
//     }
//   };
  
//   // Filter notifications based on orderIdFilter
//   const filteredNotificationsByOrderID = notifications.filter(notification => {
//     if (orderIdFilter === 'sub') {
//       return notification.orderID.startsWith('sub-');
//     } else if (orderIdFilter === 'hub') {
//       return notification.orderID.startsWith('hub-');
//     }
//     return notification.orderID.startsWith('sub-') || notification.orderID.startsWith('hub-'); 
//     // Default case shows both 'sub-' and 'hub-' orders
//   });

//   // Calculate stats based on filtered notifications
//   const allDataStats = {
//     totalSubscribed: filteredNotificationsByOrderID.length,
//     totalActive: filteredNotificationsByOrderID.filter(notification => 
//       (notification.orderID.startsWith('sub-') && notification.accountStatus === 'Active') ||
//       (notification.orderID.startsWith('hub-') && notification.accountStatus === 'Active')
//     ).length,
//     totalExpired: filteredNotificationsByOrderID.filter(notification => 
//       (notification.orderID.startsWith('sub-') && notification.accountStatus === 'Expire') ||
//       (notification.orderID.startsWith('hub-') && notification.accountStatus === 'Expire')
//     ).length,
//     totalPending: filteredNotificationsByOrderID.filter(notification => 
//       (notification.orderID.startsWith('sub-') && notification.accountStatus === 'Pending') ||
//       (notification.orderID.startsWith('hub-') && notification.accountStatus === 'Pending')
//     ).length,
//     totalRejected: filteredNotificationsByOrderID.filter(notification => 
//       (notification.orderID.startsWith('sub-') && notification.accountStatus === 'Deactive') ||
//       (notification.orderID.startsWith('hub-') && notification.accountStatus === 'Deactive')
//     ).length,
//   };

//   const filteredNotifications = notifications.filter(notification => {
//     const isInSearch = Object.values(notification).some(value =>
//       typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
//     );
  
//     // Handle filtering based on activeTab
//     if (activeTab === 'Approve') {
//       return (notification.accountStatus === 'Active' || notification.accountStatus === 'Active') && isInSearch;
//     } else if (activeTab === 'Reject') {
//       return (notification.accountStatus === 'Deactive' || notification.accountStatus === 'Deactive') && isInSearch;
//     } else if (activeTab === 'All Data') {
//       return ['Active', 'Deactive', 'Pending', 'Expire'].includes(notification.accountStatus) && isInSearch;
//     }
//     return (notification.accountStatus === 'Pending' || notification.accountStatus === 'Pending') && isInSearch;
//   });
  

//   return (
//     <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//       {showModifyModal && selectedNotification && (
//         <ModifyNotificationModal
//           notification={selectedNotification}
//           onClose={() => {
//             setShowModifyModal(false);
//             setSelectedNotification(null);
//             fetchNotifications();
//           }}
//           // isAddMode={isAddMode} 
//         />
//       )}

//       {enlargedImage && (
//         <div 
//           className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
//           onClick={() => setEnlargedImage(null)}
//         >
//           <img src={enlargedImage} alt="Enlarged Receipt" className="w-auto h-auto max-w-lg max-h-lg bg-white" />
//         </div>
//       )}
//       <div className="bg-white p-6 rounded-lg shadow-lg w-auto max-w-screen h-auto max-h-screen">
//         <div className="flex justify-between items-center mb-4">
//           <h2 className="text-xl font-semibold">AlgoMatrix Table Data & Subscription Notification</h2>
//           <button onClick={onClose}>
//             <AiOutlineClose className="text-xl" />
//           </button>
//         </div>
//         <div>
//           <div className="flex mb-1 items-center">
//             <div className="flex">
//               <button
//                 className={`px-4 py-2 ${activeTab === 'Pending' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
//                 onClick={() => setActiveTab('Pending')}
//               >
//                 Pending
//               </button>
//               <button
//                 className={`px-4 py-2 ${activeTab === 'Approve' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
//                 onClick={() => setActiveTab('Approve')}
//               >
//                 Approve
//               </button>
//               <button
//                 className={`px-4 py-2 ${activeTab === 'Reject' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
//                 onClick={() => setActiveTab('Reject')}
//               >
//                 Reject
//               </button>
//               <button
//                 className={`px-4 py-2 ${activeTab === 'All Data' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
//                 onClick={() => setActiveTab('All Data')}
//               >
//                 All Data
//               </button>
//             </div>

//             {/* Add status dropdown */}
//             <select
//               value={statusFilter}
//               onChange={(e) => setStatusFilter(e.target.value)}
//               className="ml-2 px-2 py-1 bg-white border rounded"
//             >
//               <option value="">Status</option>
//               <option value="Pending">Pending</option>
//               <option value="Active">Active</option>
//               <option value="Deactive">Deactive</option>
//               <option value="Expire">Expire</option>
//             </select>

//             {/* Add order ID filter */}
//             <div className="mx-2 px-2 flex items-center border rounded-sm">
//               <label className="mr-2">Order ID:</label>
//               <label className="flex items-center mr-4">
//                 <input
//                   type="radio"
//                   value="sub"
//                   checked={orderIdFilter === 'sub'}
//                   onChange={() => setOrderIdFilter('sub')}
//                   className="mr-1 accent-cyan-800"
//                 />
//                 Sub
//               </label>
//               <label className="flex items-center">
//                 <input
//                   type="radio"
//                   value="hub"
//                   checked={orderIdFilter === 'hub'}
//                   onChange={() => setOrderIdFilter('hub')}
//                   className="mr-1 accent-cyan-800"
//                 />
//                 Hub
//               </label>
//             </div>

//             {/* Reset button/icon */}
//             <button
//               onClick={resetFilters}
//               className="mr-2 text-cyan-800 hover:text-black"
//             >
//               <RiRefreshFill size={24} />
//             </button>

//             <button
//               onClick={handleAddNewNotification}
//               className="mr-2 text-cyan-800 hover:text-black"
//             >
//               <RiAddCircleFill size={24} />
//             </button>

//             <SubNotificationSearchFilter
//               searchTerm={searchTerm}
//               onSearchChange={setSearchTerm}
//             />
//           </div>

//           {/* Apply the filter to the notifications */}
//           {filteredNotifications && filterNotifications(filteredNotifications).length > 0 ? (
//             <div className="overflow-y-auto h-auto max-h-[500px] text-center">
//               <table className="text-sm w-auto bg-white border">
//                 <thead>
//                   <tr className="bg-gray-300">
//                     <th className="py-2 px-4 border-b">Order ID</th>
//                     <th className="py-2 px-4 border-b">Order Title</th>
//                     <th className="py-2 px-4 border-b">Name</th>
//                     <th className="py-2 px-4 border-b">Email</th>
//                     <th className="py-2 px-4 border-b">Type</th>
//                     <th className="py-2 px-4 border-b">Amount</th>
//                     <th className="py-2 px-4 border-b">Period</th>
//                     <th className="py-2 px-4 border-b">Status</th>
//                     <th className="py-2 px-4 border-b">Sended Date</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {filterNotifications(filteredNotifications).map((notification, index) => (
//                     <React.Fragment key={index}>
//                       <tr
//                         className="hover:bg-gray-200 cursor-pointer"
//                         onClick={() => handleRowClick(index, notification)}
//                       >
//                         <td className="py-2 px-4 border-b">{formatData(notification.orderID)}</td>
//                         <td className="py-2 px-4 border-b">{formatData(notification.planName)}</td>
//                         <td className="py-2 px-4 border-b">{formatData(notification.userName)}</td>
//                         <td className="py-2 px-4 border-b">{formatData(notification.email)}</td>
//                         <td className="py-2 px-4 border-b">{formatData(notification.planType)}</td>
//                         <td className="py-2 px-4 border-b">{formatData(notification.planAmount)}</td>
//                         <td className="py-2 px-4 border-b">
//                           {notification.planType === 'Trial' ? formatData(notification.planTrialPeriod) : formatData(notification.planPeriod)}
//                         </td>
//                           <span className="flex items-center py-3 px-4 border-b">
//                             <td className={`py-2 px-4 rounded-full text-xs ${notification.planTypeHub ? getStatusHubClass(notification.accountStatus) : getStatusClass(notification.accountStatus)}`}>{formatData(notification.accountStatus)}</td>
//                           </span>                        
//                         <td className="py-2 px-4 border-b">{formatDate(notification.createdAt)}</td>
//                       </tr>
//                       {expandedRowIndex === index && (
//                         <tr>
//                           <td colSpan={9} className="py-4 px-10 border-b bg-gray-100">
//                             <div className="flex flex-row justify-center items-center p-4">
//                               <h4 className="text-lg font-semibold mr-2">STATUS:</h4>
//                               <div className={`text-sm font-semibold px-4 py-1 rounded-full ${notification.planTypeHub ? getStatusHubClass(notification.accountStatus) : getStatusClass(notification.accountStatus)}`}>{formatData(notification.accountStatus)}</div>
//                               <div className="text-sm font-semibold px-4">
//                                 {notification.accountStatus === 'Pending' ? (
//                                   `0 Expired Days`
//                                 ) : notification.planType === 'Trial' ? (
//                                   `${formatData(notification.planTrialDays)} Expired Days`
//                                 ) : (
//                                   `${formatData(notification.planDays)} Expired Days`
//                                 )}
//                               </div>
//                               <div className="flex space-x-2">
//                                 <button 
//                                   className="text-cyan-800"
//                                   onClick={() => handleModify(notification)}
//                                 >
//                                   <AiFillEdit className="w-5 h-5"/>
//                                 </button>
//                                 <button 
//                                   className="text-cyan-800"
//                                   onClick={() => handleRemove(notification)}
//                                 >
//                                   <AiFillDelete className="w-5 h-5"/>
//                                 </button>
//                               </div>
//                             </div>
//                             <div className="flex flex-row space-x-8">
//                               <div className="flex flex-row text-left">
//                                 <div className="space-2 font-bold">
//                                   <p>Order ID:</p>
//                                   <p>Requested By:</p>
//                                   <p>Email:</p>
//                                   <p>Note:</p>
//                                   <p>T&C Apply:</p>
//                                   <div className="border border-gray-300 my-4"></div>
//                                   <p>Account ID:</p>
//                                   <p>Account Number:</p>
//                                   <p>Account Password:</p>
//                                   <p>Account Server:</p>
//                                 </div>
//                                 <div className="space-2 ml-2">
//                                   <p>{formatData(notification.orderID)}</p>
//                                   <p>{formatData(notification.userName)}</p>
//                                   <p>{formatData(notification.email)}</p>
//                                   <p>{formatData(notification.notes)}</p>
//                                   <p>{formatData(notification.termAndCondition)}</p>
//                                   <div className="border border-gray-300 my-4"></div>
//                                   <p>{formatData(notification.accountID)}</p>
//                                   <p>{formatData(notification.accountNumber)}</p>
//                                   <p>{formatData(notification.accountPassword)}</p>
//                                   <p>{formatData(notification.accountServer)}</p>
//                                 </div>
//                               </div>
//                               <div className="flex flex-row text-left">
//                                 <div className="space-2 font-bold">
//                                   <p>Plan Type:</p>
//                                   <p>Plan Amount:</p>
//                                   <p>Apply Date:</p>
//                                   <p>Plan Period:</p>
//                                   <p>Plan Trial Period:</p>
//                                   <div className="border border-gray-300 my-4"></div>
//                                   <p>Payment Method:</p>
//                                   <p>Account Type:</p>
//                                   <p>Trial Check</p>
//                                   <p>Voucher Check</p>
//                                 </div>
//                                 <div className="space-2 ml-2">
//                                   <p>{formatData(notification.planType)}</p>
//                                   <p>{formatData(notification.planAmount)}</p>
//                                   <p>{formatDate(notification.planSubDate)}</p>
//                                   <p>{notification.planType === 'Trial' ? 'N/A' : `${formatData(notification.planPeriod)} Days (Expires on ${formatDate(notification.planExpiredDate)})`}</p>
//                                   <p>{notification.planType === 'Trial' ? `${formatData(notification.planTrialPeriod)} Days (Expires on ${formatDate(notification.planExpiredTrialDate)})` : 'N/A'}</p>
//                                   <div className="border border-gray-300 my-4"></div>
//                                   <p>{formatData(notification.PaymentMethod)}</p>
//                                   <p>{formatData(notification.accountType)}</p>
//                                   <p>{formatData(notification.accountTrialCheck)}</p>
//                                   <p>{formatData(notification.accountVoucherCheck)}</p>
//                                 </div>
//                                 <div className="ml-8">
//                                 <img
//                                     src={getProductImageUrl(notification.PaymentReceipts, notification.productName)}
//                                     alt="proofImage"
//                                     className="w-[200px] h-[200px] rounded cursor-pointer"
//                                     onClick={() => setEnlargedImage(getProductImageUrl(notification.PaymentReceipts, notification.productName))}
//                                   />
//                                 </div>
//                               </div>
//                             </div>
//                             {activeTab === 'Pending' && (
//                               <div className="flex justify-end mt-4 space-x-2">
//                                 <button 
//                                   className="bg-red-500 text-white px-6 py-2 rounded disabled:bg-gray-500"
//                                   onClick={() => handleReject(notification, index)}
//                                   disabled={disableButtons === index}
//                                 >
//                                   Reject
//                                 </button>
//                                 <button 
//                                   className="bg-green-500 text-white px-4 py-2 rounded disabled:bg-gray-500"
//                                   onClick={() => handleApprove(notification, index)}
//                                   disabled={disableButtons === index}
//                                 >
//                                   Approve
//                                 </button>
//                               </div>
//                             )}
//                           </td>
//                         </tr>
//                       )}
//                     </React.Fragment>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           ) : (
//             <div className="p-4 border rounded bg-gray-100">
//               <p className="text-center text-gray-600">No notifications available.</p>
//             </div>
//           )}
//         </div>
//         {/* Render the stats section always, regardless of the active tab */}
//         <div className="flex justify-around p-4 border shadow-lg rounded-md my-2">
//           <div className="text-center">
//             <p className="font-semibold text-lg">Total Subscribed</p>
//             <p className="text-lg">{allDataStats.totalSubscribed}</p>
//           </div>
//           <div className="text-center">
//             <p className="font-semibold text-lg">Total Active</p>
//             <p className="text-lg">{allDataStats.totalActive}</p>
//           </div>
//           <div className="text-center">
//             <p className="font-semibold text-lg">Total Expired</p>
//             <p className="text-lg">{allDataStats.totalExpired}</p>
//           </div>
//           <div className="text-center">
//             <p className="font-semibold text-lg">Total Pending</p>
//             <p className="text-lg">{allDataStats.totalPending}</p>
//           </div>
//           <div className="text-center">
//             <p className="font-semibold text-lg">Total Rejected</p>
//             <p className="text-lg">{allDataStats.totalRejected}</p>
//           </div>
//         </div>
//       </div>
//       {/* Confirm Warning Alert Message */}
//       {showConfirmAlert && (
//         <ConfirmAlertMessage
//           type="warning"
//           message="Are you sure you want to delete this notification? This action cannot be undo."
//           visible={showConfirmAlert}
//           onClose={() => setShowConfirmAlert(false)}
//           onConfirm={handleConfirmDelete}
//         />
//       )}
//       {showApproveAlert && (
//         <ConfirmAlertMessage
//           type={alertType}
//           message={alertMessage}
//           visible={showApproveAlert}
//           onClose={() => setShowApproveAlert(false)}
//           onConfirm={handleApproveConfirm}
//         />
//       )}
//       {showRejectAlert && (
//         <ConfirmAlertMessage
//           type={alertType}
//           message={alertMessage}
//           visible={showRejectAlert}
//           onClose={() => setShowRejectAlert(false)}
//           onConfirm={handleRejectConfirm}
//         />
//       )}
//     </div>
//   );
// };

// export default SubNotificationTable;