// src/components/admin/MarketSetting/DefaultMJMLTemplate.tsx

const defaultMJMLTemplate = `
  <mjml>
    <mj-head>
      <mj-attributes>
        <mj-all font-family="Noto Sans, sans-serif" color="#333" />
        <mj-button background-color="#0f5469" color="#fff" border-radius="5px" />
        <mj-section padding="0px" />
        <mj-column padding="0px" />
        <mj-text padding="20px" font-size="14px" />
      </mj-attributes>
      <mj-style>
        body {
          background: url('https://i.imgur.com/Iwitz9x.jpeg') no-repeat center center fixed;
          background-size: cover;
        }
      </mj-style>
    </mj-head>
    <mj-body background-color="rgba(245, 245, 245, 0.75)">
      <mj-section>
        <mj-column>
          <mj-image src="https://i.imgur.com/PggMdgA.png" alt="r1logo" width="400px" />
          <mj-image src="https://i.imgur.com/igPuj5m.png" alt="divider" width="500px" padding="0px 0 15px 0" />
        </mj-column>
      </mj-section>
      <mj-section background-color="#fff" border-radius="10px" padding="20px">
        <mj-column>
          <mj-text font-size="28px" color="#0f5469" font-weight="bold" align="center"> {{Title}} </mj-text>
          <mj-text align="left">Dear {{name}},</mj-text>
          <mj-text align="left">{{content}}</mj-text>
          <mj-text align="left"> If you have any questions or need further assistance, do not hesitate to contact our support team.</mj-text>
        </mj-column>
      </mj-section>
      <mj-section>
        <mj-column>
          <mj-text color="#333" font-size="16px" font-weight="bold" align="center">Get In Touch</mj-text>
          <mj-social align="center" font-size="20px" icon-size="30px" padding="0 0 20px 0">
            <mj-social-element name="facebook" href="https://www.facebook.com/R1Century?mibextid=ZbWKwL" />
            <mj-social-element name="instagram" href="https://www.instagram.com/r1century?igsh=eDdobDN0c29xZ3d3" />
            <mj-social-element name="youtube" href="https://www.youtube.com/@RONE-TV" />
          </mj-social>
        </mj-column>
      </mj-section>
      <mj-section background-color="#0f5469" border-radius="10px">
        <mj-column>
          <mj-text color="#fff" font-size="12px" align="center">If you did not register for this event, please ignore this email.</mj-text>
          <mj-text color="#fff" font-size="12px" align="center">Cheers,<br>R One Century Team</mj-text>
          <mj-divider border-color="#fff" border-width="2px" padding="5px 30px 5px 30px" />
          <mj-text color="#fff" font-size="12px" align="center">2024 &copy; R One Century 创市纪. All Rights Reserved.</mj-text>
        </mj-column>
      </mj-section>
      <mj-section>
        <mj-column>
          <mj-text font-size="12px" color="#555" font-style="italic" align="center">Please do not reply to this email. This mailbox is not monitored and you will not receive a response. If you need assistance, please contact our support team at <a href="mailto:general@r1trader.com">general@r1trader.com</a>.</mj-text>
        </mj-column>
      </mj-section>
    </mj-body>
  </mjml>
`;

export default defaultMJMLTemplate;
