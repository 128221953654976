// src/components/admin/SubscriptionSetting/SubNotificationSearchFilter.tsx

import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

interface SubNotificationSearchFilterProps {
  searchTerm: string;
  onSearchChange: (searchTerm: string) => void;
}

const SubNotificationSearchFilter: React.FC<SubNotificationSearchFilterProps> = ({ searchTerm, onSearchChange }) => {
  return (
    <div className="flex items-center ml-auto">
      <div className="relative">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
          className="pl-8 pr-4 py-2 rounded bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-800"
        />
        <AiOutlineSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500" />
      </div>
    </div>
  );
};

export default SubNotificationSearchFilter;
