// src/pages/client/p03_UserDashboard.tsx

// import react components
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// import components file
import TopMenu from '../../components/TopMenu';
import AppFooter from '../../components/Footer';
import UserInfo from '../../components/client/Dashboard/UserInfo';
import UserPointsStats from '../../components/client/Dashboard/UserPointStats';
import UserTask from '../../components/client/Dashboard/UserTask';
import UserCalendar from '../../components/client/Dashboard/UserCalendar';
import UserEvent from '../../components/client/Dashboard/UserEvent';
import NotifyBanner from '../../components/client/Banner';
import UserBoard from '../../components/client/Dashboard/UserBoard';
import UserApp from '../../components/client/Dashboard/UserApp';
import AccountExpireAlert from '../../components/AccountExpireAlert';

// import hooks file
import useUserStage from '../../hooks/Dashboard/useUserStage';
import useUserDashboard from '../../hooks/Dashboard/useUserDashboard';

// import state modal
import StateModal from '../../components/UpdateState';

const OverlayWrapper: React.FC<{ 
  children: React.ReactNode, 
  isGuest: boolean, 
  message: string, 
  subMessage: string 
}> = ({ children, isGuest, message, subMessage }) => (
  <div className="relative w-full h-full">
    {children}
    {isGuest && (
      <div className="absolute inset-0 bg-gray-300 bg-opacity-95 border-2 border-rose-300 flex flex-col items-center justify-center rounded-lg z-10">
        <span className="text-red-500 font-medium text-center px-4 md:text-sm sm:text-sm xs:text-sm">{message}</span>
        <span className="text-red-500 font-bold text-center px-4 mt-4 md:text-sm sm:text-sm xs:text-sm">{subMessage}</span>
      </div>
    )}
  </div>
);

const DashboardView: React.FC<{ email: string }> = ({ email }) => {
  const { isGuest } = useUserStage();
  const { showExpireAlert, handleExpireAlertConfirm, updateUserState, showStateModal } = useUserDashboard();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col items-center h-screen w-full bg-gray-100">
      {showExpireAlert && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <AccountExpireAlert visible={showExpireAlert} onClose={handleExpireAlertConfirm} />
        </div>
      )}

      {/* Display the modal to select the state */}
      {showStateModal && (
        <StateModal onSave={updateUserState} /> 
      )}

      <TopMenu />
      <NotifyBanner />
      <div className="flex flex-col flex-grow w-full max-w-[1100px] h-auto max-h-screen p-4 space-y-4 overflow-y-auto md:space-y-2 sm:space-y-0 xs:space-y-0">
        <div className="flex flex-row w-full space-x-4 md:space-x-2 sm:flex-col sm:space-x-0 xs:flex-col xs:space-x-0">
          <div className="w-1/2 p-2 rounded-xl md:w-[400px] sm:w-full xs:w-full">
            {loading ? <Skeleton height={200} /> : <UserInfo />}
          </div>
          <div className="flex flex-col w-full sm:w-auto xs:w-auto">
            <div className="w-full p-2 rounded-xl">
              {loading ? <Skeleton height={200} /> : <UserBoard />}
            </div>
            <div className="w-full p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
              <OverlayWrapper
                isGuest={isGuest}
                message="Explore your rewards and program statuses in My Stats"
                subMessage="Enroll classes to access this feature"
              >
                {loading ? <Skeleton height={200} /> : <UserPointsStats />}
              </OverlayWrapper>
            </div>
          </div>
        </div>
        <div className="flex w-full space-x-4 md:space-x-2 sm:flex-col sm:space-x-0 xs:flex-col xs:space-x-0">
          <div className="w-1/2 p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
            <OverlayWrapper
              isGuest={isGuest}
              message="Discover and plan your events easily with My Calendar to Stay informed and ahead"
              subMessage="Enroll classes to access this feature"
            >
              {loading ? <Skeleton height={200} /> : <UserCalendar email={email} />}
            </OverlayWrapper>
          </div>
          <div className="w-1/2 p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
            <OverlayWrapper
              isGuest={isGuest}
              message="Stay updated on today's events with My Event. See your bookings and briefs on upcoming events effortlessly"
              subMessage="Enroll classes to access this feature"
            >
              {loading ? <Skeleton height={200} /> : <UserEvent email={email} />}
            </OverlayWrapper>
          </div>
        </div>
        <div className="w-full p-2 bg-gray-100 rounded-xl">
          {loading ? <Skeleton height={200} /> : <UserApp />}
        </div>
        <div className="flex space-x-4">
          <div className="p-2 w-full bg-gray-100 rounded-xl">
            <OverlayWrapper
              isGuest={isGuest}
              message="Track your tasks, earn points, and unlock rewards with My Progress Start collecting points now!"
              subMessage="Enroll classes to access this feature"
            >
              {loading ? <Skeleton height={200} /> : <UserTask />}
            </OverlayWrapper>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 w-full">
        <AppFooter />
      </div>
    </div>
  );
};

export default DashboardView;


// // udpate code --> 15 Sept 2024
// // src/pages/client/p03_UserDashboard.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';

// // import components file
// import TopMenu from '../../components/TopMenu';
// import AppFooter from '../../components/Footer';
// import UserInfo from '../../components/client/Dashboard/UserInfo';
// import UserPointsStats from '../../components/client/Dashboard/UserPointStats';
// import UserTask from '../../components/client/Dashboard/UserTask';
// import UserCalendar from '../../components/client/Dashboard/UserCalendar';
// import UserEvent from '../../components/client/Dashboard/UserEvent';
// import NotifyBanner from '../../components/client/Banner';
// import UserBoard from '../../components/client/Dashboard/UserBoard';
// import UserApp from '../../components/client/Dashboard/UserApp';
// import AccountExpireAlert from '../../components/AccountExpireAlert';

// // import hooks file
// import useUserStage from '../../hooks/Dashboard/useUserStage';
// import useUserDashboard from '../../hooks/Dashboard/useUserDashboard';

// const OverlayWrapper: React.FC<{ 
//   children: React.ReactNode, 
//   isGuest: boolean, 
//   message: string, 
//   subMessage: string 
// }> = ({ children, isGuest, message, subMessage }) => (
//   <div className="relative w-full h-full">
//     {children}
//     {isGuest && (
//       <div className="absolute inset-0 bg-gray-300 bg-opacity-95 border-2 border-rose-300 flex flex-col items-center justify-center rounded-lg z-10">
//         <span className="text-red-500 font-medium text-center px-4 md:text-sm sm:text-sm xs:text-sm">{message}</span>
//         <span className="text-red-500 font-bold text-center px-4 mt-4 md:text-sm sm:text-sm xs:text-sm">{subMessage}</span>
//       </div>
//     )}
//   </div>
// );

// const DashboardView: React.FC<{ email: string }> = ({ email }) => {
//   const { isGuest } = useUserStage();
//   const { showExpireAlert, handleExpireAlertConfirm } = useUserDashboard();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // Simulate loading delay
//     const timer = setTimeout(() => setLoading(false), 2000);
//     return () => clearTimeout(timer);
//   }, []);

//   return (
//     <div className="flex flex-col items-center h-screen w-full bg-gray-100">
//       {showExpireAlert && (
//         <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//           <AccountExpireAlert visible={showExpireAlert} onClose={handleExpireAlertConfirm} />
//         </div>
//       )}
//       <TopMenu />
//       <NotifyBanner />
//       <div className="flex flex-col flex-grow w-full max-w-[1100px] h-auto max-h-screen p-4 space-y-4 overflow-y-auto md:space-y-2 sm:space-y-0 xs:space-y-0">
//         <div className="flex flex-row w-full space-x-4 md:space-x-2 sm:flex-col sm:space-x-0 xs:flex-col xs:space-x-0">
//           <div className="w-1/2 p-2 rounded-xl md:w-[400px] sm:w-full xs:w-full">
//             {loading ? <Skeleton height={200} /> : <UserInfo />}
//           </div>
//           <div className="flex flex-col w-full sm:w-auto xs:w-auto">
//             <div className="w-full p-2 rounded-xl">
//               {loading ? <Skeleton height={200} /> : <UserBoard />}
//             </div>
//             <div className="w-full p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
//               <OverlayWrapper
//                 isGuest={isGuest}
//                 message="Explore your rewards and program statuses in My Stats"
//                 subMessage="Enroll classes to access this feature"
//               >
//                 {loading ? <Skeleton height={200} /> : <UserPointsStats />}
//               </OverlayWrapper>
//             </div>
//           </div>
//         </div>
//         <div className="flex w-full space-x-4 md:space-x-2 sm:flex-col sm:space-x-0 xs:flex-col xs:space-x-0">
//           <div className="w-1/2 p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
//             <OverlayWrapper
//               isGuest={isGuest}
//               message="Discover and plan your events easily with My Calendar to Stay informed and ahead"
//               subMessage="Enroll classes to access this feature"
//             >
//               {loading ? <Skeleton height={200} /> : <UserCalendar email={email} />}
//             </OverlayWrapper>
//           </div>
//           <div className="w-1/2 p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
//             <OverlayWrapper
//               isGuest={isGuest}
//               message="Stay updated on today's events with My Event. See your bookings and briefs on upcoming events effortlessly"
//               subMessage="Enroll classes to access this feature"
//             >
//               {loading ? <Skeleton height={200} /> : <UserEvent email={email} />}
//             </OverlayWrapper>
//           </div>
//         </div>
//         <div className="w-full p-2 bg-gray-100 rounded-xl">
//           {loading ? <Skeleton height={200} /> : <UserApp />}
//         </div>
//         <div className="flex space-x-4">
//           <div className="p-2 w-full bg-gray-100 rounded-xl">
//             <OverlayWrapper
//               isGuest={isGuest}
//               message="Track your tasks, earn points, and unlock rewards with My Progress Start collecting points now!"
//               subMessage="Enroll classes to access this feature"
//             >
//               {loading ? <Skeleton height={200} /> : <UserTask />}
//             </OverlayWrapper>
//           </div>
//         </div>
//       </div>
//       <div className="flex-shrink-0 w-full">
//         <AppFooter />
//       </div>
//     </div>
//   );
// };

// export default DashboardView;


// // updated code --> 15 july 2024
// // src/pages/client/p03_UserDashboard.tsx

// import React from 'react';
// // import { useTheme } from '../../context/ThemeContext';
// import TopMenu from '../../components/TopMenu';
// import AppFooter from '../../components/Footer';
// import UserInfo from '../../components/client/Dashboard/UserInfo';
// import UserPointsStats from '../../components/client/Dashboard/UserPointStats';
// import UserTask from '../../components/client/Dashboard/UserTask';
// import UserCalendar from '../../components/client/Dashboard/UserCalendar';
// import UserEvent from '../../components/client/Dashboard/UserEvent';
// import NotifyBanner from '../../components/client/Banner';
// import UserBoard from '../../components/client/Dashboard/UserBoard';
// import UserApp from '../../components/client/Dashboard/UserApp';
// import useUserStage from '../../hooks/Dashboard/useUserStage';
// import useUserDashboard from '../../hooks/Dashboard/useUserDashboard';
// import AccountExpireAlert from '../../components/AccountExpireAlert';

// const OverlayWrapper: React.FC<{ 
//   children: React.ReactNode, 
//   isGuest: boolean, 
//   message: string, 
//   subMessage: string 
// }> = ({ children, isGuest, message, subMessage }) => (
//   <div className="relative w-full h-full">
//     {children}
//     {isGuest && (
//       <div className="absolute inset-0 bg-gray-300 bg-opacity-95 border-2 border-rose-300 flex flex-col items-center justify-center rounded-lg z-10">
//         <span className="text-red-500 font-medium text-center px-4 md:text-sm sm:text-sm xs:text-sm">{message}</span>
//         <span className="text-red-500 font-bold text-center px-4 mt-4 md:text-sm sm:text-sm xs:text-sm">{subMessage}</span>
//       </div>
//     )}
//   </div>
// );

// const DashboardView: React.FC<{ email: string }> = ({ email }) => {
//   // const { theme } = useTheme();
//   const { isGuest } = useUserStage();
//   const { showExpireAlert, handleExpireAlertConfirm } = useUserDashboard();

//   return (
//     <div className="flex flex-col items-center h-screen w-full bg-gray-100 s">
//       {showExpireAlert && (
//         <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//           <AccountExpireAlert visible={showExpireAlert} onClose={handleExpireAlertConfirm} />
//         </div>
//       )}
//       <TopMenu />
//       <NotifyBanner />
//       <div className="flex flex-col flex-grow w-full max-w-[1100px] p-4 space-y-4 overflow-y-auto md:space-y-2 sm:space-y-0 xs:space-y-0">
//         <div className="flex flex-row w-full space-x-4 md:space-x-2 sm:flex-col sm:space-x-0 xs:flex-col xs:space-x-0">
//           <div className="w-1/2 p-2 rounded-xl md:w-[400px] sm:w-full xs:w-full">
//             <UserInfo />
//           </div>
//           <div className="flex flex-col w-full sm:w-auto xs:w-auto">
//             <div className="w-full p-2 rounded-xl">
//               <UserBoard />
//             </div>
//             <div className="w-full p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
//               <OverlayWrapper
//                 isGuest={isGuest}
//                 message="Explore your rewards and program statuses in My Stats"
//                 subMessage="Enroll classes to access this feature"
//               >
//                 <UserPointsStats />
//               </OverlayWrapper>
//             </div>
//           </div>
//         </div>
//         <div className="flex w-full space-x-4 md:space-x-2 sm:flex-col sm:space-x-0 xs:flex-col xs:space-x-0">
//           <div className="w-1/2 p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
//             <OverlayWrapper
//               isGuest={isGuest}
//               message="Discover and plan your events easily with My Calendar to Stay informed and ahead"
//               subMessage="Enroll classes to access this feature"
//             >
//               <UserCalendar email={email} />
//             </OverlayWrapper>
//           </div>
//           <div className="w-1/2 p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
//             <OverlayWrapper
//               isGuest={isGuest}
//               message="Stay updated on today's events with My Event. See your bookings and briefs on upcoming events effortlessly"
//               subMessage="Enroll classes to access this feature"
//             >
//               <UserEvent email={email} />
//             </OverlayWrapper>
//           </div>
//         </div>
//         <div className="w-full p-2 bg-gray-100 rounded-xl">
//           <UserApp />
//         </div>
//         <div className="flex space-x-4">
//           <div className="p-2 w-full bg-gray-100 rounded-xl">
//             <OverlayWrapper
//               isGuest={isGuest}
//               message="Track your tasks, earn points, and unlock rewards with My Progress Start collecting points now!"
//               subMessage="Enroll classes to access this feature"
//             >
//               <UserTask />
//             </OverlayWrapper>
//           </div>
//         </div>
//       </div>
//       <div className="flex-shrink-0 w-full">
//         <AppFooter />
//       </div>
//     </div>
//   );
// };

// export default DashboardView;



// // update code --> 11 july 2024
// // src/pages/client/p03_UserDashboard.tsx

// import React from 'react';
// // import { useTheme } from '../../context/ThemeContext';
// import TopMenu from '../../components/TopMenu';
// import AppFooter from '../../components/Footer';
// import UserInfo from '../../components/client/Dashboard/UserInfo';
// import UserPointsStats from '../../components/client/Dashboard/UserPointStats';
// import UserTask from '../../components/client/Dashboard/UserTask';
// import UserCalendar from '../../components/client/Dashboard/UserCalendar';
// import UserEvent from '../../components/client/Dashboard/UserEvent';
// import NotifyBanner from '../../components/client/Banner';
// import UserBoard from '../../components/client/Dashboard/UserBoard';
// import UserApp from '../../components/client/Dashboard/UserApp';
// import useUserStage from '../../hooks/Dashboard/useUserStage';
// import useUserDashboard from '../../hooks/Dashboard/useUserDashboard';
// import AccountExpireAlert from '../../components/AccountExpireAlert';

// const DashboardView: React.FC<{ email: string }> = ({ email }) => {
//   // const { theme } = useTheme();
//   const { isGuest } = useUserStage();
//   const { showExpireAlert, handleExpireAlertConfirm } = useUserDashboard();

//   return (
//     <div className="flex flex-col items-center h-screen w-full bg-gray-100 s">
//       {showExpireAlert && (
//         <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//           <AccountExpireAlert visible={showExpireAlert} onClose={handleExpireAlertConfirm} />
//         </div>
//       )}
//       <TopMenu />
//       <NotifyBanner />
//       <div className="flex flex-col flex-grow w-full max-w-[1100px] p-4 space-y-4 overflow-y-auto md:space-y-2 sm:space-y-0 xs:space-y-0">
//         <div className="flex flex-row w-full space-x-4 md:space-x-2 sm:flex-col sm:space-x-0 xs:flex-col xs:space-x-0">
//           <div className="w-1/2 p-2 rounded-xl md:w-[400px] sm:w-full xs:w-full">
//             <UserInfo />
//           </div>
//           <div className="flex flex-col w-full sm:w-auto xs:w-auto">
//             <div className="w-full p-2 rounded-xl">
//               <UserBoard />
//             </div>
//             <div className="w-full p-2 rounded-xl">
//               <UserPointsStats />
//               {isGuest && (
//                 <div className="absolute inset-0 ring-2 ring-rose-300 bg-gray-300 bg-opacity-95 flex flex-col items-center justify-center rounded-lg">
//                   <span className="text-red-600 font-medium mx-8 text-center">Explore your rewards and program statuses in <strong>My Stats</strong></span>
//                   <span className="text-red-600 font-bold mx-8 text-center my-10">Enroll classes to access this feature</span>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//         <div className="flex w-full space-x-4 md:space-x-2 sm:flex-col sm:space-x-0 xs:flex-col xs:space-x-0">
//           <div className="w-1/2 p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
//             <UserCalendar email={email} />
//             {isGuest && (
//               <div className="absolute inset-0 ring-2 ring-rose-300 bg-gray-300 bg-opacity-90 flex flex-col items-center justify-center rounded-lg">
//                 <span className="text-red-600 font-medium mx-8 text-center">Discover and plan your events easily with <strong>My Calendar</strong> to Stay informed and ahead</span>
//                 <span className="text-red-600 font-bold mx-8 text-center my-10">Enroll classes to access this feature</span>
//               </div>
//             )}
//           </div>
//           <div className="w-1/2 p-2 bg-gray-100 rounded-xl sm:w-auto xs:w-auto">
//             <UserEvent email={email} />
//             {isGuest && (
//               <div className="absolute inset-0 ring-2 ring-rose-300 bg-gray-300 bg-opacity-90 flex flex-col items-center justify-center rounded-lg">
//                 <span className="text-red-600 font-medium mx-6 text-center">Stay updated on today's events with <strong>My Event</strong>. See your bookings and briefs on upcoming events effortlessly</span>
//                 <span className="text-red-600 font-bold mx-8 text-center my-10">Enroll classes to access this feature</span>
//               </div>
//             )}
//           </div>
//         </div>
//         <div className="w-full p-2 bg-gray-100 rounded-xl">
//           <UserApp />
//         </div>
//         <div className="flex space-x-4">
//           <div className="p-2 w-full bg-gray-100 rounded-xl">
//             <UserTask />
//             {isGuest && (
//               <div className="absolute inset-0 ring-2 ring-rose-300 bg-gray-300 bg-opacity-95 flex flex-col items-center justify-center rounded-lg">
//                 <span className="text-red-600 font-medium mx-20 text-center">Track your tasks, earn points, and unlock rewards with <strong>My Progress</strong> Start collecting points now!</span>
//                 <span className="text-red-600 font-bold mx-8 text-center my-10">Enroll classes to access this feature</span>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//       <div className="flex-shrink-0 w-full">
//         <AppFooter />
//       </div>
//     </div>
//   );
// };

// export default DashboardView;