// src/hooks/MiniApp/useEventData.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';
interface RawEvent {
  id: number;
  ticketId: string;
  eventTitle: string;
  name: string;
  startDate: string;
  endDate: string;
  time: string;
  location: string;
  onlineConferenceText: string;
  amount: number;
  currency: string;
  lotSize: number;
  deposit:number;
  broker: string;
  points: number;
  description: string;
  image: string | null;
  status: string;
  redeemNumber: number;
  slot: number;
  memberType: string; // This is how it's received from the backend
  eventStatus: string;
}

export interface Event {
  id: number;
  ticketId: string;
  eventTitle: string;
  name: string;
  startDate: string;
  endDate: string;
  time: string;
  location: string;
  onlineConferenceText: string;
  amount: number;
  currency: string;
  lotSize: number;
  deposit:number;
  broker: string;
  points: number;
  description: string;
  image: string | null;
  status: string;
  redeemNumber: number;
  slot: number;
  MemberType: string[]; // This is how we want to use it
  eventStatus: string;
}

export interface MyTicketEvent extends Event {
  appointmentDate: string;
  onlineConferenceText: string; // Ensure this line is included
}

export const getEventImagePath = (image: string | null, apiUrl: string): string => {
  return image ? `${apiUrl}/uploads/forEventTicket/${image}` : "https://placehold.co/150x150";
};

// Add these helper functions inside useEventData.tsx
export const getEventStatusStyle = (status: string): string => {
  switch (status) {
    case 'attend':
      return 'text-green-800 bg-green-200 px-2 py-1 rounded';
    case 'Not Check-in':
      return 'text-yellow-800 bg-yellow-200 px-2 py-1 rounded';
    case 'expired':
      return 'text-red-800 bg-red-200 px-2 py-1 rounded';
    default:
      return 'text-gray-800 bg-gray-200 px-2 py-1 rounded';
  }
};

export const convertToShortForm = (memberType: string): string => {
  switch (memberType) {
    case "R One Elite Trader":
      return "Elite";
    case "R One Master Trader":
      return "Master";
    case "R One Certified Trader":
      return "Certified";
    case "Trader Hub Member":
      return "TraderHub";
    case "R One Dealer":
      return "Dealer";
    case "R One Member":
      return "Member";
    case "R One Guest":
      return "Guest";
    default:
      return memberType;
  }
};

export const getTagColor = (shortForm: string): string => {
  switch (shortForm) {
    case "Certified":
      return "text-green-800";
    case "Dealer":
      return "text-yellow-600";
    case "Elite":
      return "text-blue-800";
    case "Master":
      return "text-purple-800";
    case "TraderHub":
      return "text-orange-800";
    case "Member":
      return "text-cyan-800";
    default:
      return "text-gray-400";
  }
};

export const formatAmount = (amount: string | number) => {
  const number = typeof amount === 'string' ? parseFloat(amount) : amount;
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};

export const formatDateWithTime = (dateString: string, timeString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-GB', options);

  if (!timeString) {
    return `${formattedDate} at N/A`;
  }

  // Convert the time string to 12-hour format with AM/PM
  const [hours, minutes] = timeString.split(':');
  const time = new Date();
  time.setHours(parseInt(hours), parseInt(minutes));
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  const formattedTime = time.toLocaleTimeString('en-US', timeOptions);

  return `${formattedDate} at ${formattedTime}`;
};

export const formatTime = (timeString: string): string => {
  if (!timeString) return 'N/A';
  
  const [hours, minutes] = timeString.split(':');
  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
};

export const formatDate = (dateString: string, timeString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-GB', options);
  
  if (!timeString) {
    return `${formattedDate}`;
  }

  return `${formattedDate}`;
};

export const isToday = (dateString: string): boolean => {
  const today = new Date();
  const date = new Date(dateString);
  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  );
};

export const convertTextToBulletPoints = (text: string): string => {
  if (!text) return '';

  return text
    .split('\n')
    .map(line => {
      // Handle bullet points
      line = line.replace(/-/g, '•');
      // Handle new lines
      line = line.replace(/\/n/g, '<br/>');
      // Handle URLs
      if (line.includes('https://') || line.includes('http://')) {
        const urlPattern = /(https?:\/\/[^\s]+)/g;
        line = line.replace(urlPattern, '<a href="$1" target="_blank" style="color: blue; text-decoration: underline;">$1</a>');
      }
      return line;
    })
    .join('<br/>');
};

const useEventData = () => {
  const { apiUrl } = useEnv();
  const [events, setEvents] = useState<Event[]>([]);
  const [expiredEvents, setExpiredEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [myTickets, setMyTickets] = useState<MyTicketEvent[]>([]);
  const [attendedTickets, setAttendedTickets] = useState<string[]>([]);

  const fetchEvents = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}/api/programs`);
      const data: RawEvent[] = await response.json();

      // Parse MemberType from JSON string to array of strings
      const parsedData = data.map((event) => ({
        ...event,
        MemberType: JSON.parse(event.memberType.replace(/\\/g, '')),
        onlineConferenceText: convertTextToBulletPoints(event.onlineConferenceText || ''), // Convert text
      }));

      const runningEvents = parsedData.filter((event) => event.status === 'running');
      const expiredEvents = parsedData.filter((event) => event.status === 'expired');
      setEvents(runningEvents);
      setExpiredEvents(expiredEvents);
    } catch (err) {
      setError('Failed to fetch events');
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

  // Update fetchApprovedEvents function to set the attendedTickets state
  const fetchApprovedEvents = useCallback(async (email: string) => {
    try {
        setLoading(true);
        const response = await fetch(`${apiUrl}/api/approved-events?email=${email}`);
        if (response.ok) {
            const data: MyTicketEvent[] = await response.json();
            setMyTickets(data);

            const attended = data.filter(ticket => ticket.eventStatus === 'attend').map(ticket => ticket.ticketId);
            setAttendedTickets(attended);
        } else {
            throw new Error('Failed to fetch approved events');
        }
    } catch (error) {
        console.error('Failed to fetch approved events:', error);
        setError('Failed to fetch approved events');
    } finally {
        setLoading(false);
    }
  }, [apiUrl]);

  const fetchUserApprovedTickets = useCallback(async (email: string, eventName: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/approved-events?email=${email}`);
      if (response.ok) {
        const data: MyTicketEvent[] = await response.json();
        return data.filter(ticket => ticket.eventTitle === eventName);
      } else {
        throw new Error('Failed to fetch approved events');
      }
    } catch (error) {
      console.error('Failed to fetch approved events:', error);
      throw error;
    }
  }, [apiUrl]);  
  
  const checkMembership = async (email: string, eventMemberType: string[]): Promise<{ allowed: boolean, requiredMemberType: string }> => {
    const response = await fetch(`${apiUrl}/api/check-membership`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, eventMemberType })
    });

    const data = await response.json();
    return data;
  };

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const getSlotMessage = (slot: number) => {
    if (slot === -1) {
      return (
        <div className="bg-green-100 text-green-800 py-2 px-4 w-auto rounded-2xl">
          Tickets are unlimited!
        </div>
      );
    } else if (slot === 0) {
      return (
        <div className="bg-red-100 text-red-800 py-2 px-4 w-auto rounded-2xl">
          Sold Out!
        </div>
      );
    } else if (slot <= 5) {
      return (
        <div className="animate-pulse bg-red-100 text-red-800 py-2 px-4 w-auto rounded-2xl">
          Only {slot} left! Secure your spot now!
        </div>
      );
    } else if (slot <= 10) {
      return (
        <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 w-auto rounded-2xl">
          Just {slot} remaining! Grab your ticket before !
        </div>
      );
    } else if (slot <= 20) {
      return (
        <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 w-auto rounded-2xl">
          {slot} slots left! limited availability!
        </div>
      );
    } else if (slot <= 50) {
      return (
        <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl w-auto flex justify-center">
          {slot} tickets left! Don't miss out!
        </div>
      );
    } else {
      return (
        <div className="bg-green-100 text-green-800 py-2 px-4 w-auto rounded-2xl">
          {slot} tickets available!
        </div>
      );
    }
  };
  
  return { 
    events, 
    expiredEvents, 
    myTickets, 
    loading, 
    error, 
    fetchEvents, 
    getSlotMessage, 
    fetchApprovedEvents,
    fetchUserApprovedTickets,
    attendedTickets,
    checkMembership 
  };
};

export default useEventData;


// // update code --> 21 july 2024
// // src/hooks/MiniApp/useEventData.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// interface RawEvent {
//   id: number;
//   ticketId: string;
//   eventTitle: string;
//   name: string;
//   startDate: string;
//   endDate: string;
//   time: string;
//   location: string;
//   onlineConferenceText: string;
//   amount: number;
//   currency: string;
//   lotSize: number;
//   deposit:number;
//   broker: string;
//   points: number;
//   description: string;
//   image: string | null;
//   status: string;
//   redeemNumber: number;
//   slot: number;
//   memberType: string; // This is how it's received from the backend
//   eventStatus: string;
// }

// export interface Event {
//   id: number;
//   ticketId: string;
//   eventTitle: string;
//   name: string;
//   startDate: string;
//   endDate: string;
//   time: string;
//   location: string;
//   onlineConferenceText: string;
//   amount: number;
//   currency: string;
//   lotSize: number;
//   deposit:number;
//   broker: string;
//   points: number;
//   description: string;
//   image: string | null;
//   status: string;
//   redeemNumber: number;
//   slot: number;
//   MemberType: string[]; // This is how we want to use it
//   eventStatus: string;
// }

// export interface MyTicketEvent extends Event {
//   appointmentDate: string;
//   onlineConferenceText: string; // Ensure this line is included
// }

// export const getEventImagePath = (image: string | null, apiUrl: string): string => {
//   return image ? `${apiUrl}/uploads/forEventTicket/${image}` : "https://placehold.co/150x150";
// };

// // Add these helper functions inside useEventData.tsx
// export const getEventStatusStyle = (status: string): string => {
//   switch (status) {
//     case 'attend':
//       return 'text-green-800 bg-green-200 px-2 py-1 rounded';
//     case 'Not Check-in':
//       return 'text-yellow-800 bg-yellow-200 px-2 py-1 rounded';
//     case 'expired':
//       return 'text-red-800 bg-red-200 px-2 py-1 rounded';
//     default:
//       return 'text-gray-800 bg-gray-200 px-2 py-1 rounded';
//   }
// };

// export const convertToShortForm = (memberType: string): string => {
//   switch (memberType) {
//     case "R One Elite Trader":
//       return "Elite";
//     case "R One Master Trader":
//       return "Master";
//     case "R One Certified Trader":
//       return "Certified";
//     case "Trader Hub Member":
//       return "TraderHub";
//     case "R One Dealer":
//       return "Dealer";
//     case "R One Member":
//       return "Member";
//     case "R One Guest":
//       return "Guest";
//     default:
//       return memberType;
//   }
// };

// export const getTagColor = (shortForm: string): string => {
//   switch (shortForm) {
//     case "Certified":
//       return "text-green-800";
//     case "Dealer":
//       return "text-yellow-600";
//     case "Elite":
//       return "text-blue-800";
//     case "Master":
//       return "text-purple-800";
//     case "TraderHub":
//       return "text-orange-800";
//     case "Member":
//       return "text-cyan-800";
//     default:
//       return "text-gray-400";
//   }
// };

// export const formatAmount = (amount: string | number) => {
//   const number = typeof amount === 'string' ? parseFloat(amount) : amount;
//   return new Intl.NumberFormat('en-US', {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2
//   }).format(number);
// };

// export const formatDateWithTime = (dateString: string, timeString: string): string => {
//   const options: Intl.DateTimeFormatOptions = {
//     day: '2-digit',
//     month: '2-digit',
//     year: 'numeric',
//   };
//   const date = new Date(dateString);
//   const formattedDate = date.toLocaleDateString('en-GB', options);

//   if (!timeString) {
//     return `${formattedDate} at N/A`;
//   }

//   // Convert the time string to 12-hour format with AM/PM
//   const [hours, minutes] = timeString.split(':');
//   const time = new Date();
//   time.setHours(parseInt(hours), parseInt(minutes));
//   const timeOptions: Intl.DateTimeFormatOptions = {
//     hour: '2-digit',
//     minute: '2-digit',
//     hour12: true,
//   };
//   const formattedTime = time.toLocaleTimeString('en-US', timeOptions);

//   return `${formattedDate} at ${formattedTime}`;
// };

// export const formatTime = (timeString: string): string => {
//   if (!timeString) return 'N/A';
  
//   const [hours, minutes] = timeString.split(':');
//   const date = new Date();
//   date.setHours(parseInt(hours, 10));
//   date.setMinutes(parseInt(minutes, 10));
  
//   return date.toLocaleTimeString('en-US', {
//     hour: 'numeric',
//     minute: '2-digit',
//     hour12: true
//   });
// };

// export const formatDate = (dateString: string, timeString: string): string => {
//   const options: Intl.DateTimeFormatOptions = {
//     day: '2-digit',
//     month: '2-digit',
//     year: 'numeric',
//   };
//   const date = new Date(dateString);
//   const formattedDate = date.toLocaleDateString('en-GB', options);
  
//   if (!timeString) {
//     return `${formattedDate}`;
//   }

//   return `${formattedDate}`;
// };

// export const isToday = (dateString: string): boolean => {
//   const today = new Date();
//   const date = new Date(dateString);
//   return (
//     today.getFullYear() === date.getFullYear() &&
//     today.getMonth() === date.getMonth() &&
//     today.getDate() === date.getDate()
//   );
// };

// export const convertTextToBulletPoints = (text: string): string => {
//   if (!text) return '';

//   return text
//     .split('\n')
//     .map(line => {
//       // Handle bullet points
//       line = line.replace(/-/g, '•');
//       // Handle new lines
//       line = line.replace(/\/n/g, '<br/>');
//       // Handle URLs
//       if (line.includes('https://') || line.includes('http://')) {
//         const urlPattern = /(https?:\/\/[^\s]+)/g;
//         line = line.replace(urlPattern, '<a href="$1" target="_blank" style="color: blue; text-decoration: underline;">$1</a>');
//       }
//       return line;
//     })
//     .join('<br/>');
// };

// const useEventData = () => {
//   const { apiUrl } = useEnv();
//   const [events, setEvents] = useState<Event[]>([]);
//   const [expiredEvents, setExpiredEvents] = useState<Event[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [myTickets, setMyTickets] = useState<MyTicketEvent[]>([]);
//   const [attendedTickets, setAttendedTickets] = useState<string[]>([]);

//   const fetchEvents = useCallback(async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/api/programs`);
//       const data: RawEvent[] = await response.json();

//       // Parse MemberType from JSON string to array of strings
//       const parsedData = data.map((event) => ({
//         ...event,
//         MemberType: JSON.parse(event.memberType.replace(/\\/g, '')),
//         onlineConferenceText: convertTextToBulletPoints(event.onlineConferenceText || ''), // Convert text
//       }));

//       const runningEvents = parsedData.filter((event) => event.status === 'running');
//       const expiredEvents = parsedData.filter((event) => event.status === 'expired');
//       setEvents(runningEvents);
//       setExpiredEvents(expiredEvents);
//     } catch (err) {
//       setError('Failed to fetch events');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   // Update fetchApprovedEvents function to set the attendedTickets state
//   const fetchApprovedEvents = useCallback(async (email: string) => {
//     try {
//         setLoading(true);
//         const response = await fetch(`${apiUrl}/api/approved-events?email=${email}`);
//         if (response.ok) {
//             const data: MyTicketEvent[] = await response.json();
//             setMyTickets(data);

//             const attended = data.filter(ticket => ticket.eventStatus === 'attend').map(ticket => ticket.ticketId);
//             setAttendedTickets(attended);
//         } else {
//             throw new Error('Failed to fetch approved events');
//         }
//     } catch (error) {
//         console.error('Failed to fetch approved events:', error);
//         setError('Failed to fetch approved events');
//     } finally {
//         setLoading(false);
//     }
//   }, [apiUrl]);

//   const fetchUserApprovedTickets = useCallback(async (email: string, eventName: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/approved-events?email=${email}`);
//       if (response.ok) {
//         const data: MyTicketEvent[] = await response.json();
//         return data.filter(ticket => ticket.eventTitle === eventName);
//       } else {
//         throw new Error('Failed to fetch approved events');
//       }
//     } catch (error) {
//       console.error('Failed to fetch approved events:', error);
//       throw error;
//     }
//   }, [apiUrl]);  
  
//   const checkMembership = async (email: string, eventMemberType: string[]): Promise<{ allowed: boolean, requiredMemberType: string }> => {
//     const response = await fetch(`${apiUrl}/api/check-membership`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ email, eventMemberType })
//     });

//     const data = await response.json();
//     return data;
//   };

//   useEffect(() => {
//     fetchEvents();
//   }, [fetchEvents]);

//   const getSlotMessage = (slot: number) => {
//     if (slot === -1) {
//       return (
//         <div className="bg-green-100 text-green-800 py-2 px-4 w-auto rounded-2xl">
//           Tickets are unlimited!
//         </div>
//       );
//     } else if (slot === 0) {
//       return (
//         <div className="bg-red-100 text-red-800 py-2 px-4 w-auto rounded-2xl">
//           Sold Out!
//         </div>
//       );
//     } else if (slot <= 5) {
//       return (
//         <div className="animate-pulse bg-red-100 text-red-800 py-2 px-4 w-auto rounded-2xl">
//           Only {slot} left! Secure your spot now!
//         </div>
//       );
//     } else if (slot <= 10) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 w-auto rounded-2xl">
//           Just {slot} remaining! Grab your ticket before !
//         </div>
//       );
//     } else if (slot <= 20) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 w-auto rounded-2xl">
//           {slot} slots left! limited availability!
//         </div>
//       );
//     } else if (slot <= 50) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl w-auto flex justify-center">
//           {slot} tickets left! Don't miss out!
//         </div>
//       );
//     } else {
//       return (
//         <div className="bg-green-100 text-green-800 py-2 px-4 w-auto rounded-2xl">
//           {slot} tickets available!
//         </div>
//       );
//     }
//   };
  
//   return { 
//     events, 
//     expiredEvents, 
//     myTickets, 
//     loading, 
//     error, 
//     fetchEvents, 
//     getSlotMessage, 
//     fetchApprovedEvents,
//     fetchUserApprovedTickets,
//     attendedTickets,
//     checkMembership 
//   };
// };

// export default useEventData;

// // update code --> 02 july 2024
// // src/hooks/MiniApp/useEventData.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// interface RawEvent {
//   id: number;
//   ticketId: string;
//   eventTitle: string;
//   name: string;
//   startDate: string;
//   endDate: string;
//   time: string;
//   location: string;
//   onlineConferenceText: string;
//   amount: number;
//   currency: string;
//   points: number;
//   description: string;
//   image: string | null;
//   status: string;
//   redeemNumber: number;
//   slot: number;
//   memberType: string; // This is how it's received from the backend
//   eventStatus: string;
// }

// export interface Event {
//   id: number;
//   ticketId: string;
//   eventTitle: string;
//   name: string;
//   startDate: string;
//   endDate: string;
//   time: string;
//   location: string;
//   onlineConferenceText: string;
//   amount: number;
//   currency: string;
//   points: number;
//   description: string;
//   image: string | null;
//   status: string;
//   redeemNumber: number;
//   slot: number;
//   MemberType: string[]; // This is how we want to use it
//   eventStatus: string;
// }

// export interface MyTicketEvent extends Event {
//   appointmentDate: string;
//   onlineConferenceText: string; // Ensure this line is included
// }

// export const getEventImagePath = (image: string | null, apiUrl: string): string => {
//   return image ? `${apiUrl}/uploads/forEventTicket/${image}` : "https://placehold.co/150x150";
// };

// // Add these helper functions inside useEventData.tsx
// export const getEventStatusStyle = (status: string): string => {
//   switch (status) {
//     case 'attend':
//       return 'text-green-800 bg-green-200 px-2 py-1 rounded';
//     case 'Not Check-in':
//       return 'text-yellow-800 bg-yellow-200 px-2 py-1 rounded';
//     case 'expired':
//       return 'text-red-800 bg-red-200 px-2 py-1 rounded';
//     default:
//       return 'text-gray-800 bg-gray-200 px-2 py-1 rounded';
//   }
// };

// export const convertToShortForm = (memberType: string): string => {
//   switch (memberType) {
//     case "R One Elite Trader":
//       return "Elite";
//     case "R One Master Trader":
//       return "Master";
//     case "R One Certified Trader":
//       return "Certified";
//     case "Trader Hub Member":
//       return "TraderHub";
//     case "R One Dealer":
//       return "Dealer";
//     case "R One Member":
//       return "Member";
//     default:
//       return memberType;
//   }
// };

// export const getTagColor = (shortForm: string): string => {
//   switch (shortForm) {
//     case "Certified":
//       return "text-green-800";
//     case "Dealer":
//       return "text-red-800";
//     case "Elite":
//       return "text-blue-800";
//     case "Master":
//       return "text-purple-800";
//     case "TraderHub":
//       return "text-orange-800";
//     case "Member":
//       return "text-gray-800";
//     default:
//       return "text-yellow-800";
//   }
// };

// export const formatDateWithTime = (dateString: string, timeString: string): string => {
//   const options: Intl.DateTimeFormatOptions = {
//     day: '2-digit',
//     month: '2-digit',
//     year: 'numeric',
//   };
//   const date = new Date(dateString).toLocaleDateString('en-GB', options);
  
//   if (!timeString) {
//     return `${date} on N/A`;
//   }

//   // Convert the time string to 12-hour format with AM/PM
//   const [hours, minutes] = timeString.split(':');
//   const time = new Date();
//   time.setHours(parseInt(hours), parseInt(minutes));
//   const timeOptions: Intl.DateTimeFormatOptions = {
//     hour: '2-digit',
//     minute: '2-digit',
//     hour12: true,
//   };
//   const formattedTime = time.toLocaleTimeString('en-US', timeOptions);
  
//   return `${date} on ${formattedTime}`;
// };

// export const formatTime = (timeString: string): string => {
//   const [hours, minutes] = timeString.split(':');
//   const date = new Date();
//   date.setHours(parseInt(hours, 10));
//   date.setMinutes(parseInt(minutes, 10));
  
//   return date.toLocaleTimeString('en-US', {
//     hour: 'numeric',
//     minute: '2-digit',
//     hour12: true
//   });
// };

// export const isToday = (dateString: string): boolean => {
//   const today = new Date();
//   const date = new Date(dateString);
//   return (
//     today.getFullYear() === date.getFullYear() &&
//     today.getMonth() === date.getMonth() &&
//     today.getDate() === date.getDate()
//   );
// };

// export const convertTextToBulletPoints = (text: string): string => {
//   return text
//     .split('\n')
//     .map(line => {
//       // Handle bullet points
//       line = line.replace(/-/g, '•');
//       // Handle new lines
//       line = line.replace(/\/n/g, '<br/>');
//       // Handle URLs
//       if (line.includes('https://') || line.includes('http://')) {
//         const urlPattern = /(https?:\/\/[^\s]+)/g;
//         line = line.replace(urlPattern, '<a href="$1" target="_blank" style="color: blue; text-decoration: underline;">$1</a>');
//       }
//       return line;
//     })
//     .join('<br/>');
// };

// const useEventData = () => {
//   const { apiUrl } = useEnv();
//   const [events, setEvents] = useState<Event[]>([]);
//   const [expiredEvents, setExpiredEvents] = useState<Event[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [myTickets, setMyTickets] = useState<MyTicketEvent[]>([]);

//   const fetchEvents = useCallback(async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/api/programs`);
//       const data: RawEvent[] = await response.json();

//       // Parse MemberType from JSON string to array of strings
//       const parsedData = data.map((event) => ({
//         ...event,
//         MemberType: JSON.parse(event.memberType.replace(/\\/g, '')),
//         onlineConferenceText: convertTextToBulletPoints(event.onlineConferenceText || ''), // Convert text
//       }));

//       const runningEvents = parsedData.filter((event) => event.status === 'running');
//       const expiredEvents = parsedData.filter((event) => event.status === 'expired');
//       setEvents(runningEvents);
//       setExpiredEvents(expiredEvents);
//     } catch (err) {
//       setError('Failed to fetch events');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const fetchApprovedEvents = useCallback(async (email: string) => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/api/approved-events?email=${email}`);
//       if (response.ok) {
//         const data: MyTicketEvent[] = await response.json();
  
//         const ticketsWithConferenceText = data.map(ticket => ({
//           ...ticket,
//           onlineConferenceText: convertTextToBulletPoints(ticket.onlineConferenceText || ''), // Convert text
//         }));
  
//         setMyTickets(ticketsWithConferenceText);
//       } else {
//         throw new Error('Failed to fetch approved events');
//       }
//     } catch (error) {
//       console.error('Failed to fetch approved events:', error);
//       setError('Failed to fetch approved events');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);   

//   const fetchUserApprovedTickets = useCallback(async (email: string, eventName: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/approved-events?email=${email}`);
//       if (response.ok) {
//         const data: MyTicketEvent[] = await response.json();
//         return data.filter(ticket => ticket.eventTitle === eventName);
//       } else {
//         throw new Error('Failed to fetch approved events');
//       }
//     } catch (error) {
//       console.error('Failed to fetch approved events:', error);
//       throw error;
//     }
//   }, [apiUrl]);  
  
//   const checkMembership = async (email: string, eventMemberType: string[]): Promise<{ allowed: boolean, requiredMemberType: string }> => {
//     const response = await fetch(`${apiUrl}/api/check-membership`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ email, eventMemberType })
//     });

//     const data = await response.json();
//     return data;
//   };

//   useEffect(() => {
//     fetchEvents();
//   }, [fetchEvents]);

//   const getSlotMessage = (slot: number) => {
//     if (slot === -1) {
//       return (
//         <div className="bg-green-100 text-green-800 py-2 px-4 rounded-2xl">
//           Tickets are unlimited!
//         </div>
//       );
//     } else if (slot === 0) {
//       return (
//         <div className="bg-red-100 text-red-800 py-2 px-4 rounded-2xl">
//           Sold Out!
//         </div>
//       );
//     } else if (slot <= 5) {
//       return (
//         <div className="animate-pulse bg-red-100 text-red-800 py-2 px-4 rounded-2xl">
//           Only {slot} left! Secure your spot now!
//         </div>
//       );
//     } else if (slot <= 10) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl">
//           Just {slot} remaining! Grab your ticket before they're gone!
//         </div>
//       );
//     } else if (slot <= 20) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl">
//           {slot} slots left! Hurry, limited availability!
//         </div>
//       );
//     } else if (slot <= 50) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl w-[250px] flex justify-center">
//           {slot} tickets left! Don't miss out!
//         </div>
//       );
//     } else {
//       return (
//         <div className="bg-green-100 text-green-800 py-2 px-4 rounded-2xl">
//           {slot} tickets available!
//         </div>
//       );
//     }
//   };
  
//   return { 
//     events, 
//     expiredEvents, 
//     myTickets, 
//     loading, 
//     error, 
//     fetchEvents, 
//     getSlotMessage, 
//     fetchApprovedEvents,
//     fetchUserApprovedTickets,
//     checkMembership  // Correctly include checkMembership in the return statement
//   };
// };

// export default useEventData;

// // udpate code --> 01 july 2024
// // src/hooks/MiniApp/useEventData.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// interface RawEvent {
//   id: number;
//   ticketId: string;
//   eventTitle: string;
//   name: string;
//   startDate: string;
//   endDate: string;
//   time: string;
//   location: string;
//   onlineConferenceText: string;
//   amount: number;
//   currency: string;
//   points: number;
//   description: string;
//   image: string | null;
//   status: string;
//   redeemNumber: number;
//   slot: number;
//   memberType: string; // This is how it's received from the backend
//   eventStatus: string;
// }

// export interface Event {
//   id: number;
//   ticketId: string;
//   eventTitle: string;
//   name: string;
//   startDate: string;
//   endDate: string;
//   time: string;
//   location: string;
//   onlineConferenceText: string;
//   amount: number;
//   currency: string;
//   points: number;
//   description: string;
//   image: string | null;
//   status: string;
//   redeemNumber: number;
//   slot: number;
//   MemberType: string[]; // This is how we want to use it
//   eventStatus: string;
// }

// export interface MyTicketEvent extends Event {
//   appointmentDate: string;
//   onlineConferenceText: string; // Ensure this line is included
// }

// export const getEventImagePath = (image: string | null, apiUrl: string): string => {
//   return image ? `${apiUrl}/uploads/forEventTicket/${image}` : "https://placehold.co/150x150";
// };

// // Add these helper functions inside useEventData.tsx
// export const getEventStatusStyle = (status: string): string => {
//   switch (status) {
//     case 'attend':
//       return 'text-green-800 bg-green-200 px-2 py-1 rounded';
//     case 'Not Check-in':
//       return 'text-yellow-800 bg-yellow-200 px-2 py-1 rounded';
//     case 'expired':
//       return 'text-red-800 bg-red-200 px-2 py-1 rounded';
//     default:
//       return 'text-gray-800 bg-gray-200 px-2 py-1 rounded';
//   }
// };

// export const convertToShortForm = (memberType: string): string => {
//   switch (memberType) {
//     case "R One Elite Trader":
//       return "Elite";
//     case "R One Master Trader":
//       return "Master";
//     case "R One Certified Trader":
//       return "Certified";
//     case "Trader Hub Member":
//       return "TraderHub";
//     case "R One Dealer":
//       return "Dealer";
//     case "R One Member":
//       return "Member";
//     default:
//       return memberType;
//   }
// };

// export const getTagColor = (shortForm: string): string => {
//   switch (shortForm) {
//     case "Certified":
//       return "text-green-800";
//     case "Dealer":
//       return "text-red-800";
//     case "Elite":
//       return "text-blue-800";
//     case "Master":
//       return "text-purple-800";
//     case "TraderHub":
//       return "text-orange-800";
//     case "Member":
//       return "text-gray-800";
//     default:
//       return "text-yellow-800";
//   }
// };

// export const formatDateWithTime = (dateString: string, timeString: string): string => {
//   const options: Intl.DateTimeFormatOptions = {
//     day: '2-digit',
//     month: '2-digit',
//     year: 'numeric',
//   };
//   const date = new Date(dateString).toLocaleDateString('en-GB', options);
  
//   if (!timeString) {
//     return `${date} on N/A`;
//   }

//   // Convert the time string to 12-hour format with AM/PM
//   const [hours, minutes] = timeString.split(':');
//   const time = new Date();
//   time.setHours(parseInt(hours), parseInt(minutes));
//   const timeOptions: Intl.DateTimeFormatOptions = {
//     hour: '2-digit',
//     minute: '2-digit',
//     hour12: true,
//   };
//   const formattedTime = time.toLocaleTimeString('en-US', timeOptions);
  
//   return `${date} on ${formattedTime}`;
// };

// export const formatTime = (timeString: string): string => {
//   const [hours, minutes] = timeString.split(':');
//   const date = new Date();
//   date.setHours(parseInt(hours, 10));
//   date.setMinutes(parseInt(minutes, 10));
  
//   return date.toLocaleTimeString('en-US', {
//     hour: 'numeric',
//     minute: '2-digit',
//     hour12: true
//   });
// };

// export const isToday = (dateString: string): boolean => {
//   const today = new Date();
//   const date = new Date(dateString);
//   return (
//     today.getFullYear() === date.getFullYear() &&
//     today.getMonth() === date.getMonth() &&
//     today.getDate() === date.getDate()
//   );
// };

// const useEventData = () => {
//   const { apiUrl } = useEnv();
//   const [events, setEvents] = useState<Event[]>([]);
//   const [expiredEvents, setExpiredEvents] = useState<Event[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [myTickets, setMyTickets] = useState<MyTicketEvent[]>([]);

//   const fetchEvents = useCallback(async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/api/programs`);
//       const data: RawEvent[] = await response.json();

//       // Parse MemberType from JSON string to array of strings
//       const parsedData = data.map((event) => ({
//         ...event,
//         MemberType: JSON.parse(event.memberType.replace(/\\/g, '')),
//         onlineConferenceText: event.onlineConferenceText || '', // Ensure onlineConferenceText is included
//       }));

//       const runningEvents = parsedData.filter((event) => event.status === 'running');
//       const expiredEvents = parsedData.filter((event) => event.status === 'expired');
//       setEvents(runningEvents);
//       setExpiredEvents(expiredEvents);
//     } catch (err) {
//       setError('Failed to fetch events');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const fetchApprovedEvents = useCallback(async (email: string) => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/api/approved-events?email=${email}`);
//       if (response.ok) {
//         const data: MyTicketEvent[] = await response.json();
//         setMyTickets(data);
//       } else {
//         throw new Error('Failed to fetch approved events');
//       }
//     } catch (error) {
//       console.error('Failed to fetch approved events:', error);
//       setError('Failed to fetch approved events');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);  

//   const fetchUserApprovedTickets = useCallback(async (email: string, eventName: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/approved-events?email=${email}`);
//       if (response.ok) {
//         const data: MyTicketEvent[] = await response.json();
//         return data.filter(ticket => ticket.eventTitle === eventName);
//       } else {
//         throw new Error('Failed to fetch approved events');
//       }
//     } catch (error) {
//       console.error('Failed to fetch approved events:', error);
//       throw error;
//     }
//   }, [apiUrl]);  
  
//   const checkMembership = async (email: string, eventMemberType: string[]): Promise<{ allowed: boolean, requiredMemberType: string }> => {
//     const response = await fetch(`${apiUrl}/api/check-membership`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ email, eventMemberType })
//     });

//     const data = await response.json();
//     return data;
//   };

//   useEffect(() => {
//     fetchEvents();
//   }, [fetchEvents]);

//   const getSlotMessage = (slot: number) => {
//     if (slot === -1) {
//       return (
//         <div className="bg-green-100 text-green-800 py-2 px-4 rounded-2xl">
//           Tickets are unlimited!
//         </div>
//       );
//     } else if (slot === 0) {
//       return (
//         <div className="bg-red-100 text-red-800 py-2 px-4 rounded-2xl">
//           Sold Out!
//         </div>
//       );
//     } else if (slot <= 5) {
//       return (
//         <div className="animate-pulse bg-red-100 text-red-800 py-2 px-4 rounded-2xl">
//           Only {slot} left! Secure your spot now!
//         </div>
//       );
//     } else if (slot <= 10) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl">
//           Just {slot} remaining! Grab your ticket before they're gone!
//         </div>
//       );
//     } else if (slot <= 20) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl">
//           {slot} slots left! Hurry, limited availability!
//         </div>
//       );
//     } else if (slot <= 50) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl w-[250px] flex justify-center">
//           {slot} tickets left! Don't miss out!
//         </div>
//       );
//     } else {
//       return (
//         <div className="bg-green-100 text-green-800 py-2 px-4 rounded-2xl">
//           {slot} tickets available!
//         </div>
//       );
//     }
//   };
  
//   return { 
//     events, 
//     expiredEvents, 
//     myTickets, 
//     loading, 
//     error, 
//     fetchEvents, 
//     getSlotMessage, 
//     fetchApprovedEvents,
//     fetchUserApprovedTickets,
//     checkMembership  // Correctly include checkMembership in the return statement
//   };
// };

// export default useEventData;


// // udpate code --> 30 june 2024
// // src/hooks/MiniApp/useEventData.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// interface RawEvent {
//   id: number;
//   ticketId: string;
//   eventTitle: string;
//   name: string;
//   startDate: string;
//   endDate: string;
//   time: string;
//   location: string;
//   amount: number;
//   currency: string;
//   points: number;
//   description: string;
//   image: string | null;
//   status: string;
//   redeemNumber: number;
//   slot: number;
//   memberType: string; // This is how it's received from the backend
//   eventStatus: string;
// }

// export interface Event {
//   id: number;
//   ticketId: string;
//   eventTitle: string;
//   name: string;
//   startDate: string;
//   endDate: string;
//   time: string;
//   location: string;
//   amount: number;
//   currency: string;
//   points: number;
//   description: string;
//   image: string | null;
//   status: string;
//   redeemNumber: number;
//   slot: number;
//   MemberType: string[]; // This is how we want to use it
//   eventStatus: string;
// }

// export interface MyTicketEvent extends Event {
//   appointmentDate: string;
// }

// export const getEventImagePath = (image: string | null, apiUrl: string): string => {
//   return image ? `${apiUrl}/uploads/forEventTicket/${image}` : "https://placehold.co/150x150";
// };

// // Add these helper functions inside useEventData.tsx
// export const getEventStatusStyle = (status: string): string => {
//   switch (status) {
//     case 'attend':
//       return 'text-green-800 bg-green-200 px-2 py-1 rounded';
//     case 'Not Check-in':
//       return 'text-yellow-800 bg-yellow-200 px-2 py-1 rounded';
//     case 'expired':
//       return 'text-red-800 bg-red-200 px-2 py-1 rounded';
//     default:
//       return 'text-gray-800 bg-gray-200 px-2 py-1 rounded';
//   }
// };

// export const convertToShortForm = (memberType: string): string => {
//   switch (memberType) {
//     case "R One Elite Trader":
//       return "Elite";
//     case "R One Master Trader":
//       return "Master";
//     case "R One Certified Trader":
//       return "Certified";
//     case "Trader Hub Member":
//       return "TraderHub";
//     case "R One Dealer":
//       return "Dealer";
//     case "R One Member":
//       return "Member";
//     default:
//       return memberType;
//   }
// };

// export const getTagColor = (shortForm: string): string => {
//   switch (shortForm) {
//     case "Certified":
//       return "text-green-800";
//     case "Dealer":
//       return "text-red-800";
//     case "Elite":
//       return "text-blue-800";
//     case "Master":
//       return "text-purple-800";
//     case "TraderHub":
//       return "text-orange-800";
//     case "Member":
//       return "text-gray-800";
//     default:
//       return "text-yellow-800";
//   }
// };

// export const formatDateWithTime = (dateString: string, timeString: string): string => {
//   const options: Intl.DateTimeFormatOptions = {
//     day: '2-digit',
//     month: '2-digit',
//     year: 'numeric',
//   };
//   const date = new Date(dateString).toLocaleDateString('en-GB', options);
  
//   if (!timeString) {
//     return `${date} on N/A`;
//   }

//   // Convert the time string to 12-hour format with AM/PM
//   const [hours, minutes] = timeString.split(':');
//   const time = new Date();
//   time.setHours(parseInt(hours), parseInt(minutes));
//   const timeOptions: Intl.DateTimeFormatOptions = {
//     hour: '2-digit',
//     minute: '2-digit',
//     hour12: true,
//   };
//   const formattedTime = time.toLocaleTimeString('en-US', timeOptions);
  
//   return `${date} on ${formattedTime}`;
// };

// export const formatTime = (timeString: string): string => {
//   const [hours, minutes] = timeString.split(':');
//   const date = new Date();
//   date.setHours(parseInt(hours, 10));
//   date.setMinutes(parseInt(minutes, 10));
  
//   return date.toLocaleTimeString('en-US', {
//     hour: 'numeric',
//     minute: '2-digit',
//     hour12: true
//   });
// };

// const useEventData = () => {
//   const { apiUrl } = useEnv();
//   const [events, setEvents] = useState<Event[]>([]);
//   const [expiredEvents, setExpiredEvents] = useState<Event[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [myTickets, setMyTickets] = useState<MyTicketEvent[]>([]);

//   const fetchEvents = useCallback(async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/api/programs`);
//       const data: RawEvent[] = await response.json();

//       // Parse MemberType from JSON string to array of strings
//       const parsedData = data.map((event) => ({
//         ...event,
//         MemberType: JSON.parse(event.memberType.replace(/\\/g, ''))
//       }));

//       const runningEvents = parsedData.filter((event) => event.status === 'running');
//       const expiredEvents = parsedData.filter((event) => event.status === 'expired');
//       setEvents(runningEvents);
//       setExpiredEvents(expiredEvents);
//     } catch (err) {
//       setError('Failed to fetch events');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const fetchApprovedEvents = useCallback(async (email: string) => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/api/approved-events?email=${email}`);
//       if (response.ok) {
//         const data: MyTicketEvent[] = await response.json();
//         setMyTickets(data);
//       } else {
//         throw new Error('Failed to fetch approved events');
//       }
//     } catch (error) {
//       console.error('Failed to fetch approved events:', error);
//       setError('Failed to fetch approved events');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);  

//   useEffect(() => {
//     fetchEvents();
//   }, [fetchEvents]);

//   const getSlotMessage = (slot: number) => {
//     if (slot === -1) {
//       return (
//         <div className="bg-green-100 text-green-800 py-2 px-4 rounded-2xl">
//           Tickets are unlimited!
//         </div>
//       );
//     } else if (slot === 0) {
//       return (
//         <div className="bg-red-100 text-red-800 py-2 px-4 rounded-2xl">
//           Sold Out!
//         </div>
//       );
//     } else if (slot <= 5) {
//       return (
//         <div className="animate-pulse bg-red-100 text-red-800 py-2 px-4 rounded-2xl">
//           Only {slot} left! Secure your spot now!
//         </div>
//       );
//     } else if (slot <= 10) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl">
//           Just {slot} remaining! Grab your ticket before they're gone!
//         </div>
//       );
//     } else if (slot <= 20) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl">
//           {slot} slots left! Hurry, limited availability!
//         </div>
//       );
//     } else if (slot <= 50) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl w-[250px] flex justify-center">
//           {slot} tickets left! Don't miss out!
//         </div>
//       );
//     } else {
//       return (
//         <div className="bg-green-100 text-green-800 py-2 px-4 rounded-2xl">
//           {slot} tickets available!
//         </div>
//       );
//     }
//   };
  
//   return { 
//     events, 
//     expiredEvents, 
//     myTickets, 
//     loading, 
//     error, 
//     fetchEvents, 
//     getSlotMessage, 
//     fetchApprovedEvents 
//   };
// };

// export default useEventData;


// // update code --> 27 june 2024
// // src/hooks/MiniApp/useEventData.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Event {
//   id: number;
//   ticketId: string;
//   eventTitle: string;
//   name: string;
//   startDate: string;
//   endDate: string;
//   time: string;
//   location: string;
//   amount: number;
//   currency: string;
//   points: number;
//   description: string;
//   image: string | null;
//   status: string;
//   redeemNumber: number;
//   slot: number;
//   MemberType?: string;
//   eventStatus: string; 
// }
// export interface MyTicketEvent extends Event {
//   appointmentDate: string;
// }

// const useEventData = () => {
//   const { apiUrl } = useEnv();
//   const [events, setEvents] = useState<Event[]>([]);
//   const [expiredEvents, setExpiredEvents] = useState<Event[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [myTickets, setMyTickets] = useState<MyTicketEvent[]>([]);

//   const fetchEvents = useCallback(async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/api/programs`);
//       const data = await response.json();
//       const runningEvents = data.filter((event: Event) => event.status === 'running');
//       const expiredEvents = data.filter((event: Event) => event.status === 'expired');
//       setEvents(runningEvents);
//       setExpiredEvents(expiredEvents);
//     } catch (err) {
//       setError('Failed to fetch events');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);  

//   const fetchApprovedEvents = useCallback(async (email: string) => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/api/approved-events?email=${email}`);
//       if (response.ok) {
//         const data = await response.json();
//         setMyTickets(data);
//       } else {
//         throw new Error('Failed to fetch approved events');
//       }
//     } catch (error) {
//       console.error('Failed to fetch approved events:', error);
//       setError('Failed to fetch approved events');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl])

//   useEffect(() => {
//     fetchEvents();
//   }, [fetchEvents]);

//   const getSlotMessage = (slot: number) => {
//     if (slot === -1) {
//       return (
//         <div className="bg-green-100 text-green-800 py-2 px-4 rounded-2xl">
//           Tickets are unlimited!
//         </div>
//       );
//     } else if (slot === 0) {
//       return (
//         <div className="bg-red-100 text-red-800 py-2 px-4 rounded-2xl">
//           Sold Out!
//         </div>
//       );
//     } else if (slot <= 5) {
//       return (
//         <div className="animate-pulse bg-red-100 text-red-800 py-2 px-4 rounded-2xl">
//           Only {slot} left! Secure your spot now!
//         </div>
//       );
//     } else if (slot <= 10) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl">
//           Just {slot} remaining! Grab your ticket before they're gone!
//         </div>
//       );
//     } else if (slot <= 20) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl">
//           {slot} slots left! Hurry, limited availability!
//         </div>
//       );
//     } else if (slot <= 50) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl w-[250px] flex justify-center">
//           {slot} tickets left! Don't miss out!
//         </div>
//       );
//     } else {
//       return (
//         <div className="bg-green-100 text-green-800 py-2 px-4 rounded-2xl">
//           {slot} tickets available!
//         </div>
//       );
//     }
//   };
  
//   return { events, expiredEvents, myTickets, loading, error, fetchEvents, getSlotMessage, fetchApprovedEvents };
// };

// export default useEventData;


// // udpate code --> 24 june 2024
// // src/hooks/MiniApp/useEventData.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import { useTheme } from '../../context/ThemeContext';

// export interface Event {
//   id: number;
//   name: string;
//   startDate: string;
//   endDate: string;
//   location: string;
//   amount: number;
//   points: number;
//   description: string;
//   image: string | null;
//   status: string;
//   redeemNumber: number;
//   slot: number;
// }

// const useEventData = () => {
//   const { apiUrl } = useEnv();
//   const { theme } = useTheme();
//   const [events, setEvents] = useState<Event[]>([]);
//   const [expiredEvents, setExpiredEvents] = useState<Event[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);

//   const fetchEvents = useCallback(async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`${apiUrl}/api/programs`);
//       const data = await response.json();
//       const runningEvents = data.filter((event: Event) => event.status === 'running');
//       const expiredEvents = data.filter((event: Event) => event.status === 'expired');
//       setEvents(runningEvents);
//       setExpiredEvents(expiredEvents);
//     } catch (err) {
//       setError('Failed to fetch events');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     fetchEvents();
//   }, [fetchEvents]);

//   const getSlotMessage = (slot: number) => {
//     if (slot === -1) {
//       return (
//         <div className=" bg-green-100 text-green-800 py-2 px-4 rounded-2xl">
//           Ticket is unlimited
//         </div>
//       );
//     } else if (slot === 0) {
//       return (
//         <div className=" bg-red-100 text-red-800 py-2 px-4 rounded-2xl">
//           Not Available
//         </div>
//       );
//     } else if (slot <= 5) {
//       return (
//         <div className="animate-pulse bg-red-100 text-red-800 py-2 px-4 rounded-2xl">
//           Left {slot} (Final warning!)
//         </div>
//       );
//     } else if (slot <= 10) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl">
//           Left {slot} (Warning: Low slots!)
//         </div>
//       );
//     } else if (slot <= 20) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl">
//           Left: {slot} (Warning: Limited slots!)
//         </div>
//       );
//     } else if (slot <= 50) {
//       return (
//         <div className="animate-pulse bg-yellow-100 text-yellow-800 py-2 px-4 rounded-2xl">
//           Left: {slot} (Warning: Slots are filling up!)
//         </div>
//       );
//     } else {
//       return (
//         <div className="text-gray-800 py-2 px-4 rounded-2xl" 
//           style={{ backgroundColor: theme.primaryColor, color: theme.text2Color }}>
//           Left {slot}
//         </div>
//       );
//     }
//   };

//   return { events, expiredEvents, loading, error, fetchEvents, getSlotMessage };
// };

// export default useEventData;




// // updte code --> 22 june 2024
// // src/hooks/MiniApp/useEventData.tsx

// import { useState, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface Event {
//   id: number;
//   name: string;
//   startDate: string;
//   endDate: string;
//   location: string;
//   amount: number;
//   points: number;
//   description: string;
//   image: string | null;
//   status: string;
//   redeemNumber: number;
// }

// const useEventData = () => {
//   const { apiUrl } = useEnv();
//   const [events, setEvents] = useState<Event[]>([]);
//   const [expiredEvents, setExpiredEvents] = useState<Event[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchEvents = async () => {
//       try {
//         const response = await fetch(`${apiUrl}/api/programs`);
//         const data = await response.json();
//         const runningEvents = data.filter((event: Event) => event.status === 'running');
//         const expiredEvents = data.filter((event: Event) => event.status === 'expired');
//         setEvents(runningEvents);
//         setExpiredEvents(expiredEvents);
//       } catch (err) {
//         setError('Failed to fetch events');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchEvents();
//   }, [apiUrl]);

//   return { events, expiredEvents, loading, error };
// };

// export default useEventData;


// update code --> 21 june 2024
// import React from 'react';

// interface EventDetailProps {
//   event: {
//     title: string;
//     expiry: string;
//     description: string;
//     date: string;
//     registrationExpiry: string;
//     createdBy: string;
//   };
//   onBack: () => void;
// }

// const EventDetail: React.FC<EventDetailProps> = ({ event, onBack }) => {
//   return (
//     <div className="bg-white p-4 rounded-lg shadow-lg max-h-[80vh] overflow-y-auto"> {/* Ensure consistent height */}
//       <button 
//         className="bg-blue-500 text-white py-1 px-4 rounded-full mb-4"
//         onClick={onBack}
//       >
//         Back
//       </button>
//       <div className="flex flex-col items-center">
//         <img src="https://placehold.co/300x200" alt={event.title} className="w-full h-48 object-cover rounded-lg mb-4" />
//         <div className="text-lg font-bold mb-2">{event.title}</div>
//         <div className="text-sm text-zinc-600 mb-2">Event Date: {event.date}</div>
//         <div className="text-sm text-zinc-600 mb-2">Registration Expiry: {event.registrationExpiry}</div>
//         <div className="text-sm text-zinc-600 mb-2">Expiry: {event.expiry}</div>
//         <div className="text-sm text-zinc-600 mb-2">Created By: {event.createdBy}</div>
//         <div className="text-xs text-zinc-500 mb-4">{event.description}</div>
//         <button className="mt-4 bg-green-500 text-white py-2 px-4 rounded-full">Register</button>
//       </div>
//     </div>
//   );
// };

// export default EventDetail;