// src/components/login/ResetPasword.tsx

import React, { useState } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { useTheme } from '../../context/ThemeContext';
import { useReset } from '../../hooks/Login/useReset';
import ResetModal from './ResetModal';
import { motion } from 'framer-motion';

interface ResetPasswordProps {
  visible: boolean;
  onClose: () => void;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ visible, onClose }) => {
  const [email, setEmail] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const { theme } = useTheme();
  const { resetPassword, loading, error } = useReset();

  const handleResetPassword = async () => {
    const result = await resetPassword(email);

    if (result) {
      setModalVisible(true);
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    onClose();
  };

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center ${visible ? 'bg-gray-900 bg-opacity-75 z-50' : 'hidden'}`}
        onClick={onClose}
      >
        <motion.div
          className="bg-white rounded-lg shadow-lg p-6 w-96 relative"
          onClick={(e) => e.stopPropagation()}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: visible ? 1 : 0, scale: visible ? 1 : 0.8 }}
          transition={{ duration: 0.3 }}
        >
          <div className="text-center mb-4">
            <h4 className="text-lg font-semibold" style={{ color: theme.textColor }}>Reset Your Password</h4>
            <p className="text-gray-600" style={{ fontFamily: theme.fontFamily }}>Enter your email to reset your password</p>
          </div>
          <div>
            <div className="mb-4">
              <div className="flex items-center border rounded px-3 py-2">
                <FaEnvelope className="text-gray-500 mr-3" />
                <input
                  type="email"
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full py-2 focus:outline-none"
                  style={{ fontFamily: theme.fontFamily }}
                />
              </div>
            </div>
            <button
              type="button"
              onClick={handleResetPassword}
              className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
              style={{
                backgroundColor: theme.primaryColor,
                borderColor: theme.primaryColor,
                color: theme.text2Color
              }}
              disabled={loading}
            >
              Reset Password
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </div>
        </motion.div>
      </div>
      <ResetModal visible={modalVisible} onClose={handleCloseModal} isValidEmail={true} />
    </>
  );
};

export default ResetPassword;



// // update code --> 06 June 2024
// // src/components/login/ResetPassword.tsx

// import React, { useState } from 'react';
// // import react icon
// import { FaEnvelope } from 'react-icons/fa';
// // import file from source
// import { useDummy } from '../../context/dummy';
// import { useTheme } from '../../context/ThemeContext';
// // import ResetModal component
// import ResetModal from '../login/ResetModal';

// interface ResetPasswordProps {
//   visible: boolean;
//   onClose: () => void;
// }

// const ResetPassword: React.FC<ResetPasswordProps> = ({ visible, onClose }) => {
//   // State for email input and modal visibility
//   const [email, setEmail] = useState('');
//   const [modalVisible, setModalVisible] = useState(false);
//   const [isValidEmail, setIsValidEmail] = useState(false);
//   const [buttonDisabled, setButtonDisabled] = useState(false);
//   // Access dummy context for email verification
//   const { verifyEmail } = useDummy();
//   // Access theme context
//   const { theme } = useTheme();

//   const handleResetPassword = () => {
//     if (verifyEmail(email)) {
//       // Show modal on successful email verification
//       setIsValidEmail(true);
//       setButtonDisabled(true);
//       setModalVisible(true);
//     } else {
//       // Show modal for invalid email
//       setIsValidEmail(false);
//       setModalVisible(true);
//     }
//   };

//   const handleCloseModal = () => {
//     // Re-enable the button when modal is closed
//     setModalVisible(false);
//     setButtonDisabled(false);
//     onClose();
//   };

//   return (
//     <>
//       <div
//         // Conditionally apply classes based on the visibility prop
//         className={`fixed inset-0 flex items-center justify-center ${visible ? 'bg-gray-900 bg-opacity-75 z-50' : 'hidden'}`}
//         onClick={onClose}
//       >
//         <div
//           className="bg-white rounded-lg shadow-lg p-6 w-96 relative"
//           onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
//         >
//           <div className="text-center mb-4">
//             <h4 className="text-lg font-semibold" style={{ color: theme.textColor }}>Reset Your Password</h4>
//             <p className="text-gray-600" style={{ fontFamily: theme.fontFamily }}>Enter your email to reset your password</p>
//           </div>
//           <div>
//             <div className="mb-4">
//               <div className="flex items-center border rounded px-3 py-2">
//                 <FaEnvelope className="text-gray-500 mr-3" />
//                 <input
//                   type="email"
//                   placeholder="Your email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   className="w-full py-2 focus:outline-none"
//                   style={{ fontFamily: theme.fontFamily }}
//                 />
//               </div>
//             </div>
//             <button
//               type="button"
//               onClick={handleResetPassword}
//               className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
//               style={{
//                 backgroundColor: theme.primaryColor,
//                 borderColor: theme.primaryColor,
//                 color: theme.text2Color
//               }}
//               disabled={buttonDisabled} // Disable button conditionally
//             >
//               Reset Password
//             </button>
//           </div>
//         </div>
//       </div>
//       <ResetModal visible={modalVisible} onClose={handleCloseModal} isValidEmail={isValidEmail} />
//     </>
//   );
// };

// export default ResetPassword;



// // update code --> 30 May 2024
// // src/components/login/ResetPassword.tsx

// // import react components
// import React, { useState } from 'react';

// // import react icon
// import { FaEnvelope } from 'react-icons/fa';

// // import file from source
// import { useDummy } from '../../context/dummy';
// import { useTheme } from '../../context/ThemeContext';

// interface ResetPasswordProps {
//   visible: boolean;
//   onClose: () => void;
// }

// const ResetPassword: React.FC<ResetPasswordProps> = ({ visible, onClose }) => {
//   const [email, setEmail] = useState('');
//   const { verifyEmail } = useDummy();
//   const { theme } = useTheme();

//   const handleResetPassword = () => {
//     if (verifyEmail(email)) {
//       // Add reset password logic here
//       onClose();
//     } else {
//       // Handle invalid email case
//       alert('Invalid email address.');
//     }
//   };

//   return (
//     <div className={`fixed inset-0 flex items-center justify-center ${visible ? 'bg-gray-900 bg-opacity-75 z-50' : 'hidden'}`} onClick={onClose}>
//       <div className="bg-white rounded-lg shadow-lg p-6 w-96 relative" onClick={(e) => e.stopPropagation()}>
//         <div className="text-center mb-4">
//           <h4 className="text-lg font-semibold" style={{ color: theme.textColor }}>Reset Your Password</h4>
//           <p className="text-gray-600" style={{ fontFamily: theme.fontFamily }}>Enter your email to reset your password</p>
//         </div>
//         <div>
//           <div className="mb-4">
//             <div className="flex items-center border rounded px-3 py-2">
//               <FaEnvelope className="text-gray-500 mr-3" />
//               <input
//                 type="email"
//                 placeholder="Your email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 className="w-full py-2 focus:outline-none"
//                 style={{ fontFamily: theme.fontFamily }}
//               />
//             </div>
//           </div>
//           <button
//             type="button"
//             onClick={handleResetPassword}
//             className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
//             style={{
//               backgroundColor: theme.primaryColor,
//               borderColor: theme.primaryColor,
//               color: theme.text2Color
//             }}
//           >
//             Reset Password
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ResetPassword;
