// src/hooks/AdminDashboard/useSubNotificationFilter.tsx

import { useState, useCallback } from 'react';
import { Notification } from './useSubNotification';

export const useNotificationFilter = () => {
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [orderIdFilter, setOrderIdFilter] = useState<'sub' | 'hub' | ''>('');

  const resetFilters = () => {
    setStatusFilter('');
    setOrderIdFilter('');
  };

  const filterNotifications = useCallback(
    (notifications: Notification[]) => {
      return notifications.filter((notification) => {
        let matchesStatus = true;
        let matchesOrderId = true;

        if (statusFilter) {
          matchesStatus =
            (statusFilter === 'Pending' && ['Pending'].includes(notification.accountStatus)) ||
            (statusFilter === 'Active' && ['Active'].includes(notification.accountStatus)) ||
            (statusFilter === 'Deactive' && ['Deactive'].includes(notification.accountStatus)) ||
            (statusFilter === 'Expire' && ['Expire'].includes(notification.accountStatus));
        }

        if (orderIdFilter) {
          matchesOrderId =
            (orderIdFilter === 'sub' && notification.orderID.startsWith('sub-')) ||
            (orderIdFilter === 'hub' && notification.orderID.startsWith('hub-'));
        }

        return matchesStatus && matchesOrderId;
      });
    },
    [statusFilter, orderIdFilter]
  );

  return {
    statusFilter,
    setStatusFilter,
    orderIdFilter,
    setOrderIdFilter,
    filterNotifications,
    resetFilters, 
  };
};

