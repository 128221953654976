// src/components/admin/SubscriptionSetting/TrialSetting.tsx

import React, { useState, useEffect } from 'react';
import { TbEdit, TbTrash } from "react-icons/tb";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { RiCloseCircleLine } from "react-icons/ri";
import useTrialSetting, { Trial } from '../../../hooks/AdminDashboard/useTrialSetting';
import SpecifyGroupModal from '../../admin/SubscriptionSetting/SubSettingGroupSet';

interface TrialSettingProps {
  selectedPlan: any;
  setSelectedPlan: React.Dispatch<React.SetStateAction<any>>;
  isPlanEditMode: boolean;
}

const TrialSetting: React.FC<TrialSettingProps> = ({ selectedPlan }) => {
  // trials setting section
  const { saveTrial, deleteTrial, fetchTrials, trials } = useTrialSetting();
  const [isTrialEnabled, setIsTrialEnabled] = useState(false);
  const [selectedTrial, setSelectedTrial] = useState<Trial | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [trialPeriod, setTrialPeriod] = useState(selectedPlan.planTrialPeriod);
  const [trialMembersType, setTrialMembersType] = useState<string[]>(selectedPlan.planTrialMemberType || []);
  const [isCustomTrialPeriod, setIsCustomTrialPeriod] = useState(false);
  const [customTrialDays, setCustomTrialDays] = useState('');
  const [trialName, setTrialName] = useState('');
  const [trialStatus, setTrialStatus] = useState('');
  const [trialRequirements, setTrialRequirements] = useState<string[]>(['']);
  // trial member setting group modal
  const [isSpecifyGroupModalOpen, setIsSpecifyGroupModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [isEmailListModalOpen, setIsEmailListModalOpen] = useState(false);
  const [groupName, setGroupName] = useState<string>('');
  const [customGroups, setCustomGroups] = useState<string[]>([]);

  useEffect(() => {
    fetchTrials();
  }, [fetchTrials]);

  useEffect(() => {
  }, [trials, selectedPlan]);

  useEffect(() => {
    setIsTrialEnabled(false);
    setTrialPeriod('Select a period');
    setTrialMembersType([]);
    setSelectedTrial(null);
  }, [selectedPlan]);

  useEffect(() => {
    if (isSpecifyGroupModalOpen && selectedTrial?.trialGroupName) {
      setGroupName(selectedTrial.trialGroupName);
      setSelectedEmails(selectedTrial.trialGroupMember || []);
    }
  }, [isSpecifyGroupModalOpen, selectedTrial]);  

  const memberTypes = [
    "R One Guest", 
    "R One Member", 
    "R One Dealer", 
    "R One Elite Trader", 
    "R One Master Trader", 
    "R One Certified Trader", 
    "R One Trader Hub",
  ];

  const handleMemberTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    setTrialMembersType((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((type) => type !== value);
      }
    });
  };

  const handleShowSettings = () => {
    setSelectedTrial(null);
    setTrialName('');
    setTrialPeriod('Select a period');
    setTrialMembersType([]);
    setIsCustomTrialPeriod(false);
    setCustomTrialDays('');
    setTrialStatus('');
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const generateTrialID = () => {
    return 'TRIAL-' + Math.random().toString(36).substr(2, 9).toUpperCase();
  };

  const handleSaveTrial = async () => {
    const trialData = {
      id: selectedTrial ? selectedTrial.id : undefined, 
      planID: selectedPlan.id,
      trialName,
      trialDays: trialPeriod === 'monthly' ? '30' : trialPeriod === 'quarterly' ? '90' : trialPeriod === 'yearly' ? '365' : customTrialDays,
      trialMembersType,
      trialRequirement: trialRequirements,
      trialID: selectedTrial ? selectedTrial.trialID : generateTrialID(),
      trialStatus: trialStatus || 'Running',
      trialGroupName: selectedTrial?.trialGroupName || groupName,
      trialGroupMember: selectedTrial?.trialGroupMember || selectedEmails,
    };
    try {
      await saveTrial(trialData);
      handleCloseModal();
      fetchTrials();
    } catch (error) {
      console.error('Error saving trial:', error);
    }
  };

  const handleModifyTrial = (trial: Trial) => {
    setSelectedTrial(trial);
    setTrialName(trial.trialName);
    setTrialPeriod(trial.trialDays);
    setTrialMembersType(trial.trialMembersType);
    setTrialStatus(trial.trialStatus);
    setCustomGroups(trial.trialMembersType.filter(type => !memberTypes.includes(type)));
    setIsModalOpen(true);
  };
  
// Add function to handle requirement changes
const handleRequirementChange = (index: number, value: string) => {
  const newRequirements = [...trialRequirements];
  newRequirements[index] = value;
  setTrialRequirements(newRequirements);
};

const handleCreateGroup = (emails: string[]) => {
  // Add the new group name only to customGroups, not to trialMembersType directly
  if (!customGroups.includes(groupName)) {
    setCustomGroups((prevGroups) => [...prevGroups, groupName]);
  }
  setSelectedEmails(emails);

  setSelectedTrial((prev: Trial | null) => {
    if (prev) {
      return {
        ...prev,
        trialGroupName: groupName,
        trialGroupMember: emails,
      };
    }
    return prev;
  });

  setIsSpecifyGroupModalOpen(false);
};


// // Function to handle opening the modal
// const handleCreateGroup = (emails: string[]) => {
//   setSelectedEmails(emails);
//   setCustomGroups((prevGroups) => [...prevGroups, groupName]);
//   setTrialMembersType((prevMembers) => [...prevMembers, groupName]);
//   setSelectedTrial((prev: Trial | null) => {
//     if (prev) {
//       return {
//         ...prev,
//         trialGroupName: groupName,
//         trialGroupMember: emails,
//       };
//     }
//     return prev;
//   });
//   setCustomGroups((prevGroups) => [...prevGroups, groupName]);
//   setIsSpecifyGroupModalOpen(false);
// };

const handleOpenSpecifyGroupModal = () => {
  setIsSpecifyGroupModalOpen(true);
};

const handleModifyGroup = (groupName: string) => {
  const trial = trials.find(trial => trial.trialGroupName === groupName);
  if (trial) {
    setSelectedTrial(trial);
    setGroupName(groupName);
    setSelectedEmails(trial.trialGroupMember || []);
    setIsSpecifyGroupModalOpen(true);
  }
};

const handleRemoveGroup = (groupName: string) => {
  setCustomGroups((prevGroups) => prevGroups.filter((group) => group !== groupName));
  setTrialMembersType((prevMembers) => prevMembers.filter((type) => type !== groupName));
  setSelectedTrial((prev: Trial | null) => {
    if (prev) {
      return {
        ...prev,
        trialMembersType: prev.trialMembersType.filter(type => type !== groupName),
        trialGroupName: prev.trialGroupName === groupName ? '' : prev.trialGroupName,
        trialGroupMember: prev.trialGroupName === groupName ? [] : prev.trialGroupMember,
      };
    }
    return prev;
  });
};

const handleCustomGroupClick = (group: string) => {
  if (selectedTrial?.trialGroupName === group) {
    // Fetch the emails from the selected trial's group
    setSelectedEmails(
      selectedTrial?.trialGroupMember || []
    );

    // Open the modal
    setIsEmailListModalOpen(true);
  }
};


// Add function to add a new requirement input field
const addRequirementField = () => {
  setTrialRequirements([...trialRequirements, '']);
};

  const filteredTrials = trials.filter(trial => {
    return trial.planID.toString() === selectedPlan.id.toString();
  });
  
  return (
    <div>
      {/* Email List Custom Group */}
      {isEmailListModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-1/2 space-y-2">
            <button className="float-right text-2xl" onClick={() => setIsEmailListModalOpen(false)}><RiCloseCircleLine /></button>
            <h2 className="text-xl font-semibold mb-4">
              {selectedTrial?.trialGroupName} - Email List
            </h2>
            <div className="border-2 border-cyan-600 rounded p-4">
              <ul>
                {selectedEmails.map((email, index) => (
                  <li key={index} className="text-gray-700 text-center overflow-y-auto">{email}</li>
                ))}
              </ul>
            </div>
            <div className="mt-4 text-gray-700 font-semibold">
              Total Emails: {selectedEmails.length}
            </div>
          </div>
        </div>
      )}

      {/* Trial Setting Header */}
      <div className="flex justify-normal items-center cursor-pointer">
        <h2 className="text-xl font-semibold mb-4">Trial Setting</h2>
        <span className="text-rose-600 mb-4 mx-2 text-sm"> ( click here to expand & collpese trial setting )</span>
        <div 
          className="mb-4"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <FaMinus size={24} /> : <FaPlus size={24} />}
        </div>
      </div>

      {/* Trial Set Review */}
      {isExpanded && (
        <>
          <div className="flex items-center">
            {/* Trial Set Apply */}
            <label className="block mb-2 w-1/3">Plan Trial Apply:</label>
            <div className="w-2/3 flex items-center justify-between text-sm">
              <div className="flex items-cente">
                <input
                  type="checkbox"
                  checked={isTrialEnabled}
                  onChange={(e) => setIsTrialEnabled(e.target.checked)}
                  className="mr-2 w-4 h-4 accent-cyan-800"
                />
                <label className="text-black">{isTrialEnabled ? 'Enabled' : 'Disabled'}</label>
              </div>
              <div className="flex justify-end items-center text-xs">
                <button
                  onClick={handleShowSettings}
                  className={`ml-4 px-4 py-2 rounded-full ${isTrialEnabled ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-slate-400'}`}
                  disabled={!isTrialEnabled}
                >
                  + Create Set
                </button>
                <button
                  onClick={() => selectedTrial && deleteTrial(selectedTrial.id)}
                  className={`ml-2 px-4 py-2 rounded-full ${isTrialEnabled ? 'bg-rose-600 text-white' : 'bg-gray-200 text-slate-400'}`}
                  disabled={!isTrialEnabled}
                >
                  Delete Set
                </button>
              </div>
            </div>
          </div>

          {/* Trial Set Selector */}
          <div className="flex items-center mt-2">
            <label className="w-1/3 font-semibold">Select a Trial:</label>
            <div className="w-2/3">
              {filteredTrials.length > 0 ? (
                <div className="grid grid-cols-3 gap-2">
                  {filteredTrials.map(trial => (
                    <div
                      key={trial.id}
                      className={`p-2 border rounded cursor-pointer ${selectedTrial?.id === trial.id ? 'border-cyan-800 border-2' : 'text-gray-300'}`}
                      onClick={() => isTrialEnabled && setSelectedTrial(trial)} // Enable selection only if isTrialEnabled is true
                      style={{ backgroundColor: isTrialEnabled ? 'white' : '#EEEDEB' }}
                    >
                      <div className="flex justify-between items-center">
                        <span>{trial.trialName}</span>
                        <TbEdit
                          className={`text-cyan-800 cursor-pointer ${!isTrialEnabled ? 'cursor-not-allowed text-gray-300' : 'text-cyan-800'}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (isTrialEnabled) handleModifyTrial(trial);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="p-2 border-2 border-dashed border-gray-300 rounded text-center w-[200px]">
                  No trials available
                </div>
              )}
            </div>
          </div>
          
          {/* Trial Set Preview */}
          <div className="flex mt-2" style={{ color: isTrialEnabled ? 'black' : 'lightgray' }}>
            <div className="w-1/3">
              <div className="mt-2 ">
                <p>Trial Name:</p>
                <p>Trial Period:</p>
                <p>Allow Trial Members Type:</p>
                <p>Trial Requirenment</p>
                <p>Trial Status:</p>
              </div>
            </div>
            <div className="w-2/3">
              <div className="mt-2">
                <p>{selectedTrial ? selectedTrial.trialName : 'N/A'}</p>
                <p>{selectedTrial ? `${selectedTrial.trialDays} Days` : 'N/A'}</p>
                <div>
                  {selectedTrial ? (
                    <>
                      {selectedTrial.trialMembersType.map((type) => (
                        <div key={type}>
                          <span
                            className="cursor-pointer text-cyan-800"
                            onClick={() => handleCustomGroupClick(type)}
                          >
                            {type}
                          </span>
                        </div>
                      ))}
                    </>
                  ) : (
                    'N/A'
                  )}
                </div>
                <p>{selectedTrial ? selectedTrial.trialRequirement.join(','): 'N/A'}</p>
                <p style={{ color: selectedTrial?.trialStatus === 'Running' ? 'green' : 'red' }}>
                  {selectedTrial ? selectedTrial.trialStatus : 'N/A'}
                </p>
              </div>
            </div>
          </div>
          
          {/* Trial Set create or modify */}
          {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded shadow-lg w-1/2 space-y-2 ">
                <button className="float-right" onClick={handleCloseModal}>X</button>
                <h2 className="text-xl font-semibold mb-4">Trial Settings</h2>
                
                {/* Trial Name */}
                <div className="flex items-center">
                  <label className="block mb-2 w-1/3">Trial Name:</label>
                  <input
                    type="text"
                    className="w-2/3 p-2 border rounded"
                    value={trialName}
                    onChange={(e) => setTrialName(e.target.value)}
                  />
                </div>

                {/* Trial Period */}
                <div className="flex items-center">
                  <label className="block mb-2 w-1/3">Trial Period:</label>
                  <div className="w-2/3 flex items-center">
                    <select
                      className="p-2 border rounded w-full"
                      value={trialPeriod}
                      onChange={(e) => {
                        setTrialPeriod(e.target.value);
                        setIsCustomTrialPeriod(e.target.value === 'Specify Period');
                      }}
                    >
                      <option value="">Select a plan</option>
                      <option value="monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="yearly">Yearly</option>
                      <option value="Specify Period">Specify Period</option>
                    </select>
                    {isCustomTrialPeriod && (
                      <div className="ml-4 flex items-center">
                        <input
                          type="text"
                          className="p-2 border rounded"
                          value={customTrialDays}
                          onChange={(e) => setCustomTrialDays(e.target.value)}
                          placeholder="Enter days"
                        />
                        <span className="ml-2 text-black">days</span>
                      </div>
                    )}
                  </div>
                </div>

                {/* Trial Members Type */}
                <div className="flex my-2">
                  <label className="block mt-2 w-1/3">Trial Members Type:</label>
                  <div className="flex flex-col my-2">
                    {memberTypes.map((type) => (
                      <label key={type} className="flex items-center mr-4 mb-2">
                        <input
                          type="checkbox"
                          value={type}
                          onChange={handleMemberTypeChange}
                          className="mr-2 w-4 h-4 accent-cyan-800"
                          checked={trialMembersType.includes(type)}
                        />
                        <span className="text-black">
                          {type}
                        </span>
                      </label>
                    ))}

                    {/* Display custom groups */}
                    {customGroups.map((group) => (
                      <div key={group} className="flex items-center justify-between">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            value={group}
                            onChange={handleMemberTypeChange}
                            className="mr-2 w-4 h-4 accent-cyan-800"
                            checked={trialMembersType.includes(group)}
                          />
                          <span className="text-black">
                            {group}
                          </span>
                        </label>
                        <div className="flex items-center">
                          <TbEdit
                            className="text-cyan-800 cursor-pointer ml-2"
                            onClick={() => handleModifyGroup(group)}
                          />
                          <TbTrash
                            className="text-rose-600 cursor-pointer ml-2"
                            onClick={() => handleRemoveGroup(group)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex justify-end items-center">
                  <button
                    type="button"
                    className="text-white text-sm px-4 py-1 bg-cyan-800 rounded-full mt-2"
                    onClick={handleOpenSpecifyGroupModal}
                  >
                    + Add Group
                  </button>
                </div>

                {/* Trial Requirements */}
                <div className="flex my-2">
                  <label className="block mt-2 w-1/3">Trial Requirements:</label>
                  <div className="flex flex-col w-2/3 space-y-1">
                    {trialRequirements.map((requirement, index) => (
                      <input
                        key={index}
                        type="text"
                        className="p-1 border rounded"
                        value={requirement}
                        onChange={(e) => handleRequirementChange(index, e.target.value)}
                      />
                    ))}
                    <div className="flex justify-end text-sm mt-2">
                      <button
                        onClick={addRequirementField}
                        className=" text-cyan-800"
                      >
                        + Add Requirement
                      </button>
                    </div>
                  </div>
                </div>

                {/* Trial Status */}
                <div className="flex items-center">
                  <label className="block mb-2 w-1/3">Trial Status:</label>
                  <div className="w-2/3 flex items-center">
                    <select
                      className="p-2 border rounded w-full"
                      value={trialStatus}
                      onChange={(e) => setTrialStatus(e.target.value)}
                    >
                      <option value="Running">Running</option>
                      <option value="Stopped">Stopped</option>
                    </select>
                  </div>
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={handleSaveTrial}
                    className="px-4 py-2 bg-cyan-800 text-white rounded"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* Specify Group Modal */}
      <SpecifyGroupModal
        isOpen={isSpecifyGroupModalOpen}
        onRequestClose={() => setIsSpecifyGroupModalOpen(false)}
        onCreateGroup={handleCreateGroup}
        setGroupName={setGroupName}
        groupName={groupName}
      />
    </div>
  );
}

export default TrialSetting;

// // update code --> 20 Aug 2024 v03
// // src/components/admin/SubscriptionSetting/TrialSetting.tsx

// import React, { useState, useEffect } from 'react';
// import { TbEdit, TbTrash } from "react-icons/tb";
// import { FaPlus, FaMinus } from "react-icons/fa6";
// import useTrialSetting, { Trial } from '../../../hooks/AdminDashboard/useTrialSetting';
// import SpecifyGroupModal from '../../admin/SubscriptionSetting/SubSettingGroupSet';

// interface TrialSettingProps {
//   selectedPlan: any;
//   setSelectedPlan: React.Dispatch<React.SetStateAction<any>>;
//   isPlanEditMode: boolean;
// }

// const TrialSetting: React.FC<TrialSettingProps> = ({ selectedPlan }) => {
//   // trials setting section
//   const { saveTrial, deleteTrial, fetchTrials, trials } = useTrialSetting();
//   const [isTrialEnabled, setIsTrialEnabled] = useState(false);
//   const [selectedTrial, setSelectedTrial] = useState<Trial | null>(null);
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [trialPeriod, setTrialPeriod] = useState(selectedPlan.planTrialPeriod);
//   const [trialMembersType, setTrialMembersType] = useState<string[]>(selectedPlan.planTrialMemberType || []);
//   const [isCustomTrialPeriod, setIsCustomTrialPeriod] = useState(false);
//   const [customTrialDays, setCustomTrialDays] = useState('');
//   const [trialName, setTrialName] = useState('');
//   const [trialStatus, setTrialStatus] = useState('');
//   const [trialRequirements, setTrialRequirements] = useState<string[]>(['']);
//   // trial member setting group modal
//   const [isSpecifyGroupModalOpen, setIsSpecifyGroupModalOpen] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
//   const [groupName, setGroupName] = useState<string>('');
//   const [customGroups, setCustomGroups] = useState<string[]>([]); // New state to store custom group names

//   useEffect(() => {
//     fetchTrials();
//   }, [fetchTrials]);

//   useEffect(() => {
//   }, [trials, selectedPlan]);

//   useEffect(() => {
//     setIsTrialEnabled(false);
//     setTrialPeriod('Select a period');
//     setTrialMembersType([]);
//     setSelectedTrial(null);
//   }, [selectedPlan]);

//   useEffect(() => {
//     if (isSpecifyGroupModalOpen && selectedTrial?.trialGroupName) {
//       setGroupName(selectedTrial.trialGroupName);
//       setSelectedEmails(selectedTrial.trialGroupMember || []);
//     }
//   }, [isSpecifyGroupModalOpen, selectedTrial]);  

//   const memberTypes = [
//     "R One Guest", 
//     "R One Member", 
//     "R One Dealer", 
//     "R One Elite Trader", 
//     "R One Master Trader", 
//     "R One Certified Trader", 
//     "R One Trader Hub",
//     ...customGroups,
//   ];

//   const handleMemberTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { value, checked } = event.target;
//     setTrialMembersType((prev) => {
//       if (checked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((type) => type !== value);
//       }
//     });
//   };

//   const handleShowSettings = () => {
//     setSelectedTrial(null);
//     setTrialName('');
//     setTrialPeriod('Select a period');
//     setTrialMembersType([]);
//     setIsCustomTrialPeriod(false);
//     setCustomTrialDays('');
//     setTrialStatus('');
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const generateTrialID = () => {
//     return 'TRIAL-' + Math.random().toString(36).substr(2, 9).toUpperCase();
//   };

//   const handleSaveTrial = async () => {
//     const trialData = {
//       id: selectedTrial ? selectedTrial.id : undefined, 
//       planID: selectedPlan.id,
//       trialName,
//       trialDays: trialPeriod === 'monthly' ? '30' : trialPeriod === 'quarterly' ? '90' : trialPeriod === 'yearly' ? '365' : customTrialDays,
//       trialMembersType,
//       trialRequirement: trialRequirements,
//       trialID: selectedTrial ? selectedTrial.trialID : generateTrialID(),
//       trialStatus: trialStatus || 'Running',
//       trialGroupName: selectedTrial?.trialGroupName,
//       trialGroupMember: selectedTrial?.trialGroupMember,
//     };
//     try {
//       await saveTrial(trialData);
//       handleCloseModal();
//       fetchTrials();
//     } catch (error) {
//       console.error('Error saving trial:', error);
//     }
//   };

//   const handleModifyTrial = (trial: Trial) => {
//     setSelectedTrial(trial);
//     setTrialName(trial.trialName);
//     setTrialPeriod(trial.trialDays);
//     setTrialMembersType(trial.trialMembersType);
//     setTrialStatus(trial.trialStatus);
//     setCustomGroups(trial.trialMembersType.filter(type => !memberTypes.includes(type)));
//     setIsModalOpen(true);
//   };
  
// // Add function to handle requirement changes
// const handleRequirementChange = (index: number, value: string) => {
//   const newRequirements = [...trialRequirements];
//   newRequirements[index] = value;
//   setTrialRequirements(newRequirements);
// };

// // Function to handle opening the modal
// const handleCreateGroup = (emails: string[]) => {
//   setSelectedEmails(emails);
//   setCustomGroups((prevGroups) => [...prevGroups, groupName]);
//   setTrialMembersType((prevMembers) => [...prevMembers, groupName]);
//   setSelectedTrial((prev: Trial | null) => {
//     if (prev) {
//       return {
//         ...prev,
//         trialGroupName: groupName,
//         trialGroupMember: emails,
//       };
//     }
//     return prev;
//   });
//   setCustomGroups((prevGroups) => [...prevGroups, groupName]);
//   setIsSpecifyGroupModalOpen(false);
// };

// const handleOpenSpecifyGroupModal = () => {
//   setIsSpecifyGroupModalOpen(true);
// };

// const handleModifyGroup = (groupName: string) => {
//   const trial = trials.find(trial => trial.trialGroupName === groupName);
//   if (trial) {
//     setSelectedTrial(trial);
//     setGroupName(groupName);
//     setSelectedEmails(trial.trialGroupMember || []);
//     setIsSpecifyGroupModalOpen(true);
//   }
// };

// const handleRemoveGroup = (groupName: string) => {
//   setCustomGroups((prevGroups) => prevGroups.filter((group) => group !== groupName));
//   setTrialMembersType((prevMembers) => prevMembers.filter((type) => type !== groupName));
//   setSelectedTrial((prev: Trial | null) => {
//     if (prev) {
//       return {
//         ...prev,
//         trialMembersType: prev.trialMembersType.filter(type => type !== groupName),
//         trialGroupName: prev.trialGroupName === groupName ? '' : prev.trialGroupName,
//         trialGroupMember: prev.trialGroupName === groupName ? [] : prev.trialGroupMember,
//       };
//     }
//     return prev;
//   });
// };

// // Add function to add a new requirement input field
// const addRequirementField = () => {
//   setTrialRequirements([...trialRequirements, '']);
// };

//   const filteredTrials = trials.filter(trial => {
//     return trial.planID.toString() === selectedPlan.id.toString();
//   });
  
//   return (
//     <div>
//       <div className="flex justify-normal items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
//         <h2 className="text-xl font-semibold mb-4">Trial Setting</h2>
//         <div className="mb-4 mx-4">
//           {isExpanded ? <FaMinus size={24} /> : <FaPlus size={24} />}
//         </div>
//       </div>

//       {/* Trial Set Review */}
//       {isExpanded && (
//         <>
//           <div className="flex items-center">
//             {/* Trial Set Apply */}
//             <label className="block mb-2 w-1/3">Plan Trial Apply:</label>
//             <div className="w-2/3 flex items-center justify-between text-sm">
//               <div className="flex items-cente">
//                 <input
//                   type="checkbox"
//                   checked={isTrialEnabled}
//                   onChange={(e) => setIsTrialEnabled(e.target.checked)}
//                   className="mr-2 w-4 h-4 bg-white text-cyan-800"
//                 />
//                 <label className="text-black">{isTrialEnabled ? 'Enabled' : 'Disabled'}</label>
//               </div>
//               <div className="flex justify-end items-center text-xs">
//                 <button
//                   onClick={handleShowSettings}
//                   className={`ml-4 px-4 py-2 rounded-full ${isTrialEnabled ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isTrialEnabled}
//                 >
//                   + Create Set
//                 </button>
//                 <button
//                   onClick={() => selectedTrial && deleteTrial(selectedTrial.id)}
//                   className={`ml-2 px-4 py-2 rounded-full ${isTrialEnabled ? 'bg-rose-600 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isTrialEnabled}
//                 >
//                   Delete Set
//                 </button>
//               </div>
//             </div>
//           </div>

//           {/* Trial Set Selector */}
//           <div className="flex items-center mt-2">
//             <label className="w-1/3 font-semibold">Select a Trial:</label>
//             <div className="w-2/3">
//               {filteredTrials.length > 0 ? (
//                 <div className="grid grid-cols-3 gap-2">
//                   {filteredTrials.map(trial => (
//                     <div
//                       key={trial.id}
//                       className={`p-2 border rounded cursor-pointer ${selectedTrial?.id === trial.id ? 'border-cyan-800 border-2' : 'text-gray-300'}`}
//                       onClick={() => isTrialEnabled && setSelectedTrial(trial)} // Enable selection only if isTrialEnabled is true
//                       style={{ backgroundColor: isTrialEnabled ? 'white' : '#EEEDEB' }}
//                     >
//                       <div className="flex justify-between items-center">
//                         <span>{trial.trialName}</span>
//                         <TbEdit
//                           className={`text-cyan-800 cursor-pointer ${!isTrialEnabled ? 'cursor-not-allowed text-gray-300' : 'text-cyan-800'}`}
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             if (isTrialEnabled) handleModifyTrial(trial);
//                           }}
//                         />
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               ) : (
//                 <div className="p-2 border-2 border-dashed border-gray-300 rounded text-center w-[200px]">
//                   No trials available
//                 </div>
//               )}
//             </div>
//           </div>
          
//           {/* Trial Set Preview */}
//           <div className="flex mt-2" style={{ color: isTrialEnabled ? 'black' : 'lightgray' }}>
//             <div className="w-1/3">
//               <div className="mt-2 ">
//                 <p>Trial Name:</p>
//                 <p>Trial Period:</p>
//                 <p>Allow Trial Members Type:</p>
//                 <p>Trial Requirenment</p>
//                 <p>Trial Status:</p>
//               </div>
//             </div>
//             <div className="w-2/3">
//               <div className="mt-2">
//                 <p>{selectedTrial ? selectedTrial.trialName : 'N/A'}</p>
//                 <p>{selectedTrial ? `${selectedTrial.trialDays} Days` : 'N/A'}</p>
//                 <p>{selectedTrial ? selectedTrial.trialMembersType.join(', ') : 'N/A'}</p>
//                 <p>{selectedTrial ? selectedTrial.trialRequirement.join(','): 'N/A'}</p>
//                 <p style={{ color: selectedTrial?.trialStatus === 'Running' ? 'green' : 'red' }}>
//                   {selectedTrial ? selectedTrial.trialStatus : 'N/A'}
//                 </p>
//               </div>
//             </div>
//           </div>
          
//           {/* Trial Set create or modify */}
//           {isModalOpen && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//               <div className="bg-white p-6 rounded shadow-lg w-1/2 space-y-2 ">
//                 <button className="float-right" onClick={handleCloseModal}>X</button>
//                 <h2 className="text-xl font-semibold mb-4">Trial Settings</h2>
                
//                 {/* Trial Name */}
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Trial Name:</label>
//                   <input
//                     type="text"
//                     className="w-2/3 p-2 border rounded"
//                     value={trialName}
//                     onChange={(e) => setTrialName(e.target.value)}
//                   />
//                 </div>

//                 {/* Trial Period */}
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Trial Period:</label>
//                   <div className="w-2/3 flex items-center">
//                     <select
//                       className="p-2 border rounded w-full"
//                       value={trialPeriod}
//                       onChange={(e) => {
//                         setTrialPeriod(e.target.value);
//                         setIsCustomTrialPeriod(e.target.value === 'Specify Period');
//                       }}
//                     >
//                       <option value="">Select a plan</option>
//                       <option value="monthly">Monthly</option>
//                       <option value="quarterly">Quarterly</option>
//                       <option value="yearly">Yearly</option>
//                       <option value="Specify Period">Specify Period</option>
//                     </select>
//                     {isCustomTrialPeriod && (
//                       <div className="ml-4 flex items-center">
//                         <input
//                           type="text"
//                           className="p-2 border rounded"
//                           value={customTrialDays}
//                           onChange={(e) => setCustomTrialDays(e.target.value)}
//                           placeholder="Enter days"
//                         />
//                         <span className="ml-2 text-black">days</span>
//                       </div>
//                     )}
//                   </div>
//                 </div>

//                 {/* Trial Members Type */}
//                 <div className="flex my-2">
//                   <label className="block mt-2 w-1/3">Trial Members Type:</label>
//                   <div className="flex flex-col my-2">
//                     {memberTypes.map((type) => (
//                       <label key={type} className="flex items-center mr-4 mb-2">
//                         <input
//                           type="checkbox"
//                           value={type}
//                           onChange={handleMemberTypeChange}
//                           className="mr-2 w-4 h-4"
//                           checked={trialMembersType.includes(type)}
//                         />
//                         <span className="text-black">
//                           {type}
//                         </span>
//                       </label>
//                     ))}
//                     {/* Display custom groups */}
//                     {customGroups.map((group) => (
//                       <div key={group} className="flex items-center justify-between mt-2 border p-2">
//                         <label className="flex items-center">
//                           <input
//                             type="checkbox"
//                             value={group}
//                             onChange={handleMemberTypeChange}
//                             className="mr-2 w-4 h-4"
//                             checked={trialMembersType.includes(group)}
//                           />
//                           <span className="text-black">
//                             {group}
//                           </span>
//                         </label>
//                         <div className="flex items-center">
//                           <TbEdit
//                             className="text-cyan-800 cursor-pointer ml-2"
//                             onClick={() => handleModifyGroup(group)}
//                           />
//                           <TbTrash
//                             className="text-rose-600 cursor-pointer ml-2"
//                             onClick={() => handleRemoveGroup(group)}
//                           />
//                         </div>
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="text-blue-600 mt-2"
//                       onClick={handleOpenSpecifyGroupModal}
//                     >
//                       + Add Group
//                     </button>
//                   </div>
//                 </div>

//                 {/* Trial Requirements */}
//                 <div className="flex my-2">
//                   <label className="block mt-2 w-1/3">Trial Requirements:</label>
//                   <div className="flex flex-col w-2/3 space-y-1">
//                     {trialRequirements.map((requirement, index) => (
//                       <input
//                         key={index}
//                         type="text"
//                         className="p-1 border rounded"
//                         value={requirement}
//                         onChange={(e) => handleRequirementChange(index, e.target.value)}
//                       />
//                     ))}
//                     <div className="flex justify-end text-sm mt-2">
//                       <button
//                         onClick={addRequirementField}
//                         className=" text-cyan-800"
//                       >
//                         + Add Requirement
//                       </button>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Trial Status */}
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Trial Status:</label>
//                   <div className="w-2/3 flex items-center">
//                     <select
//                       className="p-2 border rounded w-full"
//                       value={trialStatus}
//                       onChange={(e) => setTrialStatus(e.target.value)}
//                     >
//                       <option value="Running">Running</option>
//                       <option value="Stopped">Stopped</option>
//                     </select>
//                   </div>
//                 </div>
//                 <div className="flex justify-end mt-4">
//                   <button
//                     onClick={handleSaveTrial}
//                     className="px-4 py-2 bg-cyan-800 text-white rounded"
//                   >
//                     Save
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </>
//       )}
//       {/* Specify Group Modal */}
//       <SpecifyGroupModal
//         isOpen={isSpecifyGroupModalOpen}
//         onRequestClose={() => setIsSpecifyGroupModalOpen(false)}
//         onCreateGroup={handleCreateGroup}
//         setGroupName={setGroupName}
//         groupName={groupName}
//       />
//     </div>
//   );
// }

// export default TrialSetting;

// // update code --> 20 Aug 2024 v02
// // src/components/admin/SubscriptionSetting/TrialSetting.tsx

// import React, { useState, useEffect } from 'react';
// import { TbEdit } from "react-icons/tb";
// import { FaPlus, FaMinus } from "react-icons/fa6";
// import useTrialSetting, { Trial } from '../../../hooks/AdminDashboard/useTrialSetting';
// import SpecifyGroupModal from '../../admin/SubscriptionSetting/SubSettingGroupSet';

// interface TrialSettingProps {
//   selectedPlan: any;
//   setSelectedPlan: React.Dispatch<React.SetStateAction<any>>;
//   isPlanEditMode: boolean;
// }

// const TrialSetting: React.FC<TrialSettingProps> = ({ selectedPlan }) => {
//   // trials setting section
//   const { saveTrial, deleteTrial, fetchTrials, trials } = useTrialSetting();
//   const [isTrialEnabled, setIsTrialEnabled] = useState(false);
//   const [selectedTrial, setSelectedTrial] = useState<Trial | null>(null);
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [trialPeriod, setTrialPeriod] = useState(selectedPlan.planTrialPeriod);
//   const [trialMembersType, setTrialMembersType] = useState<string[]>(selectedPlan.planTrialMemberType || []);
//   const [isCustomTrialPeriod, setIsCustomTrialPeriod] = useState(false);
//   const [customTrialDays, setCustomTrialDays] = useState('');
//   const [trialName, setTrialName] = useState('');
//   const [trialStatus, setTrialStatus] = useState('');
//   const [trialRequirements, setTrialRequirements] = useState<string[]>(['']);
//   // trial member setting group modal
//   const [isSpecifyGroupModalOpen, setIsSpecifyGroupModalOpen] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
//   const [groupName, setGroupName] = useState<string>('');
//   const [customGroups, setCustomGroups] = useState<string[]>([]); // New state to store custom group names

//   useEffect(() => {
//     fetchTrials();
//   }, [fetchTrials]);

//   useEffect(() => {
//   }, [trials, selectedPlan]);

//   useEffect(() => {
//     setIsTrialEnabled(false);
//     setTrialPeriod('Select a period');
//     setTrialMembersType([]);
//     setSelectedTrial(null);
//   }, [selectedPlan]);

//   useEffect(() => {
//     if (isSpecifyGroupModalOpen && selectedTrial?.trialGroupName) {
//       setGroupName(selectedTrial.trialGroupName);
//       setSelectedEmails(selectedTrial.trialGroupMember || []);
//     }
//   }, [isSpecifyGroupModalOpen, selectedTrial]);  

//   const memberTypes = [
//     "R One Guest", 
//     "R One Member", 
//     "R One Dealer", 
//     "R One Elite Trader", 
//     "R One Master Trader", 
//     "R One Certified Trader", 
//     "R One Trader Hub",
//     ...customGroups,
//   ];

//   const handleMemberTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { value, checked } = event.target;
//     setTrialMembersType((prev) => {
//       if (checked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((type) => type !== value);
//       }
//     });
//   };

//   const handleShowSettings = () => {
//     setSelectedTrial(null);
//     setTrialName('');
//     setTrialPeriod('Select a period');
//     setTrialMembersType([]);
//     setIsCustomTrialPeriod(false);
//     setCustomTrialDays('');
//     setTrialStatus('');
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const generateTrialID = () => {
//     return 'TRIAL-' + Math.random().toString(36).substr(2, 9).toUpperCase();
//   };

//   const handleSaveTrial = async () => {
//     const trialData = {
//       id: selectedTrial ? selectedTrial.id : undefined, 
//       planID: selectedPlan.id,
//       trialName,
//       trialDays: trialPeriod === 'monthly' ? '30' : trialPeriod === 'quarterly' ? '90' : trialPeriod === 'yearly' ? '365' : customTrialDays,
//       trialMembersType,
//       trialRequirement: trialRequirements,
//       trialID: selectedTrial ? selectedTrial.trialID : generateTrialID(),
//       trialStatus: trialStatus || 'Running',
//       trialGroupName: selectedTrial?.trialGroupName,
//       trialGroupMember: selectedTrial?.trialGroupMember,
//     };
//     try {
//       await saveTrial(trialData);
//       handleCloseModal();
//       fetchTrials();
//     } catch (error) {
//       console.error('Error saving trial:', error);
//     }
//   };

//   const handleModifyTrial = (trial: Trial) => {
//     setSelectedTrial(trial);
//     setTrialName(trial.trialName);
//     setTrialPeriod(trial.trialDays);
//     setTrialMembersType(trial.trialMembersType);
//     setTrialStatus(trial.trialStatus);
//     setIsModalOpen(true);
//   };
  
// // Add function to handle requirement changes
// const handleRequirementChange = (index: number, value: string) => {
//   const newRequirements = [...trialRequirements];
//   newRequirements[index] = value;
//   setTrialRequirements(newRequirements);
// };

// // Function to handle opening the modal
// const handleCreateGroup = (emails: string[]) => {
//   setSelectedEmails(emails);
//   setCustomGroups((prevGroups) => [...prevGroups, groupName]);
//   setTrialMembersType((prevMembers) => [...prevMembers, groupName]);
//   setSelectedTrial((prev: Trial | null) => {
//     if (prev) {
//       return {
//         ...prev,
//         trialGroupName: groupName,
//         trialGroupMember: emails,
//       };
//     }
//     return prev;
//   });
//   setCustomGroups((prevGroups) => [...prevGroups, groupName]);
//   setIsSpecifyGroupModalOpen(false);
// };

// const handleOpenSpecifyGroupModal = () => {
//   setIsSpecifyGroupModalOpen(true);
// };

// // Add function to add a new requirement input field
// const addRequirementField = () => {
//   setTrialRequirements([...trialRequirements, '']);
// };

//   const filteredTrials = trials.filter(trial => {
//     return trial.planID.toString() === selectedPlan.id.toString();
//   });
  
//   return (
//     <div>
//       <div className="flex justify-normal items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
//         <h2 className="text-xl font-semibold mb-4">Trial Setting</h2>
//         <div className="mb-4 mx-4">
//           {isExpanded ? <FaMinus size={24} /> : <FaPlus size={24} />}
//         </div>
//       </div>

//       {/* Trial Set Review */}
//       {isExpanded && (
//         <>
//           <div className="flex items-center">
//             {/* Trial Set Apply */}
//             <label className="block mb-2 w-1/3">Plan Trial Apply:</label>
//             <div className="w-2/3 flex items-center justify-between text-sm">
//               <div className="flex items-cente">
//                 <input
//                   type="checkbox"
//                   checked={isTrialEnabled}
//                   onChange={(e) => setIsTrialEnabled(e.target.checked)}
//                   className="mr-2 w-4 h-4 bg-white text-cyan-800"
//                 />
//                 <label className="text-black">{isTrialEnabled ? 'Enabled' : 'Disabled'}</label>
//               </div>
//               <div className="flex justify-end items-center text-xs">
//                 <button
//                   onClick={handleShowSettings}
//                   className={`ml-4 px-4 py-2 rounded-full ${isTrialEnabled ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isTrialEnabled}
//                 >
//                   + Create Set
//                 </button>
//                 <button
//                   onClick={() => selectedTrial && deleteTrial(selectedTrial.id)}
//                   className={`ml-2 px-4 py-2 rounded-full ${isTrialEnabled ? 'bg-rose-600 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isTrialEnabled}
//                 >
//                   Delete Set
//                 </button>
//               </div>
//             </div>
//           </div>

//           {/* Trial Set Selector */}
//           <div className="flex items-center mt-2">
//             <label className="w-1/3 font-semibold">Select a Trial:</label>
//             <div className="w-2/3">
//               {filteredTrials.length > 0 ? (
//                 <div className="grid grid-cols-3 gap-2">
//                   {filteredTrials.map(trial => (
//                     <div
//                       key={trial.id}
//                       className={`p-2 border rounded cursor-pointer ${selectedTrial?.id === trial.id ? 'border-cyan-800 border-2' : 'text-gray-300'}`}
//                       onClick={() => isTrialEnabled && setSelectedTrial(trial)} // Enable selection only if isTrialEnabled is true
//                       style={{ backgroundColor: isTrialEnabled ? 'white' : '#EEEDEB' }}
//                     >
//                       <div className="flex justify-between items-center">
//                         <span>{trial.trialName}</span>
//                         <TbEdit
//                           className={`text-cyan-800 cursor-pointer ${!isTrialEnabled ? 'cursor-not-allowed text-gray-300' : 'text-cyan-800'}`}
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             if (isTrialEnabled) handleModifyTrial(trial);
//                           }}
//                         />
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               ) : (
//                 <div className="p-2 border-2 border-dashed border-gray-300 rounded text-center w-[200px]">
//                   No trials available
//                 </div>
//               )}
//             </div>
//           </div>
          
//           {/* Trial Set Preview */}
//           <div className="flex mt-2" style={{ color: isTrialEnabled ? 'black' : 'lightgray' }}>
//             <div className="w-1/3">
//               <div className="mt-2 ">
//                 <p>Trial Name:</p>
//                 <p>Trial Period:</p>
//                 <p>Allow Trial Members Type:</p>
//                 <p>Trial Requirenment</p>
//                 <p>Trial Status:</p>
//               </div>
//             </div>
//             <div className="w-2/3">
//               <div className="mt-2">
//                 <p>{selectedTrial ? selectedTrial.trialName : 'N/A'}</p>
//                 <p>{selectedTrial ? `${selectedTrial.trialDays} Days` : 'N/A'}</p>
//                 <p>{selectedTrial ? selectedTrial.trialMembersType.join(', ') : 'N/A'}</p>
//                 <p>{selectedTrial ? selectedTrial.trialRequirement.join(','): 'N/A'}</p>
//                 <p style={{ color: selectedTrial?.trialStatus === 'Running' ? 'green' : 'red' }}>
//                   {selectedTrial ? selectedTrial.trialStatus : 'N/A'}
//                 </p>
//               </div>
//             </div>
//           </div>
          
//           {/* Trial Set create or modify */}
//           {isModalOpen && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//               <div className="bg-white p-6 rounded shadow-lg w-1/2 space-y-2 ">
//                 <button className="float-right" onClick={handleCloseModal}>X</button>
//                 <h2 className="text-xl font-semibold mb-4">Trial Settings</h2>
                
//                 {/* Trial Name */}
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Trial Name:</label>
//                   <input
//                     type="text"
//                     className="w-2/3 p-2 border rounded"
//                     value={trialName}
//                     onChange={(e) => setTrialName(e.target.value)}
//                   />
//                 </div>

//                 {/* Trial Period */}
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Trial Period:</label>
//                   <div className="w-2/3 flex items-center">
//                     <select
//                       className="p-2 border rounded w-full"
//                       value={trialPeriod}
//                       onChange={(e) => {
//                         setTrialPeriod(e.target.value);
//                         setIsCustomTrialPeriod(e.target.value === 'Specify Period');
//                       }}
//                     >
//                       <option value="">Select a plan</option>
//                       <option value="monthly">Monthly</option>
//                       <option value="quarterly">Quarterly</option>
//                       <option value="yearly">Yearly</option>
//                       <option value="Specify Period">Specify Period</option>
//                     </select>
//                     {isCustomTrialPeriod && (
//                       <div className="ml-4 flex items-center">
//                         <input
//                           type="text"
//                           className="p-2 border rounded"
//                           value={customTrialDays}
//                           onChange={(e) => setCustomTrialDays(e.target.value)}
//                           placeholder="Enter days"
//                         />
//                         <span className="ml-2 text-black">days</span>
//                       </div>
//                     )}
//                   </div>
//                 </div>

//                 {/* Trial Members Type */}
//                 <div className="flex my-2">
//                   <label className="block mt-2 w-1/3">Trial Members Type:</label>
//                   <div className="flex flex-col my-2">
//                     {memberTypes.map((type) => (
//                       <label key={type} className="flex items-center mr-4 mb-2">
//                         <input
//                           type="checkbox"
//                           value={type}
//                           onChange={handleMemberTypeChange}
//                           className="mr-2 w-4 h-4"
//                           checked={trialMembersType.includes(type)}
//                         />
//                         <span className="text-black">
//                           {type}
//                         </span>
//                       </label>
//                     ))}
//                     {/* Display existing groups */}
//                     {selectedTrial?.trialGroupName && (
//                       <div className="flex items-center mt-2 border p-2">
//                         <div className="flex items-center justify-between w-2/3">
//                           <span>{selectedTrial.trialGroupName}</span>
//                           <TbEdit
//                             className="text-cyan-800 cursor-pointer ml-2"
//                             onClick={() => setIsSpecifyGroupModalOpen(true)}
//                           />
//                         </div>
//                       </div>
//                     )}
//                     <button
//                       type="button"
//                       className="text-blue-600 mt-2"
//                       onClick={handleOpenSpecifyGroupModal}
//                     >
//                       + Add Group
//                     </button>
//                   </div>
//                 </div>

//                 {/* Trial Requirements */}
//                 <div className="flex my-2">
//                   <label className="block mt-2 w-1/3">Trial Requirements:</label>
//                   <div className="flex flex-col w-2/3 space-y-1">
//                     {trialRequirements.map((requirement, index) => (
//                       <input
//                         key={index}
//                         type="text"
//                         className="p-1 border rounded"
//                         value={requirement}
//                         onChange={(e) => handleRequirementChange(index, e.target.value)}
//                       />
//                     ))}
//                     <div className="flex justify-end text-sm mt-2">
//                       <button
//                         onClick={addRequirementField}
//                         className=" text-cyan-800"
//                       >
//                         + Add Requirement
//                       </button>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Trial Status */}
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Trial Status:</label>
//                   <div className="w-2/3 flex items-center">
//                     <select
//                       className="p-2 border rounded w-full"
//                       value={trialStatus}
//                       onChange={(e) => setTrialStatus(e.target.value)}
//                     >
//                       <option value="Running">Running</option>
//                       <option value="Stopped">Stopped</option>
//                     </select>
//                   </div>
//                 </div>
//                 <div className="flex justify-end mt-4">
//                   <button
//                     onClick={handleSaveTrial}
//                     className="px-4 py-2 bg-cyan-800 text-white rounded"
//                   >
//                     Save
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </>
//       )}
//       {/* Specify Group Modal */}
//       <SpecifyGroupModal
//         isOpen={isSpecifyGroupModalOpen}
//         onRequestClose={() => setIsSpecifyGroupModalOpen(false)}
//         onCreateGroup={handleCreateGroup}
//         setGroupName={setGroupName}
//         groupName={groupName}
//       />
//     </div>
//   );
// }

// export default TrialSetting;

// // udpate code --> 20 Aug 2024
// // src/components/admin/SubscriptionSetting/TrialSetting.tsx

// import React, { useState, useEffect } from 'react';
// import useTrialSetting from '../../../hooks/AdminDashboard/useTrialSetting';
// import { TbEdit } from "react-icons/tb";
// import { FaPlus, FaMinus } from "react-icons/fa6";

// interface TrialSettingProps {
//   selectedPlan: any;
//   setSelectedPlan: React.Dispatch<React.SetStateAction<any>>;
//   isPlanEditMode: boolean;
// }

// interface Trial {
//   id: number;
//   productID: string;
//   planID: string;
//   eventID: string;
//   programID: string;
//   trialID: string;
//   trialName: string;
//   trialDays: string;
//   trialMembersType: string[];
//   trialRequirement: string[];
//   trialStatus: string;
// }

// const TrialSetting: React.FC<TrialSettingProps> = ({ selectedPlan }) => {
//   // trials setting section
//   const { saveTrial, deleteTrial, fetchTrials, trials } = useTrialSetting();
//   const [isTrialEnabled, setIsTrialEnabled] = useState(false); // Default to false
//   const [selectedTrial, setSelectedTrial] = useState<Trial | null>(null);
//   const [isExpanded, setIsExpanded] = useState(false);

//   // trial setting modal
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [trialPeriod, setTrialPeriod] = useState(selectedPlan.planTrialPeriod);
//   const [trialMembersType, setTrialMembersType] = useState<string[]>(selectedPlan.planTrialMemberType || []);
//   const [isCustomTrialPeriod, setIsCustomTrialPeriod] = useState(false);
//   const [customTrialDays, setCustomTrialDays] = useState('');
//   const [trialName, setTrialName] = useState('');
//   const [trialStatus, setTrialStatus] = useState('');
//   const [trialRequirements, setTrialRequirements] = useState<string[]>(['']);


//   useEffect(() => {
//     fetchTrials();
//   }, [fetchTrials]);

//   useEffect(() => {
//   }, [trials, selectedPlan]);

//   useEffect(() => {
//     setIsTrialEnabled(false);
//     setTrialPeriod('Select a period');
//     setTrialMembersType([]);
//     setSelectedTrial(null);
//   }, [selectedPlan]);

//   const memberTypes = [
//     "R One Guest", 
//     "R One Member", 
//     "R One Dealer", 
//     "R One Elite Trader", 
//     "R One Master Trader", 
//     "R One Certified Trader", 
//     "R One Trader Hub",
//   ];

//   const handleMemberTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { value, checked } = event.target;
//     setTrialMembersType((prev) => {
//       if (checked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((type) => type !== value);
//       }
//     });
//   };

//   const handleShowSettings = () => {
//     setSelectedTrial(null); // Clear selected trial
//     setTrialName('');
//     setTrialPeriod('Select a period');
//     setTrialMembersType([]);
//     setIsCustomTrialPeriod(false);
//     setCustomTrialDays('');
//     setTrialStatus('');
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const generateTrialID = () => {
//     return 'TRIAL-' + Math.random().toString(36).substr(2, 9).toUpperCase();
//   };

//   const handleSaveTrial = async () => {
//     const trialData = {
//       id: selectedTrial ? selectedTrial.id : undefined, // Ensure undefined to create a new trial if no selected trial
//       planID: selectedPlan.id,
//       trialName,
//       trialDays: trialPeriod === 'monthly' ? '30' : trialPeriod === 'quarterly' ? '90' : trialPeriod === 'yearly' ? '365' : customTrialDays,
//       trialMembersType,
//       trialRequirement: trialRequirements,
//       trialID: selectedTrial ? selectedTrial.trialID : generateTrialID(),
//       trialStatus: trialStatus || 'Running' // Ensure a default value if not set
//     };
//     try {
//       await saveTrial(trialData);
//       handleCloseModal();
//       fetchTrials(); // Refetch trials to update the list
//     } catch (error) {
//       console.error('Error saving trial:', error);
//     }
//   };

//   const handleModifyTrial = (trial: Trial) => {
//     setSelectedTrial(trial);
//     setTrialName(trial.trialName);
//     setTrialPeriod(trial.trialDays);
//     setTrialMembersType(trial.trialMembersType);
//     setTrialStatus(trial.trialStatus);
//     setIsModalOpen(true);
//   };
  
// // Add function to handle requirement changes
// const handleRequirementChange = (index: number, value: string) => {
//   const newRequirements = [...trialRequirements];
//   newRequirements[index] = value;
//   setTrialRequirements(newRequirements);
// };

// // Add function to add a new requirement input field
// const addRequirementField = () => {
//   setTrialRequirements([...trialRequirements, '']);
// };

//   const filteredTrials = trials.filter(trial => {
//     return trial.planID.toString() === selectedPlan.id.toString();
//   });
  
//   return (
//     <div>
//       <div className="flex justify-normal items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
//         <h2 className="text-xl font-semibold mb-4">Trial Setting</h2>
//         <div className="mb-4 mx-4">
//           {isExpanded ? <FaMinus size={24} /> : <FaPlus size={24} />}
//         </div>
//       </div>

//       {/* Trial Set  Review */}
//       {isExpanded && (
//         <>
//           <div className="flex items-center">
//             {/* Trial Set Apply */}
//             <label className="block mb-2 w-1/3">Plan Trial Apply:</label>
//             <div className="w-2/3 flex items-center justify-between text-sm">
//               <div className="flex items-cente">
//                 <input
//                   type="checkbox"
//                   checked={isTrialEnabled}
//                   onChange={(e) => setIsTrialEnabled(e.target.checked)}
//                   className="mr-2 w-4 h-4 bg-white text-cyan-800"
//                 />
//                 <label className="text-black">{isTrialEnabled ? 'Enabled' : 'Disabled'}</label>
//               </div>
//               <div className="flex justify-end items-center text-xs">
//                 <button
//                   onClick={handleShowSettings}
//                   className={`ml-4 px-4 py-2 rounded-full ${isTrialEnabled ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isTrialEnabled}
//                 >
//                   + Create Set
//                 </button>
//                 <button
//                   onClick={() => selectedTrial && deleteTrial(selectedTrial.id)}
//                   className={`ml-2 px-4 py-2 rounded-full ${isTrialEnabled ? 'bg-rose-600 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isTrialEnabled}
//                 >
//                   Delete Set
//                 </button>
//               </div>
//             </div>
//           </div>

//           {/* Trial Set Selector */}
//           <div className="flex items-center mt-2">
//             <label className="w-1/3 font-semibold">Select a Trial:</label>
//             <div className="w-2/3">
//               {filteredTrials.length > 0 ? (
//                 <div className="grid grid-cols-3 gap-2">
//                   {filteredTrials.map(trial => (
//                     <div
//                       key={trial.id}
//                       className={`p-2 border rounded cursor-pointer ${selectedTrial?.id === trial.id ? 'border-cyan-800 border-2' : 'text-gray-300'}`}
//                       onClick={() => isTrialEnabled && setSelectedTrial(trial)} // Enable selection only if isTrialEnabled is true
//                       style={{ backgroundColor: isTrialEnabled ? 'white' : '#EEEDEB' }}
//                     >
//                       <div className="flex justify-between items-center">
//                         <span>{trial.trialName}</span>
//                         <TbEdit
//                           className={`text-cyan-800 cursor-pointer ${!isTrialEnabled ? 'cursor-not-allowed text-gray-300' : 'text-cyan-800'}`}
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             if (isTrialEnabled) handleModifyTrial(trial);
//                           }}
//                         />
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               ) : (
//                 <div className="p-2 border-2 border-dashed border-gray-300 rounded text-center w-[200px]">
//                   No trials available
//                 </div>
//               )}
//             </div>
//           </div>
          
//           {/* Trial Set Preview */}
//           <div className="flex mt-2" style={{ color: isTrialEnabled ? 'black' : 'lightgray' }}>
//             <div className="w-1/3">
//               <div className="mt-2 ">
//                 <p>Trial Name:</p>
//                 <p>Trial Period:</p>
//                 <p>Allow Trial Members Type:</p>
//                 <p>Trial Requirenment</p>
//                 <p>Trial Status:</p>
//               </div>
//             </div>
//             <div className="w-2/3">
//               <div className="mt-2">
//                 <p>{selectedTrial ? selectedTrial.trialName : 'N/A'}</p>
//                 <p>{selectedTrial ? `${selectedTrial.trialDays} Days` : 'N/A'}</p>
//                 <p>{selectedTrial ? selectedTrial.trialMembersType.join(', ') : 'N/A'}</p>
//                 <p>{selectedTrial ? selectedTrial.trialRequirement.join(','): 'N/A'}</p>
//                 <p style={{ color: selectedTrial?.trialStatus === 'Running' ? 'green' : 'red' }}>
//                   {selectedTrial ? selectedTrial.trialStatus : 'N/A'}
//                 </p>
//               </div>
//             </div>
//           </div>
          
//           {/* Trial Set create or modify */}
//           {isModalOpen && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//               <div className="bg-white p-6 rounded shadow-lg w-1/2 space-y-2 ">
//                 <button className="float-right" onClick={handleCloseModal}>X</button>
//                 <h2 className="text-xl font-semibold mb-4">Trial Settings</h2>
                
//                 {/* Trial Name */}
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Trial Name:</label>
//                   <input
//                     type="text"
//                     className="w-2/3 p-2 border rounded"
//                     value={trialName}
//                     onChange={(e) => setTrialName(e.target.value)}
//                   />
//                 </div>

//                 {/* Trial Period */}
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Trial Period:</label>
//                   <div className="w-2/3 flex items-center">
//                     <select
//                       className="p-2 border rounded w-full"
//                       value={trialPeriod}
//                       onChange={(e) => {
//                         setTrialPeriod(e.target.value);
//                         setIsCustomTrialPeriod(e.target.value === 'Specify Period');
//                       }}
//                     >
//                       <option value="">Select a plan</option>
//                       <option value="monthly">Monthly</option>
//                       <option value="quarterly">Quarterly</option>
//                       <option value="yearly">Yearly</option>
//                       <option value="Specify Period">Specify Period</option>
//                     </select>
//                     {isCustomTrialPeriod && (
//                       <div className="ml-4 flex items-center">
//                         <input
//                           type="text"
//                           className="p-2 border rounded"
//                           value={customTrialDays}
//                           onChange={(e) => setCustomTrialDays(e.target.value)}
//                           placeholder="Enter days"
//                         />
//                         <span className="ml-2 text-black">days</span>
//                       </div>
//                     )}
//                   </div>
//                 </div>

//                 {/* Trial Members Type */}
//                 <div className="flex my-2">
//                   <label className="block mt-2 w-1/3">Trial Members Type:</label>
//                   <div className="flex flex-col my-2">
//                     {memberTypes.map((type) => (
//                       <label key={type} className="flex items-center mr-4 mb-2">
//                         <input
//                           type="checkbox"
//                           value={type}
//                           onChange={handleMemberTypeChange}
//                           className="mr-2 w-4 h-4"
//                           checked={trialMembersType.includes(type)}
//                         />
//                         <span className="text-black">
//                           {type}
//                         </span>
//                       </label>
//                     ))}
//                   </div>
//                 </div>

//                 {/* Trial Requirements */}
//                 <div className="flex my-2">
//                   <label className="block mt-2 w-1/3">Trial Requirements:</label>
//                   <div className="flex flex-col w-2/3 space-y-1">
//                     {trialRequirements.map((requirement, index) => (
//                       <input
//                         key={index}
//                         type="text"
//                         className="p-1 border rounded"
//                         value={requirement}
//                         onChange={(e) => handleRequirementChange(index, e.target.value)}
//                       />
//                     ))}
//                     <div className="flex justify-end text-sm mt-2">
//                       <button
//                         onClick={addRequirementField}
//                         className=" text-cyan-800"
//                       >
//                         + Add Requirement
//                       </button>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Trial Status */}
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Trial Status:</label>
//                   <div className="w-2/3 flex items-center">
//                     <select
//                       className="p-2 border rounded w-full"
//                       value={trialStatus}
//                       onChange={(e) => setTrialStatus(e.target.value)}
//                     >
//                       <option value="Running">Running</option>
//                       <option value="Stopped">Stopped</option>
//                     </select>
//                   </div>
//                 </div>
//                 <div className="flex justify-end mt-4">
//                   <button
//                     onClick={handleSaveTrial}
//                     className="px-4 py-2 bg-cyan-800 text-white rounded"
//                   >
//                     Save
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </>
//       )}
//     </div>
//   );
// }

// export default TrialSetting;