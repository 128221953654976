// src/components/client/MiniApp/MyProgram.tsx

import React from 'react';
import { RiFundsFill } from "react-icons/ri";
import { useTheme } from '../../../context/ThemeContext';

const MyProgram: React.FC = () => {
  const { theme } = useTheme();

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  return (
    <div className="max-w-xl mx-auto p-4 rounded-lg w-auto h-auto max-h-screen md:p-6">
      <h2 className="flex justify-center items-center text-2xl font-bold mb-4 text-center sm:text-xl xs:text-lg" style={styles.title}>
        <RiFundsFill className="mr-2 w-6 h-6"/>
        Funded Trader Program
      </h2>
      <div className="overflow-y-auto w-auto h-auto xl:h-[500px] lg:h-[500px] md:h-[600px] sm:h-[500px] xs:h-[300px]">
        <p className="mb-6 xs:text-sm" style={styles.text}>
          Welcome to "My Program," your gateway to two exceptional funded trader programs designed to elevate your trading career. Explore the opportunities offered by our 180 and Go4Funds programs and find the perfect fit for your trading aspirations.
        </p>

        <h3 className="text-2xl font-semibold mb-4 sm:text-lg xs:text-base" style={styles.title}>
          180 Funded Trader Program
        </h3>
        <p className="mb-6 xs:text-sm" style={styles.text}>
          The 180 Funded Trader Program is tailored for traders looking to scale their trading operations with the backing of substantial capital. This program offers:
        </p>
        <ul className="list-disc list-inside space-y-2 mb-6 xs:text-sm" style={styles.text}>
          <li><span className="font-semibold">Generous Capital Allocation:</span> Receive up to $180,000 in trading capital based on your performance.</li>
          <li><span className="font-semibold">Comprehensive Support:</span> Benefit from professional guidance and resources to optimize your trading strategies.</li>
          <li><span className="font-semibold">Performance-Based Scaling:</span> Increase your capital allocation as you demonstrate consistent profitability and risk management.</li>
          <li><span className="font-semibold">Flexible Trading Conditions:</span> Enjoy the freedom to trade various markets and instruments.</li>
        </ul>

        <h3 className="text-2xl font-semibold mb-4 sm:text-lg xs:text-base" style={styles.title}>
          Go4Funds Funded Trader Program
        </h3>
        <p className="mb-6 xs:text-sm" style={styles.text}>
          The Go4Funds Funded Trader Program is designed for ambitious traders who seek to maximize their potential with robust financial backing. Key features of this program include:
        </p>
        <ul className="list-disc list-inside space-y-2 mb-6 xs:text-sm" style={styles.text}>
          <li><span className="font-semibold text-gray-800">Significant Funding:</span> Access up to $250,000 in trading capital, allowing you to trade larger positions and capture more opportunities.</li>
          <li><span className="font-semibold text-gray-800">Advanced Tools and Resources:</span> Utilize cutting-edge trading tools and analytics to enhance your trading decisions.</li>
          <li><span className="font-semibold text-gray-800">Growth Opportunities:</span> Scale your funding based on performance milestones, providing a clear path to greater capital access.</li>
          <li><span className="font-semibold text-gray-800">Mentorship and Training:</span> Receive expert mentorship to refine your strategies and stay ahead in the market.</li>
        </ul>

        <p className="mb-6 xs:text-sm" style={styles.text}>
          Whether you are a seasoned trader or just starting out, "My Program" offers the support and resources you need to succeed. Join our funded trader programs today and take the next step in your trading journey with confidence and backing.
        </p>
      </div>
      <div className="flex justify-evenly mt-4">
        <button className="font-semi py-2 px-6 rounded-full xs:text-xs" style={styles.button}>
          180 Program
        </button>
        <button className="font-semi py-2 px-6 rounded-full xs:text-xs" style={styles.button}>
          Go4Funds Program
        </button>
      </div>
    </div>
  );
};

export default MyProgram;
