// src/components/client/MiniApp/MyJournal.tsx

import React from 'react';
import { useTheme } from '../../../context/ThemeContext';
import { MdInsertChart } from "react-icons/md";

const MyJournal: React.FC = () => {
  const { theme } = useTheme();

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  return (
    <div className="max-w-2xl h-auto w-auto mx-auto p-6 rounded-lg">
      <h2 className="flex justify-center items-center text-2xl font-bold mb-4 text-center xs:text-lg" style={styles.title}>
        <MdInsertChart className="mr-2 w-8 h-8 xs:w-6"/> 
        R One Journal
      </h2>
      <div className="w-auto h-auto overflow-y-auto xl:h-[450px] lg:h-[450px] md:h-[500px] sm:h-[500px] xs:h-[320px] xs:text-sm">
        <p className="text-gray-600 mb-6">
          A comprehensive tool designed for traders to document and analyze their trading activities. 
          It allows you to log trades, strategies, and market conditions, providing valuable insights 
          into your trading behavior.
        </p>
        <h3 className="text-xl font-semibold mb-2 text-gray-700">Key Features:</h3>
        <ul className="list-disc list-inside space-y-2 mb-6 text-gray-600">
          <li><span className="font-semibold text-gray-800">Trade Logging:</span> Record detailed trade information including entry/exit points and outcomes.</li>
          <li><span className="font-semibold text-gray-800">Behavioral Insights:</span> Document strategies and emotional states to understand decision-making processes.</li>
          <li><span className="font-semibold text-gray-800">Performance Analysis:</span> Generate reports and visualizations to evaluate trading performance.</li>
          <li><span className="font-semibold text-gray-800">Progress Tracking:</span> Set goals and monitor progress.</li>
          <li><span className="font-semibold text-gray-800">Secure and Private:</span> Ensure your data remains confidential.</li>
        </ul>
        <p className="text-gray-600 mb-6">
          Enhance your trading strategy with "My Journal" and achieve consistent success.
        </p>
      </div>
      <div className="flex justify-center mt-4">
        <button className="font-bold py-2 px-6 rounded-full" style={styles.button}>
          R One Journal
        </button>
      </div>
    </div>
  );
};

export default MyJournal;

