// src/components/client/Dashboard/UserEvent.tsx

// react components
import React, { useState } from 'react';
import { motion } from 'framer-motion';
// react icons
import { IoIosArrowForward, IoMdCloseCircle } from "react-icons/io";
import { BsCalendar2EventFill } from "react-icons/bs";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { MdOutlineEventBusy } from "react-icons/md";
// context file
import { useTheme } from '../../../context/ThemeContext';
import { useEnv } from '../../../context/EnvContext';
// hooks file
import useEventData, { formatDate, formatTime, getEventImagePath, formatAmount  } from '../../../hooks/MiniApp/useEventData'
import useUserStage from '../../../hooks/Dashboard/useUserStage';
import useProgramCalendar, { ProgramEvent, ApprovedEvent } from '../../../hooks/Dashboard/useUserProgramCalender';
import MyTicket from '../MiniApp/App01_MyTicket'; // Import MyTicket component

const UserEvent: React.FC<{ email: string }> = ({ email }) => {
  const { theme } = useTheme();
  const { apiUrl } = useEnv(); 
  const { isGuest } = useUserStage();
  const { getSlotMessage } = useEventData();
  const { programEvents, approvedEvents } = useProgramCalendar(email);
  const [selectedEvent, setSelectedEvent] = useState<ProgramEvent | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMyTicketModalOpen, setIsMyTicketModalOpen] = useState(false); // New state for MyTicket modal

  const todayEvents = programEvents.filter((event: ProgramEvent) => new Date(event.startDate).toDateString() === new Date().toDateString());
  const upcomingEvents = programEvents.filter((event: ProgramEvent) => new Date(event.startDate) > new Date());

  const getEventStatus = (event: ProgramEvent) => {
    return approvedEvents.some((approvedEvent: ApprovedEvent) => approvedEvent.eventTitle === event.name) ? 'booked' : 'unbooked';
  };

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  return (
    <div className={`h-full border-2 rounded-lg p-4 shadow-md bg-white ${isGuest ? 'opacity-50' : ''}`}>
      <div>

        {/* Today Event */}
        <div className="flex justify-start items-center">
          <h2 className="text-xl font-bold py-2 px-2 sm:text-lg xs:text-lg" style={styles.title}>
            Today's R One Events
          </h2>
          <div className="relative group ml-2">
            <AiOutlineInfoCircle 
              className="text-xl text-gray-400 hover:text-cyan-800 cursor-pointer md:w-4 sm:w-4 xs:w-4"
              aria-hidden="true"
            />
            {/* Tooltip */}
            <div className="absolute top-full z-50 mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white md:right-0 sm:right-0 xs:right-0"
              style={styles.button}>
              Discover and engage in today's scheduled programs and activities. Don't miss out on the latest happenings in the R One community.
            </div>
          </div>
        </div>
        <div className="h-[170px] overflow-hidden">
          {todayEvents.length > 0 ? (
            todayEvents.map((event, index) => (
              <div
                key={index}
                className="border p-4 rounded-md shadow-md cursor-pointer flex items-center justify-between md:flex-col sm:flex-col xs:flex-col"
                onClick={() => { if (!isGuest) { setSelectedEvent(event); setIsModalOpen(true); } }}
              >
                <div className="flex items-center">
                  <div>                  
                    <div className="flex justify-left items-center">
                      <BsCalendar2EventFill className="mr-2" style={styles.icon}/>
                      <div className="font-semibold" style={styles.title}>
                        {event.name}
                      </div>
                    </div>
                    <div className="text-sm" style={styles.title}><strong>Date</strong>: {formatDate(event.startDate, event.time)} at {formatTime(event.time)}</div>
                    <div className="text-sm" style={styles.title}><strong>Venue</strong>: {event.location}</div>
                    <div className={`flex justify-center text-xs px-2 py-1 rounded 
                      ${getEventStatus(event) === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
                      {getEventStatus(event) === 'booked' ? 'Booked' : 'Unbooked'}
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-xs md:my-1 sm:my-2 xs:my-2">{getSlotMessage(event.slot)}</div>
                  <IoIosArrowForward className="w-6 h-6" style={styles.icon}/> 
                </div>
              </div>
            ))
          ) : (
            <div className="border p-8 bg-white rounded-md shadow-md text-center">
              <div className="flex justify-center items-center text-lg md:text-base sm:test-base xs:text-sm text-gray-300">
                <MdOutlineEventBusy className="mr-2" />
                No events today
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Upcoming Event */}
      <div>
        <div className="flex justify-start items-center">
          <h2 className="text-xl font-bold pt-4 pb-2 px-2 sm:text-lg xs:text-lg" style={styles.title}>
            R One Upcoming Events
          </h2>
          <div className="relative group ml-2 pt-2">
            <AiOutlineInfoCircle 
              className="text-xl text-gray-400 hover:text-cyan-800 cursor-pointer md:w-4 sm:w-4 xs:w-4"
              aria-hidden="true"
            />
            {/* Tooltip */}
            <div className="absolute top-full z-50 mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white md:right-0 sm:right-0 xs:right-0"
              style={styles.button}>
              Explore and schedule future programs and activities to ensure you never miss out on engaging with the R One community.
            </div>
          </div>
        </div>
        <div className="h-[270px] space-y-2 overflow-y-auto">
          {upcomingEvents.length > 0 ? (
            upcomingEvents.map((event, index) => (
              <div
                key={index}
                className="border bg-white p-4 rounded-md shadow-md cursor-pointer flex items-center justify-between md:flex-col sm:flex-col xs:flex-col"
                onClick={() => { if (!isGuest) { setSelectedEvent(event); setIsModalOpen(true); } }}
              >
                <div className="flex justify-center items-center">
                  <div>
                    <div className="flex justify-left items-center">
                      <BsCalendar2EventFill className="mr-2" style={styles.icon}/>
                      <div className="font-semibold" style={styles.title}>
                        {event.name}
                      </div>
                    </div>
                    <div className="text-sm" style={styles.title}><strong>Date</strong>: {formatDate(event.startDate, event.time)} at {formatTime(event.time)}</div>
                    <div className="text-sm" style={styles.title}><strong>Venue</strong>: {event.location}</div>
                    <div className={`flex justify-center text-xs px-2 py-1 rounded
                      ${getEventStatus(event) === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
                      {getEventStatus(event) === 'booked' ? 'Booked' : 'Unbooked'}
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-xs md:my-1 sm:my-2 xs:my-2">{getSlotMessage(event.slot)}</div>
                  <IoIosArrowForward className="w-6 h-6" style={styles.icon}/> 
                </div>
              </div>
            ))
          ) : (
            <div className="border p-8 bg-white rounded-md shadow-md text-center">
              <div className="flex justify-center items-center text-lg md:text-base sm:test-base xs:text-sm">
                <MdOutlineEventBusy className="mr-2" />
                No upcoming events.
              </div>
            </div>
          )}
        </div>
      </div>
      {isGuest && (
        <div className="text-center text-red-600 font-bold">Enroll classes to access these events.</div>
      )}
      {selectedEvent && isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
          onClick={() => setIsModalOpen(false)}
        >
          <motion.div 
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            className="bg-white rounded-lg p-6 mx-4 max-w-lg w-full"
          >
              <div className="flex flex-row justify-between md:flex-col sm:flex-col xs:flex-col">
                <div className="w-auto mr-8 p-2 md:mr-0 sm:mr-0 xs:mr-0">
                  <h3 className="text-xl font-semibold mb-4 flex items-center" style={styles.title}>
                    📅 Event Details
                  </h3>
                  <div className="font-semibold text-lg" style={styles.title}>
                      {selectedEvent.name}
                  </div>
                  <div className="py-4 md:py-2 sm:py-2 xs:py-2">
                    <div className="text-sm md:text-base sm:text-base" style={styles.title}>
                      <strong>Date:</strong> {formatDate(selectedEvent.startDate, selectedEvent.time)} at {formatTime(selectedEvent.time)}
                    </div>
                    <div className="text-sm md:text-base sm:text-base" style={styles.title}>
                      <strong>Venue:</strong> {selectedEvent.location}
                    </div>
                    <div className="text-sm md:text-base sm:text-base" style={styles.title}>
                      <strong>Amount:</strong> 
                        {selectedEvent.amount === 0 
                          ? 'Free of Charges' 
                          : `${selectedEvent.currency} ${selectedEvent.amount}`}
                    </div>
                    <div className="text-sm md:text-center sm:text-base sm:text-center xs:text-sm xs:text-center">
                      {getSlotMessage(selectedEvent.slot)}
                    </div>
                  </div>
                  <div className="text-sm text-gray-600 md:text-base sm:text-base" style={styles.title}><strong>Requirement:</strong>
                    <ul>
                      <li className="md:text-sm sm:text-xs xs:text-xs">▪️ {selectedEvent.lotSize === 0 ? 'No Lot Size Required' : `${selectedEvent.lotSize} lot Size`}</li>
                      <li className="md:text-sm sm:text-xs xs:text-xs">▪️ {selectedEvent.deposit === 0 ? 'No Deposit Required' : `${formatAmount(selectedEvent.deposit)} USD`}</li>
                      <li className="md:text-sm sm:text-xs xs:text-xs">▪️ {selectedEvent.broker}</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <img
                    src={getEventImagePath(selectedEvent.image, apiUrl)}
                    alt={selectedEvent.name}
                    className="w-[200px] h-[200px] object-cover rounded-lg mb-4 md:w-full sm:w-full xs:w-full"
                  />
                </div>
              </div>
            <div className={`flex justify-center text-xs px-2 py-1 rounded mt-6 md:text-sm sm:text-sm ${getEventStatus(selectedEvent) === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
              {getEventStatus(selectedEvent) === 'booked' ? 'Booked' : 'Unbooked'}
            </div>
            <button 
              onClick={() => { setIsModalOpen(false); setIsMyTicketModalOpen(true); }} 
              className="mt-4 px-4 py-2 rounded w-full" 
              style={{ backgroundColor: theme.primaryColor, color: theme.text2Color }}>
              Open My Tickets
            </button>
          </motion.div>
        </div>
      )}
      {isMyTicketModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            className="bg-white rounded-lg p-6 w-auto max-w-[800px] max-h-screen mx-2 my-2 lg:h-[700px] md:max-w-2xl sm:max-w-lg xs:max-w-xs"
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">My Ticket</h2>
              <button onClick={() => setIsMyTicketModalOpen(false)} className="text-red-500" title="Close">
                <IoMdCloseCircle />
              </button>
            </div>
            <MyTicket />
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default UserEvent;


// // update code --> 05 aug 2024
// // src/components/client/Dashboard/UserEvent.tsx

// // react components
// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// // react icons
// import { IoIosArrowForward, IoMdCloseCircle } from "react-icons/io";
// import { BsCalendar2EventFill } from "react-icons/bs";
// import { AiOutlineInfoCircle } from 'react-icons/ai';
// import { MdOutlineEventBusy } from "react-icons/md";
// // context file
// import { useTheme } from '../../../context/ThemeContext';
// import { useEnv } from '../../../context/EnvContext';
// // hooks file
// import useEventData, { formatDate, formatTime, getEventImagePath, formatAmount  } from '../../../hooks/MiniApp/useEventData'
// import useUserStage from '../../../hooks/Dashboard/useUserStage';
// import useProgramCalendar, { ProgramEvent, ApprovedEvent } from '../../../hooks/Dashboard/useUserProgramCalender';
// import MyTicket from '../MiniApp/App01_MyTicket'; // Import MyTicket component

// const UserEvent: React.FC<{ email: string }> = ({ email }) => {
//   const { theme } = useTheme();
//   const { apiUrl } = useEnv(); 
//   const { isGuest } = useUserStage();
//   const { getSlotMessage } = useEventData();
//   const { programEvents, approvedEvents } = useProgramCalendar(email);
//   const [selectedEvent, setSelectedEvent] = useState<ProgramEvent | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isMyTicketModalOpen, setIsMyTicketModalOpen] = useState(false); // New state for MyTicket modal

//   const todayEvents = programEvents.filter((event: ProgramEvent) => new Date(event.startDate).toDateString() === new Date().toDateString());
//   const upcomingEvents = programEvents.filter((event: ProgramEvent) => new Date(event.startDate) > new Date());

//   const getEventStatus = (event: ProgramEvent) => {
//     return approvedEvents.some((approvedEvent: ApprovedEvent) => approvedEvent.eventTitle === event.name) ? 'booked' : 'unbooked';
//   };

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   return (
//     <div className={`h-full border-2 rounded-lg p-4 shadow-md bg-white ${isGuest ? 'opacity-50' : ''}`}>
//       <div>

//         {/* Today Event */}
//         <div className="flex justify-start items-center">
//           <h2 className="text-xl font-bold py-2 px-2 sm:text-lg xs:text-lg" style={styles.title}>
//             Today's R One Events
//           </h2>
//           <div className="relative group ml-2">
//             <AiOutlineInfoCircle 
//               className="text-xl text-gray-400 hover:text-cyan-800 cursor-pointer md:w-4 sm:w-4 xs:w-4"
//               aria-hidden="true"
//             />
//             {/* Tooltip */}
//             <div className="absolute top-full z-50 mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white md:right-0 sm:right-0 xs:right-0"
//               style={styles.button}>
//               Discover and engage in today's scheduled programs and activities. Don't miss out on the latest happenings in the R One community.
//             </div>
//           </div>
//         </div>
//         <div className="h-[170px] overflow-hidden">
//           {todayEvents.length > 0 ? (
//             todayEvents.map((event, index) => (
//               <div
//                 key={index}
//                 className="border p-4 rounded-md shadow-md cursor-pointer flex items-center justify-between md:flex-col sm:flex-col xs:flex-col"
//                 onClick={() => { if (!isGuest) { setSelectedEvent(event); setIsModalOpen(true); } }}
//               >
//                 <div className="flex items-center">
//                   <div>                  
//                     <div className="flex justify-left items-center">
//                       <BsCalendar2EventFill className="mr-2" style={styles.icon}/>
//                       <div className="font-semibold" style={styles.title}>
//                         {event.name}
//                       </div>
//                     </div>
//                     <div className="text-sm" style={styles.title}><strong>Date</strong>: {formatDate(event.startDate, event.time)} at {formatTime(event.time)}</div>
//                     <div className="text-sm" style={styles.title}><strong>Venue</strong>: {event.location}</div>
//                     <div className={`flex justify-center text-xs px-2 py-1 rounded 
//                       ${getEventStatus(event) === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
//                       {getEventStatus(event) === 'booked' ? 'Booked' : 'Unbooked'}
//                     </div>
//                   </div>
//                 </div>
//                 <div className="flex items-center">
//                   <div className="text-xs md:my-1 sm:my-2 xs:my-2">{getSlotMessage(event.slot)}</div>
//                   <IoIosArrowForward className="w-6 h-6" style={styles.icon}/> 
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div className="border p-8 bg-white rounded-md shadow-md text-center">
//               <div className="flex justify-center items-center text-lg md:text-base sm:test-base xs:text-sm text-gray-300">
//                 <MdOutlineEventBusy className="mr-2" />
//                 No events today
//               </div>
//             </div>
//           )}
//         </div>
//       </div>

//       {/* Upcoming Event */}
//       <div>
//         <div className="flex justify-start items-center">
//           <h2 className="text-xl font-bold pt-4 pb-2 px-2 sm:text-lg xs:text-lg" style={styles.title}>
//             R One Upcoming Events
//           </h2>
//           <div className="relative group ml-2 pt-2">
//             <AiOutlineInfoCircle 
//               className="text-xl text-gray-400 hover:text-cyan-800 cursor-pointer md:w-4 sm:w-4 xs:w-4"
//               aria-hidden="true"
//             />
//             {/* Tooltip */}
//             <div className="absolute top-full z-50 mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white md:right-0 sm:right-0 xs:right-0"
//               style={styles.button}>
//               Explore and schedule future programs and activities to ensure you never miss out on engaging with the R One community.
//             </div>
//           </div>
//         </div>
//         <div className="h-[270px] space-y-2 overflow-y-auto">
//           {upcomingEvents.length > 0 ? (
//             upcomingEvents.map((event, index) => (
//               <div
//                 key={index}
//                 className="border bg-white p-4 rounded-md shadow-md cursor-pointer flex items-center justify-between md:flex-col sm:flex-col xs:flex-col"
//                 onClick={() => { if (!isGuest) { setSelectedEvent(event); setIsModalOpen(true); } }}
//               >
//                 <div className="flex justify-center items-center">
//                   <div>
//                     <div className="flex justify-left items-center">
//                       <BsCalendar2EventFill className="mr-2" style={styles.icon}/>
//                       <div className="font-semibold" style={styles.title}>
//                         {event.name}
//                       </div>
//                     </div>
//                     <div className="text-sm" style={styles.title}><strong>Date</strong>: {formatDate(event.startDate, event.time)} at {formatTime(event.time)}</div>
//                     <div className="text-sm" style={styles.title}><strong>Venue</strong>: {event.location}</div>
//                     <div className={`flex justify-center text-xs px-2 py-1 rounded
//                       ${getEventStatus(event) === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
//                       {getEventStatus(event) === 'booked' ? 'Booked' : 'Unbooked'}
//                     </div>
//                   </div>
//                 </div>
//                 <div className="flex items-center">
//                   <div className="text-xs md:my-1 sm:my-2 xs:my-2">{getSlotMessage(event.slot)}</div>
//                   <IoIosArrowForward className="w-6 h-6" style={styles.icon}/> 
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div className="border p-8 bg-white rounded-md shadow-md text-center">
//               <div className="flex justify-center items-center text-lg md:text-base sm:test-base xs:text-sm">
//                 <MdOutlineEventBusy className="mr-2" />
//                 No upcoming events.
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       {isGuest && (
//         <div className="text-center text-red-600 font-bold">Enroll classes to access these events.</div>
//       )}
//       {selectedEvent && isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
//           onClick={() => setIsModalOpen(false)}
//         >
//           <motion.div 
//             initial={{ opacity: 0, scale: 0.8 }}
//             animate={{ opacity: 1, scale: 1 }}
//             exit={{ opacity: 0, scale: 0.8 }}
//             className="bg-white rounded-lg p-6 mx-4 max-w-lg w-full"
//           >
//               <div className="flex flex-row justify-between md:flex-col sm:flex-col xs:flex-col">
//                 <div className="w-auto mr-8 p-2 md:mr-0 sm:mr-0 xs:mr-0">
//                   <h3 className="text-xl font-semibold mb-4 flex items-center" style={styles.title}>
//                     📅 Event Details
//                   </h3>
//                   <div className="font-semibold text-lg" style={styles.title}>
//                       {selectedEvent.name}
//                   </div>
//                   <div className="py-4 md:py-2 sm:py-2 xs:py-2">
//                     <div className="text-sm md:text-base sm:text-base" style={styles.title}>
//                       <strong>Date:</strong> {formatDate(selectedEvent.startDate, selectedEvent.time)} at {formatTime(selectedEvent.time)}
//                     </div>
//                     <div className="text-sm md:text-base sm:text-base" style={styles.title}>
//                       <strong>Venue:</strong> {selectedEvent.location}
//                     </div>
//                     <div className="text-sm md:text-base sm:text-base" style={styles.title}>
//                       <strong>Amount:</strong> 
//                         {selectedEvent.amount === 0 
//                           ? 'Free of Charges' 
//                           : `${selectedEvent.currency} ${selectedEvent.amount}`}
//                     </div>
//                     <div className="text-sm md:text-center sm:text-base sm:text-center xs:text-sm xs:text-center">
//                       {getSlotMessage(selectedEvent.slot)}
//                     </div>
//                   </div>
//                   <div className="text-sm text-gray-600 md:text-base sm:text-base" style={styles.title}><strong>Requirement:</strong>
//                     <ul>
//                       <li className="md:text-sm sm:text-xs xs:text-xs">▪️ {selectedEvent.lotSize === 0 ? 'No Lot Size Required' : `${selectedEvent.lotSize} lot Size`}</li>
//                       <li className="md:text-sm sm:text-xs xs:text-xs">▪️ {selectedEvent.deposit === 0 ? 'No Deposit Required' : `${formatAmount(selectedEvent.deposit)} USD`}</li>
//                       <li className="md:text-sm sm:text-xs xs:text-xs">▪️ {selectedEvent.broker}</li>
//                     </ul>
//                   </div>
//                 </div>
//                 <div>
//                   <img
//                     src={getEventImagePath(selectedEvent.image, apiUrl)}
//                     alt={selectedEvent.name}
//                     className="w-[200px] h-[200px] object-cover rounded-lg mb-4 md:w-full sm:w-full xs:w-full"
//                   />
//                 </div>
//               </div>
//             <div className={`flex justify-center text-xs px-2 py-1 rounded mt-6 md:text-sm sm:text-sm ${getEventStatus(selectedEvent) === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
//               {getEventStatus(selectedEvent) === 'booked' ? 'Booked' : 'Unbooked'}
//             </div>
//             <button 
//               onClick={() => { setIsModalOpen(false); setIsMyTicketModalOpen(true); }} 
//               className="mt-4 px-4 py-2 rounded w-full" 
//               style={{ backgroundColor: theme.primaryColor, color: theme.text2Color }}>
//               Open My Tickets
//             </button>
//           </motion.div>
//         </div>
//       )}
//       {isMyTicketModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <motion.div
//             initial={{ opacity: 0, scale: 0.8 }}
//             animate={{ opacity: 1, scale: 1 }}
//             exit={{ opacity: 0, scale: 0.8 }}
//             className="bg-white rounded-lg p-6 w-auto max-w-[800px] max-h-screen mx-2 my-2 lg:h-[700px] md:max-w-2xl sm:max-w-lg xs:max-w-xs"
//           >
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-xl font-bold">My Ticket</h2>
//               <button onClick={() => setIsMyTicketModalOpen(false)} className="text-red-500" title="Close">
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <MyTicket />
//           </motion.div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserEvent;



// // udpate code --> 04 aug 2024
// // src/components/client/Dashboard/UserEvent.tsx

// import React, { useState } from 'react';
// import { IoIosArrowForward } from "react-icons/io";
// import { BsCalendar2EventFill } from "react-icons/bs";
// import { AiOutlineInfoCircle } from 'react-icons/ai';
// import { MdOutlineEventBusy } from "react-icons/md";
// import { useTheme } from '../../../context/ThemeContext';
// import { useEnv } from '../../../context/EnvContext';
// import useEventData, { formatDate, formatTime, getEventImagePath, formatAmount  } from '../../../hooks/MiniApp/useEventData'
// import useUserStage from '../../../hooks/Dashboard/useUserStage';
// import useProgramCalendar, { ProgramEvent, ApprovedEvent } from '../../../hooks/Dashboard/useUserProgramCalender';
// import { motion } from 'framer-motion';

// const UserEvent: React.FC<{ email: string }> = ({ email }) => {
//   const { theme } = useTheme();
//   const { apiUrl } = useEnv(); 
//   const { isGuest } = useUserStage();
//   const { getSlotMessage } = useEventData();
//   const { programEvents, approvedEvents } = useProgramCalendar(email);
//   const [selectedEvent, setSelectedEvent] = useState<ProgramEvent | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const todayEvents = programEvents.filter((event: ProgramEvent) => new Date(event.startDate).toDateString() === new Date().toDateString());
//   const upcomingEvents = programEvents.filter((event: ProgramEvent) => new Date(event.startDate) > new Date());

//   const getEventStatus = (event: ProgramEvent) => {
//     return approvedEvents.some((approvedEvent: ApprovedEvent) => approvedEvent.eventTitle === event.name) ? 'booked' : 'unbooked';
//   };

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   return (
//     <div className={`h-full border-2 rounded-lg p-4 shadow-md bg-white ${isGuest ? 'opacity-50' : ''}`}>
//       <div>

//         {/* Today Event */}
//         <div className="flex justify-start items-center">
//           <h2 className="text-xl font-bold py-2 px-2 sm:text-lg xs:text-lg" style={styles.title}>
//             Today's R One Events
//           </h2>
//           <div className="relative group ml-2">
//             <AiOutlineInfoCircle 
//               className="text-xl text-gray-400 hover:text-cyan-800 cursor-pointer md:w-4 sm:w-4 xs:w-4"
//               aria-hidden="true"
//             />
//             {/* Tooltip */}
//             <div className="absolute top-full z-50 mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white md:right-0 sm:right-0 xs:right-0"
//               style={styles.button}>
//               Discover and engage in today's scheduled programs and activities. Don't miss out on the latest happenings in the R One community.
//             </div>
//           </div>
//         </div>
//         <div className="h-[170px] overflow-hidden">
//           {todayEvents.length > 0 ? (
//             todayEvents.map((event, index) => (
//               <div
//                 key={index}
//                 className="border p-4 rounded-md shadow-md cursor-pointer flex items-center justify-between md:flex-col sm:flex-col xs:flex-col"
//                 onClick={() => { if (!isGuest) { setSelectedEvent(event); setIsModalOpen(true); } }}
//               >
//                 <div className="flex items-center">
//                   <div>                  
//                     <div className="flex justify-left items-center">
//                       <BsCalendar2EventFill className="mr-2" style={styles.icon}/>
//                       <div className="font-semibold" style={styles.title}>
//                         {event.name}
//                       </div>
//                     </div>
//                     <div className="text-sm" style={styles.title}><strong>Date</strong>: {formatDate(event.startDate, event.time)} at {formatTime(event.time)}</div>
//                     <div className="text-sm" style={styles.title}><strong>Venue</strong>: {event.location}</div>
//                     <div className={`flex justify-center text-xs px-2 py-1 rounded 
//                       ${getEventStatus(event) === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
//                       {getEventStatus(event) === 'booked' ? 'Booked' : 'Unbooked'}
//                     </div>
//                   </div>
//                 </div>
//                 <div className="flex items-center">
//                   <div className="text-xs md:my-1 sm:my-2 xs:my-2">{getSlotMessage(event.slot)}</div>
//                   <IoIosArrowForward className="w-6 h-6" style={styles.icon}/> 
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div className="border p-8 bg-white rounded-md shadow-md text-center">
//               <div className="flex justify-center items-center text-lg md:text-base sm:test-base xs:text-sm text-gray-300">
//                 <MdOutlineEventBusy className="mr-2" />
//                 No events today
//               </div>
//             </div>
//           )}
//         </div>
//       </div>

//       {/* Upcoming Event */}
//       <div>
//         <div className="flex justify-start items-center">
//           <h2 className="text-xl font-bold pt-4 pb-2 px-2 sm:text-lg xs:text-lg" style={styles.title}>
//             R One Upcoming Events
//           </h2>
//           <div className="relative group ml-2 pt-2">
//             <AiOutlineInfoCircle 
//               className="text-xl text-gray-400 hover:text-cyan-800 cursor-pointer md:w-4 sm:w-4 xs:w-4"
//               aria-hidden="true"
//             />
//             {/* Tooltip */}
//             <div className="absolute top-full z-50 mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block border-2 border-white md:right-0 sm:right-0 xs:right-0"
//               style={styles.button}>
//               Explore and schedule future programs and activities to ensure you never miss out on engaging with the R One community.
//             </div>
//           </div>
//         </div>
//         <div className="h-[270px] space-y-2 overflow-y-auto">
//           {upcomingEvents.length > 0 ? (
//             upcomingEvents.map((event, index) => (
//               <div
//                 key={index}
//                 className="border bg-white p-4 rounded-md shadow-md cursor-pointer flex items-center justify-between md:flex-col sm:flex-col xs:flex-col"
//                 onClick={() => { if (!isGuest) { setSelectedEvent(event); setIsModalOpen(true); } }}
//               >
//                 <div className="flex justify-center items-center">
//                   <div>
//                     <div className="flex justify-left items-center">
//                       <BsCalendar2EventFill className="mr-2" style={styles.icon}/>
//                       <div className="font-semibold" style={styles.title}>
//                         {event.name}
//                       </div>
//                     </div>
//                     <div className="text-sm" style={styles.title}><strong>Date</strong>: {formatDate(event.startDate, event.time)} at {formatTime(event.time)}</div>
//                     <div className="text-sm" style={styles.title}><strong>Venue</strong>: {event.location}</div>
//                     <div className={`flex justify-center text-xs px-2 py-1 rounded
//                       ${getEventStatus(event) === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
//                       {getEventStatus(event) === 'booked' ? 'Booked' : 'Unbooked'}
//                     </div>
//                   </div>
//                 </div>
//                 <div className="flex items-center">
//                   <div className="text-xs md:my-1 sm:my-2 xs:my-2">{getSlotMessage(event.slot)}</div>
//                   <IoIosArrowForward className="w-6 h-6" style={styles.icon}/> 
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div className="border p-8 bg-white rounded-md shadow-md text-center">
//               <div className="flex justify-center items-center text-lg md:text-base sm:test-base xs:text-sm">
//                 <MdOutlineEventBusy className="mr-2" />
//                 No upcoming events.
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       {isGuest && (
//         <div className="text-center text-red-600 font-bold">Enroll classes to access these events.</div>
//       )}
//       {selectedEvent && isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <motion.div 
//             initial={{ opacity: 0, scale: 0.8 }}
//             animate={{ opacity: 1, scale: 1 }}
//             exit={{ opacity: 0, scale: 0.8 }}
//             className="bg-white rounded-lg p-6 mx-4 max-w-lg w-full"
//           >
//               <div className="flex flex-row justify-between md:flex-col sm:flex-col xs:flex-col">
//                 <div className="w-auto mr-8 p-2 md:mr-0 sm:mr-0 xs:mr-0">
//                   <h3 className="text-xl font-semibold mb-4 flex items-center" style={styles.title}>
//                     📅 Event Details
//                   </h3>
//                   <div className="font-semibold text-lg" style={styles.title}>
//                       {selectedEvent.name}
//                   </div>
//                   <div className="py-4 md:py-2 sm:py-2 xs:py-2">
//                     <div className="text-sm md:text-base sm:text-base" style={styles.title}>
//                       <strong>Date:</strong> {formatDate(selectedEvent.startDate, selectedEvent.time)} at {formatTime(selectedEvent.time)}
//                     </div>
//                     <div className="text-sm md:text-base sm:text-base" style={styles.title}>
//                       <strong>Venue:</strong> {selectedEvent.location}
//                     </div>
//                     <div className="text-sm md:text-base sm:text-base" style={styles.title}>
//                       <strong>Amount:</strong> 
//                         {selectedEvent.amount === 0 
//                           ? 'Free of Charges' 
//                           : `${selectedEvent.currency} ${selectedEvent.amount}`}
//                     </div>
//                     <div className="text-sm md:text-center sm:text-base sm:text-center xs:text-sm xs:text-center">
//                       {getSlotMessage(selectedEvent.slot)}
//                     </div>
//                   </div>
//                   <div className="text-sm text-gray-600 md:text-base sm:text-base" style={styles.title}><strong>Requirement:</strong>
//                     <ul>
//                       <li className="md:text-sm sm:text-xs xs:text-xs">▪️ {selectedEvent.lotSize === 0 ? 'No Lot Size Required' : `${selectedEvent.lotSize} lot Size`}</li>
//                       <li className="md:text-sm sm:text-xs xs:text-xs">▪️ {selectedEvent.deposit === 0 ? 'No Deposit Required' : `${formatAmount(selectedEvent.deposit)} USD`}</li>
//                       <li className="md:text-sm sm:text-xs xs:text-xs">▪️ {selectedEvent.broker}</li>
//                     </ul>
//                   </div>
//                 </div>
//                 <div>
//                   <img
//                     src={getEventImagePath(selectedEvent.image, apiUrl)}
//                     alt={selectedEvent.name}
//                     className="w-[200px] h-[200px] object-cover rounded-lg mb-4 md:w-full sm:w-full xs:w-full"
//                   />
//                 </div>
//               </div>
//             <div className={`flex justify-center text-xs px-2 py-1 rounded mt-6 md:text-sm sm:text-sm ${getEventStatus(selectedEvent) === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
//               {getEventStatus(selectedEvent) === 'booked' ? 'Booked' : 'Unbooked'}
//             </div>
//             <button onClick={() => setIsModalOpen(false)} className="mt-4 px-4 py-2 rounded w-full" style={{ backgroundColor: theme.primaryColor, color: theme.text2Color }}>
//               Close
//             </button>
//           </motion.div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserEvent;



// // update --> 09 july 2024
// // src/components/client/Dashboard/UserEvent.tsx

// import React, { useState } from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import useUserStage from '../../../hooks/Dashboard/useUserStage';
// import useProgramCalendar, { ProgramEvent, ApprovedEvent } from '../../../hooks/Dashboard/useUserProgramCalender';

// const UserEvent: React.FC<{ email: string }> = ({ email }) => {
//   const [selectedEvent, setSelectedEvent] = useState<ProgramEvent | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const { theme } = useTheme();
//   const { isGuest } = useUserStage();
//   const { programEvents, approvedEvents } = useProgramCalendar(email);

//   const todayEvents = programEvents.filter((event: ProgramEvent) => new Date(event.startDate).toDateString() === new Date().toDateString());
//   const upcomingEvents = programEvents.filter((event: ProgramEvent) => new Date(event.startDate) > new Date());

//   const getEventStatus = (event: ProgramEvent) => {
//     return approvedEvents.some((approvedEvent: ApprovedEvent) => approvedEvent.eventTitle === event.name) ? 'booked' : 'unbooked';
//   };

//   const formatDate = (date: string) => {
//     return new Date(date).toISOString().split('T')[0];
//   };

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   return (
//     <div className={`h-full border-2 rounded-lg p-4 shadow-md bg-white ${isGuest ? 'opacity-50' : ''}`}>
//       <div>
//         <h2 className="text-xl font-bold py-2 px-2" 
//           style={styles.title}>
//             Today's Events
//         </h2>
//         <div className="h-[110px] overflow-y-auto">
//           {todayEvents.length > 0 ? (
//             todayEvents.map((event, index) => (
//               <div key={index} className="border p-4 rounded-md shadow-md cursor-pointer flex items-center" 
//                    onClick={() => { if (!isGuest) { setSelectedEvent(event); setIsModalOpen(true); } }}>
//                 <div className="flex-shrink-0 mr-4">
//                   <i className="fas fa-calendar-alt text-blue-500"></i>
//                 </div>
//                 <div>
//                   <div className="font-semibold">{event.name}</div>
//                   <div className="text-sm text-gray-600">{formatDate(event.startDate)} at {event.location}</div>
//                   <div className={`text-xs px-2 py-1 rounded ${getEventStatus(event) === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
//                     {getEventStatus(event) === 'booked' ? 'Booked' : 'Unbooked'}
//                   </div>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div className="border p-8 bg-white rounded-md shadow-md text-center">
//               <div className="text-sm text-gray-600">No events today.</div>
//             </div>
//           )}
//         </div>
//       </div>
//       <div>
//         <h2 className="text-xl font-bold py-2 px-2" 
//           style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//             Upcoming Events
//         </h2>
//         <div className="h-48 overflow-y-auto">
//           {upcomingEvents.length > 0 ? (
//             upcomingEvents.map((event, index) => (
//               <div key={index} className="border bg-white p-4 rounded-md shadow-md cursor-pointer flex items-center" 
//                    onClick={() => { if (!isGuest) { setSelectedEvent(event); setIsModalOpen(true); } }}>
//                 <div className="flex-shrink-0 mr-4">
//                   <i className="fas fa-calendar-alt text-blue-500"></i>
//                 </div>
//                 <div>
//                   <div className="font-semibold">{event.name}</div>
//                   <div className="text-sm text-gray-600">{formatDate(event.startDate)} at {event.location}</div>
//                   <div className={`flex justify-center text-xs px-2 py-1 rounded ${getEventStatus(event) === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
//                     {getEventStatus(event) === 'booked' ? 'Booked' : 'Unbooked'}
//                   </div>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div className="border p-4 rounded-md shadow-md text-center">
//               <div className="text-sm text-gray-600">No upcoming events.</div>
//             </div>
//           )}
//         </div>
//       </div>
//       {isGuest && (
//         <div className="text-center text-red-600 font-bold">Enroll classes to access these events.</div>
//       )}
//       {selectedEvent && isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white rounded-lg p-6 mx-4 max-w-md w-full">
//             <h3 className="text-xl font-semibold mb-4 flex items-center" style={{ color: theme.textColor }}>
//               📅 Event Details
//             </h3>
//             <div className="font-semibold text-lg">{selectedEvent.name}</div>
//             <div className="text-sm text-gray-600">{formatDate(selectedEvent.startDate)} at {selectedEvent.location}</div>
//             <div className="text-sm text-gray-600 mt-2">{selectedEvent.description}</div>
//             <div className={`flex justify-center text-xs px-2 py-1 rounded mt-6 ${getEventStatus(selectedEvent) === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
//               {getEventStatus(selectedEvent) === 'booked' ? 'Booked' : 'Unbooked'}
//             </div>
//             <button onClick={() => setIsModalOpen(false)} className="mt-4 px-4 py-2 rounded w-full" style={{ backgroundColor: theme.primaryColor, color: theme.text2Color }}>
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserEvent;






// // udpate code --> 25 june 2024
// // src/components/client/Dashboard/UserEvent.tsx

// import React, { useState } from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import useUserStage from '../../../hooks/Dashboard/useUserStage';

// interface Event {
//   title: string;
//   date: string;
//   time: string;
//   location: string;
//   description: string;
//   status: 'booked' | 'not booking';
// }

// const events: Event[] = [
//   { title: '1 to Many with Casper', date: '2024-06-12', time: '11:00 AM', location: 'Online Class', description: 'Master Casper Question and answer session for R One Student', status: 'booked' },
//   { title: 'R One SOP Classes', date: '2024-07-10', time: '11:59 PM', location: 'R one Office', description: 'Classes for R One Member trained for SOP', status: 'not booking' },
//   { title: 'R One Trader Program - Examinee Sharing', date: '2024-06-23', time: '22:00 PM', location: 'R one Office', description: 'Team building activities', status: 'booked' },
//   { title: 'R One Trader Program - Trader Training', date: '2024-07-24', time: '20:00 PM', location: 'R one Office', description: 'Team building activities', status: 'booked' },
// ];

// const UserEvent: React.FC = () => {
//   const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const { theme } = useTheme();
//   const { isGuest } = useUserStage();

//   // Filter today's events
//   const todayEvents = events.filter(event => new Date(event.date).toDateString() === new Date().toDateString());

//   // Filter upcoming events
//   const upcomingEvents = events.filter(event => new Date(event.date) > new Date());

//   return (
//     <div className={`space-y-6 bg-white rounded-lg p-4 shadow-md ${isGuest ? 'opacity-50' : ''}`}>
//       <div>
//         <h2 className="text-xl font-bold py-2 px-2"
//           style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>Today's Events</h2>
//         {todayEvents.length > 0 ? (
//           todayEvents.map((event, index) => (
//             <div key={index} className="border p-4 rounded-md shadow-md cursor-pointer flex items-center" 
//                  onClick={() => { if (!isGuest) { setSelectedEvent(event); setIsModalOpen(true); } }}>
//               <div className="flex-shrink-0 mr-4">
//                 <i className="fas fa-calendar-alt text-blue-500"></i>
//               </div>
//               <div>
//                 <div className="font-semibold">{event.title}</div>
//                 <div className="text-sm text-gray-600">{event.date} at {event.time}</div>
//                 <div className={`text-xs px-2 py-1 rounded ${event.status === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
//                   {event.status === 'booked' ? 'Booked' : 'Unbooked'}
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <div className="border p-4 rounded-md shadow-md text-center">
//             <div className="text-sm text-gray-600">No events today.</div>
//           </div>
//         )}
//       </div>
//       <div>
//         <h2 className="text-xl font-bold py-2 px-2"
//           style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>Upcoming Events</h2>
//         {upcomingEvents.length > 0 ? (
//           upcomingEvents.map((event, index) => (
//             <div key={index} className="border p-4 rounded-md shadow-md cursor-pointer flex items-center" 
//                  onClick={() => { if (!isGuest) { setSelectedEvent(event); setIsModalOpen(true); } }}>
//               <div className="flex-shrink-0 mr-4">
//                 <i className="fas fa-calendar-alt text-blue-500"></i>
//               </div>
//               <div>
//                 <div className="font-semibold">{event.title}</div>
//                 <div className="text-sm text-gray-600">{event.date} at {event.time}</div>
//                 <div className={`text-xs px-2 py-1 rounded ${event.status === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
//                   {event.status === 'booked' ? 'Booked' : 'Unbooked'}
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <div className="border p-4 rounded-md shadow-md text-center">
//             <div className="text-sm text-gray-600">No upcoming events.</div>
//           </div>
//         )}
//       </div>
//       {isGuest && (
//         <div className="text-center text-red-600 font-bold">Enroll classes to access these events.</div>
//       )}
//       {/* Event details modal */}
//       {selectedEvent && isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//           <div className="bg-white rounded-lg p-6 mx-4 max-w-md w-full">
//             <h3 className="text-xl font-semibold mb-4 flex items-center" style={{ color: theme.textColor }}>
//               📅 Event Details
//             </h3>
//             <div className="font-semibold text-lg">{selectedEvent.title}</div>
//             <div className="text-sm text-gray-600">{selectedEvent.date} at {selectedEvent.time}</div>
//             <div className="text-sm text-gray-600">{selectedEvent.location}</div>
//             <div className="text-sm text-gray-600 mt-2">{selectedEvent.description}</div>
//             <div className={`text-xs px-2 py-1 rounded mt-2 ${selectedEvent.status === 'booked' ? 'bg-green-200' : 'bg-red-200'}`}>
//               {selectedEvent.status === 'booked' ? 'Booked' : 'Unbooked'}
//             </div>
//             <button onClick={() => setIsModalOpen(false)} className="mt-4 px-4 py-2 rounded w-full" style={{ backgroundColor: theme.primaryColor, color: theme.text2Color }}>
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserEvent;
