// src/components/admin/admin07_traderprogram.tsx

import React from 'react';

const TraderProgram: React.FC = () => {
  return (
    <h1>This is Trader Program Section</h1>
  );
};

export default TraderProgram;