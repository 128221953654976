// src/hooks/login/useVerifly.tsx

import { useState } from 'react';
import { useEnv } from '../../context/EnvContext'; // Import the useEnv hook

export const useVerifly = () => {
  const { apiUrl } = useEnv(); // Use the useEnv hook to get the apiUrl
  const [error, setError] = useState<string | null>(null);

  const verifyAccount = async (token: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/verify/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message);
        return false;
      }

      return true;
    } catch (err) {
      setError('Verification failed. Please try again.');
      return false;
    }
  };

  const resendVerificationEmail = async (email: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/resend-verification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message);
        return false;
      }

      return true;
    } catch (err) {
      setError('Resend verification email failed. Please try again.');
      return false;
    }
  };

  return { verifyAccount, resendVerificationEmail, error, setError };
};


// // update code --> 10 june 2024
// // src/hooks/useVerifly.tsx

// import { useState } from 'react';
// import { useEnv } from '../../context/EnvContext'; // Import the useEnv hook

// export const useVerifly = () => {
//   const { apiUrl } = useEnv(); // Use the useEnv hook to get the apiUrl
//   const [error, setError] = useState<string | null>(null);

//   const verifyAccount = async (token: string) => {
//     try {
//       const response = await fetch(`${apiUrl}/api/verify/${token}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         setError(errorData.message);
//         return false;
//       }

//       return true;
//     } catch (err) {
//       setError('Verification failed. Please try again.');
//       return false;
//     }
//   };

//   return { verifyAccount, error, setError };
// };


