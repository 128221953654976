// src/components/AuthRoute.tsx

import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface AuthRouteProps {
  children: JSX.Element;
  requiresAuth?: boolean;
  requiresToken?: boolean;
  token?: string | null;
}

const AuthRoute: React.FC<AuthRouteProps> = ({
  children,
  requiresAuth = false,
  requiresToken = false,
  token = null,
}) => {
  const { isAuthenticated, checkTokenExpiration, setUserFromToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        const isExpired = checkTokenExpiration(storedToken);
        if (isExpired) {
          setLoading(false);
          return;
        }
        await setUserFromToken(storedToken);
      }
      setLoading(false);
    };

    if (!isAuthenticated) {
      verifyToken();
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, checkTokenExpiration, setUserFromToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (requiresAuth && !isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (requiresToken && !token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default AuthRoute;


// // update code --> 12 july 2024
// // src/components/AuthRoute.tsx

// import React, { useEffect, useState } from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';

// interface AuthRouteProps {
//   children: JSX.Element;
//   requiresAuth?: boolean;
//   requiresToken?: boolean;
//   token?: string | null;
// }

// const AuthRoute: React.FC<AuthRouteProps> = ({
//   children,
//   requiresAuth = false,
//   requiresToken = false,
//   token = null
// }) => {
//   const { isAuthenticated, logout, checkTokenExpiration } = useAuth();
//   const [shouldRedirect, setShouldRedirect] = useState(false);

//   useEffect(() => {
//     if (requiresAuth && token) {
//       const isExpired = checkTokenExpiration(token);
//       if (isExpired) {
//         logout();
//         setShouldRedirect(true);
//       }
//     }
//   }, [token, requiresAuth, logout, checkTokenExpiration]);

//   if (shouldRedirect) {
//     return <Navigate to="/login" />;
//   }

//   if (requiresAuth && !isAuthenticated) {
//     return <Navigate to="/login" />;
//   }

//   if (requiresToken && !token) {
//     return <Navigate to="/login" />;
//   }

//   return children;
// };

// export default AuthRoute;


// // udpte code --> 04 july 2024
// // src/components/AuthRoute.tsx

// import React, { useEffect } from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';

// interface AuthRouteProps {
//   children: JSX.Element;
//   requiresAuth?: boolean;
//   requiresToken?: boolean;
//   token?: string | null;
// }

// const AuthRoute: React.FC<AuthRouteProps> = ({
//   children,
//   requiresAuth = false,
//   requiresToken = false,
//   token = null
// }) => {
//   const { isAuthenticated, logout } = useAuth();

//   useEffect(() => {
//     if (requiresAuth && token) {
//       try {
//         const decodedToken = JSON.parse(atob(token.split('.')[1]));
//         const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  
//         if (currentTime > decodedToken.exp) {
//           logout(); // Automatically log out if token is expired
//         }
//       } catch (error) {
//         console.error('Token decoding error:', error);
//         logout(); // Handle invalid token
//       }
//     }
//   }, [token, requiresAuth, logout]);

//   if (requiresAuth && !isAuthenticated) {
//     return <Navigate to="/login" />;
//   }

//   if (requiresToken && !token) {
//     return <Navigate to="/login" />;
//   }

//   return children;
// };

// export default AuthRoute;

// // update code --> 03 july 2024 
// // src/components/AuthRoute.tsx

// import React, { useEffect } from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';

// interface AuthRouteProps {
//   children: JSX.Element;
//   requiresAuth?: boolean;
//   requiresToken?: boolean;
//   token?: string | null;
// }

// const AuthRoute: React.FC<AuthRouteProps> = ({
//   children,
//   requiresAuth = false,
//   requiresToken = false,
//   token = null
// }) => {
//   const { isAuthenticated, logout } = useAuth();

//   useEffect(() => {
//     if (token) {
//       try {
//         const decodedToken = JSON.parse(atob(token.split('.')[1]));
//         const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

//         if (currentTime > decodedToken.exp) {
//           logout(); // Automatically log out if token is expired
//         }
//       } catch (error) {
//         console.error('Token decoding error:', error);
//         logout(); // Handle invalid token
//       }
//     }
//   }, [token, logout]);

//   if (requiresAuth && !isAuthenticated) {
//     return <Navigate to="/login" />;
//   }

//   if (requiresToken && !token) {
//     return <Navigate to="/login" />;
//   }

//   return children;
// };

// export default AuthRoute;

// // update code --> 02 july 2024
// // src/components/AuthRoute.tsx

// import React, { useEffect } from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';

// interface AuthRouteProps {
//   children: JSX.Element;
//   requiresAuth?: boolean;
//   requiresToken?: boolean;
//   token?: string | null;
// }

// const AuthRoute: React.FC<AuthRouteProps> = ({
//   children,
//   requiresAuth = false,
//   requiresToken = false,
//   token = null
// }) => {
//   const { isAuthenticated, logout } = useAuth();

//   useEffect(() => {
//     if (token) {
//       const decodedToken = JSON.parse(atob(token.split('.')[1]));
//       const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

//       if (currentTime > decodedToken.exp) {
//         logout(); // Automatically log out if token is expired
//       }
//     }
//   }, [token, logout]);

//   if (requiresAuth && !isAuthenticated) {
//     return <Navigate to="/login" />;
//   }

//   if (requiresToken && !token) {
//     return <Navigate to="/login" />;
//   }

//   return children;
// };

// export default AuthRoute;
