// src/hooks/Subscription/useSubscriptionData.tsx

import { useState, useEffect, useCallback  } from 'react';
import { useEnv } from '../../context/EnvContext';
import moment from 'moment-timezone';

export interface UserRole {
  memberRole: string;
}
export interface Subscription {
  // algo matrix data
  id: number;
  name: string;
  userName: string;
  email: string;
  image: string;
  planID?: string; 
  planName: string;
  planType: string;
  planAmount: string;
  planSubDate: string;
  planPeriod: string;
  planExpiredDate: string;
  planDays: string;
  planTrialPeriod: string;
  planExpiredTrialDate: string;
  planTrialDays: string;
  accountID: string;
  accountType: string;
  accountNumber: string;
  accountServer: string;
  accountPassword: string;
  accountStatus: string;
  productID: string;
  productImage?: string;
  productName?: string;
  showPassword?: boolean;
  fileName?: string;
  product?: Product;
  // trader hub data
  telegramNameID?: string;
  planNameHub?: string;
  planTypeHub?: string;
  planPeriodHub?: string;
  planPeriodTrialHub?: string;
  renewalDate?: string;
  expiryDate?: string;
  expireDays?: string;
  status?: string;
}

export interface Plan {
  id: number;
  planID: string;
  planName: string;
  planDescription: string;
  planFeatures: string[];
  planAmount: string;
  planCurrency: string;
  planPeriod: string;
  planTrialPeriod: string;
  planRequirement: string;
  trialRequirement: string[];
}

export interface Product {
  id: number;
  productName: string;
  productDescription: string;
  image: string;
  plans: Plan[];
}


interface SubscriptionData {
  algoSubscriptions: Subscription[];
  traderHubSubscriptions: Subscription[];
  products: Product[];
  plans: any[];
  vouchers: any[];
  trials: any[];
  trialInfo: string;
  versionNumber: string;
}

const useSubscriptionData = () => {
  const { apiUrl } = useEnv();
  const [data, setData] = useState<SubscriptionData>({
    algoSubscriptions: [],
    traderHubSubscriptions: [],
    products: [],
    plans: [],
    vouchers: [],
    trials: [],
    versionNumber: '',
    trialInfo: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const formatDate = (date: string) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const convertPlanPeriod = (period: string) => {
    switch (period) {
      case '30':
        return 'Monthly';
      case '90':
        return 'Quarterly';
      case '365':
        return 'Annually';
      case '0':
        return 'None';
      default:
        return period;
    }
  };

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Active':
        return 'bg-green-600 text-white';
      case 'Deactive':
        return 'bg-green-800 text-white';
      case 'Pending':
        return 'bg-yellow-300 text-black'
      case 'Expire':
        return 'bg-rose-500 text-white';
      default:
        return '';
    }
  };

  const getHubStatusStyle = (status: string) => {
    switch (status) {
      case 'Active':
        return 'bg-green-600 text-white';
      case 'Deactive':
        return 'bg-gray-300 text-white';
      case 'Pending':
        return 'bg-yellow-300 text-black'
      case 'Expire':
        return 'bg-rose-500 text-white';
      default:
        return '';
    }
  };

  const calculateRemainingDays = (planExpiredDate: string) => {
    const currentDate = moment().tz("Asia/Kuala_Lumpur");
    const expiredDate = moment(planExpiredDate);
    return expiredDate.diff(currentDate, 'days');
  };

  const fetchData = useCallback(async () => {
    try {
      const [subscriptions, products, plans, vouchers, trials, version, trialInfo] = await Promise.all([
        fetch(`${apiUrl}/api/subscriptions`).then((res) => res.json()),
        fetch(`${apiUrl}/api/products`).then((res) => res.json()),
        fetch(`${apiUrl}/api/plans`).then((res) => res.json()),
        fetch(`${apiUrl}/api/vouchers`).then((res) => res.json()),
        fetch(`${apiUrl}/api/trials`).then((res) => res.json()),
        fetch(`${apiUrl}/api/version`).then((res) => res.json()),
        fetch(`${apiUrl}/api/trial-info`).then((res) => res.json()),
      ]);

      
      const updatedAlgoSubscriptions = subscriptions.algoSubscriptions.map((sub: Subscription) => {
        const product = products.find((product: Product) => product.id === parseInt(sub.productID, 10));
        const remainingDays = calculateRemainingDays(sub.planExpiredDate);
        const planTrialDays = sub.planType === 'Trial' ? calculateRemainingDays(sub.planExpiredTrialDate) : null;

        // Update account status if necessary
        const isExpired = remainingDays < 0;
        const accountStatus = remainingDays < 0 ? 'Expire' : sub.accountStatus;
        return {
          ...sub,
          planDays: isExpired ? '0' : remainingDays.toString(),
          planTrialDays,
          productName: product ? product.productName : '',
          accountStatus,
          product,
        };
      });

      // Filter out "Trial" subscriptions with "Expire" status if a corresponding "Renew" subscription exists
      const filteredAlgoSubscriptions = updatedAlgoSubscriptions.filter((sub: Subscription) => {
        if (sub.planType === 'Trial' && sub.accountStatus === 'Expire') {
          const hasRenewal = updatedAlgoSubscriptions.some(
            (otherSub: Subscription) =>
              otherSub.planType === 'Renew' &&
              otherSub.userName === sub.userName &&
              otherSub.email === sub.email &&
              otherSub.accountNumber === sub.accountNumber
          );
          return !hasRenewal;
        }
        return true;
      });

      // Map the TraderHub subscriptions
      const filterTraderHubSubscriptions = subscriptions.traderHubSubscriptions.map((sub: Subscription) => {
        const remainingDays = sub.expiryDate ? calculateRemainingDays(sub.expiryDate) : 0;

        // Update account status if necessary
        const isExpired = remainingDays < 0;
        const accountStatus = remainingDays < 0 ? 'Expire' : sub.status;

        return {
          id: sub.id,
          productName: 'TraderHub',
          accountStatus,
          name: sub.name,
          email: sub.email,
          telegramNameID: sub.telegramNameID,
          planName: sub.planNameHub,
          planType: sub.planTypeHub,
          planTrialsDays: sub.planPeriodTrialHub,
          planPeriod: sub.planPeriodHub,
          planExpiredDate: sub.expiryDate,
          planDays: isExpired ? '0' : remainingDays.toString(),
          image: sub.image,
        };
      });

      setData({
        traderHubSubscriptions: filterTraderHubSubscriptions, 
        algoSubscriptions: filteredAlgoSubscriptions,
        products,
        plans,
        vouchers,
        trials,
        versionNumber: version.versionNumber,
        trialInfo: trialInfo.trialInfo,
      });

    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

  const fetchTrialInfo = useCallback(async (email: string, planID: number) => {
    if (!email) {
      console.error('Email parameter is required');
      return { trialInfo: 'Email parameter is required', trialRequirement: '', trialDays: null };
    }

    try {
      const encodedEmail = encodeURIComponent(email);
      const response = await fetch(`${apiUrl}/api/trial-info?email=${encodedEmail}&planID=${planID}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      return { 
        trialInfo: result.trialInfo, 
        trialRequirement: result.trialRequirement, 
        trialDays: result.trialDays
       };
    } catch (error) {
      console.error('Error fetching trial info:', error);
      return { trialInfo: 'Error fetching trial info', planRequirement: '' };
    }
  }, [apiUrl]);
   
  // Fetch user role
  const fetchUserRole = async (email: string, apiUrl: string): Promise<string | null> => {
    try {
        const response = await fetch(`${apiUrl}/api/user-role?email=${encodeURIComponent(email)}`);
        if (response.ok) {
            const data: UserRole = await response.json();
            return data.memberRole;
          } else {
            console.log("fetchUserRole failed with status:", response.status); // Debugging log
          }
    } catch (error) {
        console.error('Error fetching user role:', error);
    }
    return null;
  };

  // function to fetch the trial period for TraderHub
  const fetchTrialPeriod = async (planType: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/trial-period?planType=${planType}`);
      const data = await response.json();

      if (response.ok && data.planPeriodTrialHub) {
        return data.planPeriodTrialHub;
      } else {
        console.error('Error fetching TraderHub trial period:', data.error || 'Unknown error');
        return null;
      }
    } catch (error) {
      console.error('Error fetching TraderHub trial period:', error);
      return null;
    }
  };

  // Fetch trial entitlement
  const checkTrialEntitlement = async (email: string, planID: number, apiUrl: string): Promise<boolean | object> => {
    try {
      const response = await fetch(`${apiUrl}/api/trials?email=${encodeURIComponent(email)}&planID=${planID}`);
      if (response.ok) {
        const data = await response.json();
  
// << --------------------- Hardcoded ------------------------- >> //// << --------------------- Hardcoded ------------------------- >> //
        // Filter the trials relevant to the given product ID (planID: 4 maps to planIDs 7 and 8)
        const trialData = data.filter((trial: any) => trial.planID === 3 || trial.planID === 4);
// << --------------------- Hardcoded ------------------------- >> //// << --------------------- Hardcoded ------------------------- >> //

        if (trialData.length > 0) {
          // Assuming that we can process the first matching trial
          const selectedTrial = trialData[0];
  
          return { 
            trialInfo: selectedTrial.trialInfo, 
            trialMemberType: selectedTrial.trialMembersType, 
            trialDays: selectedTrial.trialDays 
          };
        }
      }
    } catch (error) {
      console.error('Error checking trial entitlement:', error);
    }
    return false;
  };   

  const fetchUserRoleAndEntitlement = useCallback(async (email: string, planID: number) => {
  // console.log(`fetchUserRoleAndEntitlement called with email: ${email}, planID: ${planID}`);
    try {
      // Fetch the user role and trial entitlement concurrently
      const [userRoleResponse, entitlementResponse] = await Promise.all([
        fetchUserRole(email, apiUrl),
        checkTrialEntitlement(email, planID, apiUrl) 
      ]);

      // Ensure the user role response is valid
      if (!userRoleResponse || !entitlementResponse || typeof entitlementResponse !== 'object' || !('trialMemberType' in entitlementResponse)) {
        console.error('Error: Missing or invalid response data.');
        return { userRole: null, isEntitled: false };
      }

      // console.log('fetchUserRole success:', userRoleResponse);
      // console.log('checkTrialEntitlement success:', entitlementResponse);

      // Clean the user roles
      const cleanedRoles = typeof userRoleResponse === 'string'
        ? userRoleResponse.replace(/[[\]"]/g, '').split(',').map((role) => role.trim())
        : [];

      // console.log('Cleaned user roles:', cleanedRoles);

      // Clean the trial member types
      const trialMemberTypes = Array.isArray(entitlementResponse.trialMemberType)
        ? entitlementResponse.trialMemberType.map((role: string) => role.trim())
        : [];

      // console.log('Cleaned trial member types:', trialMemberTypes);

      // Match user roles with trial member types to determine entitlement
      const isEntitled = cleanedRoles.some((role) => trialMemberTypes.includes(role));

      // console.log('fetchUserRoleAndEntitlement result:', { userRole: cleanedRoles, isEntitled });

      return { userRole: cleanedRoles, isEntitled };
    } catch (error) {
      console.error('Error fetching user role and entitlement:', error);
      return { userRole: null, isEntitled: false };
    }
  }, [apiUrl]);

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/version`);
        const versionData = await response.json();
        setData((prevData) => ({
          ...prevData,
          versionNumber: versionData.versionNumber,
        }));
      } catch (error) {
        console.error('Error fetching version:', error);
      }
    };
  
    fetchVersion();
  }, [apiUrl]);  

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { 
    data, 
    loading, 
    error, 
    formatDate, 
    convertPlanPeriod, 
    getStatusStyle,
    getHubStatusStyle, 
    fetchData, 
    fetchTrialInfo,
    fetchTrialPeriod,
    // fetchTrialPeriod,
    calculateRemainingDays,
    fetchUserRoleAndEntitlement,

  };
};

export default useSubscriptionData;

// // update code --> 01 Sept 2024
// // src/hooks/Subscription/useSubscriptionData.tsx

// import { useState, useEffect, useCallback  } from 'react';
// import { useEnv } from '../../context/EnvContext';
// import moment from 'moment-timezone';

// export interface UserRole {
//   memberRole: string;
// }
// export interface Subscription {
//   // algo matrix data
//   id: number;
//   name: string;
//   userName: string;
//   email: string;
//   image: string;
//   planID?: string; 
//   planName: string;
//   planType: string;
//   planAmount: string;
//   planSubDate: string;
//   planPeriod: string;
//   planExpiredDate: string;
//   planDays: string;
//   planTrialPeriod: string;
//   planExpiredTrialDate: string;
//   planTrialDays: string;
//   accountID: string;
//   accountType: string;
//   accountNumber: string;
//   accountServer: string;
//   accountPassword: string;
//   accountStatus: string;
//   productID: string;
//   productImage?: string;
//   productName?: string;
//   showPassword?: boolean;
//   fileName?: string;
//   product?: Product;
//   // trader hub data
//   telegramNameID?: string;
//   planNameHub?: string;
//   planTypeHub?: string;
//   planPeriodHub?: string;
//   renewalDate?: string;
//   expiryDate?: string;
//   expireDays?: string;
//   status?: string;
// }

// export interface Plan {
//   id: number;
//   planID: string;
//   planName: string;
//   planDescription: string;
//   planFeatures: string[];
//   planAmount: string;
//   planCurrency: string;
//   planPeriod: string;
//   planTrialPeriod: string;
//   planRequirement: string;
//   trialRequirement: string[];
// }

// export interface Product {
//   id: number;
//   productName: string;
//   productDescription: string;
//   image: string;
//   plans: Plan[];
// }


// interface SubscriptionData {
//   algoSubscriptions: Subscription[];
//   traderHubSubscriptions: Subscription[];
//   products: Product[];
//   plans: any[];
//   vouchers: any[];
//   trials: any[];
//   trialInfo: string;
//   versionNumber: string;
// }

// const useSubscriptionData = () => {
//   const { apiUrl } = useEnv();
//   const [data, setData] = useState<SubscriptionData>({
//     algoSubscriptions: [],
//     traderHubSubscriptions: [],
//     products: [],
//     plans: [],
//     vouchers: [],
//     trials: [],
//     versionNumber: '',
//     trialInfo: '',
//   });
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   const formatDate = (date: string) => {
//     return moment(date).format('YYYY-MM-DD');
//   };

//   const convertPlanPeriod = (period: string) => {
//     switch (period) {
//       case '30':
//         return 'Monthly';
//       case '90':
//         return 'Quarterly';
//       case '365':
//         return 'Annually';
//       case '0':
//         return 'None';
//       default:
//         return period;
//     }
//   };

//   const getStatusStyle = (status: string) => {
//     switch (status) {
//       case 'Active':
//         return 'bg-green-600 text-white';
//       case 'Deactive':
//         return 'bg-green-800 text-white';
//       case 'Pending':
//         return 'bg-yellow-300 text-black'
//       case 'Expire':
//         return 'bg-rose-500 text-white';
//       default:
//         return '';
//     }
//   };

//   const getHubStatusStyle = (status: string) => {
//     switch (status) {
//       case 'running':
//         return 'bg-green-600 text-white';
//       case 'stopped':
//         return 'bg-gray-300 text-white';
//       case 'pending':
//         return 'bg-yellow-300 text-black'
//       case 'expire':
//         return 'bg-rose-500 text-white';
//       default:
//         return '';
//     }
//   };

//   const calculateRemainingDays = (planExpiredDate: string) => {
//     const currentDate = moment().tz("Asia/Kuala_Lumpur");
//     const expiredDate = moment(planExpiredDate);
//     return expiredDate.diff(currentDate, 'days');
//   };

//   const fetchData = useCallback(async () => {
//     try {
//       const [subscriptions, products, plans, vouchers, trials, version, trialInfo] = await Promise.all([
//         fetch(`${apiUrl}/api/subscriptions`).then((res) => res.json()),
//         fetch(`${apiUrl}/api/products`).then((res) => res.json()),
//         fetch(`${apiUrl}/api/plans`).then((res) => res.json()),
//         fetch(`${apiUrl}/api/vouchers`).then((res) => res.json()),
//         fetch(`${apiUrl}/api/trials`).then((res) => res.json()),
//         fetch(`${apiUrl}/api/version`).then((res) => res.json()),
//         fetch(`${apiUrl}/api/trial-info`).then((res) => res.json()),
//       ]);

      
//       const updatedAlgoSubscriptions = subscriptions.algoSubscriptions.map((sub: Subscription) => {
//         const product = products.find((product: Product) => product.id === parseInt(sub.productID, 10));
//         const remainingDays = calculateRemainingDays(sub.planExpiredDate);
//         const planTrialDays = sub.planType === 'Trial' ? calculateRemainingDays(sub.planExpiredTrialDate) : null;

//         // Update account status if necessary
//         const isExpired = remainingDays < 0;
//         const accountStatus = remainingDays < 0 ? 'Expire' : sub.accountStatus;
//         return {
//           ...sub,
//           planDays: isExpired ? '0' : remainingDays.toString(),
//           planTrialDays,
//           productName: product ? product.productName : '',
//           accountStatus,
//           product,
//         };
//       });

//       // Filter out "Trial" subscriptions with "Expire" status if a corresponding "Renew" subscription exists
//       const filteredAlgoSubscriptions = updatedAlgoSubscriptions.filter((sub: Subscription) => {
//         if (sub.planType === 'Trial' && sub.accountStatus === 'Expire') {
//           const hasRenewal = updatedAlgoSubscriptions.some(
//             (otherSub: Subscription) =>
//               otherSub.planType === 'Renew' &&
//               otherSub.userName === sub.userName &&
//               otherSub.email === sub.email &&
//               otherSub.accountNumber === sub.accountNumber
//           );
//           return !hasRenewal;
//         }
//         return true;
//       });

//       // Map the TraderHub subscriptions
//       const filterTraderHubSubscriptions = subscriptions.traderHubSubscriptions.map((sub: Subscription) => ({
//         id: sub.id,
//         productName: 'TraderHub',
//         accountStatus: sub.status,
//         name: sub.name,
//         email: sub.email,
//         telegramNameID: sub.telegramNameID,
//         planName: sub.planNameHub,
//         planType: sub.planTypeHub,
//         planPeriod: sub.planPeriodHub,
//         planExpiredDate: sub.expiryDate,
//         planDays: sub.expireDays,
//         image: sub.image,
//       }));

//       setData({
//         traderHubSubscriptions: filterTraderHubSubscriptions, 
//         algoSubscriptions: filteredAlgoSubscriptions,
//         products,
//         plans,
//         vouchers,
//         trials,
//         versionNumber: version.versionNumber,
//         trialInfo: trialInfo.trialInfo,
//       });

//     } catch (error) {
//       setError((error as Error).message);
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const fetchTrialInfo = useCallback(async (email: string, planID: number) => {
//     if (!email) {
//       console.error('Email parameter is required');
//       return { trialInfo: 'Email parameter is required', trialRequirement: '', trialDays: null };
//     }

//     try {
//       const encodedEmail = encodeURIComponent(email);
//       const response = await fetch(`${apiUrl}/api/trial-info?email=${encodedEmail}&planID=${planID}`);
      
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }

//       const result = await response.json();

//       return { 
//         trialInfo: result.trialInfo, 
//         trialRequirement: result.trialRequirement, 
//         trialDays: result.trialDays
//        };
//     } catch (error) {
//       console.error('Error fetching trial info:', error);
//       return { trialInfo: 'Error fetching trial info', planRequirement: '' };
//     }
//   }, [apiUrl]);
   
//   // Fetch user role
//   const fetchUserRole = async (email: string, apiUrl: string): Promise<string | null> => {
//     try {
//         const response = await fetch(`${apiUrl}/api/user-role?email=${encodeURIComponent(email)}`);
//         if (response.ok) {
//             const data: UserRole = await response.json();
//             console.log("fetchUserRole success:", data); // Debugging log
//             return data.memberRole;
//           } else {
//             console.log("fetchUserRole failed with status:", response.status); // Debugging log
//           }
//     } catch (error) {
//         console.error('Error fetching user role:', error);
//     }
//     return null;
//   };

//   // Fetch trial entitlement
//   const checkTrialEntitlement = async (email: string, planID: number, apiUrl: string): Promise<boolean | object> => {
//     try {
//       const response = await fetch(`${apiUrl}/api/trials?email=${encodeURIComponent(email)}&planID=${planID}`);
//       if (response.ok) {
//         const data = await response.json();
  
//         // Filter the trials relevant to the given product ID (planID: 4 maps to planIDs 7 and 8)
//         const trialData = data.filter((trial: any) => trial.planID === 7 || trial.planID === 8);
  
//         if (trialData.length > 0) {
//           // Assuming that we can process the first matching trial
//           const selectedTrial = trialData[0];
  
//           // // Log specifically the trialMembersType for debugging
//           // console.log("Fetched trialMembersType data:", selectedTrial.trialMembersType);
  
//           return { 
//             trialInfo: selectedTrial.trialInfo, 
//             trialMemberType: selectedTrial.trialMembersType, 
//             trialDays: selectedTrial.trialDays 
//           };
//         // } else {
//         //   console.log(`No relevant trial found for product ID: ${planID}`);
//         }
//       // } else {
//       //   console.log("checkTrialEntitlement failed with status:", response.status);
//       }
//     } catch (error) {
//       console.error('Error checking trial entitlement:', error);
//     }
//     return false;
//   };   

//   const fetchUserRoleAndEntitlement = useCallback(async (email: string, planID: number) => {
//   // console.log(`fetchUserRoleAndEntitlement called with email: ${email}, planID: ${planID}`);
//     try {
//       // Fetch the user role and trial entitlement concurrently
//       const [userRoleResponse, entitlementResponse] = await Promise.all([
//         fetchUserRole(email, apiUrl),
//         checkTrialEntitlement(email, planID, apiUrl) // planID 4 will map to planIDs 7 and 8 within this function
//       ]);

//       // Ensure the user role response is valid
//       if (!userRoleResponse || !entitlementResponse || typeof entitlementResponse !== 'object' || !('trialMemberType' in entitlementResponse)) {
//         console.error('Error: Missing or invalid response data.');
//         return { userRole: null, isEntitled: false };
//       }

//       // console.log('fetchUserRole success:', userRoleResponse);
//       // console.log('checkTrialEntitlement success:', entitlementResponse);

//       // Clean the user roles
//       const cleanedRoles = typeof userRoleResponse === 'string'
//         ? userRoleResponse.replace(/[[\]"]/g, '').split(',').map((role) => role.trim())
//         : [];

//       // console.log('Cleaned user roles:', cleanedRoles);

//       // Clean the trial member types
//       const trialMemberTypes = Array.isArray(entitlementResponse.trialMemberType)
//         ? entitlementResponse.trialMemberType.map((role: string) => role.trim())
//         : [];

//       // console.log('Cleaned trial member types:', trialMemberTypes);

//       // Match user roles with trial member types to determine entitlement
//       const isEntitled = cleanedRoles.some((role) => trialMemberTypes.includes(role));

//       // console.log('fetchUserRoleAndEntitlement result:', { userRole: cleanedRoles, isEntitled });

//       return { userRole: cleanedRoles, isEntitled };
//     } catch (error) {
//       console.error('Error fetching user role and entitlement:', error);
//       return { userRole: null, isEntitled: false };
//     }
//   }, [apiUrl]);

//   useEffect(() => {
//     const fetchVersion = async () => {
//       try {
//         const response = await fetch(`${apiUrl}/api/version`);
//         const versionData = await response.json();
//         setData((prevData) => ({
//           ...prevData,
//           versionNumber: versionData.versionNumber,
//         }));
//       } catch (error) {
//         console.error('Error fetching version:', error);
//       }
//     };
  
//     fetchVersion();
//   }, [apiUrl]);  

//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//   return { 
//     data, 
//     loading, 
//     error, 
//     formatDate, 
//     convertPlanPeriod, 
//     getStatusStyle,
//     getHubStatusStyle, 
//     fetchData, 
//     fetchTrialInfo, 
//     calculateRemainingDays,
//     fetchUserRoleAndEntitlement,

//   };
// };

// export default useSubscriptionData;