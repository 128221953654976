// // src/hooks/AdminDashboard/useEmailSetting.tsx

import { useState, useCallback, useEffect } from 'react';
import { useEnv } from '../../context/EnvContext'; 

interface CampaignEmail {
  id: number;
  campTitle: string;
  campType: string;
  campList: string;
  // content setting
  contentTitle: string;
  contentName: string;
  contentBody: string;
  contentButton: string;
  // email setting
  sendFrom: string;
  sendTo: string;
  sendCC: string;
  subjectMail: string;
  logicStatus?: string;
}

interface CampaignNode {
  id: number;
  workflowId: number;
  workflowName: string;
  workflowStatus: string;
  clickedStats: string;
  nodeId: number;
  nodeType: string;
  nodePosX: number;
  nodePosY: number;
  nodeData: any;
  createdAt: Date;
}

export const useEmail = () => {
  const [email, setEmail] = useState<CampaignEmail[]>([]);
  const [nodes, setNodes] = useState<CampaignNode[]>([]);
  const [workflows, setWorkflows] = useState<CampaignNode[]>([]); 
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { apiUrl } = useEnv();

  // Campaigns Email section --------------------------------------->>
  // fetching campaigns email setting
  const fetchCampaigns = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/email-campaign`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch campaigns: ${response.statusText}`);
      }

      const data = await response.json();
      setEmail(data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setError('Failed to fetch campaigns.');
    } finally {
      setLoading(false);
    }
  }, [apiUrl]); 

  const addCampaign = async (newCampaignEmail: Omit<CampaignEmail, 'id'>) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/api/email-campaign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newCampaignEmail),
      });

      if (!response.ok) {
        throw new Error(`Failed to create a campaign: ${response.statusText}`);
      }

      const data = await response.json();

      setEmail([...email, { ...newCampaignEmail, id: data.id }]);
      setLoading(false);
    } catch (error) {

      console.error('Error in addCampaign:', error);
      setError('Failed to add campaign. Please try again.');
      setLoading(false);
    }
  };

  const updateCampaign = async (updatedCampaign: CampaignEmail) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/api/email-campaign/${updatedCampaign.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedCampaign),
      });

      if (!response.ok) {
        throw new Error(`Failed to update campaign: ${response.statusText}`);
      }

      setEmail((prevEmails) =>
        prevEmails.map((email) =>
          email.id === updatedCampaign.id ? updatedCampaign : email
        )
      );
      setLoading(false);
    } catch (error) {
      console.error('Error in updateCampaign:', error); 
      setError('Failed to update campaign.');
    }
  };

  useEffect(() => {
    fetchCampaigns(); 
  }, [fetchCampaigns]);

  // created workflow section --------------------------------------->>
  // Fetch workflows
  const fetchWorkflows = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/workflows`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch workflows: ${response.statusText}`);
      }
      const data = await response.json();
      setWorkflows(data); // Set the workflows to state
    } catch (error) {
      console.error('Error fetching workflows:', error);
      setError('Failed to fetch workflows.');
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

   // Function to update the workflow status
   const updateWorkflowStatus = async (workflowId: number, newStatus: string) => {
    try {
      const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ workflowStatus: newStatus }), 
      });
  
      if (!response.ok) {
        throw new Error('Failed to update workflow status');
      }

      await fetchWorkflows(); 
    } catch (error) {
      console.error('Error updating workflow status:', error);
      throw error;
    }
  };
  

  // add a workflow
  const addWorkflow = async (newWorkflow: { workflowName: string }) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/api/workflows`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newWorkflow),
      });

      if (!response.ok) {
        throw new Error(`Failed to create workflow: ${response.statusText}`);
      }

      const data = await response.json();
      setLoading(false);
      return data;
    } catch (error) {
      console.error('Error in addWorkflow:', error);
      setError('Failed to add workflow. Please try again.');
      setLoading(false);
    }
  };

  const deleteWorkflow = async (workflowId: number) => {
    setLoading(true);
    setError(null);
  
    try {
      const response = await fetch(`${apiUrl}/api/workflows/${workflowId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to delete workflow: ${response.statusText}`);
      }
  
      setWorkflows((prevWorkflows) => prevWorkflows.filter((wf) => wf.workflowId !== workflowId));
    } catch (error) {
      setError('Failed to delete workflow.');
      console.error('Error deleting workflow:', error);
    } finally {
      setLoading(false);
    }
  };  

  // created node section --------------------------------------->>
  // Fetch nodes for a specific workflow
  const fetchNodes = useCallback(async (workflowId: number) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/nodes`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch nodes: ${response.statusText}`);
      }
      const data = await response.json();
      setNodes(data);
    } catch (error) {
      setError('Failed to fetch nodes.');
      console.error('Error fetching nodes:', error);
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

  // Create a new node
  const addNode = async (newNode: Omit<CampaignNode, 'id'>) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiUrl}/api/nodes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newNode),
      });
      if (!response.ok) {
        throw new Error(`Failed to create node: ${response.statusText}`);
      }
      const data = await response.json();
      setNodes((prevNodes) => [...prevNodes, { ...newNode, id: data.newNode.id }]);
    } catch (error) {
      setError('Failed to add node.');
      console.error('Error adding node:', error);
    } finally {
      setLoading(false);
    }
  };

  // Update an existing node
  const updateNode = async (updatedNode: CampaignNode) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiUrl}/api/nodes/${updatedNode.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedNode),
      });
      if (!response.ok) {
        throw new Error(`Failed to update node: ${response.statusText}`);
      }
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === updatedNode.id ? updatedNode : node
        )
      );
    } catch (error) {
      setError('Failed to update node.');
      console.error('Error updating node:', error);
    } finally {
      setLoading(false);
    }
  };

  // Delete a node
  const deleteNode = async (nodeId: number) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiUrl}/api/nodes/${nodeId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to delete node: ${response.statusText}`);
      }
      setNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
    } catch (error) {
      setError('Failed to delete node.');
      console.error('Error deleting node:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    email,
    addCampaign,
    updateCampaign,
    workflows,
    fetchWorkflows,
    addWorkflow,
    updateWorkflowStatus,
    deleteWorkflow,
    nodes,
    fetchNodes,
    addNode,
    updateNode,
    deleteNode,
    loading,
    error,
  };
};

// // udpate code --> 11 Sept 2024
// // // src/hooks/AdminDashboard/useEmailSetting.tsx

// import { useState, useCallback, useEffect } from 'react';
// import { useEnv } from '../../context/EnvContext'; 

// interface CampaignEmail {
//   id: number;
//   campTitle: string;
//   campType: string;
//   campList: string;
//   // content setting
//   contentTitle: string;
//   contentName: string;
//   contentBody: string;
//   contentButton: string;
//   // email setting
//   sendFrom: string;
//   sendTo: string;
//   sendCC: string;
//   subjectMail: string;
//   logicStatus?: string;
// }

// interface CampaignNode {
//   id: number;
//   workflowid: number;
//   nodeType: string;
//   nodePosX: number;
//   nodePosY: number;
//   nodeData: any;
// }

// export const useEmail = () => {
//   const [email, setEmail] = useState<CampaignEmail[]>([]);
//   const [nodes, setNodes] = useState<CampaignNode[]>([]);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();

//   const addCampaign = async (newCampaignEmail: Omit<CampaignEmail, 'id'>) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newCampaignEmail),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to create a campaign: ${response.statusText}`);
//       }

//       const data = await response.json();

//       setEmail([...email, { ...newCampaignEmail, id: data.id }]);
//       setLoading(false);
//     } catch (error) {

//       console.error('Error in addCampaign:', error);
//       setError('Failed to add campaign. Please try again.');
//       setLoading(false);
//     }
//   };

//   const updateCampaign = async (updatedCampaign: CampaignEmail) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign/${updatedCampaign.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedCampaign),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to update campaign: ${response.statusText}`);
//       }

//       setEmail((prevEmails) =>
//         prevEmails.map((email) =>
//           email.id === updatedCampaign.id ? updatedCampaign : email
//         )
//       );
//       setLoading(false);
//     } catch (error) {
//       console.error('Error in updateCampaign:', error); 
//       setError('Failed to update campaign.');
//     }
//   };

//   const fetchCampaigns = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch campaigns: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setEmail(data);
//     } catch (error) {
//       console.error('Error fetching campaigns:', error);
//       setError('Failed to fetch campaigns.');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]); 

//   useEffect(() => {
//     fetchCampaigns(); 
//   }, [fetchCampaigns]);

//   // Fetch nodes for a specific workflow
//   const fetchNodes = useCallback(async (workflowId: number) => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/workflows/${workflowId}/nodes`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       if (!response.ok) {
//         throw new Error(`Failed to fetch nodes: ${response.statusText}`);
//       }
//       const data = await response.json();
//       setNodes(data);
//     } catch (error) {
//       setError('Failed to fetch nodes.');
//       console.error('Error fetching nodes:', error);
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   // Create a new node
//   const addNode = async (newNode: Omit<CampaignNode, 'id'>) => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/nodes`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newNode),
//       });
//       if (!response.ok) {
//         throw new Error(`Failed to create node: ${response.statusText}`);
//       }
//       const data = await response.json();
//       setNodes((prevNodes) => [...prevNodes, { ...newNode, id: data.newNode.id }]);
//     } catch (error) {
//       setError('Failed to add node.');
//       console.error('Error adding node:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Update an existing node
//   const updateNode = async (updatedNode: CampaignNode) => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/nodes/${updatedNode.id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedNode),
//       });
//       if (!response.ok) {
//         throw new Error(`Failed to update node: ${response.statusText}`);
//       }
//       setNodes((prevNodes) =>
//         prevNodes.map((node) =>
//           node.id === updatedNode.id ? updatedNode : node
//         )
//       );
//     } catch (error) {
//       setError('Failed to update node.');
//       console.error('Error updating node:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Delete a node
//   const deleteNode = async (nodeId: number) => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${apiUrl}/api/nodes/${nodeId}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       if (!response.ok) {
//         throw new Error(`Failed to delete node: ${response.statusText}`);
//       }
//       setNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
//     } catch (error) {
//       setError('Failed to delete node.');
//       console.error('Error deleting node:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return {
//     email,
//     loading,
//     error,
//     addCampaign,
//     updateCampaign,
//     nodes,
//     fetchNodes,
//     addNode,
//     updateNode,
//     deleteNode
//   };
// };

// // update code --> 10 Sept 2024
// // // src/hooks/AdminDashboard/useEmailSetting.tsx

// import { useState } from 'react';
// import { useEnv } from '../../context/EnvContext'; 

// interface CampaignEmail {
//   id: number;
//   campTitle: string;
//   campType: string;
//   campList: string;
//   // content setting
//   contentTitle: string;
//   contentName: string;
//   contentBody: string;
//   // email setting
//   sendFrom: string;
//   sendTo: string;
//   sendCC: string;
//   subjectMail: string;
//   logicStatus?: string;
// }

// export const useEmail = () => {
//   const [email, setEmail] = useState<CampaignEmail[]>([]);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const { apiUrl } = useEnv();

//   const addCampaign = async (newCampaignEmail: Omit<CampaignEmail, 'id'>) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(`${apiUrl}/api/email-campaign`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(newCampaignEmail),
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to create a campaign: ${response.statusText}`);
//       }

//       const data = await response.json();
//       console.log('Received response from API:', data);

//       setEmail([...email, { ...newCampaignEmail, id: data.id }]);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error in addCampaign:', error);
//       setError('Failed to add campaign. Please try again.');
//       setLoading(false);
//     }
//   };

//   return {
//     email,
//     loading,
//     error,
//     addCampaign,
//   };
// };