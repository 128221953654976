// src/App.tsx

// import react components
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// import context file
import { ThemeProvider } from './context/ThemeContext';
import { AuthProvider, useAuth } from './context/AuthContext';
import { EnvProvider } from './context/EnvContext';
import { RankingProvider } from './context/RankingContext';

// import page view file
import LoginView from './pages/p02_Login';
import ResendVerification from './pages/p02_ResendVerification';
import RequestResetPassword from './pages/p02_RequestResetPassword';
import ClientDashboardView from './pages/client/p03_UserDashboard';
import AdminDashboardView from './pages/admin/p04_AdminDashboard';
import VeriflyAccount from './pages/p02_VeriflyPage';
import ResetPassword from './pages/p02_ResetPassword';

// import components file
import Chat from './components/Chat';
import AuthRoute from './components/AuthRoute';

const App: React.FC = () => {
  const token = new URLSearchParams(window.location.search).get('token');

  return (
    <ThemeProvider>
      <Router>
        <EnvProvider>
          <AuthProvider>
            <RankingProvider>
                <Routes>
                  <Route path="/" element={<Navigate to="/login" />} />
                  <Route path="/login" element={<LoginView />} />
                  <Route
                    path="/verifly"
                    element={
                      <AuthRoute requiresToken={true} token={token}>
                        <VeriflyAccount />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/reset-password"
                    element={
                      <AuthRoute requiresToken={true} token={token}>
                        <ResetPassword />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <AuthRoute requiresAuth={true} token={token}>
                        <ClientDashboardViewWithAuth />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/admin-dashboard/*"
                    element={
                      <AuthRoute requiresAuth={true}>
                        <AdminDashboardView />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/resend-verification"
                    element={
                      <AuthRoute requiresAuth={true}> 
                        <ResendVerification />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/request-reset-password"
                    element={
                      <AuthRoute requiresAuth={true}> 
                        <RequestResetPassword />
                      </AuthRoute>
                    }
                  />
                </Routes>
              <Chat />
            </RankingProvider>
          </AuthProvider>
        </EnvProvider>
      </Router>
    </ThemeProvider>
  );
};

const ClientDashboardViewWithAuth = () => {
  const { user } = useAuth();
  return <ClientDashboardView email={user?.email || ''} />;
};

export default App;


// // udpate code --> 25 june 2024
// // src/App.tsx

// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import { ThemeProvider } from './context/ThemeContext';
// import { AuthProvider } from './context/AuthContext';
// import { EnvProvider } from './context/EnvContext';
// import { RankingProvider } from './context/RankingContext';
// import LoginView from './pages/p02_Login';
// import ResendVerification from './pages/p02_ResendVerification';
// import RequestResetPassword from './pages/p02_RequestResetPassword';
// import ClientDashboardView from './pages/client/p03_UserDashboard';
// import AdminDashboardView from './pages/admin/p04_AdminDashboard';
// import Chat from './components/Chat';
// import VeriflyAccount from './pages/p02_VeriflyPage';
// import ResetPassword from './pages/p02_ResetPassword';
// import AuthRoute from './components/AuthRoute';

// const App: React.FC = () => {
//   const token = new URLSearchParams(window.location.search).get('token');
  
//   return (
//     <ThemeProvider>
//       <AuthProvider>
//         <EnvProvider>
//           <RankingProvider>
//             <Router>
//               <Routes>
//                 <Route path="/" element={<Navigate to="/login" />} />
//                 <Route path="/login" element={<LoginView />} />
//                 <Route
//                   path="/verifly"
//                   element={
//                     <AuthRoute requiresToken={true} token={token}>
//                       <VeriflyAccount />
//                     </AuthRoute>
//                   }
//                 />
//                 <Route
//                   path="/reset-password"
//                   element={
//                     <AuthRoute requiresToken={true} token={token}>
//                       <ResetPassword />
//                     </AuthRoute>
//                   }
//                 />
//                 <Route
//                   path="/dashboard"
//                   element={
//                     <AuthRoute requiresAuth={true}>
//                       <ClientDashboardView />
//                     </AuthRoute>
//                   }
//                 />
//                 <Route
//                   path="/admin-dashboard/*"
//                   element={
//                     <AuthRoute requiresAuth={true}>
//                       <AdminDashboardView />
//                     </AuthRoute>
//                   }
//                 />
//                 <Route
//                   path="/resend-verification"
//                   element={
//                     <AuthRoute requiresAuth={true}> 
//                       <ResendVerification />
//                     </AuthRoute>
//                   }
//                 />
//                 <Route
//                   path="/request-reset-password"
//                   element={
//                     <AuthRoute requiresAuth={true}> 
//                       <RequestResetPassword />
//                     </AuthRoute>
//                   }
//                 />
//               </Routes>
//               <Chat />
//             </Router>
//           </RankingProvider>
//         </EnvProvider>
//       </AuthProvider>
//     </ThemeProvider>
//   );
// };

// export default App;




// // udpate code --> 17 june 2024
// // src/App.tsx

// // import react components
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// // import context file from source
// import { ThemeProvider } from './context/ThemeContext';
// import { AuthProvider } from './context/AuthContext';
// import { EnvProvider } from './context/EnvContext';
// import { RankingProvider } from './context/RankingContext'; 

// // import pages file from source
// import LoginView from './pages/p02_Login';
// import ResendVerification from './pages/p02_ResendVerification';
// import RequestResetPassword from './pages/p02_RequestResetPassword';
// import ClientDashboardView from './pages/client/p03_UserDashboard';
// import AdminDashboardView from './pages/admin/p04_AdminDashboard';

// // import component file from source
// import Chat from './components/Chat';
// import VeriflyAccount from './pages/p02_VeriflyPage';
// import ResetPassword from './pages/p02_ResetPassword';
// import AuthRoute from './components/AuthRoute';

// const App: React.FC = () => {
//   const token = new URLSearchParams(window.location.search).get('token');

//   return (
//     <ThemeProvider>
//       <AuthProvider>
//         <EnvProvider>
//           <RankingProvider>
//             <Router>
//               <Routes>
//                 <Route path="/" element={<LoginView />} />
//                 <Route path="/login" element={<LoginView />} />
//                 <Route
//                   path="/verifly"
//                   element={
//                     <AuthRoute requiresToken={true} token={token}>
//                       <VeriflyAccount />
//                     </AuthRoute>
//                   }
//                 />
//                 <Route
//                   path="/reset-password"
//                   element={
//                     <AuthRoute requiresToken={true} token={token}>
//                       <ResetPassword />
//                     </AuthRoute>
//                   }
//                 />
//                 <Route
//                   path="/dashboard"
//                   element={
//                     <AuthRoute requiresAuth={true}>
//                       <ClientDashboardView />
//                     </AuthRoute>
//                   }
//                 />
//                 <Route
//                   path="/admin-dashboard/*"
//                   element={
//                     <AuthRoute requiresAuth={true}>
//                       <AdminDashboardView />
//                     </AuthRoute>
//                   }
//                 />
//                 <Route
//                   path="/resend-verification"
//                   element={
//                     <AuthRoute requiresAuth={true}>
//                       <ResendVerification />
//                     </AuthRoute>
//                   }
//                 />
//                 <Route
//                   path="/request-reset-password"
//                   element={
//                     <AuthRoute requiresAuth={true}>
//                       <RequestResetPassword />
//                     </AuthRoute>
//                   }
//                 />
//               </Routes>
//               <Chat />
//             </Router>
//           </RankingProvider>
//         </EnvProvider>
//       </AuthProvider>
//     </ThemeProvider>
//   );
// };

// export default App;