// src/hooks/useSubSetting.tsx

import { useState, useEffect, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

interface Plan {
    id?: number;
    planID?: string;
    planName: string;
    planDescription: string;
    planFeatures: string[];
    planAmount: string;
    planCurrency: string;
    planPeriod: string;
    planTrial: "0";
    planTrialPeriod: string;
    planTrialMemberType: string[];
    planVoucherCode: string;
    planDiscountType: string;
    planDiscountValue: string;
    period: string;
  }

interface Product {
    id: number;
    productName: string;
    productId: string;  
    productDescription: string;
    image?: string;
    plans: Plan[];
}

const useSubSetting = () => {
  const { apiUrl } = useEnv();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/subscription-products`);
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      setProducts(data);
    } catch (err) {
      console.error("Error fetching products:", err);
      setError(err instanceof Error ? err.message : 'Unknown error');
    } finally {
      setLoading(false);
    }
  }, [apiUrl]);

  const fetchPlans = useCallback(async (productId: number, selectedPlanId?: number) => {
    try {
      const response = await fetch(`${apiUrl}/subscription-products/${productId}/plans`);
      if (!response.ok) {
        throw new Error('Failed to fetch plans');
      }
      const plans: Plan[] = await response.json();
      setProducts((prevProducts) => prevProducts.map(product =>
        product.id === productId ? { ...product, plans } : product
      ));
  
      if (selectedPlanId) {
        const updatedPlan = plans.find((plan: Plan) => plan.id === selectedPlanId);
        if (updatedPlan) {
          return updatedPlan;
        }
      }
    } catch (err) {
      console.error("Error fetching plans:", err);
      setError(err instanceof Error ? err.message : 'Unknown error');
    }
  }, [apiUrl]);
   

  const handleSaveProduct = async (productData: Product) => {
    try {
      const response = await fetch(`${apiUrl}/save-subscription-product`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ productData })
      });
      if (!response.ok) {
        throw new Error('Failed to save product');
      }
      await fetchProducts(); // Refetch the products to update the list
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Unknown error');
    }
  };

  const createProduct = async (productData: Product) => {
    try {
      const response = await fetch(`${apiUrl}/create-subscription-product`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ productData })
      });
      if (!response.ok) {
        throw new Error('Failed to create product');
      }
      const newProduct = await response.json();
      await fetchProducts(); 
      return newProduct;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Unknown error');
      throw err;
    }
  };
   
  const deleteProduct = async (productId: number) => {
    try {
      const response = await fetch(`${apiUrl}/delete-subscription-product`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ productId })
      });
      if (!response.ok) {
        throw new Error('Failed to delete product');
      }
      await fetchProducts(); 
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Unknown error');
    }
  };

  const savePlan = async (planData: Plan): Promise<Plan> => {
    try {
      const response = await fetch(`${apiUrl}/save-subscription-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ planData })
      });
      if (!response.ok) {
        throw new Error('Failed to save plan');
      }
      const savedPlan = await response.json();
      
      // Update the products state with the new plan data
      setProducts(prevProducts => prevProducts.map(product => {
        if (product.id === parseInt(planData.planID!)) {
          const updatedPlans = product.plans.map(plan => 
            plan.id === savedPlan.id ? savedPlan : plan
          );
          return { ...product, plans: updatedPlans };
        }
        return product;
      }));
      
      return savedPlan;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Unknown error');
      throw err;
    }
  };     

  const deletePlan = async (planId: number) => {
    try {
      const response = await fetch(`${apiUrl}/delete-subscription-plan`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ planId })
      });
      if (!response.ok) {
        throw new Error('Failed to delete plan');
      }
      await fetchProducts(); // Refetch the products to update the list
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Unknown error');
      throw err; // Re-throw the error so it can be caught in the component
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return { 
    products, 
    loading, 
    error, 
    handleSaveProduct, 
    deleteProduct, 
    createProduct, 
    savePlan, 
    deletePlan, 
    fetchProducts, 
    fetchPlans 
  };
};

export default useSubSetting;

// // update code --> 16 july 2024
// // src/hooks/useSubSetting.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface Plan {
//     id?: number;
//     name: string;
//     description: string;
//     features: string[];
//     fee: string;
//     period: string;
// }

// interface Product {
//     id: number;
//     productName: string;
//     productId: string;  // Changed from productId to productID
//     productDescription: string;
//     image?: string;
//     plans: Plan[];
// }

// const useSubSetting = () => {
//   const { apiUrl } = useEnv();
//   const [products, setProducts] = useState<Product[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   const fetchProducts = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/subscription-products`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch products');
//       }
//       const data = await response.json();
//       setProducts(data);
//     } catch (err) {
//       setError(err instanceof Error ? err.message : 'Unknown error');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const handleSaveProduct = async (productData: Product) => {
//     try {
//       const response = await fetch(`${apiUrl}/save-subscription-product`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({ productData })
//       });
//       if (!response.ok) {
//         throw new Error('Failed to save product');
//       }
//       await fetchProducts(); // Refetch the products to update the list
//     } catch (err) {
//       setError(err instanceof Error ? err.message : 'Unknown error');
//     }
//   };
   

//   const savePlan = async (planData: Plan) => {
//     try {
//       const response = await fetch(`${apiUrl}/save-subscription-plan`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({ planData })
//       });
//       if (!response.ok) {
//         throw new Error('Failed to save plan');
//       }
//       // Refetch the products after saving the plan
//       await fetchProducts();
//     } catch (err) {
//       setError(err instanceof Error ? err.message : 'Unknown error');
//     }
//   };

//   useEffect(() => {
//     fetchProducts();
//   }, [fetchProducts]);

//   return { products, loading, error, savePlan, handleSaveProduct };
// };

// export default useSubSetting;



// // udpate code --> 15 july 2024
// // src/hooks/useSubSetting.tsx

// import { useState, useEffect, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// interface Plan {
//   id?: number;
//   name: string;
//   description: string;
//   features: string[];
//   fee: string;
//   period: string;
// }

// interface Product {
//   id: number;
//   name: string;
//   description: string;
//   details: string;
//   plans: Plan[];
// }

// const useSubSetting = () => {
//   const { apiUrl } = useEnv();
//   const [products, setProducts] = useState<Product[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   const fetchProducts = useCallback(async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/subscription-products`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch products');
//       }
//       const data = await response.json();
//       setProducts(data);
//     } catch (err) {
//       setError(err instanceof Error ? err.message : 'Unknown error');
//     } finally {
//       setLoading(false);
//     }
//   }, [apiUrl]);

//   const handleSaveProduct = async (productData: Product) => {
//     try {
//       const response = await fetch(`${apiUrl}/save-subscription-product`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({ productData })
//       });
//       if (!response.ok) {
//         throw new Error('Failed to save product');
//       }
//       await fetchProducts(); // Refetch the products to update the list
//     } catch (err) {
//       setError(err instanceof Error ? err.message : 'Unknown error');
//     }
//   };
   

//   const savePlan = async (planData: Plan) => {
//     try {
//       const response = await fetch(`${apiUrl}/save-subscription-plan`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({ planData })
//       });
//       if (!response.ok) {
//         throw new Error('Failed to save plan');
//       }
//       // Refetch the products after saving the plan
//       await fetchProducts();
//     } catch (err) {
//       setError(err instanceof Error ? err.message : 'Unknown error');
//     }
//   };

//   useEffect(() => {
//     fetchProducts();
//   }, [fetchProducts]);

//   return { products, loading, error, savePlan, handleSaveProduct };
// };

// export default useSubSetting;
