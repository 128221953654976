// src/components/admin/EventSetting/ProgramCreatedAlert.tsx

import React from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { FaExclamationTriangle, FaTag } from 'react-icons/fa';
import { 
  convertToShortForm, 
  getTagColor, 
  formatTimeTo12Hour, 
  formatDate, 
  convertDescription, 
  formatAmount 
} from '../../../hooks/AdminDashboard/useProgram';

interface ProgramAlertProps {
  programDetails: any;
  selectedLocation: string;  // Add this prop
  onConfirm: () => void;
  onCancel: () => void;
}
const ProgramCreatedAlert: React.FC<ProgramAlertProps> = 
({ 
  programDetails, selectedLocation, onConfirm, onCancel 
}) => {
  return (
    <Transition show={true}>
      <Dialog className="relative z-10" onClose={onCancel}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <FaExclamationTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left sm:flex sm:justify-between sm:w-full">
                      <div className="flex-1">
                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Confirm Event Creation
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to create this event? Here are the details:
                          </p>
                          <table className="mt-2 w-full border-collapse border border-gray-200">
                            <tbody>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">Program Name:</td>
                                <td className="p-2">{programDetails.programName}</td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">Amount:</td>
                                <td className="p-2">{programDetails.currency} {formatAmount(programDetails.amount)}</td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">Lot Size:</td>
                                <td className="p-2">{programDetails.lotSize}</td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">Deposit Amount:</td>
                                <td className="p-2">USD {formatAmount(programDetails.deposit)}</td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">Broker:</td>
                                <td className="p-2">{programDetails.broker}</td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">Start Date:</td>
                                <td className="p-2">{formatDate(programDetails.startDate)}</td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">End Date:</td>
                                <td className="p-2">{formatDate(programDetails.endDate)}</td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">Time:</td>
                                <td className="p-2">{formatTimeTo12Hour(programDetails.time)}</td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">Location:</td>
                                <td className="p-2">{selectedLocation === 'specify location' ? programDetails.location : selectedLocation}</td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">Points:</td>
                                <td className="p-2">{programDetails.points}</td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">Slot:</td>
                                <td className="p-2">{programDetails.slotType === 'unlimited' ? 'Unlimited' : programDetails.slotCount}</td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">Member Type:</td>
                                <td className="p-2">
                                  {(programDetails.memberType || []).map((type: string, index: number) => {
                                    const shortForm = convertToShortForm(type);
                                    const tagColor = getTagColor(shortForm);
                                    return (
                                      <span key={index} className={`flex items-center px-2 py-1 rounded ${tagColor} bg-opacity-20`}>
                                        <FaTag className={`mr-1 ${tagColor}`} /> {/* Add the tag icon here */}
                                        {shortForm}
                                      </span>
                                    );
                                  })}
                                </td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold">Redeem Number:</td>
                                <td className="p-2">{programDetails.redeemNumber ? programDetails.redeemNumber : 0}</td>
                              </tr>
                              <tr className="border-b">
                                <td className="p-2 font-semibold align-top">Description:</td>
                                <td className="p-2">
                                  <ul className="list-none">
                                    {convertDescription(programDetails.description).split('\n').map((line, index) => (
                                      <li key={index}>{line}</li>
                                    ))}
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {programDetails.image && (
                        <div className="mt-4 sm:mt-0 sm:ml-4 flex-shrink-0">
                          <img
                            src={URL.createObjectURL(programDetails.image)}
                            alt="Event"
                            className="w-48 h-48 object-cover rounded"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={onConfirm}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={onCancel}
                    data-autofocus
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ProgramCreatedAlert;


// // update code --> 26 june 2024
// // src/components/admin/EventSetting/ProgramCreatedAlert.tsx

// import React from 'react';
// import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
// import { FaExclamationTriangle } from 'react-icons/fa';

// interface ProgramAlertProps {
//   programDetails: any;
//   selectedLocation: string;  // Add this prop
//   onConfirm: () => void;
//   onCancel: () => void;
// }

// const formatTimeTo12Hour = (time: string) => {
//   const [hours, minutes] = time.split(':').map(Number);
//   const suffix = hours >= 12 ? 'PM' : 'AM';
//   const adjustedHours = hours % 12 || 12;
//   return `${adjustedHours}:${minutes < 10 ? '0' : ''}${minutes} ${suffix}`;
// };

// const ProgramCreatedAlert: React.FC<ProgramAlertProps> = ({ programDetails, selectedLocation, onConfirm, onCancel }) => {
//   return (
//     <Transition show={true}>
//       <Dialog className="relative z-10" onClose={onCancel}>
//         <TransitionChild
//           enter="ease-out duration-300"
//           enterFrom="opacity-0"
//           enterTo="opacity-100"
//           leave="ease-in duration-200"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
//         </TransitionChild>

//         <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
//           <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
//             <TransitionChild
//               enter="ease-out duration-300"
//               enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//               enterTo="opacity-100 translate-y-0 sm:scale-100"
//               leave="ease-in duration-200"
//               leaveFrom="opacity-100 translate-y-0 sm:scale-100"
//               leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//             >
//               <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
//                 <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
//                   <div className="sm:flex sm:items-start">
//                     <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
//                       <FaExclamationTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
//                     </div>
//                     <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left sm:flex sm:justify-between sm:w-full">
//                       <div>
//                         <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
//                           Confirm Event Creation
//                         </DialogTitle>
//                         <div className="mt-2">
//                           <p className="text-sm text-gray-500">
//                             Are you sure you want to create this event? Here are the details:
//                           </p>
//                           <ul className="mt-2 list-disc list-inside text-sm text-gray-500">
//                             <li>Program Name: {programDetails.programName}</li>
//                             <li>Amount: {programDetails.amount} {programDetails.currency}</li>
//                             <li>Start Date: {programDetails.startDate}</li>
//                             <li>End Date: {programDetails.endDate}</li>
//                             <li>Time: {formatTimeTo12Hour(programDetails.time)}</li>
//                             <li>Points: {programDetails.points}</li>
//                             <li>Slot: {programDetails.slotType === 'unlimited' ? 'Unlimited' : programDetails.slotCount}</li>
//                             <li>Description: {programDetails.description}</li>
//                             <li>Redeem Number: {programDetails.redeemNumber ? programDetails.redeemNumber : 0}</li>
//                             <li>Location: {selectedLocation === 'specify location' ? programDetails.location : selectedLocation}</li>
//                           </ul>
//                         </div>
//                       </div>
//                       {programDetails.image && (
//                         <div className="mt-4 sm:mt-0 sm:ml-4">
//                           <img
//                             src={URL.createObjectURL(programDetails.image)}
//                             alt="Event"
//                             className="w-24 h-24 object-cover rounded"
//                           />
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//                 <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
//                   <button
//                     type="button"
//                     className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
//                     onClick={onConfirm}
//                   >
//                     Confirm
//                   </button>
//                   <button
//                     type="button"
//                     className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
//                     onClick={onCancel}
//                     data-autofocus
//                   >
//                     Cancel
//                   </button>
//                 </div>
//               </DialogPanel>
//             </TransitionChild>
//           </div>
//         </div>
//       </Dialog>
//     </Transition>
//   );
// };

// export default ProgramCreatedAlert;
