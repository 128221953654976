// src/components/admin/MarketSetting/MailSetting.tsx

// import components
import React, { useState } from 'react';
// import file
import mjml2html from 'mjml-browser'; 
import defaultMJMLTemplate from './DefaultMJMLTemplate';
import ConfirmAlertMessage from '../alertMessage';
import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';

interface MailSettingProps {
  onCreate: (
    CampaignEmail: {
      campTitle: string;
      campType: string;
      campList: string; 
      contentTitle: string;
      contentName: string;
      contentBody: string;
      contentButton: string;
      sendFrom: string;
      sendTo: string;
      sendCC: string;
      subjectMail: string;
    }) => void;
  onClose: () => void;
  campaign?: any;
}

const MailSetting: React.FC<MailSettingProps> = () => {
  // component state
  const [activeTab, setActiveTab] = useState<'preview' | 'source'>('preview');
  const { email: campaigns, addCampaign, updateCampaign } = useEmail();
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>('');
  // create title state
  const [campaignTitle, setCampaignTitle] = useState('');
  const [campaignType, setCampaignType] = useState('');
  const [campaignList, setCampaignList] = useState('');
  // form state
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [contents, setContents] = useState<string[]>(['']);
  const [buttonEnabled, setButtonEnabled] = useState(false); 
  const [buttonURL, setButtonURL] = useState('');
  const [sendFrom, setSendFrom] = useState('');
  const [sendTo, setSendTo] = useState('');
  const [ccFrom, setCCFrom] = useState('');
  const [subject, setSubject] = useState('');
  // alert state
  const [isEditMode, setIsEditMode] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState<'confirm' | 'error' | 'success' | 'warning'>('confirm');
  const [alertMessage, setAlertMessage] = useState('');
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  // Handle selecting a campaign
  const handleCampaignSelect = (campaignId: string) => {
    if (!campaignId) return;
    setSelectedCampaignId(campaignId);

    const selectedCampaign = campaigns.find(campaign => campaign?.id?.toString() === campaignId);
    
    // Add a check to handle if the campaign is not found
    if (!selectedCampaign) {
        console.error('Selected campaign not found');
      return;
    }

    if (selectedCampaign) {
        setCampaignTitle(selectedCampaign.campTitle || '');
        setCampaignType(selectedCampaign.campType || '');
        setCampaignList(selectedCampaign.campList || '');
        setTitle(selectedCampaign.contentTitle || '');
        setName(selectedCampaign.contentName || '');
        setContents([selectedCampaign.contentBody || '']);

        // Enable button if there is a contentButton URL
        setButtonEnabled(!!selectedCampaign.contentButton);
        setButtonURL(selectedCampaign.contentButton || '');

        setSendFrom(selectedCampaign.sendFrom || '');
        setSendTo(selectedCampaign.sendTo || '');
        setCCFrom(selectedCampaign.sendCC || '');
        setSubject(selectedCampaign.subjectMail || '');
        setIsEditMode(true); 
    }
  };  

  const handleSubmit = async () => {
    let combinedContent = contents.join('\n\n');

    const CampaignEmail = {
      campTitle: campaignTitle,
      campType: campaignType,
      campList: campaignList,
      contentTitle: title,
      contentName: name,
      contentBody: combinedContent,
      contentButton: buttonURL,
      sendFrom,
      sendTo,
      sendCC: ccFrom,
      subjectMail: subject,
    };

    try {
        if (isEditMode && selectedCampaignId) {
          await updateCampaign({ ...CampaignEmail, id: Number(selectedCampaignId) });
          setAlertMessage('Campaign updated successfully!');

        } else {
          await addCampaign(CampaignEmail);
          setAlertMessage('Campaign created successfully!');
        }
        setAlertType('success');

      } catch (error) {
        setAlertMessage('Failed to save campaign.');
        setAlertType('error');

      } finally {
        setAlertVisible(true);
      }
    };
  
    const handleConfirm = () => {
        setAlertMessage(
            isEditMode ? 
            'Are you sure you want to modify this campaign?' : 
            'Are you sure you want to create this campaign?'
        );
        setAlertType('confirm');
        setAlertVisible(true);
        setConfirmSubmit(true);
    };
  
    const handleConfirmSubmit = () => {
      setAlertVisible(false);
      handleSubmit(); 
    };

    const handleContentChange = (index: number, value: string) => {
        const updatedContents = [...contents];
        updatedContents[index] = value;
        setContents(updatedContents);
    };

    const formattedContents = contents
        .map(content => content.replace(/\n/g, '<br />') || '< Your content here >')
        .join('<br /><br />');

    let combinedContent = formattedContents;

    if (buttonEnabled && buttonURL) {
        combinedContent += `
        <div style="text-align: center; margin-top: 20px;">
            <a href="${buttonURL}" style="display:inline-block; padding:10px 20px; background-color:#0f5469; color:white; text-decoration:none; border-radius:5px;">Click Here</a>
        </div>`;
    }

    const updatedContent = defaultMJMLTemplate
        .replace('{{Title}}', title || '< Your title here >')
        .replace('{{content}}', combinedContent)
        .replace('{{name}}', name || '< Your name here >');

    const previewHTML = mjml2html(updatedContent).html;

return (
    <>
      <h2 className="text-xl font-semibold mb-4">
        {isEditMode ? 'Modify Selected Email' : 'Create New Mail'}
      </h2>
      <div className="flex justify-between">

        {/* Tabs for Preview and Source Code */}
        <div className="flex flex-col w-full">
          <div className="flex justify-start">
            <button
              onClick={() => setActiveTab('preview')}
              className={`px-4 py-2 ${activeTab === 'preview' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
            >
              Preview
            </button>
            <button
              onClick={() => setActiveTab('source')}
              className={`px-4 py-2 ${activeTab === 'source' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
            >
              Code
            </button>
          </div>

          {/* Conditional Rendering based on activeTab */}
          <div className="overflow-y-auto max-h-[565px] border-2 border-cyan-800 rounded">
            {activeTab === 'preview' ? (
                <div 
                  className="p-4 w-full bg-gray-50" 
                  dangerouslySetInnerHTML={{ __html: previewHTML }} 
                />
            ) : (
              <div className="flex flex-col items-center">
                <pre
                    className="p-8 w-full bg-gray-50 text-sm" 
                    style={{ whiteSpace: 'pre-wrap' }}
                >
                    {updatedContent}
                </pre>
              </div>
            )}
          </div>
        </div>

        {/* Right side - Form fields */}
        <div className="flex flex-col p-4 w-full max-w-[380px] md:w-[200px] mx-2">
          <span className="text-xl font-semibold mb-4 text-cyan-800">Email Setting</span>
          <div className="overflow-y-auto max-h-[500px] p-4 border">

          <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Campaign Setting</div>

            {/* Campaign Title */}
            <div className="flex flex-col items-center mb-4">
              <label className="w-full px-2 font-semibold text-cyan-800">Campaign Email Title:</label>  
              <input
                type="text"
                placeholder="Enter campaign title"
                value={campaignTitle}
                onChange={(e) => setCampaignTitle(e.target.value)} 
                className="border p-2 w-full outline-cyan-700"
              />
            </div>

            {/* Campaign Type */}
            <div className="flex flex-col items-center mb-4">
              <label className="w-full px-2 font-semibold text-cyan-800">Campaign Email Type:</label>  
              <select
                value={campaignType}
                onChange={(e) => setCampaignType(e.target.value)}
                className="border p-2 w-full outline-cyan-700"
              >
                <option value="">select a type</option>
                <option value="Start">Start🟢</option>
                <option value="Follow-Up">Follow-Up🟡</option>
                <option value="Ended">Ended🔴</option>
              </select>
            </div>

            {/* Campaign Email List */}
            <div className="flex flex-col items-center mb-4">
              <label className="w-full px-2 font-semibold text-cyan-800">Campaign Email List:</label>
              <select
                value={selectedCampaignId}
                onChange={(e) => handleCampaignSelect(e.target.value)}
                className="border p-2 w-full outline-cyan-700"
              >
                <option value="">Select a campaign</option>
                {campaigns && campaigns.length > 0 ? (
                  campaigns.map((campaign) => (
                    <option key={campaign.id} value={campaign.id?.toString()}>
                      {campaign.campTitle}
                    </option>
                  ))
                ) : (
                  <option value="">No campaigns available</option>
                )}
              </select>
            </div>

            <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Edit Email Content</div>

            {/* Email Title */}
            <div className="flex flex-col items-center mb-4">
                <label className="w-full px-2 font-semibold text-cyan-800">Content Title:</label>  
                <input
                    type="text"
                    placeholder="Enter content title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)} 
                    className="border p-2 w-full outline-cyan-700"
                />
            </div>

            {/* Name Input */}
            <div className="flex flex-col items-center mb-4">
                <label className="w-full px-2 font-semibold text-cyan-800">Content Name</label>  
                <input
                  type="text"
                  placeholder="Enter email content name"
                  value={name}
                  onChange={(e) => setName(e.target.value)} 
                  className="border p-2 w-full"
                />
            </div>
            
            {/* Content Sections */}
            {contents.map((content, index) => (
              <div className="flex flex-col mb-2">
                <label className="w-full px-2 font-semibold text-cyan-800">Content:</label>
                <div className="flex items-center w-full">
                  <textarea
                    placeholder="Enter email content"
                    value={content}
                    onChange={(e) => handleContentChange(index, e.target.value)}
                    className="border p-2 w-full h-15"
                    style={{ resize: 'vertical' }}
                  />
                </div>
              </div>
            ))}

            {/* New Content Button Field */}
            <div className="flex flex-col my-4">
                <div className="flex justify-normal items-center">
                  <label className="w-full px-2 font-semibold text-cyan-800">Content Button:</label>
                  <div className="flex justify-normal">
                    <span>{buttonEnabled ? 'Enable' : 'Disable'}</span>
                    <input
                      type="checkbox"
                      checked={buttonEnabled}
                      onChange={() => setButtonEnabled(!buttonEnabled)}
                      className="ml-2 accent-cyan-800"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  placeholder={buttonEnabled ? "Enter button URL" : "" }
                  value={buttonURL}
                  onChange={(e) => setButtonURL(e.target.value)}
                  className={`border p-2 w-full mt-2 ${!buttonEnabled ? 'bg-gray-100' : 'bg-white'}`}
                />
            </div>
            
            <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Email Setting</div>

            {/* Send email field */}
            <div className="flex flex-col items-center mb-4">
                <label className="w-full px-2 font-semibold text-cyan-800">Send From:</label>  
                <input
                  type="text"
                  placeholder="Enter sended email"
                  value={sendFrom}
                  onChange={(e) => setSendFrom(e.target.value)}
                  className="border p-2 w-full"
                />
            </div>

            {/* Send to field */}
            <div className="flex flex-col items-center mb-4">
                <label className="w-full px-2 font-semibold text-cyan-800">Send To:</label>  
                <input
                  type="text"
                  placeholder="Enter recipient's email"
                  value={sendTo}
                  onChange={(e) => setSendTo(e.target.value)}
                  className="border p-2 w-full"
                />
            </div>

            {/* Send cc field */}
            <div className="flex flex-col items-center mb-4">
                <label className="w-full px-2 font-semibold text-cyan-800">Send CC:</label>  
                <input
                  type="text"
                  placeholder="Enter recipient's email"
                  value={ccFrom}
                  onChange={(e) => setCCFrom(e.target.value)}
                  className="border p-2 w-full"
                />
            </div>

            {/* subject email field */}
            <div className="flex flex-col items-center mb-4">
              <label className="w-full px-2 font-semibold text-cyan-800">Email Subject:</label>  
              <input
                type="text"
                placeholder="Enter email subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
          </div>

          {/* Create/Modify Campaign button */}
          <div className="flex flex-col justify-end py-2">
            <button
              onClick={handleConfirm}
              className="bg-cyan-800 hover:bg-cyan-900 text-white px-4 py-2 rounded-md w-auto"
            >
              {isEditMode ? 'Modify Email' : 'Create Email'}
            </button>
          </div>

          <ConfirmAlertMessage
            type={alertType}
            message={alertMessage}
            visible={alertVisible}
            onClose={() => setAlertVisible(false)}
            onConfirm={confirmSubmit ? handleConfirmSubmit : handleConfirm}
            />
        </div>
      </div>
    </>
  );
};

export default MailSetting;

// // udpate xcode --> 10 Sept 2024 v03
// // src/components/admin/MarketSetting/MailSetting.tsx

// // import components
// import React, { useState } from 'react';
// // import file
// import mjml2html from 'mjml-browser'; 
// import defaultMJMLTemplate from './DefaultMJMLTemplate';
// import ConfirmAlertMessage from '../alertMessage';
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';
// // import icon
// import { IoMdAddCircle, IoMdRemoveCircle } from "react-icons/io";

// interface MailSettingProps {
//   onCreate: (
//     CampaignEmail: {
//       campTitle: string;
//       campType: string;
//       campList: string; 
//       contentTitle: string;
//       contentName: string;
//       contentBody: string;
//       contentTable: string;
//       includeButton: boolean;
//       sendFrom: string;
//       sendTo: string;
//       sendCC: string;
//       subjectMail: string;
//     }) => void;
//   onClose: () => void;
//   campaign?: any;
// }

// const MailSetting: React.FC<MailSettingProps> = () => {
//   // component state
//   const [activeTab, setActiveTab] = useState<'preview' | 'source'>('preview');
//   const { email: campaigns, addCampaign, updateCampaign } = useEmail();
//   const [selectedCampaignId, setSelectedCampaignId] = useState<string>('');
//   // create title state
//   const [campaignTitle, setCampaignTitle] = useState('');
//   const [campaignType, setCampaignType] = useState('');
//   const [campaignList, setCampaignList] = useState('');
//   // form state
//   const [title, setTitle] = useState('');
//   const [name, setName] = useState('');
//   const [contents, setContents] = useState<string[]>(['']);
//   const [sendFrom, setSendFrom] = useState('');
//   const [sendTo, setSendTo] = useState('');
//   const [ccFrom, setCCFrom] = useState('');
//   const [subject, setSubject] = useState('');
//   // alert state
//   const [isEditMode, setIsEditMode] = useState(false);
//   const [alertVisible, setAlertVisible] = useState(false);
//   const [alertType, setAlertType] = useState<'confirm' | 'error' | 'success' | 'warning'>('confirm');
//   const [alertMessage, setAlertMessage] = useState('');
//   const [confirmSubmit, setConfirmSubmit] = useState(false);

//   // Handle selecting a campaign
//   const handleCampaignSelect = (campaignId: string) => {
//     if (!campaignId) return;
//     setSelectedCampaignId(campaignId);

//     const selectedCampaign = campaigns.find(campaign => campaign?.id?.toString() === campaignId);
    
//     // Add a check to handle if the campaign is not found
//     if (!selectedCampaign) {
//         console.error('Selected campaign not found');
//       return;
//     }

//     if (selectedCampaign) {
//         setCampaignTitle(selectedCampaign.campTitle || '');
//         setCampaignType(selectedCampaign.campType || '');
//         setCampaignList(selectedCampaign.campList || '');
//         setTitle(selectedCampaign.contentTitle || '');
//         setName(selectedCampaign.contentName || '');
//         setContents([selectedCampaign.contentBody || '']);
//         setSendFrom(selectedCampaign.sendFrom || '');
//         setSendTo(selectedCampaign.sendTo || '');
//         setCCFrom(selectedCampaign.sendCC || '');
//         setSubject(selectedCampaign.subjectMail || '');

//         setIsEditMode(true); 
//     }
//   };  

//   const handleSubmit = async () => {
//     const combinedContent = contents.join('\n\n');

//     const CampaignEmail = {
//       campTitle: campaignTitle,
//       campType: campaignType,
//       campList: campaignList,
//       contentTitle: title,
//       contentName: name,
//       contentBody: combinedContent,
//       sendFrom,
//       sendTo,
//       sendCC: ccFrom,
//       subjectMail: subject,
//     };

//     try {
//         if (isEditMode && selectedCampaignId) {
//           await updateCampaign({ ...CampaignEmail, id: Number(selectedCampaignId) });
//           setAlertMessage('Campaign updated successfully!');

//         } else {
//           await addCampaign(CampaignEmail);
//           setAlertMessage('Campaign created successfully!');
//         }
//         setAlertType('success');

//       } catch (error) {
//         setAlertMessage('Failed to save campaign.');
//         setAlertType('error');

//       } finally {
//         setAlertVisible(true);
//       }
//     };
  
//     const handleConfirm = () => {
//         setAlertMessage(
//             isEditMode ? 
//             'Are you sure you want to modify this campaign?' : 
//             'Are you sure you want to create this campaign?'
//         );
//         setAlertType('confirm');
//         setAlertVisible(true);
//         setConfirmSubmit(true);
//     };
  
//     const handleConfirmSubmit = () => {
//       setAlertVisible(false);
//       handleSubmit(); 
//     };

//     const handleContentChange = (index: number, value: string) => {
//         const updatedContents = [...contents];
//         updatedContents[index] = value;
//         setContents(updatedContents);
//     };

//     const addContentSection = () => {
//         setContents([...contents, '']);
//     };

//     const removeContentSection = (index: number) => {
//         const updatedContents = contents.filter((_, i) => i !== index);
//         setContents(updatedContents);
//     };

//     const formattedContents = contents
//         .map(content => content.replace(/\n/g, '<br />') || '< Your content here >')
//         .join('<br /><br />');

//     const updatedContent = defaultMJMLTemplate
//         .replace('{{Title}}', title || '< Your title here >')
//         .replace('{{content}}', formattedContents)
//         .replace('{{name}}', name || '< Your name here >');

//     const previewHTML = mjml2html(updatedContent).html;

// return (
//     <>
//       <h2 className="text-xl font-semibold mb-4">
//         {isEditMode ? 'Modify Selected Email' : 'Create New Mail'}
//       </h2>
//       <div className="flex justify-between">

//         {/* Tabs for Preview and Source Code */}
//         <div className="flex flex-col w-full">
//           <div className="flex justify-start">
//             <button
//               onClick={() => setActiveTab('preview')}
//               className={`px-4 py-2 ${activeTab === 'preview' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
//             >
//               Preview
//             </button>
//             <button
//               onClick={() => setActiveTab('source')}
//               className={`px-4 py-2 ${activeTab === 'source' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
//             >
//               Code
//             </button>
//           </div>

//           {/* Conditional Rendering based on activeTab */}
//           <div className="overflow-y-auto max-h-[565px] border-2 border-cyan-800 rounded">
//             {activeTab === 'preview' ? (
//                 <div 
//                   className="p-4 w-full bg-gray-50" 
//                   dangerouslySetInnerHTML={{ __html: previewHTML }} 
//                 />
//             ) : (
//               <div className="flex flex-col items-center">
//                 <pre
//                     className="p-8 w-full bg-gray-50 text-sm" 
//                     style={{ whiteSpace: 'pre-wrap' }}
//                 >
//                     {updatedContent}
//                 </pre>
//               </div>
//             )}
//           </div>
//         </div>

//         {/* Right side - Form fields */}
//         <div className="flex flex-col p-4 w-full max-w-[380px] md:w-[200px] mx-2">
//           <span className="text-xl font-semibold mb-4 text-cyan-800">Email Setting</span>
//           <div className="overflow-y-auto max-h-[500px] p-4 border">

//           <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Campaign Setting</div>

//             {/* Campaign Title */}
//             <div className="flex flex-col items-center mb-4">
//               <label className="w-full px-2 font-semibold text-cyan-800">Campaign Email Title:</label>  
//               <input
//                 type="text"
//                 placeholder="Enter campaign title"
//                 value={campaignTitle}
//                 onChange={(e) => setCampaignTitle(e.target.value)} 
//                 className="border p-2 w-full outline-cyan-700"
//               />
//             </div>

//             {/* Campaign Type */}
//             <div className="flex flex-col items-center mb-4">
//               <label className="w-full px-2 font-semibold text-cyan-800">Campaign Email Type:</label>  
//               <select
//                 value={campaignType}
//                 onChange={(e) => setCampaignType(e.target.value)}
//                 className="border p-2 w-full outline-cyan-700"
//               >
//                 <option value="">select a type</option>
//                 <option value="Start">Start🟢</option>
//                 <option value="Follow-Up">Follow-Up🟡</option>
//                 <option value="Ended">Ended🔴</option>
//               </select>
//             </div>

//             {/* Campaign Email List */}
//             <div className="flex flex-col items-center mb-4">
//               <label className="w-full px-2 font-semibold text-cyan-800">Campaign Email List:</label>
//               <select
//                 value={selectedCampaignId}
//                 onChange={(e) => handleCampaignSelect(e.target.value)}
//                 className="border p-2 w-full outline-cyan-700"
//               >
//                 <option value="">Select a campaign</option>
//                 {campaigns && campaigns.length > 0 ? (
//                   campaigns.map((campaign) => (
//                     <option key={campaign.id} value={campaign.id?.toString()}>
//                       {campaign.campTitle}
//                     </option>
//                   ))
//                 ) : (
//                   <option value="">No campaigns available</option>
//                 )}
//               </select>
//             </div>

//             <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Edit Email Content</div>

//             {/* Email Title */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Content Title:</label>  
//                 <input
//                     type="text"
//                     placeholder="Enter content title"
//                     value={title}
//                     onChange={(e) => setTitle(e.target.value)} 
//                     className="border p-2 w-full outline-cyan-700"
//                 />
//             </div>

//             {/* Name Input */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Content Name</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter email content name"
//                   value={name}
//                   onChange={(e) => setName(e.target.value)} 
//                   className="border p-2 w-full"
//                 />
//             </div>
            
//             {/* Content Sections */}
//             {contents.map((content, index) => (
//               <div key={index} className="flex flex-col mb-2">
//                 <div className="flex justify-normal">
//                     <label className="w-full px-2 font-semibold text-cyan-800">Content {index + 1}:</label>
//                     {contents.length > 1 && (
//                       <button
//                         onClick={() => removeContentSection(index)}
//                         className="text-cyan-800 text-xl ml-2"
//                       >
//                         <IoMdRemoveCircle />
//                       </button>
//                     )}
//                 </div>
//                 <div className="flex items-center w-full">
//                   <textarea
//                     placeholder="Enter email content"
//                     value={content}
//                     onChange={(e) => handleContentChange(index, e.target.value)}
//                     className="border p-2 w-full h-15"
//                     style={{ resize: 'vertical' }}
//                   />
//                 </div>
//               </div>
//             ))}

//             {/* Add new content button */}
//             <div className="flex justify-end">
//                 <button
//                     className=" text-cyan-800 rounded flex items-center"
//                     onClick={addContentSection}
//                 >
//                 <span className="flex justify-normal items-center">
//                     Add <IoMdAddCircle className="mx-1"/>
//                 </span>
//                 </button>
//             </div>
            
//             <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Email Setting</div>

//             {/* Send email field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send From:</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter sended email"
//                   value={sendFrom}
//                   onChange={(e) => setSendFrom(e.target.value)}
//                   className="border p-2 w-full"
//                 />
//             </div>

//             {/* Send to field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send To:</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter recipient's email"
//                   value={sendTo}
//                   onChange={(e) => setSendTo(e.target.value)}
//                   className="border p-2 w-full"
//                 />
//             </div>

//             {/* Send cc field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send CC:</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter recipient's email"
//                   value={ccFrom}
//                   onChange={(e) => setCCFrom(e.target.value)}
//                   className="border p-2 w-full"
//                 />
//             </div>

//             {/* subject email field */}
//             <div className="flex flex-col items-center mb-4">
//               <label className="w-full px-2 font-semibold text-cyan-800">Email Subject:</label>  
//               <input
//                 type="text"
//                 placeholder="Enter email subject"
//                 value={subject}
//                 onChange={(e) => setSubject(e.target.value)}
//                 className="border p-2 w-full"
//               />
//             </div>
//           </div>

//           {/* Create/Modify Campaign button */}
//           <div className="flex flex-col justify-end py-2">
//             <button
//               onClick={handleConfirm}
//               className="bg-cyan-800 hover:bg-cyan-900 text-white px-4 py-2 rounded-md w-auto"
//             >
//               {isEditMode ? 'Modify Email' : 'Create Email'}
//             </button>
//           </div>

//           <ConfirmAlertMessage
//             type={alertType}
//             message={alertMessage}
//             visible={alertVisible}
//             onClose={() => setAlertVisible(false)}
//             onConfirm={confirmSubmit ? handleConfirmSubmit : handleConfirm}
//             />
//         </div>
//       </div>
//     </>
//   );
// };

// export default MailSetting;

// // update code --> 10 sept 2024 v02
// // src/components/admin/MarketSetting/MailSetting.tsx

// // import components
// import React, { useState } from 'react';
// // import file
// import mjml2html from 'mjml-browser'; 
// import defaultMJMLTemplate from './DefaultMJMLTemplate';
// import ConfirmAlertMessage from '../alertMessage';
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';
// // import icon
// import { IoMdAddCircle, IoMdRemoveCircle } from "react-icons/io";

// interface MailSettingProps {
//   onCreate: (
//     CampaignEmail: {
//       campTitle: string;
//       campType: string;
//       campList: string; 
//       contentTitle: string;
//       contentName: string;
//       contentBody: string;
//       sendFrom: string;
//       sendTo: string;
//       sendCC: string;
//       subjectMail: string;
//     }) => void;
//   onClose: () => void;
//   campaign?: any;
// }

// const MailSetting: React.FC<MailSettingProps> = ({ onCreate, onClose, campaign  }) => {
//   // component state
//   const [activeTab, setActiveTab] = useState<'preview' | 'source'>('preview');
//   const { email: campaigns, addCampaign } = useEmail();
//   const [selectedCampaignId, setSelectedCampaignId] = useState<string>('');
//   // create title state
//   const [campaignTitle, setCampaignTitle] = useState('');
//   const [campaignType, setCampaignType] = useState('');
//   const [campaignList, setCampaignList] = useState('');
//   // form state
//   const [title, setTitle] = useState('');
//   const [name, setName] = useState('');
//   const [contents, setContents] = useState<string[]>(['']);
//   const [sendFrom, setSendFrom] = useState('');
//   const [sendTo, setSendTo] = useState('');
//   const [ccFrom, setCCFrom] = useState('');
//   const [subject, setSubject] = useState('');
//   // alert state
//   const [alertVisible, setAlertVisible] = useState(false);
//   const [alertType, setAlertType] = useState<'confirm' | 'error' | 'success' | 'warning'>('confirm');
//   const [alertMessage, setAlertMessage] = useState('');
//   const [confirmSubmit, setConfirmSubmit] = useState(false);

//   // Handle selecting a campaign
//   const handleCampaignSelect = (campaignId: string) => {
//     setSelectedCampaignId(campaignId);
//     const selectedCampaign = campaigns.find(campaign => campaign.id.toString() === campaignId);
//     if (selectedCampaign) {
//       setCampaignTitle(selectedCampaign.campTitle);
//       setCampaignType(selectedCampaign.campType);
//       setCampaignList(selectedCampaign.campList);
//       setTitle(selectedCampaign.contentTitle);
//       setName(selectedCampaign.contentName);
//       setContents([selectedCampaign.contentBody]);
//       setSendFrom(selectedCampaign.sendFrom);
//       setSendTo(selectedCampaign.sendTo);
//       setCCFrom(selectedCampaign.sendCC);
//       setSubject(selectedCampaign.subjectMail);
//     }
//   }

//   const handleSubmit = async () => {
//     const combinedContent = contents.join('\n\n');

//     const CampaignEmail = {
//       campTitle: campaignTitle,
//       campType: campaignType,
//       campList: campaignList,
//       contentTitle: title,
//       contentName: name,
//       contentBody: combinedContent,
//       sendFrom,
//       sendTo,
//       sendCC: ccFrom,
//       subjectMail: subject,
//     };

//     try {
//       await addCampaign(CampaignEmail);
//       setAlertMessage('Campaign created successfully!');
//       setAlertType('success');
//     } catch (error) {
//       setAlertMessage('Failed to create campaign.');
//       setAlertType('error');
//     } finally {
//       setAlertVisible(true);
//     }
//   };
  
//     const handleConfirm = () => {
//       // Show confirmation message when user clicks create
//       setAlertMessage('Are you sure you want to create this campaign?');
//       setAlertType('confirm');
//       setAlertVisible(true);
//       setConfirmSubmit(true);
//     };
  
//     const handleConfirmSubmit = () => {
//       setAlertVisible(false);
//       handleSubmit(); 
//     };

//     const handleContentChange = (index: number, value: string) => {
//         const updatedContents = [...contents];
//         updatedContents[index] = value;
//         setContents(updatedContents);
//     };

//     const addContentSection = () => {
//         setContents([...contents, '']);
//     };

//     const removeContentSection = (index: number) => {
//         const updatedContents = contents.filter((_, i) => i !== index);
//         setContents(updatedContents);
//     };

//     const formattedContents = contents
//         .map(content => content.replace(/\n/g, '<br />') || '< Your content here >')
//         .join('<br /><br />');

//     const updatedContent = defaultMJMLTemplate
//         .replace('{{Title}}', title || '< Your title here >')
//         .replace('{{content}}', formattedContents)
//         .replace('{{name}}', name || '< Your name here >');

//     const previewHTML = mjml2html(updatedContent).html;

// return (
//     <>
//       <h2 className="text-xl font-semibold mb-4">Create New Mail</h2>
//       <div className="flex justify-between">
//         {/* Tabs for Preview and Source Code */}
//         <div className="flex flex-col w-full">
//           <div className="flex justify-start">
//             <button
//               onClick={() => setActiveTab('preview')}
//               className={`px-4 py-2 ${activeTab === 'preview' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
//             >
//               Preview
//             </button>
//             <button
//               onClick={() => setActiveTab('source')}
//               className={`px-4 py-2 ${activeTab === 'source' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
//             >
//               Code
//             </button>
//           </div>

//           {/* Conditional Rendering based on activeTab */}
//           <div className="overflow-y-auto max-h-[565px] border-2 border-cyan-800 rounded">
//             {activeTab === 'preview' ? (
//                 <div 
//                   className="p-4 w-full bg-gray-50" 
//                   dangerouslySetInnerHTML={{ __html: previewHTML }} 
//                 />
//             ) : (
//               <div className="flex flex-col items-center">
//                 <pre
//                     className="p-8 w-full bg-gray-50 text-sm" 
//                     style={{ whiteSpace: 'pre-wrap' }}
//                 >
//                     {updatedContent}
//                 </pre>
//               </div>
//             )}
//           </div>
//         </div>

//         {/* Right side - Form fields */}
//         <div className="flex flex-col p-4 w-full max-w-[380px] md:w-[200px] mx-2">
//           <span className="text-xl font-semibold mb-4 text-cyan-800">Email Scheduled Setting</span>
//           <div className="overflow-y-auto max-h-[500px] p-4 border">

//           <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Campaign Setting</div>

//             {/* Campaign Title */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Campaign Email Title:</label>  
//                 <input
//                     type="text"
//                     placeholder="Enter campaign title"
//                     value={campaignTitle}
//                     onChange={(e) => setCampaignTitle(e.target.value)} 
//                     className="border p-2 w-full outline-cyan-700"
//                 />
//             </div>

//             {/* Campaign Type */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Campaign Email Type:</label>  
//                 <select
//                     value={campaignType}
//                     onChange={(e) => setCampaignType(e.target.value)}
//                     className="border p-2 w-full outline-cyan-700"
//                 >
//                     <option value="">select a type</option>
//                     <option value="Start">Start🟢</option>
//                     <option value="Follow-Up">Follow-Up🟡</option>
//                     <option value="Ended">Ended🔴</option>
//                 </select>
//             </div>

//             {/* Campaign Email List */}
//             <div className="flex flex-col items-center mb-4">
//               <label className="w-full px-2 font-semibold text-cyan-800">Campaign Email List:</label>
//               <select
//                 value={selectedCampaignId}
//                 onChange={(e) => handleCampaignSelect(e.target.value)}
//                 className="border p-2 w-full outline-cyan-700"
//                 >
//                 <option value="">Select a campaign</option>
//                 {campaigns && campaigns.length > 0 ? (
//                     campaigns.map((campaign) => (
//                     <option key={campaign.id} value={campaign.id?.toString()}>
//                         {campaign.campTitle}
//                     </option>
//                     ))
//                 ) : (
//                     <option value="">No campaigns available</option>
//                 )}
//                 </select>
//             </div>

//             <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Edit Email Content</div>

//             {/* Email Title */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Content Title:</label>  
//                 <input
//                     type="text"
//                     placeholder="Enter content title"
//                     value={title}
//                     onChange={(e) => setTitle(e.target.value)} 
//                     className="border p-2 w-full outline-cyan-700"
//                 />
//             </div>

//             {/* Name Input */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Content Name</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter email content name"
//                   value={name}
//                   onChange={(e) => setName(e.target.value)} 
//                   className="border p-2 w-full"
//                 />
//             </div>
            
//             {/* Content Sections */}
//             {contents.map((content, index) => (
//               <div key={index} className="flex flex-col mb-2">
//                 <div className="flex justify-normal">
//                     <label className="w-full px-2 font-semibold text-cyan-800">Content {index + 1}:</label>
//                     {contents.length > 1 && (
//                       <button
//                         onClick={() => removeContentSection(index)}
//                         className="text-cyan-800 text-xl ml-2"
//                       >
//                         <IoMdRemoveCircle />
//                       </button>
//                     )}
//                 </div>
//                 <div className="flex items-center w-full">
//                   <textarea
//                     placeholder="Enter email content"
//                     value={content}
//                     onChange={(e) => handleContentChange(index, e.target.value)}
//                     className="border p-2 w-full h-15"
//                     style={{ resize: 'vertical' }}
//                   />
//                 </div>
//               </div>
//             ))}

//             {/* Add new content button */}
//             <div className="flex justify-end">
//                 <button
//                     className=" text-cyan-800 rounded flex items-center"
//                     onClick={addContentSection}
//                 >
//                 <span className="flex justify-normal items-center">
//                     Add <IoMdAddCircle className="mx-1"/>
//                 </span>
//                 </button>
//             </div>
            
//             <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Email Setting</div>

//             {/* Send email field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send From:</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter sended email"
//                   value={sendFrom}
//                   onChange={(e) => setSendFrom(e.target.value)}
//                   className="border p-2 w-full"
//                 />
//             </div>

//             {/* Send to field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send To:</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter recipient's email"
//                   value={sendTo}
//                   onChange={(e) => setSendTo(e.target.value)}
//                   className="border p-2 w-full"
//                 />
//             </div>

//             {/* Send cc field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send CC:</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter recipient's email"
//                   value={ccFrom}
//                   onChange={(e) => setCCFrom(e.target.value)}
//                   className="border p-2 w-full"
//                 />
//             </div>

//             {/* subject email field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Email Subject:</label>  
//                 <input
//                     type="text"
//                     placeholder="Enter email subject"
//                     value={subject}
//                     onChange={(e) => setSubject(e.target.value)}
//                     className="border p-2 w-full"
//                 />
//             </div>
//           </div>

//           {/* Create Campaign button */}
//           <div className="flex flex-col justify-end py-2">
//             <button
//               onClick={handleConfirm}
//               className="bg-cyan-800 hover:bg-cyan-900 text-white px-4 py-2 rounded-md w-auto"
//             >
//               Create Email
//             </button>
//           </div>
//           <ConfirmAlertMessage
//             type={alertType}
//             message={alertMessage}
//             visible={alertVisible}
//             onClose={() => setAlertVisible(false)}
//             onConfirm={confirmSubmit ? handleConfirmSubmit : handleConfirm}
//             />
//         </div>
//       </div>
//     </>
//   );
// };

// export default MailSetting;

// // update code --> 10 Sept 2024
// // src/components/admin/MarketSetting/MailSetting.tsx

// import React, { useState } from 'react';
// import mjml2html from 'mjml-browser'; 
// import defaultMJMLTemplate from './DefaultMJMLTemplate';
// import { IoMdAddCircle, IoMdRemoveCircle, IoMdCloseCircle } from "react-icons/io";

// interface MailSettingProps {
//   onCreate: (
//     campaign: { title: string; content: string; sendFrom: string; sendTo: string; sendCc: string;  subject: string, scheduleAt: string }) => void;
//   onClose: () => void;
// }

// const  initialTemplates: Record<string, string> = {
//     "Select a Template": "Your content here",
//     "Default Template": "{{Title}} \n Dear {{name}}, \n Your content here.",
//     "Welcome Template": "Welcome {{name}}, \n Thank you for signing up!"
//   };

// const MailSetting: React.FC<MailSettingProps> = ({ onCreate, onClose }) => {
//   // input text state
//   const [title, setTitle] = useState('');
//   const [campaignTitle, setCampaignTitle] = useState('');
//   const [campaignType, setCampaignType] = useState('');
//   const [contents, setContents] = useState<string[]>(['']);
//   const [name, setName] = useState('');
//   // form state 
//   const [sendFrom, setSendFrom] = useState('');
//   const [sendTo, setSendTo] = useState('');
//   const [ccFrom, setCCFrom] = useState('');
//   const [subject, setSubject] = useState('');
//   const [scheduleAt, setScheduleAt] = useState('');
//   // template state
//   const [templates, setTemplates] = useState(initialTemplates);
//   const [newTemplateName, setNewTemplateName] = useState('');
//   const [selectedTemplate, setSelectedTemplate] = useState('Select a Template');
//   const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false); 
//   // component state
//   const [activeTab, setActiveTab] = useState<'preview' | 'source'>('preview');


//   // Function to handle template selection
//   const handleTemplateSelect = (template: string) => {
//     setContents([templates[template]]);
//     setSelectedTemplate(template);
//   };

//   // Function to add and save custom templates
//   const addCustomTemplate = () => {
//     setIsTemplateModalOpen(true); // Open the modal
//   };

//   const handleSubmit = () => {
//     if (!title || contents.every(content => content === '') || !sendFrom || !sendTo || !subject ) {
//         alert('Please fill out all required fields');
//       return;
//     }
  
//     const combinedContent = contents.join('\n\n'); 
//     // Make sure to include scheduleAt here
//     const scheduleAt = new Date().toISOString();

//     onCreate({ title, content: combinedContent, sendFrom, sendTo, sendCc: ccFrom, subject, scheduleAt, });
//     onClose();
//   };

//   // Function to handle content changes for multiple contents
//   const handleContentChange = (index: number, value: string) => {
//     const updatedContents = [...contents];
//     updatedContents[index] = value;
//     setContents(updatedContents);
//   };

//   // Function to add another content section
//   const addContentSection = () => {
//     setContents([...contents, '']);
//   };

//   // Function to remove a content section
//   const removeContentSection = (index: number) => {
//     const updatedContents = contents.filter((_, i) => i !== index);
//     setContents(updatedContents);
//   };

//   // Convert MJML to HTML for preview
//   const formattedContents = contents.map(content => 
//     content.replace(/\n/g, '<br />') || '< Your content here >').join('<br /><br />');
  
//     const updatedContent = defaultMJMLTemplate
//         .replace('{{Title}}', title || '< Your title here >')
//         .replace('{{content}}', formattedContents)
//         .replace('{{name}}', name || '< Your name here >');

//   const previewHTML = mjml2html(updatedContent).html;

//   // Function to close the template modal and add the template
//   const handleTemplateModalSubmit = () => {
//     setTemplates({
//       ...templates,
//       [newTemplateName]: "Your content here"
//     });
//     setIsTemplateModalOpen(false); // Close modal
//     setNewTemplateName(''); // Clear input
//   };

//   return (
//     <>
//       <h2 className="text-xl font-semibold mb-4">Create New Campaign</h2>
//       <div className="flex justify-between">
//         {/* Tabs for Preview and Source Code */}
//         <div className="flex flex-col w-full">
//           <div className="flex justify-start">
//             <button
//               onClick={() => setActiveTab('preview')}
//               className={`px-4 py-2 ${activeTab === 'preview' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
//             >
//               Preview
//             </button>
//             <button
//               onClick={() => setActiveTab('source')}
//               className={`px-4 py-2 ${activeTab === 'source' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
//             >
//               Code
//             </button>
//           </div>

//           {/* Conditional Rendering based on activeTab */}
//           <div className="overflow-y-auto max-h-[565px] border-2 border-cyan-800 rounded">
//             {activeTab === 'preview' ? (
//                 <div 
//                   className="p-4 w-full bg-gray-50" 
//                   dangerouslySetInnerHTML={{ __html: previewHTML }} 
//                 />
//             ) : (
//               <div className="flex flex-col items-center">
//                 <pre
//                     className="p-8 w-full bg-gray-50 text-sm" 
//                     style={{ whiteSpace: 'pre-wrap' }}
//                 >
//                     {updatedContent}
//                 </pre>
//               </div>
//             )}
//           </div>
//         </div>

//         {/* Right side - Form fields */}
//         <div className="flex flex-col p-4 w-full max-w-[380px] md:w-[200px] mx-2">
//           <span className="text-xl font-semibold mb-4 text-cyan-800">Email Scheduled Setting</span>
//           <div className="overflow-y-auto max-h-[500px] p-4 border">

//           <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Campaign Setting</div>

//             {/* Campaign Title */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Campaign Title:</label>  
//                 <input
//                     type="text"
//                     placeholder="Enter campaign title"
//                     value={campaignTitle}
//                     onChange={(e) => setCampaignTitle(e.target.value)} 
//                     className="border p-2 w-full outline-cyan-700"
//                 />
//             </div>

//             {/* Campaign Type */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Campaign Type:</label>  
//                 <input
//                     type="text"
//                     placeholder="Enter campaign title"
//                     value={campaignType}
//                     onChange={(e) => setCampaignType(e.target.value)} 
//                     className="border p-2 w-full outline-cyan-700"
//                 />
//             </div>

//             <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Edit Email Content</div>

//             {/* Email Title */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Content Title:</label>  
//                 <input
//                     type="text"
//                     placeholder="Enter content title"
//                     value={title}
//                     onChange={(e) => setTitle(e.target.value)} 
//                     className="border p-2 w-full outline-cyan-700"
//                 />
//             </div>

//             {/* Template Selector */}
//             <div className="flex flex-col mb-4">
//               <div className="flex justify-between">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Content Template:</label>
//                 <div className="flex justify-end">
//                     <button
//                         className="text-cyan-800 rounded flex items-center"
//                         onClick={addCustomTemplate}
//                     >
//                         <span className="flex justify-normal items-center">
//                             Add <IoMdAddCircle className="mx-1"/>
//                         </span>
//                     </button>
//                 </div>
//               </div>
//               <select 
//                 value={selectedTemplate}
//                 onChange={(e) => handleTemplateSelect(e.target.value)}
//                 className="border p-2 w-full outline-cyan-700"
//               >
//                 {Object.keys(templates).map(template => (
//                   <option key={template} value={template}>{template}</option>
//                 ))}
//               </select>
//             </div>

//             {/* Modal for adding new template */}
//             {isTemplateModalOpen && (
//               <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
//                 <div className="bg-white p-8 rounded-md shadow-lg">
//                   <div className="flex justify-end">
//                     <button
//                         onClick={() => setIsTemplateModalOpen(false)}
//                         className=" text-rose-500 text-xl"
//                     >
//                         <IoMdCloseCircle />
//                     </button>
//                   </div>
//                   <h2 className="text-lg mb-4">Enter New Template Name</h2>
//                   <input
//                         type="text"
//                         placeholder="Template name"
//                         value={newTemplateName}
//                         onChange={(e) => setNewTemplateName(e.target.value)}
//                         className="border p-2 w-full mb-4"
//                   />
//                   <button
//                         onClick={handleTemplateModalSubmit}
//                         className="bg-cyan-800 hover:bg-cyan-900 text-white px-4 py-2 rounded-md"
//                   >
//                     OK
//                   </button>
//                 </div>
//               </div>
//             )}

//              {/* Name Input */}
//              <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Content Name</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter email content name"
//                   value={name}
//                   onChange={(e) => setName(e.target.value)} 
//                   className="border p-2 w-full"
//                 />
//             </div>
            
//             {/* Email content */}
//             {contents.map((content, index) => (
//               <div key={index} className="flex flex-col mb-2">
//                 <div className="flex justify-normal">
//                     <label className="w-full px-2 font-semibold text-cyan-800">Content {index + 1}:</label>
//                     {contents.length > 1 && (
//                       <button
//                         onClick={() => removeContentSection(index)}
//                         className="text-cyan-800 text-xl ml-2"
//                       >
//                         <IoMdRemoveCircle />
//                       </button>
//                     )}
//                 </div>
//                 <div className="flex items-center w-full">
//                   <textarea
//                     placeholder="Enter email content"
//                     value={content}
//                     onChange={(e) => handleContentChange(index, e.target.value)}
//                     className="border p-2 w-full h-15"
//                     style={{ resize: 'vertical' }}
//                   />
//                 </div>
//               </div>
//             ))}

//             {/* Add new content button */}
//             <div className="flex justify-end">
//                 <button
//                     className=" text-cyan-800 rounded flex items-center"
//                     onClick={addContentSection}
//                 >
//                 <span className="flex justify-normal items-center">
//                     Add <IoMdAddCircle className="mx-1"/>
//                 </span>
//                 </button>
//             </div>
            
//             <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Email Setting</div>

//             {/* Send email field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send From:</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter sended email"
//                   value={sendFrom}
//                   onChange={(e) => setSendFrom(e.target.value)}
//                   className="border p-2 w-full"
//                 />
//             </div>

//             {/* Send to field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send To:</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter recipient's email"
//                   value={sendFrom}
//                   onChange={(e) => setSendTo(e.target.value)}
//                   className="border p-2 w-full"
//                 />
//             </div>

//             {/* Send cc field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send CC:</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter recipient's email"
//                   value={ccFrom}
//                   onChange={(e) => setCCFrom(e.target.value)}
//                   className="border p-2 w-full"
//                 />
//             </div>

//             {/* subject email field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Email Subject:</label>  
//                 <input
//                     type="text"
//                     placeholder="Enter email subject"
//                     value={subject}
//                     onChange={(e) => setSubject(e.target.value)}
//                     className="border p-2 w-full"
//                 />
//             </div>

//             {/* Schedule field */}
//             <div className="flex flex-col items-center mb-4">
//             <label className="w-full px-2 font-semibold text-cyan-800">scheduleAt:</label>  
//             <input
//                 type="datetime-local"
//                 value={scheduleAt}
//                 onChange={(e) => setScheduleAt(e.target.value)}
//                 className="border p-2 w-full"

//                 />
//             </div>


//           </div>

//           {/* Create Campaign button */}
//           <div className="flex flex-col justify-end py-2">
//             <button
//               onClick={handleSubmit}
//               className="bg-cyan-800 hover:bg-cyan-900 text-white px-4 py-2 rounded-md w-auto"
//             >
//               Create Campaign
//             </button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default MailSetting;

// // udpate code --> 06 Sept 2024
// // src/components/admin/MarketSetting/MailSetting.tsx

// import React, { useState } from 'react';
// import mjml2html from 'mjml-browser'; 
// import defaultMJMLTemplate from './DefaultMJMLTemplate';
// import { IoMdAddCircle, IoMdRemoveCircle, IoMdCloseCircle } from "react-icons/io";

// interface MailSettingProps {
//   onCreate: (
//     campaign: 
//     { 
//         title: string; 
//         content: string; 
//         scheduleAt: string; 
//         sendFrom: string; 
//         subject: string, 
//         periodDays: number, 
//         status: string 
//     }) => void;
//   onClose: () => void;
// }

// const  initialTemplates: Record<string, string> = {
//     "Select a Template": "Your content here",
//     "Default Template": "{{Title}} \n Dear {{name}}, \n Your content here.",
//     "Welcome Template": "Welcome {{name}}, \n Thank you for signing up!"
//   };

// const MailSetting: React.FC<MailSettingProps> = ({ onCreate, onClose }) => {
//   // input text state
//   const [title, setTitle] = useState('');
//   const [campaignTitle, setCampaignTitle] = useState('');
//   const [contents, setContents] = useState<string[]>(['']);
//   const [name, setName] = useState('');
//   // form state 
//   const [scheduleDate, setScheduleDate] = useState('');
//   const [scheduleTime, setScheduleTime] = useState('');
//   const [sendFrom, setSendFrom] = useState('');
//   const [ccFrom, setCCFrom] = useState('');
//   const [subject, setSubject] = useState('');
//   const [templates, setTemplates] = useState(initialTemplates);
//   const [newTemplateName, setNewTemplateName] = useState('');
//   const [isDateEnabled, setIsDateEnabled] = useState(false);
//   const [isTimeEnabled, setIsTimeEnabled] = useState(false);
//   const [periodDays, setPeriodDays] = useState('30');
//   const [status, setStatus] = useState('Active');
//   // component state
//   const [activeTab, setActiveTab] = useState<'preview' | 'source'>('preview');
//   const [selectedTemplate, setSelectedTemplate] = useState('Select a Template');
//   const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false); 
//   const [isModalOpen, setIsModalOpen] = useState(false); 
//   const [specifiedDay, setSpecifiedDay] = useState('');

//   // Function to handle template selection
//   const handleTemplateSelect = (template: string) => {
//     setContents([templates[template]]);
//     setSelectedTemplate(template);
//   };

//   // Function to add and save custom templates
//   const addCustomTemplate = () => {
//     setIsTemplateModalOpen(true); // Open the modal
//   };

//   const handleSubmit = () => {
//     if (!title || contents.every(content => content === '') || !sendFrom || !subject || (isDateEnabled && !scheduleDate) || (isTimeEnabled && !scheduleTime)) {
//       alert('Please fill out all required fields');
//       return;
//     }
  
//     const scheduleAt = `${scheduleDate} ${scheduleTime}`;
//     const combinedContent = contents.join('\n\n'); 
  
//     onCreate({ 
//         title, 
//         content: combinedContent, 
//         scheduleAt, 
//         sendFrom, 
//         subject, 
//         periodDays: parseInt(periodDays), 
//         status 
//     });
//     onClose();
//   };

//   // Function to handle content changes for multiple contents
//   const handleContentChange = (index: number, value: string) => {
//     const updatedContents = [...contents];
//     updatedContents[index] = value;
//     setContents(updatedContents);
//   };

//   // Function to add another content section
//   const addContentSection = () => {
//     setContents([...contents, '']);
//   };

//   // Function to remove a content section
//   const removeContentSection = (index: number) => {
//     const updatedContents = contents.filter((_, i) => i !== index);
//     setContents(updatedContents);
//   };

//   // Convert MJML to HTML for preview
//   const formattedContents = contents.map(content => 
//     content.replace(/\n/g, '<br />') || '< Your content here >').join('<br /><br />');
  
//     const updatedContent = defaultMJMLTemplate
//         .replace('{{Title}}', title || '< Your title here >')
//         .replace('{{content}}', formattedContents)
//         .replace('{{name}}', name || '< Your name here >');

//   const previewHTML = mjml2html(updatedContent).html;

//   // Function to open the modal when "Specify Day" is selected
//   const handleSpecifyDaySelect = () => {
//     setIsModalOpen(true);
//   };

//   // Function to close the modal and set the specified day count
//   const handleModalSubmit = () => {
//     setPeriodDays(specifiedDay);
//     setIsModalOpen(false);
//   };

//   const handleModalClose = () => {
//     setIsModalOpen(false);
//   };

//   // Function to close the template modal and add the template
//   const handleTemplateModalSubmit = () => {
//     setTemplates({
//       ...templates,
//       [newTemplateName]: "Your content here"
//     });
//     setIsTemplateModalOpen(false); // Close modal
//     setNewTemplateName(''); // Clear input
//   };

//   return (
//     <>
//       <h2 className="text-xl font-semibold mb-4">Create New Campaign</h2>
//       <div className="flex justify-between">
//         {/* Tabs for Preview and Source Code */}
//         <div className="flex flex-col w-full">
//           <div className="flex justify-start">
//             <button
//               onClick={() => setActiveTab('preview')}
//               className={`px-4 py-2 ${activeTab === 'preview' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
//             >
//               Preview
//             </button>
//             <button
//               onClick={() => setActiveTab('source')}
//               className={`px-4 py-2 ${activeTab === 'source' ? 'bg-cyan-800 text-white' : 'bg-gray-200'}`}
//             >
//               Code
//             </button>
//           </div>

//           {/* Conditional Rendering based on activeTab */}
//           <div className="overflow-y-auto max-h-[580px] border-2 border-cyan-800 rounded">
//             {activeTab === 'preview' ? (
//                 <div 
//                   className="p-4 w-full bg-gray-50" 
//                   dangerouslySetInnerHTML={{ __html: previewHTML }} 
//                 />
//             ) : (
//               <div className="flex flex-col items-center">
//                 <pre
//                     className="p-8 w-full bg-gray-50 text-sm" 
//                     style={{ whiteSpace: 'pre-wrap' }}
//                 >
//                     {updatedContent}
//                 </pre>
//               </div>
//             )}
//           </div>
//         </div>

//         {/* Right side - Form fields */}
//         <div className="flex flex-col p-4 w-full max-w-[380px] md:w-[200px] mx-2">
//           <span className="text-xl font-semibold mb-4 text-cyan-800">Email Scheduled Setting</span>
//           <div className="overflow-y-auto max-h-[500px] p-4 border">

//           <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Campaign Setting</div>

//             {/* Campaign Title */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Campaign Title:</label>  
//                 <input
//                     type="text"
//                     placeholder="Enter campaign title"
//                     value={campaignTitle}
//                     onChange={(e) => setCampaignTitle(e.target.value)} 
//                     className="border p-2 w-full outline-cyan-700"
//                 />
//             </div>

//             <div className="mb-4 bg-cyan-800 text-white text-center text-xs rounded-full mx-20 py-1">Email Setting</div>

//             {/* Email Title */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Email Title:</label>  
//                 <input
//                     type="text"
//                     placeholder="Enter content title"
//                     value={title}
//                     onChange={(e) => setTitle(e.target.value)} 
//                     className="border p-2 w-full outline-cyan-700"
//                 />
//             </div>

//             {/* Template Selector */}
//             <div className="flex flex-col mb-4">
//               <div className="flex justify-between">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Template:</label>
//                 <div className="flex justify-end">
//                     <button
//                         className="text-cyan-800 rounded flex items-center"
//                         onClick={addCustomTemplate}
//                     >
//                         <span className="flex justify-normal items-center">
//                             Add <IoMdAddCircle className="mx-1"/>
//                         </span>
//                     </button>
//                 </div>
//               </div>
//               <select 
//                 value={selectedTemplate}
//                 onChange={(e) => handleTemplateSelect(e.target.value)}
//                 className="border p-2 w-full outline-cyan-700"
//               >
//                 {Object.keys(templates).map(template => (
//                   <option key={template} value={template}>{template}</option>
//                 ))}
//               </select>
//             </div>

//             {/* Modal for adding new template */}
//             {isTemplateModalOpen && (
//               <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
//                 <div className="bg-white p-8 rounded-md shadow-lg">
//                   <div className="flex justify-end">
//                     <button
//                         onClick={() => setIsTemplateModalOpen(false)}
//                         className=" text-rose-500 text-xl"
//                     >
//                         <IoMdCloseCircle />
//                     </button>
//                   </div>
//                   <h2 className="text-lg mb-4">Enter New Template Name</h2>
//                   <input
//                         type="text"
//                         placeholder="Template name"
//                         value={newTemplateName}
//                         onChange={(e) => setNewTemplateName(e.target.value)}
//                         className="border p-2 w-full mb-4"
//                   />
//                   <button
//                         onClick={handleTemplateModalSubmit}
//                         className="bg-cyan-800 hover:bg-cyan-900 text-white px-4 py-2 rounded-md"
//                   >
//                     OK
//                   </button>
//                 </div>
//               </div>
//             )}

//              {/* Name Input */}
//              <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Name</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter recipient's name"
//                   value={name}
//                   onChange={(e) => setName(e.target.value)} 
//                   className="border p-2 w-full"
//                 />
//             </div>
            
//             {/* Email content */}
//             {contents.map((content, index) => (
//               <div key={index} className="flex flex-col mb-2">
//                 <div className="flex justify-normal">
//                     <label className="w-full px-2 font-semibold text-cyan-800">Content {index + 1}:</label>
//                     {contents.length > 1 && (
//                       <button
//                         onClick={() => removeContentSection(index)}
//                         className="text-cyan-800 text-xl ml-2"
//                       >
//                         <IoMdRemoveCircle />
//                       </button>
//                     )}
//                 </div>
//                 <div className="flex items-center w-full">
//                   <textarea
//                     placeholder="Enter email content"
//                     value={content}
//                     onChange={(e) => handleContentChange(index, e.target.value)}
//                     className="border p-2 w-full h-15"
//                     style={{ resize: 'vertical' }}
//                   />
//                 </div>
//               </div>
//             ))}

//             {/* Add new content button */}
//             <div className="flex justify-end">
//                 <button
//                 className=" text-cyan-800 rounded flex items-center"
//                 onClick={addContentSection}
//                 >
//                 <span className="flex justify-normal items-center">
//                     Add 
//                     <IoMdAddCircle className="mx-1"/>
//                 </span>
//                 </button>
//             </div>
            
//             {/* Send email field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send From:</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter sended email"
//                   value={sendFrom}
//                   onChange={(e) => setSendFrom(e.target.value)}
//                   className="border p-2 w-full"
//                 />
//             </div>

//             {/* Send to field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send To:</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter recipient's email"
//                   value={sendFrom}
//                   onChange={(e) => setSendFrom(e.target.value)}
//                   className="border p-2 w-full"
//                 />
//             </div>

//             {/* Send cc field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send CC:</label>  
//                 <input
//                   type="text"
//                   placeholder="Enter recipient's email"
//                   value={ccFrom}
//                   onChange={(e) => setCCFrom(e.target.value)}
//                   className="border p-2 w-full"
//                 />
//             </div>

//             {/* subject email field */}
//             <div className="flex flex-col items-center mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Email Subject:</label>  
//                 <input
//                     type="text"
//                     placeholder="Enter email subject"
//                     value={subject}
//                     onChange={(e) => setSubject(e.target.value)}
//                     className="border p-2 w-full"
//                 />
//             </div>

//             {/* Date Scheduled with Checkbox */}
//             <div className="flex flex-col mb-4">
//                 <div className="flex justify-normal">
//                 <label className="w-auto px-2 font-semibold text-cyan-800">Email Date Scheduled:</label>
//                 <input
//                     type="checkbox"
//                     className=" accent-cyan-800"
//                     checked={isDateEnabled}
//                     onChange={() => setIsDateEnabled(!isDateEnabled)}
//                 />
//                 </div>
//                 <input
//                     type="date"
//                     value={scheduleDate}
//                     onChange={(e) => setScheduleDate(e.target.value)}
//                     className={`border rounded p-2 w-full ${!isDateEnabled ? 'bg-gray-100' : ''}`}
//                     disabled={!isDateEnabled}
//                 />
//             </div>

//             {/* Time Scheduled with Checkbox */}
//             <div className="flex flex-col mb-4">
//                 <div className="flex justify-normal">
//                 <label className="w-auto px-2 font-semibold text-cyan-800">Email Time Scheduled:</label>
//                 <input
//                     type="checkbox"
//                     className=" accent-cyan-800"
//                     checked={isTimeEnabled}
//                     onChange={() => setIsTimeEnabled(!isTimeEnabled)}
//                 />
//                 </div>
//                 <input
//                     type="time"
//                     value={scheduleTime}
//                     onChange={(e) => setScheduleTime(e.target.value)}
//                     className={`border rounded p-2 w-full ${!isTimeEnabled ? 'bg-gray-100' : ''}`}
//                     disabled={!isTimeEnabled}
//                 />
//             </div>

//             {/* Period Days Dropdown */}
//             <div className="flex flex-col justify-normal mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Send Period:</label>
//                 <select
//                     value={periodDays}
//                     onChange={(e) => e.target.value === 'custom' ? handleSpecifyDaySelect() : setPeriodDays(e.target.value)}
//                     className="border p-2 w-full"
//                 >
//                     <option value="Active">Select a Period:</option>
//                     <option value="30">30 Days</option>
//                     <option value="14">14 Days</option>
//                     <option value="7">7 Days</option>
//                     <option value="custom">Specify Day</option>
//                 </select>
//             </div>

//             {/* Modal for specifying the day count */}
//             {isModalOpen && (
//               <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
//                 <div className="bg-white p-8 rounded-md shadow-lg">
//                   <div className="flex justify-end">
//                     <button
//                         onClick={handleModalClose}
//                         className=" text-rose-500 text-xl"
//                     >
//                         <IoMdCloseCircle />
//                     </button>
//                   </div>
//                   <h2 className="text-lg mb-4">Enter Specific Day Count</h2>
//                   <input
//                     type="number"
//                     placeholder="Specify day count"
//                     value={specifiedDay}
//                     onChange={(e) => setSpecifiedDay(e.target.value)}
//                     className="border p-2 w-full mb-4"
//                   />
//                   <button
//                     onClick={handleModalSubmit}
//                     className="bg-cyan-800 hover:bg-cyan-900 text-white px-4 py-2 rounded-md"
//                   >
//                     OK
//                   </button>
//                 </div>
//               </div>
//             )}

//             {/* Scheduled Status Dropdown */}
//             <div className="flex flex-col justify-normal mb-4">
//                 <label className="w-full px-2 font-semibold text-cyan-800">Scheduled Status:</label>
//                 <select
//                 value={status}
//                 onChange={(e) => setStatus(e.target.value)}
//                 className="border p-2 w-full"
//                 >
//                 <option value="Active">Select a Status:</option>
//                 <option value="Active">Active</option>
//                 <option value="Deactive">Deactive</option>
//                 </select>
//             </div>
//           </div>

//           {/* Create Campaign button */}
//           <div className="flex flex-col justify-end py-2">
//             <button
//               onClick={handleSubmit}
//               className="bg-cyan-800 hover:bg-cyan-900 text-white px-4 py-2 rounded-md w-auto"
//             >
//               Create Campaign
//             </button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default MailSetting;

