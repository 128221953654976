// src/components/TopMenu.tsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCog, FaSignOutAlt, FaUser } from 'react-icons/fa';
// import { BiScan } from "react-icons/bi";

import NotifyTop from '../components/client/TopMenu/NotificationTop';
import AccountSettingsDrawer from './client/TopMenu/AccountSettingTop'; 
import DashboardSettingDrawer from './client/TopMenu/DashboardSettingTop';

import { useTheme } from '../context/ThemeContext';
import useUserNotification from '../hooks/Dashboard/useUserNotification';
import useUserDashboard from '../hooks/Dashboard/useUserDashboard'; 

import r1logo from '../assets/usrDashboard/logo_r1_dash_v02.png';

const TopMenu: React.FC = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { notifications } = useUserNotification(); 
  const { user } = useUserDashboard(); 

  // State to manage unread notifications count
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [isAccDrawerOpen, setIsAccDrawerOpen] = useState(false);
  const [isDashDrawerOpen, setIsDashDrawerOpen] = useState(false);

  useEffect(() => {
    // Update unread notifications whenever notifications change
    const unreadCount = notifications.filter(notification => !notification.read).length;
    setUnreadNotifications(unreadCount);
  }, [notifications]);

  const handleLogout = () => {
    navigate('/login');
  };

  if (!user) {
    return null; 
  }

  return (
    <>
      <div className="flex justify-between items-center py-5 w-full z-40" 
          style={{ fontFamily: theme.fontFamily, color: theme.textColor }}>
        <div className="flex items-center">
          <img src={r1logo} alt="ROneLogo" className="h-16 mx-4 md:mx-10" />
        </div>
        <div className="flex space-x-4 p-2 mr-6 rounded-[100px] shadow-md">
          <NotifyTop 
            unreadNotifications={unreadNotifications}
            setUnreadNotifications={setUnreadNotifications}
          />
          {/* <button 
            className="p-2 focus:outline-none" 
            aria-label="Scan QR Code" 
          >
            <BiScan 
              className="w-5 h-5" 
              style={{ color: theme.textColor }} 
            />
          </button> */}
          <button 
            className="p-2 focus:outline-none" 
            aria-label="Account Settings" 
            onClick={() => setIsAccDrawerOpen(true)}
          >
            <FaUser 
              className="w-5 h-5" 
              style={{ color: theme.textColor }} 
            />
          </button>
          <button 
            className="p-2 focus:outline-none" 
            aria-label="Dashboard Settings" 
            onClick={() => setIsDashDrawerOpen(true)}
          >
            <FaCog 
              className="w-5 h-5" 
              style={{ color: theme.textColor }} 
            />
          </button>
          <button 
            className="p-2 focus:outline-none" 
            aria-label="Logout" 
            onClick={handleLogout}
          >
            <FaSignOutAlt 
              className="w-5 h-5" 
              style={{ color: theme.textColor }} 
            />
          </button>
        </div>
      </div>
      <AccountSettingsDrawer 
        isOpen={isAccDrawerOpen} 
        onClose={() => setIsAccDrawerOpen(false)} 
      />
      <DashboardSettingDrawer 
        isOpen={isDashDrawerOpen} 
        onClose={() => setIsDashDrawerOpen(false)} 
      />
    </>
  );
};

export default TopMenu;

// // udpate code --> 22 Aug 2024
// // src/components/TopMenu.tsx

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FaCog, FaSignOutAlt, FaUser } from 'react-icons/fa';

// import NotifyTop from '../components/client/TopMenu/NotificationTop';
// import AccountSettingsDrawer from './client/TopMenu/AccountSettingTop'; 
// import DashboardSettingDrawer from './client/TopMenu/DashboardSettingTop';

// import { useTheme } from '../context/ThemeContext';
// import useUserNotification from '../hooks/Dashboard/useUserNotification';
// import useUserDashboard from '../hooks/Dashboard/useUserDashboard'; 

// import r1logo from '../assets/usrDashboard/logo_r1_dash_v02.png';

// const TopMenu: React.FC = () => {
//   const navigate = useNavigate();
//   const { theme } = useTheme();
//   const { notifications } = useUserNotification(); 
//   const { user } = useUserDashboard(); 

//   // State to manage unread notifications count
//   const [unreadNotifications, setUnreadNotifications] = useState(0);
//   const [isAccDrawerOpen, setIsAccDrawerOpen] = useState(false);
//   const [isDashDrawerOpen, setIsDashDrawerOpen] = useState(false);

//   useEffect(() => {
//     // Update unread notifications whenever notifications change
//     const unreadCount = notifications.filter(notification => !notification.read).length;
//     setUnreadNotifications(unreadCount);
//   }, [notifications]);

//   const handleLogout = () => {
//     navigate('/login');
//   };

//   if (!user) {
//     return null; 
//   }

//   return (
//     <>
//       <div className="flex justify-between items-center py-5 w-full z-40" 
//           style={{ fontFamily: theme.fontFamily, color: theme.textColor }}>
//         <div className="flex items-center">
//           <img src={r1logo} alt="ROneLogo" className="h-16 mx-4 md:mx-10" />
//         </div>
//         <div className="flex space-x-4 p-2 mr-6 rounded-[100px] shadow-md">
//           <NotifyTop 
//             unreadNotifications={unreadNotifications}
//             setUnreadNotifications={setUnreadNotifications}
//           />
//           <button 
//             className="p-2 focus:outline-none" 
//             aria-label="Account Settings" 
//             onClick={() => setIsAccDrawerOpen(true)}
//           >
//             <FaUser 
//               className="w-5 h-5" 
//               style={{ color: theme.textColor }} 
//             />
//           </button>
//           <button 
//             className="p-2 focus:outline-none" 
//             aria-label="Dashboard Settings" 
//             onClick={() => setIsDashDrawerOpen(true)}
//           >
//             <FaCog 
//               className="w-5 h-5" 
//               style={{ color: theme.textColor }} 
//             />
//           </button>
//           <button 
//             className="p-2 focus:outline-none" 
//             aria-label="Logout" 
//             onClick={handleLogout}
//           >
//             <FaSignOutAlt 
//               className="w-5 h-5" 
//               style={{ color: theme.textColor }} 
//             />
//           </button>
//         </div>
//       </div>
//       <AccountSettingsDrawer isOpen={isAccDrawerOpen} onClose={() => setIsAccDrawerOpen(false)} />
//       <DashboardSettingDrawer isOpen={isDashDrawerOpen} onClose={() => setIsDashDrawerOpen(false)} />
//     </>
//   );
// };

// export default TopMenu;