// src/components/admin/OverviewStats.tsx

import React from 'react';

interface OverviewStatsProps {
  totalCampaigns: number;
  scheduledCampaigns: number;
  sentCampaigns: number;
}

const OverviewStats: React.FC<OverviewStatsProps> = ({ totalCampaigns, scheduledCampaigns, sentCampaigns }) => {
  return (
    <div className="flex flex-row justify-between items-center w-auto h-auto p-6 my-2 rounded-xl border">
      <h2 className="text-xl font-semibold mb-4">Overview Stats</h2>
      <div className="flex flex-row space-x-2">
        <div className="bg-white shadow-md p-6 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Total Campaigns</h2>
          <p className="text-3xl font-bold text-right">{totalCampaigns}</p>
        </div>
        <div className="bg-white shadow-md p-6 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Scheduled Campaigns</h2>
          <p className="text-3xl font-bold text-right">{scheduledCampaigns}</p>
        </div>
        <div className="bg-white shadow-md p-6 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Sent Campaigns</h2>
          <p className="text-3xl font-bold text-right">{sentCampaigns}</p>
        </div>
        <div className="bg-white shadow-md p-6 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Total Clicked</h2>
          <p className="text-3xl font-bold text-right">{sentCampaigns}</p>
        </div>
      </div>
    </div>
  );
};

export default OverviewStats;
