// src/components/admin/SubscriptionSetting/SubSetting.tsx

import React, { useState, useEffect } from 'react';
import useSubSetting from '../../../hooks/AdminDashboard/useSubSetting';
import { TbEdit, TbEditOff } from "react-icons/tb";
import { IoIosRemoveCircle, IoMdAddCircleOutline } from "react-icons/io";
import { IoReturnUpBackOutline } from "react-icons/io5";
import TrialSetting from '../TrialSetting/TrialSetting';
import VoucherSetting from '../VoucherSetting/VoucherSetting';

interface Plan {
  id?: number;
  planID?: string;
  planName: string;
  planDescription: string;
  planFeatures: string[];
  planAmount: string;
  planCurrency: string;
  planPeriod: string;
  planTrial: "0";
  planTrialPeriod: string;
  planTrialMemberType: string[];
  planVoucherCode: string;
  planDiscountType: string;
  planDiscountValue: string;
  period: string;
}

interface Product {
  id: number;
  productId: string;
  productName: string;
  productDescription: string;
  image?: string;
  plans: Plan[];
}

const SubscriptionSettings: React.FC = () => {
  const { products = [], savePlan, handleSaveProduct: saveProduct, deleteProduct, createProduct, deletePlan } = useSubSetting();

  // Product Setup
  const [filterProductId, setFilterProductId] = useState<number | null>(products.length > 0 ? products[0]?.id : null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(products.length > 0 ? products[0] : null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [description, setDescription] = useState(selectedProduct?.productDescription || '');

  // Plan Setup
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(
    selectedProduct && selectedProduct.plans && selectedProduct.plans.length > 0
      ? selectedProduct.plans[0]
      : null
  );
  const [isPlanEditMode, setIsPlanEditMode] = useState(false);
  const [planTitle, setPlanTitle] = useState(selectedPlan?.planName || '');
  const [planDescription, setPlanDescription] = useState(selectedPlan?.planDescription || '');
  const [planFeatures, setPlanFeatures] = useState<string[]>(selectedPlan?.planFeatures || []);
  const [planFee, setPlanFee] = useState(selectedPlan?.planAmount || '');
  const [planPeriod, setPlanPeriod] = useState(selectedPlan?.planPeriod || '');
  const [isAddingFeature, setIsAddingFeature] = useState(false);
  const [newFeature, setNewFeature] = useState('');

  // Trial Setup
  const [isTrialEnabled, setIsTrialEnabled] = useState(false);
  const [trialPeriod, setTrialPeriod] = useState('');
  const [trialMembersType, setTrialMembersType] = useState<string[]>([]);
  const [planCurrency, setPlanCurrency] = useState('USD');
  const [isCustomPlanPeriod, setIsCustomPlanPeriod] = useState(false);
  const [customPlanDays, setCustomPlanDays] = useState('');

  // Voucher Setup
  const [voucherCode, setVoucherCode] = useState('');
  const [discountType, setDiscountType] = useState('percentage');
  const [discountValue, setDiscountValue] = useState('');

  const [allProducts, setAllProducts] = useState<Product[]>([]);

  useEffect(() => {
    setAllProducts([...products]);
  }, [products]);

  useEffect(() => {
    if (selectedProduct && selectedProduct.plans) {
      setSelectedPlan(selectedProduct.plans.find(plan => plan.id === selectedPlan?.id) || selectedProduct.plans[0]);
      setDescription(selectedProduct.productDescription);
    } else {
      setSelectedPlan(null);
    }
  }, [selectedProduct, selectedPlan?.id, products]);

  useEffect(() => {
    if (selectedPlan) {
      setPlanTitle(selectedPlan.planName);
      setPlanDescription(selectedPlan.planDescription);
      setPlanFeatures(selectedPlan.planFeatures);
      setPlanFee(selectedPlan.planAmount);
      setPlanPeriod(selectedPlan.planPeriod);
      setIsTrialEnabled(selectedPlan.planTrialPeriod !== '0');
      setTrialPeriod(selectedPlan.planTrialPeriod);
      setTrialMembersType(selectedPlan.planTrialMemberType || []);
      setVoucherCode(selectedPlan.planVoucherCode);
      setDiscountType(selectedPlan.planDiscountType);
      setDiscountValue(selectedPlan.planDiscountValue);
    } else {
      setPlanTitle('');
      setPlanDescription('');
      setPlanFeatures([]);
      setPlanFee('');
      setPlanPeriod('');
      setIsTrialEnabled(false);
      setTrialPeriod('');
      setTrialMembersType([]);
      setVoucherCode('');
      setDiscountType('percentage');
      setDiscountValue('');
    }
  }, [selectedPlan, selectedPlan?.id, products]);

  // handle product function -->>>
  const handleCreateProduct = async () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const randomChar = characters.charAt(Math.floor(Math.random() * characters.length));
    const randomNum = Math.floor(1000 + Math.random() * 9000);
    const productId = `${randomChar}-${randomNum}`;
  
    const newProduct: Product = {
      id: Date.now(),
      productId,
      productName: 'New Product',
      productDescription: 'Description of the new product.',
      plans: [],
    };
  
    const savedProduct = await createProduct(newProduct);
  
    setSelectedProduct(savedProduct);
    setFilterProductId(savedProduct.id);
    setDescription(savedProduct.productDescription);
    setIsEditMode(true);
  };

  const handleProductChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const productId = event.target.value === "create-new" ? null : parseInt(event.target.value);
    setFilterProductId(productId);

    if (productId === null) {
      const newProduct: Product = {
        id: Date.now(),
        productId: 'New Product ID',
        productName: '',
        productDescription: '',
        plans: [],
      };
      setSelectedProduct(newProduct);
      setDescription(newProduct.productDescription);
    } else {
      const selected = products.find((product) => product.id === productId);
      setSelectedProduct(selected || null);
      setDescription(selected?.productDescription || '');
    }
  };

  const handleProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedProduct) {
      const updatedProduct = { ...selectedProduct, productName: event.target.value };
      setSelectedProduct(updatedProduct);
      setAllProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === updatedProduct.id ? updatedProduct : product
        )
      );
    }
  };

  const handleProductDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (selectedProduct) {
      const updatedProduct = { ...selectedProduct, productDescription: event.target.value };
      setSelectedProduct(updatedProduct);
      setAllProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === updatedProduct.id ? updatedProduct : product
        )
      );
      setDescription(event.target.value);
    }
  };  

  const handleSaveProductClick = async () => {
    if (selectedProduct) {
      const productToSave: Product = {
        id: selectedProduct.id,
        productId: selectedProduct.productId,
        productName: selectedProduct.productName,
        productDescription: selectedProduct.productDescription,
        image: selectedProduct.image,
        plans: selectedProduct.plans,
      };
      await saveProduct(productToSave);
      const updatedProducts = allProducts.map(product =>
        product.id === productToSave.id ? productToSave : product
      );
      setAllProducts(updatedProducts);
      setIsEditMode(false);
    }
  };

  const handleDeleteProductClick = async () => {
    if (selectedProduct) {
      try {
        await deleteProduct(selectedProduct.id);
        setAllProducts(allProducts.filter(product => product.id !== selectedProduct.id));
        setSelectedProduct(null);
        setFilterProductId(null);
      } catch (error) {
        console.error('Failed to delete the product', error);
      }
    }
  };

  // handle plan function -->>>
  const handlePlanSelection = (plan: Plan) => {
    setSelectedPlan(plan);
    setPlanTitle(plan.planName);
    setPlanDescription(plan.planDescription);
    setPlanFeatures(plan.planFeatures);
    setPlanFee(plan.planAmount);
    setPlanPeriod(plan.planPeriod);
    setIsTrialEnabled(plan.planTrialPeriod !== '0');
    setTrialPeriod(plan.planTrialPeriod);
    setTrialMembersType(plan.planTrialMemberType || []);
    setVoucherCode(plan.planVoucherCode);
    setDiscountType(plan.planDiscountType);
    setDiscountValue(plan.planDiscountValue);
  };

  const handlePlanTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedTitle = event.target.value;
    setPlanTitle(updatedTitle);

    if (selectedPlan) {
      const updatedPlan = { ...selectedPlan, planName: updatedTitle };
      setSelectedPlan(updatedPlan);

      if (selectedProduct) {
        const updatedProduct = {
          ...selectedProduct,
          plans: selectedProduct.plans.map(plan => plan.id === updatedPlan.id ? updatedPlan : plan)
        };
        setSelectedProduct(updatedProduct);

        setAllProducts(allProducts.map(product =>
          product.id === updatedProduct.id ? updatedProduct : product
        ));
      }
    }
  };

  const handlePlanDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlanDescription(event.target.value);
  };

  const handleFeatureChange = (index: number, value: string) => {
    const updatedFeatures = [...planFeatures];
    updatedFeatures[index] = value;
    setPlanFeatures(updatedFeatures);
  };

  const handleAddFeature = () => {
    setPlanFeatures([...planFeatures, newFeature]);
    setNewFeature('');
    setIsAddingFeature(false);
  };

  const handleAddNewPlan = async () => {
    if (!selectedProduct) return;

    const planID = selectedProduct.id.toString();
    const newPlan: Plan = {
      id: undefined,
      planID,
      planName: 'New Plan',
      planDescription: 'Description of the new plan.',
      planAmount: '0',
      planCurrency: 'USD',
      planPeriod: '30',
      planFeatures: ["Default feature 1", "Default feature 2", "Default feature 3"],
      planTrial: '0',
      planTrialPeriod: '0',
      planTrialMemberType: [],
      planVoucherCode: '',
      planDiscountType: 'percentage',
      planDiscountValue: '0',
      period: '30'
    };

    const savedPlan = await savePlan(newPlan);
    if (!savedPlan) return;

    const updatedProduct = {
      ...selectedProduct,
      plans: [...(selectedProduct.plans || []), savedPlan] 
    };

    setSelectedProduct(updatedProduct);
    setSelectedPlan(savedPlan);
    setPlanTitle(savedPlan.planName);
    setPlanDescription(savedPlan.planDescription);
    setPlanFeatures(savedPlan.planFeatures);
    setPlanFee(savedPlan.planAmount);
    setPlanPeriod(savedPlan.planPeriod);
    setIsPlanEditMode(true);
  };

  const handleSavePlan = async () => {
    if (selectedPlan && selectedProduct) {
      const updatedPlan: Plan = {
        ...selectedPlan,
        planName: planTitle,
        planDescription: planDescription,
        planAmount: planFee,
        planCurrency: planCurrency,
        planPeriod: planPeriod === 'monthly' ? '30' :
                     planPeriod === 'quarterly' ? '90' :
                     planPeriod === 'yearly' ? '365' :
                     customPlanDays,
        planTrialPeriod: isTrialEnabled ? (trialPeriod === 'monthly' ? '30' :
                          trialPeriod === 'quarterly' ? '90' :
                          trialPeriod === 'yearly' ? '365' : '0') : '0',
        planTrialMemberType: trialMembersType,
        planFeatures: planFeatures,
        planVoucherCode: voucherCode,
        planDiscountType: discountType,
        planDiscountValue: discountValue,
        period: planPeriod === 'monthly' ? '30' :
                 planPeriod === 'quarterly' ? '90' :
                 planPeriod === 'yearly' ? '365' :
                 customPlanDays,
        planTrial: "0"
      };

      try {
        const savedPlan = await savePlan(updatedPlan);

        const updatedPlans = selectedProduct.plans.map(plan =>
          plan.id === savedPlan.id ? savedPlan : plan
        );

        const updatedProduct = {
          ...selectedProduct,
          plans: updatedPlans
        };

        setSelectedProduct(updatedProduct);

        setAllProducts(prevProducts => prevProducts.map(product =>
          product.id === selectedProduct.id ? updatedProduct : product
        ));

        setSelectedPlan(savedPlan);
        setPlanTitle(savedPlan.planName);
        setPlanDescription(savedPlan.planDescription);
        setPlanFeatures(savedPlan.planFeatures);
        setPlanFee(savedPlan.planAmount);
        setPlanPeriod(savedPlan.planPeriod);
        setIsTrialEnabled(savedPlan.planTrialPeriod !== '0');
        setTrialPeriod(savedPlan.planTrialPeriod);
        setTrialMembersType(savedPlan.planTrialMemberType || []);
        setVoucherCode(savedPlan.planVoucherCode);
        setDiscountType(savedPlan.planDiscountType);
        setDiscountValue(savedPlan.planDiscountValue);

        setIsPlanEditMode(false);
      } catch (error) {
        console.error('Failed to save the plan:', error);
      }
    }
  };

  const handleDeletePlan = async () => {
    if (selectedPlan && selectedProduct) {
      try {
        await deletePlan(selectedPlan.id!);
        const updatedPlans = selectedProduct.plans.filter(plan => plan.id !== selectedPlan.id);
        const updatedProduct = { ...selectedProduct, plans: updatedPlans };
        setSelectedProduct(updatedProduct);
        setSelectedPlan(updatedPlans.length > 0 ? updatedPlans[0] : null);
        setPlanTitle(updatedPlans.length > 0 ? updatedPlans[0].planName : '');
        setPlanDescription(updatedPlans.length > 0 ? updatedPlans[0].planDescription : '');
        setPlanFeatures(updatedPlans.length > 0 ? updatedPlans[0].planFeatures : []);
        setPlanFee(updatedPlans.length > 0 ? updatedPlans[0].planAmount : '');
        setPlanPeriod(updatedPlans.length > 0 ? updatedPlans[0].planPeriod : '');
      } catch (error) {
        console.error('Failed to delete the plan', error);
      }
    }
  };

  return (
    <div className="bg-card p-6 rounded border space-y-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold">Product List Setting</h2>
        <div className="flex items-center">
          <button
            className="text-xs ml-2 px-4 py-2 rounded-full bg-cyan-900 text-white"
            onClick={handleCreateProduct}
          >
            + Create Product
          </button>
          <button
            className={`text-xs ml-2 px-4 py-2 rounded-full ${isEditMode ? 'bg-rose-500 text-slate-100' : 'bg-gray-200 text-gray-400'}`}
            onClick={handleDeleteProductClick}
            disabled={!isEditMode || !selectedProduct}
          >
            Delete Product
          </button>
          <label className="inline-flex items-center cursor-pointer ml-8">
            <span className="text-xl font-medium mr-2">
              {isEditMode ? (
                <>
                  <TbEdit className="text-cyan-800" />
                </>
              ) : (
                <>
                  <TbEditOff className="text-gray-300" />
                </>
              )}
            </span>
            <input
              type="checkbox"
              className="sr-only peer"
              checked={isEditMode}
              onChange={() => setIsEditMode(!isEditMode)}
            />
            <div className={`
              relative w-11 h-6 ${isEditMode ? 'bg-cyan-800' : 'bg-gray-200'} 
              rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
              peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white 
              after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 
            peer-checked:bg-cyan-800`}></div>
          </label>
        </div>
      </div>

      <div className="mb-4 flex items-center">
        <label className="block mb-2 w-1/3 font-bold">Select a Product:</label>
        <select
          value={filterProductId || ''}
          onChange={handleProductChange}
          className="p-2 border rounded w-2/3"
        >
          <option value="">No selected product</option>
          {products && products.map((product) => (
            <option key={product.id} value={product.id}>
              {product.productName}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4 flex items-center">
        <label className="block mb-2 w-1/3">Product Name:</label>
        <input
          type="text"
          className={`w-2/3 p-2 border rounded ${isEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
          value={selectedProduct?.productName || ''}
          onChange={handleProductNameChange}
          disabled={!isEditMode}
        />
      </div>

      <div className="mb-4 flex items-center">
        <label className="block mb-2 w-1/3">Product Image:</label>
        <input
          type="file"
          className={`w-2/3 p-2 border rounded ${isEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
          onChange={(e) => {
            if (e.target.files && e.target.files[0] && selectedProduct) {
              const file = e.target.files[0];
              const reader = new FileReader();
              reader.onloadend = () => {
                selectedProduct.image = reader.result as string;
                setSelectedProduct({ ...selectedProduct });
              };
              reader.readAsDataURL(file);
            }
          }}
          disabled={!isEditMode}
        />
      </div>

      <div className="mb-4 flex items-center">
        <label className="block mb-2 w-1/3">Description:</label>
        <textarea
          className={`w-2/3 p-2 border rounded ${isEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
          value={description}
          onChange={handleProductDescriptionChange}
          disabled={!isEditMode}
        />
      </div>

      <div className="flex justify-end">
        <button
          className={`mt-2 px-4 py-2 rounded-full ${isEditMode ? 'bg-cyan-900 text-white' : 'bg-gray-200 text-gray-400'}`}
          onClick={handleSaveProductClick}
          disabled={!isEditMode}
        >
          {isEditMode ? 'Modify Product' : 'Edit Product'}
        </button>
      </div>

      <div className="border border-b border-gray-400 border-opacity-15"></div>

      <div>
        <div className="flex flex-row justify-between items-center">
          <h2 className="text-xl font-semibold mb-4">Plan Setting</h2>
          <div className="flex justify-between items-center">
            <div>
              <button
                className="px-4 py-2 text-xs border-dashed rounded-full bg-cyan-800 text-white"
                onClick={handleAddNewPlan}
              >
                + Create Plan
              </button>
              <button
                className={`ml-2 px-4 py-2 text-xs rounded-full ${isPlanEditMode ? 'bg-rose-500 text-white' : 'bg-gray-200 text-gray-400'}`}
                onClick={isPlanEditMode ? handleDeletePlan : undefined}
                disabled={!isPlanEditMode}
              >
                Delete Plan
              </button>
            </div>
            <div className="flex items-center ml-8">
              <label className="inline-flex items-center cursor-pointer">
                <span className="text-xl font-medium mr-2">
                  {isPlanEditMode ? (
                    <>
                      <TbEdit className="text-cyan-800" />
                    </>
                  ) : (
                    <>
                      <TbEditOff className="text-gray-300" />
                    </>
                  )}
                </span>
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={isPlanEditMode}
                  onChange={() => setIsPlanEditMode(!isPlanEditMode)}
                />
                <div className={`
                  relative w-11 h-6 ${isPlanEditMode ? 'bg-cyan-800' : 'bg-gray-200'} 
                  rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
                  peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white 
                  after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 
                  peer-checked:bg-cyan-800`}></div>
              </label>
            </div>
          </div>
        </div>
        <div className="mb-4 flex items-center">
          <label className="block mb-2 w-1/3 font-bold">Select a Plan:</label>
          <div className="space-x-2 flex flex-row w-auto">
            {selectedProduct && selectedProduct.plans && selectedProduct.plans.length > 0 ? (
              selectedProduct.plans.map((plan, index) => (
                <div
                  key={index}
                  className={`p-2 border rounded cursor-pointer ${selectedPlan === plan ? 'border-cyan-800 border-2' : ''}`}
                  onClick={() => handlePlanSelection(plan)}
                >
                  {plan.planName}
                </div>
              ))
            ) : (
              <div className="p-2 border-dashed border-2 border-gray-200 text-black rounded">No plans selected</div>
            )}
          </div>
        </div>

        {selectedPlan && (
          <div className="space-y-4">
            <div className="flex items-center">
              <label className="block mb-2 w-1/3">Plan Title:</label>
              <input
                type="text"
                className={`w-2/3 p-2 border rounded ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
                value={planTitle}
                onChange={handlePlanTitleChange}
                disabled={!isPlanEditMode}
              />
            </div>

            <div className="flex items-center">
              <label className="block mb-2 w-1/3">Plan Description:</label>
              <input
                type="text"
                className={`w-2/3 p-2 border rounded ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
                value={planDescription}
                onChange={handlePlanDescriptionChange}
                disabled={!isPlanEditMode}
              />
            </div>

            <div className="flex items-center">
              <label className="block mb-2 w-1/3">Plan Amount:</label>
              <div className="w-2/3 flex items-center">
                <input
                  type="text"
                  className={`w-full p-2 border rounded ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
                  value={planFee}
                  onChange={(e) => setPlanFee(e.target.value)}
                  disabled={!isPlanEditMode}
                />
                <select
                  className={`p-2 border rounded mx-2 ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
                  value={planCurrency}
                  onChange={(e) => setPlanCurrency(e.target.value)}
                  disabled={!isPlanEditMode}
                >
                  <option value="USD">USD</option>
                  <option value="MYR">MYR</option>
                </select>
              </div>
            </div>

            <div className="flex items-center">
              <label className="block mb-2 w-1/3">Plan Period:</label>
              <div className="w-2/3 flex items-center">
                <select
                  className={`w-full p-2 border rounded ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
                  value={planPeriod}
                  onChange={(e) => {
                    setPlanPeriod(e.target.value);
                    setIsCustomPlanPeriod(e.target.value === 'Specify Period');
                  }}
                  disabled={!isPlanEditMode}
                >
                  <option value="monthly">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="yearly">Yearly</option>
                  <option value="Specify Period">Specify Period</option>
                </select>
                {isCustomPlanPeriod && (
                  <div className="ml-4 flex items-center">
                    <input
                      type="text"
                      className={`p-2 border rounded ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
                      value={customPlanDays}
                      onChange={(e) => setCustomPlanDays(e.target.value)}
                      disabled={!isPlanEditMode}
                      placeholder="Enter days"
                    />
                    <span className="ml-2">days</span>
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center">
              <label className="block mb-2 w-1/3">Plan Features:</label>
              <div className="w-2/3 space-y-2">
                {planFeatures && planFeatures.map((feature, index) => (
                  <div key={index} className="flex items-center">
                    <input
                      type="text"
                      className={`w-full p-2 border rounded ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
                      value={feature}
                      onChange={(e) => handleFeatureChange(index, e.target.value)}
                      disabled={!isPlanEditMode}
                    />
                    {isPlanEditMode && (
                      <button
                        className="ml-2 text-rose-400"
                        onClick={() => {
                          const updatedFeatures = planFeatures.filter((_, i) => i !== index);
                          setPlanFeatures(updatedFeatures);
                        }}
                      >
                        <IoIosRemoveCircle />
                      </button>
                    )}
                  </div>
                ))}
                {isAddingFeature ? (
                  <div className="flex items-center">
                    <input
                      type="text"
                      className="w-full p-2 border rounded mr-2"
                      value={newFeature}
                      onChange={(e) => setNewFeature(e.target.value)}
                    />
                    <button
                      className="px-4 py-2 text-cyan-800 hover:text-gray-300"
                      onClick={handleAddFeature}
                    >
                      <IoMdAddCircleOutline className="w-6 h-6"/>
                    </button>
                    <button
                      className="ml-2 text-cyan-800 hover:text-gray-300"
                      onClick={() => setIsAddingFeature(false)}
                    >
                      <IoReturnUpBackOutline className="w-6 h-6"/>
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-end">
                    <button
                      className={`text-sm py-2 ${isPlanEditMode ? 'text-cyan-800' : 'text-gray-400 cursor-not-allowed'}`}
                      onClick={() => isPlanEditMode && setIsAddingFeature(true)}
                      disabled={!isPlanEditMode}
                    >
                      + Add Feature
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-end">
              <button
                className={`mt-2 px-4 py-2 rounded-full ${isPlanEditMode ? 'bg-cyan-900 text-white' : 'bg-gray-200 text-gray-400'}`}
                onClick={isPlanEditMode ? handleSavePlan : undefined}
                disabled={!isPlanEditMode}
              >
                {isPlanEditMode ? 'Save Plan' : 'Edit Plan'}
              </button>
            </div>

            <div className="border-b border-gray-300"></div>

            <TrialSetting 
              selectedPlan={selectedPlan} 
              setSelectedPlan={setSelectedPlan} 
              isPlanEditMode={isPlanEditMode} 
            />

            <VoucherSetting 
              selectedPlan={selectedPlan} 
              setSelectedPlan={setSelectedPlan} 
              isPlanEditMode={isPlanEditMode} 
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionSettings;

// // udpate code --> 23 july 2024
// // src/components/admin/SubscriptionSetting/SubSetting.tsx

// import React, { useState, useEffect } from 'react';
// import useSubSetting from '../../../hooks/AdminDashboard/useSubSetting';
// import { TbEdit, TbEditOff } from "react-icons/tb";
// import { IoIosRemoveCircle, IoMdAddCircleOutline } from "react-icons/io";
// import { IoReturnUpBackOutline } from "react-icons/io5";
// import TrialSetting from '../TrialSetting/TrialSetting';
// import VoucherSetting from '../VoucherSetting/VoucherSetting';

// interface Plan {
//   id?: number;
//   planID?: string;
//   planName: string;
//   planDescription: string;
//   planFeatures: string[];
//   planAmount: string;
//   planCurrency: string;
//   planPeriod: string;
//   planTrial: "0";
//   planTrialPeriod: string;
//   planTrialMemberType: string[];
//   planVoucherCode: string;
//   planDiscountType: string;
//   planDiscountValue: string;
//   period: string;
// }

// interface Product {
//   id: number;
//   productId: string;
//   productName: string;
//   productDescription: string;
//   image?: string;
//   plans: Plan[];
// }

// const SubscriptionSettings: React.FC = () => {
//   const { products = [], savePlan, handleSaveProduct: saveProduct, deleteProduct, createProduct, deletePlan } = useSubSetting();

//   // Product Setup
//   const [filterProductId, setFilterProductId] = useState<number | null>(products.length > 0 ? products[0]?.id : null);
//   const [selectedProduct, setSelectedProduct] = useState<Product | null>(products.length > 0 ? products[0] : null);
//   const [isEditMode, setIsEditMode] = useState(false);
//   const [description, setDescription] = useState(selectedProduct?.productDescription || '');

//   // Plan Setup
//   const [selectedPlan, setSelectedPlan] = useState<Plan | null>(
//     selectedProduct && selectedProduct.plans && selectedProduct.plans.length > 0
//       ? selectedProduct.plans[0]
//       : null
//   );
//   const [isPlanEditMode, setIsPlanEditMode] = useState(false);
//   const [planTitle, setPlanTitle] = useState(selectedPlan?.planName || '');
//   const [planDescription, setPlanDescription] = useState(selectedPlan?.planDescription || '');
//   const [planFeatures, setPlanFeatures] = useState<string[]>(selectedPlan?.planFeatures || []);
//   const [planFee, setPlanFee] = useState(selectedPlan?.planAmount || '');
//   const [planPeriod, setPlanPeriod] = useState(selectedPlan?.planPeriod || '');
//   const [isAddingFeature, setIsAddingFeature] = useState(false);
//   const [newFeature, setNewFeature] = useState('');

//   // Trial Setup
//   const [isTrialEnabled, setIsTrialEnabled] = useState(false);
//   const [trialPeriod, setTrialPeriod] = useState('');
//   const [trialMembersType, setTrialMembersType] = useState<string[]>([]);
//   const [planCurrency, setPlanCurrency] = useState('USD');
//   const [isCustomPlanPeriod, setIsCustomPlanPeriod] = useState(false);
//   const [customPlanDays, setCustomPlanDays] = useState('');

//   // Voucher Setup
//   const [voucherCode, setVoucherCode] = useState('');
//   const [discountType, setDiscountType] = useState('percentage');
//   const [discountValue, setDiscountValue] = useState('');

//   const [allProducts, setAllProducts] = useState<Product[]>([]);

//   useEffect(() => {
//     setAllProducts([...products]);
//   }, [products]);

//   useEffect(() => {
//     if (selectedProduct && selectedProduct.plans) {
//       setSelectedPlan(selectedProduct.plans.find(plan => plan.id === selectedPlan?.id) || selectedProduct.plans[0]);
//       setDescription(selectedProduct.productDescription);
//     } else {
//       setSelectedPlan(null);
//     }
//   }, [selectedProduct, selectedPlan?.id, products]);

//   useEffect(() => {
//     if (selectedPlan) {
//       setPlanTitle(selectedPlan.planName);
//       setPlanDescription(selectedPlan.planDescription);
//       setPlanFeatures(selectedPlan.planFeatures);
//       setPlanFee(selectedPlan.planAmount);
//       setPlanPeriod(selectedPlan.planPeriod);
//       setIsTrialEnabled(selectedPlan.planTrialPeriod !== '0');
//       setTrialPeriod(selectedPlan.planTrialPeriod);
//       setTrialMembersType(selectedPlan.planTrialMemberType || []);
//       setVoucherCode(selectedPlan.planVoucherCode);
//       setDiscountType(selectedPlan.planDiscountType);
//       setDiscountValue(selectedPlan.planDiscountValue);
//     } else {
//       setPlanTitle('');
//       setPlanDescription('');
//       setPlanFeatures([]);
//       setPlanFee('');
//       setPlanPeriod('');
//       setIsTrialEnabled(false);
//       setTrialPeriod('');
//       setTrialMembersType([]);
//       setVoucherCode('');
//       setDiscountType('percentage');
//       setDiscountValue('');
//     }
//   }, [selectedPlan, selectedPlan?.id, products]);

//   // handle product function -->>>
//   const handleCreateProduct = async () => {
//     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
//     const randomChar = characters.charAt(Math.floor(Math.random() * characters.length));
//     const randomNum = Math.floor(1000 + Math.random() * 9000);
//     const productId = `${randomChar}-${randomNum}`;
  
//     const newProduct: Product = {
//       id: Date.now(),
//       productId,
//       productName: 'New Product',
//       productDescription: 'Description of the new product.',
//       plans: [],
//     };
  
//     const savedProduct = await createProduct(newProduct);
  
//     setSelectedProduct(savedProduct);
//     setFilterProductId(savedProduct.id);
//     setDescription(savedProduct.productDescription);
//     setIsEditMode(true);
//   };

//   const handleProductChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const productId = event.target.value === "create-new" ? null : parseInt(event.target.value);
//     setFilterProductId(productId);

//     if (productId === null) {
//       const newProduct: Product = {
//         id: Date.now(),
//         productId: 'New Product ID',
//         productName: '',
//         productDescription: '',
//         plans: [],
//       };
//       setSelectedProduct(newProduct);
//       setDescription(newProduct.productDescription);
//     } else {
//       const selected = products.find((product) => product.id === productId);
//       setSelectedProduct(selected || null);
//       setDescription(selected?.productDescription || '');
//     }
//   };

//   const handleProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     if (selectedProduct) {
//       const updatedProduct = { ...selectedProduct, productName: event.target.value };
//       setSelectedProduct(updatedProduct);
//       setAllProducts((prevProducts) =>
//         prevProducts.map((product) =>
//           product.id === updatedProduct.id ? updatedProduct : product
//         )
//       );
//     }
//   };

//   const handleProductDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
//     if (selectedProduct) {
//       const updatedProduct = { ...selectedProduct, productDescription: event.target.value };
//       setSelectedProduct(updatedProduct);
//       setAllProducts((prevProducts) =>
//         prevProducts.map((product) =>
//           product.id === updatedProduct.id ? updatedProduct : product
//         )
//       );
//       setDescription(event.target.value);
//     }
//   };  

//   const handleSaveProductClick = async () => {
//     if (selectedProduct) {
//       const productToSave: Product = {
//         id: selectedProduct.id,
//         productId: selectedProduct.productId,
//         productName: selectedProduct.productName,
//         productDescription: selectedProduct.productDescription,
//         image: selectedProduct.image,
//         plans: selectedProduct.plans,
//       };
//       await saveProduct(productToSave);
//       const updatedProducts = allProducts.map(product =>
//         product.id === productToSave.id ? productToSave : product
//       );
//       setAllProducts(updatedProducts);
//       setIsEditMode(false);
//     }
//   };

//   const handleDeleteProductClick = async () => {
//     if (selectedProduct) {
//       try {
//         await deleteProduct(selectedProduct.id);
//         setAllProducts(allProducts.filter(product => product.id !== selectedProduct.id));
//         setSelectedProduct(null);
//         setFilterProductId(null);
//       } catch (error) {
//         console.error('Failed to delete the product', error);
//       }
//     }
//   };

//   // handle plan function -->>>
//   const handlePlanSelection = (plan: Plan) => {
//     setSelectedPlan(plan);
//     setPlanTitle(plan.planName);
//     setPlanDescription(plan.planDescription);
//     setPlanFeatures(plan.planFeatures);
//     setPlanFee(plan.planAmount);
//     setPlanPeriod(plan.planPeriod);
//     setIsTrialEnabled(plan.planTrialPeriod !== '0');
//     setTrialPeriod(plan.planTrialPeriod);
//     setTrialMembersType(plan.planTrialMemberType || []);
//     setVoucherCode(plan.planVoucherCode);
//     setDiscountType(plan.planDiscountType);
//     setDiscountValue(plan.planDiscountValue);
//   };

//   const handlePlanTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const updatedTitle = event.target.value;
//     setPlanTitle(updatedTitle);

//     if (selectedPlan) {
//       const updatedPlan = { ...selectedPlan, planName: updatedTitle };
//       setSelectedPlan(updatedPlan);

//       if (selectedProduct) {
//         const updatedProduct = {
//           ...selectedProduct,
//           plans: selectedProduct.plans.map(plan => plan.id === updatedPlan.id ? updatedPlan : plan)
//         };
//         setSelectedProduct(updatedProduct);

//         setAllProducts(allProducts.map(product =>
//           product.id === updatedProduct.id ? updatedProduct : product
//         ));
//       }
//     }
//   };

//   const handlePlanDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setPlanDescription(event.target.value);
//   };

//   const handleFeatureChange = (index: number, value: string) => {
//     const updatedFeatures = [...planFeatures];
//     updatedFeatures[index] = value;
//     setPlanFeatures(updatedFeatures);
//   };

//   const handleAddFeature = () => {
//     setPlanFeatures([...planFeatures, newFeature]);
//     setNewFeature('');
//     setIsAddingFeature(false);
//   };

//   const handleAddNewPlan = async () => {
//     if (!selectedProduct) return;

//     const planID = selectedProduct.id.toString();
//     const newPlan: Plan = {
//       id: undefined,
//       planID,
//       planName: 'New Plan',
//       planDescription: 'Description of the new plan.',
//       planAmount: '0',
//       planCurrency: 'USD',
//       planPeriod: '30',
//       planFeatures: ["Default feature 1", "Default feature 2", "Default feature 3"],
//       planTrial: '0',
//       planTrialPeriod: '0',
//       planTrialMemberType: [],
//       planVoucherCode: '',
//       planDiscountType: 'percentage',
//       planDiscountValue: '0',
//       period: '30'
//     };

//     const savedPlan = await savePlan(newPlan);
//     if (!savedPlan) return;

//     const updatedProduct = {
//       ...selectedProduct,
//       plans: [...(selectedProduct.plans || []), savedPlan] 
//     };

//     setSelectedProduct(updatedProduct);
//     setSelectedPlan(savedPlan);
//     setPlanTitle(savedPlan.planName);
//     setPlanDescription(savedPlan.planDescription);
//     setPlanFeatures(savedPlan.planFeatures);
//     setPlanFee(savedPlan.planAmount);
//     setPlanPeriod(savedPlan.planPeriod);
//     setIsPlanEditMode(true);
//   };

//   const handleSavePlan = async () => {
//     if (selectedPlan && selectedProduct) {
//       const updatedPlan: Plan = {
//         ...selectedPlan,
//         planName: planTitle,
//         planDescription: planDescription,
//         planAmount: planFee,
//         planCurrency: planCurrency,
//         planPeriod: planPeriod === 'monthly' ? '30' :
//                      planPeriod === 'quarterly' ? '90' :
//                      planPeriod === 'yearly' ? '365' :
//                      customPlanDays,
//         planTrialPeriod: isTrialEnabled ? (trialPeriod === 'monthly' ? '30' :
//                           trialPeriod === 'quarterly' ? '90' :
//                           trialPeriod === 'yearly' ? '365' : '0') : '0',
//         planTrialMemberType: trialMembersType,
//         planFeatures: planFeatures,
//         planVoucherCode: voucherCode,
//         planDiscountType: discountType,
//         planDiscountValue: discountValue,
//         period: planPeriod === 'monthly' ? '30' :
//                  planPeriod === 'quarterly' ? '90' :
//                  planPeriod === 'yearly' ? '365' :
//                  customPlanDays,
//         planTrial: "0"
//       };

//       try {
//         const savedPlan = await savePlan(updatedPlan);

//         const updatedPlans = selectedProduct.plans.map(plan =>
//           plan.id === savedPlan.id ? savedPlan : plan
//         );

//         const updatedProduct = {
//           ...selectedProduct,
//           plans: updatedPlans
//         };

//         setSelectedProduct(updatedProduct);

//         setAllProducts(prevProducts => prevProducts.map(product =>
//           product.id === selectedProduct.id ? updatedProduct : product
//         ));

//         setSelectedPlan(savedPlan);
//         setPlanTitle(savedPlan.planName);
//         setPlanDescription(savedPlan.planDescription);
//         setPlanFeatures(savedPlan.planFeatures);
//         setPlanFee(savedPlan.planAmount);
//         setPlanPeriod(savedPlan.planPeriod);
//         setIsTrialEnabled(savedPlan.planTrialPeriod !== '0');
//         setTrialPeriod(savedPlan.planTrialPeriod);
//         setTrialMembersType(savedPlan.planTrialMemberType || []);
//         setVoucherCode(savedPlan.planVoucherCode);
//         setDiscountType(savedPlan.planDiscountType);
//         setDiscountValue(savedPlan.planDiscountValue);

//         setIsPlanEditMode(false);
//       } catch (error) {
//         console.error('Failed to save the plan:', error);
//       }
//     }
//   };

//   const handleDeletePlan = async () => {
//     if (selectedPlan && selectedProduct) {
//       try {
//         await deletePlan(selectedPlan.id!);
//         const updatedPlans = selectedProduct.plans.filter(plan => plan.id !== selectedPlan.id);
//         const updatedProduct = { ...selectedProduct, plans: updatedPlans };
//         setSelectedProduct(updatedProduct);
//         setSelectedPlan(updatedPlans.length > 0 ? updatedPlans[0] : null);
//         setPlanTitle(updatedPlans.length > 0 ? updatedPlans[0].planName : '');
//         setPlanDescription(updatedPlans.length > 0 ? updatedPlans[0].planDescription : '');
//         setPlanFeatures(updatedPlans.length > 0 ? updatedPlans[0].planFeatures : []);
//         setPlanFee(updatedPlans.length > 0 ? updatedPlans[0].planAmount : '');
//         setPlanPeriod(updatedPlans.length > 0 ? updatedPlans[0].planPeriod : '');
//       } catch (error) {
//         console.error('Failed to delete the plan', error);
//       }
//     }
//   };

//   return (
//     <div className="bg-card p-6 rounded shadow space-y-6">
//       <div className="flex items-center justify-between mb-4">
//         <h2 className="text-xl font-semibold">Product List Setting</h2>
//         <div className="flex items-center">
//           <button
//             className="text-xs ml-2 px-4 py-2 rounded-full bg-cyan-900 text-white"
//             onClick={handleCreateProduct}
//           >
//             + Create Product
//           </button>
//           <button
//             className={`text-xs ml-2 px-4 py-2 rounded-full ${isEditMode ? 'bg-rose-500 text-slate-100' : 'bg-gray-200 text-gray-400'}`}
//             onClick={handleDeleteProductClick}
//             disabled={!isEditMode || !selectedProduct}
//           >
//             Delete Product
//           </button>
//           <label className="inline-flex items-center cursor-pointer ml-8">
//             <span className="text-xl font-medium mr-2">
//               {isEditMode ? (
//                 <>
//                   <TbEdit className="text-cyan-800" />
//                 </>
//               ) : (
//                 <>
//                   <TbEditOff className="text-gray-300" />
//                 </>
//               )}
//             </span>
//             <input
//               type="checkbox"
//               className="sr-only peer"
//               checked={isEditMode}
//               onChange={() => setIsEditMode(!isEditMode)}
//             />
//             <div className={`
//               relative w-11 h-6 ${isEditMode ? 'bg-cyan-800' : 'bg-gray-200'} 
//               rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
//               peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white 
//               after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 
//             peer-checked:bg-cyan-800`}></div>
//           </label>
//         </div>
//       </div>

//       <div className="mb-4 flex items-center">
//         <label className="block mb-2 w-1/3 font-bold">Select a Product:</label>
//         <select
//           value={filterProductId || ''}
//           onChange={handleProductChange}
//           className="p-2 border rounded w-2/3"
//         >
//           <option value="">No selected product</option>
//           {products && products.map((product) => (
//             <option key={product.id} value={product.id}>
//               {product.productName}
//             </option>
//           ))}
//         </select>
//       </div>

//       <div className="mb-4 flex items-center">
//         <label className="block mb-2 w-1/3">Product Name:</label>
//         <input
//           type="text"
//           className={`w-2/3 p-2 border rounded ${isEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
//           value={selectedProduct?.productName || ''}
//           onChange={handleProductNameChange}
//           disabled={!isEditMode}
//         />
//       </div>

//       <div className="mb-4 flex items-center">
//         <label className="block mb-2 w-1/3">Product Image:</label>
//         <input
//           type="file"
//           className={`w-2/3 p-2 border rounded ${isEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
//           onChange={(e) => {
//             if (e.target.files && e.target.files[0] && selectedProduct) {
//               const file = e.target.files[0];
//               const reader = new FileReader();
//               reader.onloadend = () => {
//                 selectedProduct.image = reader.result as string;
//                 setSelectedProduct({ ...selectedProduct });
//               };
//               reader.readAsDataURL(file);
//             }
//           }}
//           disabled={!isEditMode}
//         />
//       </div>

//       <div className="mb-4 flex items-center">
//         <label className="block mb-2 w-1/3">Description:</label>
//         <textarea
//           className={`w-2/3 p-2 border rounded ${isEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
//           value={description}
//           onChange={handleProductDescriptionChange}
//           disabled={!isEditMode}
//         />
//       </div>

//       <div className="flex justify-end">
//         <button
//           className={`mt-2 px-4 py-2 rounded-full ${isEditMode ? 'bg-cyan-900 text-white' : 'bg-gray-200 text-gray-400'}`}
//           onClick={handleSaveProductClick}
//           disabled={!isEditMode}
//         >
//           {isEditMode ? 'Modify Product' : 'Edit Product'}
//         </button>
//       </div>

//       <div className="border border-b border-gray-400 border-opacity-15"></div>

//       <div>
//         <div className="flex flex-row justify-between items-center">
//           <h2 className="text-xl font-semibold mb-4">Plan Setting</h2>
//           <div className="flex justify-between items-center">
//             <div>
//               <button
//                 className="px-4 py-2 text-xs border-dashed rounded-full bg-cyan-800 text-white"
//                 onClick={handleAddNewPlan}
//               >
//                 + Create Plan
//               </button>
//               <button
//                 className={`ml-2 px-4 py-2 text-xs rounded-full ${isPlanEditMode ? 'bg-rose-500 text-white' : 'bg-gray-200 text-gray-400'}`}
//                 onClick={isPlanEditMode ? handleDeletePlan : undefined}
//                 disabled={!isPlanEditMode}
//               >
//                 Delete Plan
//               </button>
//             </div>
//             <div className="flex items-center ml-8">
//               <label className="inline-flex items-center cursor-pointer">
//                 <span className="text-xl font-medium mr-2">
//                   {isPlanEditMode ? (
//                     <>
//                       <TbEdit className="text-cyan-800" />
//                     </>
//                   ) : (
//                     <>
//                       <TbEditOff className="text-gray-300" />
//                     </>
//                   )}
//                 </span>
//                 <input
//                   type="checkbox"
//                   className="sr-only peer"
//                   checked={isPlanEditMode}
//                   onChange={() => setIsPlanEditMode(!isPlanEditMode)}
//                 />
//                 <div className={`
//                   relative w-11 h-6 ${isPlanEditMode ? 'bg-cyan-800' : 'bg-gray-200'} 
//                   rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
//                   peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white 
//                   after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 
//                   peer-checked:bg-cyan-800`}></div>
//               </label>
//             </div>
//           </div>
//         </div>
//         <div className="mb-4 flex items-center">
//           <label className="block mb-2 w-1/3 font-bold">Select a Plan:</label>
//           <div className="space-x-2 flex flex-row w-auto">
//             {selectedProduct && selectedProduct.plans && selectedProduct.plans.length > 0 ? (
//               selectedProduct.plans.map((plan, index) => (
//                 <div
//                   key={index}
//                   className={`p-2 border rounded cursor-pointer ${selectedPlan === plan ? 'border-cyan-800 border-2' : ''}`}
//                   onClick={() => handlePlanSelection(plan)}
//                 >
//                   {plan.planName}
//                 </div>
//               ))
//             ) : (
//               <div className="p-2 border-dashed border-2 border-gray-200 text-black rounded">No plans selected</div>
//             )}
//           </div>
//         </div>

//         {selectedPlan && (
//           <div className="space-y-4">
//             <div className="flex items-center">
//               <label className="block mb-2 w-1/3">Plan Title:</label>
//               <input
//                 type="text"
//                 className={`w-2/3 p-2 border rounded ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
//                 value={planTitle}
//                 onChange={handlePlanTitleChange}
//                 disabled={!isPlanEditMode}
//               />
//             </div>

//             <div className="flex items-center">
//               <label className="block mb-2 w-1/3">Plan Description:</label>
//               <input
//                 type="text"
//                 className={`w-2/3 p-2 border rounded ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
//                 value={planDescription}
//                 onChange={handlePlanDescriptionChange}
//                 disabled={!isPlanEditMode}
//               />
//             </div>

//             <div className="flex items-center">
//               <label className="block mb-2 w-1/3">Plan Amount:</label>
//               <div className="w-2/3 flex items-center">
//                 <input
//                   type="text"
//                   className={`w-full p-2 border rounded ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
//                   value={planFee}
//                   onChange={(e) => setPlanFee(e.target.value)}
//                   disabled={!isPlanEditMode}
//                 />
//                 <select
//                   className={`p-2 border rounded ml-2 ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
//                   value={planCurrency}
//                   onChange={(e) => setPlanCurrency(e.target.value)}
//                   disabled={!isPlanEditMode}
//                 >
//                   <option value="USD">USD</option>
//                   <option value="MYR">MYR</option>
//                 </select>
//               </div>
//             </div>

//             <div className="flex items-center">
//               <label className="block mb-2 w-1/3">Plan Period:</label>
//               <div className="w-2/3 flex items-center">
//                 <select
//                   className={`w-full p-2 border rounded ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
//                   value={planPeriod}
//                   onChange={(e) => {
//                     setPlanPeriod(e.target.value);
//                     setIsCustomPlanPeriod(e.target.value === 'Specify Period');
//                   }}
//                   disabled={!isPlanEditMode}
//                 >
//                   <option value="monthly">Monthly</option>
//                   <option value="quarterly">Quarterly</option>
//                   <option value="yearly">Yearly</option>
//                   <option value="Specify Period">Specify Period</option>
//                 </select>
//                 {isCustomPlanPeriod && (
//                   <div className="ml-4 flex items-center">
//                     <input
//                       type="text"
//                       className={`p-2 border rounded ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
//                       value={customPlanDays}
//                       onChange={(e) => setCustomPlanDays(e.target.value)}
//                       disabled={!isPlanEditMode}
//                       placeholder="Enter days"
//                     />
//                     <span className="ml-2">days</span>
//                   </div>
//                 )}
//               </div>
//             </div>

//             <div className="flex items-center">
//               <label className="block mb-2 w-1/3">Plan Features:</label>
//               <div className="w-2/3 space-y-2">
//                 {planFeatures && planFeatures.map((feature, index) => (
//                   <div key={index} className="flex items-center">
//                     <input
//                       type="text"
//                       className={`w-full p-2 border rounded ${isPlanEditMode ? 'bg-white' : 'bg-gray-100 text-gray-300'}`}
//                       value={feature}
//                       onChange={(e) => handleFeatureChange(index, e.target.value)}
//                       disabled={!isPlanEditMode}
//                     />
//                     {isPlanEditMode && (
//                       <button
//                         className="ml-2 text-rose-400"
//                         onClick={() => {
//                           const updatedFeatures = planFeatures.filter((_, i) => i !== index);
//                           setPlanFeatures(updatedFeatures);
//                         }}
//                       >
//                         <IoIosRemoveCircle />
//                       </button>
//                     )}
//                   </div>
//                 ))}
//                 {isAddingFeature ? (
//                   <div className="flex items-center">
//                     <input
//                       type="text"
//                       className="w-full p-2 border rounded mr-2"
//                       value={newFeature}
//                       onChange={(e) => setNewFeature(e.target.value)}
//                     />
//                     <button
//                       className="px-4 py-2 text-cyan-800 hover:text-gray-300"
//                       onClick={handleAddFeature}
//                     >
//                       <IoMdAddCircleOutline className="w-6 h-6"/>
//                     </button>
//                     <button
//                       className="ml-2 text-cyan-800 hover:text-gray-300"
//                       onClick={() => setIsAddingFeature(false)}
//                     >
//                       <IoReturnUpBackOutline className="w-6 h-6"/>
//                     </button>
//                   </div>
//                 ) : (
//                   <div className="flex justify-end">
//                     <button
//                       className={`text-sm py-2 ${isPlanEditMode ? 'text-cyan-800' : 'text-gray-400 cursor-not-allowed'}`}
//                       onClick={() => isPlanEditMode && setIsAddingFeature(true)}
//                       disabled={!isPlanEditMode}
//                     >
//                       + Add Feature
//                     </button>
//                   </div>
//                 )}
//               </div>
//             </div>

//             <div className="flex justify-end">
//               <button
//                 className={`mt-2 px-4 py-2 rounded-full ${isPlanEditMode ? 'bg-cyan-900 text-white' : 'bg-gray-200 text-gray-400'}`}
//                 onClick={isPlanEditMode ? handleSavePlan : undefined}
//                 disabled={!isPlanEditMode}
//               >
//                 {isPlanEditMode ? 'Save Plan' : 'Edit Plan'}
//               </button>
//             </div>

//             <div className="border-b border-gray-300"></div>

//             <TrialSetting 
//               selectedPlan={selectedPlan} 
//               setSelectedPlan={setSelectedPlan} 
//               isPlanEditMode={isPlanEditMode} 
//             />

//             <VoucherSetting 
//               selectedPlan={selectedPlan} 
//               setSelectedPlan={setSelectedPlan} 
//               isPlanEditMode={isPlanEditMode} 
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default SubscriptionSettings;