// src/hooks/Dashboard/useUserDashboard.tsx

import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useEnv } from '../../context/EnvContext';

interface UserDashboard {
  username: string;
  email: string;
  gender: string;
  profile_image: string;
  points: number;
  stages: string;
  badges: string;
  program_stats: string;
  phone: string;
  address: string;
  state: string;
  telegram_id: string;
  discord_id: string;
}

const useUserDashboard = () => {
  const { isAuthenticated, triggerSessionExpired, logout  } = useAuth();
  const { apiUrl } = useEnv();
  const [user, setUser] = useState<UserDashboard | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [showExpireAlert, setShowExpireAlert] = useState(false);
  const [showStateModal, setShowStateModal] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem('token');
      if (!token) {
        setError(new Error('No token found'));
        return;
      }

      fetch(`${apiUrl}/api/user-dashboard`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          if (!response.ok) {
            if (response.status === 401) {
              setShowExpireAlert(true);
              triggerSessionExpired();
              throw new Error('Session expired');
            }
            const errorText = await response.text();
            throw new Error(`Error fetching data: ${response.status} ${response.statusText} - ${errorText}`);
          }
          return response.json();
        })
        .then((data: UserDashboard) => {
          setUser(data);

        // Check if the state is empty and show the modal
        if (!data.state) {
          setShowStateModal(true);
          }
        })
        
        .catch((error: Error) => {
          setError(error);
          console.error('Error fetching user data:', error);
        });
    }
  }, [isAuthenticated, apiUrl, triggerSessionExpired]);


  // udpate users data
  const updateUser = async (formData: FormData) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError(new Error('No token found'));
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/user-dashboard`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error updating profile: ${response.status} ${response.statusText} - ${errorText}`);
      }

      const data: UserDashboard = await response.json();
      setUser(data);
    } catch (error: any) {
      setError(error);
      console.error('Error updating profile:', error);
    }
  };

  const updateUserState = async (state: string) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError(new Error('No token found'));
      return;
    }
  
    try {
      const response = await fetch(`${apiUrl}/api/user-dashboard/update-state`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ state }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error updating state: ${response.status} ${response.statusText} - ${errorText}`);
      }
  
      // Fetch the updated data from the server to ensure consistency
      const updatedUser: UserDashboard = await response.json();
      setUser((prev) => (prev ? { ...prev, state: updatedUser.state } : null));
      setShowStateModal(false);
    } catch (error: any) {
      setError(error);
      console.error('Error updating state:', error);
    }
  };  

  const handleExpireAlertConfirm = () => {
    setShowExpireAlert(false);
    logout();
  };

  return { user, error, updateUser, updateUserState, showStateModal, logout, showExpireAlert, handleExpireAlertConfirm };
};

export default useUserDashboard;

// // udpate code --> 15 Sept 2024
// // src/hooks/Dashboard/useUserDashboard.tsx

// import { useState, useEffect } from 'react';
// import { useAuth } from '../../context/AuthContext';
// import { useEnv } from '../../context/EnvContext';

// interface UserDashboard {
//   username: string;
//   email: string;
//   gender: string;
//   profile_image: string;
//   points: number;
//   stages: string;
//   badges: string;
//   program_stats: string;
//   phone: string;
//   address: string;
//   state: string;
//   telegram_id: string;
//   discord_id: string;
// }

// const useUserDashboard = () => {
//   const { isAuthenticated, triggerSessionExpired, logout  } = useAuth();
//   const { apiUrl } = useEnv();
//   const [user, setUser] = useState<UserDashboard | null>(null);
//   const [error, setError] = useState<Error | null>(null);
//   const [showExpireAlert, setShowExpireAlert] = useState(false);

//   useEffect(() => {
//     if (isAuthenticated) {
//       const token = localStorage.getItem('token');
//       if (!token) {
//         setError(new Error('No token found'));
//         return;
//       }

//       fetch(`${apiUrl}/api/user-dashboard`, {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//       })
//         .then(async (response) => {
//           if (!response.ok) {
//             if (response.status === 401) {
//               setShowExpireAlert(true);
//               triggerSessionExpired();
//               throw new Error('Session expired');
//             }
//             const errorText = await response.text();
//             throw new Error(`Error fetching data: ${response.status} ${response.statusText} - ${errorText}`);
//           }
//           return response.json();
//         })
//         .then((data: UserDashboard) => {
//           setUser(data);
//         })
//         .catch((error: Error) => {
//           setError(error);
//           console.error('Error fetching user data:', error);
//         });
//     }
//   }, [isAuthenticated, apiUrl, triggerSessionExpired]);

//   const updateUser = async (formData: FormData) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       setError(new Error('No token found'));
//       return;
//     }

//     try {
//       const response = await fetch(`${apiUrl}/api/user-dashboard`, {
//         method: 'PUT',
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         body: formData,
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`Error updating profile: ${response.status} ${response.statusText} - ${errorText}`);
//       }

//       const data: UserDashboard = await response.json();
//       setUser(data);
//     } catch (error: any) {
//       setError(error);
//       console.error('Error updating profile:', error);
//     }
//   };

//   const handleExpireAlertConfirm = () => {
//     setShowExpireAlert(false);
//     logout();
//   };

//   return { user, error, updateUser, logout, showExpireAlert, handleExpireAlertConfirm };
// };

// export default useUserDashboard;