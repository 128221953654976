// src/pages/p02_Login.tsx

// import component
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

import { useTheme } from '../context/ThemeContext';
import AppFooter from '../components/Footer';
import ResetPassword from '../components/login/ResetPassword';
import SetupPassword from '../components/login/SignUp';
import SuccessModal from '../components/login/SuccessModal';
import ErrorModal from '../components/login/ErrorModal';
import { useAuth } from '../context/AuthContext';
import CookieNotification from '../components/CookieNotification';

import r1logoOri from '../assets/login/logo_r1_ori_v02.png';
import r1bg from '../assets/login/bg_r1.png';

import { motion } from 'framer-motion'; 
import Cookies from 'js-cookie';

const LoginView: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState<boolean>(false);
  const [isSetupPasswordVisible, setIsSetupPasswordVisible] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);

  const navigate = useNavigate();
  const { theme } = useTheme();
  const { login } = useAuth();

  useEffect(() => {
    const storedEmail = Cookies.get('email');
    const storedPassword = Cookies.get('password');
    const storedRememberMe = Cookies.get('rememberMe') === 'true';

    if (storedRememberMe && storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await login(email, password);
  
      if (response && response.token) {
        if (rememberMe) {
          Cookies.set('email', email, { expires: 7 });
          Cookies.set('password', password, { expires: 7 });
          Cookies.set('rememberMe', 'true', { expires: 7 });
          Cookies.set('token', response.token, { expires: 7 });
        } else {
          Cookies.remove('email');
          Cookies.remove('password');
          Cookies.set('rememberMe', 'false');
        }
        setIsSuccessModalVisible(true);
  
        // Set a timeout to redirect after 5 seconds
        setTimeout(() => handleCloseSuccessModal(response.role), 3000);
      } else {
        setIsErrorModalVisible(true);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setIsErrorModalVisible(true);
    }
  };  

  const handleCloseSuccessModal = (role: string) => {
    setIsSuccessModalVisible(false);
    if (role === 'admin') {
      navigate('/admin-dashboard');
    } else {
      navigate('/dashboard');
    }
  };

  const handleForgotPassword = (e: React.FormEvent) => {
    e.preventDefault();
    setIsResetModalVisible(true);
  };

  const handleCloseResetModal = () => {
    setIsResetModalVisible(false);
  };

  const handleOpenSetupPassword = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSetupPasswordVisible(true);
  };

  const handleCloseSetupPassword = () => {
    setIsSetupPasswordVisible(false);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalVisible(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative flex flex-col min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${r1bg})`, backgroundSize: 'cover' }}>
      <CookieNotification />
      <div className="flex-1 flex justify-center items-center z-10 px-4 sm:px-2">
        <motion.div
          className="bg-white p-6 sm:p-10 rounded-lg shadow-lg w-full max-w-md"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            className="flex flex-col items-center space-y-6 xs:space-y-4"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <motion.img
              src={r1logoOri}
              alt="R One Century Logo"
              className="w-32 h-32 sm:w-1/2 sm:h-1/2"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, ease: "easeIn" }}
            />
            <motion.h3
              className="text-lg font-medium text-center sm:text-xl xs:text-sm"
              style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              Welcome to R One Century 创市纪, <br />
              Login Your Account
            </motion.h3>
            <form className="w-full" onSubmit={handleLogin}>
              <motion.div
                className="mb-4 xs:mb-2"
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <div className="flex items-center border rounded px-3 py-2">
                  <FaEnvelope className="text-gray-500 mr-3"
                    style={{ color: theme.primaryColor }} />
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full py-2 xs:py-1 focus:outline-none"
                    style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
                  />
                </div>
              </motion.div>
              <motion.div
                className="mb-4"
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 1 }}
              >
                <div className="flex items-center border rounded px-3 py-2 relative">
                  <FaLock className="text-gray-500 mr-3" 
                    style={{ color: theme.primaryColor }}/>
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full py-2 xs:py-1 focus:outline-none"
                    style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 focus:outline-none text-gray-500"
                  >
                    {showPassword ? <FaEyeSlash style={{ color: theme.primaryColor }} /> : <FaEye style={{ color: theme.primaryColor }}/>}
                  </button>
                </div>
              </motion.div>
              <motion.div
                className="flex justify-between items-center mb-4"
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 1.2 }}
              >
                <label className="flex items-center" style={{ color: theme.primaryColor }}>
                  <input
                    type="checkbox"
                    className="mr-2 w-4 h-4"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    style={{ accentColor: theme.primaryColor }}
                  />
                  <span className="text-base xs:text-xs"
                    style={{ 
                      color: theme.textColor, fontFamily: theme.fontFamily }}>
                      Remember me
                  </span>
                </label>
                <button
                  onClick={handleForgotPassword}
                  className="text-pink-500 xs:text-xs underline"
                  style={{ fontFamily: theme.fontFamily }}
                >
                  Forget password?
                </button>
              </motion.div>
              <motion.div
                className="mb-1"
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 1.4 }}
              >
                <button
                  type="submit"
                  className="w-full py-3 rounded xs:py-2 xs:text-sm  bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
                  style={{ fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
                >
                  Sign in
                </button>
              </motion.div>
            </form>
            <motion.p
              className="text-center xs:text-xs text-gray-600"
              style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 1.6 }}
            >
              First time Sign in?
              <button
                onClick={handleOpenSetupPassword}
                className="text-pink-500 underline"
                style={{ fontFamily: theme.fontFamily }}
              >
                Register here
              </button>
            </motion.p>
          </motion.div>
        </motion.div>
      </div>
      <div className="flex-shrink-0 w-full z-10">
        <AppFooter />
      </div>
      <ResetPassword visible={isResetModalVisible} onClose={handleCloseResetModal} />
      <SetupPassword visible={isSetupPasswordVisible} onClose={handleCloseSetupPassword} />
      <SuccessModal
        visible={isSuccessModalVisible}
        onClose={() => setIsSuccessModalVisible(false)}
        message="Sign In Successful!"
      />
      <ErrorModal visible={isErrorModalVisible} onClose={handleCloseErrorModal} />
    </div>
  );
};

export default LoginView;


// // src/pages/p02_Login.tsx

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
// import { useTheme } from '../context/ThemeContext';
// import AppFooter from '../components/Footer';
// import ResetPassword from '../components/login/ResetPassword';
// import SetupPassword from '../components/login/SignUp';
// import SuccessModal from '../components/login/SuccessModal';
// import ErrorModal from '../components/login/ErrorModal';
// import r1logoOri from '../assets/login/logo_r1_ori.png';
// import r1bg from '../assets/login/bg_r1.png';
// import { useAuth } from '../context/AuthContext';
// import Cookies from 'js-cookie';
// import CookieNotification from '../components/CookieNotification'; // Import the new component

// const LoginView: React.FC = () => {
//   const [email, setEmail] = useState<string>('');
//   const [password, setPassword] = useState<string>('');
//   const [showPassword, setShowPassword] = useState<boolean>(false);
//   const [isResetModalVisible, setIsResetModalVisible] = useState<boolean>(false);
//   const [isSetupPasswordVisible, setIsSetupPasswordVisible] = useState<boolean>(false);
//   const [rememberMe, setRememberMe] = useState<boolean>(false);
//   const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
//   const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);

//   const navigate = useNavigate();
//   const { theme } = useTheme();
//   const { login } = useAuth();

//   useEffect(() => {
//     const storedEmail = Cookies.get('email');
//     const storedPassword = Cookies.get('password');
//     const storedRememberMe = Cookies.get('rememberMe') === 'true';

//     if (storedRememberMe && storedEmail && storedPassword) {
//       setEmail(storedEmail);
//       setPassword(storedPassword);
//       setRememberMe(true);
//     }
//   }, []);

//   const handleLogin = async (e: React.FormEvent) => {
//     e.preventDefault();
//     try {
//       const response = await login(email, password);

//       if (response && response.token) {
//         if (rememberMe) {
//           Cookies.set('email', email, { expires: 7 });
//           Cookies.set('password', password, { expires: 7 });
//           Cookies.set('rememberMe', 'true', { expires: 7 });
//           Cookies.set('token', response.token, { expires: 7 });
//         } else {
//           Cookies.remove('email');
//           Cookies.remove('password');
//           Cookies.set('rememberMe', 'false');
//         }
//         setIsSuccessModalVisible(true);
//         handleCloseSuccessModal(response.role); // Navigate immediately based on role
//       } else {
//         setIsErrorModalVisible(true);
//       }
//     } catch (error) {
//       console.error('Error during login:', error);
//       setIsErrorModalVisible(true);
//     }
//   };

//   const handleCloseSuccessModal = (role: string) => {
//     setIsSuccessModalVisible(false);
//     if (role === 'admin') {
//       navigate('/admin-dashboard');
//     } else {
//       navigate('/dashboard');
//     }
//   };

//   const handleForgotPassword = (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsResetModalVisible(true);
//   };

//   const handleCloseResetModal = () => {
//     setIsResetModalVisible(false);
//   };

//   const handleOpenSetupPassword = (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsSetupPasswordVisible(true);
//   };

//   const handleCloseSetupPassword = () => {
//     setIsSetupPasswordVisible(false);
//   };

//   const handleCloseErrorModal = () => {
//     setIsErrorModalVisible(false);
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <div className="relative flex flex-col min-h-screen bg-cover bg-center"
//       style={{ backgroundImage: `url(${r1bg})`, backgroundSize: 'cover' }}>
//       <CookieNotification /> {/* Include the CookieNotification component */}
//       <div className="flex-1 flex justify-center items-center z-10 px-4 sm:px-0">
//         <div className="bg-white p-6 sm:p-10 rounded-lg shadow-lg w-full max-w-md">
//           <div className="flex flex-col items-center space-y-6">
//             <img src={r1logoOri} alt="R One Century Logo" className="w-32 h-32 sm:w-1/2 sm:h-1/2" />
//             <h3 className="text-lg sm:text-xl font-medium text-center" 
//               style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                 Welcome to R One Century, <br />
//                 Sign in Your Account
//             </h3>
//             <form className="w-full" onSubmit={handleLogin}>
//               <div className="mb-4">
//                 <div className="flex items-center border rounded px-3 py-2">
//                   <FaEnvelope className="text-gray-500 mr-3" />
//                   <input
//                     type="email"
//                     placeholder="Email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     className="w-full py-2 focus:outline-none"
//                     style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4">
//                 <div className="flex items-center border rounded px-3 py-2 relative">
//                   <FaLock className="text-gray-500 mr-3" />
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     className="w-full py-2 focus:outline-none"
//                     style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
//                   />
//                   <button
//                     type="button"
//                     onClick={togglePasswordVisibility} className="absolute right-3 focus:outline-none text-gray-500">
//                     {showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>
//               <div className="flex justify-between items-center mb-4">
//                 <label className="flex items-center" 
//                   style={{ color: theme.primaryColor }}>
//                     <input
//                       type="checkbox"
//                       className="mr-2 w-4 h-4"
//                       checked={rememberMe}
//                       onChange={(e) => setRememberMe(e.target.checked)}
//                       style={{ accentColor: theme.primaryColor }}
//                     />
//                   <span style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>Remember me</span>
//                 </label>
//                 <button 
//                   onClick={handleForgotPassword} 
//                   className="text-pink-500 underline" 
//                   style={{ fontFamily: theme.fontFamily }}>
//                     Forget password?
//                 </button>
//               </div>
//               <div className="mb-1">
//                 <button
//                   type="submit"
//                   className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
//                   style={{ fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
//                 >
//                   Sign in
//                 </button>
//               </div>
//             </form>
//             <p className="text-center text-gray-600" 
//               style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                 First time Sign in?  
//               <button 
//                 onClick={handleOpenSetupPassword} 
//                 className="text-pink-500 underline" 
//                 style={{ fontFamily: theme.fontFamily }}>Register here
//               </button>
//             </p>
//           </div>
//         </div>
//       </div>
//       <div className="flex-shrink-0 w-full z-10">
//         <AppFooter />
//       </div>
//       <ResetPassword visible={isResetModalVisible} onClose={handleCloseResetModal} />
//       <SetupPassword visible={isSetupPasswordVisible} onClose={handleCloseSetupPassword} />
//       <SuccessModal visible={isSuccessModalVisible} onClose={() => setIsSuccessModalVisible(false)} />
//       <ErrorModal visible={isErrorModalVisible} onClose={handleCloseErrorModal} />
//     </div>
//   );
// };

// export default LoginView;



// // 02 july 2024
// // src/pages/p02_Login.tsx

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
// import { useTheme } from '../context/ThemeContext';
// import AppFooter from '../components/Footer';
// import ResetPassword from '../components/login/ResetPassword';
// import SetupPassword from '../components/login/SignUp';
// import SuccessModal from '../components/login/SuccessModal';
// import ErrorModal from '../components/login/ErrorModal';
// import r1logoOri from '../assets/login/logo_r1_ori.png';
// import r1bg from '../assets/login/bg_r1.png';
// import { useAuth } from '../context/AuthContext';

// const LoginView: React.FC = () => {
//   const [email, setEmail] = useState<string>('');
//   const [password, setPassword] = useState<string>('');
//   const [showPassword, setShowPassword] = useState<boolean>(false);
//   const [isResetModalVisible, setIsResetModalVisible] = useState<boolean>(false);
//   const [isSetupPasswordVisible, setIsSetupPasswordVisible] = useState<boolean>(false);
//   const [rememberMe, setRememberMe] = useState<boolean>(false);
//   const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
//   const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);

//   const navigate = useNavigate();
//   const { theme } = useTheme();
//   const { login } = useAuth();

//   useEffect(() => {
//     const storedEmail = localStorage.getItem('email');
//     const storedPassword = localStorage.getItem('password');
//     const storedRememberMe = localStorage.getItem('rememberMe') === 'true';

//     if (storedRememberMe && storedEmail && storedPassword) {
//       setEmail(storedEmail);
//       setPassword(storedPassword);
//       setRememberMe(true);
//     }
//   }, []);

//   const handleLogin = async (e: React.FormEvent) => {
//     e.preventDefault();
//     try {
//       const response = await login(email, password);

//       if (response && response.token) {
//         if (rememberMe) {
//           localStorage.setItem('email', email);
//           localStorage.setItem('password', password);
//           localStorage.setItem('rememberMe', 'true');
//         } else {
//           localStorage.removeItem('email');
//           localStorage.removeItem('password');
//           localStorage.setItem('rememberMe', 'false');
//         }
//         setIsSuccessModalVisible(true);
//         handleCloseSuccessModal(response.role); // Navigate immediately based on role
//       } else {
//         setIsErrorModalVisible(true);
//       }
//     } catch (error) {
//       console.error('Error during login:', error);
//       setIsErrorModalVisible(true);
//     }
//   };

//   const handleCloseSuccessModal = (role: string) => {
//     setIsSuccessModalVisible(false);
//     if (role === 'admin') {
//       navigate('/admin-dashboard');
//     } else {
//       navigate('/dashboard');
//     }
//   };
  
//   const handleForgotPassword = (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsResetModalVisible(true);
//   };

//   const handleCloseResetModal = () => {
//     setIsResetModalVisible(false);
//   };

//   const handleOpenSetupPassword = (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsSetupPasswordVisible(true);
//   };

//   const handleCloseSetupPassword = () => {
//     setIsSetupPasswordVisible(false);
//   };

//   const handleCloseErrorModal = () => {
//     setIsErrorModalVisible(false);
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <div className="relative flex flex-col min-h-screen bg-cover bg-center"
//       style={{ backgroundImage: `url(${r1bg})`, backgroundSize: 'cover' }}>
//       <div className="flex-1 flex justify-center items-center z-10 px-4 sm:px-0">
//         <div className="bg-white p-6 sm:p-10 rounded-lg shadow-lg w-full max-w-md">
//           <div className="flex flex-col items-center space-y-6">
//             <img src={r1logoOri} alt="R One Century Logo" className="w-32 h-32 sm:w-1/2 sm:h-1/2" />
//             <h3 className="text-lg sm:text-xl font-medium text-center" 
//               style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                 Welcome to R One Century, <br />
//                 Sign in Your Account
//             </h3>
//             <form className="w-full" onSubmit={handleLogin}>
//               <div className="mb-4">
//                 <div className="flex items-center border rounded px-3 py-2">
//                   <FaEnvelope className="text-gray-500 mr-3" />
//                   <input
//                     type="email"
//                     placeholder="Email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     className="w-full py-2 focus:outline-none"
//                     style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4">
//                 <div className="flex items-center border rounded px-3 py-2 relative">
//                   <FaLock className="text-gray-500 mr-3" />
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     className="w-full py-2 focus:outline-none"
//                     style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
//                   />
//                   <button
//                     type="button"
//                     onClick={togglePasswordVisibility} className="absolute right-3 focus:outline-none text-gray-500">
//                     {showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>
//               <div className="flex justify-between items-center mb-4">
//                 <label className="flex items-center" 
//                   style={{ color: theme.primaryColor }}>
//                     <input
//                       type="checkbox"
//                       className="mr-2 w-4 h-4"
//                       checked={rememberMe}
//                       onChange={(e) => setRememberMe(e.target.checked)}
//                       style={{ accentColor: theme.primaryColor }}
//                     />
//                   <span style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>Remember me</span>
//                 </label>
//                 <button 
//                   onClick={handleForgotPassword} 
//                   className="text-pink-500 underline" 
//                   style={{ fontFamily: theme.fontFamily }}>
//                     Forget password?
//                 </button>
//               </div>
//               <div className="mb-1">
//                 <button
//                   type="submit"
//                   className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
//                   style={{ fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
//                 >
//                   Sign in
//                 </button>
//               </div>
//             </form>
//             <p className="text-center text-gray-600" 
//               style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                 First time Sign in?  
//               <button 
//                 onClick={handleOpenSetupPassword} 
//                 className="text-pink-500 underline" 
//                 style={{ fontFamily: theme.fontFamily }}>Register here
//               </button>
//             </p>
//           </div>
//         </div>
//       </div>
//       <div className="flex-shrink-0 w-full z-10">
//         <AppFooter />
//       </div>
//       <ResetPassword visible={isResetModalVisible} onClose={handleCloseResetModal} />
//       <SetupPassword visible={isSetupPasswordVisible} onClose={handleCloseSetupPassword} />
//       <SuccessModal visible={isSuccessModalVisible} onClose={() => setIsSuccessModalVisible(false)} />
//       <ErrorModal visible={isErrorModalVisible} onClose={handleCloseErrorModal} />
//     </div>
//   );
// };

// export default LoginView;





// // update code --> 17 june 2024
// // src/pages/p02_Login.tsx

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
// import { useDummy } from '../context/dummy';
// import { useTheme } from '../context/ThemeContext';
// import AppFooter from '../components/Footer';
// import ResetPassword from '../components/login/ResetPassword';
// import SetupPassword from '../components/login/SignUp';
// import SuccessModal from '../components/login/SuccessModal';
// import ErrorModal from '../components/login/ErrorModal';
// import r1logoOri from '../assets/login/logo_r1_ori.png';
// import r1bg from '../assets/login/bg_r1.png';
// import { useLoginAuth } from '../hooks/Login/useLoginAuth';

// const LoginView: React.FC = () => {
//   const [email, setEmail] = useState<string>('');
//   const [password, setPassword] = useState<string>('');
//   const [showPassword, setShowPassword] = useState<boolean>(false);
//   const [isResetModalVisible, setIsResetModalVisible] = useState<boolean>(false);
//   const [isSetupPasswordVisible, setIsSetupPasswordVisible] = useState<boolean>(false);
//   const [rememberMe, setRememberMe] = useState<boolean>(false);
//   const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
//   const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);

//   const navigate = useNavigate();
//   const { isAdmin } = useDummy();
//   const { theme } = useTheme();
//   const { login } = useLoginAuth(); // Removed loading state

//   useEffect(() => {
//     const storedEmail = localStorage.getItem('email');
//     const storedPassword = localStorage.getItem('password');
//     const storedRememberMe = localStorage.getItem('rememberMe') === 'true';

//     if (storedRememberMe && storedEmail && storedPassword) {
//       setEmail(storedEmail);
//       setPassword(storedPassword);
//       setRememberMe(true);
//     }
//   }, []);

//   const handleLogin = async (e: React.FormEvent) => {
//     e.preventDefault();
//     const response = await login(email, password);

//     if (response && response.token) {
//       if (rememberMe) {
//         localStorage.setItem('email', email);
//         localStorage.setItem('password', password);
//         localStorage.setItem('rememberMe', 'true');
//       } else {
//         localStorage.removeItem('email');
//         localStorage.removeItem('password');
//         localStorage.setItem('rememberMe', 'false');
//       }
//       setIsSuccessModalVisible(true);
//       setTimeout(() => {
//         navigate('/dashboard'); // Navigate to dashboard on successful login
//       }, 2000); // Adding a delay to allow the SuccessModal to show briefly
//     } else {
//       setIsErrorModalVisible(true);
//     }
//   };

//   const handleForgotPassword = (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsResetModalVisible(true);
//   };

//   const handleCloseResetModal = () => {
//     setIsResetModalVisible(false);
//   };

//   const handleOpenSetupPassword = (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsSetupPasswordVisible(true);
//   };

//   const handleCloseSetupPassword = () => {
//     setIsSetupPasswordVisible(false);
//   };

//   const handleCloseSuccessModal = () => {
//     setIsSuccessModalVisible(false);
//     if (isAdmin(email)) {
//       navigate('/admin-dashboard');
//     } else {
//       navigate('/dashboard');
//     }
//   };

//   const handleCloseErrorModal = () => {
//     setIsErrorModalVisible(false);
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <div className="relative flex flex-col min-h-screen bg-cover bg-center"
//       style={{ backgroundImage: `url(${r1bg})`, backgroundSize: 'cover' }}>
//       <div className="flex-1 flex justify-center items-center z-10 px-4 sm:px-0">
//         <div className="bg-white p-6 sm:p-10 rounded-lg shadow-lg w-full max-w-md">
//           <div className="flex flex-col items-center space-y-6">
//             <img src={r1logoOri} alt="R One Century Logo" className="w-32 h-32 sm:w-1/2 sm:h-1/2" />
//             <h3 className="text-lg sm:text-xl font-medium text-center" 
//               style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                 Welcome to R One Century, <br />
//                 Sign in Your Account
//             </h3>
//             <form className="w-full" onSubmit={handleLogin}>
//               <div className="mb-4">
//                 <div className="flex items-center border rounded px-3 py-2">
//                   <FaEnvelope className="text-gray-500 mr-3" />
//                   <input
//                     type="email"
//                     placeholder="Email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     className="w-full py-2 focus:outline-none"
//                     style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
//                   />
//                 </div>
//               </div>
//               <div className="mb-4">
//                 <div className="flex items-center border rounded px-3 py-2 relative">
//                   <FaLock className="text-gray-500 mr-3" />
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     className="w-full py-2 focus:outline-none"
//                     style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
//                   />
//                   <button
//                     type="button"
//                     onClick={togglePasswordVisibility} className="absolute right-3 focus:outline-none text-gray-500">
//                     {showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>
//               <div className="flex justify-between items-center mb-4">
//                 <label className="flex items-center" 
//                   style={{ color: theme.primaryColor }}>
//                     <input
//                       type="checkbox"
//                       className="mr-2 w-4 h-4"
//                       checked={rememberMe}
//                       onChange={(e) => setRememberMe(e.target.checked)}
//                       style={{ accentColor: theme.primaryColor }}
//                     />
//                   <span style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>Remember me</span>
//                 </label>
//                 <button 
//                   onClick={handleForgotPassword} 
//                   className="text-pink-500 underline" 
//                   style={{ fontFamily: theme.fontFamily }}>
//                     Forget password?
//                 </button>
//               </div>
//               <div className="mb-1">
//                 <button
//                   type="submit"
//                   className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
//                   style={{ fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
//                 >
//                   Sign in
//                 </button>
//               </div>
//             </form>
//             <p className="text-center text-gray-600" 
//               style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                 First time Sign in?  
//               <button 
//                 onClick={handleOpenSetupPassword} 
//                 className="text-pink-500 underline" 
//                 style={{ fontFamily: theme.fontFamily }}>Register here
//               </button>
//             </p>
//           </div>
//         </div>
//       </div>
//       <div className="flex-shrink-0 w-full z-10">
//         <AppFooter />
//       </div>
//       <ResetPassword visible={isResetModalVisible} onClose={handleCloseResetModal} />
//       <SetupPassword visible={isSetupPasswordVisible} onClose={handleCloseSetupPassword} />
//       <SuccessModal visible={isSuccessModalVisible} onClose={handleCloseSuccessModal} />
//       <ErrorModal visible={isErrorModalVisible} onClose={handleCloseErrorModal} />
//     </div>
//   );
// };

// export default LoginView;



// // update code --> 07 June 2024
// // src/pages/p02_login.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// // import react-icon components
// import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

// // import context file from source
// import { useDummy } from '../context/dummy';
// import { useTheme } from '../context/ThemeContext';
// import { useAuth } from '../context/AuthContext';

// // import components file from source
// import AppFooter from '../components/Footer';
// import ResetPassword from '../components/login/ResetPassword';
// import SetupPassword from '../components/login/SignUp';
// import SuccessModal from '../components/login/SuccessModal'; // Updated import path
// import ErrorModal from '../components/login/ErrorModal'; // Updated import path

// // import assets file from source
// import r1logoOri from '../assets/login/logo_r1_ori.png';
// import r1bg from '../assets/login/bg_r1.png';

// const LoginView: React.FC = () => {
//   const [email, setEmail] = useState<string>('');
//   const [password, setPassword] = useState<string>('');
//   const [showPassword, setShowPassword] = useState<boolean>(false);
//   const [isResetModalVisible, setIsResetModalVisible] = useState<boolean>(false);
//   const [isSetupPasswordVisible, setIsSetupPasswordVisible] = useState<boolean>(false);
//   const [rememberMe, setRememberMe] = useState<boolean>(false);
//   const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
//   const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);

//   const navigate = useNavigate();
//   const { isAdmin } = useDummy();
//   const { theme } = useTheme();
//   const { login, isVerified } = useAuth();

//   // Check if there is stored login information in localStorage
//   useEffect(() => {
//     const storedEmail = localStorage.getItem('email');
//     const storedPassword = localStorage.getItem('password');
//     const storedRememberMe = localStorage.getItem('rememberMe') === 'true';

//     if (storedRememberMe && storedEmail && storedPassword) {
//       setEmail(storedEmail);
//       setPassword(storedPassword);
//       setRememberMe(true);
//     }
//   }, []);

//   // Redirect to login if the user is not verified
//   useEffect(() => {
//     if (!isVerified) {
//       navigate('/login');
//     }
//   }, [isVerified, navigate]);

//   // Handle login form submission
//   const handleLogin = (e: React.FormEvent) => {
//     e.preventDefault();
//     if (login(email, password)) {
//       if (rememberMe) {
//         localStorage.setItem('email', email);
//         localStorage.setItem('password', password);
//         localStorage.setItem('rememberMe', 'true');
//       } else {
//         localStorage.removeItem('email');
//         localStorage.removeItem('password');
//         localStorage.setItem('rememberMe', 'false');
//       }
//       setIsSuccessModalVisible(true);
//     } else {
//       setIsErrorModalVisible(true);
//     }
//   };

//   // Handle "Forgot password" link click
//   const handleForgotPassword = (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsResetModalVisible(true);
//   };

//   // Close the reset password modal
//   const handleCloseResetModal = () => {
//     setIsResetModalVisible(false);
//   };

//   // Open the setup password modal
//   const handleOpenSetupPassword = (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsSetupPasswordVisible(true);
//   };

//   // Close the setup password modal
//   const handleCloseSetupPassword = () => {
//     setIsSetupPasswordVisible(false);
//   };

//   // Close the success modal and navigate to the appropriate dashboard
//   const handleCloseSuccessModal = () => {
//     setIsSuccessModalVisible(false);
//     if (isAdmin(email)) {
//       navigate('/admin-dashboard');
//     } else {
//       navigate('/dashboard');
//     }
//   };

//   // Close the error modal
//   const handleCloseErrorModal = () => {
//     setIsErrorModalVisible(false);
//   };

//   // Toggle password visibility
//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <div className="relative flex flex-col min-h-screen bg-cover bg-center"
//       style={{ backgroundImage: `url(${r1bg})`, backgroundSize: 'cover' }}>
//       <div className="flex-1 flex justify-center items-center z-10 px-4 sm:px-0">
//         <div className="bg-white p-6 sm:p-10 rounded-lg shadow-lg w-full max-w-md">
//           <div className="flex flex-col items-center space-y-6">
            
//             {/* R One Century Logo */}
//             <img src={r1logoOri} alt="R One Century Logo" className="w-32 h-32 sm:w-1/2 sm:h-1/2" />
//             <h3 className="text-lg sm:text-xl font-medium text-center" 
//               style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                 Welcome to R One Century, <br />
//                 Sign in Your Account
//             </h3>
            
//             {/* Sign in form field */}
//             <form className="w-full" onSubmit={handleLogin}>
              
//               {/* Email input field */}
//               <div className="mb-4">
//                 <div className="flex items-center border rounded px-3 py-2">
//                   <FaEnvelope className="text-gray-500 mr-3" />
//                   <input
//                     type="email"
//                     placeholder="Email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     className="w-full py-2 focus:outline-none"
//                     style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
//                   />
//                 </div>
//               </div>

//               {/* Password input field */}
//               <div className="mb-4">
//                 <div className="flex items-center border rounded px-3 py-2 relative">
//                   <FaLock className="text-gray-500 mr-3" />
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     className="w-full py-2 focus:outline-none"
//                     style={{ color: theme.textColor, fontFamily: theme.fontFamily }}
//                   />
//                   <button
//                     type="button"
//                     onClick={togglePasswordVisibility} className="absolute right-3 focus:outline-none text-gray-500">
//                     {showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//               </div>

//               {/* Set remember input */}
//               <div className="flex justify-between items-center mb-4">
//                 <label className="flex items-center" 
//                   style={{ color: theme.primaryColor }}>
//                     <input
//                       type="checkbox"
//                       className="mr-2 w-4 h-4"
//                       checked={rememberMe}
//                       onChange={(e) => setRememberMe(e.target.checked)}
//                       style={{ accentColor: theme.primaryColor }}
//                     />
//                   <span style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>Remember me</span>
//                 </label>
                
//                 {/* Reset password button */}
//                 <button 
//                   onClick={handleForgotPassword} 
//                   className="text-pink-500 underline" 
//                   style={{ fontFamily: theme.fontFamily }}>
//                     Forget password?
//                 </button>
//               </div>

//               {/* User sign in button */}
//               <div className="mb-1">
//                 <button
//                   type="submit"
//                   className="w-full py-3 rounded bg-blue-600 text-white hover:bg-blue-700 focus:outline-none"
//                   style={{ fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor }}
//                 >
//                   Sign in
//                 </button>
//               </div>
//             </form>

//             {/* First time sign in registration */}
//             <p className="text-center text-gray-600" 
//               style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                 First time Sign in?  
//               <button 
//                 onClick={handleOpenSetupPassword} 
//                 className="text-pink-500 underline" 
//                 style={{ fontFamily: theme.fontFamily }}>Register here
//               </button>
//             </p>
//           </div>
//         </div>
//       </div>

//       {/* Footer */}
//       <div className="flex-shrink-0 w-full z-10">
//         <AppFooter />
//       </div>
//       <ResetPassword visible={isResetModalVisible} onClose={handleCloseResetModal} />
//       <SetupPassword visible={isSetupPasswordVisible} onClose={handleCloseSetupPassword} />
//       <SuccessModal visible={isSuccessModalVisible} onClose={handleCloseSuccessModal} /> {/* Success modal */}
//       <ErrorModal visible={isErrorModalVisible} onClose={handleCloseErrorModal} /> {/* Error modal */}
//     </div>
//   );
// };

// export default LoginView;


