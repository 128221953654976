// src/components/admin/clientData/UserDetailModal.tsx

import React, { useState } from 'react';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { useTheme } from '../../../context/ThemeContext';
import SuccessAlert from './UserDetailAlert';
import { ClientDataProps } from '../../../hooks/AdminDashboard/useDataTable';

interface UserDetailModalProps {
  client?: ClientDataProps;
  onClose: () => void;
  onUpdate: (updatedUser: ClientDataProps) => void;
  onDelete: (id: string) => void;
  onAdd: (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => void; // Include password in the type
}

const UserDetailModal: React.FC<UserDetailModalProps> = ({ client, onClose, onUpdate, onDelete, onAdd }) => {
  const { theme } = useTheme();
  const [editMode, setEditMode] = useState<{ [key: string]: boolean }>({});
  const [editedClient, setEditedClient] = useState<ClientDataProps | Omit<ClientDataProps, 'id'>>(
    client || {
      name: '',
      email: '',
      gender: '',
      phone: '',
      dob: '',
      country: '',
      address: '',
      password: '', // Add password field
    }
  );
  const [showAlert, setShowAlert] = useState(false);
  const isAddMode = !client;

  const toggleEditMode = (field: keyof ClientDataProps) => {
    setEditMode(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedClient(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    if (isAddMode) {
      onAdd(editedClient as Omit<ClientDataProps, 'id'> & { password: string });
    } else {
      onUpdate(editedClient as ClientDataProps);
    }
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000);
    onClose();
  };

  const renderField = (label: string, field: keyof ClientDataProps | 'password') => {
    let value = (editedClient as any)[field];
    if (field === 'dob' && value) {
      value = new Date(value).toISOString().split('T')[0];
    }
    value = value || '';
    
    return (
      <div className="flex justify-between py-2">
        <p><strong className="font-semibold">{label}:</strong></p>
        <div className="flex items-center gap-2">
          {editMode[field] || isAddMode ? (
            <>
              <input
                type={field === 'dob' ? 'date' : 'text'}
                name={field}
                value={value}
                onChange={handleInputChange}
                className="border rounded px-2 py-1"
              />
              {!isAddMode && (
                <button onClick={() => toggleEditMode(field as keyof ClientDataProps)} className="text-red-500 hover:text-red-700">
                  <FaTimes />
                </button>
              )}
            </>
          ) : (
            <>
              <p className="mr-2">{value || 'N/A'}</p>
              <button onClick={() => toggleEditMode(field as keyof ClientDataProps)} className="text-blue-500 hover:text-blue-700">
                <FaEdit />
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 relative" style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
        <div className="bg-gray-100 p-4 rounded-t-lg">
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">
            {isAddMode ? 'Add New User' : 'User Details'}
          </h2>
          {!isAddMode && (
            <div className="bg-red-100 text-red-800 p-2 rounded border border-red-300 mb-2">
              <p className="text-sm">Admin caution: Be careful when changing user information. Ensure all modifications are accurate.</p>
            </div>
          )}
        </div>

        <div className="bg-white p-4 space-y-3">
          <div className="grid grid-cols-1 gap-4 divide-y divide-gray-200">
            {renderField('Name', 'name')}
            {renderField('Email', 'email')}
            {renderField('Gender', 'gender')}
            {renderField('Phone', 'phone')}
            {renderField('Date of Birth', 'dob')}
            {renderField('Country', 'country')}
            {renderField('Address', 'address')}
            {isAddMode && renderField('Password', 'password')} {/* Add password field */}
          </div>
        </div>

        <div className="mt-6 flex justify-end gap-3 bg-gray-100 p-4 rounded-b-lg">
          <button onClick={onClose} className="px-4 py-2 bg-gray-500 text-white font-semibold rounded-lg hover:bg-gray-600 transition-colors">
            Close
          </button>
          <button onClick={handleSave} className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 transition-colors">
            {isAddMode ? 'Add User' : 'Save'}
          </button>
        </div>

        {showAlert && <SuccessAlert message={isAddMode ? "User added successfully" : "User information updated successfully"} />}
      </div>
    </div>
  );
};

export default UserDetailModal;


// // uipdate code --> 25 june 2024 v02
// // src/components/admin/clientData/UserDetailModal.tsx

// import React, { useState } from 'react';
// import { FaEdit, FaSave, FaTimes } from 'react-icons/fa';
// import { useTheme } from '../../../context/ThemeContext';
// import SuccessAlert from './UserDetailAlert';
// import { ClientDataProps } from '../../../hooks/AdminDashboard/useDataTable';

// interface UserDetailModalProps {
//   client?: ClientDataProps;
//   onClose: () => void;
//   onUpdate: (updatedUser: ClientDataProps) => void;
//   onDelete: (id: string) => void;
//   onAdd: (newUser: Omit<ClientDataProps, 'id'> & { password: string }) => void; // Include password in the type
// }

// const UserDetailModal: React.FC<UserDetailModalProps> = ({ client, onClose, onUpdate, onDelete, onAdd }) => {
//   const { theme } = useTheme();
//   const [editMode, setEditMode] = useState<{ [key: string]: boolean }>({});
//   const [editedClient, setEditedClient] = useState<ClientDataProps | Omit<ClientDataProps, 'id'>>(
//     client || {
//       name: '',
//       email: '',
//       gender: '',
//       phone: '',
//       dob: '',
//       country: '',
//       address: '',
//       password: '', // Add password field
//     }
//   );
//   const [showAlert, setShowAlert] = useState(false);
//   const isAddMode = !client;

//   const toggleEditMode = (field: keyof ClientDataProps) => {
//     setEditMode(prev => ({ ...prev, [field]: !prev[field] }));
//   };

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setEditedClient(prev => ({ ...prev, [name]: value }));
//   };

//   const handleSave = (field: keyof ClientDataProps | 'password') => {
//     if (isAddMode) {
//       onAdd(editedClient as Omit<ClientDataProps, 'id'> & { password: string });
//     } else {
//       onUpdate(editedClient as ClientDataProps);
//     }
//     toggleEditMode(field as keyof ClientDataProps);
//     setShowAlert(true);
//     setTimeout(() => setShowAlert(false), 3000);
//   };

//   const handleDelete = () => {
//     if (!isAddMode && 'id' in editedClient) {
//       onDelete((editedClient as ClientDataProps).id);
//     }
//     onClose();
//   };

//   const renderField = (label: string, field: keyof ClientDataProps | 'password') => {
//     let value = (editedClient as any)[field];
//     if (field === 'dob' && value) {
//       value = new Date(value).toISOString().split('T')[0];
//     }
//     value = value || '';
    
//     return (
//       <div className="flex justify-between py-2">
//         <p><strong className="font-semibold">{label}:</strong></p>
//         <div className="flex items-center gap-2">
//           {editMode[field] || isAddMode ? (
//             <>
//               <input
//                 type={field === 'dob' ? 'date' : 'text'}
//                 name={field}
//                 value={value}
//                 onChange={handleInputChange}
//                 className="border rounded px-2 py-1"
//               />
//               <button onClick={() => handleSave(field)} className="text-green-500 hover:text-green-700">
//                 <FaSave />
//               </button>
//               {!isAddMode && (
//                 <button onClick={() => toggleEditMode(field as keyof ClientDataProps)} className="text-red-500 hover:text-red-700">
//                   <FaTimes />
//                 </button>
//               )}
//             </>
//           ) : (
//             <>
//               <p className="mr-2">{value || 'N/A'}</p>
//               <button onClick={() => toggleEditMode(field as keyof ClientDataProps)} className="text-blue-500 hover:text-blue-700">
//                 <FaEdit />
//               </button>
//             </>
//           )}
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 relative" style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>
//         <div className="bg-gray-100 p-4 rounded-t-lg">
//           <h2 className="text-2xl font-semibold text-gray-800 mb-2">
//             {isAddMode ? 'Add New User' : 'User Details'}
//           </h2>
//           {!isAddMode && (
//             <div className="bg-red-100 text-red-800 p-2 rounded border border-red-300 mb-2">
//               <p className="text-sm">Admin caution: Be careful when changing user information. Ensure all modifications are accurate.</p>
//             </div>
//           )}
//         </div>

//         <div className="bg-white p-4 space-y-3">
//           <div className="grid grid-cols-1 gap-4 divide-y divide-gray-200">
//             {renderField('Name', 'name')}
//             {renderField('Email', 'email')}
//             {renderField('Gender', 'gender')}
//             {renderField('Phone', 'phone')}
//             {renderField('Date of Birth', 'dob')}
//             {renderField('Country', 'country')}
//             {renderField('Address', 'address')}
//             {isAddMode && renderField('Password', 'password')} {/* Add password field */}
//           </div>
//         </div>

//         <div className="mt-6 flex justify-end gap-3 bg-gray-100 p-4 rounded-b-lg">
//           <button onClick={onClose} className="px-4 py-2 bg-gray-500 text-white font-semibold rounded-lg hover:bg-gray-600 transition-colors">
//             Close
//           </button>
//           {isAddMode ? (
//             <button onClick={() => handleSave('name')} className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 transition-colors">
//               Add User
//             </button>
//           ) : (
//             <button onClick={handleDelete} className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600 transition-colors">
//               Delete User
//             </button>
//           )}
//         </div>

//         {showAlert && <SuccessAlert message={isAddMode ? "User added successfully" : "User information updated successfully"} />}
//       </div>
//     </div>
//   );
// };

// export default UserDetailModal;


// // update code --> 25/06/2024
// // src/components/admin/ClientData/UserDetailModal.tsx

// import React, { useState } from 'react';
// import { ClientDataProps } from '../../../hooks/AdminDashboard/useDataTable';
// import { FaEdit } from 'react-icons/fa';
// import { useTheme } from '../../../context/ThemeContext';
// import SuccessAlert from './UserDetailAlert';

// interface UserDetailModalProps {
//   client: ClientDataProps;
//   onClose: () => void;
// }

// const UserDetailModal: React.FC<UserDetailModalProps> = ({ client, onClose }) => {
//   const { theme } = useTheme(); // Access theme from ThemeContext
//   const [showAlert, setShowAlert] = useState(false);

//   // Function to handle save button click
//   const handleSave = () => {
//     setShowAlert(true);
//     setTimeout(() => setShowAlert(false), 3000); // Hide alert after 3 seconds
//   };

//   return (
//     <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 relative" style={{ color: theme.primaryColor, fontFamily: theme.fontFamily }}>

//         {/* Modal header with alert message */}
//         <div className="bg-gray-100 p-4 rounded-t-lg">
//           <h2 className="text-2xl font-semibold text-gray-800 mb-2">User Details</h2>
//           <div className="bg-red-100 text-red-800 p-2 rounded border border-red-300 mb-2">
//             <p className="text-sm">Admin caution: Be careful when changing user information. Ensure all modifications are accurate.</p>
//           </div>
//         </div>

//         {/* User details form */}
//         <div className="bg-white p-4 space-y-3">
//           <div className="grid grid-cols-1 gap-4 divide-y divide-gray-200">
//             <div className="flex justify-between py-2">
//               <p><strong className="font-semibold">Name:</strong></p>
//               <div className="flex items-center gap-10">
//                 <p className="mr-2">{client.name}</p>
//                 <button className="text-blue-500 hover:text-blue-700"><FaEdit /></button>
//               </div>
//             </div>
//             <div className="flex justify-between py-2">
//               <p><strong className="font-semibold">Address:</strong></p>
//               <div className="flex items-center gap-10">
//                 <p className="mr-2">{client.address}</p>
//                 <button className="text-blue-500 hover:text-blue-700"><FaEdit /></button>
//               </div>
//             </div>
//             <div className="flex justify-between py-2">
//               <p><strong className="font-semibold">Email:</strong></p>
//               <div className="flex items-center gap-10">
//                 <p className="mr-2">{client.email}</p>
//                 <button className="text-blue-500 hover:text-blue-700"><FaEdit /></button>
//               </div>
//             </div>
//             <div className="flex justify-between py-2">
//               <p><strong className="font-semibold">Date of Birth:</strong></p>
//               <div className="flex items-center gap-10">
//                 <p className="mr-2">{client.dob}</p>
//                 <button className="text-blue-500 hover:text-blue-700"><FaEdit /></button>
//               </div>
//             </div>
//             <div className="flex justify-between py-2">
//               <p><strong className="font-semibold">Contact No:</strong></p>
//               <div className="flex items-center gap-10">
//                 <p className="mr-2">{client.phone}</p>
//                 <button className="text-blue-500 hover:text-blue-700"><FaEdit /></button>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Buttons section */}
//         <div className="mt-6 flex justify-end gap-3 bg-gray-100 p-4 rounded-b-lg">
//           <button onClick={onClose} className="px-6 py-2 bg-gray-600 text-white font-semibold rounded-lg hover:bg-gray-700 transition-colors">
//             Close
//           </button>
//           <button onClick={handleSave} className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-colors">
//             Save
//           </button>
//         </div>

//         {/* Success alert */}
//         {showAlert && <SuccessAlert />}
//       </div>
//     </div>
//   );
// };

// export default UserDetailModal;
