// src/components/login/resendResetPasswordAlert.tsx

import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimes, FaExclamationCircle } from 'react-icons/fa';
import { useTheme } from '../../context/ThemeContext'; // Import theme context

interface ResendAlertProps {
  visible: boolean;
  onClose: () => void;
  isSuccess: boolean;
}

const ResendResetPasswordAlert: React.FC<ResendAlertProps> = ({ visible, onClose, isSuccess }) => {
  const { theme } = useTheme(); // Get theme from context
  const [showModal, setShowModal] = useState(false);

  // Effect to handle the animation and redirection
  useEffect(() => {
    if (visible) {
      setShowModal(true);
      // Set a timeout to hide the modal after 3 seconds
      const timer = setTimeout(() => {
        onClose(); // Close the modal after 3 seconds
      }, 3000);
      return () => clearTimeout(timer); // Cleanup timeout on unmount
    } else {
      setShowModal(false);
    }
  }, [visible, onClose]);

  // If the modal is not visible, return null
  if (!visible) return null;

  const alertClasses = isSuccess ? 'text-green-800 bg-green-100 border-green-500' : 'text-red-800 bg-red-100 border-red-500';
  const icon = isSuccess ? <FaCheckCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" /> : <FaExclamationCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" />;
  const message = isSuccess ? 'Password Reset Email Sent!' : 'Failed to Send Password Reset Email';

  return (
    // Modal container with transition effect
    <div 
      className={`fixed inset-x-0 top-0 transition-transform transform ${showModal ? 'translate-y-0' : '-translate-y-full'} z-50 px-4`}
      style={{ fontFamily: theme.fontFamily }}
    >
      {/* Alert box with light green or red background and green or red border */}
      <div className={`relative flex flex-col p-4 mt-4 mb-4 text-sm rounded-lg border max-w-lg mx-auto md:max-w-2xl ${alertClasses}`} role="alert">
        {/* Success or error icon and title */}
        <div className="flex items-center">
          {icon}
          <span className="font-medium" style={{ color: theme.textColor }}>
            {message}
          </span>
        </div>
        {/* Success or error message content */}
        <div className="mt-2 md:ml-4">
          {isSuccess
            ? 'We have successfully sent the password reset email. Please check your inbox to reset your password. Thank you for using our platform!'
            : 'We could not send the password reset email. Please try again later.'}
        </div>
        {/* Close icon button */}
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 hover:text-gray-600 focus:outline-none"
          style={{ color: isSuccess ? 'green' : 'red' }}
        >
          <FaTimes className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default ResendResetPasswordAlert;
