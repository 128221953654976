// src/hooks/Subscription/useSubscriptionValidation.tsx

import { useState } from 'react';

interface ValidationErrors {
  planType?: boolean;
  accountType?: boolean;
  accountServer?: boolean;
  accountNumber?: boolean;
  accountPassword?: boolean;
  demoAccountType?: boolean;
  demoAccountServer?: boolean;
  demoAccountNumber?: boolean;
  demoAccountPassword?: boolean;
  paymentMethod?: boolean;
  paymentReceipts?: boolean;
  termsAccepted?: boolean;
}

const useFormValidation = () => {
  const [errors, setErrors] = useState<ValidationErrors>({});

  const validate = (fields: {
    planType: string;
    accountType: string;
    accountServer: string;
    accountNumber: string;
    accountPassword: string;
    demoAccountType?: string;
    demoAccountServer?: string;
    demoAccountNumber?: string;
    demoAccountPassword?: string;
    paymentMethod: string;
    paymentReceipts: File[];
    termsAccepted: boolean;
  }) => {
    const newErrors: ValidationErrors = {};

    // Validate Live account fields only if accountType is provided
  if (fields.accountType) {
    if (!fields.accountServer) newErrors.accountServer = true;
    if (!fields.accountNumber) newErrors.accountNumber = true;
    if (!fields.accountPassword) newErrors.accountPassword = true;
  }

  // Validate Demo account fields only if demoAccountType is provided
  if (fields.demoAccountType) {
    if (!fields.demoAccountServer) newErrors.demoAccountServer = true;
    if (!fields.demoAccountNumber) newErrors.demoAccountNumber = true;
    if (!fields.demoAccountPassword) newErrors.demoAccountPassword = true;
  }

  if (!fields.planType) newErrors.planType = true;
  if (fields.planType !== 'trial' && !fields.paymentMethod) newErrors.paymentMethod = true;
  if (fields.paymentReceipts.length === 0) newErrors.paymentReceipts = true;
  if (!fields.termsAccepted) newErrors.termsAccepted = true;

  setErrors(newErrors);

  return Object.keys(newErrors).length === 0;
};

  return { validate, errors, setErrors };
};

export default useFormValidation;


// // update code --> 19 Aug 2024
// // src/hooks/Subscription/useSubscriptionValidation.tsx

// import { useState } from 'react';

// interface ValidationErrors {
//   planType?: boolean;
//   accountType?: boolean;
//   accountServer?: boolean;
//   accountNumber?: boolean;
//   accountPassword?: boolean;
//   demoAccountType?: boolean;
//   demoAccountServer?: boolean;
//   demoAccountNumber?: boolean;
//   demoAccountPassword?: boolean;
//   paymentMethod?: boolean;
//   paymentReceipts?: boolean;
//   termsAccepted?: boolean;
// }

// const useFormValidation = () => {
//   const [errors, setErrors] = useState<ValidationErrors>({});

//   const validate = (fields: {
//     planType: string;
//     accountType: string;
//     accountServer: string;
//     accountNumber: string;
//     accountPassword: string;
//     demoAccountType?: boolean;
//     demoAccountServer?: boolean;
//     demoAccountNumber?: boolean;
//     demoAccountPassword?: boolean;
//     paymentMethod: string;
//     paymentReceipts: File[];
//     termsAccepted: boolean;
//   }) => {
//     const newErrors: ValidationErrors = {};

//     if (!fields.planType) newErrors.planType = true;
//     if (!fields.accountType) newErrors.accountType = true;
//     if (!fields.accountServer) newErrors.accountServer = true;
//     if (!fields.accountNumber) newErrors.accountNumber = true;
//     if (!fields.accountPassword) newErrors.accountPassword = true;
//     if (fields.planType !== 'trial' && !fields.paymentMethod) newErrors.paymentMethod = true;
//     if (fields.paymentReceipts.length === 0) newErrors.paymentReceipts = true;
//     if (!fields.termsAccepted) newErrors.termsAccepted = true;

//     setErrors(newErrors);

//     return Object.keys(newErrors).length === 0;
//   };

//   return { validate, errors };
// };

// export default useFormValidation;

