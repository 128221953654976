// src/hooks/AdminDashboard/useVoucherSetting.tsx

import { useState, useCallback } from 'react';
import { useEnv } from '../../context/EnvContext';

export interface Voucher {
  id?: number;
  planID: number;
  voucherName: string;
  voucherCode: string;
  voucherType: string;
  voucherAmount: string;
  voucherPercentage: string;
  voucherPeriod: string;
  voucherDays: string;
  voucherRedeemCount: string;
  voucherRedeemCheck: string;
  voucherStatus: string;
  voucherMemberType: string[];
  voucherGroupName: string;
  voucherGroupMember: string[];
}

const useVoucherSetting = () => {
  const { apiUrl } = useEnv();
  const [vouchers, setVouchers] = useState<Voucher[]>([]);

  const fetchAPI = useCallback(async (endpoint: string, options?: RequestInit) => {
    try {
      const response = await fetch(`${apiUrl}${endpoint}`, options);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }, [apiUrl]);

  const fetchVouchers = useCallback(async (): Promise<Voucher[]> => {
    try {
      const data = await fetchAPI('/api/voucher-settings');
      setVouchers(Array.isArray(data) ? data : [data]); 
      return Array.isArray(data) ? data : [data]; 
    } catch (error) {
      console.error('Error fetching voucher settings:', error);
      return [];
    }
  }, [fetchAPI]);

  const fetchVouchersByPlan = useCallback(async (planID: number): Promise<Voucher[]> => {
    if (!planID) {
      throw new Error('planID is required');
    }
    try {
      const data = await fetchAPI(`/api/voucher-settings?planID=${planID}`);
      setVouchers(Array.isArray(data) ? data : [data]); 
      return Array.isArray(data) ? data : [data]; 
    } catch (error) {
      console.error('Error fetching voucher settings:', error);
      return [];
    }
  }, [fetchAPI]);


  const saveVoucher = async (voucherData: Voucher) => {
    try {
      const data = await fetchAPI('/api/save-voucher-setting', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(voucherData)
      });

      if (voucherData.planID) {
        await fetchVouchersByPlan(voucherData.planID); 
      } else {
        await fetchVouchers();
      }
      return data;
    } catch (error) {
      console.error('Error saving voucher setting:', error);
      throw error;
    }
  };
  
  const deleteVoucher = async (voucherId: number) => {
    try {
      await fetchAPI('/api/delete-voucher-setting', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ voucherId })
      });

      if (voucherId) {
        await fetchVouchersByPlan(voucherId);
      } else {
        await fetchVouchers();
      }
    } catch (error) {
      console.error('Error deleting voucher setting:', error);
    }
  };

  return {
    vouchers,
    fetchVouchers,
    saveVoucher,
    deleteVoucher,
    fetchVouchersByPlan,
  };
};

export default useVoucherSetting;


// // update code --> 20 Aug 2024
// // src/hooks/AdminDashboard/useVoucherSetting.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Voucher {
//   id?: number;
//   planID: number;
//   voucherName: string;
//   voucherCode: string;
//   voucherType: string;
//   voucherAmount: string;
//   voucherPercentage: string;
//   voucherPeriod: string;
//   voucherDays: string;
//   voucherRedeemCount: string;
//   voucherStatus: string;
//   voucherMemberType: string[];
//   voucherGroupName: string;
//   voucherGroupMember: string[];
// }

// const useVoucherSetting = () => {
//   const { apiUrl } = useEnv();
//   const [vouchers, setVouchers] = useState<Voucher[]>([]);

//   const fetchAPI = useCallback(async (endpoint: string, options?: RequestInit) => {
//     try {
//       const response = await fetch(`${apiUrl}${endpoint}`, options);
//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.error || 'Network response was not ok');
//       }
//       return await response.json();
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       throw error;
//     }
//   }, [apiUrl]);

//   const fetchVouchers = useCallback(async (): Promise<Voucher[]> => {
//     try {
//       const data = await fetchAPI('/api/voucher-settings');
//       setVouchers(Array.isArray(data) ? data : [data]); 
//       return Array.isArray(data) ? data : [data]; 
//     } catch (error) {
//       console.error('Error fetching voucher settings:', error);
//       return [];
//     }
//   }, [fetchAPI]);

//   const fetchVouchersByPlan = useCallback(async (planID: number): Promise<Voucher[]> => {
//     if (!planID) {
//       throw new Error('planID is required');
//     }
//     try {
//       const data = await fetchAPI(`/api/voucher-settings?planID=${planID}`);
//       setVouchers(Array.isArray(data) ? data : [data]); 
//       return Array.isArray(data) ? data : [data]; 
//     } catch (error) {
//       console.error('Error fetching voucher settings:', error);
//       return [];
//     }
//   }, [fetchAPI]);


//   const saveVoucher = async (voucherData: Voucher) => {
//     try {
//       const data = await fetchAPI('/api/save-voucher-setting', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(voucherData)
//       });

//       if (voucherData.planID) {
//         await fetchVouchersByPlan(voucherData.planID); 
//       } else {
//         await fetchVouchers();
//       }
//       return data;
//     } catch (error) {
//       console.error('Error saving voucher setting:', error);
//       throw error;
//     }
//   };
  
//   const deleteVoucher = async (voucherId: number) => {
//     try {
//       await fetchAPI('/api/delete-voucher-setting', {
//         method: 'DELETE',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ voucherId })
//       });

//       if (voucherId) {
//         await fetchVouchersByPlan(voucherId);
//       } else {
//         await fetchVouchers();
//       }
//     } catch (error) {
//       console.error('Error deleting voucher setting:', error);
//     }
//   };

//   return {
//     vouchers,
//     fetchVouchers,
//     saveVoucher,
//     deleteVoucher,
//     fetchVouchersByPlan,
//   };
// };

// export default useVoucherSetting;


// // udpate dode --> 01 aug 2024 v03
// // src/hooks/AdminDashboard/useVoucherSetting.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Voucher {
//   id?: number;
//   planID: number;
//   voucherName: string;
//   voucherCode: string;
//   voucherType: string;
//   voucherAmount: string;
//   voucherPercentage: string;
//   voucherPeriod: string;
//   voucherDays: string;
//   voucherRedeemCount: string;
//   voucherStatus: string;
//   voucherMemberType: string[];
// }

// const useVoucherSetting = () => {
//   const { apiUrl } = useEnv();
//   const [vouchers, setVouchers] = useState<Voucher[]>([]);

//   const fetchAPI = useCallback(async (endpoint: string, options?: RequestInit) => {
//     try {
//       const response = await fetch(`${apiUrl}${endpoint}`, options);
//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.error || 'Network response was not ok');
//       }
//       return await response.json();
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       throw error;
//     }
//   }, [apiUrl]);

//   const fetchVouchers = useCallback(async (): Promise<Voucher[]> => {
//     try {
//       // console.log('Fetching all vouchers from API');
//       const data = await fetchAPI('/api/voucher-settings');
//       // console.log('Fetched vouchers:', data);
//       setVouchers(Array.isArray(data) ? data : [data]); 
//       return Array.isArray(data) ? data : [data]; 
//     } catch (error) {
//       console.error('Error fetching voucher settings:', error);
//       return [];
//     }
//   }, [fetchAPI]);

//   const fetchVouchersByPlan = useCallback(async (planID: number): Promise<Voucher[]> => {
//     if (!planID) {
//       throw new Error('planID is required');
//     }
//     try {
//       // console.log('Fetching vouchers from API for planID:', planID);
//       const data = await fetchAPI(`/api/voucher-settings?planID=${planID}`);
//       // console.log('Fetched vouchers:', data);
//       setVouchers(Array.isArray(data) ? data : [data]); 
//       return Array.isArray(data) ? data : [data]; 
//     } catch (error) {
//       console.error('Error fetching voucher settings:', error);
//       return [];
//     }
//   }, [fetchAPI]);
  

//   // const fetchVouchersByPlan = useCallback(async (planID: number): Promise<Voucher[]> => {
//   //   try {
//   //     console.log('Fetching vouchers from API for planID:', planID);
//   //     const data = await fetchAPI(`/api/voucher-settings?planID=${planID}`);
//   //     console.log('Fetched vouchers:', data);
//   //     setVouchers(Array.isArray(data) ? data : [data]); // Ensure the data is set correctly as an array
//   //     return Array.isArray(data) ? data : [data]; // Ensure this returns the fetched data as an array
//   //   } catch (error) {
//   //     console.error('Error fetching voucher settings:', error);
//   //     return [];
//   //   }
//   // }, [fetchAPI]);  

//   const saveVoucher = async (voucherData: Voucher) => {
//     try {
//       // console.log('Saving voucher data:', voucherData);
//       const data = await fetchAPI('/api/save-voucher-setting', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(voucherData)
//       });
//       // console.log('Saved voucher data:', data);
//       if (voucherData.planID) {
//         await fetchVouchersByPlan(voucherData.planID); // Fetch vouchers for the specific plan
//       } else {
//         await fetchVouchers(); // Fetch all vouchers
//       }
//       return data;
//     } catch (error) {
//       console.error('Error saving voucher setting:', error);
//       throw error;
//     }
//   };
  
//   const deleteVoucher = async (voucherId: number) => {
//     try {
//       // console.log('Deleting voucher with ID:', voucherId);
//       await fetchAPI('/api/delete-voucher-setting', {
//         method: 'DELETE',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ voucherId })
//       });
//       // console.log('Deleted voucher with ID:', voucherId);
//       if (voucherId) {
//         await fetchVouchersByPlan(voucherId); // Fetch vouchers for the specific plan
//       } else {
//         await fetchVouchers(); // Fetch all vouchers
//       }
//     } catch (error) {
//       console.error('Error deleting voucher setting:', error);
//     }
//   };
  

//   // const saveVoucher = async (voucherData: Voucher) => {
//   //   try {
//   //     console.log('Saving voucher data:', voucherData);
//   //     const data = await fetchAPI('/api/save-voucher-setting', {
//   //       method: 'POST',
//   //       headers: { 'Content-Type': 'application/json' },
//   //       body: JSON.stringify(voucherData)
//   //     });
//   //     console.log('Saved voucher data:', data);
//   //     await fetchVouchers(); // Call fetchVouchers without arguments
//   //     return data;
//   //   } catch (error) {
//   //     console.error('Error saving voucher setting:', error);
//   //     throw error;
//   //   }
//   // };
  
//   // const deleteVoucher = async (voucherId: number) => {
//   //   try {
//   //     console.log('Deleting voucher with ID:', voucherId);
//   //     await fetchAPI('/api/delete-voucher-setting', {
//   //       method: 'DELETE',
//   //       headers: { 'Content-Type': 'application/json' },
//   //       body: JSON.stringify({ voucherId })
//   //     });
//   //     console.log('Deleted voucher with ID:', voucherId);
//   //     await fetchVouchers(); // Call fetchVouchers without arguments
//   //   } catch (error) {
//   //     console.error('Error deleting voucher setting:', error);
//   //   }
//   // };
  

//   return {
//     vouchers,
//     fetchVouchers,
//     saveVoucher,
//     deleteVoucher,
//     fetchVouchersByPlan,
//   };
// };

// export default useVoucherSetting;


// // update code --> 01 Aug 2024 v02
// // src/hooks/AdminDashboard/useVoucherSetting.tsx

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// export interface Voucher {
//   id?: number;
//   planID: number;
//   voucherName: string;
//   voucherCode: string;
//   voucherType: string;
//   voucherAmount: string;
//   voucherPercentage: string;
//   voucherPeriod: string;
//   voucherDays: string;
//   voucherRedeemCount: string;
//   voucherStatus: string;
//   voucherMemberType: string[];
// }

// const useVoucherSetting = () => {
//   const { apiUrl } = useEnv();
//   const [vouchers, setVouchers] = useState<Voucher[]>([]);

//   const fetchAPI = useCallback(async (endpoint: string, options?: RequestInit) => {
//     try {
//       const response = await fetch(`${apiUrl}${endpoint}`, options);
//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.error || 'Network response was not ok');
//       }
//       return await response.json();
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       throw error;
//     }
//   }, [apiUrl]);

//   const fetchVouchers = useCallback(async (): Promise<Voucher[]> => {
//     try {
//       console.log('Fetching all vouchers from API');
//       const data = await fetchAPI('/api/voucher-settings');
//       console.log('Fetched vouchers:', data);
//       setVouchers(Array.isArray(data) ? data : [data]); // Ensure the data is set correctly as an array
//       return Array.isArray(data) ? data : [data]; // Ensure this returns the fetched data as an array
//     } catch (error) {
//       console.error('Error fetching voucher settings:', error);
//       return [];
//     }
//   }, [fetchAPI]);

//   const fetchVouchersByPlan = useCallback(async (planID: number): Promise<Voucher[]> => {
//     try {
//       console.log('Fetching vouchers from API for planID:', planID);
//       const data = await fetchAPI(`/api/voucher-settings?planID=${planID}`);
//       console.log('Fetched vouchers:', data);
//       setVouchers(Array.isArray(data) ? data : [data]); // Ensure the data is set correctly as an array
//       return Array.isArray(data) ? data : [data]; // Ensure this returns the fetched data as an array
//     } catch (error) {
//       console.error('Error fetching voucher settings:', error);
//       return [];
//     }
//   }, [fetchAPI]);  

//   const saveVoucher = async (voucherData: Voucher) => {
//     try {
//       console.log('Saving voucher data:', voucherData);
//       const data = await fetchAPI('/api/save-voucher-setting', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(voucherData)
//       });
//       console.log('Saved voucher data:', data);
//       await fetchVouchers(); // Call fetchVouchers without arguments
//       return data;
//     } catch (error) {
//       console.error('Error saving voucher setting:', error);
//       throw error;
//     }
//   };
  
//   const deleteVoucher = async (voucherId: number) => {
//     try {
//       console.log('Deleting voucher with ID:', voucherId);
//       await fetchAPI('/api/delete-voucher-setting', {
//         method: 'DELETE',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ voucherId })
//       });
//       console.log('Deleted voucher with ID:', voucherId);
//       await fetchVouchers(); // Call fetchVouchers without arguments
//     } catch (error) {
//       console.error('Error deleting voucher setting:', error);
//     }
//   };
  

//   return {
//     vouchers,
//     fetchVouchers,
//     saveVoucher,
//     deleteVoucher,
//     fetchVouchersByPlan,
//   };
// };

// export default useVoucherSetting;



// // update code --> 01 Aug 2024
// // src/hooks/AdminDashboard/useVoucherSetting.ts

// import { useState, useCallback } from 'react';
// import { useEnv } from '../../context/EnvContext';

// const useVoucherSetting = () => {
//   const { apiUrl } = useEnv();
//   const [vouchers, setVouchers] = useState([]);

//   const fetchAPI = useCallback(async (endpoint: string, options?: RequestInit) => {
//     try {
//       const response = await fetch(`${apiUrl}${endpoint}`, options);
//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.error || 'Network response was not ok');
//       }
//       return await response.json();
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       throw error;
//     }
//   }, [apiUrl]);

//   const fetchVouchers = useCallback(async () => {
//     try {
//       const data = await fetchAPI('/api/voucher-settings');
//       setVouchers(data);
//     } catch (error) {
//       console.error('Error fetching voucher settings:', error);
//     }
//   }, [fetchAPI]);

//   const saveVoucher = async (voucherData: any) => {
//     try {
//       const data = await fetchAPI('/api/save-voucher-setting', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(voucherData)
//       });
//       fetchVouchers();
//       return data;
//     } catch (error) {
//       console.error('Error saving voucher setting:', error);
//       throw error;
//     }
//   };

//   const deleteVoucher = async (voucherId: number) => {
//     try {
//       await fetchAPI('/api/delete-voucher-setting', {
//         method: 'DELETE',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ voucherId })
//       });
//       fetchVouchers();
//     } catch (error) {
//       console.error('Error deleting voucher setting:', error);
//     }
//   };

//   return {
//     vouchers,
//     fetchVouchers,
//     saveVoucher,
//     deleteVoucher,
//   };
// };

// export default useVoucherSetting;