// src/components/admin/CampaignSetting.tsx

// import react components
import React, { useState, useEffect } from 'react';
import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';  
import MailSetting from './MailSetting';
import CampaignLogic from './CampingLogic';
// import react icon
import { IoMdCloseCircle, IoMdSearch } from "react-icons/io";
import { MdPlaylistAddCircle, MdPlayArrow, MdStop, MdEditSquare } from "react-icons/md"; 
import { BsFillInboxesFill } from "react-icons/bs";
import { TbLayoutGridRemove } from "react-icons/tb";

const CampaignSetting: React.FC = () => {
  const { addCampaign, addWorkflow, workflows, fetchWorkflows, updateWorkflowStatus, deleteWorkflow  } = useEmail();   
  // table filtering
  const [searchTerm, setSearchTerm] = useState(''); 
  // open modal component
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogicModalOpen, setIsLogicModalOpen] = useState(false);
  const [workflowName, setWorkflowName] = useState(''); 
  const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
  // table action stats
  const [isOnButton, setIsOnButton] = useState<{ [workflowId: number]: boolean }>({});

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0]; 
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Kuala_Lumpur',
    };
    const formattedTime = date.toLocaleString('en-US', options); 
    
    return `${formattedDate}, ${formattedTime}`;
  };

  const handleCreate = (
    campaign: { 
      campTitle: string; 
      campType: string;
      campList: string
      contentTitle: string;
      contentName: string;
      contentBody: string;
      contentButton: string;
      sendFrom: string;
      sendTo: string;
      sendCC: string;
      subjectMail: string;
    }) => {
    addCampaign({ ...campaign });  
    setIsModalOpen(false); 
  };
  
  const handleOpenWorkflowModal = () => {
    setIsWorkflowModalOpen(true);
  };

  const handleAddWorkflow = () => {
    // Add workflow to the backend
    addWorkflow({ workflowName }).then(() => {
      setIsWorkflowModalOpen(false);
      setIsLogicModalOpen(true);
    });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // <<----- Filtering workflow function
  const filteredWorkflows = workflows.filter((workflow) =>
    workflow.workflowName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // <<----- Filtering workflow table action stats
  // Update isOnButton for the specific workflowId
  const handleToggleStatus = (workflowId: number, currentStatus: string) => {
    const newStatus = currentStatus === 'Stop' ? 'Play' : 'Stop'; 
    
    // Update the button UI to reflect the new status
    setIsOnButton((prev) => ({
      ...prev,
      [workflowId]: newStatus === 'Stop', 
    }));
  
    // Call the API to update the workflow status in the backend
    updateWorkflowStatus(workflowId, newStatus).catch((error) => {
      console.error('Failed to update status:', error);
    });
  };
  
  const handleRemoveWorkflow = async (workflowId: number) => {
    try {
      await deleteWorkflow(workflowId);
      await fetchWorkflows();
    } catch (error) {
      console.error('Failed to delete workflow:', error);
    }
  }; 

  // Function to edit a workflow
  const handleEditWorkflow = (workflowId: number, workflowName: string) => {
    setWorkflowName(workflowName); // Set the selected workflow name
    setIsLogicModalOpen(true); // Open the modal for editing the workflow
  };


  return (
    <div>
      <div className="bg-white shadow-md p-6 rounded-lg">
        <h2 className="text-xl font-semibold mb-4">Scheduled Campaigns</h2>
        <div className="mb-4 flex justify-between items-center space-x-2">
          <div className="flex justify-normal items-center relative w-full">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
              <IoMdSearch className="text-2xl text-cyan-800" />
            </span>
            <input
                type="text"
                placeholder="Search by Title or Content"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="border p-2 rounded pl-10 w-full"
            />
          </div>
          <div className="flex justify-end w-full space-x-2">
            <button
                onClick={handleOpenWorkflowModal}
                className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
            >
                <div className="flex justify-center items-center">
                    <MdPlaylistAddCircle className="text-xl mr-2" /> Add Logic
                </div>
            </button>
            <div className="flex flex-normal items-center">
              <button
                  onClick={handleOpenModal}
                  className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
              >
                  <div className="flex justify-center items-center">
                      <MdPlaylistAddCircle className="text-xl mr-2" /> Add Email
                  </div>
              </button>
            </div>
            <div 
              onClick={handleOpenModal}
              className="flex justify-center items-center text-base text-cyan-800 space-x-2"
            >
              <BsFillInboxesFill />
            </div>
          </div>
        </div>

        {/* Single Campaign Table Section */}
        <div className="mb-6">
          <table className="w-full table-full bg-white text-center rounded text-sm">
            <thead>
              <tr className="bg-gray-200 text-cyan-800">
                <th className="px-4 py-2 w-auto">id</th>
                <th className="px-4 py-2 w-auto">Workflow Name</th>
                <th className="px-4 py-2 w-auto">Workflow Status</th>
                <th className="px-4 py-2 w-auto">Created Date</th>
                <th className="px-4 py-2 w-auto">Click Stats</th>
                <th className="px-4 py-2 w-auto">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredWorkflows.length > 0 ? (
                filteredWorkflows.map((workflow) => (
                <tr key={workflow.id} className="border-b border-x hover:bg-gray-100 cursor-pointer">
                  <td className="px-4 py-2">{workflow.workflowId}</td>
                  <td className="px-4 py-2">{workflow.workflowName}</td>
                  <td className="px-4 py-2 flex justify-center items-center">
                    <button
                      onClick={() => handleToggleStatus(workflow.workflowId, workflow.workflowStatus)}
                      className={`flex justify-normal items-center text-sm ${isOnButton[workflow.workflowId] ? 'text-rose-600' : 'text-cyan-800'}`}
                    >
                      {isOnButton[workflow.workflowId] ? (
                        <>
                          <MdStop className="mx-2 cursor-pointer" title="Stop Workflow" />
                          <span>Stop</span>
                        </>
                      ) : (
                        <>
                          <MdPlayArrow className="mx-2 cursor-pointer" title="Play Workflow" />
                          <span>Play</span>
                        </>
                      )}
                    </button>
                  </td>
                  <td className="px-4 py-2">{formatDate(workflow.createdAt.toString())}</td>
                  <td className="px-4 py-2">{workflow.clickedStats}</td>
                  <td>
                    <div 
                      className="flex justify-center items-center text-base text-cyan-800 space-x-2"
                    >
                      {/* Edit icon */}
                      <MdEditSquare 
                        className="cursor-pointer" 
                        onClick={() => handleEditWorkflow(workflow.workflowId, workflow.workflowName)} 
                      />
                      {/* Remove icon */}
                      <TbLayoutGridRemove 
                        className="cursor-pointer" 
                        onClick={() => handleRemoveWorkflow(workflow.workflowId)} 
                      />
                    </div>
                  </td>
                </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="px-4 py-4 border-dashed border-2">
                    No campaigns found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Workflow Modal */}
      {isWorkflowModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
            <div className="flex justify-end">
              <button
                onClick={() => setIsWorkflowModalOpen(false)}
                className="text-rose-500 text-2xl"
              >
                <IoMdCloseCircle />
              </button>
            </div>
            <h2 className="text-xl mb-4">Enter a Workflow Name</h2>
            <input
              type="text"
              value={workflowName}
              onChange={(e) => setWorkflowName(e.target.value)}
              className="border p-2 rounded w-full mb-4"
              placeholder="Workflow Name"
            />
            <div className="flex justify-end space-x-2">
              <button onClick={() => setIsWorkflowModalOpen(false)} className="bg-gray-300 px-4 py-2 rounded">
                Cancel
              </button>
              <button onClick={handleAddWorkflow} className="bg-cyan-800 text-white px-4 py-2 rounded">
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Logic Modal */}
      {isLogicModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-full h-auto max-w-[1000px] max-h-screen">
            <CampaignLogic 
              workflowName={workflowName}
              closeWorkflow={() => setIsLogicModalOpen(false)} 
            />
          </div>
        </div>
      )}

      {/* add Mail Content Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
            <div className="flex justify-end">
              <button
                onClick={handleCloseModal}
                className=" text-rose-500 text-2xl"
              >
                <IoMdCloseCircle />
              </button>
            </div>
            <MailSetting onCreate={handleCreate} onClose={handleCloseModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignSetting;

// // update code --> 13 sept 2024
// // src/components/admin/CampaignSetting.tsx

// import React, { useState } from 'react';
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';  
// import MailSetting from './MailSetting';
// import CampaignLogic from './CampingLogic';
// import { IoMdCloseCircle, IoMdSearch } from "react-icons/io";
// import { MdPlaylistAddCircle, MdPlayArrow, MdStop } from "react-icons/md"; 
// import { BsFillInboxesFill } from "react-icons/bs";

// const CampaignSetting: React.FC = () => {
//   const {email: campaigns, addCampaign, addWorkflow } = useEmail();  
//   // table filtering
//   const [searchTerm, setSearchTerm] = useState(''); 
//   // open modal component
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isLogicModalOpen, setIsLogicModalOpen] = useState(false);
//   const [workflowName, setWorkflowName] = useState(''); 
//   const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
//   // table action stats
//   const [isOnButton, setIsOnButton] = useState(true);

//   const handleCreate = (
//     campaign: { 
//       campTitle: string; 
//       campType: string;
//       campList: string
//       contentTitle: string;
//       contentName: string;
//       contentBody: string;
//       contentButton: string;
//       sendFrom: string;
//       sendTo: string;
//       sendCC: string;
//       subjectMail: string;
//     }) => {
//     addCampaign({ ...campaign });  
//     setIsModalOpen(false); 
//   };
  
//   const handleOpenWorkflowModal = () => {
//     setIsWorkflowModalOpen(true);
//   };

//   const handleAddWorkflow = () => {
//     // Add workflow to the backend
//     addWorkflow({ workflowName }).then(() => {
//       setIsWorkflowModalOpen(false);
//       setIsLogicModalOpen(true);
//     });
//   };

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };
  
//   const handleCloseLogicModal = () => {
//     setIsLogicModalOpen(false);
//   };

//   const filteredCampaigns = campaigns.filter((campaign) => {
//     return campaign.campTitle.toLowerCase().includes(searchTerm.toLowerCase());
//   });

//   const formatDate = (dateString: string) => {
//     const date = new Date(dateString);
//     const formattedDate = date.toISOString().split('T')[0]; 
//     const options: Intl.DateTimeFormatOptions = {
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//       timeZone: 'Asia/Kuala_Lumpur',
//     };
//     const formattedTime = date.toLocaleString('en-US', options); 
    
//     return `${formattedDate}, ${formattedTime}`;
//   };

//   return (
//     <div>
//       <div className="bg-white shadow-md p-6 rounded-lg">
//         <h2 className="text-xl font-semibold mb-4">Scheduled Campaigns</h2>
//         <div className="mb-4 flex justify-between items-center space-x-2">
//           <div className="flex justify-normal items-center relative w-full">
//             <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
//               <IoMdSearch className="text-2xl text-cyan-800" />
//             </span>
//             <input
//                 type="text"
//                 placeholder="Search by Title or Content"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//                 className="border p-2 rounded pl-10 w-full"
//             />
//           </div>
//           <div className="flex justify-end w-full space-x-2">
//             <button
//                 onClick={handleOpenWorkflowModal}
//                 className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//             >
//                 <div className="flex justify-center items-center">
//                     <MdPlaylistAddCircle className="text-xl mr-2" /> Add Logic
//                 </div>
//             </button>
//             <div className="flex flex-normal items-center">
//                 <button
//                     onClick={handleOpenModal}
//                     className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//                 >
//                     <div className="flex justify-center items-center">
//                         <MdPlaylistAddCircle className="text-xl mr-2" /> Add Email
//                     </div>
//                 </button>
//                 <div 
//                     onClick={handleOpenModal}
//                     className="text-xl text-cyan-800 ml-2"
//                 >
//                     <BsFillInboxesFill />
//                 </div>
//             </div>
//           </div>
//         </div>

//         {/* Single Campaign Table Section */}
//         <div className="mb-6">
//           <table className="w-full table-full bg-white text-center rounded text-sm">
//             <thead>
//               <tr className="bg-gray-200 text-cyan-800">
//                 <th className="px-4 py-2 w-auto">id</th>
//                 <th className="px-4 py-2 w-auto">Workflow Name</th>
//                 <th className="px-4 py-2 w-auto">Workflow Status</th>
//                 <th className="px-4 py-2 w-auto">Scheduled Date</th>
//                 <th className="px-4 py-2 w-auto">Click Stats</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredCampaigns.length > 0 ? (
//                 filteredCampaigns.map((campaign) => (
//                 <tr key={campaign.id} className="border-b border-x hover:bg-gray-100 cursor-pointer">
//                   <td className="px-4 py-2">{campaign.id}</td>
//                   <td className="px-4 py-2">{campaign.campTitle}</td>
//                   <td className="px-4 py-2 flex justify-center items-center">
//                     <button
//                       onClick={() => setIsOnButton(!isOnButton)} // Toggle button state
//                       className={`flex justify-normal items-center text-sm ${isOnButton ? 'text-cyan-800' : 'text-rose-600'}`}
//                     >
//                       {isOnButton ? (
//                         <>
//                           <MdPlayArrow className="mx-2 cursor-pointer" title="Play Workflow" />
//                           <span>Play</span>
//                         </>
//                       ) : (
//                         <>
//                           <MdStop className="mx-2 cursor-pointer" title="Stop Workflow" />
//                           <span>Stop</span>
//                         </>
//                       )}
//                     </button>
//                   </td>
//                   <td className="px-4 py-2">{formatDate('2024-08-25 10:00:')}</td>
//                   <td className="px-4 py-2">10</td>
//                 </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan={6} className="px-4 py-4 border-dashed border-2">
//                     No campaigns found
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>

//       {/* Workflow Modal */}
//       {isWorkflowModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={() => setIsWorkflowModalOpen(false)}
//                 className="text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <h2 className="text-xl mb-4">Enter a Workflow Name</h2>
//             <input
//               type="text"
//               value={workflowName}
//               onChange={(e) => setWorkflowName(e.target.value)}
//               className="border p-2 rounded w-full mb-4"
//               placeholder="Workflow Name"
//             />
//             <div className="flex justify-end space-x-2">
//               <button onClick={() => setIsWorkflowModalOpen(false)} className="bg-gray-300 px-4 py-2 rounded">
//                 Cancel
//               </button>
//               <button onClick={handleAddWorkflow} className="bg-cyan-800 text-white px-4 py-2 rounded">
//                 Add
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Logic Modal */}
//       {isLogicModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-full h-auto max-w-[1000px] max-h-screen">
//             <div className="flex justify-end">
//               <button
//                 onClick={handleCloseLogicModal}
//                 className="text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <CampaignLogic workflowName={workflowName}/> {/* Render the drag-and-drop interface */}
//           </div>
//         </div>
//       )}

//       {/* add Mail Content Modal */}
//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={handleCloseModal}
//                 className=" text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <MailSetting onCreate={handleCreate} onClose={handleCloseModal} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CampaignSetting;

// // udpate code --> 12 sept 2024
// // src/components/admin/CampaignSetting.tsx

// import React, { useState } from 'react';
// import { useEmail } from '../../../hooks/AdminDashboard/useEmailSetting';  
// import MailSetting from './MailSetting';
// import CampaignLogic from './CampingLogic';
// import { IoMdCloseCircle, IoMdSearch } from "react-icons/io";
// import { MdPlaylistAddCircle, MdPlayArrow, MdStop } from "react-icons/md"; 
// import { BsFillInboxesFill } from "react-icons/bs";

// const CampaignSetting: React.FC = () => {
//   const {email: campaigns, addCampaign} = useEmail();  
//   const [searchTerm, setSearchTerm] = useState(''); 
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isLogicModalOpen, setIsLogicModalOpen] = useState(false);
//   const [isOnButton, setIsOnButton] = useState(true);

//   const handleCreate = (
//     campaign: { 
//       campTitle: string; 
//       campType: string;
//       campList: string
//       contentTitle: string;
//       contentName: string;
//       contentBody: string;
//       contentButton: string;
//       sendFrom: string;
//       sendTo: string;
//       sendCC: string;
//       subjectMail: string;
//     }) => {
//     addCampaign({ ...campaign });  
//     setIsModalOpen(false); 
//   };  

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleOpenLogicModal = () => {
//     setIsLogicModalOpen(true);
//   };

//   const handleCloseLogicModal = () => {
//     setIsLogicModalOpen(false);
//   };

//   const filteredCampaigns = campaigns.filter((campaign) => {
//     return campaign.campTitle.toLowerCase().includes(searchTerm.toLowerCase());
//   });

//   const formatDate = (dateString: string) => {
//     const date = new Date(dateString);
//     const formattedDate = date.toISOString().split('T')[0]; 
//     const options: Intl.DateTimeFormatOptions = {
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//       timeZone: 'Asia/Kuala_Lumpur',
//     };
//     const formattedTime = date.toLocaleString('en-US', options); 
    
//     return `${formattedDate}, ${formattedTime}`;
//   };

//   return (
//     <div>
//       <div className="bg-white shadow-md p-6 rounded-lg">
//         <h2 className="text-xl font-semibold mb-4">Scheduled Campaigns</h2>
//         <div className="mb-4 flex justify-between items-center space-x-2">
//           <div className="flex justify-normal items-center relative w-full">
//             <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
//               <IoMdSearch className="text-2xl text-cyan-800" />
//             </span>
//             <input
//                 type="text"
//                 placeholder="Search by Title or Content"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//                 className="border p-2 rounded pl-10 w-full"
//             />
//           </div>
//           <div className="flex justify-end w-full space-x-2">
//             <button
//                 onClick={handleOpenLogicModal}
//                 className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//             >
//                 <div className="flex justify-center items-center">
//                     <MdPlaylistAddCircle className="text-xl mr-2" /> Add Logic
//                 </div>
//             </button>
//             <div className="flex flex-normal items-center">
//                 <button
//                     onClick={handleOpenModal}
//                     className="bg-cyan-800 text-white hover:bg-cyan-900 py-2 px-4 text-center rounded"
//                 >
//                     <div className="flex justify-center items-center">
//                         <MdPlaylistAddCircle className="text-xl mr-2" /> Add Email
//                     </div>
//                 </button>
//                 <div 
//                     onClick={handleOpenModal}
//                     className="text-xl text-cyan-800 ml-2"
//                 >
//                     <BsFillInboxesFill />
//                 </div>
//             </div>
//           </div>
//         </div>

//         {/* Single Campaign Table Section */}
//         <div className="mb-6">
//           <table className="w-full table-full bg-white text-center rounded text-sm">
//             <thead>
//               <tr className="bg-gray-200 text-cyan-800">
//                 <th className="px-4 py-2 w-auto">id</th>
//                 <th className="px-4 py-2 w-auto">Workflow Name</th>
//                 <th className="px-4 py-2 w-auto">Workflow Status</th>
//                 <th className="px-4 py-2 w-auto">Scheduled Date</th>
//                 <th className="px-4 py-2 w-auto">Click Stats</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredCampaigns.length > 0 ? (
//                 filteredCampaigns.map((campaign) => (
//                 <tr key={campaign.id} className="border-b border-x hover:bg-gray-100 cursor-pointer">
//                   <td className="px-4 py-2">{campaign.id}</td>
//                   <td className="px-4 py-2">{campaign.campTitle}</td>
//                   <td className="px-4 py-2 flex justify-center items-center">
//                     <button
//                       onClick={() => setIsOnButton(!isOnButton)} // Toggle button state
//                       className={`flex justify-normal items-center text-sm ${isOnButton ? 'text-cyan-800' : 'text-rose-600'}`}
//                     >
//                       {isOnButton ? (
//                         <>
//                           <MdPlayArrow className="mx-2 cursor-pointer" title="Play Workflow" />
//                           <span>Play</span>
//                         </>
//                       ) : (
//                         <>
//                           <MdStop className="mx-2 cursor-pointer" title="Stop Workflow" />
//                           <span>Stop</span>
//                         </>
//                       )}
//                     </button>
//                   </td>
//                   <td className="px-4 py-2">{formatDate('2024-08-25 10:00:')}</td>
//                   <td className="px-4 py-2">10</td>
//                 </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan={6} className="px-4 py-4 border-dashed border-2">
//                     No campaigns found
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>

//       {/* Logic Modal */}
//       {isLogicModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-full h-auto max-w-[1000px] max-h-screen">
//             <div className="flex justify-end">
//               <button
//                 onClick={handleCloseLogicModal}
//                 className="text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <CampaignLogic /> {/* Render the drag-and-drop interface */}
//           </div>
//         </div>
//       )}

//       {/* add Mail Content Modal */}
//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white p-6 rounded-lg w-auto h-auto max-w-screen max-h-screen relative">
//             <div className="flex justify-end">
//               <button
//                 onClick={handleCloseModal}
//                 className=" text-rose-500 text-2xl"
//               >
//                 <IoMdCloseCircle />
//               </button>
//             </div>
//             <MailSetting onCreate={handleCreate} onClose={handleCloseModal} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CampaignSetting;
