// src/components/admin/SubscriptionSetting/SubSettingGroupSet.tsx

import React, { useState } from 'react';
import { FaUserGroup } from "react-icons/fa6";
import { IoSearchCircleSharp } from "react-icons/io5";
import { GoTriangleRight, GoTriangleLeft } from "react-icons/go";
import { useSubscriptionGroupSet } from '../../../hooks/AdminDashboard/useSubSettingGroupSet';

interface SpecifyGroupModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onCreateGroup: (selectedEmails: string[]) => void;
  setGroupName: (name: string) => void;
  groupName: string;
}

const SpecifyGroupModal: React.FC<SpecifyGroupModalProps> = ({ isOpen, onRequestClose, onCreateGroup, setGroupName, groupName }) => {
  const { filteredEmails, searchQuery, handleSearchChange } = useSubscriptionGroupSet();
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [displayCount, setDisplayCount] = useState(10); 

  const handleSelectEmail = (email: string) => {
    if (!selectedEmails.includes(email)) {
      setSelectedEmails([...selectedEmails, email]);
    }
    handleSearchChange(searchQuery); 
  };

  const handleDeselectEmail = (email: string) => {
    setSelectedEmails(selectedEmails.filter(item => item !== email));
    handleSearchChange(searchQuery); 
  };

  const handleAddEmails = () => {
    const emailsToAdd = filteredEmails.filter(email => !selectedEmails.includes(email));
    setSelectedEmails([...selectedEmails, ...emailsToAdd]);
  };

  const handleRemoveEmails = () => {
    const emailsToRemove = selectedEmails.filter(email => filteredEmails.includes(email));
    setSelectedEmails(selectedEmails.filter(email => !emailsToRemove.includes(email)));
  };

  const handleCreateGroup = () => {
    onCreateGroup(selectedEmails); 
    onRequestClose();
  };
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-3xl shadow-lg" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-normal items-center p-4">
          <FaUserGroup className="text-cyan-800 text-xl"/>
          <div className="mx-2 text-xl text-cyan-800 font-semibold">Create a Group Set</div>
        </div>

        {/* Group Name Input Field */}
        <div className="mb-4">
          <label className="block text-sm font-semibold text-gray-700">Group Name:</label>
          <input
            type="text"
            value={groupName} 
            onChange={(e) => setGroupName(e.target.value)} 
            className="w-full px-2 py-2 border rounded"
            placeholder="Enter Group Name"
          />
        </div>

        <div className="flex">
          <div className="flex-1 border border-gray-300 p-4 rounded-md">
            <h3 className="text-lg text-cyan-800 font-semibold mb-4">Emails List</h3>
            <div className="flex justify-normal items-center">
              <input 
                type="text" 
                placeholder="Search emails..." 
                value={searchQuery} 
                onChange={(e) => handleSearchChange(e.target.value)} 
                className="w-full px-2 py-2 mb-4 border rounded"
              />
              <IoSearchCircleSharp className="text-cyan-800 text-[40px] mb-4 ml-2" />
            </div>
            <ul className="max-h-72 overflow-y-auto space-y-2">
              {filteredEmails.slice(0, displayCount).map(email => (
                <li 
                  key={email} 
                  className="cursor-pointer p-2 border-b border-gray-200 hover:bg-gray-100"
                  onClick={() => handleSelectEmail(email)}
                >
                  {email}
                </li>
              ))}
            </ul>
            {/* Only show "Load More Emails" if there are more emails to load */}
            {displayCount < filteredEmails.length && (
              <div className="text-center mt-4">
                <button
                  className="px-4 py-2 bg-cyan-800 text-white rounded hover:bg-cyan-900"
                  onClick={() => setDisplayCount(displayCount + 10)}
                >
                  Load More Emails
                </button>
              </div>
            )}
          </div>
          <div className="flex flex-col justify-center items-center text-center mx-4">
            <button 
              className="mb-2 p-2 bg-cyan-800 text-white rounded hover:bg-cyan-900"
              onClick={handleRemoveEmails}
            >
              <GoTriangleLeft />
            </button>
            <button
              className="p-2 bg-cyan-800 text-white rounded hover:bg-cyan-900"
              onClick={handleAddEmails}
            >
              <GoTriangleRight/> 
            </button>
          </div>
          <div className="flex-1 border border-gray-300 p-4 rounded">
            <h3 className="text-lg text-cyan-800 font-semibold mb-4">Selected Emails</h3>
            <ul className="max-h-72 overflow-y-auto space-y-2">
              {selectedEmails.map(email => (
                <li 
                  key={email} 
                  className="cursor-pointer p-2 border-b border-gray-200 hover:bg-gray-100"
                  onClick={() => handleDeselectEmail(email)}
                >
                  {email}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex justify-between items-center mt-6">
          <span>Total Email Lists: {filteredEmails.length}</span>
          <span className="ml-4">Total Selected Emails: {selectedEmails.length}</span>
          <div>
            <button 
              className="mr-2 px-4 py-2 bg-cyan-800 text-white rounded hover:bg-cyan-900"
              onClick={handleCreateGroup}
            >
              Create Group
            </button>
            <button 
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={onRequestClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecifyGroupModal;

