// src/components/admin/SubscriptionSetting/VoucherSetting.tsx

// import react components
import React, { useState, useEffect } from 'react';
import { TbEdit, TbTrash } from "react-icons/tb";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { RiCloseCircleLine } from "react-icons/ri";
// import file 
import useVoucherSetting, { Voucher } from '../../../hooks/AdminDashboard/useVoucherSetting'; // Import Voucher
import SpecifyGroupModal from '../../admin/SubscriptionSetting/SubSettingGroupSet';

interface VoucherSettingProps {
  selectedPlan: any;
  setSelectedPlan: React.Dispatch<React.SetStateAction<any>>;
  isPlanEditMode: boolean;
}

const VoucherSetting: React.FC<VoucherSettingProps> = ({ selectedPlan }) => {
  const { saveVoucher, deleteVoucher, fetchVouchersByPlan } = useVoucherSetting();
  // voucher setting
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVoucherEnabled, setIsVoucherEnabled] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState<Voucher | null>(null);
  const [filteredVouchers, setFilteredVouchers] = useState<Voucher[]>([]);
  // voucher modal setting
  const [voucherName, setVoucherName] = useState('');
  const [voucherCode, setVoucherCode] = useState('');
  const [voucherType, setVoucherType] = useState('By Amount');
  const [voucherAmount, setVoucherAmount] = useState('');
  const [voucherPercentage, setVoucherPercentage] = useState('');
  const [voucherPeriod, setVoucherPeriod] = useState(new Date());
  const [voucherDays, setVoucherDays] = useState('');
  const [voucherRedeemCount, setVoucherRedeemCount] = useState('');
  const [voucherRedeemCheck, setVoucherRedeemCheck] = useState('');
  const [voucherStatus, setVoucherStatus] = useState('Running');
  const [voucherMemberType, setVoucherMemberType] = useState<string[]>([]);
  // voucher custom group setting
  const [customGroups, setCustomGroups] = useState<string[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [isEmailListModalOpen, setIsEmailListModalOpen] = useState(false);
  const [isSpecifyGroupModalOpen, setIsSpecifyGroupModalOpen] = useState(false);
  const [groupName, setGroupName] = useState<string>('');

  useEffect(() => {
    if (selectedPlan?.id) { 
      fetchVouchersByPlan(selectedPlan.id).then((vouchers) => {
        setFilteredVouchers(vouchers);
      }).catch((error) => {
        console.error('Error fetching vouchers for selected plan:', error);
      });
    }
  }, [selectedPlan, fetchVouchersByPlan]);   

  const memberTypes = [
    "R One Guest", 
    "R One Member", 
    "R One Dealer", 
    "R One Elite Trader", 
    "R One Master Trader", 
    "R One Certified Trader", 
    "R One Trader Hub",
  ];

  const handleMemberTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    setVoucherMemberType((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((type) => type !== value);
      }
    });
  };

  const handleShowSettings = () => {
    setSelectedVoucher(null);
    setVoucherName('');
    setVoucherCode('');
    setVoucherType('By Amount');
    setVoucherAmount('');
    setVoucherPercentage('');
    setVoucherPeriod(new Date());
    setVoucherDays('');
    setVoucherStatus('Running');
    setVoucherMemberType([]);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveVoucher = async () => {
    const voucherData: Voucher = {
      id: selectedVoucher ? selectedVoucher.id : undefined,
      planID: selectedPlan?.id,
      voucherName,
      voucherCode,
      voucherType,
      voucherAmount: voucherType === 'By Amount' ? voucherAmount : '',
      voucherPercentage: voucherType === 'By Percentage' ? voucherPercentage : '',
      voucherPeriod: voucherPeriod.toISOString(),
      voucherDays,
      voucherStatus,
      voucherMemberType: customGroups,
      voucherRedeemCount: voucherRedeemCount,
      voucherRedeemCheck: voucherRedeemCheck,
      voucherGroupName: selectedVoucher?.voucherGroupName || groupName,
      voucherGroupMember: selectedVoucher?.voucherGroupMember || selectedEmails,
    };
  
    if (!selectedPlan?.id) {
      console.error('planID is required');
      return;
    }
  
    await saveVoucher(voucherData);
    handleCloseModal();
    fetchVouchersByPlan(selectedPlan.id).then((vouchers) => {
      setFilteredVouchers(vouchers);
    });
  };
  
  // Open modal to specify group
  const handleOpenSpecifyGroupModal = () => {
    setIsSpecifyGroupModalOpen(true);
  };

  // Handle group creation
  const handleCreateGroup = (emails: string[]) => {
    if (!customGroups.includes(groupName)) {
      setCustomGroups((prevGroups) => [...prevGroups, groupName]);
    }
    setSelectedEmails(emails);

    setSelectedVoucher((prev: Voucher | null) => {
      if (prev) {
        return {
          ...prev,
          voucherGroupName: groupName,
          voucherGroupMember: emails,
        };
      }
      return prev;
    });

    setIsSpecifyGroupModalOpen(false);
  };

  const handleModifyGroup = (groupName: string) => {
    const targetVoucher = filteredVouchers.find(voucher => voucher.voucherGroupName === groupName);
    if (targetVoucher) {
      setSelectedVoucher(targetVoucher);
      setGroupName(groupName);
      setSelectedEmails(targetVoucher.voucherGroupMember || []);
      setIsSpecifyGroupModalOpen(true);
    }
  };  
  
  const handleRemoveGroup = (groupName: string) => {
    setCustomGroups((prevGroups) => prevGroups.filter((group) => group !== groupName));
    setVoucherMemberType((prevMembers) => prevMembers.filter((type) => type !== groupName));
    setSelectedVoucher((prev: Voucher | null) => {
      if (prev) {
        return {
          ...prev,
          voucherMemberType: prev.voucherMemberType.filter(type => type !== groupName),
          voucherGroupName: prev.voucherGroupName === groupName ? '' : prev.voucherGroupName,  // Reset or retain as needed
          voucherGroupMember: prev.voucherGroupMember.filter(member => member !== groupName),
        };
      }
      return prev;
    });    
  };    

  const handleModifyVoucher = (voucher: Voucher) => {
    setSelectedVoucher(voucher);
    setVoucherName(voucher.voucherName);
    setVoucherCode(voucher.voucherCode);
    setVoucherType(voucher.voucherType);
    setVoucherAmount(voucher.voucherAmount);
    setVoucherPercentage(voucher.voucherPercentage);
    setVoucherPeriod(new Date(voucher.voucherPeriod));
    setVoucherDays(voucher.voucherDays);
    setVoucherStatus(voucher.voucherStatus);
    setVoucherMemberType(voucher.voucherMemberType);
    setVoucherRedeemCount(voucher.voucherRedeemCount);
    setVoucherRedeemCheck(voucher.voucherRedeemCheck); 
    setIsModalOpen(true);
  };
  

  const handleDeleteVoucher = async () => {
    if (selectedVoucher && selectedVoucher.id !== undefined) {
      await deleteVoucher(selectedVoucher.id);
      setSelectedVoucher(null);
      if (selectedPlan?.id) {
        fetchVouchersByPlan(selectedPlan.id).then((vouchers) => {
          setFilteredVouchers(vouchers);
        });
      }
    }
  };

  const handleVoucherTypeChange = (type: string) => {
    setVoucherType(type);
    if (type === 'By Amount') {
      setVoucherPercentage('');
    } else {
      setVoucherAmount('');
    }
  };

  const handleDateChange = (date: Date) => {
    setVoucherPeriod(date);
    const currentDate = new Date();
    const timeDiff = date.getTime() - currentDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    setVoucherDays(daysDiff.toString());
  };

  const handleCustomGroupClick = (group: string) => {
    if (selectedVoucher?.voucherGroupName.includes(group)) {
      // Fetch the emails from the selected voucher's group
      setSelectedEmails(selectedVoucher?.voucherGroupMember || []);
      
      // Open the modal
      setIsEmailListModalOpen(true);
    }
  };  

  return (
    <div>
      {/* Email List Custom Group */}
      {isEmailListModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-1/2 space-y-2">
            <button className="float-right text-2xl" onClick={() => setIsEmailListModalOpen(false)}><RiCloseCircleLine /></button>
            <h2 className="text-xl font-semibold mb-4">
              {selectedVoucher?.voucherGroupName} - Email List
            </h2>
            <div className="border-2 border-cyan-600 rounded p-4">
              <ul>
                {selectedEmails.map((email, index) => (
                  <li key={index} className="text-gray-700 text-center overflow-y-auto">{email}</li>
                ))}
              </ul>
            </div>
            <div className="mt-4 text-gray-700 font-semibold">
              Total Emails: {selectedEmails.length}
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-normal items-center cursor-pointer">
        <h2 className="text-xl font-semibold mb-4">Voucher Setting</h2>
        <span className="text-rose-600 mb-4 mx-2 text-sm"> ( click here to expand & collpese voucher setting )</span>
        <div 
          className="mb-4"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <FaMinus size={24} /> : <FaPlus size={24} />}
        </div>
      </div>

      {isExpanded && (
        <>
          <div className="flex items-center">
            <label className="block mb-2 w-1/3">Plan Voucher Apply:</label>
            <div className="w-2/3 flex items-center justify-between text-sm">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={isVoucherEnabled}
                  onChange={(e) => setIsVoucherEnabled(e.target.checked)}
                  className="mr-2 w-4 h-4 accent-cyan-800"
                />
                <label className="text-black">{isVoucherEnabled ? 'Enabled' : 'Disabled'}</label>
              </div>
              <div className="flex justify-end items-center text-xs">
                <button
                  onClick={handleShowSettings}
                  className={`ml-4 px-4 py-2 rounded-full ${isVoucherEnabled ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-slate-400'}`}
                  disabled={!isVoucherEnabled}
                >
                  + Create Set
                </button>
                <button
                  onClick={handleDeleteVoucher}
                  className={`ml-2 px-4 py-2 rounded-full ${isVoucherEnabled && selectedVoucher ? 'bg-rose-600 text-white' : 'bg-gray-200 text-slate-400'}`}
                  disabled={!isVoucherEnabled || !selectedVoucher}
                >
                  Delete Set
                </button>
              </div>
            </div>
          </div>

          <div className="flex items-center mt-2">
            <label className="w-1/3 font-semibold">Select a Voucher:</label>
            <div className="w-2/3">
              {filteredVouchers.length > 0 ? (
                <div className="grid grid-cols-3 gap-2">
                  {filteredVouchers.map((voucher: Voucher) => (
                    <div
                      key={voucher.id}
                      className={`p-2 border rounded cursor-pointer ${selectedVoucher?.id === voucher.id ? 'border-cyan-800 border-2' : 'text-gray-300'}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        isVoucherEnabled && setSelectedVoucher(voucher);
                      }} 
                      style={{ backgroundColor: isVoucherEnabled ? 'white' : '#EEEDEB' }}
                    >
                      <div className="flex justify-between items-center">
                        <span>{voucher.id} - {voucher.voucherName}</span>
                        <TbEdit
                          className={`text-cyan-800 cursor-pointer ${!isVoucherEnabled ? 'cursor-not-allowed text-gray-300' : 'text-cyan-800'}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (isVoucherEnabled) handleModifyVoucher(voucher);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="p-2 border-2 border-dashed border-gray-300 rounded text-center w-[200px]">
                  No vouchers available
                </div>
              )}
            </div>
          </div>

          <div className="flex mt-2" style={{ color: isVoucherEnabled ? 'black' : 'lightgray' }}>
            <div className="w-1/3">
              <div className="mt-2">
                <p>Voucher Name:</p>
                <p>Voucher Code:</p>
                <p>Voucher Type:</p>
                {selectedVoucher?.voucherType === 'By Amount' && <p>Voucher Amount:</p>}
                {selectedVoucher?.voucherType === 'By Percentage' && <p>Voucher Percentage:</p>}
                <p>Voucher Period:</p>
                <p>Voucher Days:</p>
                <p>Voucher Redeem Count:</p>
                <p>Voucher Redeem Check:</p>
                <p>Voucher Status:</p>
                <p>Voucher Member Type:</p>
              </div>
            </div>
            <div className="w-2/3">
              <div className="mt-2">
                <p>{selectedVoucher ? selectedVoucher.voucherName : 'N/A'}</p>
                <p>{selectedVoucher ? selectedVoucher.voucherCode : 'N/A'}</p>
                <p>{selectedVoucher ? selectedVoucher.voucherType : 'N/A'}</p>
                {selectedVoucher?.voucherType === 'By Amount' && 
                  <p>{selectedVoucher.voucherAmount} USD</p>
                }
                {selectedVoucher?.voucherType === 'By Percentage' && 
                  <p>{selectedVoucher.voucherPercentage} %</p>
                }
                <p>{selectedVoucher ? new Date(selectedVoucher.voucherPeriod).toLocaleDateString() : 'N/A'}</p>
                <p>{selectedVoucher ? selectedVoucher.voucherDays : 'N/A'}</p>
                <p>{selectedVoucher ? selectedVoucher.voucherRedeemCount : 'N/A'}</p>
                <p>{selectedVoucher ? selectedVoucher.voucherRedeemCheck : 'N/A'}</p>
                <p style={{ color: selectedVoucher?.voucherStatus === 'Running' ? 'green' : 'red' }}>
                  {selectedVoucher ? selectedVoucher.voucherStatus : 'N/A'}
                </p>
                <div>
                  {selectedVoucher ? (
                    <>
                      {selectedVoucher.voucherMemberType.map((type) => (
                        <div key={type}>
                          <span
                            className="cursor-pointer text-cyan-800"
                            onClick={() => handleCustomGroupClick(type)}
                          >
                            {type}
                          </span>
                        </div>
                      ))}
                    </>
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
            </div>
          </div>

          {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded shadow-lg w-auto lg:w-[600px] space-y-2">
                <button className="float-right" onClick={handleCloseModal}>X</button>
                <h2 className="text-xl font-semibold mb-4">Voucher Settings</h2>
                <div className="flex items-center">
                  <label className="block mb-2 w-1/3">Voucher Name:</label>
                  <input
                    type="text"
                    className="w-2/3 p-2 border rounded"
                    value={voucherName}
                    onChange={(e) => setVoucherName(e.target.value)}
                  />
                </div>
                <div className="flex items-center">
                  <label className="block mb-2 w-1/3">Voucher Code:</label>
                  <input
                    type="text"
                    className="w-2/3 p-2 border rounded"
                    value={voucherCode}
                    onChange={(e) => setVoucherCode(e.target.value)}
                  />
                </div>
                <div className="flex items-center">
                  <label className="block mb-2 w-1/3">Voucher Type:</label>
                  <select
                    className="w-2/3 p-2 border rounded"
                    value={voucherType}
                    onChange={(e) => handleVoucherTypeChange(e.target.value)}
                  >
                    <option value="By Amount">By Amount</option>
                    <option value="By Percentage">By Percentage</option>
                  </select>
                </div>
                {voucherType === 'By Amount' && (
                  <div className="flex items-center">
                    <label className="block mb-2 w-1/3">Voucher Amount:</label>
                    <input
                      type="number"
                      className="w-2/3 p-2 border rounded"
                      value={voucherAmount}
                      onChange={(e) => setVoucherAmount(e.target.value)}
                      disabled={voucherType !== 'By Amount'}
                    />
                  </div>
                )}
                {voucherType === 'By Percentage' && (
                  <div className="flex items-center">
                    <label className="block mb-2 w-1/3">Voucher Percentage:</label>
                    <input
                      type="number"
                      className="w-2/3 p-2 border rounded"
                      value={voucherPercentage}
                      onChange={(e) => setVoucherPercentage(e.target.value)}
                      disabled={voucherType !== 'By Percentage'}
                    />
                  </div>
                )}
                <div className="flex items-center">
                  <label className="block mb-2 w-1/3">Voucher Period:</label>
                  <input
                    type="date"
                    className="w-[280px] p-2 border rounded"
                    value={voucherPeriod.toISOString().split('T')[0]}
                    onChange={(e) => handleDateChange(new Date(e.target.value))}
                  />
                  <span className="ml-2 text-black">({voucherDays} days)</span>
                </div>
                <div className="flex items-center">
                  <label className="block mb-2 w-1/3">Voucher Redeem Count:</label>
                  <input
                    type="number"
                    className="w-2/3 p-2 border rounded"
                    value={voucherRedeemCount}
                    onChange={(e) => setVoucherRedeemCount(e.target.value)}
                  />
                </div>
                <div className="flex items-center">
                  <label className="block mb-2 w-1/3">Voucher Redeem Check:</label>
                  <input
                    type="number"
                    className="w-2/3 p-2 border rounded"
                    value={voucherRedeemCheck}
                    onChange={(e) => setVoucherRedeemCheck(e.target.value)}
                  />
                </div>
                <div className="flex items-center">
                  <label className="block mb-2 w-1/3">Voucher Status:</label>
                  <select
                    className="w-2/3 p-2 border rounded"
                    value={voucherStatus}
                    onChange={(e) => setVoucherStatus(e.target.value)}
                  >
                    <option value="Running">Running</option>
                    <option value="Expired">Expired</option>
                    <option value="Stopped">Stopped</option>
                  </select>
                </div>
                <div className="flex my-2">
                  <label className="block mt-2 w-1/3">Voucher Member Type:</label>
                  <div className="flex flex-col my-2">
                    {memberTypes.map((type) => (
                      <label key={type} className="flex items-center mr-4 mb-2">
                        <input
                          type="checkbox"
                          value={type}
                          onChange={handleMemberTypeChange}
                          className="mr-2 w-4 h-4 accent-cyan-800"
                          checked={voucherMemberType.includes(type)}
                        />
                        <span className="text-black">
                          {type}
                        </span>
                      </label>
                    ))}
                    {/* Display custom groups */}
                    {customGroups.map((group) => (
                      <div key={group} className="flex items-center justify-between">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            value={group}
                            onChange={handleMemberTypeChange}
                            className="mr-2 w-4 h-4 accent-cyan-800"
                            checked={voucherMemberType.includes(group)}
                          />
                          <span className="text-black">
                            {group}
                          </span>
                        </label>
                        <div className="flex items-center">
                          <TbEdit
                            className="text-cyan-800 cursor-pointer ml-2"
                            onClick={() => handleModifyGroup(group)}
                          />
                          <TbTrash
                            className="text-rose-600 cursor-pointer ml-2"
                            onClick={() => handleRemoveGroup(group)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex justify-end ">
                  <button
                    type="button"
                    className="text-white text-sm px-4 py-1 mx-2 bg-cyan-800 rounded-full"
                    onClick={handleOpenSpecifyGroupModal}
                  >
                    + Add Group
                  </button>
                  <button
                    onClick={handleSaveVoucher}
                    className="text-white text-sm px-4 py-1 bg-cyan-800 rounded-full"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* Specify Group Modal */}
      <SpecifyGroupModal
        isOpen={isSpecifyGroupModalOpen}
        onRequestClose={() => setIsSpecifyGroupModalOpen(false)}
        onCreateGroup={handleCreateGroup}
        setGroupName={setGroupName}
        groupName={groupName}
      />
    </div>
  );
};

export default VoucherSetting;

// // udpate code --> 20 Aug 2024
// // src/components/admin/SubscriptionSetting/VoucherSetting.tsx

// import React, { useState, useEffect } from 'react';
// import useVoucherSetting, { Voucher } from '../../../hooks/AdminDashboard/useVoucherSetting'; // Import Voucher
// import { TbEdit } from "react-icons/tb";
// import { FaPlus, FaMinus } from "react-icons/fa6";

// interface VoucherSettingProps {
//   selectedPlan: any;
//   setSelectedPlan: React.Dispatch<React.SetStateAction<any>>;
//   isPlanEditMode: boolean;
// }

// const VoucherSetting: React.FC<VoucherSettingProps> = ({ selectedPlan }) => {
//   const { saveVoucher, deleteVoucher, fetchVouchersByPlan } = useVoucherSetting();
//   const [isVoucherEnabled, setIsVoucherEnabled] = useState(false);
//   const [selectedVoucher, setSelectedVoucher] = useState<Voucher | null>(null);
//   const [filteredVouchers, setFilteredVouchers] = useState<Voucher[]>([]);
//   // voucher setting
//   const [voucherName, setVoucherName] = useState('');
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherType, setVoucherType] = useState('By Amount');
//   const [voucherAmount, setVoucherAmount] = useState('');
//   const [voucherPercentage, setVoucherPercentage] = useState('');
//   const [voucherPeriod, setVoucherPeriod] = useState(new Date());
//   const [voucherDays, setVoucherDays] = useState('');
//   const [voucherRedeemCount, setVoucherRedeemCount] = useState(''); // Add this state
//   const [voucherStatus, setVoucherStatus] = useState('Running');
//   const [voucherMemberType, setVoucherMemberType] = useState<string[]>([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isExpanded, setIsExpanded] = useState(false);

//   useEffect(() => {
//     if (selectedPlan?.id) { 
//       // console.log('Selected plan:', selectedPlan);
//       fetchVouchersByPlan(selectedPlan.id).then((vouchers) => {
//         // console.log('Fetched vouchers for selected plan:', vouchers);
//         setFilteredVouchers(vouchers);
//       }).catch((error) => {
//         console.error('Error fetching vouchers for selected plan:', error);
//       });
//     }
//   }, [selectedPlan, fetchVouchersByPlan]);   

//   const memberTypes = [
//     "R One Guest", 
//     "R One Member", 
//     "R One Dealer", 
//     "R One Elite Trader", 
//     "R One Master Trader", 
//     "R One Certified Trader", 
//     "R One Trader Hub",
//   ];

//   const handleMemberTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { value, checked } = event.target;
//     setVoucherMemberType((prev) => {
//       if (checked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((type) => type !== value);
//       }
//     });
//   };

//   const handleShowSettings = () => {
//     setSelectedVoucher(null);
//     setVoucherName('');
//     setVoucherCode('');
//     setVoucherType('By Amount');
//     setVoucherAmount('');
//     setVoucherPercentage('');
//     setVoucherPeriod(new Date());
//     setVoucherDays('');
//     setVoucherStatus('Running');
//     setVoucherMemberType([]);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleSaveVoucher = async () => {
//     const voucherData: Voucher = {
//       id: selectedVoucher ? selectedVoucher.id : undefined,
//       planID: selectedPlan?.id,
//       voucherName,
//       voucherCode,
//       voucherType,
//       voucherAmount: voucherType === 'By Amount' ? voucherAmount : '',
//       voucherPercentage: voucherType === 'By Percentage' ? voucherPercentage : '',
//       voucherPeriod: voucherPeriod.toISOString(),
//       voucherDays,
//       voucherStatus,
//       voucherMemberType,
//       voucherRedeemCount: voucherRedeemCount,
//     };
  
//     if (!selectedPlan?.id) {
//       console.error('planID is required');
//       return;
//     }
  
//     await saveVoucher(voucherData);
//     handleCloseModal();
//     fetchVouchersByPlan(selectedPlan.id).then((vouchers) => {
//       setFilteredVouchers(vouchers);
//     });
//   };
  
//   const handleModifyVoucher = (voucher: Voucher) => {
//     setSelectedVoucher(voucher);
//     setVoucherName(voucher.voucherName);
//     setVoucherCode(voucher.voucherCode);
//     setVoucherType(voucher.voucherType);
//     setVoucherAmount(voucher.voucherAmount);
//     setVoucherPercentage(voucher.voucherPercentage);
//     setVoucherPeriod(new Date(voucher.voucherPeriod));
//     setVoucherDays(voucher.voucherDays);
//     setVoucherStatus(voucher.voucherStatus);
//     setVoucherMemberType(voucher.voucherMemberType);
//     setVoucherRedeemCount(voucher.voucherRedeemCount); 
//     setIsModalOpen(true);
//   };
  

//   const handleDeleteVoucher = async () => {
//     if (selectedVoucher && selectedVoucher.id !== undefined) {
//       await deleteVoucher(selectedVoucher.id);
//       setSelectedVoucher(null);
//       if (selectedPlan?.id) {
//         fetchVouchersByPlan(selectedPlan.id).then((vouchers) => {
//           setFilteredVouchers(vouchers);
//         });
//       }
//     }
//   };
   

//   const handleVoucherTypeChange = (type: string) => {
//     setVoucherType(type);
//     if (type === 'By Amount') {
//       setVoucherPercentage('');
//     } else {
//       setVoucherAmount('');
//     }
//   };

//   const handleDateChange = (date: Date) => {
//     setVoucherPeriod(date);
//     const currentDate = new Date();
//     const timeDiff = date.getTime() - currentDate.getTime();
//     const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
//     setVoucherDays(daysDiff.toString());
//   };

//   return (
//     <div>
//       <div className="flex justify-normal items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
//         <h2 className="text-xl font-semibold mb-4">Voucher Setting</h2>
//         <div className="mb-4 mx-4">
//           {isExpanded ? <FaMinus size={24} /> : <FaPlus size={24} />}
//         </div>
//       </div>
//       {isExpanded && (
//         <>
//           <div className="flex items-center">
//             <label className="block mb-2 w-1/3">Plan Voucher Apply:</label>
//             <div className="w-2/3 flex items-center justify-between text-sm">
//               <div className="flex items-center">
//                 <input
//                   type="checkbox"
//                   checked={isVoucherEnabled}
//                   onChange={(e) => setIsVoucherEnabled(e.target.checked)}
//                   className="mr-2 w-4 h-4 bg-white text-cyan-800"
//                 />
//                 <label className="text-black">{isVoucherEnabled ? 'Enabled' : 'Disabled'}</label>
//               </div>
//               <div className="flex justify-end items-center text-xs">
//                 <button
//                   onClick={handleShowSettings}
//                   className={`ml-4 px-4 py-2 rounded-full ${isVoucherEnabled ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isVoucherEnabled}
//                 >
//                   + Create Set
//                 </button>
//                 <button
//                   onClick={handleDeleteVoucher}
//                   className={`ml-2 px-4 py-2 rounded-full ${isVoucherEnabled && selectedVoucher ? 'bg-rose-600 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isVoucherEnabled || !selectedVoucher}
//                 >
//                   Delete Set
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div className="flex items-center mt-2">
//             <label className="w-1/3 font-semibold">Select a Voucher:</label>
//             <div className="w-2/3">
//               {filteredVouchers.length > 0 ? (
//                 <div className="grid grid-cols-3 gap-2">
//                   {filteredVouchers.map((voucher: Voucher) => (
//                     <div
//                       key={voucher.id}
//                       className={`p-2 border rounded cursor-pointer ${selectedVoucher?.id === voucher.id ? 'border-cyan-800 border-2' : 'text-gray-300'}`}
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         isVoucherEnabled && setSelectedVoucher(voucher);
//                       }} 
//                       style={{ backgroundColor: isVoucherEnabled ? 'white' : '#EEEDEB' }}
//                     >
//                       <div className="flex justify-between items-center">
//                         <span>{voucher.id} - {voucher.voucherName}</span>
//                         <TbEdit
//                           className={`text-cyan-800 cursor-pointer ${!isVoucherEnabled ? 'cursor-not-allowed text-gray-300' : 'text-cyan-800'}`}
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             if (isVoucherEnabled) handleModifyVoucher(voucher);
//                           }}
//                         />
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               ) : (
//                 <div className="p-2 border-2 border-dashed border-gray-300 rounded text-center w-[200px]">
//                   No vouchers available
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="flex mt-2" style={{ color: isVoucherEnabled ? 'black' : 'lightgray' }}>
//             <div className="w-1/3">
//               <div className="mt-2">
//                 <p>Voucher Name:</p>
//                 <p>Voucher Code:</p>
//                 <p>Voucher Type:</p>
//                 {selectedVoucher?.voucherType === 'By Amount' && <p>Voucher Amount:</p>}
//                 {selectedVoucher?.voucherType === 'By Percentage' && <p>Voucher Percentage:</p>}
//                 <p>Voucher Period:</p>
//                 <p>Voucher Days:</p>
//                 <p>Voucher Redeem Count:</p>
//                 <p>Voucher Status:</p>
//                 <p>Voucher Member Type:</p>
//               </div>
//             </div>
//             <div className="w-2/3">
//               <div className="mt-2">
//                 <p>{selectedVoucher ? selectedVoucher.voucherName : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherCode : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherType : 'N/A'}</p>
//                 {selectedVoucher?.voucherType === 'By Amount' && 
//                   <p>{selectedVoucher.voucherAmount} USD</p>
//                 }
//                 {selectedVoucher?.voucherType === 'By Percentage' && 
//                   <p>{selectedVoucher.voucherPercentage} %</p>
//                 }
//                 <p>{selectedVoucher ? new Date(selectedVoucher.voucherPeriod).toLocaleDateString() : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherDays : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherRedeemCount : 'N/A'}</p>
//                 <p style={{ color: selectedVoucher?.voucherStatus === 'Running' ? 'green' : 'red' }}>
//                   {selectedVoucher ? selectedVoucher.voucherStatus : 'N/A'}
//                 </p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherMemberType.join(', ') : 'N/A'}</p>
//               </div>
//             </div>
//           </div>

//           {isModalOpen && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//               <div className="bg-white p-6 rounded shadow-lg w-auto lg:w-[600px] space-y-2">
//                 <button className="float-right" onClick={handleCloseModal}>X</button>
//                 <h2 className="text-xl font-semibold mb-4">Voucher Settings</h2>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Name:</label>
//                   <input
//                     type="text"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherName}
//                     onChange={(e) => setVoucherName(e.target.value)}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Code:</label>
//                   <input
//                     type="text"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherCode}
//                     onChange={(e) => setVoucherCode(e.target.value)}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Type:</label>
//                   <select
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherType}
//                     onChange={(e) => handleVoucherTypeChange(e.target.value)}
//                   >
//                     <option value="By Amount">By Amount</option>
//                     <option value="By Percentage">By Percentage</option>
//                   </select>
//                 </div>
//                 {voucherType === 'By Amount' && (
//                   <div className="flex items-center">
//                     <label className="block mb-2 w-1/3">Voucher Amount:</label>
//                     <input
//                       type="number"
//                       className="w-2/3 p-2 border rounded"
//                       value={voucherAmount}
//                       onChange={(e) => setVoucherAmount(e.target.value)}
//                       disabled={voucherType !== 'By Amount'}
//                     />
//                   </div>
//                 )}
//                 {voucherType === 'By Percentage' && (
//                   <div className="flex items-center">
//                     <label className="block mb-2 w-1/3">Voucher Percentage:</label>
//                     <input
//                       type="number"
//                       className="w-2/3 p-2 border rounded"
//                       value={voucherPercentage}
//                       onChange={(e) => setVoucherPercentage(e.target.value)}
//                       disabled={voucherType !== 'By Percentage'}
//                     />
//                   </div>
//                 )}
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Period:</label>
//                   <input
//                     type="date"
//                     className="w-[280px] p-2 border rounded"
//                     value={voucherPeriod.toISOString().split('T')[0]}
//                     onChange={(e) => handleDateChange(new Date(e.target.value))}
//                   />
//                   <span className="ml-2 text-black">({voucherDays} days)</span>
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Redeem Count:</label>
//                   <input
//                     type="number"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherRedeemCount}
//                     onChange={(e) => setVoucherRedeemCount(e.target.value)}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Status:</label>
//                   <select
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherStatus}
//                     onChange={(e) => setVoucherStatus(e.target.value)}
//                   >
//                     <option value="Running">Running</option>
//                     <option value="Expired">Expired</option>
//                     <option value="Stopped">Stopped</option>
//                   </select>
//                 </div>
//                 <div className="flex my-2">
//                   <label className="block mt-2 w-1/3">Voucher Member Type:</label>
//                   <div className="flex flex-col my-2">
//                     {memberTypes.map((type) => (
//                       <label key={type} className="flex items-center mr-4 mb-2">
//                         <input
//                           type="checkbox"
//                           value={type}
//                           onChange={handleMemberTypeChange}
//                           className="mr-2 w-4 h-4"
//                           checked={voucherMemberType.includes(type)}
//                         />
//                         <span className="text-black">
//                           {type}
//                         </span>
//                       </label>
//                     ))}
//                   </div>
//                 </div>
//                 <div className="flex justify-end mt-4">
//                   <button
//                     onClick={handleSaveVoucher}
//                     className="px-4 py-2 bg-cyan-800 text-white rounded"
//                   >
//                     Save
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </>
//       )}
//     </div>
//   );
// };

// export default VoucherSetting;


// // udpate code --> 01 aug 2024 v03
// // src/components/admin/SubscriptionSetting/VoucherSetting.tsx

// import React, { useState, useEffect } from 'react';
// import useVoucherSetting, { Voucher } from '../../../hooks/AdminDashboard/useVoucherSetting'; // Import Voucher
// import { TbEdit } from "react-icons/tb";
// import { FaPlus, FaMinus } from "react-icons/fa6";

// interface VoucherSettingProps {
//   selectedPlan: any;
//   setSelectedPlan: React.Dispatch<React.SetStateAction<any>>;
//   isPlanEditMode: boolean;
// }

// const VoucherSetting: React.FC<VoucherSettingProps> = ({ selectedPlan }) => {
//   const { saveVoucher, deleteVoucher, fetchVouchersByPlan } = useVoucherSetting();
//   const [isVoucherEnabled, setIsVoucherEnabled] = useState(false);
//   const [selectedVoucher, setSelectedVoucher] = useState<Voucher | null>(null);
//   const [filteredVouchers, setFilteredVouchers] = useState<Voucher[]>([]);
//   // voucher setting
//   const [voucherName, setVoucherName] = useState('');
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherType, setVoucherType] = useState('By Amount');
//   const [voucherAmount, setVoucherAmount] = useState('');
//   const [voucherPercentage, setVoucherPercentage] = useState('');
//   const [voucherPeriod, setVoucherPeriod] = useState(new Date());
//   const [voucherDays, setVoucherDays] = useState('');
//   const [voucherRedeemCount, setVoucherRedeemCount] = useState(''); // Add this state
//   const [voucherStatus, setVoucherStatus] = useState('Running');
//   const [voucherMemberType, setVoucherMemberType] = useState<string[]>([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isExpanded, setIsExpanded] = useState(false);

//   useEffect(() => {
//     if (selectedPlan?.id) { 
//       // console.log('Selected plan:', selectedPlan);
//       fetchVouchersByPlan(selectedPlan.id).then((vouchers) => {
//         // console.log('Fetched vouchers for selected plan:', vouchers);
//         setFilteredVouchers(vouchers);
//       }).catch((error) => {
//         console.error('Error fetching vouchers for selected plan:', error);
//       });
//     }
//   }, [selectedPlan, fetchVouchersByPlan]);   

//   const memberTypes = ["R One Guest", "R One Member", "R One Dealer", "R One Elite Trader", "R One Master Trader", "R One Certified Trader", "R One Trader Hub"];

//   const handleMemberTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { value, checked } = event.target;
//     setVoucherMemberType((prev) => {
//       if (checked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((type) => type !== value);
//       }
//     });
//   };

//   const handleShowSettings = () => {
//     setSelectedVoucher(null);
//     setVoucherName('');
//     setVoucherCode('');
//     setVoucherType('By Amount');
//     setVoucherAmount('');
//     setVoucherPercentage('');
//     setVoucherPeriod(new Date());
//     setVoucherDays('');
//     setVoucherStatus('Running');
//     setVoucherMemberType([]);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleSaveVoucher = async () => {
//     const voucherData: Voucher = {
//       id: selectedVoucher ? selectedVoucher.id : undefined,
//       planID: selectedPlan?.id,
//       voucherName,
//       voucherCode,
//       voucherType,
//       voucherAmount: voucherType === 'By Amount' ? voucherAmount : '',
//       voucherPercentage: voucherType === 'By Percentage' ? voucherPercentage : '',
//       voucherPeriod: voucherPeriod.toISOString(),
//       voucherDays,
//       voucherStatus,
//       voucherMemberType,
//       voucherRedeemCount: voucherRedeemCount,
//     };
  
//     if (!selectedPlan?.id) {
//       console.error('planID is required');
//       return;
//     }
  
//     await saveVoucher(voucherData);
//     handleCloseModal();
//     fetchVouchersByPlan(selectedPlan.id).then((vouchers) => {
//       setFilteredVouchers(vouchers);
//     });
//   };

//   // const handleSaveVoucher = async () => {
//   //   const voucherData: Voucher = {
//   //     id: selectedVoucher ? selectedVoucher.id : undefined,
//   //     planID: selectedPlan?.id,
//   //     voucherName,
//   //     voucherCode,
//   //     voucherType,
//   //     voucherAmount: voucherType === 'By Amount' ? voucherAmount : '',
//   //     voucherPercentage: voucherType === 'By Percentage' ? voucherPercentage : '',
//   //     voucherPeriod: voucherPeriod.toISOString(),
//   //     voucherDays,
//   //     voucherStatus,
//   //     voucherMemberType,
//   //     voucherRedeemCount: voucherRedeemCount,
//   //   };
    
//   //   await saveVoucher(voucherData);
//   //   handleCloseModal();
//   //   if (selectedPlan?.id) { // Ensure selectedPlan.id is defined
//   //     fetchVouchersByPlan(selectedPlan.id).then((vouchers) => {
//   //       setFilteredVouchers(vouchers);
//   //     });
//   //   }
//   // };  
  
//   const handleModifyVoucher = (voucher: Voucher) => {
//     setSelectedVoucher(voucher);
//     setVoucherName(voucher.voucherName);
//     setVoucherCode(voucher.voucherCode);
//     setVoucherType(voucher.voucherType);
//     setVoucherAmount(voucher.voucherAmount);
//     setVoucherPercentage(voucher.voucherPercentage);
//     setVoucherPeriod(new Date(voucher.voucherPeriod));
//     setVoucherDays(voucher.voucherDays);
//     setVoucherStatus(voucher.voucherStatus);
//     setVoucherMemberType(voucher.voucherMemberType);
//     setVoucherRedeemCount(voucher.voucherRedeemCount); 
//     setIsModalOpen(true);
//   };
  

//   const handleDeleteVoucher = async () => {
//     if (selectedVoucher && selectedVoucher.id !== undefined) {
//       await deleteVoucher(selectedVoucher.id);
//       setSelectedVoucher(null);
//       if (selectedPlan?.id) {
//         fetchVouchersByPlan(selectedPlan.id).then((vouchers) => {
//           setFilteredVouchers(vouchers);
//         });
//       }
//     }
//   };
   

//   const handleVoucherTypeChange = (type: string) => {
//     setVoucherType(type);
//     if (type === 'By Amount') {
//       setVoucherPercentage('');
//     } else {
//       setVoucherAmount('');
//     }
//   };

//   const handleDateChange = (date: Date) => {
//     setVoucherPeriod(date);
//     const currentDate = new Date();
//     const timeDiff = date.getTime() - currentDate.getTime();
//     const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
//     setVoucherDays(daysDiff.toString());
//   };

//   return (
//     <div>
//       <div className="flex justify-normal items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
//         <h2 className="text-xl font-semibold mb-4">Voucher Setting</h2>
//         <div className="mb-4 mx-4">
//           {isExpanded ? <FaMinus size={24} /> : <FaPlus size={24} />}
//         </div>
//       </div>
//       {isExpanded && (
//         <>
//           <div className="flex items-center">
//             <label className="block mb-2 w-1/3">Plan Voucher Apply:</label>
//             <div className="w-2/3 flex items-center justify-between text-sm">
//               <div className="flex items-center">
//                 <input
//                   type="checkbox"
//                   checked={isVoucherEnabled}
//                   onChange={(e) => setIsVoucherEnabled(e.target.checked)}
//                   className="mr-2 w-4 h-4 bg-white text-cyan-800"
//                 />
//                 <label className="text-black">{isVoucherEnabled ? 'Enabled' : 'Disabled'}</label>
//               </div>
//               <div className="flex justify-end items-center text-xs">
//                 <button
//                   onClick={handleShowSettings}
//                   className={`ml-4 px-4 py-2 rounded-full ${isVoucherEnabled ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isVoucherEnabled}
//                 >
//                   + Create Set
//                 </button>
//                 <button
//                   onClick={handleDeleteVoucher}
//                   className={`ml-2 px-4 py-2 rounded-full ${isVoucherEnabled && selectedVoucher ? 'bg-rose-600 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isVoucherEnabled || !selectedVoucher}
//                 >
//                   Delete Set
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div className="flex items-center mt-2">
//             <label className="w-1/3 font-semibold">Select a Voucher:</label>
//             <div className="w-2/3">
//               {filteredVouchers.length > 0 ? (
//                 <div className="grid grid-cols-3 gap-2">
//                   {filteredVouchers.map((voucher: Voucher) => (
//                     <div
//                       key={voucher.id}
//                       className={`p-2 border rounded cursor-pointer ${selectedVoucher?.id === voucher.id ? 'border-cyan-800 border-2' : 'text-gray-300'}`}
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         isVoucherEnabled && setSelectedVoucher(voucher);
//                       }} 
//                       style={{ backgroundColor: isVoucherEnabled ? 'white' : '#EEEDEB' }}
//                     >
//                       <div className="flex justify-between items-center">
//                         <span>{voucher.id} - {voucher.voucherName}</span>
//                         <TbEdit
//                           className={`text-cyan-800 cursor-pointer ${!isVoucherEnabled ? 'cursor-not-allowed text-gray-300' : 'text-cyan-800'}`}
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             if (isVoucherEnabled) handleModifyVoucher(voucher);
//                           }}
//                         />
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               ) : (
//                 <div className="p-2 border-2 border-dashed border-gray-300 rounded text-center w-[200px]">
//                   No vouchers available
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="flex mt-2" style={{ color: isVoucherEnabled ? 'black' : 'lightgray' }}>
//             <div className="w-1/3">
//               <div className="mt-2">
//                 <p>Voucher Name:</p>
//                 <p>Voucher Code:</p>
//                 <p>Voucher Type:</p>
//                 {selectedVoucher?.voucherType === 'By Amount' && <p>Voucher Amount:</p>}
//                 {selectedVoucher?.voucherType === 'By Percentage' && <p>Voucher Percentage:</p>}
//                 <p>Voucher Period:</p>
//                 <p>Voucher Days:</p>
//                 <p>Voucher Redeem Count:</p>
//                 <p>Voucher Status:</p>
//                 <p>Voucher Member Type:</p>
//               </div>
//             </div>
//             <div className="w-2/3">
//               <div className="mt-2">
//                 <p>{selectedVoucher ? selectedVoucher.voucherName : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherCode : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherType : 'N/A'}</p>
//                 {selectedVoucher?.voucherType === 'By Amount' && <p>{selectedVoucher.voucherAmount} USD</p>}
//                 {selectedVoucher?.voucherType === 'By Percentage' && <p>{selectedVoucher.voucherPercentage} %</p>}
//                 <p>{selectedVoucher ? new Date(selectedVoucher.voucherPeriod).toLocaleDateString() : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherDays : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherRedeemCount : 'N/A'}</p>
//                 <p style={{ color: selectedVoucher?.voucherStatus === 'Running' ? 'green' : 'red' }}>
//                   {selectedVoucher ? selectedVoucher.voucherStatus : 'N/A'}
//                 </p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherMemberType.join(', ') : 'N/A'}</p>
//               </div>
//             </div>
//           </div>

//           {isModalOpen && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//               <div className="bg-white p-6 rounded shadow-lg w-auto lg:w-[600px] space-y-2">
//                 <button className="float-right" onClick={handleCloseModal}>X</button>
//                 <h2 className="text-xl font-semibold mb-4">Voucher Settings</h2>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Name:</label>
//                   <input
//                     type="text"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherName}
//                     onChange={(e) => setVoucherName(e.target.value)}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Code:</label>
//                   <input
//                     type="text"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherCode}
//                     onChange={(e) => setVoucherCode(e.target.value)}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Type:</label>
//                   <select
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherType}
//                     onChange={(e) => handleVoucherTypeChange(e.target.value)}
//                   >
//                     <option value="By Amount">By Amount</option>
//                     <option value="By Percentage">By Percentage</option>
//                   </select>
//                 </div>
//                 {voucherType === 'By Amount' && (
//                   <div className="flex items-center">
//                     <label className="block mb-2 w-1/3">Voucher Amount:</label>
//                     <input
//                       type="number"
//                       className="w-2/3 p-2 border rounded"
//                       value={voucherAmount}
//                       onChange={(e) => setVoucherAmount(e.target.value)}
//                       disabled={voucherType !== 'By Amount'}
//                     />
//                   </div>
//                 )}
//                 {voucherType === 'By Percentage' && (
//                   <div className="flex items-center">
//                     <label className="block mb-2 w-1/3">Voucher Percentage:</label>
//                     <input
//                       type="number"
//                       className="w-2/3 p-2 border rounded"
//                       value={voucherPercentage}
//                       onChange={(e) => setVoucherPercentage(e.target.value)}
//                       disabled={voucherType !== 'By Percentage'}
//                     />
//                   </div>
//                 )}
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Period:</label>
//                   <input
//                     type="date"
//                     className="w-[280px] p-2 border rounded"
//                     value={voucherPeriod.toISOString().split('T')[0]}
//                     onChange={(e) => handleDateChange(new Date(e.target.value))}
//                   />
//                   <span className="ml-2 text-black">({voucherDays} days)</span>
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Redeem Count:</label>
//                   <input
//                     type="number"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherRedeemCount}
//                     onChange={(e) => setVoucherRedeemCount(e.target.value)}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Status:</label>
//                   <select
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherStatus}
//                     onChange={(e) => setVoucherStatus(e.target.value)}
//                   >
//                     <option value="Running">Running</option>
//                     <option value="Expired">Expired</option>
//                     <option value="Stopped">Stopped</option>
//                   </select>
//                 </div>
//                 <div className="flex my-2">
//                   <label className="block mt-2 w-1/3">Voucher Member Type:</label>
//                   <div className="flex flex-col my-2">
//                     {memberTypes.map((type) => (
//                       <label key={type} className="flex items-center mr-4 mb-2">
//                         <input
//                           type="checkbox"
//                           value={type}
//                           onChange={handleMemberTypeChange}
//                           className="mr-2 w-4 h-4"
//                           checked={voucherMemberType.includes(type)}
//                         />
//                         <span className="text-black">
//                           {type}
//                         </span>
//                       </label>
//                     ))}
//                   </div>
//                 </div>
//                 <div className="flex justify-end mt-4">
//                   <button
//                     onClick={handleSaveVoucher}
//                     className="px-4 py-2 bg-cyan-800 text-white rounded"
//                   >
//                     Save
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </>
//       )}
//     </div>
//   );
// };

// export default VoucherSetting;


// // update code --> 01 aug 2024 v02
// // src/components/admin/SubscriptionSetting/VoucherSetting.tsx

// import React, { useState, useEffect } from 'react';
// import useVoucherSetting, { Voucher } from '../../../hooks/AdminDashboard/useVoucherSetting'; // Import Voucher
// import { TbEdit } from "react-icons/tb";
// import { FaPlus, FaMinus } from "react-icons/fa6";

// interface VoucherSettingProps {
//   selectedPlan: any;
//   setSelectedPlan: React.Dispatch<React.SetStateAction<any>>;
//   isPlanEditMode: boolean;
// }

// const VoucherSetting: React.FC<VoucherSettingProps> = ({ selectedPlan }) => {
//   const { saveVoucher, deleteVoucher, fetchVouchers, fetchVouchersByPlan } = useVoucherSetting();
//   const [isVoucherEnabled, setIsVoucherEnabled] = useState(false);
//   const [selectedVoucher, setSelectedVoucher] = useState<Voucher | null>(null);
//   const [filteredVouchers, setFilteredVouchers] = useState<Voucher[]>([]);
//   // voucher setting
//   const [voucherName, setVoucherName] = useState('');
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherType, setVoucherType] = useState('By Amount');
//   const [voucherAmount, setVoucherAmount] = useState('');
//   const [voucherPercentage, setVoucherPercentage] = useState('');
//   const [voucherPeriod, setVoucherPeriod] = useState(new Date());
//   const [voucherDays, setVoucherDays] = useState('');
//   const [voucherRedeemCount, setVoucherRedeemCount] = useState(''); // Add this state
//   const [voucherStatus, setVoucherStatus] = useState('Running');
//   const [voucherMemberType, setVoucherMemberType] = useState<string[]>([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isExpanded, setIsExpanded] = useState(false);

//   useEffect(() => {
//     if (selectedPlan) {
//       console.log('Selected plan:', selectedPlan);
//       fetchVouchersByPlan(selectedPlan.id).then((vouchers) => {
//         console.log('Fetched vouchers for selected plan:', vouchers);
//         setFilteredVouchers(vouchers);
//       }).catch((error) => {
//         console.error('Error fetching vouchers for selected plan:', error);
//       });
//     }
//   }, [selectedPlan, fetchVouchersByPlan]);  

//   const memberTypes = ["R One Guest", "R One Member", "R One Dealer", "R One Elite Trader", "R One Master Trader", "R One Certified Trader", "R One Trader Hub"];

//   const handleMemberTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { value, checked } = event.target;
//     setVoucherMemberType((prev) => {
//       if (checked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((type) => type !== value);
//       }
//     });
//   };

//   const handleShowSettings = () => {
//     setSelectedVoucher(null);
//     setVoucherName('');
//     setVoucherCode('');
//     setVoucherType('By Amount');
//     setVoucherAmount('');
//     setVoucherPercentage('');
//     setVoucherPeriod(new Date());
//     setVoucherDays('');
//     setVoucherStatus('Running');
//     setVoucherMemberType([]);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleSaveVoucher = async () => {
//     const voucherData: Voucher = {
//       id: selectedVoucher ? selectedVoucher.id : undefined,
//       planID: selectedPlan?.id,
//       voucherName,
//       voucherCode,
//       voucherType,
//       voucherAmount: voucherType === 'By Amount' ? voucherAmount : '',
//       voucherPercentage: voucherType === 'By Percentage' ? voucherPercentage : '',
//       voucherPeriod: voucherPeriod.toISOString(),
//       voucherDays,
//       voucherStatus,
//       voucherMemberType,
//       voucherRedeemCount: voucherRedeemCount,
//     };
//     await saveVoucher(voucherData);
//     handleCloseModal();
//     fetchVouchers().then((vouchers) => {
//       setFilteredVouchers(vouchers);
//     });
//   };
  
//   const handleModifyVoucher = (voucher: Voucher) => {
//     setSelectedVoucher(voucher);
//     setVoucherName(voucher.voucherName);
//     setVoucherCode(voucher.voucherCode);
//     setVoucherType(voucher.voucherType);
//     setVoucherAmount(voucher.voucherAmount);
//     setVoucherPercentage(voucher.voucherPercentage);
//     setVoucherPeriod(new Date(voucher.voucherPeriod));
//     setVoucherDays(voucher.voucherDays);
//     setVoucherStatus(voucher.voucherStatus);
//     setVoucherMemberType(voucher.voucherMemberType);
//     setVoucherRedeemCount(voucher.voucherRedeemCount); // Set this field
//     setIsModalOpen(true);
//   };
  

//   const handleDeleteVoucher = async () => {
//     if (selectedVoucher && selectedVoucher.id !== undefined) {
//       await deleteVoucher(selectedVoucher.id);
//       setSelectedVoucher(null);
//       fetchVouchers().then((vouchers) => {
//         setFilteredVouchers(vouchers);
//       });
//     }
//   };  

//   const handleVoucherTypeChange = (type: string) => {
//     setVoucherType(type);
//     if (type === 'By Amount') {
//       setVoucherPercentage('');
//     } else {
//       setVoucherAmount('');
//     }
//   };

//   const handleDateChange = (date: Date) => {
//     setVoucherPeriod(date);
//     const currentDate = new Date();
//     const timeDiff = date.getTime() - currentDate.getTime();
//     const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
//     setVoucherDays(daysDiff.toString());
//   };

//   return (
//     <div>
//       <div className="flex justify-normal items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
//         <h2 className="text-xl font-semibold mb-4">Voucher Setting</h2>
//         <div className="mb-4 mx-4">
//           {isExpanded ? <FaMinus size={24} /> : <FaPlus size={24} />}
//         </div>
//       </div>
//       {isExpanded && (
//         <>
//           <div className="flex items-center">
//             <label className="block mb-2 w-1/3">Plan Voucher Apply:</label>
//             <div className="w-2/3 flex items-center justify-between text-sm">
//               <div className="flex items-center">
//                 <input
//                   type="checkbox"
//                   checked={isVoucherEnabled}
//                   onChange={(e) => setIsVoucherEnabled(e.target.checked)}
//                   className="mr-2 w-4 h-4 bg-white text-cyan-800"
//                 />
//                 <label className="text-black">{isVoucherEnabled ? 'Enabled' : 'Disabled'}</label>
//               </div>
//               <div className="flex justify-end items-center text-xs">
//                 <button
//                   onClick={handleShowSettings}
//                   className={`ml-4 px-4 py-2 rounded-full ${isVoucherEnabled ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isVoucherEnabled}
//                 >
//                   + Create Set
//                 </button>
//                 <button
//                   onClick={handleDeleteVoucher}
//                   className={`ml-2 px-4 py-2 rounded-full ${isVoucherEnabled && selectedVoucher ? 'bg-rose-600 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isVoucherEnabled || !selectedVoucher}
//                 >
//                   Delete Set
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div className="flex items-center mt-2">
//             <label className="w-1/3 font-semibold">Select a Voucher:</label>
//             <div className="w-2/3">
//               {filteredVouchers.length > 0 ? (
//                 <div className="grid grid-cols-3 gap-2">
//                   {filteredVouchers.map((voucher: Voucher) => (
//                     <div
//                       key={voucher.id}
//                       className={`p-2 border rounded cursor-pointer ${selectedVoucher?.id === voucher.id ? 'border-cyan-800 border-2' : 'text-gray-300'}`}
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         isVoucherEnabled && setSelectedVoucher(voucher);
//                       }} 
//                       style={{ backgroundColor: isVoucherEnabled ? 'white' : '#EEEDEB' }}
//                     >
//                       <div className="flex justify-between items-center">
//                         <span>{voucher.id} - {voucher.voucherName}</span>
//                         <TbEdit
//                           className={`text-cyan-800 cursor-pointer ${!isVoucherEnabled ? 'cursor-not-allowed text-gray-300' : 'text-cyan-800'}`}
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             if (isVoucherEnabled) handleModifyVoucher(voucher);
//                           }}
//                         />
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               ) : (
//                 <div className="p-2 border-2 border-dashed border-gray-300 rounded text-center w-[200px]">
//                   No vouchers available
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="flex mt-2" style={{ color: isVoucherEnabled ? 'black' : 'lightgray' }}>
//             <div className="w-1/3">
//               <div className="mt-2">
//                 <p>Voucher Name:</p>
//                 <p>Voucher Code:</p>
//                 <p>Voucher Type:</p>
//                 {selectedVoucher?.voucherType === 'By Amount' && <p>Voucher Amount:</p>}
//                 {selectedVoucher?.voucherType === 'By Percentage' && <p>Voucher Percentage:</p>}
//                 <p>Voucher Period:</p>
//                 <p>Voucher Days:</p>
//                 <p>Voucher Redeem Count:</p>
//                 <p>Voucher Status:</p>
//                 <p>Voucher Member Type:</p>
//               </div>
//             </div>
//             <div className="w-2/3">
//               <div className="mt-2">
//                 <p>{selectedVoucher ? selectedVoucher.voucherName : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherCode : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherType : 'N/A'}</p>
//                 {selectedVoucher?.voucherType === 'By Amount' && <p>{selectedVoucher.voucherAmount} USD</p>}
//                 {selectedVoucher?.voucherType === 'By Percentage' && <p>{selectedVoucher.voucherPercentage} %</p>}
//                 <p>{selectedVoucher ? new Date(selectedVoucher.voucherPeriod).toLocaleDateString() : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherDays : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherRedeemCount : 'N/A'}</p>
//                 <p style={{ color: selectedVoucher?.voucherStatus === 'Running' ? 'green' : 'red' }}>
//                   {selectedVoucher ? selectedVoucher.voucherStatus : 'N/A'}
//                 </p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherMemberType.join(', ') : 'N/A'}</p>
//               </div>
//             </div>
//           </div>

//           {isModalOpen && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//               <div className="bg-white p-6 rounded shadow-lg w-auto lg:w-[600px] space-y-2">
//                 <button className="float-right" onClick={handleCloseModal}>X</button>
//                 <h2 className="text-xl font-semibold mb-4">Voucher Settings</h2>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Name:</label>
//                   <input
//                     type="text"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherName}
//                     onChange={(e) => setVoucherName(e.target.value)}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Code:</label>
//                   <input
//                     type="text"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherCode}
//                     onChange={(e) => setVoucherCode(e.target.value)}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Type:</label>
//                   <select
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherType}
//                     onChange={(e) => handleVoucherTypeChange(e.target.value)}
//                   >
//                     <option value="By Amount">By Amount</option>
//                     <option value="By Percentage">By Percentage</option>
//                   </select>
//                 </div>
//                 {voucherType === 'By Amount' && (
//                   <div className="flex items-center">
//                     <label className="block mb-2 w-1/3">Voucher Amount:</label>
//                     <input
//                       type="number"
//                       className="w-2/3 p-2 border rounded"
//                       value={voucherAmount}
//                       onChange={(e) => setVoucherAmount(e.target.value)}
//                       disabled={voucherType !== 'By Amount'}
//                     />
//                   </div>
//                 )}
//                 {voucherType === 'By Percentage' && (
//                   <div className="flex items-center">
//                     <label className="block mb-2 w-1/3">Voucher Percentage:</label>
//                     <input
//                       type="number"
//                       className="w-2/3 p-2 border rounded"
//                       value={voucherPercentage}
//                       onChange={(e) => setVoucherPercentage(e.target.value)}
//                       disabled={voucherType !== 'By Percentage'}
//                     />
//                   </div>
//                 )}
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Period:</label>
//                   <input
//                     type="date"
//                     className="w-[280px] p-2 border rounded"
//                     value={voucherPeriod.toISOString().split('T')[0]}
//                     onChange={(e) => handleDateChange(new Date(e.target.value))}
//                   />
//                   <span className="ml-2 text-black">({voucherDays} days)</span>
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Redeem Count:</label>
//                   <input
//                     type="number"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherRedeemCount}
//                     onChange={(e) => setVoucherRedeemCount(e.target.value)}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Status:</label>
//                   <select
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherStatus}
//                     onChange={(e) => setVoucherStatus(e.target.value)}
//                   >
//                     <option value="Running">Running</option>
//                     <option value="Expired">Expired</option>
//                     <option value="Stopped">Stopped</option>
//                   </select>
//                 </div>
//                 <div className="flex my-2">
//                   <label className="block mt-2 w-1/3">Voucher Member Type:</label>
//                   <div className="flex flex-col my-2">
//                     {memberTypes.map((type) => (
//                       <label key={type} className="flex items-center mr-4 mb-2">
//                         <input
//                           type="checkbox"
//                           value={type}
//                           onChange={handleMemberTypeChange}
//                           className="mr-2 w-4 h-4"
//                           checked={voucherMemberType.includes(type)}
//                         />
//                         <span className="text-black">
//                           {type}
//                         </span>
//                       </label>
//                     ))}
//                   </div>
//                 </div>
//                 <div className="flex justify-end mt-4">
//                   <button
//                     onClick={handleSaveVoucher}
//                     className="px-4 py-2 bg-cyan-800 text-white rounded"
//                   >
//                     Save
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </>
//       )}
//     </div>
//   );
// };

// export default VoucherSetting;



// // update code --> 01 Aug 2024
// // src/components/admin/SubscriptionSetting/VoucherSetting.tsx

// import React, { useState, useEffect, useCallback  } from 'react';
// import useVoucherSetting from '../../../hooks/AdminDashboard/useVoucherSetting';
// import { TbEdit } from "react-icons/tb";
// import { FaPlus, FaMinus } from "react-icons/fa6";

// interface Voucher {
//   id: number;
//   planID: number;
//   voucherName: string;
//   voucherCode: string;
//   voucherType: string;
//   voucherAmount: string;
//   voucherPercentage: string;
//   voucherPeriod: string;
//   voucherDays: string;
//   voucherStatus: string;
//   voucherMemberType: string[];
// }

// interface VoucherSettingProps {
//   selectedPlan: any;
//   setSelectedPlan: React.Dispatch<React.SetStateAction<any>>;
//   isPlanEditMode: boolean;
// }

// const VoucherSetting: React.FC<VoucherSettingProps> = ({ selectedPlan }) => {
//   // voucher setting section
//   const { saveVoucher, deleteVoucher, fetchVouchers, vouchers } = useVoucherSetting();
//   const [isVoucherEnabled, setIsVoucherEnabled] = useState(selectedPlan?.planVoucherCode !== '');
//   const [selectedVoucher, setSelectedVoucher] = useState<Voucher | null>(null);
//   const [filteredVouchers, setFilteredVouchers] = useState<Voucher[]>([]);

//   // voucher modal setting
//   const [voucherName, setVoucherName] = useState('');
//   const [voucherCode, setVoucherCode] = useState('');
//   const [voucherType, setVoucherType] = useState('By Amount');
//   const [voucherAmount, setVoucherAmount] = useState('');
//   const [voucherPercentage, setVoucherPercentage] = useState('');
//   const [voucherPeriod, setVoucherPeriod] = useState(new Date());
//   const [voucherDays, setVoucherDays] = useState('');
//   const [voucherStatus, setVoucherStatus] = useState('Running');
//   const [voucherMemberType, setVoucherMemberType] = useState<string[]>([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isExpanded, setIsExpanded] = useState(false); 

//   const fetchVouchersData = useCallback(async () => {
//     try {
//       await fetchVouchers();
//     } catch (error) {
//       console.error('Error fetching vouchers:', error);
//     }
//   }, [fetchVouchers]);

//   useEffect(() => {
//     fetchVouchersData();
//   }, [fetchVouchersData]);

//   useEffect(() => {
//     if (selectedPlan && vouchers.length > 0) {  
//       const filtered = vouchers.filter((voucher: Voucher) => {
//         return voucher.planID === selectedPlan.id;
//       });
//       setFilteredVouchers(filtered);
//     }
//   }, [selectedPlan, vouchers]);  

//   const memberTypes = ["R One Guest", "R One Member", "R One Dealer", "R One Elite Trader", "R One Master Trader", "R One Certified Trader", "Trader Hub", "Go4Funds", "180 Programs"];

//   const handleMemberTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { value, checked } = event.target;
//     setVoucherMemberType((prev) => {
//       if (checked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((type) => type !== value);
//       }
//     });
//   };

//   const handleShowSettings = () => {
//     setSelectedVoucher(null);
//     setVoucherName('');
//     setVoucherCode('');
//     setVoucherType('By Amount');
//     setVoucherAmount('');
//     setVoucherPercentage('');
//     setVoucherPeriod(new Date());
//     setVoucherDays('');
//     setVoucherStatus('Running');
//     setVoucherMemberType([]);
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleSaveVoucher = async () => {
//     const voucherData = {
//       id: selectedVoucher ? selectedVoucher.id : undefined,
//       planID: selectedPlan?.id, // Make sure this is correctly set
//       voucherName,
//       voucherCode,
//       voucherType,
//       voucherAmount: voucherType === 'By Amount' ? voucherAmount : '',
//       voucherPercentage: voucherType === 'By Percentage' ? voucherPercentage : '',
//       voucherPeriod,
//       voucherDays,
//       voucherStatus,
//       voucherMemberType,
//     };
//     try {
//       await saveVoucher(voucherData);
//       handleCloseModal();
//       await fetchVouchersData();
//     } catch (error) {
//       console.error('Error saving voucher:', error);
//     }
//   };

//   const handleModifyVoucher = (voucher: Voucher) => {
//     setSelectedVoucher(voucher);
//     setVoucherName(voucher.voucherName);
//     setVoucherCode(voucher.voucherCode);
//     setVoucherType(voucher.voucherType);
//     setVoucherAmount(voucher.voucherAmount);
//     setVoucherPercentage(voucher.voucherPercentage);
//     setVoucherPeriod(new Date(voucher.voucherPeriod));
//     setVoucherDays(voucher.voucherDays);
//     setVoucherStatus(voucher.voucherStatus);
//     setVoucherMemberType(voucher.voucherMemberType);
//     setIsModalOpen(true);
//   };

//   const handleDeleteVoucher = async () => {
//     if (selectedVoucher) {
//       await deleteVoucher(selectedVoucher.id);
//       setSelectedVoucher(null);
//       fetchVouchers();
//     }
//   };

//   const handleVoucherTypeChange = (type: string) => {
//     setVoucherType(type);
//     if (type === 'By Amount') {
//       setVoucherPercentage('');
//     } else {
//       setVoucherAmount('');
//     }
//   };

//   const handleDateChange = (date: Date) => {
//     setVoucherPeriod(date);
//     const currentDate = new Date();
//     const timeDiff = date.getTime() - currentDate.getTime();
//     const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
//     setVoucherDays(daysDiff.toString());
//   };

//   return (
//     <div>
//       <div className="flex justify-normal items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
//         <h2 className="text-xl font-semibold mb-4">Voucher Setting</h2>
//         <div className="mb-4 mx-4">
//           {isExpanded ? <FaMinus size={24} /> : <FaPlus size={24} />}
//         </div>
//       </div>
//       {isExpanded && (
//         <>
//           <div className="flex items-center">
//             <label className="block mb-2 w-1/3">Plan Voucher Apply:</label>
//             <div className="w-2/3 flex items-center justify-between text-sm">
//               <div className="flex items-center">
//                 <input
//                   type="checkbox"
//                   checked={isVoucherEnabled}
//                   onChange={(e) => setIsVoucherEnabled(e.target.checked)}
//                   className="mr-2 w-4 h-4 bg-white text-cyan-800"
//                 />
//                 <label className="text-black">{isVoucherEnabled ? 'Enabled' : 'Disabled'}</label>
//               </div>
//               <div className="flex justify-end items-center text-xs">
//                 <button
//                   onClick={handleShowSettings}
//                   className={`ml-4 px-4 py-2 rounded-full ${isVoucherEnabled ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isVoucherEnabled}
//                 >
//                   + Create Set
//                 </button>
//                 <button
//                   onClick={handleDeleteVoucher}
//                   className={`ml-2 px-4 py-2 rounded-full ${isVoucherEnabled && selectedVoucher ? 'bg-rose-600 text-white' : 'bg-gray-200 text-slate-400'}`}
//                   disabled={!isVoucherEnabled || !selectedVoucher}
//                 >
//                   Delete Set
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div className="flex items-center mt-2">
//             <label className="w-1/3 font-semibold">Select a Voucher:</label>
//             <div className="w-2/3">
//               {filteredVouchers.length > 0 ? (
//                 <div className="grid grid-cols-3 gap-2">
//                   {filteredVouchers.map((voucher: Voucher) => (
//                     <div
//                       key={voucher.id}
//                       className={`p-2 border rounded cursor-pointer ${selectedVoucher?.id === voucher.id ? 'border-cyan-800 border-2' : 'text-gray-300'}`}
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         isVoucherEnabled && setSelectedVoucher(voucher);
//                       }} 
//                       style={{ backgroundColor: isVoucherEnabled ? 'white' : '#EEEDEB' }}
//                     >
//                       <div className="flex justify-between items-center">
//                         <span>{voucher.voucherName}</span>
//                         <TbEdit
//                           className={`text-cyan-800 cursor-pointer ${!isVoucherEnabled ? 'cursor-not-allowed text-gray-300' : 'text-cyan-800'}`}
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             if (isVoucherEnabled) handleModifyVoucher(voucher);
//                           }}
//                         />
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               ) : (
//                 <div className="p-2 border-2 border-dashed border-gray-300 rounded text-center w-[200px]">
//                   No vouchers available
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="flex mt-2" style={{ color: isVoucherEnabled ? 'black' : 'lightgray' }}>
//             <div className="w-1/3">
//               <div className="mt-2">
//                 <p>Voucher Name:</p>
//                 <p>Voucher Code:</p>
//                 <p>Voucher Type:</p>
//                 <p>Voucher Amount:</p>
//                 <p>Voucher Percentage:</p>
//                 <p>Voucher Period:</p>
//                 <p>Voucher Days:</p>
//                 <p>Voucher Status:</p>
//                 <p>Voucher Member Type:</p>
//               </div>
//             </div>
//             <div className="w-2/3">
//               <div className="mt-2">
//                 <p>{selectedVoucher ? selectedVoucher.voucherName : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherCode : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherType : 'N/A'}</p>
//                 <p>{selectedVoucher && selectedVoucher.voucherType === 'By Amount' ? selectedVoucher.voucherAmount : 'N/A'}</p>
//                 <p>{selectedVoucher && selectedVoucher.voucherType === 'By Percentage' ? selectedVoucher.voucherPercentage : 'N/A'}</p>
//                 <p>{selectedVoucher ? new Date(selectedVoucher.voucherPeriod).toLocaleDateString() : 'N/A'}</p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherDays : 'N/A'}</p>
//                 <p style={{ color: selectedVoucher?.voucherStatus === 'Running' ? 'green' : 'red' }}>
//                   {selectedVoucher ? selectedVoucher.voucherStatus : 'N/A'}
//                 </p>
//                 <p>{selectedVoucher ? selectedVoucher.voucherMemberType.join(', ') : 'N/A'}</p>
//               </div>
//             </div>
//           </div>

//           {isModalOpen && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//               <div className="bg-white p-6 rounded shadow-lg w-auto lg:w-[600px] space-y-2">
//                 <button className="float-right" onClick={handleCloseModal}>X</button>
//                 <h2 className="text-xl font-semibold mb-4">Voucher Settings</h2>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Name:</label>
//                   <input
//                     type="text"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherName}
//                     onChange={(e) => setVoucherName(e.target.value)}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Code:</label>
//                   <input
//                     type="text"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherCode}
//                     onChange={(e) => setVoucherCode(e.target.value)}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Type:</label>
//                   <select
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherType}
//                     onChange={(e) => handleVoucherTypeChange(e.target.value)}
//                   >
//                     <option value="By Amount">By Amount</option>
//                     <option value="By Percentage">By Percentage</option>
//                   </select>
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Amount:</label>
//                   <input
//                     type="number"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherAmount}
//                     onChange={(e) => setVoucherAmount(e.target.value)}
//                     disabled={voucherType !== 'By Amount'}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Percentage:</label>
//                   <input
//                     type="number"
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherPercentage}
//                     onChange={(e) => setVoucherPercentage(e.target.value)}
//                     disabled={voucherType !== 'By Percentage'}
//                   />
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Period:</label>
//                   <input
//                     type="date"
//                     className="w-[280px] p-2 border rounded"
//                     value={voucherPeriod.toISOString().split('T')[0]}
//                     onChange={(e) => handleDateChange(new Date(e.target.value))}
//                   />
//                   <span className="ml-2 text-black">({voucherDays} days)</span>
//                 </div>
//                 <div className="flex items-center">
//                   <label className="block mb-2 w-1/3">Voucher Status:</label>
//                   <select
//                     className="w-2/3 p-2 border rounded"
//                     value={voucherStatus}
//                     onChange={(e) => setVoucherStatus(e.target.value)}
//                   >
//                     <option value="Running">Running</option>
//                     <option value="Expired">Expired</option>
//                     <option value="Stopped">Stopped</option>
//                   </select>
//                 </div>
//                 <div className="flex my-2">
//                   <label className="block mt-2 w-1/3">Voucher Member Type:</label>
//                   <div className="flex flex-col my-2">
//                     {memberTypes.map((type) => (
//                       <label key={type} className="flex items-center mr-4 mb-2">
//                         <input
//                           type="checkbox"
//                           value={type}
//                           onChange={handleMemberTypeChange}
//                           className="mr-2 w-4 h-4"
//                           checked={voucherMemberType.includes(type)}
//                         />
//                         <span className="text-black">
//                           {type}
//                         </span>
//                       </label>
//                     ))}
//                   </div>
//                 </div>
//                 <div className="flex justify-end mt-4">
//                   <button
//                     onClick={handleSaveVoucher}
//                     className="px-4 py-2 bg-cyan-800 text-white rounded"
//                   >
//                     Save
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </>
//       )}
//     </div>
//   );
// };

// export default VoucherSetting;