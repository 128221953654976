// src/components/admin/SubscriptionSetting/SubNotificationModal.tsx

import React, { useState, useEffect  } from 'react';
import moment from 'moment-timezone';
import { useEnv } from '../../../context/EnvContext';
import { AiOutlineClose } from 'react-icons/ai';
import useSubNotification, { Notification, Plan } from '../../../hooks/AdminDashboard/useSubNotification';
import ConfirmAlertMessage from '../alertMessage'; 

interface ModifyNotificationModalProps {
  notification: Notification;
  onClose: () => void;
}

const ModifyNotificationModal: React.FC<ModifyNotificationModalProps> = ({ notification, onClose }) => {
  // components stats
  const { apiUrl } = useEnv();
  const { 
    modifyNotification, addNewNotification, 
    formatDate, formatData, 
    getStatusClass, getStatusHubClass,
    fetchPlans, fetchProductNames
  } = useSubNotification();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  // form state
  const [daysCount, setDaysCount] = useState<number | null>(null);
  const [isAddMode, setIsAddMode] = useState(false);
  const [orderType, setOrderType] = useState<'sub' | 'hub' | ''>(''); 
  const [generatedOrderId, setGeneratedOrderId] = useState('');
  const [plans, setPlans] = useState<Plan[]>([]);
  // enable account type when is "hub"
  const [isAccountTypeEnabled, setIsAccountTypeEnabled] = useState(false);
  const [isAccountNumberEnabled, setIsAccountNumberEnabled] = useState(false);
  const [isAccountPasswordEnabled, setIsAccountPasswordEnabled] = useState(false);
  const [isAccountServerEnabled, setIsAccountServerEnabled] = useState(false);
  // alert state
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState<'success' | 'error' | 'confirm'>('confirm');
  const [alertMessage, setAlertMessage] = useState('');
  // formData state
  const [formData, setFormData] = useState<Notification>({
    ...notification,
    planSubDate: formatDate(notification.planSubDate) || '',
    planExpiredDate: notification.planExpiredDate ? formatDate(notification.planExpiredDate) : '',
    expiryDate: notification.expiryDate ? formatDate(notification.expiryDate) : '',
    // Add trial-specific fields for when planType is 'Trial'
    planExpiredTrialDate: notification.planExpiredTrialDate ? formatDate(notification.planExpiredTrialDate) : '',
    planTrialPeriod: notification.planTrialPeriod || '',
    planTrialDays: notification.planTrialDays || '',
    // specifly for traderhub
    userName: notification.userName || '',
  });

  const generateOrderId = (type: string) => {
    const digits = Math.floor(1000 + Math.random() * 9000); 
    const alpha = Math.random().toString(36).substring(2, 6).toUpperCase(); 
    return `${type}-${digits}${alpha}`; 
  };
  
  const generateRandomImageString = () => {
    const randomDigits = Math.floor(1000000000 + Math.random() * 9000000000); 
    return `${randomDigits}.png`;
  };
  
  const handlePaymentReceiptGeneration = () => {
    const randomImageString = generateRandomImageString();
    setFormData((prev: Notification) => ({
      ...prev,
      PaymentReceipts: randomImageString,
    }));
  };
  
  const handleOrderTypeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = e.target.value as 'sub' | 'hub';
    setOrderType(selectedType);
  
    // Generate a new orderID based on the order type
    const newOrderId = generateOrderId(selectedType);
    setGeneratedOrderId(newOrderId);
  
    // Update formData with the new orderID
    setFormData((prev) => ({
      ...prev,
      orderID: newOrderId,
    }));

    console.log('Generated Order ID:', newOrderId);
  
    try {
      const fetchedPlans = await fetchPlans(selectedType); 
      setPlans(fetchedPlans);

    } catch (error) {
      console.error('Failed to fetch plans', error);
    }
  }; 

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    setFormData((prev: Notification) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleConfirmModification = async () => {
    try {
      if (isAddMode) {
        // Get the current time in 'Asia/Kuala_Lumpur' timezone
        const currentTimeInMalaysia = moment().tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm:ss');

        // Find the selected plan based on planID
        const selectedPlan = plans.find(plan => Number(plan.id) === Number(formData.planID));
        const selectedPlanName = selectedPlan ? selectedPlan.planName : '';
        const selectedPlanAmount = selectedPlan ? selectedPlan.planAmount : '';

        // Determine the productName based on the orderID prefix
        let productName = '';
        if (formData.orderID.startsWith('sub-')) {
          productName = 'AlgoMatrix';
        } else if (formData.orderID.startsWith('hub-')) {
          productName = 'Trader Hub';
        }

        const updatedFormData = {
          ...formData,
          orderID: formData.orderID || generatedOrderId,
          productName: productName,
          planName: selectedPlanName,
          planAmount: selectedPlanAmount, 
          planDays: formData.planPeriod,
          planSubDate: currentTimeInMalaysia,
          PaymentReceipts: formData.PaymentReceipts || generateRandomImageString(),
          createdAt: currentTimeInMalaysia,
        };

        // If the orderType is "hub", save the additional fields
        if (orderType === 'hub') {
          updatedFormData.name = formData.userName;
          updatedFormData.email = formData.email;
          updatedFormData.phone = formData.phone;
          updatedFormData.expiryDate = formData.planExpiredDate; 
          updatedFormData.renewalDate = formData.planExpiredDate; 
          updatedFormData.expireDays = formData.planPeriod; 
          updatedFormData.PaymentMethodHub = formData.PaymentMethod; 
          updatedFormData.PaymentReceiptsHub = formData.PaymentReceipts; 
          updatedFormData.planNameHub = selectedPlanName;
          updatedFormData.planPeriodHub = formData.planPeriod;
          updatedFormData.planTypeHub = formData.planType; 
          updatedFormData.planAmountHub = selectedPlanAmount; 
          updatedFormData.accountType = formData.accountType;
          updatedFormData.accountNumber = formData.accountNumber;
          updatedFormData.accountPassword = formData.accountPassword;
          updatedFormData.accountServer = formData.accountServer;
          updatedFormData.status = formData.accountStatus; 
          updatedFormData.termAgreed = formData.termAndCondition; 
        }

        // If the plan type is "Trial", set specific fields for the trial
        if (formData.planType === 'Trial') {
          updatedFormData.planTrialPeriod = formData.planPeriod;
          updatedFormData.planTrialDays = formData.planPeriod;
          updatedFormData.planExpiredTrialDate = formData.planExpiredDate;
        }

        // Call the addNewNotification function with the updated formData
        await addNewNotification(updatedFormData);  
        
        setAlertType('success');
        setAlertMessage('The account has been successfully added.');

      } else {
        await modifyNotification(formData);
        setAlertType('success');
        setAlertMessage('The account has been successfully updated.');
      }

    } catch (error) {
      setAlertType('error');
      setAlertMessage(
        isAddMode
          ? 'Failed to add an account. Please contact System Administrator.'
          : 'Failed to modify the account. Please contact System Administrator.'
      );

    } finally {
      setAlertVisible(true);
    }
  };
 
  const handleSubmit = () => {
    setAlertType('confirm');
    setAlertMessage('Make sure you have enter correct information to update this account');
    setAlertVisible(true);
  };

  const handleImageClick = () => {
    setIsImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  const placeholderImage = "https://via.placeholder.com/150";

  const getProductImageUrl = (image: string | undefined, productName: string | undefined) => {
    if (productName === "Trader Hub") {
      return image ? `${apiUrl}/uploads/forTraderHubReceipts/${image}` : placeholderImage;
    } else {
      return image ? `${apiUrl}/uploads/forAlgoMatrixReceipts/${image}` : placeholderImage;
    }
  };

  useEffect(() => {
    if (formData.productName === 'Trader Hub' && formData.expiryDate) {
      const currentDate = moment().tz('Asia/Kuala_Lumpur').startOf('day');
      const expiredDate = moment.tz(formData.expiryDate, 'Asia/Kuala_Lumpur').startOf('day');
      const diffInDays = expiredDate.diff(currentDate, 'days');
      setDaysCount(diffInDays >= 0 ? diffInDays : 0);
    } else if (formData.planExpiredDate) {
      const currentDate = moment().tz('Asia/Kuala_Lumpur').startOf('day');
      const expiredDate = moment.tz(formData.planExpiredDate, 'Asia/Kuala_Lumpur').startOf('day');
      const diffInDays = expiredDate.diff(currentDate, 'days');
      setDaysCount(diffInDays >= 0 ? diffInDays : 0);
    }
  }, [formData.planExpiredDate, formData.expiryDate, formData.productName]);
   
  // You can also use useEffect to set this conditionally based on notification or other criteria
  useEffect(() => {
    if (!notification.orderID) {
      setIsAddMode(true);
    }
  }, [notification]);

  useEffect(() => {
    fetchProductNames();
  }, [fetchProductNames]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white p-6 rounded-lg shadow-lg w-auto max-w-screen h-auto max-h-screen z-60">
        <div className="flex justify-between items-center mb-4">
          {isAddMode ? 'Added User Subscription Detail Information Data' : 'User Subscription Detail Information Data'}
          <button onClick={onClose}>
            <AiOutlineClose className="text-xl" />
          </button>
        </div>
        <div className="w-full border rounded-md shadow bg-gray-100 py-4 px-12 flex justify-center items-center">
          {/* Status field */}
          <div className="flex justify-between items-center">
            <strong>Status:</strong>
            <div className={`ml-2 py-1 px-6 rounded-full text-xs ${formData.productName === "Trader Hub" ? getStatusHubClass(notification.accountStatus) : getStatusClass(notification.accountStatus)}`}>
              {formatData(notification.accountStatus)}
            </div>
          </div>

          {/* Expired Days remaining field */}
          <div className="flex justify-between items-center ml-10">
            <strong>Expired Days Remaining:</strong>
            <div className="ml-2">{daysCount !== null ? `${daysCount} days` : 'N/A'}</div>
          </div>
        </div>

        <div className="mt-4 grid grid-cols-2 gap-4 w-auto h-auto max-h-[500px] overflow-y-auto">
          
          {/* Order ID field */}
          <div className="flex flex-col">
            {isAddMode ? (
              <div className="flex flex-col">
                <div className="flex justify-between items-center">
                  <label className="font-bold mr-2">Order ID:</label>
                  <select
                    value={orderType}
                    onChange={handleOrderTypeChange}
                    className="w-[150px] border rounded p-1"
                  >
                    <option value="" disabled>Select Type</option>
                    <option value="sub">Sub</option>
                    <option value="hub">Hub</option>
                  </select>
                </div>
                {/* Input field to show Order ID, only editable in Add mode */}
                <input
                  type='text'
                  name='orderID' 
                  value={generatedOrderId || formData.orderID}
                  onChange={handleChange}
                  className="w-full border p-1 rounded text-left"
                  disabled={isAddMode} 
                />
              </div>
            ) : (
              <div className="flex flex-col items-left">
                <label className="font-bold mr-2">Order ID:</label>
                <p className="text-gray-700">{formData.orderID}</p>
              </div>
            )}
          </div>

          {/* Requested By field */}
          {isAddMode ? (
            <div className="flex flex-col mb-2">
              <label className="font-bold mr-2">Requested By:</label>
              <input
                type='text'
                name='userName' 
                value={orderType === 'hub' ? formData.name : formData.userName}
                onChange={handleChange}
                className="w-full border p-1 rounded text-left"
                placeholder="Enter username..."
              />
            </div>
          ) : (
            <div className="mb-2">
              <label className="font-bold">Requested By:</label>
              <p>{formData.userName}</p>
            </div>
          )}

          {/* Email field */}
          {isAddMode ? (
            <div className="flex flex-col mb-2">
              <label className="font-bold">Email:</label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="border p-1 rounded text-left"
                placeholder="Enter user email..."
              />
            </div>
          ) : (
            <div className="mb-2">
              <label className="font-bold">Email:</label>
              <p>{formData.email}</p>
            </div>
          )}

          {orderType === 'hub' && (
            <div className='flex flex-col mb-2'>
              <label className="font-bold">Contact No:</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange} 
                className="border rounded p-1 w-full"
                placeholder="Enter contact number..."
              />
            </div>
          )}

          {/* Conditionally render "Contact No." for 'hub' type
          {(isAddMode || orderType === 'hub') && (
            <div>
              <label className="font-bold">Contact No.</label>
              <input
                type="text"
                name="contactNo"
                value={formData.phone} // Use formData here
                onChange={handleChange} // This will update formData correctly
                className="border rounded p-1 w-full"
                placeholder="Enter contact number..."
              />
            </div>
          )} */}

          {/* Note field */}
          {isAddMode ? (
            <div className="flex flex-col mb-2">
              <label className="font-bold">Note:</label>
              <input
                type="text"
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                className="border p-1 rounded text-left"
                placeholder="Enter notes to remarks..."
              />
            </div>
          ) : (
            <div className="mb-2">
              <label className="font-bold">Note:</label>
              <p>{formData.notes}</p>
            </div>
          )}

          {/* Payment Method field */}
          {isAddMode ? (
            <div className="flex flex-col mb-2">
              <label className="font-bold">Payment Method:</label>
              <select
                name="PaymentMethod"
                value={formData.PaymentMethod}
                onChange={handleChange}
                className="w-full border rounded p-1"
              >
                <option value="">Select Payment Method</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="USDT">USDT (Cryptocurrency)</option>
              </select>
            </div>
          ) : (
            <div className="mb-2">
              <label className="font-bold">Payment Method:</label>
              <p>{formData.PaymentMethod}</p>
            </div>
          )}

          {/* Term and Conditions field */}
          {isAddMode ? (
            <div className="flex flex-col mb-2">
              <label className="font-bold">T&C Apply:</label>
              <select
                name="termAndCondition"
                value={formData.termAndCondition}
                onChange={handleChange}
                className="w-full border rounded p-1"
              >
                <option value="">Select T&C Apply</option>
                <option value="Accepted">Accepted</option>
                <option value="Not Accepted">Not Accepted</option>
              </select>
            </div>
          ) : (
            <div className="mb-2">
              <label className="font-bold">T&C Apply:</label>
              <p>{formData.termAndCondition === "1" ? "Accepted" : "Not Accepted"}</p>
            </div>
          )}

          <div className="border border-gray-300 col-span-2 my-4"></div>
          
          {/* Account ID field */}
          {isAddMode && orderType !== 'hub' ? (
            <div className="flex flex-col mb-2">
              <label className="font-bold">Account ID:</label>
              <input
                type="text"
                value={formData.accountID}
                onChange={handleChange}
                className="w-full border rounded p-1"
                disabled={isAddMode}
                placeholder="Account ID will auto generated on database..."
              />
            </div>
          ) : !isAddMode && (
            <div className="mb-2">
              <label className="font-bold">Account ID:</label>
              <p>{formData.accountID}</p>
            </div>
          )}

          {/* Trader Hub Specific field */}
          {formData.productName === "Trader Hub" ? (
            <>
              {/* Plan Type field */}
              <div className="mb-2">
                <label className="font-bold">Plan Type:</label>
                <select
                  name="planTypeHub"
                  value={formData.planTypeHub}
                  onChange={handleChange}
                  className="w-full border rounded p-1"
                >
                  <option value="Renew">Renew</option>
                  <option value="Subscribe">Subscribe</option>
                </select>
              </div>

              {/* Plan Period field */}
              <div className="mb-2">
                <label className="font-bold">Plan Period (Days):</label>
                <input
                  type="text"
                  name="planPeriodHub"
                  value={formData.planPeriodHub}
                  onChange={handleChange}
                  className="w-full border rounded p-1"
                />
              </div>

              {/* Plan Amount field */}
              <div className="mb-2">
                <label className="font-bold">Plan Amount:</label>
                <input
                  type="text"
                  name="planAmountHub"
                  value={formData.planAmountHub}
                  onChange={handleChange}
                  className="w-full border rounded p-1"
                />
              </div>

              {/* Account Number field */}
              <div className="mb-2">
                <label className="font-bold">Account Number:</label>
                <input
                  type="text"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  className="w-full border rounded p-1"
                />
              </div>

              {/* Account Password field */}
              <div className="mb-2">
                <label className="font-bold">Account Password:</label>
                <input
                  type="text"
                  name="accountPassword"
                  value={formData.accountPassword}
                  onChange={handleChange}
                  className="w-full border rounded p-1"
                />
              </div>

              {/* Account Server field */}
              <div className="mb-2">
                <label className="font-bold">Account Server:</label>
                <input
                  type="text"
                  name="accountServer"
                  value={formData.accountServer}
                  onChange={handleChange}
                  className="w-full border rounded p-1"
                />
              </div>

              {/* Expires On field */}
              <div className="mb-2">
                <label className="font-bold">Expires On:</label>
                  {daysCount !== null && (
                    <span className="ml-2 text-md font-semibold text-rose-600"> {daysCount} Days </span>
                  )}
                <input
                  type="date"
                  name="expiryDate"
                  value={formData.expiryDate}
                  onChange={handleChange}
                  className="w-full border rounded p-1"
                />
              </div>

              {/* Expires Days field */}
              <div className="mb-2">
                <label className="font-bold">Expire Days:</label>
                <input
                  type="text"
                  name="expireDays"
                  value={formData.expireDays}
                  onChange={handleChange}
                  className="w-full border rounded p-1"
                />
              </div>

              {/* status field */}
              <div className="mb-2">
                <label className="font-bold">Subscribe Status:</label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  className="w-full border rounded p-1"
                >
                  <option value="running">Running</option>
                  <option value="stopped">Stopped</option>
                  <option value="pending">Pending</option>
                  <option value="expired">Expired</option>
                </select>
              </div>

              {/* disable trial check field */}
              <div className="mb-2">
              <label className={`font-bold ${true ? 'text-gray-300' : ''}`}>Trial Check:</label>
                <input
                  type="text"
                  name="accountTrialCheck"
                  value="Disabled"
                  className="w-full border rounded p-1 bg-gray-100 text-gray-400 cursor-not-allowed"
                  disabled
                />
              </div>

              {/* disable voucher check field */}
              <div className="mb-2">
                <label className={`font-bold ${true ? 'text-gray-300' : ''}`}>Voucher Check:</label>
                <input
                  type="text"
                  name="accountVoucherCheck"
                  value="Disabled"
                  className="w-full border rounded p-1 bg-gray-100 text-gray-400 cursor-not-allowed"
                  disabled
                />
              </div>
            </>
          ) : (
            <>
              {/* Existing AlgoMarix Fields */}
              {/* Plan ID field */}
              {isAddMode && plans.length > 0 && (
                <div className="mb-2">
                  <label className="font-bold">Plan Name:</label>
                  <select
                    name="planID"
                    value={formData.planID}
                    onChange={handleChange}
                    className="w-full border rounded p-1 py-1.5"
                  >
                    <option value="">Select a Plan Name</option>
                    {plans.map(plan => (
                      <option key={plan.id} value={plan.id}>
                        {plan.planName}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* plan type field */}
              <div className="mb-2">
                <label className="font-bold">Plan Type:</label>
                <select
                  name="planType"
                  value={formData.planType}
                  onChange={handleChange}
                  className="w-full border rounded p-1 py-2"
                >
                  <option value="">Select a Plan Type</option>
                  <option value="Renew">Renew</option>
                  <option value="Subscribe">Subscribe</option>
                  <option value="Trial">Trial</option>
                </select>
              </div>

              {/* Plan Period field */}
              {isAddMode ? (
                <div className="mb-2">
                  <div className="flex justify-between items-center">
                    <label className="font-bold">Plan Period (Days):</label>
                      <select
                        className="w-auto border rounded p-1 ml-2 text-sm"
                        name="planPeriod"
                        value={formData.planPeriod}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            planPeriod: e.target.value,
                          }));
                        }}
                      >
                        <option value="">select a Plan Period</option>
                        <option value="7">7 Days</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="365">365 Days</option>
                        <option value="custom">Specify Count</option>
                      </select>
                    </div>
                    <div className="flex justify-between items-center space-x-1 space-y-1">
                      <input
                        type="number"
                        name="planPeriod"
                        value={formData.planPeriod === "7" || formData.planPeriod === "14" || formData.planPeriod === "30" || formData.planPeriod === "365" ? formData.planPeriod : formData.planPeriod}
                        onChange={handleChange}
                        className="border rounded p-1 w-full"
                        disabled={formData.planPeriod === "7" || formData.planPeriod === "14" || formData.planPeriod === "30" || formData.planPeriod === "365"} 
                      />
                    </div>
                  </div>
                ) : (
                  <div className="mb-2">
                    <label className="font-bold">Plan Period (Days):</label>
                    <p>{formData.planPeriod}</p>
                  </div>
                )}

              {/* account type field */}
              {isAddMode && (orderType === 'hub' || orderType === 'sub') && (
                <div className="mb-2">
                  <div className="flex justify-normal items-center">
                    <label className="font-bold">Account Type:</label>
                    {orderType === 'hub' && (
                      <input
                        type="checkbox"
                        className="mx-2 accent-cyan-800"
                        checked={isAccountTypeEnabled}
                        onChange={(e) => setIsAccountTypeEnabled(e.target.checked)}
                      />
                    )}
                  </div>
                  <select
                    name="accountType"
                    value={formData.accountType}
                    onChange={handleChange}
                    className={`w-full border rounded p-2 ${orderType === 'hub' && !isAccountTypeEnabled ? 'bg-gray-100 text-gray-400' : 'bg-white text-black'}`}
                    disabled={orderType === 'hub' && !isAccountTypeEnabled}
                  >
                    <option value="">Select an Account Type</option>
                    <option value="LIVE">LIVE</option>
                    <option value="DEMO">DEMO</option>
                  </select>
                </div>
              )}

              {/* account number field */}
              {isAddMode && (orderType === 'hub' || orderType === 'sub') && (
                <div className="mb-2">
                  <div className="flex justify-normal items-center">
                    <label className="font-bold">Account Number:</label>
                    {orderType === 'hub' && (
                      <input
                        type="checkbox"
                        className="mx-2 accent-cyan-800"
                        checked={isAccountNumberEnabled}
                        onChange={(e) => setIsAccountNumberEnabled(e.target.checked)}
                      />
                    )}
                  </div>
                  <input
                    type="text"
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleChange}
                    className={`w-full border rounded p-1 ${orderType === 'hub' && !isAccountNumberEnabled ? 'bg-gray-100 text-gray-400' : 'bg-white text-black'}`}
                    disabled={orderType === 'hub' && !isAccountNumberEnabled}
                  />
                </div>
              )}

              {/* account password field */}
              {isAddMode && (orderType === 'hub' || orderType === 'sub') && (
                <div className="mb-2">
                  <div className="flex justify-normal items-center">
                    <label className="font-bold">Account Password:</label>
                    {orderType === 'hub' && (
                      <input
                        type="checkbox"
                        className="mx-2 accent-cyan-800"
                        checked={isAccountPasswordEnabled}
                        onChange={(e) => setIsAccountPasswordEnabled(e.target.checked)}
                      />
                    )}
                  </div>
                  <input
                    type="text"
                    name="accountPassword"
                    value={formData.accountPassword}
                    onChange={handleChange}
                    className={`w-full border rounded p-1 ${orderType === 'hub' && !isAccountPasswordEnabled ? 'bg-gray-100 text-gray-400' : 'bg-white text-black'}`}
                    disabled={orderType === 'hub' && !isAccountPasswordEnabled}
                  />
                </div>
              )}

              {/* account server field */}
              {isAddMode && (orderType === 'hub' || orderType === 'sub') && (
                <div className="mb-2">
                  <div className="flex justify-normal items-center">
                    <label className="font-bold">Account Server:</label>
                    {orderType === 'hub' && (
                      <input
                        type="checkbox"
                        className="mx-2 accent-cyan-800"
                        checked={isAccountServerEnabled}
                        onChange={(e) => setIsAccountServerEnabled(e.target.checked)}
                      />
                    )}
                  </div>
                  <input
                    type="text"
                    name="accountServer"
                    value={formData.accountServer}
                    onChange={handleChange}
                    className={`w-full border rounded p-1 ${orderType === 'hub' && !isAccountServerEnabled ? 'bg-gray-100 text-gray-400' : 'bg-white text-black'}`}
                    disabled={orderType === 'hub' && !isAccountServerEnabled}
                  />
                </div>
              )}

              {/* expires on field */}
              <div className="mb-2">
                <label className="font-bold">Expires On:</label>
                  {daysCount !== null && (
                    <span className="ml-2 text-md font-semibold text-rose-600"> {daysCount} Days </span>
                  )}
                <input
                  type="date"
                  name="planExpiredDate"
                  value={formData.planExpiredDate}
                  onChange={handleChange}
                  className="w-full border rounded p-1"
                />
              </div>

              {/* trial check field */}
              {(isAddMode || !isAddMode) && orderType !== 'hub' && (
                <div className="mb-2">
                  <label className="font-bold">Trial Check:</label>
                  <select
                    name="accountTrialCheck"
                    value={formData.accountTrialCheck}
                    onChange={handleChange}
                    className="w-full border rounded p-1"
                  >
                    <option value="">Select a Status</option>
                    <option value="1">Enable</option>
                    <option value="0">Disable</option>
                  </select>
                </div>
              )}

              {/* voucher check field */}
              {(isAddMode || !isAddMode) && orderType !== 'hub' && (
                <div className="mb-2">
                  <label className="font-bold">Voucher Check:</label>
                  <select
                    name="accountVoucherCheck"
                    value={formData.accountVoucherCheck}
                    onChange={handleChange}
                    className="w-full border rounded p-1"
                  >
                    <option value="">Select a Status</option>
                    <option value="1">Enable</option>
                    <option value="0">Disable</option>
                  </select>
                </div>
              )}

              {/* account status field */}
              <div className="mb-2">
                <label className="font-bold">Subscribe Status:</label>
                <select
                  name="accountStatus"
                  value={formData.accountStatus}
                  onChange={handleChange}
                  className="w-full border rounded p-1"
                >
                  <option value="">Select a Status</option>
                  <option value="Active">Active</option>
                  <option value="Deactive">Deactive</option>
                  <option value="Pending">Pending</option>
                  <option value="Expire">Expire</option>
                </select>
              </div>
            </>
          )}

          {/* payment receipt field */}
          {isAddMode ? (
            <div className="mb-2 flex flex-col items-start">
              <label className="font-bold">Payment Receipt:</label>
              <div className="flex w-full justify-normal items-center border rounded px-2 py-1">
                <button
                  type="button"
                  onClick={handlePaymentReceiptGeneration}
                  className="bg-cyan-800 hover:bg-cyan-900 text-white text-sm px-4 py-1 rounded-md"
                >
                  Generate
                </button>
                {/* Display the generated image string */}
                {formData.PaymentReceipts && (
                  <div className="text-sm text-gray-600 mx-4">
                    {formData.PaymentReceipts}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="mb-2 flex flex-col items-start">
              <label className="font-bold">Payment Receipt:</label>
              <button
                type="button"
                onClick={handleImageClick}
                className="text-blue-500 underline bg-transparent border-none cursor-pointer p-0"
              >
                {formData.PaymentReceipts}
              </button>
            </div>
          )}
        </div>

        {/* Modify and cancel button */}
        <div className="flex justify-end mt-4 space-x-2">
          <button
            className="bg-gray-500 text-white px-6 py-2 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-green-500 text-white px-6 py-2 rounded-md"
            onClick={handleSubmit}
          >
            {isAddMode ? 'Add' : 'Modify'}
          </button>
        </div>
      </div>

      {/* Modal for enlarged image */}
      {isImageModalOpen && (
        <div className="fixed inset-0 z-60 flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative bg-white p-6 rounded-lg shadow-lg w-auto max-w-4xl">
            <img
              src={getProductImageUrl(formData.PaymentReceipts, formData.productName)}
              alt="Payment Receipt"
              className="w-full h-auto rounded-lg"
              onClick={handleCloseImageModal}
            />
          </div>
        </div>
      )}

      {/* Alert Message */}
      <ConfirmAlertMessage
        type={alertType}
        message={alertMessage}
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
        onConfirm={handleConfirmModification}
      />
    </div>
  );
};

export default ModifyNotificationModal;

// // update code --> 05 sept 2024 v02 
// // src/components/admin/SubscriptionSetting/SubNotificationModal.tsx

// import React, { useState, useEffect  } from 'react';
// import moment from 'moment-timezone';
// import { useEnv } from '../../../context/EnvContext';
// import { AiOutlineClose } from 'react-icons/ai';
// import useSubNotification, { Notification, Plan } from '../../../hooks/AdminDashboard/useSubNotification';
// import ConfirmAlertMessage from '../alertMessage'; 

// interface ModifyNotificationModalProps {
//   notification: Notification;
//   onClose: () => void;
// }

// const ModifyNotificationModal: React.FC<ModifyNotificationModalProps> = ({ notification, onClose }) => {
//   // components stats
//   const { apiUrl } = useEnv();
//   const { 
//     modifyNotification, addNewNotification, 
//     formatDate, formatData, 
//     getStatusClass, getStatusHubClass,
//     fetchPlans, fetchProductNames
//   } = useSubNotification();
//   const [isImageModalOpen, setIsImageModalOpen] = useState(false);
//   // form state
//   const [daysCount, setDaysCount] = useState<number | null>(null);
//   const [isAddMode, setIsAddMode] = useState(false);
//   const [orderType, setOrderType] = useState<'sub' | 'hub' | ''>(''); 
//   const [generatedOrderId, setGeneratedOrderId] = useState('');
//   const [plans, setPlans] = useState<Plan[]>([]);
//   // alert state
//   const [alertVisible, setAlertVisible] = useState(false);
//   const [alertType, setAlertType] = useState<'success' | 'error' | 'confirm'>('confirm');
//   const [alertMessage, setAlertMessage] = useState('');
//   // formData state
//   const [formData, setFormData] = useState<Notification>({
//     ...notification,
//     planSubDate: formatDate(notification.planSubDate) || '',
//     planExpiredDate: notification.planExpiredDate ? formatDate(notification.planExpiredDate) : '',
//     expiryDate: notification.expiryDate ? formatDate(notification.expiryDate) : '',
//     // Add trial-specific fields for when planType is 'Trial'
//     planExpiredTrialDate: notification.planExpiredTrialDate ? formatDate(notification.planExpiredTrialDate) : '',
//     planTrialPeriod: notification.planTrialPeriod || '',
//     planTrialDays: notification.planTrialDays || '',
//     // specifly for traderhub
//     userName: notification.userName || '',
//   });

//   useEffect(() => {
//     if (!notification.orderID) {
//       setIsAddMode(true);
//     }
//   }, [notification]);

//   useEffect(() => {
//     fetchProductNames();
//   }, [fetchProductNames]);

//   const generateOrderId = (type: string) => {
//     const digits = Math.floor(1000 + Math.random() * 9000); 
//     const alpha = Math.random().toString(36).substring(2, 6).toUpperCase(); 
//     return `${type}-${digits}${alpha}`; 
//   };
  
//   const generateRandomImageString = () => {
//     const randomDigits = Math.floor(1000000000 + Math.random() * 9000000000); 
//     return `${randomDigits}.png`;
//   };
  
//   const handlePaymentReceiptGeneration = () => {
//     const randomImageString = generateRandomImageString();
//     setFormData((prev: Notification) => ({
//       ...prev,
//       PaymentReceipts: randomImageString,
//     }));
//   };
  
//   const handleOrderTypeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = e.target.value as 'sub' | 'hub';
//     console.log('Selected Order Type:', selectedType);
    
//     setOrderType(selectedType);
  
//     // Generate a new orderID based on the order type
//     const newOrderId = generateOrderId(selectedType);
//     setGeneratedOrderId(newOrderId);
  
//     // Update formData with the new orderID
//     setFormData((prev) => ({
//       ...prev,
//       orderID: newOrderId,
//     }));

//     console.log('Generated Order ID:', newOrderId);
  
//     try {
//       const fetchedPlans = await fetchPlans(selectedType); 
//       console.log('Fetched Plans:', fetchedPlans); 

//       // Update the plans state with fetched plans
//       setPlans(fetchedPlans);
//     } catch (error) {
//       console.error('Failed to fetch plans', error);
//     }
//   };  

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
//     const { name, value } = e.target;
    
//     setFormData((prev: Notification) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handleConfirmModification = async () => {
//     try {
//       if (isAddMode) {
//         // Get the current time in 'Asia/Kuala_Lumpur' timezone
//         const currentTimeInMalaysia = moment().tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm:ss');

//         // Find the selected plan based on planID
//         const selectedPlan = plans.find(plan => Number(plan.id) === Number(formData.planID));
//         const selectedPlanName = selectedPlan ? selectedPlan.planName : '';
//         const selectedPlanAmount = selectedPlan ? selectedPlan.planAmount : '';

//         // Determine the productName based on the orderID prefix
//         let productName = '';
//         if (formData.orderID.startsWith('sub-')) {
//           productName = 'AlgoMatrix';
//         } else if (formData.orderID.startsWith('hub-')) {
//           productName = 'Trader Hub';
//         }

//         // Ensure that the PaymentReceipts is included in the formData
//         const updatedFormData = {
//           ...formData,
//           orderID: formData.orderID || generatedOrderId,
//           productName: productName,
//           planName: selectedPlanName, 
//           planAmount: selectedPlanAmount, 
//           planDays: formData.planPeriod,
//           planSubDate: currentTimeInMalaysia,
//           PaymentReceipts: formData.PaymentReceipts || generateRandomImageString(),
//           createdAt: currentTimeInMalaysia,
//         };

//         // If the plan type is "Trial", set specific fields for the trial
//         if (formData.planType === 'Trial') {
//           updatedFormData.planTrialPeriod = formData.planPeriod;
//           updatedFormData.planTrialDays = formData.planPeriod;
//           updatedFormData.planExpiredTrialDate = formData.planExpiredDate;
//         }

//         // Call the addNewNotification function with the updated formData
//         await addNewNotification(updatedFormData);  
        
//         setAlertType('success');
//         setAlertMessage('The account has been successfully added.');
//       } else {
//         await modifyNotification(formData);
//         setAlertType('success');
//         setAlertMessage('The account has been successfully updated.');
//       }
//     } catch (error) {
//       setAlertType('error');
//       setAlertMessage(
//         isAddMode
//           ? 'Failed to add an account. Please contact System Administrator.'
//           : 'Failed to modify the account. Please contact System Administrator.'
//       );
//     } finally {
//       setAlertVisible(true);
//     }
//   };
 
//   const handleSubmit = () => {
//     setAlertType('confirm');
//     setAlertMessage('Make sure you have enter correct information to update this account');
//     setAlertVisible(true);
//   };

//   const handleImageClick = () => {
//     setIsImageModalOpen(true);
//   };

//   const handleCloseImageModal = () => {
//     setIsImageModalOpen(false);
//   };

//   const placeholderImage = "https://via.placeholder.com/150";

//   const getProductImageUrl = (image: string | undefined, productName: string | undefined) => {
//     if (productName === "Trader Hub") {
//       return image ? `${apiUrl}/uploads/forTraderHubReceipts/${image}` : placeholderImage;
//     } else {
//       return image ? `${apiUrl}/uploads/forAlgoMatrixReceipts/${image}` : placeholderImage;
//     }
//   };

//   useEffect(() => {
//     if (formData.productName === 'Trader Hub' && formData.expiryDate) {
//       const currentDate = moment().tz('Asia/Kuala_Lumpur').startOf('day');
//       const expiredDate = moment.tz(formData.expiryDate, 'Asia/Kuala_Lumpur').startOf('day');
//       const diffInDays = expiredDate.diff(currentDate, 'days');
//       setDaysCount(diffInDays >= 0 ? diffInDays : 0);
//     } else if (formData.planExpiredDate) {
//       const currentDate = moment().tz('Asia/Kuala_Lumpur').startOf('day');
//       const expiredDate = moment.tz(formData.planExpiredDate, 'Asia/Kuala_Lumpur').startOf('day');
//       const diffInDays = expiredDate.diff(currentDate, 'days');
//       setDaysCount(diffInDays >= 0 ? diffInDays : 0);
//     }
//   }, [formData.planExpiredDate, formData.expiryDate, formData.productName]);
   
//   // You can also use useEffect to set this conditionally based on notification or other criteria
//   useEffect(() => {
//     if (!notification.orderID) {
//       setIsAddMode(true);
//     }
//   }, [notification]);

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="relative bg-white p-6 rounded-lg shadow-lg w-auto max-w-screen h-auto max-h-screen z-60">
//         <div className="flex justify-between items-center mb-4">
//           {isAddMode ? 'Added User Subscription Detail Information Data' : 'User Subscription Detail Information Data'}
//           <button onClick={onClose}>
//             <AiOutlineClose className="text-xl" />
//           </button>
//         </div>
//         <div className="w-full border rounded-md shadow bg-gray-100 py-4 px-12 flex justify-center items-center">
//           {/* Status field */}
//           <div className="flex justify-between items-center">
//             <strong>Status:</strong>
//             <div className={`ml-2 py-1 px-6 rounded-full text-xs ${formData.productName === "Trader Hub" ? getStatusHubClass(notification.accountStatus) : getStatusClass(notification.accountStatus)}`}>
//               {formatData(notification.accountStatus)}
//             </div>
//           </div>

//           {/* Expired Days remaining field */}
//           <div className="flex justify-between items-center ml-10">
//             <strong>Expired Days Remaining:</strong>
//             <div className="ml-2">{daysCount !== null ? `${daysCount} days` : 'N/A'}</div>
//           </div>
//         </div>

//         <div className="mt-4 grid grid-cols-2 gap-4 w-auto h-auto max-h-[500px] overflow-y-auto">
          
//           {/* Order ID field */}
//           <div className="flex flex-col">
//             {isAddMode ? (
//               <div className="flex flex-col">
//                 <div className="flex justify-between items-center">
//                   <label className="font-bold mr-2">Order ID:</label>
//                   <select
//                     value={orderType}
//                     onChange={handleOrderTypeChange}
//                     className="w-[150px] border rounded p-1"
//                   >
//                     <option value="" disabled>Select Type</option>
//                     <option value="sub">Sub</option>
//                     <option value="hub">Hub</option>
//                   </select>
//                 </div>
//                 {/* Input field to show Order ID, only editable in Add mode */}
//                 <input
//                   type='text'
//                   name='orderID' 
//                   value={generatedOrderId || formData.orderID}
//                   onChange={handleChange}
//                   className="w-full border p-1 rounded text-left"
//                   disabled={isAddMode} 
//                 />
//               </div>
//             ) : (
//               <div className="flex flex-col items-left">
//                 <label className="font-bold mr-2">Order ID:</label>
//                 <p className="text-gray-700">{formData.orderID}</p>
//               </div>
//             )}
//           </div>

//           {/* Requested By field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold mr-2">Requested By:</label>
//               <input
//                 type='text'
//                 name='userName' 
//                 value={orderType === 'hub' ? formData.name : formData.userName}
//                 onChange={handleChange}
//                 className="w-full border p-1 rounded text-left"
//                 placeholder="Enter username..."
//               />
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Requested By:</label>
//               <p>{formData.userName}</p>
//             </div>
//           )}

//           {/* Email field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">Email:</label>
//               <input
//                 type="text"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 className="border p-1 rounded text-left"
//                 placeholder="Enter user email..."
//               />
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Email:</label>
//               <p>{formData.email}</p>
//             </div>
//           )}

//           {/* Conditionally render "Contact No." for 'hub' type */}
//           {orderType === 'hub' && (
//             <div className='flex flex-col mb-2'>
//               <label className="font-bold">Contact No.</label>
//               <input
//                 type="text"
//                 name="contactNo"
//                 value={formData.phone} // Use formData here
//                 onChange={handleChange} // This will update formData correctly
//                 className="border rounded p-1 w-full"
//                 placeholder="Enter contact number..."
//               />
//             </div>
//           )}

//           {/* Conditionally render "Contact No." for 'hub' type
//           {(isAddMode || orderType === 'hub') && (
//             <div>
//               <label className="font-bold">Contact No.</label>
//               <input
//                 type="text"
//                 name="contactNo"
//                 value={formData.phone} // Use formData here
//                 onChange={handleChange} // This will update formData correctly
//                 className="border rounded p-1 w-full"
//                 placeholder="Enter contact number..."
//               />
//             </div>
//           )} */}

//           {/* Note field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">Note:</label>
//               <input
//                 type="text"
//                 name="notes"
//                 value={formData.notes}
//                 onChange={handleChange}
//                 className="border p-1 rounded text-left"
//                 placeholder="Enter notes to remarks..."
//               />
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Note:</label>
//               <p>{formData.notes}</p>
//             </div>
//           )}

//           {/* Payment Method field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">Payment Method:</label>
//               <select
//                 name="PaymentMethod"
//                 value={formData.PaymentMethod}
//                 onChange={handleChange}
//                 className="w-full border rounded p-1"
//               >
//                 <option value="">Select Payment Method</option>
//                 <option value="Bank Transfer">Bank Transfer</option>
//                 <option value="USDT">USDT (Cryptocurrency)</option>
//               </select>
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Payment Method:</label>
//               <p>{formData.PaymentMethod}</p>
//             </div>
//           )}

//           {/* Term and Conditions field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">T&C Apply:</label>
//               <select
//                 name="termAndCondition"
//                 value={formData.termAndCondition}
//                 onChange={handleChange}
//                 className="w-full border rounded p-1"
//               >
//                 <option value="">Select T&C Apply</option>
//                 <option value="Accepted">Accepted</option>
//                 <option value="Not Accepted">Not Accepted</option>
//               </select>
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">T&C Apply:</label>
//               <p>{formData.termAndCondition === "1" ? "Accepted" : "Not Accepted"}</p>
//             </div>
//           )}

//           <div className="border border-gray-300 col-span-2 my-4"></div>
          
//           {/* Account ID field */}
//           {isAddMode && orderType !== 'hub' ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">Account ID:</label>
//               <input
//                 type="text"
//                 value={formData.accountID}
//                 onChange={handleChange}
//                 className="w-full border rounded p-1"
//                 disabled={isAddMode}
//                 placeholder="Account ID will auto generated on database..."
//               />
//             </div>
//           ) : !isAddMode && (
//             <div className="mb-2">
//               <label className="font-bold">Account ID:</label>
//               <p>{formData.accountID}</p>
//             </div>
//           )}

//           {/* Trader Hub Specific field */}
//           {formData.productName === "Trader Hub" ? (
//             <>
//               {/* Plan Type field */}
//               <div className="mb-2">
//                 <label className="font-bold">Plan Type:</label>
//                 <select
//                   name="planTypeHub"
//                   value={formData.planTypeHub}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="Renew">Renew</option>
//                   <option value="Subscribe">Subscribe</option>
//                 </select>
//               </div>

//               {/* Plan Period field */}
//               <div className="mb-2">
//                 <label className="font-bold">Plan Period (Days):</label>
//                 <input
//                   type="text"
//                   name="planPeriodHub"
//                   value={formData.planPeriodHub}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Plan Amount field */}
//               <div className="mb-2">
//                 <label className="font-bold">Plan Amount:</label>
//                 <input
//                   type="text"
//                   name="planAmountHub"
//                   value={formData.planAmountHub}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Account Number field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Number:</label>
//                 <input
//                   type="text"
//                   name="accountNumber"
//                   value={formData.accountNumber}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Account Password field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Password:</label>
//                 <input
//                   type="text"
//                   name="accountPassword"
//                   value={formData.accountPassword}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Account Server field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Server:</label>
//                 <input
//                   type="text"
//                   name="accountServer"
//                   value={formData.accountServer}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Expires On field */}
//               <div className="mb-2">
//                 <label className="font-bold">Expires On:</label>
//                   {daysCount !== null && (
//                     <span className="ml-2 text-md font-semibold text-rose-600"> {daysCount} Days </span>
//                   )}
//                 <input
//                   type="date"
//                   name="expiryDate"
//                   value={formData.expiryDate}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Expires Days field */}
//               <div className="mb-2">
//                 <label className="font-bold">Expire Days:</label>
//                 <input
//                   type="text"
//                   name="expireDays"
//                   value={formData.expireDays}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* status field */}
//               <div className="mb-2">
//                 <label className="font-bold">Subscribe Status:</label>
//                 <select
//                   name="status"
//                   value={formData.status}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="running">Running</option>
//                   <option value="stopped">Stopped</option>
//                   <option value="pending">Pending</option>
//                   <option value="expired">Expired</option>
//                 </select>
//               </div>

//               {/* disable trial check field */}
//               <div className="mb-2">
//               <label className={`font-bold ${true ? 'text-gray-300' : ''}`}>Trial Check:</label>
//                 <input
//                   type="text"
//                   name="accountTrialCheck"
//                   value="Disabled"
//                   className="w-full border rounded p-1 bg-gray-100 text-gray-400 cursor-not-allowed"
//                   disabled
//                 />
//               </div>

//               {/* disable voucher check field */}
//               <div className="mb-2">
//                 <label className={`font-bold ${true ? 'text-gray-300' : ''}`}>Voucher Check:</label>
//                 <input
//                   type="text"
//                   name="accountVoucherCheck"
//                   value="Disabled"
//                   className="w-full border rounded p-1 bg-gray-100 text-gray-400 cursor-not-allowed"
//                   disabled
//                 />
//               </div>
//             </>
//           ) : (
//             <>
//               {/* Existing AlgoMarix Fields */}
//               {/* Plan ID field */}
//               {isAddMode && plans.length > 0 && (
//                 <div className="mb-2">
//                   <label className="font-bold">Plan Name:</label>
//                   <select
//                     name="planID"
//                     value={formData.planID}
//                     onChange={handleChange}
//                     className="w-full border rounded p-1 py-1.5"
//                   >
//                     <option value="">Select a Plan Name</option>
//                     {plans.map(plan => (
//                       <option key={plan.id} value={plan.id}>
//                         {plan.planName}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               )}

//               {/* plan type field */}
//               <div className="mb-2">
//                 <label className="font-bold">Plan Type:</label>
//                 <select
//                   name="planType"
//                   value={formData.planType}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1 py-2"
//                 >
//                   <option value="">Select a Plan Type</option>
//                   <option value="Renew">Renew</option>
//                   <option value="Subscribe">Subscribe</option>
//                   <option value="Trial">Trial</option>
//                 </select>
//               </div>

//               {/* Plan Period field */}
//               {isAddMode ? (
//                 <div className="mb-2">
//                   <div className="flex justify-between items-center">
//                     <label className="font-bold">Plan Period (Days):</label>
//                       <select
//                         className="w-auto border rounded p-1 ml-2 text-sm"
//                         name="planPeriod"
//                         value={formData.planPeriod}
//                         onChange={(e) => {
//                           setFormData((prev) => ({
//                             ...prev,
//                             planPeriod: e.target.value,
//                           }));
//                         }}
//                       >
//                         <option value="">select a Plan Period</option>
//                         <option value="7">7 Days</option>
//                         <option value="14">14 Days</option>
//                         <option value="30">30 Days</option>
//                         <option value="365">365 Days</option>
//                         <option value="custom">Specify Count</option>
//                       </select>
//                     </div>
//                     <div className="flex justify-between items-center space-x-1 space-y-1">
//                       <input
//                         type="number"
//                         name="planPeriod"
//                         value={formData.planPeriod === "7" || formData.planPeriod === "14" || formData.planPeriod === "30" || formData.planPeriod === "365" ? formData.planPeriod : formData.planPeriod}
//                         onChange={handleChange}
//                         className="border rounded p-1 w-full"
//                         disabled={formData.planPeriod === "7" || formData.planPeriod === "14" || formData.planPeriod === "30" || formData.planPeriod === "365"} 
//                       />
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="mb-2">
//                     <label className="font-bold">Plan Period (Days):</label>
//                     <p>{formData.planPeriod}</p>
//                   </div>
//                 )}

//               {/* account type field */}
//               {isAddMode ? (
//                 <div className="mb-2">
//                   <label className="font-bold">Account Type:</label>
//                   <select
//                     name="accountType"
//                     value={formData.accountType}
//                     onChange={handleChange}
//                     className="w-full border rounded p-1"
//                   >
//                     <option value="">Select an Account Type</option>
//                     <option value="LIVE">LIVE</option>
//                     <option value="DEMO">DEMO</option>
//                   </select>
//                 </div>
//               ) : null}

//               {/* account number field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Number:</label>
//                 <input
//                   type="text"
//                   name="accountNumber"
//                   value={formData.accountNumber}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* account password field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Password:</label>
//                 <input
//                   type="text"
//                   name="accountPassword"
//                   value={formData.accountPassword}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* account server field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Server:</label>
//                 <input
//                   type="text"
//                   name="accountServer"
//                   value={formData.accountServer}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* expires on field */}
//               <div className="mb-2">
//                 <label className="font-bold">Expires On:</label>
//                   {daysCount !== null && (
//                     <span className="ml-2 text-md font-semibold text-rose-600"> {daysCount} Days </span>
//                   )}
//                 <input
//                   type="date"
//                   name="planExpiredDate"
//                   value={formData.planExpiredDate}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* trial check field */}
//               {(isAddMode || !isAddMode) && orderType !== 'hub' && (
//                 <div className="mb-2">
//                   <label className="font-bold">Trial Check:</label>
//                   <select
//                     name="accountTrialCheck"
//                     value={formData.accountTrialCheck}
//                     onChange={handleChange}
//                     className="w-full border rounded p-1"
//                   >
//                     <option value="">Select a Status</option>
//                     <option value="1">Enable</option>
//                     <option value="0">Disable</option>
//                   </select>
//                 </div>
//               )}

//               {/* voucher check field */}
//               {(isAddMode || !isAddMode) && orderType !== 'hub' && (
//                 <div className="mb-2">
//                   <label className="font-bold">Voucher Check:</label>
//                   <select
//                     name="accountVoucherCheck"
//                     value={formData.accountVoucherCheck}
//                     onChange={handleChange}
//                     className="w-full border rounded p-1"
//                   >
//                     <option value="">Select a Status</option>
//                     <option value="1">Enable</option>
//                     <option value="0">Disable</option>
//                   </select>
//                 </div>
//               )}

//               {/* account status field */}
//               <div className="mb-2">
//                 <label className="font-bold">Subscribe Status:</label>
//                 <select
//                   name="accountStatus"
//                   value={formData.accountStatus}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="">Select a Status</option>
//                   <option value="Active">Active</option>
//                   <option value="Deactive">Deactive</option>
//                   <option value="Pending">Pending</option>
//                   <option value="Expire">Expire</option>
//                 </select>
//               </div>
//             </>
//           )}

//           {/* payment receipt field */}
//           {isAddMode ? (
//             <div className="mb-2 flex flex-col items-start">
//               <label className="font-bold">Payment Receipt:</label>
//               <div className="flex w-full justify-normal items-center border rounded px-2 py-1">
//                 <button
//                   type="button"
//                   onClick={handlePaymentReceiptGeneration}
//                   className="bg-cyan-800 hover:bg-cyan-900 text-white text-sm px-4 py-1 rounded-md"
//                 >
//                   Generate
//                 </button>
//                 {/* Display the generated image string */}
//                 {formData.PaymentReceipts && (
//                   <div className="text-sm text-gray-600 mx-4">
//                     {formData.PaymentReceipts}
//                   </div>
//                 )}
//               </div>
//             </div>
//           ) : (
//             <div className="mb-2 flex flex-col items-start">
//               <label className="font-bold">Payment Receipt:</label>
//               <button
//                 type="button"
//                 onClick={handleImageClick}
//                 className="text-blue-500 underline bg-transparent border-none cursor-pointer p-0"
//               >
//                 {formData.PaymentReceipts}
//               </button>
//             </div>
//           )}
//         </div>

//         {/* Modify and cancel button */}
//         <div className="flex justify-end mt-4 space-x-2">
//           <button
//             className="bg-gray-500 text-white px-6 py-2 rounded-md"
//             onClick={onClose}
//           >
//             Cancel
//           </button>
//           <button
//             className="bg-green-500 text-white px-6 py-2 rounded-md"
//             onClick={handleSubmit}
//           >
//             {isAddMode ? 'Add' : 'Modify'}
//           </button>
//         </div>
//       </div>

//       {/* Modal for enlarged image */}
//       {isImageModalOpen && (
//         <div className="fixed inset-0 z-60 flex items-center justify-center bg-black bg-opacity-75">
//           <div className="relative bg-white p-6 rounded-lg shadow-lg w-auto max-w-4xl">
//             <img
//               src={getProductImageUrl(formData.PaymentReceipts, formData.productName)}
//               alt="Payment Receipt"
//               className="w-full h-auto rounded-lg"
//               onClick={handleCloseImageModal}
//             />
//           </div>
//         </div>
//       )}

//       {/* Alert Message */}
//       <ConfirmAlertMessage
//         type={alertType}
//         message={alertMessage}
//         visible={alertVisible}
//         onClose={() => setAlertVisible(false)}
//         onConfirm={handleConfirmModification}
//       />
//     </div>
//   );
// };

// export default ModifyNotificationModal;

// // update code --> 05 sept 2024 v02
// // src/components/admin/SubscriptionSetting/SubNotificationModal.tsx

// import React, { useState, useEffect  } from 'react';
// import moment from 'moment-timezone';
// import { useEnv } from '../../../context/EnvContext';
// import { AiOutlineClose } from 'react-icons/ai';
// import useSubNotification, { Notification, Plan } from '../../../hooks/AdminDashboard/useSubNotification';
// import ConfirmAlertMessage from '../alertMessage'; 

// interface ModifyNotificationModalProps {
//   notification: Notification;
//   onClose: () => void;
// }

// const ModifyNotificationModal: React.FC<ModifyNotificationModalProps> = ({ notification, onClose }) => {
//   // components stats
//   const { apiUrl } = useEnv();
//   const { 
//     modifyNotification, addNewNotification, 
//     formatDate, formatData, 
//     getStatusClass, getStatusHubClass,
//     fetchPlans, fetchProductNames
//   } = useSubNotification();
//   const [isImageModalOpen, setIsImageModalOpen] = useState(false);
//   // form state
//   const [daysCount, setDaysCount] = useState<number | null>(null);
//   const [isAddMode, setIsAddMode] = useState(false);
//   const [orderType, setOrderType] = useState<'sub' | 'hub' | ''>(''); 
//   const [generatedOrderId, setGeneratedOrderId] = useState('');
//   const [plans, setPlans] = useState<Plan[]>([]);
//   // alert state
//   const [alertVisible, setAlertVisible] = useState(false);
//   const [alertType, setAlertType] = useState<'success' | 'error' | 'confirm'>('confirm');
//   const [alertMessage, setAlertMessage] = useState('');
//   // formData state
//   const [formData, setFormData] = useState<Notification>({
//     ...notification,
//     planSubDate: formatDate(notification.planSubDate) || '',
//     planExpiredDate: notification.planExpiredDate ? formatDate(notification.planExpiredDate) : '',
//     expiryDate: notification.expiryDate ? formatDate(notification.expiryDate) : '',
//     // Add trial-specific fields for when planType is 'Trial'
//     planExpiredTrialDate: notification.planExpiredTrialDate ? formatDate(notification.planExpiredTrialDate) : '',
//     planTrialPeriod: notification.planTrialPeriod || '',
//     planTrialDays: notification.planTrialDays || '',
//   });

//   useEffect(() => {
//     if (!notification.orderID) {
//       setIsAddMode(true);
//     }
//   }, [notification]);

//   useEffect(() => {
//     fetchProductNames();
//   }, [fetchProductNames]);

//   const generateOrderId = (type: string) => {
//     const digits = Math.floor(1000 + Math.random() * 9000); 
//     const alpha = Math.random().toString(36).substring(2, 6).toUpperCase(); 
//     return `${type}-${digits}${alpha}`; 
//   };
  
//   const generateRandomImageString = () => {
//     const randomDigits = Math.floor(1000000000 + Math.random() * 9000000000); 
//     return `${randomDigits}.png`;
//   };
  
//   const handlePaymentReceiptGeneration = () => {
//     const randomImageString = generateRandomImageString();
//     setFormData((prev: Notification) => ({
//       ...prev,
//       PaymentReceipts: randomImageString,
//     }));
//   };
  
//   const handleOrderTypeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = e.target.value as 'sub' | 'hub';
//     setOrderType(selectedType);
  
//     // Generate a new orderID based on the order type
//     const newOrderId = generateOrderId(selectedType);
//     setGeneratedOrderId(newOrderId);
  
//     // Update formData with the new orderID
//     setFormData((prev) => ({
//       ...prev,
//       orderID: newOrderId,
//     }));
  
//     try {
//       const fetchedPlans = await fetchPlans(selectedType); 
//       // Update the plans state with fetched plans
//       setPlans(fetchedPlans);
//     } catch (error) {
//       console.error('Failed to fetch plans', error);
//     }
//   };  

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
//     const { name, value } = e.target;
    
//     setFormData((prev: Notification) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handleConfirmModification = async () => {
//     try {
//       if (isAddMode) {
//         // Get the current time in 'Asia/Kuala_Lumpur' timezone
//         const currentTimeInMalaysia = moment().tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm:ss');

//         // Find the selected plan based on planID
//         const selectedPlan = plans.find(plan => Number(plan.id) === Number(formData.planID));
//         const selectedPlanName = selectedPlan ? selectedPlan.planName : '';
//         const selectedPlanAmount = selectedPlan ? selectedPlan.planAmount : '';

//         // Determine the productName based on the orderID prefix
//         let productName = '';
//         if (formData.orderID.startsWith('sub-')) {
//           productName = 'AlgoMatrix';
//         } else if (formData.orderID.startsWith('hub-')) {
//           productName = 'Trader Hub';
//         }

//         // Ensure that the PaymentReceipts is included in the formData
//         const updatedFormData = {
//           ...formData,
//           orderID: formData.orderID || generatedOrderId,
//           productName: productName,
//           planName: selectedPlanName, 
//           planAmount: selectedPlanAmount, 
//           planDays: formData.planPeriod,
//           planSubDate: currentTimeInMalaysia,
//           PaymentReceipts: formData.PaymentReceipts || generateRandomImageString(),
//           createdAt: currentTimeInMalaysia,
//         };

//         // If the plan type is "Trial", set specific fields for the trial
//         if (formData.planType === 'Trial') {
//           updatedFormData.planTrialPeriod = formData.planPeriod;
//           updatedFormData.planTrialDays = formData.planPeriod;
//           updatedFormData.planExpiredTrialDate = formData.planExpiredDate;
//         }

//         // Call the addNewNotification function with the updated formData
//         await addNewNotification(updatedFormData);  
        
//         setAlertType('success');
//         setAlertMessage('The account has been successfully added.');
//       } else {
//         await modifyNotification(formData);
//         setAlertType('success');
//         setAlertMessage('The account has been successfully updated.');
//       }
//     } catch (error) {
//       setAlertType('error');
//       setAlertMessage(
//         isAddMode
//           ? 'Failed to add an account. Please contact System Administrator.'
//           : 'Failed to modify the account. Please contact System Administrator.'
//       );
//     } finally {
//       setAlertVisible(true);
//     }
//   };
 
//   const handleSubmit = () => {
//     setAlertType('confirm');
//     setAlertMessage('Make sure you have enter correct information to update this account');
//     setAlertVisible(true);
//   };

//   const handleImageClick = () => {
//     setIsImageModalOpen(true);
//   };

//   const handleCloseImageModal = () => {
//     setIsImageModalOpen(false);
//   };

//   const placeholderImage = "https://via.placeholder.com/150";

//   const getProductImageUrl = (image: string | undefined, productName: string | undefined) => {
//     if (productName === "Trader Hub") {
//       return image ? `${apiUrl}/uploads/forTraderHubReceipts/${image}` : placeholderImage;
//     } else {
//       return image ? `${apiUrl}/uploads/forAlgoMatrixReceipts/${image}` : placeholderImage;
//     }
//   };

//   useEffect(() => {
//     if (formData.productName === 'Trader Hub' && formData.expiryDate) {
//       const currentDate = moment().tz('Asia/Kuala_Lumpur').startOf('day');
//       const expiredDate = moment.tz(formData.expiryDate, 'Asia/Kuala_Lumpur').startOf('day');
//       const diffInDays = expiredDate.diff(currentDate, 'days');
//       setDaysCount(diffInDays >= 0 ? diffInDays : 0);
//     } else if (formData.planExpiredDate) {
//       const currentDate = moment().tz('Asia/Kuala_Lumpur').startOf('day');
//       const expiredDate = moment.tz(formData.planExpiredDate, 'Asia/Kuala_Lumpur').startOf('day');
//       const diffInDays = expiredDate.diff(currentDate, 'days');
//       setDaysCount(diffInDays >= 0 ? diffInDays : 0);
//     }
//   }, [formData.planExpiredDate, formData.expiryDate, formData.productName]);
   
//   // You can also use useEffect to set this conditionally based on notification or other criteria
//   useEffect(() => {
//     if (!notification.orderID) {
//       setIsAddMode(true);
//     }
//   }, [notification]);

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="relative bg-white p-6 rounded-lg shadow-lg w-auto max-w-screen h-auto max-h-screen z-60">
//         <div className="flex justify-between items-center mb-4">
//           {isAddMode ? 'Added User Subscription Detail Information Data' : 'User Subscription Detail Information Data'}
//           <button onClick={onClose}>
//             <AiOutlineClose className="text-xl" />
//           </button>
//         </div>
//         <div className="w-full border rounded-md shadow bg-gray-100 py-4 px-12 flex justify-center items-center">
//           {/* Status field */}
//           <div className="flex justify-between items-center">
//             <strong>Status:</strong>
//             <div className={`ml-2 py-1 px-6 rounded-full text-xs ${formData.productName === "Trader Hub" ? getStatusHubClass(notification.accountStatus) : getStatusClass(notification.accountStatus)}`}>
//               {formatData(notification.accountStatus)}
//             </div>
//           </div>

//           {/* Expired Days remaining field */}
//           <div className="flex justify-between items-center ml-10">
//             <strong>Expired Days Remaining:</strong>
//             <div className="ml-2">{daysCount !== null ? `${daysCount} days` : 'N/A'}</div>
//           </div>
//         </div>

//         <div className="mt-4 grid grid-cols-2 gap-4 w-auto h-auto max-h-[500px] overflow-y-auto">
          
//           {/* Order ID field */}
//           <div className="flex flex-col">
//             {isAddMode ? (
//               <div className="flex flex-col">
//                 <div className="flex justify-between items-center">
//                   <label className="font-bold mr-2">Order ID:</label>
//                   <select
//                     value={orderType}
//                     onChange={handleOrderTypeChange}
//                     className="w-[150px] border rounded p-1"
//                   >
//                     <option value="" disabled>Select Type</option>
//                     <option value="sub">Sub</option>
//                     <option value="hub">Hub</option>
//                   </select>
//                 </div>
//                 {/* Input field to show Order ID, only editable in Add mode */}
//                 <input
//                   type='text'
//                   name='orderID' 
//                   value={generatedOrderId || formData.orderID}
//                   onChange={handleChange}
//                   className="mt-2 border p-1 rounded text-left"
//                   disabled={isAddMode} 
//                 />
//               </div>
//             ) : (
//               <div className="flex flex-col items-left">
//                 <label className="font-bold mr-2">Order ID:</label>
//                 <p className="text-gray-700">{formData.orderID}</p>
//               </div>
//             )}
//           </div>

//           {/* Requested By field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold mr-2">Requested By:</label>
//               <input
//                 type='text'
//                 name='userName' 
//                 value={formData.userName}
//                 onChange={handleChange}
//                 className="mt-2 border p-1 rounded text-left"
//                 placeholder="Enter username..."
//               />
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Requested By:</label>
//               <p>{formData.userName}</p>
//             </div>
//           )}

//           {/* Email field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">Email:</label>
//               <input
//                 type="text"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 className="mt-2 border p-1 rounded text-left"
//                 placeholder="Enter user email..."
//               />
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Email:</label>
//               <p>{formData.email}</p>
//             </div>
//           )}

//           {/* Note field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">Note:</label>
//               <input
//                 type="text"
//                 name="notes"
//                 value={formData.notes}
//                 onChange={handleChange}
//                 className="mt-2 border p-1 rounded text-left"
//                 placeholder="Enter notes to remarks..."
//               />
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Note:</label>
//               <p>{formData.notes}</p>
//             </div>
//           )}

//           {/* Payment Method field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">Payment Method:</label>
//               <select
//                 name="PaymentMethod"
//                 value={formData.PaymentMethod}
//                 onChange={handleChange}
//                 className="w-full border rounded p-1"
//               >
//                 <option value="">Select Payment Method</option>
//                 <option value="Bank Transfer">Bank Transfer</option>
//                 <option value="USDT">USDT (Cryptocurrency)</option>
//               </select>
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Payment Method:</label>
//               <p>{formData.PaymentMethod}</p>
//             </div>
//           )}

//           {/* Term and Conditions field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">T&C Apply:</label>
//               <select
//                 name="termAndCondition"
//                 value={formData.termAndCondition}
//                 onChange={handleChange}
//                 className="w-full border rounded p-1"
//               >
//                 <option value="">Select T&C Apply</option>
//                 <option value="Accepted">Accepted</option>
//                 <option value="Not Accepted">Not Accepted</option>
//               </select>
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">T&C Apply:</label>
//               <p>{formData.termAndCondition === "1" ? "Accepted" : "Not Accepted"}</p>
//             </div>
//           )}

//           <div className="border border-gray-300 col-span-2 my-4"></div>
          
//           {/* Account ID field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">Account ID:</label>
//               <input
//                 type="text"
//                 value={formData.accountID}
//                 onChange={handleChange}
//                 className="w-full border rounded p-1"
//                 disabled={isAddMode}
//                 placeholder="Account ID will auto generated on database..."
//               />
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Account ID:</label>
//               <p>{formData.accountID}</p>
//             </div>
//           )}

//           {/* Trader Hub Specific field */}
//           {formData.productName === "Trader Hub" ? (
//             <>
//               {/* Plan Type field */}
//               <div className="mb-2">
//                 <label className="font-bold">Plan Type:</label>
//                 <select
//                   name="planTypeHub"
//                   value={formData.planTypeHub}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="Renew">Renew</option>
//                   <option value="Subscribe">Subscribe</option>
//                 </select>
//               </div>

//               {/* Plan Period field */}
//               <div className="mb-2">
//                 <label className="font-bold">Plan Period (Days):</label>
//                 <input
//                   type="text"
//                   name="planPeriodHub"
//                   value={formData.planPeriodHub}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Plan Amount field */}
//               <div className="mb-2">
//                 <label className="font-bold">Plan Amount:</label>
//                 <input
//                   type="text"
//                   name="planAmountHub"
//                   value={formData.planAmountHub}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Account Number field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Number:</label>
//                 <input
//                   type="text"
//                   name="accountNumber"
//                   value={formData.accountNumber}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Account Password field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Password:</label>
//                 <input
//                   type="text"
//                   name="accountPassword"
//                   value={formData.accountPassword}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Account Server field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Server:</label>
//                 <input
//                   type="text"
//                   name="accountServer"
//                   value={formData.accountServer}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Expires On field */}
//               <div className="mb-2">
//                 <label className="font-bold">Expires On:</label>
//                   {daysCount !== null && (
//                     <span className="ml-2 text-md font-semibold text-rose-600"> {daysCount} Days </span>
//                   )}
//                 <input
//                   type="date"
//                   name="expiryDate"
//                   value={formData.expiryDate}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Expires Days field */}
//               <div className="mb-2">
//                 <label className="font-bold">Expire Days:</label>
//                 <input
//                   type="text"
//                   name="expireDays"
//                   value={formData.expireDays}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* status field */}
//               <div className="mb-2">
//                 <label className="font-bold">Subscribe Status:</label>
//                 <select
//                   name="status"
//                   value={formData.status}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="running">Running</option>
//                   <option value="stopped">Stopped</option>
//                   <option value="pending">Pending</option>
//                   <option value="expired">Expired</option>
//                 </select>
//               </div>

//               {/* disable trial check field */}
//               <div className="mb-2">
//               <label className={`font-bold ${true ? 'text-gray-300' : ''}`}>Trial Check:</label>
//                 <input
//                   type="text"
//                   name="accountTrialCheck"
//                   value="Disabled"
//                   className="w-full border rounded p-1 bg-gray-100 text-gray-400 cursor-not-allowed"
//                   disabled
//                 />
//               </div>

//               {/* disable voucher check field */}
//               <div className="mb-2">
//                 <label className={`font-bold ${true ? 'text-gray-300' : ''}`}>Voucher Check:</label>
//                 <input
//                   type="text"
//                   name="accountVoucherCheck"
//                   value="Disabled"
//                   className="w-full border rounded p-1 bg-gray-100 text-gray-400 cursor-not-allowed"
//                   disabled
//                 />
//               </div>
//             </>
//           ) : (
//             <>
//               {/* Existing AlgoMarix Fields */}
//               {/* Plan ID field */}
//               {isAddMode && plans.length > 0 && (
//                 <div className="mb-2">
//                   <label className="font-bold">Plan Name:</label>
//                   <select
//                     name="planID"
//                     value={formData.planID}
//                     onChange={handleChange}
//                     className="w-full border rounded p-1 py-1.5"
//                   >
//                     <option value="">Select a Plan Name</option>
//                     {plans.map(plan => (
//                       <option key={plan.id} value={plan.id}>
//                         {plan.planName}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               )}

//               {/* plan type field */}
//               <div className="mb-2">
//                 <label className="font-bold">Plan Type:</label>
//                 <select
//                   name="planType"
//                   value={formData.planType}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1 py-2"
//                 >
//                   <option value="">Select a Plan Type</option>
//                   <option value="Renew">Renew</option>
//                   <option value="Subscribe">Subscribe</option>
//                   <option value="Trial">Trial</option>
//                 </select>
//               </div>

//               {/* Plan Period field */}
//               {isAddMode ? (
//                 <div className="mb-2">
//                   <div className="flex justify-between items-center">
//                     <label className="font-bold">Plan Period (Days):</label>
//                       <select
//                         className="w-auto border rounded p-1 ml-2 text-sm"
//                         name="planPeriod"
//                         value={formData.planPeriod}
//                         onChange={(e) => {
//                           setFormData((prev) => ({
//                             ...prev,
//                             planPeriod: e.target.value,
//                           }));
//                         }}
//                       >
//                         <option value="">select a Plan Period</option>
//                         <option value="7">7 Days</option>
//                         <option value="14">14 Days</option>
//                         <option value="30">30 Days</option>
//                         <option value="365">365 Days</option>
//                         <option value="custom">Specify Count</option>
//                       </select>
//                     </div>
//                     <div className="flex justify-between items-center space-x-1 space-y-1">
//                       <input
//                         type="number"
//                         name="planPeriod"
//                         value={formData.planPeriod === "7" || formData.planPeriod === "14" || formData.planPeriod === "30" || formData.planPeriod === "365" ? formData.planPeriod : formData.planPeriod}
//                         onChange={handleChange}
//                         className="border rounded p-1 w-full"
//                         disabled={formData.planPeriod === "7" || formData.planPeriod === "14" || formData.planPeriod === "30" || formData.planPeriod === "365"} 
//                       />
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="mb-2">
//                     <label className="font-bold">Plan Period (Days):</label>
//                     <p>{formData.planPeriod}</p>
//                   </div>
//                 )}

//               {/* account type field */}
//               {isAddMode ? (
//                 <div className="mb-2">
//                   <label className="font-bold">Account Type:</label>
//                   <select
//                     name="accountType"
//                     value={formData.accountType}
//                     onChange={handleChange}
//                     className="w-full border rounded p-1"
//                   >
//                     <option value="">Select an Account Type</option>
//                     <option value="LIVE">LIVE</option>
//                     <option value="DEMO">DEMO</option>
//                   </select>
//                 </div>
//               ) : null}

//               {/* account number field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Number:</label>
//                 <input
//                   type="text"
//                   name="accountNumber"
//                   value={formData.accountNumber}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* account password field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Password:</label>
//                 <input
//                   type="text"
//                   name="accountPassword"
//                   value={formData.accountPassword}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* account server field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Server:</label>
//                 <input
//                   type="text"
//                   name="accountServer"
//                   value={formData.accountServer}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* expires on field */}
//               <div className="mb-2">
//                 <label className="font-bold">Expires On:</label>
//                   {daysCount !== null && (
//                     <span className="ml-2 text-md font-semibold text-rose-600"> {daysCount} Days </span>
//                   )}
//                 <input
//                   type="date"
//                   name="planExpiredDate"
//                   value={formData.planExpiredDate}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* trial check field */}
//               <div className="mb-2">
//                 <label className="font-bold">Trial Check:</label>
//                 <select
//                   name="accountTrialCheck"
//                   value={formData.accountTrialCheck}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="">Select a Status</option>
//                   <option value="1">Enable</option>
//                   <option value="0">Disable</option>
//                 </select>
//               </div>

//               {/* voucher check field */}
//               <div className="mb-2">
//                 <label className="font-bold">Voucher Check:</label>
//                 <select
//                   name="accountVoucherCheck"
//                   value={formData.accountVoucherCheck}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="">Select a Status</option>
//                   <option value="1">Enable</option>
//                   <option value="0">Disable</option>
//                 </select>
//               </div>

//               {/* account status field */}
//               <div className="mb-2">
//                 <label className="font-bold">Subscribe Status:</label>
//                 <select
//                   name="accountStatus"
//                   value={formData.accountStatus}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="">Select a Status</option>
//                   <option value="Active">Active</option>
//                   <option value="Deactive">Deactive</option>
//                   <option value="Pending">Pending</option>
//                   <option value="Expire">Expire</option>
//                 </select>
//               </div>
//             </>
//           )}

//           {/* payment receipt field */}
//           {isAddMode ? (
//             <div className="mb-2 flex flex-col items-start">
//               <label className="font-bold">Payment Receipt:</label>
//               <div className="flex w-full justify-normal items-center border rounded px-2 py-1">
//                 <button
//                   type="button"
//                   onClick={handlePaymentReceiptGeneration}
//                   className="bg-cyan-800 hover:bg-cyan-900 text-white text-sm px-4 py-1 rounded-md"
//                 >
//                   Generate
//                 </button>
//                 {/* Display the generated image string */}
//                 {formData.PaymentReceipts && (
//                   <div className="text-sm text-gray-600 mx-4">
//                     {formData.PaymentReceipts}
//                   </div>
//                 )}
//               </div>
//             </div>
//           ) : (
//             <div className="mb-2 flex flex-col items-start">
//               <label className="font-bold">Payment Receipt:</label>
//               <button
//                 type="button"
//                 onClick={handleImageClick}
//                 className="text-blue-500 underline bg-transparent border-none cursor-pointer p-0"
//               >
//                 {formData.PaymentReceipts}
//               </button>
//             </div>
//           )}
//         </div>

//         {/* Modify and cancel button */}
//         <div className="flex justify-end mt-4 space-x-2">
//           <button
//             className="bg-gray-500 text-white px-6 py-2 rounded-md"
//             onClick={onClose}
//           >
//             Cancel
//           </button>
//           <button
//             className="bg-green-500 text-white px-6 py-2 rounded-md"
//             onClick={handleSubmit}
//           >
//             {isAddMode ? 'Add' : 'Modify'}
//           </button>
//         </div>
//       </div>

//       {/* Modal for enlarged image */}
//       {isImageModalOpen && (
//         <div className="fixed inset-0 z-60 flex items-center justify-center bg-black bg-opacity-75">
//           <div className="relative bg-white p-6 rounded-lg shadow-lg w-auto max-w-4xl">
//             <img
//               src={getProductImageUrl(formData.PaymentReceipts, formData.productName)}
//               alt="Payment Receipt"
//               className="w-full h-auto rounded-lg"
//               onClick={handleCloseImageModal}
//             />
//           </div>
//         </div>
//       )}

//       {/* Alert Message */}
//       <ConfirmAlertMessage
//         type={alertType}
//         message={alertMessage}
//         visible={alertVisible}
//         onClose={() => setAlertVisible(false)}
//         onConfirm={handleConfirmModification}
//       />
//     </div>
//   );
// };

// export default ModifyNotificationModal;

// // udpate code --> 05 Sept 2024
// // src/components/admin/SubscriptionSetting/SubNotificationModal.tsx

// import React, { useState, useEffect  } from 'react';
// import moment from 'moment-timezone';
// import { useEnv } from '../../../context/EnvContext';
// import { AiOutlineClose } from 'react-icons/ai';
// import useSubNotification, { Notification, Plan } from '../../../hooks/AdminDashboard/useSubNotification';
// import ConfirmAlertMessage from '../alertMessage'; 

// interface ModifyNotificationModalProps {
//   notification: Notification;
//   onClose: () => void;
// }

// const ModifyNotificationModal: React.FC<ModifyNotificationModalProps> = ({ notification, onClose }) => {
//   // components stats
//   const { apiUrl } = useEnv();
//   const { 
//     modifyNotification, addNewNotification, 
//     formatDate, formatData, 
//     getStatusClass, getStatusHubClass,
//     fetchPlans, fetchProductNames
//   } = useSubNotification();
//   const [isImageModalOpen, setIsImageModalOpen] = useState(false);
//   // form state
//   const [daysCount, setDaysCount] = useState<number | null>(null);
//   const [isAddMode, setIsAddMode] = useState(false);
//   const [orderType, setOrderType] = useState<'sub' | 'hub' | ''>(''); 
//   const [generatedOrderId, setGeneratedOrderId] = useState('');
//   const [plans, setPlans] = useState<Plan[]>([]);
//   // alert state
//   const [alertVisible, setAlertVisible] = useState(false);
//   const [alertType, setAlertType] = useState<'success' | 'error' | 'confirm'>('confirm');
//   const [alertMessage, setAlertMessage] = useState('');
//   // formData state
//   const [formData, setFormData] = useState<Notification>({
//     ...notification,
//     planSubDate: formatDate(notification.planSubDate) || '',
//     planExpiredDate: notification.planExpiredDate ? formatDate(notification.planExpiredDate) : '',
//     planExpiredTrialDate: notification.planExpiredTrialDate ? formatDate(notification.planExpiredTrialDate) : '',
//     expiryDate: notification.expiryDate ? formatDate(notification.expiryDate) : '',
//   });

//   useEffect(() => {
//     if (!notification.orderID) {
//       setIsAddMode(true);
//     }
//   }, [notification]);

//   useEffect(() => {
//     fetchProductNames();
//   }, [fetchProductNames]);

//   const generateOrderId = (type: string) => {
//     const digits = Math.floor(1000 + Math.random() * 9000); 
//     const alpha = Math.random().toString(36).substring(2, 6).toUpperCase(); 
//     return `${type}-${digits}${alpha}`; 
//   };
  
//   const generateRandomImageString = () => {
//     const randomDigits = Math.floor(1000000000 + Math.random() * 9000000000); 
//     return `${randomDigits}.png`;
//   };
  
//   const handlePaymentReceiptGeneration = () => {
//     const randomImageString = generateRandomImageString();
//     setFormData((prev: Notification) => ({
//       ...prev,
//       PaymentReceipts: randomImageString,
//     }));
//   };
  
//   const handleOrderTypeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const selectedType = e.target.value as 'sub' | 'hub';
//     setOrderType(selectedType);
  
//     // Generate a new orderID based on the order type
//     const newOrderId = generateOrderId(selectedType);
//     setGeneratedOrderId(newOrderId);
  
//     // Update formData with the new orderID
//     setFormData((prev) => ({
//       ...prev,
//       orderID: newOrderId,
//     }));
  
//     try {
//       const fetchedPlans = await fetchPlans(selectedType); 
//       // Update the plans state with fetched plans
//       setPlans(fetchedPlans);
//     } catch (error) {
//       console.error('Failed to fetch plans', error);
//     }
//   };  

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
//     const { name, value } = e.target;
    
//     setFormData((prev: Notification) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handleConfirmModification = async () => {
//     try {
//       if (isAddMode) {
//         // Get the current time in 'Asia/Kuala_Lumpur' timezone
//         const currentTimeInMalaysia = moment().tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm:ss');

//         // Find the selected plan based on planID
//         const selectedPlan = plans.find(plan => Number(plan.id) === Number(formData.planID));
//         const selectedPlanName = selectedPlan ? selectedPlan.planName : '';
//         const selectedPlanAmount = selectedPlan ? selectedPlan.planAmount : '';

//         // Determine the productName based on the orderID prefix
//         let productName = '';
//         if (formData.orderID.startsWith('sub-')) {
//           productName = 'AlgoMatrix';
//         } else if (formData.orderID.startsWith('hub-')) {
//           productName = 'Trader Hub';
//         }

//         // Ensure that the PaymentReceipts is included in the formData
//         const updatedFormData = {
//           ...formData,
//           orderID: formData.orderID || generatedOrderId,
//           productName: productName,
//           planName: selectedPlanName, 
//           planAmount: selectedPlanAmount, 
//           planDays: formData.planPeriod,
//           planSubDate: currentTimeInMalaysia,
//           PaymentReceipts: formData.PaymentReceipts || generateRandomImageString(),
//           createdAt: currentTimeInMalaysia,
//         };

//         // Call the addNewNotification function with the updated formData
//         await addNewNotification(updatedFormData);  
        
//         setAlertType('success');
//         setAlertMessage('The account has been successfully added.');
//       } else {
//         await modifyNotification(formData);
//         setAlertType('success');
//         setAlertMessage('The account has been successfully updated.');
//       }
//     } catch (error) {
//       setAlertType('error');
//       setAlertMessage(
//         isAddMode
//           ? 'Failed to add an account. Please contact System Administrator.'
//           : 'Failed to modify the account. Please contact System Administrator.'
//       );
//     } finally {
//       setAlertVisible(true);
//     }
//   };
 
//   const handleSubmit = () => {
//     setAlertType('confirm');
//     setAlertMessage('Make sure you have enter correct information to update this account');
//     setAlertVisible(true);
//   };

//   const handleImageClick = () => {
//     setIsImageModalOpen(true);
//   };

//   const handleCloseImageModal = () => {
//     setIsImageModalOpen(false);
//   };

//   const placeholderImage = "https://via.placeholder.com/150";

//   const getProductImageUrl = (image: string | undefined, productName: string | undefined) => {
//     if (productName === "Trader Hub") {
//       return image ? `${apiUrl}/uploads/forTraderHubReceipts/${image}` : placeholderImage;
//     } else {
//       return image ? `${apiUrl}/uploads/forAlgoMatrixReceipts/${image}` : placeholderImage;
//     }
//   };

//   useEffect(() => {
//     if (formData.productName === 'Trader Hub' && formData.expiryDate) {
//       const currentDate = moment().tz('Asia/Kuala_Lumpur').startOf('day');
//       const expiredDate = moment.tz(formData.expiryDate, 'Asia/Kuala_Lumpur').startOf('day');
//       const diffInDays = expiredDate.diff(currentDate, 'days');
//       setDaysCount(diffInDays >= 0 ? diffInDays : 0);
//     } else if (formData.planExpiredDate) {
//       const currentDate = moment().tz('Asia/Kuala_Lumpur').startOf('day');
//       const expiredDate = moment.tz(formData.planExpiredDate, 'Asia/Kuala_Lumpur').startOf('day');
//       const diffInDays = expiredDate.diff(currentDate, 'days');
//       setDaysCount(diffInDays >= 0 ? diffInDays : 0);
//     }
//   }, [formData.planExpiredDate, formData.expiryDate, formData.productName]);
   
//   // You can also use useEffect to set this conditionally based on notification or other criteria
//   useEffect(() => {
//     if (!notification.orderID) {
//       setIsAddMode(true);
//     }
//   }, [notification]);

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="relative bg-white p-6 rounded-lg shadow-lg w-auto max-w-screen h-auto max-h-screen z-60">
//         <div className="flex justify-between items-center mb-4">
//           {isAddMode ? 'Added User Subscription Detail Information Data' : 'User Subscription Detail Information Data'}
//           <button onClick={onClose}>
//             <AiOutlineClose className="text-xl" />
//           </button>
//         </div>
//         <div className="w-full border rounded-md shadow bg-gray-100 py-4 px-12 flex justify-center items-center">
//           {/* Status field */}
//           <div className="flex justify-between items-center">
//             <strong>Status:</strong>
//             <div className={`ml-2 py-1 px-6 rounded-full text-xs ${formData.productName === "Trader Hub" ? getStatusHubClass(notification.accountStatus) : getStatusClass(notification.accountStatus)}`}>
//               {formatData(notification.accountStatus)}
//             </div>
//           </div>

//           {/* Expired Days remaining field */}
//           <div className="flex justify-between items-center ml-10">
//             <strong>Expired Days Remaining:</strong>
//             <div className="ml-2">{daysCount !== null ? `${daysCount} days` : 'N/A'}</div>
//           </div>
//         </div>

//         <div className="mt-4 grid grid-cols-2 gap-4 w-auto h-auto max-h-[500px] overflow-y-auto">
          
//           {/* Order ID field */}
//           <div className="flex flex-col">
//             {isAddMode ? (
//               <div className="flex flex-col">
//                 <div className="flex justify-between items-center">
//                   <label className="font-bold mr-2">Order ID:</label>
//                   <select
//                     value={orderType}
//                     onChange={handleOrderTypeChange}
//                     className="w-[150px] border rounded p-1"
//                   >
//                     <option value="" disabled>Select Type</option>
//                     <option value="sub">Sub</option>
//                     <option value="hub">Hub</option>
//                   </select>
//                 </div>
//                 {/* Input field to show Order ID, only editable in Add mode */}
//                 <input
//                   type='text'
//                   name='orderID' 
//                   value={generatedOrderId || formData.orderID}
//                   onChange={handleChange}
//                   className="mt-2 border p-1 rounded text-left"
//                   disabled={isAddMode} 
//                 />
//               </div>
//             ) : (
//               <div className="flex flex-col items-left">
//                 <label className="font-bold mr-2">Order ID:</label>
//                 <p className="text-gray-700">{formData.orderID}</p>
//               </div>
//             )}
//           </div>

//           {/* Requested By field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold mr-2">Requested By:</label>
//               <input
//                 type='text'
//                 name='userName' 
//                 value={formData.userName}
//                 onChange={handleChange}
//                 className="mt-2 border p-1 rounded text-left"
//                 placeholder="Enter username..."
//               />
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Requested By:</label>
//               <p>{formData.userName}</p>
//             </div>
//           )}

//           {/* Email field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">Email:</label>
//               <input
//                 type="text"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 className="mt-2 border p-1 rounded text-left"
//                 placeholder="Enter user email..."
//               />
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Email:</label>
//               <p>{formData.email}</p>
//             </div>
//           )}

//           {/* Note field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">Note:</label>
//               <input
//                 type="text"
//                 name="notes"
//                 value={formData.notes}
//                 onChange={handleChange}
//                 className="mt-2 border p-1 rounded text-left"
//                 placeholder="Enter notes to remarks..."
//               />
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Note:</label>
//               <p>{formData.notes}</p>
//             </div>
//           )}

//           {/* Payment Method field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">Payment Method:</label>
//               <select
//                 name="PaymentMethod"
//                 value={formData.PaymentMethod}
//                 onChange={handleChange}
//                 className="w-full border rounded p-1"
//               >
//                 <option value="">Select Payment Method</option>
//                 <option value="Bank Transfer">Bank Transfer</option>
//                 <option value="USDT">USDT (Cryptocurrency)</option>
//               </select>
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Payment Method:</label>
//               <p>{formData.PaymentMethod}</p>
//             </div>
//           )}

//           {/* Term and Conditions field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">T&C Apply:</label>
//               <select
//                 name="termAndCondition"
//                 value={formData.termAndCondition}
//                 onChange={handleChange}
//                 className="w-full border rounded p-1"
//               >
//                 <option value="">Select T&C Apply</option>
//                 <option value="Accepted">Accepted</option>
//                 <option value="Not Accepted">Not Accepted</option>
//               </select>
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">T&C Apply:</label>
//               <p>{formData.termAndCondition === "1" ? "Accepted" : "Not Accepted"}</p>
//             </div>
//           )}

//           <div className="border border-gray-300 col-span-2 my-4"></div>
          
//           {/* Account ID field */}
//           {isAddMode ? (
//             <div className="flex flex-col mb-2">
//               <label className="font-bold">Account ID:</label>
//               <input
//                 type="text"
//                 value={formData.accountID}
//                 onChange={handleChange}
//                 className="w-full border rounded p-1"
//                 disabled={isAddMode}
//                 placeholder="Account ID will auto generated on database..."
//               />
//             </div>
//           ) : (
//             <div className="mb-2">
//               <label className="font-bold">Account ID:</label>
//               <p>{formData.accountID}</p>
//             </div>
//           )}

//           {/* Trader Hub Specific field */}
//           {formData.productName === "Trader Hub" ? (
//             <>
//               {/* Plan Type field */}
//               <div className="mb-2">
//                 <label className="font-bold">Plan Type:</label>
//                 <select
//                   name="planTypeHub"
//                   value={formData.planTypeHub}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="Renew">Renew</option>
//                   <option value="Subscribe">Subscribe</option>
//                 </select>
//               </div>

//               {/* Plan Period field */}
//               <div className="mb-2">
//                 <label className="font-bold">Plan Period (Days):</label>
//                 <input
//                   type="text"
//                   name="planPeriodHub"
//                   value={formData.planPeriodHub}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Plan Amount field */}
//               <div className="mb-2">
//                 <label className="font-bold">Plan Amount:</label>
//                 <input
//                   type="text"
//                   name="planAmountHub"
//                   value={formData.planAmountHub}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Account Number field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Number:</label>
//                 <input
//                   type="text"
//                   name="accountNumber"
//                   value={formData.accountNumber}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Account Password field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Password:</label>
//                 <input
//                   type="text"
//                   name="accountPassword"
//                   value={formData.accountPassword}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Account Server field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Server:</label>
//                 <input
//                   type="text"
//                   name="accountServer"
//                   value={formData.accountServer}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Expires On field */}
//               <div className="mb-2">
//                 <label className="font-bold">Expires On:</label>
//                   {daysCount !== null && (
//                     <span className="ml-2 text-md font-semibold text-rose-600"> {daysCount} Days </span>
//                   )}
//                 <input
//                   type="date"
//                   name="expiryDate"
//                   value={formData.expiryDate}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* Expires Days field */}
//               <div className="mb-2">
//                 <label className="font-bold">Expire Days:</label>
//                 <input
//                   type="text"
//                   name="expireDays"
//                   value={formData.expireDays}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* status field */}
//               <div className="mb-2">
//                 <label className="font-bold">Subscribe Status:</label>
//                 <select
//                   name="status"
//                   value={formData.status}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="running">Running</option>
//                   <option value="stopped">Stopped</option>
//                   <option value="pending">Pending</option>
//                   <option value="expired">Expired</option>
//                 </select>
//               </div>

//               {/* disable trial check field */}
//               <div className="mb-2">
//               <label className={`font-bold ${true ? 'text-gray-300' : ''}`}>Trial Check:</label>
//                 <input
//                   type="text"
//                   name="accountTrialCheck"
//                   value="Disabled"
//                   className="w-full border rounded p-1 bg-gray-100 text-gray-400 cursor-not-allowed"
//                   disabled
//                 />
//               </div>

//               {/* disable voucher check field */}
//               <div className="mb-2">
//                 <label className={`font-bold ${true ? 'text-gray-300' : ''}`}>Voucher Check:</label>
//                 <input
//                   type="text"
//                   name="accountVoucherCheck"
//                   value="Disabled"
//                   className="w-full border rounded p-1 bg-gray-100 text-gray-400 cursor-not-allowed"
//                   disabled
//                 />
//               </div>
//             </>
//           ) : (
//             <>
//               {/* Existing AlgoMarix Fields */}
//               {/* Plan ID field */}
//               {isAddMode && plans.length > 0 && (
//                 <div className="mb-2">
//                   <label className="font-bold">Plan Name:</label>
//                   <select
//                     name="planID"
//                     value={formData.planID}
//                     onChange={handleChange}
//                     className="w-full border rounded p-1 py-1.5"
//                   >
//                     <option value="">Select a Plan Name</option>
//                     {plans.map(plan => (
//                       <option key={plan.id} value={plan.id}>
//                         {plan.planName}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               )}

//               {/* plan type field */}
//               <div className="mb-2">
//                 <label className="font-bold">Plan Type:</label>
//                 <select
//                   name="planType"
//                   value={formData.planType}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1 py-2"
//                 >
//                   <option value="">Select a Plan Type</option>
//                   <option value="Renew">Renew</option>
//                   <option value="Subscribe">Subscribe</option>
//                   <option value="Trial">Trial</option>
//                 </select>
//               </div>

//               {/* Plan Period field */}
//               {isAddMode ? (
//                 <div className="mb-2">
//                   <div className="flex justify-between items-center">
//                     <label className="font-bold">Plan Period (Days):</label>
//                       <select
//                         className="w-auto border rounded p-1 ml-2 text-sm"
//                         name="planPeriod"
//                         value={formData.planPeriod}
//                         onChange={(e) => {
//                           setFormData((prev) => ({
//                             ...prev,
//                             planPeriod: e.target.value,
//                           }));
//                         }}
//                       >
//                         <option value="">select a Plan Period</option>
//                         <option value="7">7 Days</option>
//                         <option value="14">14 Days</option>
//                         <option value="30">30 Days</option>
//                         <option value="365">365 Days</option>
//                         <option value="custom">Specify Count</option>
//                       </select>
//                     </div>
//                     <div className="flex justify-between items-center space-x-1 space-y-1">
//                       <input
//                         type="number"
//                         name="planPeriod"
//                         value={formData.planPeriod === "30" || formData.planPeriod === "365" ? formData.planPeriod : formData.planPeriod}
//                         onChange={handleChange}
//                         className="border rounded p-1 w-full"
//                         disabled={formData.planPeriod === "30" || formData.planPeriod === "365"} // Disable input when 30 or 365 is selected
//                       />
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="mb-2">
//                     <label className="font-bold">Plan Period (Days):</label>
//                     <p>{formData.planPeriod}</p>
//                   </div>
//                 )}

//               {/* account type field */}
//               {isAddMode ? (
//                 <div className="mb-2">
//                   <label className="font-bold">Account Type:</label>
//                   <select
//                     name="accountType"
//                     value={formData.accountType}
//                     onChange={handleChange}
//                     className="w-full border rounded p-1"
//                   >
//                     <option value="">Select an Account Type</option>
//                     <option value="LIVE">LIVE</option>
//                     <option value="DEMO">DEMO</option>
//                   </select>
//                 </div>
//               ) : null}

//               {/* account number field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Number:</label>
//                 <input
//                   type="text"
//                   name="accountNumber"
//                   value={formData.accountNumber}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* account password field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Password:</label>
//                 <input
//                   type="text"
//                   name="accountPassword"
//                   value={formData.accountPassword}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* account server field */}
//               <div className="mb-2">
//                 <label className="font-bold">Account Server:</label>
//                 <input
//                   type="text"
//                   name="accountServer"
//                   value={formData.accountServer}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* expires on field */}
//               <div className="mb-2">
//                 <label className="font-bold">Expires On:</label>
//                   {daysCount !== null && (
//                     <span className="ml-2 text-md font-semibold text-rose-600"> {daysCount} Days </span>
//                   )}
//                 <input
//                   type="date"
//                   name="planExpiredDate"
//                   value={formData.planExpiredDate}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 />
//               </div>

//               {/* trial check field */}
//               <div className="mb-2">
//                 <label className="font-bold">Trial Check:</label>
//                 <select
//                   name="accountTrialCheck"
//                   value={formData.accountTrialCheck}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="">Select a Status</option>
//                   <option value="1">Enable</option>
//                   <option value="0">Disable</option>
//                 </select>
//               </div>

//               {/* voucher check field */}
//               <div className="mb-2">
//                 <label className="font-bold">Voucher Check:</label>
//                 <select
//                   name="accountVoucherCheck"
//                   value={formData.accountVoucherCheck}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="">Select a Status</option>
//                   <option value="1">Enable</option>
//                   <option value="0">Disable</option>
//                 </select>
//               </div>

//               {/* account status field */}
//               <div className="mb-2">
//                 <label className="font-bold">Subscribe Status:</label>
//                 <select
//                   name="accountStatus"
//                   value={formData.accountStatus}
//                   onChange={handleChange}
//                   className="w-full border rounded p-1"
//                 >
//                   <option value="">Select a Status</option>
//                   <option value="Active">Active</option>
//                   <option value="Deactive">Deactive</option>
//                   <option value="Pending">Pending</option>
//                   <option value="Expire">Expire</option>
//                 </select>
//               </div>
//             </>
//           )}

//           {/* payment receipt field */}
//           {isAddMode ? (
//             <div className="mb-2 flex flex-col items-start">
//               <label className="font-bold">Payment Receipt:</label>
//               <div className="flex w-full justify-normal items-center border rounded px-2 py-1">
//                 <button
//                   type="button"
//                   onClick={handlePaymentReceiptGeneration}
//                   className="bg-cyan-800 hover:bg-cyan-900 text-white text-sm px-4 py-1 rounded-md"
//                 >
//                   Generate
//                 </button>
//                 {/* Display the generated image string */}
//                 {formData.PaymentReceipts && (
//                   <div className="text-sm text-gray-600 mx-4">
//                     {formData.PaymentReceipts}
//                   </div>
//                 )}
//               </div>
//             </div>
//           ) : (
//             <div className="mb-2 flex flex-col items-start">
//               <label className="font-bold">Payment Receipt:</label>
//               <button
//                 type="button"
//                 onClick={handleImageClick}
//                 className="text-blue-500 underline bg-transparent border-none cursor-pointer p-0"
//               >
//                 {formData.PaymentReceipts}
//               </button>
//             </div>
//           )}
//         </div>

//         {/* Modify and cancel button */}
//         <div className="flex justify-end mt-4 space-x-2">
//           <button
//             className="bg-gray-500 text-white px-6 py-2 rounded-md"
//             onClick={onClose}
//           >
//             Cancel
//           </button>
//           <button
//             className="bg-green-500 text-white px-6 py-2 rounded-md"
//             onClick={handleSubmit}
//           >
//             {isAddMode ? 'Add' : 'Modify'}
//           </button>
//         </div>
//       </div>

//       {/* Modal for enlarged image */}
//       {isImageModalOpen && (
//         <div className="fixed inset-0 z-60 flex items-center justify-center bg-black bg-opacity-75">
//           <div className="relative bg-white p-6 rounded-lg shadow-lg w-auto max-w-4xl">
//             <img
//               src={getProductImageUrl(formData.PaymentReceipts, formData.productName)}
//               alt="Payment Receipt"
//               className="w-full h-auto rounded-lg"
//               onClick={handleCloseImageModal}
//             />
//           </div>
//         </div>
//       )}

//       {/* Alert Message */}
//       <ConfirmAlertMessage
//         type={alertType}
//         message={alertMessage}
//         visible={alertVisible}
//         onClose={() => setAlertVisible(false)}
//         onConfirm={handleConfirmModification}
//       />
//     </div>
//   );
// };

// export default ModifyNotificationModal;