// src/components/login/VeriflySuccess.tsx

import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { useTheme } from '../../context/ThemeContext'; // Import theme context
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

interface SuccessModalProps {
  visible: boolean;
  onClose: () => void;
}

const VeriflySuccess: React.FC<SuccessModalProps> = ({ visible, onClose }) => {
  const { theme } = useTheme(); // Get theme from context
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  // Effect to handle the animation and redirection
  useEffect(() => {
    if (visible) {
      setShowModal(true);
      // Set a timeout to redirect after 3 seconds
      const timer = setTimeout(() => {
        onClose(); // Close the modal before navigating
        navigate('/login'); // Redirect to the dashboard
      }, 3000);
      return () => clearTimeout(timer); // Cleanup timeout on unmount
    } else {
      setShowModal(false);
    }
  }, [visible, navigate, onClose]);

  // If the modal is not visible, return null
  if (!visible) return null;

  return (
    // Modal container with transition effect
    <div 
      className={`fixed inset-x-0 top-0 transition-transform transform ${showModal ? 'translate-y-0' : '-translate-y-full'} z-50 px-4`}
      style={{ fontFamily: theme.fontFamily }}
    >
      {/* Alert box with light green background and green border */}
      <div className="relative flex flex-col p-4 mt-4 mb-4 text-sm text-green-800 rounded-lg bg-green-100 border border-green-500 max-w-lg mx-auto md:max-w-2xl" role="alert">
        {/* Success icon and title */}
        <div className="flex items-center">
          <FaCheckCircle className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" />
          <span className="font-medium" style={{ color: theme.textColor }}>
            Sign In Successful!
          </span>
        </div>
        {/* Success message content */}
        <div className="mt-2 text-green-700 md:ml-4">
          You have successfully signed into your account. You will be redirected to your dashboard shortly. Thank you for using our platform!
        </div>
        {/* Close icon button */}
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-green-800 hover:text-green-600 focus:outline-none"
        >
          <FaTimes className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default VeriflySuccess;
