// src/components/client/MiniApp/AboutUs.tsx

import React from 'react';
import rOneLogo from '../../../assets/login/logo_r1_ori_v02.png';

const AboutUs: React.FC = () => {
  return (
    <div className="p-8 w-auto h-auto overflow-y-auto xl:h-[600px] lg:h-[600px] md:h-[600px] sm:h-[500px] xs:h-[400px]">
      <div className="mb-4">
        <img
          src={rOneLogo}
          alt="R One Logo"
          className="w-[450px] h-auto object-cover"
        />
      </div>
      <p className="text-sm text-gray-600 mb-4">
        Welcome to R One Century. We are dedicated to providing the best services and solutions to help you succeed. 
        Our team is committed to excellence, innovation, and customer satisfaction. 
        Here you can learn more about our mission, values, and the people behind our success.
      </p>
      <h3 className="text-xl font-semibold mt-6 mb-2">Our Mission</h3>
      <p className="text-sm text-gray-600 mb-4">
        Our mission is to empower individuals and organizations through cutting-edge technology and unparalleled customer support. 
        We aim to create solutions that make a real difference in the world.
      </p>
      <h3 className="text-xl font-semibold mt-6 mb-2">Our Values</h3>
      <ul className="list-disc ml-6 text-sm text-gray-600 mb-4">
        <li>Integrity: We operate with honesty and transparency.</li>
        <li>Innovation: We embrace new ideas and technologies.</li>
        <li>Customer Focus: Our customers are at the heart of everything we do.</li>
        <li>Excellence: We strive for the highest quality in all our endeavors.</li>
      </ul>
      <h3 className="text-xl font-semibold mt-6 mb-2">Our Team</h3>
      <p className="text-sm text-gray-600">
        Our team consists of passionate professionals who are experts in their fields. 
        Together, we work towards achieving our goals and making a positive impact on the community.
      </p>
    </div>
  );
};

export default AboutUs;

