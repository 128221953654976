// src/components/Chat.tsx

import React, { useState, useEffect } from 'react';
import { useTheme } from '../context/ThemeContext';
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { FaTimes } from 'react-icons/fa';
import { questionsAndAnswers, topics } from './chat/ChatData';
import { motion, AnimatePresence } from 'framer-motion';

const Chat: React.FC = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [showTopics, setShowTopics] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
  const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { theme } = useTheme();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isChatOpen) {
      const timer = setTimeout(() => {
        setShowTopics(true);
      }, 300); // Duration of the modal animation
      return () => clearTimeout(timer);
    } else {
      setShowTopics(false);
    }
  }, [isChatOpen]);
  
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    setSelectedTopic(null);
    setSelectedQuestion(null);
    setSearchTerm('');
  };

  const handleTopicClick = (topic: string) => {
    setSelectedTopic(topic);
    setSelectedQuestion(null);
  };

  const handleQuestionClick = (question: string) => {
    setSelectedQuestion(question);
  };

  const handleBack = () => {
    setSelectedTopic(null);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredTopics = topics.filter((topic) =>
    topic.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <AnimatePresence>
        {isChatOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black z-40"
          ></motion.div>
        )}
      </AnimatePresence>

      <div className="fixed bottom-24 right-5 z-50">
        <button
          onClick={toggleChat}
          style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }}
          className="p-2 rounded-full shadow-lg flex items-center"
        >
          <IoChatbubbleEllipsesOutline className="w-8 h-8" />
          {!isMobileView && (
            <div className="ml-2 mr-2">
              Support
            </div>
          )}
        </button>
      </div>

      <AnimatePresence>
        {isChatOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.3, transformOrigin: 'bottom right' }}
            animate={{ opacity: 1, scale: 1, transformOrigin: 'bottom right' }}
            exit={{ opacity: 0, scale: 0.3, transformOrigin: 'bottom right' }}
            className={`fixed ${isMobileView ? 'bottom-[160px] left-2 right-2 w-auto h-auto' : 'bottom-40 right-4 max-w-3xl w-[90%] h-[80%] md:w-[600px] md:h-[500px]'} z-50 transform bg-white rounded-lg shadow-md p-6`}
          >
            <div className="relative">
              <div className="absolute bottom-[-10px] left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-8 border-r-8 border-b-8 border-transparent border-b-white"></div>
            </div>
            <div className="flex justify-between items-center mb-4">
              <h1 className="text-lg font-semibold" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
                Chat Support
              </h1>
              <motion.button
                onClick={toggleChat}
                className="text-red-500"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
              >
                <FaTimes className="w-6 h-6" />
              </motion.button>
            </div>

            <div className={`flex ${isMobileView ? 'flex-col' : 'flex-row'} h-full overflow-hidden`}>
              <div className={`w-full ${isMobileView ? 'h-1/2' : 'sm:w-1/2'} pr-4 sm:border-r border-gray-300 overflow-y-auto`}>
                {selectedTopic ? (
                  <div>
                    <button 
                      onClick={handleBack} 
                      className="block mb-4 px-8 py-2 rounded-lg" 
                      style={{ fontFamily: theme.fontFamily, backgroundColor: theme.primaryColor, color: theme.text2Color }}>
                        Back
                    </button>
                    {Object.keys(questionsAndAnswers[selectedTopic]).map((question, index) => (
                      <button
                        key={index}
                        onClick={() => handleQuestionClick(question)}
                        className="block text-left w-full mb-2 p-3 bg-gray-100 rounded-lg hover:bg-gray-200"
                        style={{ fontFamily: theme.fontFamily }}
                      >
                        {question}
                      </button>
                    ))}
                  </div>
                ) : (
                  <div>
                    <input
                      type="text"
                      placeholder="Search for a topic..."
                      value={searchTerm}
                      onChange={handleSearch}
                      className="w-full p-2 mb-4 border border-gray-300 rounded-lg bg-white text-black"
                    />
                    {filteredTopics.map((topic, index) => (
                      <motion.div
                        key={index}
                        onClick={() => handleTopicClick(topic)}
                        className="block text-left w-full mb-2 p-3 bg-gray-100 rounded-lg hover:bg-gray-200"
                        style={{ fontFamily: theme.fontFamily }}
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: showTopics ? 0 : -100, opacity: showTopics ? 1 : 0 }}
                        transition={{ delay: index * 0.12 }} // Stagger the animation with a delay
                        exit={{ x: -100, opacity: 0 }}
                      >
                        {topic}
                      </motion.div>
                    ))}
                  </div>
                )}
              </div>

              <div className={`w-full ${isMobileView ? 'h-1/2' : 'sm:w-1/2'} pl-4 overflow-y-auto`}>
                {selectedQuestion && selectedTopic && questionsAndAnswers[selectedTopic] && questionsAndAnswers[selectedTopic][selectedQuestion] ? (
                  <div>
                    <h2 className="text-md font-semibold mb-4" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
                      {selectedQuestion}
                    </h2>
                      <p style={{ color: theme.textColor }} dangerouslySetInnerHTML={{ __html: questionsAndAnswers[selectedTopic][selectedQuestion] }}></p>
                  </div>
                ) : (
                  <div className="text-center text-gray-500 border-t pt-4">
                    Select a topic to view questions.
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Chat;




// // update code --> 02 july 2024
// // src/components/Chat.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// import { useTheme } from '../context/ThemeContext';
// import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
// import { FaTimes } from 'react-icons/fa';
// import { questionsAndAnswers, topics } from './chat/ChatData'; // Importing data
// import './Chat.css'; // Import CSS for animation

// // Main Chat component
// const Chat: React.FC = () => {
//   const [isChatOpen, setIsChatOpen] = useState(false);
//   const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
//   const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const { theme } = useTheme();
//   const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 640);

//   // Update state based on window size
//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobileView(window.innerWidth <= 640);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   // Function to toggle chat visibility
//   const toggleChat = () => {
//     setIsChatOpen(!isChatOpen);
//     setSelectedTopic(null);
//     setSelectedQuestion(null);
//     setSearchTerm('');
//   };

//   // Function to handle topic selection
//   const handleTopicClick = (topic: string) => {
//     setSelectedTopic(topic);
//     setSelectedQuestion(null);
//   };

//   // Function to handle question selection
//   const handleQuestionClick = (question: string) => {
//     setSelectedQuestion(question);
//   };

//   // Function to go back to the previous view
//   const handleBack = () => {
//     setSelectedTopic(null);
//   };

//   // Function to handle search
//   const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   // Filter topics based on search term
//   const filteredTopics = topics.filter((topic) =>
//     topic.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div>
//       {/* Dark Background Overlay */}
//       {isChatOpen && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
//       )}

//       {/* Support Chat Icon */}
//       <div className="fixed bottom-[92px] right-[20px] z-50">
//         <button 
//           onClick={toggleChat}
//           style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }} 
//           className="p-2 rounded-full shadow-lg flex justify-between items-center">
//             <IoChatbubbleEllipsesOutline className="w-8 h-8" />
//             {!isMobileView && (
//               <div className="ml-2 mr-2">
//                 Support
//               </div>
//             )}
//         </button>
//       </div>

//       {/* Chat Interface */}
//       {isChatOpen && (
//         <div className={`fixed ${isMobileView ? 'bottom-[200px] left-[10px] right-[10px] w-auto h-auto' : 'bottom-[160px] right-4 max-w-3xl w-[600px] h-[500px]'} z-50 transform transition-transform bg-white rounded-lg shadow-md p-6`}>
//           <div className="arrow-up" style={{ borderTopColor: theme.primaryColor }}></div>
//           <div className="flex justify-between items-center mb-4">
//             <h1 className="text-lg font-semibold" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//               Chat Support
//             </h1>
//             <button onClick={toggleChat} className="text-red-500">
//               <FaTimes className="w-6 h-6" />
//             </button>
//           </div>

//           <div className={`flex ${isMobileView ? 'flex-col' : 'flex-row'} h-full overflow-hidden`}>
//             <div className={`w-full ${isMobileView ? 'h-1/2' : 'sm:w-1/2'} pr-4 sm:border-r border-gray-300 overflow-y-auto`}>
//               {selectedTopic ? (
//                 <div>
//                   <button onClick={handleBack} className="block mb-4 p-2 rounded-lg bg-blue-500 text-white" style={{ fontFamily: theme.fontFamily }}>Back</button>
//                   {Object.keys(questionsAndAnswers[selectedTopic]).map((question, index) => (
//                     <button
//                       key={index}
//                       onClick={() => handleQuestionClick(question)}
//                       className="block text-left w-full mb-2 p-3 bg-gray-100 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
//                       style={{ fontFamily: theme.fontFamily }}
//                     >
//                       {question}
//                     </button>
//                   ))}
//                 </div>
//               ) : (
//                 <div>
//                   <input
//                     type="text"
//                     placeholder="Search for a topic..."
//                     value={searchTerm}
//                     onChange={handleSearch}
//                     className="w-full p-2 mb-4 border border-gray-300 rounded-lg bg-white text-black"
//                   />
//                   {filteredTopics.map((topic, index) => (
//                     <button
//                       key={index}
//                       onClick={() => handleTopicClick(topic)}
//                       className="block text-left w-full mb-2 p-3 bg-gray-100 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
//                       style={{ fontFamily: theme.fontFamily }}
//                     >
//                       {topic}
//                     </button>
//                   ))}
//                 </div>
//               )}
//             </div>

//             {/* Answers section */}
//             <div className={`w-full ${isMobileView ? 'h-1/2' : 'sm:w-1/2'} pl-4 overflow-y-auto`}>
//               {selectedQuestion && selectedTopic && questionsAndAnswers[selectedTopic] && questionsAndAnswers[selectedTopic][selectedQuestion] ? (
//                 <div>
//                   <h2 className="text-md font-semibold mb-4" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                     {selectedQuestion}
//                   </h2>
//                   <p style={{ color: theme.textColor }}>{questionsAndAnswers[selectedTopic][selectedQuestion]}</p>
//                 </div>
//               ) : (
//                 <div className="text-center text-gray-500 border-t pt-4">
//                   Select a topic to view questions.
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Chat;

// // udpate code --> 13 june 2024
// // src/components/Chat.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// import { useTheme } from '../context/ThemeContext';
// import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
// import { FaTimes } from 'react-icons/fa';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import { questionsAndAnswers, topics } from './chat/ChatData'; // Importing data
// import './Chat.css'; // Import CSS for animation

// // Main Chat component
// const Chat: React.FC = () => {
//   const [isChatOpen, setIsChatOpen] = useState(false);
//   const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
//   const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const { theme } = useTheme();
//   const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 640);

//   // Update state based on window size
//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobileView(window.innerWidth <= 640);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   // Function to toggle chat visibility
//   const toggleChat = () => {
//     setIsChatOpen(!isChatOpen);
//     setSelectedTopic(null);
//     setSelectedQuestion(null);
//     setSearchTerm('');
//   };

//   // Function to handle topic selection
//   const handleTopicClick = (topic: string) => {
//     setSelectedTopic(topic);
//     setSelectedQuestion(null);
//   };

//   // Function to handle question selection
//   const handleQuestionClick = (question: string) => {
//     setSelectedQuestion(question);
//   };

//   // Function to go back to the previous view
//   const handleBack = () => {
//     setSelectedTopic(null);
//   };

//   // Function to handle search
//   const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   // Filter topics based on search term
//   const filteredTopics = topics.filter((topic) =>
//     topic.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div>
//       {/* Dark Background Overlay */}
//       {isChatOpen && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
//       )}

//       {/* Support Chat Icon */}
//       <div className="fixed bottom-[92px] right-[20px] z-50">
//         <button 
//           onClick={toggleChat}
//           style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }} 
//           className="p-2 rounded-full shadow-lg flex justify-between items-center">
//             <IoChatbubbleEllipsesOutline className="w-8 h-8" />
//             {!isMobileView && (
//               <div className="ml-2 mr-2">
//                 Support
//               </div>
//             )}
//         </button>
//       </div>

//       {/* Chat Interface */}
//       <CSSTransition in={isChatOpen} timeout={300} classNames="chat" unmountOnExit>
//         <div className={`fixed ${isMobileView ? 'bottom-[200px] left-[10px] right-[10px] w-auto h-auto' : 'bottom-[160px] right-4 max-w-3xl w-[600px] h-[500px]'} z-50 transform transition-transform bg-white rounded-lg shadow-md p-6`}>
//           <div className="arrow-up" style={{ borderTopColor: theme.primaryColor }}></div>
//           <div className="flex justify-between items-center mb-4">
//             <h1 className="text-lg font-semibold" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//               Chat Support
//             </h1>
//             <button onClick={toggleChat} className="text-red-500">
//               <FaTimes className="w-6 h-6" />
//             </button>
//           </div>

          
//           <div className={`flex ${isMobileView ? 'flex-col' : 'flex-row'} h-full overflow-hidden`}>
//             <div className={`w-full ${isMobileView ? 'h-1/2' : 'sm:w-1/2'} pr-4 sm:border-r border-gray-300 overflow-y-auto`}>
//               {selectedTopic ? (
//                 <div>
//                   <button onClick={handleBack} className="block mb-4 p-2 rounded-lg bg-blue-500 text-white" style={{ fontFamily: theme.fontFamily }}>Back</button>
//                   <TransitionGroup>
//                     {Object.keys(questionsAndAnswers[selectedTopic]).map((question, index) => (
//                       <CSSTransition key={index} timeout={300} classNames="fade">
//                         <button
//                           onClick={() => handleQuestionClick(question)}
//                           className="block text-left w-full mb-2 p-3 bg-gray-100 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
//                           style={{ fontFamily: theme.fontFamily }}
//                         >
//                           {question}
//                         </button>
//                       </CSSTransition>
//                     ))}
//                   </TransitionGroup>
//                 </div>
//               ) : (
//                 <div>
//                   <input
//                     type="text"
//                     placeholder="Search for a topic..."
//                     value={searchTerm}
//                     onChange={handleSearch}
//                     className="w-full p-2 mb-4 border border-gray-300 rounded-lg bg-white text-black"
//                   />
//                   <TransitionGroup>
//                     {filteredTopics.map((topic, index) => (
//                       <CSSTransition key={index} timeout={300} classNames="fade">
//                         <button
//                           onClick={() => handleTopicClick(topic)}
//                           className="block text-left w-full mb-2 p-3 bg-gray-100 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
//                           style={{ fontFamily: theme.fontFamily }}
//                         >
//                           {topic}
//                         </button>
//                       </CSSTransition>
//                     ))}
//                   </TransitionGroup>
//                 </div>
//               )}
//             </div>

//             {/* Answers section */}
//             <div className={`w-full ${isMobileView ? 'h-1/2' : 'sm:w-1/2'} pl-4 overflow-y-auto`}>
//               {selectedQuestion && selectedTopic && questionsAndAnswers[selectedTopic] && questionsAndAnswers[selectedTopic][selectedQuestion] ? (
//                 <CSSTransition in={!!selectedQuestion} timeout={300} classNames="fade" unmountOnExit>
//                   <div>
//                     <h2 className="text-md font-semibold mb-4" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                       {selectedQuestion}
//                     </h2>
//                     <p style={{ color: theme.textColor }}>{questionsAndAnswers[selectedTopic][selectedQuestion]}</p>
//                   </div>
//                 </CSSTransition>
//               ) : (
//                 <div className="text-center text-gray-500 border-t pt-4">
//                   Select a topic to view questions.
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </CSSTransition>
//     </div>
//   );
// };

// export default Chat;

// // update code --> 30 May 2024
// // src/components/Chat.tsx

// // import react components
// import React, { useState, useEffect } from 'react';
// import { useTheme } from '../context/ThemeContext';
// import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
// import { FaTimes } from 'react-icons/fa';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import { questionsAndAnswers, topics } from './chat/ChatData'; // Importing data
// import './Chat.css'; // Import CSS for animation

// // Main Chat component
// const Chat: React.FC = () => {
//   const [isChatOpen, setIsChatOpen] = useState(false);
//   const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
//   const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const { theme } = useTheme();
//   const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 640);

//   // Update state based on window size
//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobileView(window.innerWidth <= 640);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   // Function to toggle chat visibility
//   const toggleChat = () => {
//     setIsChatOpen(!isChatOpen);
//     setSelectedTopic(null);
//     setSelectedQuestion(null);
//     setSearchTerm('');
//   };

//   // Function to handle topic selection
//   const handleTopicClick = (topic: string) => {
//     setSelectedTopic(topic);
//     setSelectedQuestion(null);
//   };

//   // Function to handle question selection
//   const handleQuestionClick = (question: string) => {
//     setSelectedQuestion(question);
//   };

//   // Function to go back to the previous view
//   const handleBack = () => {
//     setSelectedTopic(null);
//   };

//   // Function to handle search
//   const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   // Filter topics based on search term
//   const filteredTopics = topics.filter((topic) =>
//     topic.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div>
//       {/* Support Chat Icon */}
//       <div className="fixed bottom-[80px] right-[50px] z-50">
//         <button 
//           onClick={toggleChat}
//           style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }} 
//           className="p-2 rounded-full shadow-lg flex justify-between items-center">
//             <IoChatbubbleEllipsesOutline className="w-8 h-8" />
//             {!isMobileView && (
//               <div className="ml-2 mr-2">
//                 Support
//               </div>
//             )}
//         </button>
//       </div>

//       {/* Chat Interface */}
//       <CSSTransition in={isChatOpen} timeout={300} classNames="chat" unmountOnExit>
//         <div className={`fixed ${isMobileView ? 'bottom-0 right-0 w-full h-full' : 'bottom-[150px] right-4 max-w-3xl w-full sm:w-96 h-[600px] sm:h-[600px]'} z-50 transform transition-transform bg-white rounded-lg shadow-md p-6`}>
//           <div className="arrow-up" style={{ borderTopColor: theme.primaryColor }}></div>
//           <div className="flex justify-between items-center mb-4">
//             <h1 className="text-lg font-semibold" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//               Chat Support
//             </h1>
//             <button onClick={toggleChat} className="text-red-500">
//               <FaTimes className="w-6 h-6" />
//             </button>
//           </div>

//           <div className={`flex ${isMobileView ? 'flex-col' : 'flex-row'} h-full overflow-hidden`}>
//             <div className={`w-full ${isMobileView ? 'h-1/2' : 'sm:w-1/2'} pr-4 sm:border-r border-gray-300 overflow-y-auto`}>
//               {selectedTopic ? (
//                 <div>
//                   <button onClick={handleBack} className="block mb-4 p-2 rounded-lg bg-blue-500 text-white" style={{ fontFamily: theme.fontFamily }}>Back</button>
//                   <TransitionGroup>
//                     {Object.keys(questionsAndAnswers[selectedTopic]).map((question, index) => (
//                       <CSSTransition key={index} timeout={300} classNames="fade">
//                         <button
//                           onClick={() => handleQuestionClick(question)}
//                           className="block text-left w-full mb-2 p-3 bg-gray-100 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
//                           style={{ fontFamily: theme.fontFamily }}
//                         >
//                           {question}
//                         </button>
//                       </CSSTransition>
//                     ))}
//                   </TransitionGroup>
//                 </div>
//               ) : (
//                 <div>
//                   <input
//                     type="text"
//                     placeholder="Search for a topic..."
//                     value={searchTerm}
//                     onChange={handleSearch}
//                     className="w-full p-2 mb-4 border border-gray-300 rounded-lg bg-white text-black"
//                   />
//                   <TransitionGroup>
//                     {filteredTopics.map((topic, index) => (
//                       <CSSTransition key={index} timeout={300} classNames="fade">
//                         <button
//                           onClick={() => handleTopicClick(topic)}
//                           className="block text-left w-full mb-2 p-3 bg-gray-100 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
//                           style={{ fontFamily: theme.fontFamily }}
//                         >
//                           {topic}
//                         </button>
//                       </CSSTransition>
//                     ))}
//                   </TransitionGroup>
//                 </div>
//               )}
//             </div>
//             <div className={`w-full ${isMobileView ? 'h-1/2' : 'sm:w-1/2'} pl-4 overflow-y-auto`}>
//               {selectedQuestion && selectedTopic && questionsAndAnswers[selectedTopic] && questionsAndAnswers[selectedTopic][selectedQuestion] ? (
//                 <CSSTransition in={!!selectedQuestion} timeout={300} classNames="fade" unmountOnExit>
//                   <div>
//                     <h2 className="text-md font-semibold mb-4" style={{ color: theme.textColor, fontFamily: theme.fontFamily }}>
//                       {selectedQuestion}
//                     </h2>
//                     <p style={{ color: theme.textColor }}>{questionsAndAnswers[selectedTopic][selectedQuestion]}</p>
//                   </div>
//                 </CSSTransition>
//               ) : (
//                 <div className="text-center text-gray-500 border-t pt-4">
//                   Select a topic to view questions.
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </CSSTransition>
//     </div>
//   );
// };

// export default Chat;
