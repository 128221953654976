// src/components/client/Dashboard/UserInfo.tsx

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TbBadges } from 'react-icons/tb';
import { GiAchievement } from 'react-icons/gi';
import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
import UserInfoEdit from './UserInfoEdit';
import { useTheme } from '../../../context/ThemeContext';
import { useRanking } from '../../../context/RankingContext';
import maleAvatar from '../../../assets/usrDashboard/avatar_male.png';
import femaleAvatar from '../../../assets/usrDashboard/avatar_female.png';

const UserInfo: React.FC = () => {
  const { user } = useUserDashboard();
  const { theme } = useTheme();
  const { stages, badges, getStageColor, getBadgeColor } = useRanking();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState<{ badges: boolean; stages: boolean }>({
    badges: false,
    stages: false,
  });

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    avatar: '',
    telegram_id: '',
    discord_id: '',
    gender: '',
  });

  const [isEditing, setIsEditing] = useState({
    name: false,
    phone: false,
    address: false,
    telegram_id: false,
    discord_id: false,
  });

  useEffect(() => {
    if (user) {
      setFormValues({
        name: user.username,
        email: user.email,
        phone: user.phone || '',
        address: user.address || '',
        avatar: user.profile_image,
        telegram_id: user.telegram_id || '',
        discord_id: user.discord_id || '',
        gender: user.gender,
      });
    }
  }, [user]);

  if (!user) {
    return null;
  }

  const showModal = () => {
    setIsModalVisible(true);
    setIsEditing({
      name: false,
      phone: false,
      address: false,
      telegram_id: false,
      discord_id: false,
    });
  };

  const handleSave = (field: string) => {
    setIsEditing({ ...isEditing, [field]: false });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Updated values:', formValues);
    setIsModalVisible(false);
  };

  const togglePopover = (field: 'badges' | 'stages') => {
    setIsPopoverVisible((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const closePopovers = () => {
    setIsPopoverVisible({ badges: false, stages: false });
  };  

  const renderStepper = (steps: string[], currentStep: number) => {
    return (
      <div className="flex space-x-2 mt-2">
        {steps.map((step, index) => (
          <div key={index} className={`flex-1 text-center py-1 rounded ${index < currentStep ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>
            {step}
          </div>
        ))}
      </div>
    );
  };

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  return (
    <>
      {/* Profile Stats */}
      <div className="h-full w-auto bg-white rounded-lg overflow-hidden shadow-lg">
        <div className="h-[300px] text-center p-6 bg-gray-800 border-b" style={{ backgroundColor: getStageColor(user.stages) }}>
          <img src={user.gender === 'male' ? maleAvatar : femaleAvatar} alt="User Avatar" className="h-[150px] w-[150px] rounded-full mx-auto" />
          <p className="pt-2 text-lg font-semibold text-gray-50">{user.username}</p>
          <p className="text-sm text-gray-100">{user.email}</p>
          <div className="mt-4">
            <button onClick={showModal} className="border rounded-full py-2 px-4 text-xs font-semibold text-gray-100">
              Edit Profile
            </button>
          </div>
        </div>

        {/* Badges Stats */}
        <div className="my-2">
          <button type="button" onClick={() => togglePopover('badges')} className="px-4 py-2 hover:bg-slate-100 flex w-full text-left">
            <div className="align-items-center flex w-full">
              <div style={{ color: getBadgeColor(user.badges) }}>
                <TbBadges size={36} />
              </div>
              <div className="pl-3 flex-1">
                <p className="text-md font-bold leading-none rounded p-1 text-center" style={{ backgroundColor: getBadgeColor(user.badges) }}>
                  {user.badges}
                </p>
                <p className="text-xs text-center" style={styles.title}>
                  View your current Badges
                </p>
              </div>
            </div>
          </button>

          {/* Overlay */}
          <AnimatePresence>
            {(isPopoverVisible.badges || isPopoverVisible.stages) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                exit={{ opacity: 0 }}
                onClick={closePopovers}
                className="fixed inset-0 bg-black bg-opacity-50 z-40"
              />
            )}
          </AnimatePresence>

          {/* Badges Stepper Popover */}
          <AnimatePresence>
            {isPopoverVisible.badges && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="absolute z-50 inline-block w-[385px] xs:w-[350px] text-sm transition-opacity duration-300 border-2 border-gray-500 rounded-lg bg-white"
              >
                <div className="px-3 py-2 border-b border-gray-400 rounded-t-md">
                  <h3 className="font-semibold md:text-sm xs:text-sm"style={styles.title}>Badges Details</h3>
                </div>
                <div className="px-3 py-2 text-xs">
                  {renderStepper(badges.map((b) => b.name), badges.findIndex((b) => b.name === user.badges) + 1)}
                </div>
                <div className="px-3 py-2">
                  <p className="text-base py-2 md:text-sm sm:text-xs xs:text-xs">
                  <strong style={styles.title}>Current Badges:</strong>
                  <span className="p-2 rounded-full ml-2 text-sm px-4 md:text-xs sm:text-xs xs:text-xs" style={styles.button}>
                    {user.badges}
                  </span>
                  </p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          
          <div className="border-t"></div>

          {/* Account Stats */}
          <button type="button" onClick={() => togglePopover('stages')} className="px-4 py-2 hover:bg-slate-100 flex w-full text-left">
            <div className="align-items-center flex w-full">
              <div className="text-rose-600">
                <GiAchievement size={36} />
              </div>
              <div className="pl-3 flex-1">
                <p className="text-md font-medium leading-none rounded p-1 text-center text-white" style={{ backgroundColor: getStageColor(user.stages) }}>
                  {user.stages}
                </p>
                <p className="text-xs text-center" style={styles.title}>
                  View your account stages
                </p>
              </div>
            </div>
          </button>

          {/* Overlay */}
          <AnimatePresence>
            {(isPopoverVisible.badges || isPopoverVisible.stages) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                exit={{ opacity: 0 }}
                onClick={closePopovers}
                className="fixed inset-0 bg-black bg-opacity-50 z-40"
              />
            )}
          </AnimatePresence>

          {/* Account Stepper Popover */}
          <AnimatePresence>
            {isPopoverVisible.stages && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="absolute z-50 inline-block w-[385px] xs:w-[350px] text-sm transition-opacity duration-300 border-2 border-gray-500 rounded-lg bg-white"
              >
                <div className="px-3 py-2 border-b border-gray-400 rounded-t-md">
                  <h3 className="font-semibold md:text-sm xs:text-sm" style={styles.title}>Stages Details</h3>
                </div>
                <div className="px-4 py-2 text-xs">
                  {renderStepper(stages.map((s) => s.name), stages.findIndex((s) => s.name === user.stages) + 1)}
                </div>
                <div className="px-3 py-2">
                  <p className="text-base py-2 md:text-sm sm:text-xs xs:text-xs">
                    <strong style={styles.title}>Current Stages:</strong>
                    <span className="p-2 px-4 rounded-full ml-2 md:text-sm sm:text-xs xs:text-xs" style={styles.button}>
                      {user.stages}
                    </span>
                  </p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      
      {/* Edit Profile Modal */}
      {isModalVisible && (
        <UserInfoEdit
          formValues={formValues}
          setFormValues={setFormValues}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleSave={handleSave}
          handleSubmit={handleSubmit}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </>
  );
};

export default UserInfo;



// // update code --> 12 june 2024 ver 1.2
// // src/components/client/Dashboard/Userinfo.tsx

// import React, { useState, useEffect } from 'react';
// import { TbBadges } from 'react-icons/tb';
// import { GiAchievement } from 'react-icons/gi';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import UserInfoEdit from './UserInfoEdit';
// import maleAvatar from '../../../assets/usrDashboard/avatar_male.png';
// import femaleAvatar from '../../../assets/usrDashboard/avatar_female.png';
// import { useTheme } from '../../../context/ThemeContext';

// const UserInfo: React.FC = () => {
//   const { user } = useUserDashboard();
//   const { theme } = useTheme();

//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isPopoverVisible, setIsPopoverVisible] = useState<{ badges: boolean; stages: boolean }>({
//     badges: false,
//     stages: false,
//   });

//   const [formValues, setFormValues] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     address: '',
//     avatar: '',
//     telegram_id: '',
//     discord_id: '',
//     gender: '',
//   });

//   const [isEditing, setIsEditing] = useState({
//     name: false,
//     phone: false,
//     address: false,
//     telegram_id: false,
//     discord_id: false,
//   });

//   useEffect(() => {
//     if (user) {
//       setFormValues({
//         name: user.username,
//         email: user.email,
//         phone: user.phone || '',
//         address: user.address || '',
//         avatar: user.profile_image,
//         telegram_id: user.telegram_id || '',
//         discord_id: user.discord_id || '',
//         gender: user.gender,
//       });
//     }
//   }, [user]);

//   if (!user) {
//     return null;
//   }

//   const showModal = () => {
//     setIsModalVisible(true);
//     setIsEditing({
//       name: false,
//       phone: false,
//       address: false,
//       telegram_id: false,
//       discord_id: false,
//     });
//   };

//   const handleSave = (field: string) => {
//     setIsEditing({ ...isEditing, [field]: false });
//   };

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     console.log('Updated values:', formValues);
//     setIsModalVisible(false);
//   };

//   const togglePopover = (field: 'badges' | 'stages') => {
//     setIsPopoverVisible((prev) => ({
//       ...prev,
//       [field]: !prev[field],
//     }));
//   };

//   const renderStepper = (steps: string[], currentStep: number) => {
//     return (
//       <div className="flex space-x-2 mt-2">
//         {steps.map((step, index) => (
//           <div key={index} className={`flex-1 text-center py-1 rounded ${index < currentStep ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>
//             {step}
//           </div>
//         ))}
//       </div>
//     );
//   };

//   const stagesSteps = [
//     { name: 'R One Guest', color: theme.stages.guest }, // Modification: Updated stage name and color
//     { name: 'R One Member', color: theme.stages.member }, // Modification: Updated stage name and color
//     { name: 'R One Elite Trader', color: theme.stages.eliteTrader }, // Modification: Updated stage name and color
//     { name: 'R One Master Trader', color: theme.stages.masterTrader }, // Modification: Updated stage name and color
//     { name: 'R One Certified Trader', color: theme.stages.certifiedTrader }, // Modification: Updated stage name and color
//   ];

//   const badgesSteps = [
//     { name: 'Copper', color: theme.badges.copper }, // Modification: Updated badge name and color
//     { name: 'Bronze', color: theme.badges.bronze }, // Modification: Updated badge name and color
//     { name: 'Silver', color: theme.badges.silver }, // Modification: Updated badge name and color
//     { name: 'Gold', color: theme.badges.gold }, // Modification: Updated badge name and color
//     { name: 'Diamond', color: theme.badges.diamond }, // Modification: Updated badge name and color
//   ];

//   const getStageColor = (stage: string) => {
//     const stageObj = stagesSteps.find((s) => s.name === stage);
//     return stageObj ? stageObj.color : 'gray';
//   };

//   const getBadgeColor = (badge: string) => {
//     const badgeObj = badgesSteps.find((b) => b.name === badge);
//     return badgeObj ? badgeObj.color : 'gray';
//   };

//   return (
//     <>
//       <div className="h-full bg-white rounded-lg overflow-hidden shadow-lg">
//         <div
//           className="text-center p-6 bg-gray-800 border-b"
//           style={{ backgroundColor: getStageColor(user.stages) }}
//         >
//           <img
//             src={user.gender === 'male' ? maleAvatar : femaleAvatar}
//             alt="User Avatar"
//             className="h-24 w-24 text-white rounded-full mx-auto"
//           />
//           <p className="pt-2 text-lg font-semibold text-gray-50">{user.username}</p>
//           <p className="text-sm text-gray-100">{user.email}</p>
//           <div className="mt-4">
//             <button
//               onClick={showModal}
//               className="border rounded-full py-2 px-4 text-xs font-semibold text-gray-100"
//             >
//               Edit Profile
//             </button>
//           </div>
//         </div>
//         <div className="mt-3">
//           <button
//             type="button"
//             onClick={() => togglePopover('badges')}
//             className="px-4 py-2 hover:bg-gray-100 flex w-full text-left"
//           >
//             <div
//               className="align-items-center flex"
//               style={{ color: getBadgeColor(user.badges) }} // Comment: Updated badge color logic
//             >
//               <TbBadges size={24} />
//             </div>
//             <div className="pl-3 flex-1">
//               <p className="text-md font-bold leading-none rounded p-1" style={{ backgroundColor: getBadgeColor(user.badges) }}>{user.badges}</p>
//               <p className="text-xs text-gray-500">View your current Badges</p>
//             </div>
//           </button>
//           {isPopoverVisible.badges && (
//             <div className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100">
//               <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
//                 <h3 className="font-semibold text-gray-900">Badges Details</h3>
//               </div>
//               <div className="px-3 py-2">
//                 {renderStepper(badgesSteps.map((b) => b.name), badgesSteps.findIndex((b) => b.name === user.badges) + 1)}
//               </div>
//               <div className="px-3 py-2">
//                 <p>Current Badges: {user.badges}</p>
//               </div>
//               <div data-popper-arrow></div>
//             </div>
//           )}
//           <div className="border-t"></div>
//           <button
//             type="button"
//             onClick={() => togglePopover('stages')}
//             className="px-4 py-2 mb-2 hover:bg-gray-100 flex w-full text-left"
//           >
//             <div className="text-red-800 align-items-center flex">
//               <GiAchievement size={24} />
//             </div>
//             <div className="pl-3 flex-1">
//               <p
//                 className="text-md font-bold leading-none rounded p-1"
//                 style={{ color: getStageColor(user.stages) }} // Comment: Updated stage color logic
//               >
//                 {user.stages}
//               </p>
//               <p className="text-xs text-gray-500">View your account stages</p>
//             </div>
//           </button>
//           {isPopoverVisible.stages && (
//             <div className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100">
//               <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
//                 <h3 className="font-semibold text-gray-900">Stages Details</h3>
//               </div>
//               <div className="px-3 py-2">
//                 {renderStepper(stagesSteps.map((s) => s.name), stagesSteps.findIndex((s) => s.name === user.stages) + 1)}
//               </div>
//               <div className="px-3 py-2">
//                 <p className="text-md font-bold text-red-500">{user.stages}</p>
//               </div>
//               <div data-popper-arrow></div>
//             </div>
//           )}
//         </div>
//       </div>

//       {isModalVisible && (
//         <UserInfoEdit
//           formValues={formValues}
//           setFormValues={setFormValues}
//           isEditing={isEditing}
//           setIsEditing={setIsEditing}
//           handleSave={handleSave}
//           handleSubmit={handleSubmit}
//           setIsModalVisible={setIsModalVisible}
//         />
//       )}
//     </>
//   );
// };

// export default UserInfo;


// // udpate code --> 12 june 2024 ver 1.1
// // src/components/client/Dashboard/UserInfo.tsx

// import React, { useState, useEffect } from 'react';
// import { TbBadges } from 'react-icons/tb';
// import { GiAchievement } from 'react-icons/gi';
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import UserInfoEdit from './UserInfoEdit';
// import maleAvatar from '../../../assets/usrDashboard/avatar_male.png';
// import femaleAvatar from '../../../assets/usrDashboard/avatar_female.png';

// const UserInfo: React.FC = () => {
//   const { user } = useUserDashboard(); // Fetch user data

//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isPopoverVisible, setIsPopoverVisible] = useState<{ badges: boolean; stages: boolean }>({
//     badges: false,
//     stages: false,
//   });

//   const [formValues, setFormValues] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     address: '',
//     avatar: '',
//     telegram_id: '',
//     discord_id: '',
//     gender: '',
//   });

//   const [isEditing, setIsEditing] = useState({
//     name: false,
//     phone: false,
//     address: false,
//     telegram_id: false, // Added telegram_id
//     discord_id: false, // Added discord_id
//   });

//   useEffect(() => {
//     if (user) {
//       setFormValues({
//         name: user.username,
//         email: user.email,
//         phone: user.phone || '', // Set phone value
//         address: user.address || '', // Set address value
//         avatar: user.profile_image,
//         telegram_id: user.telegram_id || '', // Set telegram_id value
//         discord_id: user.discord_id || '', // Set discord_id value
//         gender: user.gender,
//       });
//     }
//   }, [user]);

//   if (!user) {
//     return null;
//   }

//   const showModal = () => {
//     setIsModalVisible(true);
//     setIsEditing({
//       name: false,
//       phone: false,
//       address: false,
//       telegram_id: false, // Added telegram_id
//       discord_id: false, // Added discord_id
//     });
//   };

//   const handleSave = (field: string) => {
//     setIsEditing({ ...isEditing, [field]: false });
//   };

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     console.log('Updated values:', formValues);
//     setIsModalVisible(false);
//   };

//   const togglePopover = (field: 'badges' | 'stages') => {
//     setIsPopoverVisible((prev) => ({
//       ...prev,
//       [field]: !prev[field],
//     }));
//   };

//   const renderStepper = (steps: string[], currentStep: number) => {
//     return (
//       <div className="flex space-x-2 mt-2">
//         {steps.map((step, index) => (
//           <div key={index} className={`flex-1 text-center py-1 rounded ${index < currentStep ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>
//             {step}
//           </div>
//         ))}
//       </div>
//     );
//   };

//   const stagesSteps = ['R One Guest', 'R One Member', 'R One Elite Trader', 'R One Master Trader', 'R One Certified Trader'];
//   const badgesSteps = ['Bronze', 'Silver', 'Gold', 'Diamond'];

//   return (
//     <>
//       <div className="h-full bg-white rounded-lg overflow-hidden shadow-lg">
//         <div className="text-center p-6 bg-gray-800 border-b">
//           <img
//             src={user.gender === 'male' ? maleAvatar : femaleAvatar} // Modification: Use appropriate avatar
//             alt="User Avatar"
//             className="h-24 w-24 text-white rounded-full mx-auto"
//           />
//           <p className="pt-2 text-lg font-semibold text-gray-50">{user.username}</p>
//           <p className="text-sm text-gray-100">{user.email}</p>
//           <div className="mt-4">
//             <button
//               onClick={showModal}
//               className="border rounded-full py-2 px-4 text-xs font-semibold text-gray-100"
//             >
//               Edit Profile
//             </button>
//           </div>
//         </div>
//         <div className="mt-3">
//           <button type="button" onClick={() => togglePopover('badges')} className="px-4 py-2 hover:bg-gray-100 flex w-full text-left">
//             <div className="text-yellow-600 align-items-center flex">
//               <TbBadges size={24} />
//             </div>
//             <div className="pl-3 flex-1">
//               <p className="text-md font-bold leading-none bg-yellow-500 rounded p-1">{user.badges}</p>
//               <p className="text-xs text-gray-500">View your current Badges</p>
//             </div>
//           </button>
//           {isPopoverVisible.badges && (
//             <div className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100">
//               <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
//                 <h3 className="font-semibold text-gray-900">Badges Details</h3>
//               </div>
//               <div className="px-3 py-2">
//                 {renderStepper(badgesSteps, badgesSteps.indexOf(user.badges) + 1)}
//               </div>
//               <div className="px-3 py-2">
//                 <p>Current Badges: {user.badges}</p>
//               </div>
//               <div data-popper-arrow></div>
//             </div>
//           )}
//           <div className="border-t"></div>
//           <button type="button" onClick={() => togglePopover('stages')} className="px-4 py-2 mb-2 hover:bg-gray-100 flex w-full text-left">
//             <div className="text-red-800 align-items-center flex">
//               <GiAchievement size={24} />
//             </div>
//             <div className="pl-3 flex-1">
//               <p className="text-md font-bold text-gray-800 leading-none rounded p-1">{user.stages}</p>
//               <p className="text-xs text-gray-500">View your account stages</p>
//             </div>
//           </button>
//           {isPopoverVisible.stages && (
//             <div className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100">
//               <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
//                 <h3 className="font-semibold text-gray-900">Stages Details</h3>
//               </div>
//               <div className="px-3 py-2">
//                 {renderStepper(stagesSteps, stagesSteps.indexOf(user.stages) + 1)}
//               </div>
//               <div className="px-3 py-2">
//                 <p className="text-md font-bold text-red-500">{user.stages}</p>
//               </div>
//               <div data-popper-arrow></div>
//             </div>
//           )}
//         </div>
//       </div>

//       {isModalVisible && (
//         <UserInfoEdit
//           formValues={formValues}
//           setFormValues={setFormValues}
//           isEditing={isEditing}
//           setIsEditing={setIsEditing}
//           handleSave={handleSave}
//           handleSubmit={handleSubmit}
//           setIsModalVisible={setIsModalVisible}
//         />
//       )}
//     </>
//   );
// };

// export default UserInfo;



// // udpate code --> 11 june 2024
// // src/components/client/Dashboard/UserInfo.tsx

// import React, { useState, useEffect } from 'react';
// import { TbBadges } from 'react-icons/tb';
// import { GiAchievement } from 'react-icons/gi';
// import { useDummy } from '../../../context/dummy';
// import UserInfoEdit from './UserInfoEdit';

// type PopoverField = 'ranking' | 'achievement';

// const UserInfo: React.FC = () => {
//   const { getUser } = useDummy();
//   const user = getUser('zeric2tan@gmail.com'); // Replace with the logic to get the currently logged-in user's email

//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isPopoverVisible, setIsPopoverVisible] = useState<Record<PopoverField, boolean>>({
//     ranking: false,
//     achievement: false,
//   });

//   const [formValues, setFormValues] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     address: '',
//     avatar: '',
//   });

//   const [isEditing, setIsEditing] = useState({
//     name: false,
//     phone: false,
//     address: false,
//   });

//   useEffect(() => {
//     if (user) {
//       setFormValues({
//         name: user.name,
//         email: user.email,
//         phone: user.phone,
//         address: user.address,
//         avatar: user.avatar,
//       });
//     }
//   }, [user]);

//   if (!user) {
//     return null;
//   }

//   const showModal = () => {
//     setIsModalVisible(true);
//     setIsEditing({
//       name: false,
//       phone: false,
//       address: false,
//     });
//   };

//   const handleSave = (field: string) => {
//     setIsEditing({ ...isEditing, [field]: false });
//   };

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     console.log('Updated values:', formValues);
//     setIsModalVisible(false);
//   };

//   const togglePopover = (field: PopoverField) => {
//     setIsPopoverVisible((prev) => ({
//       ...prev,
//       [field]: !prev[field],
//     }));
//   };

//   const renderStepper = (steps: string[], currentStep: number) => {
//     return (
//       <div className="flex space-x-2 mt-2">
//         {steps.map((step, index) => (
//           <div key={index} className={`flex-1 text-center py-1 rounded ${index < currentStep ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>
//             {step}
//           </div>
//         ))}
//       </div>
//     );
//   };

//   const rankingSteps = ['Silver', 'Gold', 'Diamond'];
//   const achievementSteps = ['R One Elite Trader', 'R One Master Trader', 'R One Certified Trader'];

//   return (
//     <>
//       <div className="h-full bg-white rounded-lg overflow-hidden shadow-lg">
//         <div className="text-center p-6 bg-gray-800 border-b">
//           <img
//             src="https://i.imgur.com/VHJ8zYL.png"
//             alt="User Avatar"
//             className="h-24 w-24 text-white rounded-full mx-auto"
//           />
//           <p className="pt-2 text-lg font-semibold text-gray-50">{user.name}</p>
//           <p className="text-sm text-gray-100">{user.email}</p>
//           <div className="mt-4">
//             <button
//               onClick={showModal}
//               className="border rounded-full py-2 px-4 text-xs font-semibold text-gray-100"
//             >
//               Edit Profile
//             </button>
//           </div>
//         </div>
//         <div className="mt-3">
//           <button data-popover-target="popover-ranking" data-popover-placement="right" type="button" onClick={() => togglePopover('ranking')} className="px-4 py-2 hover:bg-gray-100 flex w-full text-left">
//             <div className="text-yellow-600 align-items-center flex">
//               <TbBadges size={24} />
//             </div>
//             <div className="pl-3 flex-1">
//               <p className="text-md font-bold leading-none bg-yellow-500 rounded p-1">
//                 {user.ranking}
//               </p>
//               <p className="text-xs text-gray-500">View your current Rankings</p>
//             </div>
//           </button>
//           {isPopoverVisible.ranking && (
//             <div id="popover-ranking" className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100">
//               <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
//                 <h3 className="font-semibold text-gray-900">Ranking Details</h3>
//               </div>
//               <div className="px-3 py-2">
//                 {renderStepper(rankingSteps, rankingSteps.indexOf(user.ranking) + 1)}
//               </div>
//               <div className="px-3 py-2">
//                 <p>Current Ranking: {user.ranking}</p>
//               </div>
//               <div data-popper-arrow></div>
//             </div>
//           )}
//           <div className="border-t"></div>
//           <button data-popover-target="popover-achievement" data-popover-placement="right" type="button" onClick={() => togglePopover('achievement')} className="px-4 py-2 mb-2 hover:bg-gray-100 flex w-full text-left">
//             <div className="text-red-800 align-items-center flex">
//               <GiAchievement size={24} />
//             </div>
//             <div className="pl-3 flex-1">
//               <p className="text-md font-bold text-gray-800 leading-none rounded p-1">{user.achievement}</p>
//               <p className="text-xs text-gray-500">View your account achievement</p>
//             </div>
//           </button>
//           {isPopoverVisible.achievement && (
//             <div id="popover-achievement" className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100">
//               <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
//                 <h3 className="font-semibold text-gray-900">Achievement Details</h3>
//               </div>
//               <div className="px-3 py-2">
//                 {renderStepper(achievementSteps, achievementSteps.indexOf(user.achievement) + 1)}
//               </div>
//               <div className="px-3 py-2">
//                 <p className="text-md font-bold text-red-500">{user.achievement}</p>
//               </div>
//               <div data-popper-arrow></div>
//             </div>
//           )}
//         </div>
//       </div>

//       {isModalVisible && (
//         <UserInfoEdit
//           formValues={formValues}
//           setFormValues={setFormValues}
//           isEditing={isEditing}
//           setIsEditing={setIsEditing}
//           handleSave={handleSave}
//           handleSubmit={handleSubmit}
//           setIsModalVisible={setIsModalVisible}
//         />
//       )}
//     </>
//   );
// };

// export default UserInfo;




// // update code --> 04 June 2024
// // src/components/client/Dashboard/UserInfo.tsx

// import React, { useState, useEffect } from 'react';
// import { FaLevelUpAlt } from 'react-icons/fa';
// import { TbBadges } from 'react-icons/tb';
// import { GiAchievement } from 'react-icons/gi';
// import { useDummy } from '../../../context/dummy';
// import UserInfoEdit from './UserInfoEdit';

// type PopoverField = 'ranking' | 'level' | 'achievement';

// const UserInfo: React.FC = () => {
//   const { getUser } = useDummy();
//   const user = getUser('zeric2tan@gmail.com'); // Replace with the logic to get the currently logged-in user's email

//   // Moved hooks to the top to avoid conditional usage
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isPopoverVisible, setIsPopoverVisible] = useState<Record<PopoverField, boolean>>({
//     ranking: false,
//     level: false,
//     achievement: false,
//   });

//   // State for form values and editing status
//   const [formValues, setFormValues] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     address: '',
//     avatar: '',
//   });

//   const [isEditing, setIsEditing] = useState({
//     name: false,
//     phone: false,
//     address: false,
//   });

//   useEffect(() => {
//     if (user) {
//       setFormValues({
//         name: user.name,
//         email: user.email,
//         phone: user.phone,
//         address: user.address,
//         avatar: user.avatar,
//       });
//     }
//   }, [user]);

//   // Early return if no user is found
//   if (!user) {
//     return null;
//   }

//   const showModal = () => {
//     setIsModalVisible(true);
//     setIsEditing({
//       name: false,
//       phone: false,
//       address: false,
//     });
//   };

//   const handleSave = (field: string) => {
//     setIsEditing({ ...isEditing, [field]: false });
//   };

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     console.log('Updated values:', formValues);
//     setIsModalVisible(false);
//   };

//   const togglePopover = (field: PopoverField) => {
//     setIsPopoverVisible((prev) => ({
//       ...prev,
//       [field]: !prev[field],
//     }));
//   };

//   const renderStepper = (steps: string[], currentStep: number) => {
//     return (
//       <div className="flex space-x-2 mt-2">
//         {steps.map((step, index) => (
//           <div key={index} className={`flex-1 text-center py-1 rounded ${index < currentStep ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>
//             {step}
//           </div>
//         ))}
//       </div>
//     );
//   };

//   const rankingSteps = ['Bronze', 'Silver', 'Gold', 'Platinum'];
//   const levelSteps = Array.from({ length: 10 }, (_, i) => (i + 1).toString());
//   const achievementSteps = ['R One Member', 'R One Senior', 'R One Coach'];

//   return (
//     <>
//       <div className="h-full bg-white rounded-lg overflow-hidden shadow-lg">
//         <div className="text-center p-6 bg-gray-800 border-b">
//           <img
//             src="https://i.imgur.com/VHJ8zYL.png"
//             alt="User Avatar"
//             className="h-24 w-24 text-white rounded-full mx-auto"
//           />
//           <p className="pt-2 text-lg font-semibold text-gray-50">{user.name}</p>
//           <p className="text-sm text-gray-100">{user.email}</p>
//           <div className="mt-4">
//             <button
//               onClick={showModal}
//               className="border rounded-full py-2 px-4 text-xs font-semibold text-gray-100"
//             >
//               Edit Profile
//             </button>
//           </div>
//         </div>
//         <div className="mt-3">
//           <button data-popover-target="popover-ranking" data-popover-placement="right" type="button" onClick={() => togglePopover('ranking')} className="px-4 py-2 hover:bg-gray-100 flex w-full text-left">
//             <div className="text-yellow-600 align-items-center flex">
//               <TbBadges size={24} />
//             </div>
//             <div className="pl-3 flex-1">
//               <p className="text-md font-bold leading-none bg-yellow-500 rounded p-1">
//                 {user.ranking}
//               </p>
//               <p className="text-xs text-gray-500">View your current Rankings</p>
//             </div>
//           </button>
//           {isPopoverVisible.ranking && (
//             <div id="popover-ranking" className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100">
//               <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
//                 <h3 className="font-semibold text-gray-900">Ranking Details</h3>
//               </div>
//               <div className="px-3 py-2">
//                 {renderStepper(rankingSteps, rankingSteps.indexOf(user.ranking) + 1)}
//               </div>
//               <div className="px-3 py-2">
//                 <p>Current Ranking: {user.ranking}</p>
//               </div>
//               <div data-popper-arrow></div>
//             </div>
//           )}
//           <div className="border-t"></div>
//           <button data-popover-target="popover-level" data-popover-placement="right" type="button" onClick={() => togglePopover('level')} className="px-4 py-2 hover:bg-gray-100 flex w-full text-left">
//             <div className="text-green-800 align-items-center flex">
//               <FaLevelUpAlt size={24} />
//             </div>
//             <div className="pl-3 flex-1">
//               <p className="text-md font-bold text-gray-800 leading-none rounded p-1">{user.level} Lv.</p>
//               <p className="text-xs text-gray-500">View your current level</p>
//             </div>
//           </button>
//           {isPopoverVisible.level && (
//             <div id="popover-level" className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100">
//               <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
//                 <h3 className="font-semibold text-gray-900">Level Details</h3>
//               </div>
//               <div className="px-3 py-2">
//                 {renderStepper(levelSteps, user.level)}
//               </div>
//               <div className="px-3 py-2">
//                 <p>Current Level: {user.level} Lv.</p>
//               </div>
//               <div data-popper-arrow></div>
//             </div>
//           )}
//           <div className="border-t"></div>
//           <button data-popover-target="popover-achievement" data-popover-placement="right" type="button" onClick={() => togglePopover('achievement')} className="px-4 py-2 mb-2 hover:bg-gray-100 flex w-full text-left">
//             <div className="text-red-800 align-items-center flex">
//               <GiAchievement size={24} />
//             </div>
//             <div className="pl-3 flex-1">
//               <p className="text-md font-bold text-gray-800 leading-none rounded p-1">{user.achievement}</p>
//               <p className="text-xs text-gray-500">View your account achievement</p>
//             </div>
//           </button>
//           {isPopoverVisible.achievement && (
//             <div id="popover-achievement" className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100">
//               <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
//                 <h3 className="font-semibold text-gray-900">Achievement Details</h3>
//               </div>
//               <div className="px-3 py-2">
//                 {renderStepper(achievementSteps, achievementSteps.indexOf(user.achievement) + 1)}
//               </div>
//               <div className="px-3 py-2">
//                 <p className="text-md font-bold text-red-500">{user.achievement}</p>
//               </div>
//               <div data-popper-arrow></div>
//             </div>
//           )}
//         </div>
//       </div>

//       {isModalVisible && (
//         <UserInfoEdit
//           formValues={formValues}
//           setFormValues={setFormValues}
//           isEditing={isEditing}
//           setIsEditing={setIsEditing}
//           handleSave={handleSave}
//           handleSubmit={handleSubmit}
//           setIsModalVisible={setIsModalVisible}
//         />
//       )}
//     </>
//   );
// };

// export default UserInfo;


// // update code --> 29 May 2024
// // src/components/client/Dashboard/UserInfo.tsx

// // import react components
// import React, { useState } from 'react';

// // import react icon
// import { FaLevelUpAlt } from 'react-icons/fa';
// import { TbBadges } from "react-icons/tb";
// import { GiAchievement } from "react-icons/gi";

// // import file from source
// import { useDummy } from '../../../context/dummy';
// import UserInfoEdit from './UserInfoEdit';

// type PopoverField = 'ranking' | 'level' | 'achievement';

// const UserInfo = () => {
//   const { user } = useDummy();
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isPopoverVisible, setIsPopoverVisible] = useState<Record<PopoverField, boolean>>({
//     ranking: false,
//     level: false,
//     achievement: false,
//   });

//   const [formValues, setFormValues] = useState({
//     name: user.name,
//     email: user.email,
//     phone: user.phone,
//     address: user.address,
//     avatar: user.avatar,
//   });
//   const [isEditing, setIsEditing] = useState({
//     name: false,
//     phone: false,
//     address: false,
//   });

//   const showModal = () => {
//     setIsModalVisible(true);
//     setIsEditing({
//       name: false,
//       phone: false,
//       address: false,
//     });
//   };

//   const handleSave = (field: string) => {
//     setIsEditing({ ...isEditing, [field]: false });
//   };

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     console.log('Updated values:', formValues);
//     setIsModalVisible(false);
//   };

//   const togglePopover = (field: PopoverField) => {
//     setIsPopoverVisible((prev) => ({
//       ...prev,
//       [field]: !prev[field],
//     }));
//   };

//   const renderStepper = (steps: string[], currentStep: number) => {
//     return (
//       <div className="flex space-x-2 mt-2">
//         {steps.map((step, index) => (
//           <div key={index} className={`flex-1 text-center py-1 rounded ${index < currentStep ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>
//             {step}
//           </div>
//         ))}
//       </div>
//     );
//   };

//   const rankingSteps = ['Bronze', 'Silver', 'Gold', 'Platinum'];
//   const levelSteps = Array.from({ length: 10 }, (_, i) => (i + 1).toString());
//   const achievementSteps = ['R One Member', 'R One Senior', 'R One Coach'];

//   return (
//     <>
//         <div className="h-full bg-white rounded-lg overflow-hidden shadow-lg">
//           <div className="text-center p-6 bg-gray-800 border-b">
//             <img
//               src="https://i.imgur.com/VHJ8zYL.png"
//               alt="User Avatar"
//               className="h-24 w-24 text-white rounded-full mx-auto"
//             />
//             <p className="pt-2 text-lg font-semibold text-gray-50">{user.name}</p>
//             <p className="text-sm text-gray-100">{user.email}</p>
//             <div className="mt-4">
//               <button
//                 onClick={showModal}
//                 className="border rounded-full py-2 px-4 text-xs font-semibold text-gray-100"
//               >
//                 Edit Profile
//               </button>
//             </div>
//           </div>
//           <div className="mt-3">
//             <button data-popover-target="popover-ranking" data-popover-placement="right" type="button" onClick={() => togglePopover('ranking')} className="px-4 py-2 hover:bg-gray-100 flex w-full text-left">
//               <div className="text-yellow-600 align-items-center flex">
//                 <TbBadges size={24} />
//               </div>
//               <div className="pl-3 flex-1">
//                 <p className="text-md font-bold leading-none bg-yellow-500 rounded p-1">
//                   {user.ranking}
//                 </p>
//                 <p className="text-xs text-gray-500">View your current Rankings</p>
//               </div>
//             </button>
//             {isPopoverVisible.ranking && (
//               <div id="popover-ranking" className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100">
//                 <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
//                   <h3 className="font-semibold text-gray-900">Ranking Details</h3>
//                 </div>
//                 <div className="px-3 py-2">
//                   {renderStepper(rankingSteps, rankingSteps.indexOf(user.ranking) + 1)}
//                 </div>
//                 <div className="px-3 py-2">
//                   <p>Current Ranking: {user.ranking}</p>
//                 </div>
//                 <div data-popper-arrow></div>
//               </div>
//             )}
//             <div className="border-t"></div>
//             <button data-popover-target="popover-level" data-popover-placement="right" type="button" onClick={() => togglePopover('level')} className="px-4 py-2 hover:bg-gray-100 flex w-full text-left">
//               <div className="text-green-800 align-items-center flex">
//                 <FaLevelUpAlt size={24} />
//               </div>
//               <div className="pl-3 flex-1">
//                 <p className="text-md font-bold text-gray-800 leading-none rounded p-1">{user.level} Lv.</p>
//                 <p className="text-xs text-gray-500">View your current level</p>
//               </div>
//             </button>
//             {isPopoverVisible.level && (
//               <div id="popover-level" className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100">
//                 <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
//                   <h3 className="font-semibold text-gray-900">Level Details</h3>
//                 </div>
//                 <div className="px-3 py-2">
//                   {renderStepper(levelSteps, user.level)}
//                 </div>
//                 <div className="px-3 py-2">
//                   <p>Current Level: {user.level} Lv.</p>
//                 </div>
//                 <div data-popper-arrow></div>
//               </div>
//             )}
//             <div className="border-t"></div>
//             <button data-popover-target="popover-achievement" data-popover-placement="right" type="button" onClick={() => togglePopover('achievement')} className="px-4 py-2 mb-2 hover:bg-gray-100 flex w-full text-left">
//               <div className="text-red-800 align-items-center flex">
//                 <GiAchievement size={24} />
//               </div>
//               <div className="pl-3 flex-1">
//                 <p className="text-md font-bold text-gray-800 leading-none rounded p-1">{user.achievement}</p>
//                 <p className="text-xs text-gray-500">View your account achievement</p>
//               </div>
//             </button>
//             {isPopoverVisible.achievement && (
//               <div id="popover-achievement" className="absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100">
//                 <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg">
//                   <h3 className="font-semibold text-gray-900">Achievement Details</h3>
//                 </div>
//                 <div className="px-3 py-2">
//                   {renderStepper(achievementSteps, achievementSteps.indexOf(user.achievement) + 1)}
//                 </div>
//                 <div className="px-3 py-2">
//                   <p className="text-md font-bold text-red-500">{user.achievement}</p>
//                 </div>
//                 <div data-popper-arrow></div>
//               </div>
//             )}
//           </div>
//         </div>

//       {isModalVisible && (
//         <UserInfoEdit
//           formValues={formValues}
//           setFormValues={setFormValues}
//           isEditing={isEditing}
//           setIsEditing={setIsEditing}
//           handleSave={handleSave}
//           handleSubmit={handleSubmit}
//           setIsModalVisible={setIsModalVisible}
//         />
//       )}
//     </>
//   );
// };

// export default UserInfo;