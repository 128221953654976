// src/pages/p02_VeriflyPage.tsx

import { FC, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext'; // Adjust the import path as necessary
import AppFooter from '../components/Footer'; // Adjust the import path as necessary
import { useVerifly } from '../hooks/Login/useVerifly'; // Import the custom hook
import VeriflySuccess from '../components/login/VeriflySuccess'; // Import the success modal

import r1logoOri from '../assets/login/logo_r1_ori_v02.png';
import r1bg from '../assets/login/bg_r1.png';

const VeriflyAccount: FC = () => {
    const { theme } = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const { verifyAccount, error, setError } = useVerifly();
    const [successVisible, setSuccessVisible] = useState(false);
    const token = new URLSearchParams(location.search).get('token');

    useEffect(() => {
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

            if (currentTime > decodedToken.exp) {
                navigate('/resend-verification');
            }
        }
    }, [token, navigate]);

    const handleButtonClick = async () => {
        if (token) {
          const isSuccess = await verifyAccount(token);
          if (isSuccess) {
            setSuccessVisible(true);
            setTimeout(() => {
              navigate('/login');
            }, 3000); // Redirect to login page after 3 seconds
          }
        } else {
          setError('Invalid verification token.');
        }
      };

    return (
        <div
            className="flex flex-col items-center justify-between min-h-screen bg-cover bg-center"
            style={{
                color: theme.textColor,
                fontFamily: theme.fontFamily,
                backgroundImage: `url(${r1bg})`, // Update the path to your background image
            }}
        >
            <div className="flex-grow flex flex-col items-center justify-center p-4">
                <div className="bg-white rounded-lg shadow-lg p-6 text-center max-w-md w-full">
                    <img src={r1logoOri} alt="Logo" className="mx-auto mb-4 w-24 h-24" /> {/* Update the path to your logo image */}
                    <h1 className="text-2xl font-bold mb-2">Thank You For Signing Up!</h1>
                    <p className="mb-6">
                        Thank you for signing up and welcome to R One App, the premier 
                        forex education platform designed to develop you into a funded trader.
                    </p>
                    <p>
                        For any support or assistance, please reach out to us at 
                        general@r1trader.com
                    </p>
                    {error && <p className="text-red-500 mt-4">{error}</p>}
                    <button
                        className="mt-4 py-2 px-4 rounded-full"
                        onClick={handleButtonClick}
                        style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }}
                    >
                        Get Access Now!
                    </button>
                </div>
            </div>
            <footer className="w-full mt-8 text-center" style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }}>
                <AppFooter />
            </footer>
            <VeriflySuccess visible={successVisible} onClose={() => setSuccessVisible(false)} /> {/* Add the success modal */}
        </div>
    );
};

export default VeriflyAccount;






// // update code --> 07 june 2024
// // src/pages/p02_VeriflyPage.tsx

// import { FC, useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import { useTheme } from '../context/ThemeContext'; // Adjust the import path as necessary
// import AppFooter from '../components/Footer'; // Adjust the import path as necessary
// import { useVerifly } from '../hooks/Login/useVerifly'; // Import the custom hook
// import VeriflySuccess from '../components/login/VeriflySuccess'; // Import the success modal

// import r1logoOri from '../assets/login/logo_r1_ori.png';
// import r1bg from '../assets/login/bg_r1.png';

// const VeriflyAccount: FC = () => {
//     const { theme } = useTheme();
//     const location = useLocation();
//     const { verifyAccount, error, setError } = useVerifly();
//     const [successVisible, setSuccessVisible] = useState(false);
//     const token = new URLSearchParams(location.search).get('token');

//     const handleButtonClick = async () => {
//         if (token) {
//             const isSuccess = await verifyAccount(token);
//             if (isSuccess) {
//                 setSuccessVisible(true);
//             }
//         } else {
//             setError('Invalid verification token.');
//         }
//     };

//     return (
//         <div
//             className="flex flex-col items-center justify-between min-h-screen bg-cover bg-center"
//             style={{
//                 color: theme.textColor,
//                 fontFamily: theme.fontFamily,
//                 backgroundImage: `url(${r1bg})`, // Update the path to your background image
//             }}
//         >
//             <div className="flex-grow flex flex-col items-center justify-center p-4">
//                 <div className="bg-white rounded-lg shadow-lg p-6 text-center max-w-md w-full">
//                     <img src={r1logoOri} alt="Logo" className="mx-auto mb-4 w-24 h-24" /> {/* Update the path to your logo image */}
//                     <h1 className="text-2xl font-bold mb-2">Thank You For Signing Up!</h1>
//                     <p className="mb-6">
//                         Thank you for signing up and welcome to use R One App, the most complete forex education platform to train you as a funded trader.
//                     </p>
//                     <p>
//                         If you need any support or help, please contact us at support@ronecentury.com.
//                     </p>
//                     {error && <p className="text-red-500 mt-4">{error}</p>}
//                     <button
//                         className="mt-4 py-2 px-4 rounded-full"
//                         onClick={handleButtonClick}
//                         style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }}
//                     >
//                         Get Access Now!
//                     </button>
//                 </div>
//             </div>
//             <footer className="w-full mt-8 text-center" style={{ backgroundColor: theme.primaryColor, color: theme.text2Color, fontFamily: theme.fontFamily }}>
//                 <AppFooter />
//             </footer>
//             <VeriflySuccess visible={successVisible} onClose={() => setSuccessVisible(false)} /> {/* Add the success modal */}
//         </div>
//     );
// };

// export default VeriflyAccount;




