// src/components/client/Dashboard/UserTask.tsx

import React, { useState } from 'react';
import { useTheme } from '../../../context/ThemeContext';
import { AiOutlineSearch, AiOutlineInfoCircle } from 'react-icons/ai';
import { BiTaskX } from "react-icons/bi";

// Task interface definition
interface Task {
  title: string;
  status: 'claim' | 'pending' | 'collected';
  module: string;
}

// Initial tasks array
const initialTasks: Task[] = [];

const UserTask: React.FC = () => {
  const { theme } = useTheme();
  const [tasks] = useState<Task[]>(initialTasks);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  // Handle modal close
  const handleCloseModal = () => {
    setSelectedTask(null);
  };

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg bg-white p-6 rounded-lg w-full">
      <div className="flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-4 text-center">
        <div className="flex items-center justify-between">
          <h5 className="text-xl font-semibold sm:text-lg xs:text-lg" style={styles.title}>
            R One Task
          </h5>
          <div className="relative group mx-2">
            <AiOutlineInfoCircle 
              className="text-xl text-gray-400 hover:text-cyan-800 cursor-pointer w-4 h-4 sm:w-4 sm:h-4 xs:w-4 xs:h-4"
              aria-hidden="true"
            />
            {/* Tooltip */}
            <div className="
              absolute top-full z-50 mt-2 hidden w-64 px-4 py-2 text-sm rounded-lg shadow-lg group-hover:block sm:text-xs sm:w-48 xs:text-xs xs:w-[150px]"
              style={styles.button}>
               Collect points by completing the tasks listed below
            </div>
          </div>
        </div>
        <div className="sm:ml-[120px] xs:ml-[100px]">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none ">
              <AiOutlineSearch className="w-4 h-4" style={styles.title} />
            </div>
            <input
              type="text"
              id="table-search-users"
              className="block p-2 pl-10 text-sm border border-gray-300 rounded-lg w-full bg-gray-100"
              placeholder="Search for tasks..."
              style={styles.text}
            />
          </div>
        </div>
      </div>
      {tasks.length === 0 ? (
        <div className="flex items-center justify-center p-10 border-2 border-dashed border-gray-300 rounded-lg">
          <div className="flex justify-between items-center text-lg text-gray-300">
            <BiTaskX className="mr-2"/>
            <h3>No tasks available</h3>
          </div>
        </div>
      ) : (
        <div className="transition-all duration-300">
          <div className="max-h-60 overflow-y-auto">
            <table className="w-full text-sm text-center text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 py-3">
                    No.
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Task
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Module
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* No data to display */}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {selectedTask && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">{selectedTask.title}</h2>
            <p className="text-sm mb-2"><strong>Module:</strong> {selectedTask.module}</p>
            <p className="text-sm mb-4"><strong>Status:</strong> {selectedTask.status === 'claim' ? 'Complete' : selectedTask.status === 'pending' ? 'Pending' : 'Collected'}</p>
            <button onClick={handleCloseModal} className="px-4 py-2 bg-blue-600 text-white rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserTask;



// // udpate code --> 09 july 2024
// // src/components/client/Dashboard/UserTask.tsx

// import React, { useState } from 'react';

// // Task interface definition
// interface Task {
//   title: string;
//   status: 'claim' | 'pending' | 'collected';
//   module: string;
// }

// // Initial tasks array
// const initialTasks: Task[] = [
//   { title: 'Daily login', status: 'claim', module: 'User Dashboard' },
//   { title: 'Complete profile', status: 'pending', module: 'User Profile' },
//   { title: 'Join community', status: 'pending', module: 'Community Page' },
//   { title: 'Refer a friend', status: 'collected', module: 'Referral Program' },
//   { title: 'Upload profile picture', status: 'claim', module: 'User Profile' },
//   { title: 'Verify email', status: 'collected', module: 'User Settings' },
//   { title: 'Participate in a survey', status: 'pending', module: 'Surveys' },
// ];

// const UserTask: React.FC = () => {
//   const [tasks, setTasks] = useState<Task[]>(initialTasks);
//   const [isTableCollapsed, setIsTableCollapsed] = useState(false);
//   const [selectedTask, setSelectedTask] = useState<Task | null>(null);

//   // Handle claiming a task
//   const handleClaim = (index: number) => {
//     const newTasks = [...tasks];
//     newTasks[index].status = 'collected';
//     setTasks(newTasks);
//   };

//   // Toggle table collapse state
//   const toggleTableCollapse = () => {
//     setIsTableCollapsed(!isTableCollapsed);
//   };

//   // Handle task click to show modal
//   const handleTaskClick = (task: Task) => {
//     setSelectedTask(task);
//   };

//   // Handle modal close
//   const handleCloseModal = () => {
//     setSelectedTask(null);
//   };

//   return (
//     <div className="relative overflow-x-auto shadow-md sm:rounded-lg bg-white p-6">
//       <div className="flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-4 text-center">
//         <div className="flex items-center justify-center w-full md:w-auto space-x-2 cursor-pointer" onClick={toggleTableCollapse}>
//           <svg
//             className="w-6 h-6 text-gray-700"
//             fill="none"
//             stroke="currentColor"
//             strokeWidth="2"
//             viewBox="0 0 24 24"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path strokeLinecap="round" strokeLinejoin="round" d="M3 8h18M3 12h18M3 16h18" />
//           </svg>
//           <h5 className="text-lg font-semibold text-gray-700">Task</h5>
//           <span className="text-sm text-gray-500">(Collect points by completing the tasks listed below)</span>
//         </div>
//         <div className="relative w-full md:w-auto">
//           <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
//             <svg
//               className="w-4 h-4 text-gray-500"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 20 20"
//             >
//               <path
//                 stroke="currentColor"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
//               />
//             </svg>
//           </div>
//           <input
//             type="text"
//             id="table-search-users"
//             className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full md:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
//             placeholder="Search for tasks"
//           />
//         </div>
//       </div>
//       <div className={`transition-all duration-300 ${isTableCollapsed ? 'max-h-0 overflow-hidden' : 'max-h-full'}`}>
//         <div className="max-h-60 overflow-y-auto">
//           <table className="w-full text-sm text-center text-gray-500">
//             <thead className="text-xs text-gray-700 uppercase bg-gray-50">
//               <tr>
//                 <th scope="col" className="px-3 py-3">
//                   No.
//                 </th>
//                 <th scope="col" className="px-6 py-3">
//                   Task
//                 </th>
//                 <th scope="col" className="px-6 py-3">
//                   Status
//                 </th>
//                 <th scope="col" className="px-6 py-3">
//                   Module
//                 </th>
//                 <th scope="col" className="px-6 py-3">
//                   Action
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {tasks.map((task, index) => (
//                 <tr key={index} className="bg-white border-b hover:bg-gray-50" onClick={() => handleTaskClick(task)}>
//                   <td className="px-3 py-2 font-medium text-gray-900 whitespace-nowrap">
//                     {index + 1}
//                   </td>
//                   <td className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
//                     <div>{task.title}</div>
//                   </td>
//                   <td className="px-6 py-2">
//                     {/* Status text with light background color */}
//                     <span className={`px-2 py-1 rounded text-sm ${task.status === 'claim' ? 'bg-green-100 text-green-800' : task.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 'bg-gray-100 text-gray-800'}`}>
//                       {task.status === 'claim' ? 'Complete' : task.status === 'pending' ? 'Pending' : 'Collected'}
//                     </span>
//                   </td>
//                   <td className="px-6 py-2">
//                     {task.module}
//                   </td>
//                   <td className="px-6 py-2">
//                     <button
//                       className={`px-4 py-2 rounded text-sm ${task.status === 'claim' ? 'bg-blue-400' : 'bg-gray-200 cursor-not-allowed'}`}
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         task.status === 'claim' && handleClaim(index);
//                       }}
//                       disabled={task.status !== 'claim'}
//                     >
//                       {task.status === 'claim' ? 'Claim' : task.status === 'pending' ? 'Pending' : 'Collected'}
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//       {selectedTask && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-lg shadow-lg">
//             <h2 className="text-xl font-semibold mb-4">{selectedTask.title}</h2>
//             <p className="text-sm mb-2"><strong>Module:</strong> {selectedTask.module}</p>
//             <p className="text-sm mb-4"><strong>Status:</strong> {selectedTask.status === 'claim' ? 'Complete' : selectedTask.status === 'pending' ? 'Pending' : 'Collected'}</p>
//             <button onClick={handleCloseModal} className="px-4 py-2 bg-blue-600 text-white rounded">
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserTask;

