
// src/components/admin/ClientData/UserDetailFilter.tsx

import { ClientDataProps } from '../../../hooks/AdminDashboard/useDataTable';

export const filterData = (data: ClientDataProps[], searchTerm: string): ClientDataProps[] => {
  return data.filter(client =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.email.toLowerCase().includes(searchTerm.toLowerCase())
  );
};


// // udpate code --> 01 june 2024
// // src/components/admin/ClientData/UserDetailFilter.tsx

// import { ClientDataProps } from '../../../hooks/AdminDashboard/useDataTable';

// export const filterData = (data: ClientDataProps[], searchTerm: string): ClientDataProps[] => {
//   return data.filter(client =>
//     client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     client.email.toLowerCase().includes(searchTerm.toLowerCase())
//   );
// };
