// src/components/client/MiniApp/App03_MySubscriptionItem.tsx

import React, { useState, useEffect } from 'react';
import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineDownload, AiFillCloseSquare } from 'react-icons/ai';
import { Subscription, Product } from '../../../hooks/Subscription/useSubscriptionData';
import { useEnv } from '../../../context/EnvContext';
interface SubscriptionItemProps {
  subscription: Subscription;
  getProductImageUrl: (image: string | undefined) => string;
  formatDate: (date: string) => string;
  convertPlanPeriod: (period: string) => string;
  getStatusStyle: (status: string) => string;
  getHubStatusStyle: (status: string) => string; 
  calculateRemainingDays: (planExpiredDate: string) => number;
  versionNumber: string;
  togglePasswordVisibility: (subscriptionId: number) => void;
  setParentActiveTab: (tab: string) => void; 
  setSelectedProduct: (product: Product | null) => void;
  setAccountDetails: React.Dispatch<React.SetStateAction<{
    accountID: string;
    accountType: string;
    accountNumber: string;
    accountPassword: string;
    accountServer: string;
  } | null>>;
  handleRenewClick: (subscription: Subscription) => void;
}

const SubscriptionItem: React.FC<SubscriptionItemProps> = ({
  subscription,
  getProductImageUrl,
  formatDate,
  convertPlanPeriod,
  getStatusStyle,
  getHubStatusStyle,
  calculateRemainingDays,
  versionNumber,
  togglePasswordVisibility,
  // setParentActiveTab, 
  // setSelectedProduct,
  // setAccountDetails,
  handleRenewClick,
}) => {
  const { apiUrl } = useEnv();
  // <<-------------------------------- Hardcored --------------------------------------->> //
  const isTraderHub = subscription.productName === 'TraderHub';
  // <<-------------------------------- Hardcored --------------------------------------->> //
  const [isExpanded, setIsExpanded] = useState(false);
  const isExpandCollapseDisabled = ['Pending', 'Deactive', 'Expire'].includes(subscription.accountStatus);
  const [activeTab, setActiveTab] = useState<'AlgoMatrix' | 'Lirunex'>('AlgoMatrix');
  
  const [algoMatrixUrl, setAlgoMatrixUrl] = useState<string | null>(null);
  const [lirunexUrl, setLirunexUrl] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchUrls = async () => {
      try {
        const [algoResponse, lirunexResponse, videoResponse, pdfResponse] = await Promise.all([
          fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrix`),
          fetch(`${apiUrl}/api/encrypted-download-url?type=lirunexTerminal`),
          fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixVideo`),
          fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixPdf`)
        ]);
  
        const algoData = await algoResponse.json();
        const lirunexData = await lirunexResponse.json();
        const videoData = await videoResponse.json();
        const pdfData = await pdfResponse.json();
  
        setAlgoMatrixUrl(algoData.url);
        setLirunexUrl(lirunexData.url);
        setVideoUrl(videoData.url);
        setPdfUrl(pdfData.url);
      } catch (error) {
        console.error('Error fetching encrypted URLs:', error);
      }
    };
    fetchUrls();
  }, [apiUrl]);
  
  // item status trigger
  const getStatusStyleFn = isTraderHub ? getHubStatusStyle : getStatusStyle;
  // expired plan trigger
  const planDays = isTraderHub ? parseInt(subscription.planDays, 10) : calculateRemainingDays(subscription.planExpiredDate);
  const planTrialDays = subscription.planType === 'Trial' ? calculateRemainingDays(subscription.planExpiredTrialDate) : null;

  if (planDays === -1 || (planTrialDays !== null && planTrialDays === -1)) {
    subscription.accountStatus = 'Expire';
  }
  // if (subscription.expiredDays === -1){
  //   subscription.status = 'expired';
  // }
  // // expired days for algo Matrix trigger
  const isNearExpiry = planDays !== null && planDays <= 14;
  const isVeryNearExpiry = planDays !== null && planDays <= 5;
  const isAnnuallyNearExpiry = planDays !== null && subscription.planPeriod === '365' && planDays <= 30;
  const isExpired = planDays !== null && planDays < 0;
  // expired days for trader hub trigger
  const isTraderHubExpired = isTraderHub && parseInt(subscription.expireDays || '0', 10) <= -1;
  // expired trials trigger
  const isTrialNearExpiry = planTrialDays !== null && planTrialDays <= 14;
  const isTrialVeryNearExpiry = planTrialDays !== null && planTrialDays <= 5;
  const isTrialExpired = planTrialDays !== null && planTrialDays < 0;

  return (
    <div key={subscription.id} className="flex flex-col items-center rounded-lg p-4 bg-gray-100 mb-4">
      <div className="flex flex-row justify-between items-center">
        
        {/* Product Image */}
        <div className="flex flex-row items-center sm:flex-col xs:flex-col">
          <img
            src={getProductImageUrl(subscription.image)}
            alt={subscription.name}
            className="w-[150px] rounded-lg object-cover md:w-[100px] sm:w-[120px]"
          />
          <div className="ml-4">
            <div className='my-2'>
              <div className="flex justify-normal items-center">
                
                {/* Product Name */}
                <div className="text-xl font-bold md:text-lg sm:text-lg xs:text-base">
                  {subscription.productName}
                </div>
                
                {/* Account Status */}
                <div className={`text-[10px] ml-2 px-4 py-0 rounded-full md:text-[8px] sm:text-[8px] xs:text-[8px] ${getStatusStyleFn(subscription.accountStatus)}`}>
                  {subscription.accountStatus}
                </div>
                
                {/* Account ID or Telegram ID */}
                <div className="text-xs ml-4 md:ml-2 sm:ml-2 xs:ml-2">
                  <strong>(id:</strong>{isTraderHub ? subscription.id : subscription.accountID})
                </div>
              </div>
              
              {/* Sub Username and Email
              <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
                <strong>
                  {isTraderHub ? subscription.name : subscription.userName}
                </strong> 
                <span className="mx-1">
                  ({subscription.email})
                </span>
              </div> */}
            </div>
            <div className="flex flex-row space-x-4">
                {/* Sub, Plan, Period & Expired On */}
              <div className="text-sm font-semibold md:text-xs sm:text-xs xs:text-xs">
                <div>Plan:</div>
                <div>Period:</div>
                <div>Expired On:</div>
              </div>
              <div>
                <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
                  {subscription.planName}
                </div>
                <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
                  {subscription.planType === 'Trial' ? `Trial with ${subscription.planTrialPeriod} Days` : `${subscription.planType} with ${convertPlanPeriod(subscription.planPeriod)}`}
                </div>
                <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
                  {subscription.planType === 'Trial' ? formatDate(subscription.planExpiredTrialDate) : formatDate(subscription.planExpiredDate)}
                </div>
              </div>
            </div>
            {(isNearExpiry || isAnnuallyNearExpiry || isExpired || isTrialNearExpiry || isTrialExpired || isTraderHubExpired || subscription.accountStatus === 'Pending') && (
              <div className={`text-rose-600 text-xs mt-2 ${isExpired || isTrialExpired || isTraderHubExpired ? 'cursor-pointer' : ''}`}>
                {subscription.accountStatus === 'Pending' ? (
                  'Please wait for admin approval. Pay attention to your email.'
                 ) : isExpired || isTrialExpired || isTraderHubExpired ? (
                  <>
                    {subscription.planType === 'Trial' ? 'Your trial has expired! Click ' : 'Your subscription has expired! Click '}
                    <button
                      className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
                      onClick={() => handleRenewClick(subscription)}
                    >
                      here
                    </button>
                    {subscription.planType === 'Trial' ? ' to subscribe a plan now.' : ' to renew now.'}
                  </>
                 ) : isVeryNearExpiry ? (
                    <>
                      <span className="mr-1">
                        Your subscription is expiring soon!
                      </span> 
                      <button
                        className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
                        onClick={() => handleRenewClick(subscription)}
                      >
                        Renew
                      </button>
                      <span className="ml-1">
                        now to avoid interruption.
                      </span>
                    </>
                 ) : isAnnuallyNearExpiry ? (
                    <>
                      <span className="mr-1">
                        Your annual subscription is expiring in a month. Please
                      </span> 
                      <button
                        className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
                        onClick={() => handleRenewClick(subscription)}
                      >
                        renew
                      </button>
                      <span className="ml-1">
                        it.
                      </span>
                    </>
                 ) : isTrialVeryNearExpiry ? (
                    <>
                      <span className="mr-1">
                        Your trial is expiring soon! Please
                      </span>
                      <button
                        className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
                        onClick={() => handleRenewClick(subscription)}
                      >
                        renew
                      </button>
                      <span className="ml-1">
                        to subscription plan.
                      </span>
                    </>
                 ) : isTrialNearExpiry ? (
                    <>
                      <span className="mr-1">
                        Your trial subscription is near expiry. 
                      </span>
                      <button
                        className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
                        onClick={() => handleRenewClick(subscription)}
                      >
                        Renew
                      </button>
                      <span className="ml-1">
                        now.
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="mr-1">
                        Your subscription is near expiry. Please
                      </span> 
                      <button
                        className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
                        onClick={() => handleRenewClick(subscription)}
                      >
                        renew
                      </button>
                      <span className="ml-1">
                        it.
                      </span>
                    </>
                  )}
                </div>
              )}
          </div>
          <div className="flex flex-row items-center ml-8 sm:ml-0 sm:mt-4 xs:ml-0 xs:mt-2">
            <div className="flex justify-between items-center">
              <div className="text-4xl px-4 py-2 sm:py-1 xs:py-1 bg-cyan-800 text-white rounded-xl md:text-2xl sm:text-xl xs:text-lg">
                {subscription.planType === 'Trial' ? (planTrialDays !== null && planTrialDays <= -1 ? 0 : planTrialDays) : (planDays !== null && planDays <= -1 ? 0 : planDays)}
              </div>
              <div className="text-cyan-800 text-sm ml-2 md:text-xs sm:text-xs xs:text-xs">Expired<br />Days</div>
            </div>
            <button
              onClick={() => !isExpandCollapseDisabled && setIsExpanded(!isExpanded)}
              className={`text-gray-600 hover:text-cyan-800 mx-4 md:mx-2 sm:mx-2 xs:mx-2 text-lg ${isExpandCollapseDisabled ? 'cursor-not-allowed' : ''}`}
              disabled={isExpandCollapseDisabled}
            >
              {isExpanded ? <FaMinusSquare /> : <FaPlusSquare />}
            </button>
          </div>
        </div>
      </div>

      {isExpanded && isTraderHub && (
        <div className="mt-2 bg-white p-4 rounded-lg shadow-lg w-full sm:mt-4 xs:mt-4">
          <div className="flex flex-col items-center">
            <p className="text-sm text-gray-700">
              Complete the setup by joining our R One Trader Hub Telegram Group:
            </p>
            <a 
              href="https://t.me/ROneTraderHubCS4bot" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-blue-600 underline mt-2"
            >
              https://t.me/ROneTraderHubCS4bot
            </a>
          </div>
        </div>
      )}

      {/* Expand/Collapse section */}
      {isExpanded && !isTraderHub && (
      <div className="mt-2 bg-white p-4 rounded-lg shadow-lg w-full sm:mt-4 xs:mt-4">
        <div className="flex justify-between items-center sm:flex-col xs:flex-col">
          <div>
            <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Account ID:</strong> {subscription.accountNumber}</div>
            <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Account Type:</strong> {subscription.accountType}</div>
            <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Account Server:</strong> {subscription.accountServer}</div>
            <div className="text-sm md:text-xs sm:text-xs xs:text-xs flex items-center"><strong>Account Password:</strong>
              <span className="ml-2">
                {subscription.showPassword ? 
                `${'*'.repeat(subscription.accountPassword.length - 4)}${subscription.accountPassword.slice(-4)}` : 
                '••••••••'}
              </span>
              <button 
               onClick={() => togglePasswordVisibility(subscription.id)}
               className="ml-1">
               {subscription.showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </button>
            </div>
          </div>

        {/* Resources Tab */}
        <div className="w-[350px] md:w-[280px] sm:w-[250px] xs:w-[220px] sm:mt-2 xs:mt-2">
          <div className="flex justify-normal items-center text-xs mb-2">
            <button
              className={`px-4 py-2 w-full rounded-s-md ${activeTab === 'AlgoMatrix' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
              onClick={() => setActiveTab('AlgoMatrix')}
            >
              AlgoMatrix
            </button>
            <button
              className={`px-4 py-2 w-full rounded-e-md ${activeTab === 'Lirunex' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
              onClick={() => setActiveTab('Lirunex')}
            >
              MT4 Terminal
            </button>
          </div>
                
          {/* AlgoMatrix Section */}
          {activeTab === 'AlgoMatrix' && (
            <div>
              <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1 md:text-xs sm:text-xs xs:text-xs">
                <div> Current Version <strong>{versionNumber}</strong> </div>
                <a
                  href={`${apiUrl}/api/download/${algoMatrixUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
                >
                  <AiOutlineDownload className="mr-2" /> Download
                </a>
              </div>
              <div className="text-red-600 text-xs text-center">
                Only support MT4, installation guide click 
                <button
                  className=" text-rose-600 underline hover:text-cyan-800 px-1 py-0 text-xs"
                  onClick={() => setIsModalOpen(true)} 
                  >
                   here
                </button>
              </div>
            </div>
          )}

          {/* Lirunex Section */}
          {activeTab === 'Lirunex' && (
            <div>
              <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1 md:text-xs sm:text-xs xs:text-xs">
                <div> Lirunex Terminal <strong>MT4</strong> </div>
                <a
                  href={`${apiUrl}/api/download/${lirunexUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
                >
                  <AiOutlineDownload className="mr-2" /> Download
                </a>
              </div>
              <div className="text-red-600 text-xs text-center">
                Registration for Lirunex account click 
                  <a 
                    href="https://my.lirunex.online/?Referral=90859&GroupId=8046" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className=" text-rose-600 underline hover:text-cyan-800 px-1 py-0 text-xs">
                    here
                  </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    )}

    {/* Modal for installation instructions */}
    {isModalOpen && (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-4 rounded-lg w-auto max-w-screen sm:w-[400px]">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-xl font-bold p-2 sm:text-base xs:text-sm">Installation Instructions & Strategy Video</h1>
            <button onClick={() => setIsModalOpen(false)} className="text-2xl"><AiFillCloseSquare /></button>
          </div>

          {/* Video Section */}
          <div className="flex flex-row justify-between sm:flex-col xs:flex-col">
            <div className="px-4 sm:px-2 xs:px-2">
              <video 
                controls 
                src={`${apiUrl}/api/stream-video/${videoUrl}`} 
                className="w-full h-auto rounded-lg"
                controlsList="nodownload"
                onContextMenu={(e) => e.preventDefault()}
              >
              </video>
            </div>

            {/* Manual Guide Book Section */}
            <div className="p-4 border-2 rounded-lg flex flex-col justify-center xl:justify-normal lg:justify-normal md:justify-normal sm:my-2 xs:my-2">
              <h1 className="font-bold mb-2 sm:text-sm xs:text-sm">CONTENT</h1>
              <div className="border-2 p-4 text-center lg:w-[220px] md:w-[200px] sm:text-sm xs:text-sm">
                <a href={`${apiUrl}/api/download/${pdfUrl}`} target="_blank" rel="noopener noreferrer" className="text-cyan-800 underline">
                  Download Manual Guide Book
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
   </div>
  );
};

export default SubscriptionItem;


// // update code --> 26 aug 2024 v02
// // src/components/client/MiniApp/App03_MySubscriptionItem.tsx

// import React, { useState, useEffect } from 'react';
// import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";
// import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineDownload, AiFillCloseSquare } from 'react-icons/ai';
// import { Subscription, Product } from '../../../hooks/Subscription/useSubscriptionData';
// import { useEnv } from '../../../context/EnvContext';
// interface SubscriptionItemProps {
//   subscription: Subscription;
//   getProductImageUrl: (image: string | undefined) => string;
//   formatDate: (date: string) => string;
//   convertPlanPeriod: (period: string) => string;
//   getStatusStyle: (status: string) => string;
//   calculateRemainingDays: (planExpiredDate: string) => number;
//   versionNumber: string;
//   togglePasswordVisibility: (subscriptionId: number) => void;
//   setParentActiveTab: (tab: string) => void; 
//   setSelectedProduct: (product: Product | null) => void;
//   setAccountDetails: React.Dispatch<React.SetStateAction<{
//     accountID: string;
//     accountType: string;
//     accountNumber: string;
//     accountPassword: string;
//     accountServer: string;
//   } | null>>;
//   handleRenewClick: (subscription: Subscription) => void; 
// }

// const SubscriptionItem: React.FC<SubscriptionItemProps> = ({
//   subscription,
//   getProductImageUrl,
//   formatDate,
//   convertPlanPeriod,
//   getStatusStyle,
//   calculateRemainingDays,
//   versionNumber,
//   togglePasswordVisibility,
//   // setParentActiveTab, 
//   // setSelectedProduct,
//   // setAccountDetails,
//   handleRenewClick,
// }) => {
//   const { apiUrl } = useEnv();
  
//   const [isExpanded, setIsExpanded] = useState(false);
//   const isExpandCollapseDisabled = ['Pending', 'Deactive', 'Expire'].includes(subscription.accountStatus);
//   const [activeTab, setActiveTab] = useState<'AlgoMatrix' | 'Lirunex'>('AlgoMatrix');
  
//   const [algoMatrixUrl, setAlgoMatrixUrl] = useState<string | null>(null);
//   const [lirunexUrl, setLirunexUrl] = useState<string | null>(null);
//   const [videoUrl, setVideoUrl] = useState<string | null>(null);
//   const [pdfUrl, setPdfUrl] = useState<string | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     const fetchUrls = async () => {
//       try {
//         const [algoResponse, lirunexResponse, videoResponse, pdfResponse] = await Promise.all([
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrix`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=lirunexTerminal`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixVideo`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixPdf`)
//         ]);
  
//         const algoData = await algoResponse.json();
//         const lirunexData = await lirunexResponse.json();
//         const videoData = await videoResponse.json();
//         const pdfData = await pdfResponse.json();
  
//         setAlgoMatrixUrl(algoData.url);
//         setLirunexUrl(lirunexData.url);
//         setVideoUrl(videoData.url);
//         setPdfUrl(pdfData.url);
//       } catch (error) {
//         console.error('Error fetching encrypted URLs:', error);
//       }
//     };
//     fetchUrls();
//   }, [apiUrl]);
  
//   // expired plan trigger
//   const planDays = calculateRemainingDays(subscription.planExpiredDate);
//   const planTrialDays = subscription.planType === 'Trial' ? calculateRemainingDays(subscription.planExpiredTrialDate) : null;

//   if (planDays === -1 || (planTrialDays !== null && planTrialDays === -1)) {
//     subscription.accountStatus = 'Expire';
//   }
//   // expired days trigger
//   const isNearExpiry = planDays !== null && planDays <= 14;
//   const isVeryNearExpiry = planDays !== null && planDays <= 5;
//   const isAnnuallyNearExpiry = planDays !== null && subscription.planPeriod === '365' && planDays <= 30;
//   const isExpired = planDays !== null && planDays < 0;
//   // expired trials trigger
//   const isTrialNearExpiry = planTrialDays !== null && planTrialDays <= 14;
//   const isTrialVeryNearExpiry = planTrialDays !== null && planTrialDays <= 5;
//   const isTrialExpired = planTrialDays !== null && planTrialDays < 0;

//   return (
//     <div key={subscription.id} className="flex flex-col items-center rounded-lg p-4 bg-gray-100 mb-4">
//       <div className="flex flex-row justify-between items-center">
        
//         {/* Product Image */}
//         <div className="flex flex-row items-center sm:flex-col xs:flex-col">
//           <img
//             src={getProductImageUrl(subscription.image)}
//             alt={subscription.name}
//             className="w-[150px] rounded-lg object-cover md:w-[100px] sm:w-[120px]"
//           />
//           <div className="ml-4">
//             <div className='my-2'>
//               <div className="flex justify-normal items-center">
                
//                 {/* Product Name */}
//                 <div className="text-xl font-bold md:text-lg sm:text-lg xs:text-base">{subscription.productName}</div>
                
//                 {/* Account Status */}
//                 <div className={`text-[10px] ml-2 px-4 py-0 rounded-full md:text-[8px] sm:text-[8px] xs:text-[8px] ${getStatusStyle(subscription.accountStatus)}`}>
//                   {subscription.accountStatus}
//                 </div>
                
//                 {/* Account ID */}
//                 <div className="text-xs ml-4 md:ml-2 sm:ml-2 xs:ml-2"><strong>(id:</strong>{subscription.accountID})</div>
//               </div>
              
//               {/* Sub Username and Email */}
//               <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>{subscription.userName}</strong> ({subscription.email})</div>
//             </div>
//             <div className="flex flex-row space-x-4">
//                 {/* Sub, Plan, Period & Expired On */}
//               <div className="text-sm font-semibold md:text-xs sm:text-xs xs:text-xs">
//                 <div>Plan:</div>
//                 <div>Period:</div>
//                 <div>Expired On:</div>
//               </div>
//               <div>
//                 <div className="text-sm md:text-xs sm:text-xs xs:text-xs">{subscription.planName}</div>
//                 <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
//                   {subscription.planType === 'Trial' ? `Trial with ${subscription.planTrialPeriod} Days` : `${subscription.planType} with ${convertPlanPeriod(subscription.planPeriod)}`}
//                 </div>
//                 <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
//                   {subscription.planType === 'Trial' ? formatDate(subscription.planExpiredTrialDate) : formatDate(subscription.planExpiredDate)}
//                 </div>
//               </div>
//             </div>
//             {(isNearExpiry || isAnnuallyNearExpiry || isExpired || isTrialNearExpiry || isTrialExpired || subscription.accountStatus === 'Pending') && (
//               <div className={`text-rose-600 text-xs mt-2 ${isExpired || isTrialExpired ? 'cursor-pointer' : ''}`}>
//                 {subscription.accountStatus === 'Pending' ? (
//                   'Please wait for admin approval. Pay attention to your email.'
//                  ) : isExpired || isTrialExpired ? (
//                   <>
//                     {subscription.planType === 'Trial' ? 'Your trial has expired! Click ' : 'Your subscription has expired! Click '}
//                     <button
//                       className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                       onClick={() => handleRenewClick(subscription)}
//                     >
//                       here
//                     </button>
//                     {subscription.planType === 'Trial' ? ' to subscribe a plan now.' : ' to renew now.'}
//                   </>
//                  ) : isVeryNearExpiry ? (
//                     <>
//                       <span className="mr-1">
//                         Your subscription is expiring soon!
//                       </span> 
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         Renew
//                       </button>
//                       <span className="ml-1">
//                         now to avoid interruption.
//                       </span>
//                     </>
//                  ) : isAnnuallyNearExpiry ? (
//                     <>
//                       <span className="mr-1">
//                         Your annual subscription is expiring in a month. Please
//                       </span> 
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         renew
//                       </button>
//                       <span className="ml-1">
//                         it.
//                       </span>
//                     </>
//                  ) : isTrialVeryNearExpiry ? (
//                     <>
//                       <span className="mr-1">
//                         Your trial is expiring soon! Please
//                       </span>
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         renew
//                       </button>
//                       <span className="ml-1">
//                         to subscription plan.
//                       </span>
//                     </>
//                  ) : isTrialNearExpiry ? (
//                     <>
//                       <span className="mr-1">
//                         Your trial subscription is near expiry. 
//                       </span>
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         Renew
//                       </button>
//                       <span className="ml-1">
//                         now.
//                       </span>
//                     </>
//                   ) : (
//                     <>
//                       <span className="mr-1">
//                         Your subscription is near expiry. Please
//                       </span> 
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         renew
//                       </button>
//                       <span className="ml-1">
//                         it.
//                       </span>
//                     </>
//                   )}
//                 </div>
//               )}
//           </div>
//           <div className="flex flex-row items-center ml-8 sm:ml-0 sm:mt-4 xs:ml-0 xs:mt-2">
//             <div className="flex justify-between items-center">
//               <div className="text-4xl px-4 py-2 sm:py-1 xs:py-1 bg-cyan-800 text-white rounded-xl md:text-2xl sm:text-xl xs:text-lg">
//                 {subscription.planType === 'Trial' ? (planTrialDays !== null && planTrialDays <= -1 ? 0 : planTrialDays) : (planDays !== null && planDays <= -1 ? 0 : planDays)}
//               </div>
//               <div className="text-cyan-800 text-sm ml-2 md:text-xs sm:text-xs xs:text-xs">Expired<br />Days</div>
//             </div>
//             <button
//               onClick={() => !isExpandCollapseDisabled && setIsExpanded(!isExpanded)}
//               className={`text-gray-600 hover:text-cyan-800 mx-4 md:mx-2 sm:mx-2 xs:mx-2 text-lg ${isExpandCollapseDisabled ? 'cursor-not-allowed' : ''}`}
//               disabled={isExpandCollapseDisabled}
//             >
//               {isExpanded ? <FaMinusSquare /> : <FaPlusSquare />}
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Expand/Collapse section */}
//       {isExpanded && (
//       <div className="mt-2 bg-white p-4 rounded-lg shadow-lg w-full sm:mt-4 xs:mt-4">
//         <div className="flex justify-between items-center sm:flex-col xs:flex-col">
//           <div>
//             <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Account ID:</strong> {subscription.accountNumber}</div>
//             <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Account Type:</strong> {subscription.accountType}</div>
//             <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Account Server:</strong> {subscription.accountServer}</div>
//             <div className="text-sm md:text-xs sm:text-xs xs:text-xs flex items-center"><strong>Account Password:</strong>
//               <span className="ml-2">
//                 {subscription.showPassword ? 
//                 `${'*'.repeat(subscription.accountPassword.length - 4)}${subscription.accountPassword.slice(-4)}` : 
//                 '••••••••'}
//               </span>
//               <button 
//                onClick={() => togglePasswordVisibility(subscription.id)}
//                className="ml-1">
//                {subscription.showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
//               </button>
//             </div>
//           </div>

//         {/* Resources Tab */}
//         <div className="w-[350px] md:w-[280px] sm:w-[250px] xs:w-[220px] sm:mt-2 xs:mt-2">
//           <div className="flex justify-normal items-center text-xs mb-2">
//             <button
//               className={`px-4 py-2 w-full rounded-s-md ${activeTab === 'AlgoMatrix' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
//               onClick={() => setActiveTab('AlgoMatrix')}
//             >
//               AlgoMatrix
//             </button>
//             <button
//               className={`px-4 py-2 w-full rounded-e-md ${activeTab === 'Lirunex' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
//               onClick={() => setActiveTab('Lirunex')}
//             >
//               MT4 Terminal
//             </button>
//           </div>
                
//           {/* AlgoMatrix Section */}
//           {activeTab === 'AlgoMatrix' && (
//             <div>
//               <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1 md:text-xs sm:text-xs xs:text-xs">
//                 <div> Current Version <strong>{versionNumber}</strong> </div>
//                 <a
//                   href={`${apiUrl}/api/download/${algoMatrixUrl}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
//                 >
//                   <AiOutlineDownload className="mr-2" /> Download
//                 </a>
//               </div>
//               <div className="text-red-600 text-xs text-center">
//                 Only support MT4, installation guide click 
//                 <button
//                   className=" text-rose-600 underline hover:text-cyan-800 px-1 py-0 text-xs"
//                   onClick={() => setIsModalOpen(true)} 
//                   >
//                    here
//                 </button>
//               </div>
//             </div>
//           )}

//           {/* Lirunex Section */}
//           {activeTab === 'Lirunex' && (
//             <div>
//               <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1 md:text-xs sm:text-xs xs:text-xs">
//                 <div> Lirunex Terminal <strong>MT4</strong> </div>
//                 <a
//                   href={`${apiUrl}/api/download/${lirunexUrl}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
//                 >
//                   <AiOutlineDownload className="mr-2" /> Download
//                 </a>
//               </div>
//               <div className="text-red-600 text-xs text-center">
//                 Registration for Lirunex account click 
//                   <a 
//                     href="https://my.lirunex.online/?Referral=90859&GroupId=8046" 
//                     target="_blank" 
//                     rel="noopener noreferrer" 
//                     className=" text-rose-600 underline hover:text-cyan-800 px-1 py-0 text-xs">
//                     here
//                   </a>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//     )}

//     {/* Modal for installation instructions */}
//     {isModalOpen && (
//       <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//         <div className="bg-white p-4 rounded-lg w-auto max-w-screen sm:w-[400px]">
//           <div className="flex justify-between items-center mb-4">
//             <h1 className="text-xl font-bold p-2 sm:text-base xs:text-sm">Installation Instructions & Strategy Video</h1>
//             <button onClick={() => setIsModalOpen(false)} className="text-2xl"><AiFillCloseSquare /></button>
//           </div>

//           {/* Video Section */}
//           <div className="flex flex-row justify-between sm:flex-col xs:flex-col">
//             <div className="px-4 sm:px-2 xs:px-2">
//               <video 
//                 controls 
//                 src={`${apiUrl}/api/stream-video/${videoUrl}`} 
//                 className="w-full h-auto rounded-lg"
//                 controlsList="nodownload"
//                 onContextMenu={(e) => e.preventDefault()}
//               >
//               </video>
//             </div>

//             {/* Manual Guide Book Section */}
//             <div className="p-4 border-2 rounded-lg flex flex-col justify-center xl:justify-normal lg:justify-normal md:justify-normal sm:my-2 xs:my-2">
//               <h1 className="font-bold mb-2 sm:text-sm xs:text-sm">CONTENT</h1>
//               <div className="border-2 p-4 text-center lg:w-[220px] md:w-[200px] sm:text-sm xs:text-sm">
//                 <a href={`${apiUrl}/api/download/${pdfUrl}`} target="_blank" rel="noopener noreferrer" className="text-cyan-800 underline">
//                   Download Manual Guide Book
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       )}
//    </div>
//   );
// };

// export default SubscriptionItem;


// // udpate code --> 26 Aug 2024
// // src/components/client/MiniApp/App03_MySubscriptionItem.tsx

// import React, { useState, useEffect } from 'react';
// import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";
// import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineDownload, AiFillCloseSquare } from 'react-icons/ai';
// import { Subscription, Product } from '../../../hooks/Subscription/useSubscriptionData';
// import { useEnv } from '../../../context/EnvContext';
// interface SubscriptionItemProps {
//   subscription: Subscription;
//   getProductImageUrl: (image: string | undefined) => string;
//   formatDate: (date: string) => string;
//   convertPlanPeriod: (period: string) => string;
//   getStatusStyle: (status: string) => string;
//   calculateRemainingDays: (planExpiredDate: string) => number;
//   versionNumber: string;
//   togglePasswordVisibility: (subscriptionId: number) => void;
//   setParentActiveTab: (tab: string) => void; 
//   setSelectedProduct: (product: Product | null) => void;
//   setAccountDetails: React.Dispatch<React.SetStateAction<{
//     accountID: string;
//     accountType: string;
//     accountNumber: string;
//     accountPassword: string;
//     accountServer: string;
//   } | null>>;
//   handleRenewClick: (subscription: Subscription) => void; 
// }

// const SubscriptionItem: React.FC<SubscriptionItemProps> = ({
//   subscription,
//   getProductImageUrl,
//   formatDate,
//   convertPlanPeriod,
//   getStatusStyle,
//   calculateRemainingDays,
//   versionNumber,
//   togglePasswordVisibility,
//   // setParentActiveTab, 
//   // setSelectedProduct,
//   // setAccountDetails,
//   handleRenewClick,
// }) => {
//   const { apiUrl } = useEnv();
//   const [isExpanded, setIsExpanded] = useState(false);
//   const isExpandCollapseDisabled = ['Pending', 'Deactive', 'Expire'].includes(subscription.accountStatus);
//   const [activeTab, setActiveTab] = useState<'AlgoMatrix' | 'Lirunex'>('AlgoMatrix');
//   const [algoMatrixUrl, setAlgoMatrixUrl] = useState<string | null>(null);
//   const [lirunexUrl, setLirunexUrl] = useState<string | null>(null);
//   const [videoUrl, setVideoUrl] = useState<string | null>(null);
//   const [pdfUrl, setPdfUrl] = useState<string | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     const fetchUrls = async () => {
//       try {
//         const [algoResponse, lirunexResponse, videoResponse, pdfResponse] = await Promise.all([
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrix`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=lirunexTerminal`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixVideo`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixPdf`)
//         ]);
  
//         const algoData = await algoResponse.json();
//         const lirunexData = await lirunexResponse.json();
//         const videoData = await videoResponse.json();
//         const pdfData = await pdfResponse.json();
  
//         setAlgoMatrixUrl(algoData.url);
//         setLirunexUrl(lirunexData.url);
//         setVideoUrl(videoData.url);
//         setPdfUrl(pdfData.url);
//       } catch (error) {
//         console.error('Error fetching encrypted URLs:', error);
//       }
//     };
//     fetchUrls();
//   }, [apiUrl]);
  
//   // expired plan trigger
//   const planDays = calculateRemainingDays(subscription.planExpiredDate);
//   const planTrialDays = subscription.planType === 'Trial' ? calculateRemainingDays(subscription.planExpiredTrialDate) : null;

//   if (planDays === -1 || (planTrialDays !== null && planTrialDays === -1)) {
//     subscription.accountStatus = 'Expire';
//   }
//   // expired days trigger
//   const isNearExpiry = planDays !== null && planDays <= 14;
//   const isVeryNearExpiry = planDays !== null && planDays <= 5;
//   const isAnnuallyNearExpiry = planDays !== null && subscription.planPeriod === '365' && planDays <= 30;
//   const isExpired = planDays !== null && planDays < 0;
//   // expired trials trigger
//   const isTrialNearExpiry = planTrialDays !== null && planTrialDays <= 14;
//   const isTrialVeryNearExpiry = planTrialDays !== null && planTrialDays <= 5;
//   const isTrialExpired = planTrialDays !== null && planTrialDays < 0;

//   return (
//     <div key={subscription.id} className="flex flex-col items-center rounded-lg p-4 bg-gray-100 mb-4">
//       <div className="flex flex-row justify-between items-center">
        
//         {/* Product Image */}
//         <div className="flex flex-row items-center sm:flex-col xs:flex-col">
//           <img
//             src={getProductImageUrl(subscription.image)}
//             alt={subscription.name}
//             className="w-[150px] rounded-lg object-cover md:w-[100px] sm:w-[120px]"
//           />
//           <div className="ml-4">
//             <div className='my-2'>
//               <div className="flex justify-normal items-center">
                
//                 {/* Product Name */}
//                 <div className="text-xl font-bold md:text-lg sm:text-lg xs:text-base">{subscription.productName}</div>
                
//                 {/* Account Status */}
//                 <div className={`text-[10px] ml-2 px-4 py-0 rounded-full md:text-[8px] sm:text-[8px] xs:text-[8px] ${getStatusStyle(subscription.accountStatus)}`}>
//                   {subscription.accountStatus}
//                 </div>
                
//                 {/* Account ID */}
//                 <div className="text-xs ml-4 md:ml-2 sm:ml-2 xs:ml-2"><strong>(id:</strong>{subscription.accountID})</div>
//               </div>
              
//               {/* Sub Username and Email */}
//               <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>{subscription.userName}</strong> ({subscription.email})</div>
//             </div>
//             <div className="flex flex-row space-x-4">
//                 {/* Sub, Plan, Period & Expired On */}
//               <div className="text-sm font-semibold md:text-xs sm:text-xs xs:text-xs">
//                 <div>Plan:</div>
//                 <div>Period:</div>
//                 <div>Expired On:</div>
//               </div>
//               <div>
//                 <div className="text-sm md:text-xs sm:text-xs xs:text-xs">{subscription.planName}</div>
//                 <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
//                   {subscription.planType === 'Trial' ? `Trial with ${subscription.planTrialPeriod} Days` : `${subscription.planType} with ${convertPlanPeriod(subscription.planPeriod)}`}
//                 </div>
//                 <div className="text-sm md:text-xs sm:text-xs xs:text-xs">
//                   {subscription.planType === 'Trial' ? formatDate(subscription.planExpiredTrialDate) : formatDate(subscription.planExpiredDate)}
//                 </div>
//               </div>
//             </div>
//             {(isNearExpiry || isAnnuallyNearExpiry || isExpired || isTrialNearExpiry || isTrialExpired || subscription.accountStatus === 'Pending') && (
//               <div className={`text-rose-600 text-xs mt-2 ${isExpired || isTrialExpired ? 'cursor-pointer' : ''}`}>
//                 {subscription.accountStatus === 'Pending' ? (
//                   'Please wait for admin approval. Pay attention to your email.'
//                  ) : isExpired || isTrialExpired ? (
//                   <>
//                     {subscription.planType === 'Trial' ? 'Your trial has expired! Click ' : 'Your subscription has expired! Click '}
//                     <button
//                       className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                       onClick={() => handleRenewClick(subscription)}
//                     >
//                       here
//                     </button>
//                     {subscription.planType === 'Trial' ? ' to subscribe a plan now.' : ' to renew now.'}
//                   </>
//                  ) : isVeryNearExpiry ? (
//                     <>
//                       <span className="mr-1">
//                         Your subscription is expiring soon!
//                       </span> 
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         Renew
//                       </button>
//                       <span className="ml-1">
//                         now to avoid interruption.
//                       </span>
//                     </>
//                  ) : isAnnuallyNearExpiry ? (
//                     <>
//                       <span className="mr-1">
//                         Your annual subscription is expiring in a month. Please
//                       </span> 
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         renew
//                       </button>
//                       <span className="ml-1">
//                         it.
//                       </span>
//                     </>
//                  ) : isTrialVeryNearExpiry ? (
//                     <>
//                       <span className="mr-1">
//                         Your trial is expiring soon! Please
//                       </span>
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         renew
//                       </button>
//                       <span className="ml-1">
//                         to subscription plan.
//                       </span>
//                     </>
//                  ) : isTrialNearExpiry ? (
//                     <>
//                       <span className="mr-1">
//                         Your trial subscription is near expiry. 
//                       </span>
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         Renew
//                       </button>
//                       <span className="ml-1">
//                         now.
//                       </span>
//                     </>
//                   ) : (
//                     <>
//                       <span className="mr-1">
//                         Your subscription is near expiry. Please
//                       </span> 
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         renew
//                       </button>
//                       <span className="ml-1">
//                         it.
//                       </span>
//                     </>
//                   )}
//                 </div>
//               )}
//           </div>
//           <div className="flex flex-row items-center ml-8 sm:ml-0 sm:mt-4 xs:ml-0 xs:mt-2">
//             <div className="flex justify-between items-center">
//               <div className="text-4xl px-4 py-2 sm:py-1 xs:py-1 bg-cyan-800 text-white rounded-xl md:text-2xl sm:text-xl xs:text-lg">
//                 {subscription.planType === 'Trial' ? (planTrialDays !== null && planTrialDays <= -1 ? 0 : planTrialDays) : (planDays !== null && planDays <= -1 ? 0 : planDays)}
//               </div>
//               <div className="text-cyan-800 text-sm ml-2 md:text-xs sm:text-xs xs:text-xs">Expired<br />Days</div>
//             </div>
//             <button
//               onClick={() => !isExpandCollapseDisabled && setIsExpanded(!isExpanded)}
//               className={`text-gray-600 hover:text-cyan-800 mx-4 md:mx-2 sm:mx-2 xs:mx-2 text-lg ${isExpandCollapseDisabled ? 'cursor-not-allowed' : ''}`}
//               disabled={isExpandCollapseDisabled}
//             >
//               {isExpanded ? <FaMinusSquare /> : <FaPlusSquare />}
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Expand/Collapse section */}
//       {isExpanded && (
//       <div className="mt-2 bg-white p-4 rounded-lg shadow-lg w-full sm:mt-4 xs:mt-4">
//         <div className="flex justify-between items-center sm:flex-col xs:flex-col">
//           <div>
//             <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Account ID:</strong> {subscription.accountNumber}</div>
//             <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Account Type:</strong> {subscription.accountType}</div>
//             <div className="text-sm md:text-xs sm:text-xs xs:text-xs"><strong>Account Server:</strong> {subscription.accountServer}</div>
//             <div className="text-sm md:text-xs sm:text-xs xs:text-xs flex items-center"><strong>Account Password:</strong>
//               <span className="ml-2">
//                 {subscription.showPassword ? 
//                 `${'*'.repeat(subscription.accountPassword.length - 4)}${subscription.accountPassword.slice(-4)}` : 
//                 '••••••••'}
//               </span>
//               <button 
//                onClick={() => togglePasswordVisibility(subscription.id)}
//                className="ml-1">
//                {subscription.showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
//               </button>
//             </div>
//           </div>

//         {/* Resources Tab */}
//         <div className="w-[350px] md:w-[280px] sm:w-[250px] xs:w-[220px] sm:mt-2 xs:mt-2">
//           <div className="flex justify-normal items-center text-xs mb-2">
//             <button
//               className={`px-4 py-2 w-full rounded-s-md ${activeTab === 'AlgoMatrix' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
//               onClick={() => setActiveTab('AlgoMatrix')}
//             >
//               AlgoMatrix
//             </button>
//             <button
//               className={`px-4 py-2 w-full rounded-e-md ${activeTab === 'Lirunex' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
//               onClick={() => setActiveTab('Lirunex')}
//             >
//               MT4 Terminal
//             </button>
//           </div>
                
//           {/* AlgoMatrix Section */}
//           {activeTab === 'AlgoMatrix' && (
//             <div>
//               <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1 md:text-xs sm:text-xs xs:text-xs">
//                 <div> Current Version <strong>{versionNumber}</strong> </div>
//                 <a
//                   href={`${apiUrl}/api/download/${algoMatrixUrl}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
//                 >
//                   <AiOutlineDownload className="mr-2" /> Download
//                 </a>
//               </div>
//               <div className="text-red-600 text-xs text-center">
//                 Only support MT4, installation guide click 
//                 <button
//                   className=" text-rose-600 underline hover:text-cyan-800 px-1 py-0 text-xs"
//                   onClick={() => setIsModalOpen(true)} 
//                   >
//                    here
//                 </button>
//               </div>
//             </div>
//           )}

//           {/* Lirunex Section */}
//           {activeTab === 'Lirunex' && (
//             <div>
//               <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1 md:text-xs sm:text-xs xs:text-xs">
//                 <div> Lirunex Terminal <strong>MT4</strong> </div>
//                 <a
//                   href={`${apiUrl}/api/download/${lirunexUrl}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
//                 >
//                   <AiOutlineDownload className="mr-2" /> Download
//                 </a>
//               </div>
//               <div className="text-red-600 text-xs text-center">
//                 Registration for Lirunex account click 
//                   <a 
//                     href="https://my.lirunex.online/?Referral=90859&GroupId=8046" 
//                     target="_blank" 
//                     rel="noopener noreferrer" 
//                     className=" text-rose-600 underline hover:text-cyan-800 px-1 py-0 text-xs">
//                     here
//                   </a>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//     )}

//     {/* Modal for installation instructions */}
//     {isModalOpen && (
//       <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//         <div className="bg-white p-4 rounded-lg w-auto max-w-screen sm:w-[400px]">
//           <div className="flex justify-between items-center mb-4">
//             <h1 className="text-xl font-bold p-2 sm:text-base xs:text-sm">Installation Instructions & Strategy Video</h1>
//             <button onClick={() => setIsModalOpen(false)} className="text-2xl"><AiFillCloseSquare /></button>
//           </div>

//           {/* Video Section */}
//           <div className="flex flex-row justify-between sm:flex-col xs:flex-col">
//             <div className="px-4 sm:px-2 xs:px-2">
//               <video 
//                 controls 
//                 src={`${apiUrl}/api/stream-video/${videoUrl}`} 
//                 className="w-full h-auto rounded-lg"
//                 controlsList="nodownload"
//                 onContextMenu={(e) => e.preventDefault()}
//               >
//               </video>
//             </div>

//             {/* Manual Guide Book Section */}
//             <div className="p-4 border-2 rounded-lg flex flex-col justify-center xl:justify-normal lg:justify-normal md:justify-normal sm:my-2 xs:my-2">
//               <h1 className="font-bold mb-2 sm:text-sm xs:text-sm">CONTENT</h1>
//               <div className="border-2 p-4 text-center lg:w-[220px] md:w-[200px] sm:text-sm xs:text-sm">
//                 <a href={`${apiUrl}/api/download/${pdfUrl}`} target="_blank" rel="noopener noreferrer" className="text-cyan-800 underline">
//                   Download Manual Guide Book
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       )}
//    </div>
//   );
// };

// export default SubscriptionItem;

// // update code --> 21 Aug 2024
// // src/components/client/MiniApp/App03_MySubscriptionItem.tsx

// import React, { useState, useEffect } from 'react';
// import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";
// import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineDownload, AiFillCloseSquare } from 'react-icons/ai';
// import { Subscription, Product } from '../../../hooks/Subscription/useSubscriptionData';
// import { useEnv } from '../../../context/EnvContext';

// interface SubscriptionItemProps {
//   subscription: Subscription;
//   getProductImageUrl: (image: string | undefined) => string;
//   formatDate: (date: string) => string;
//   convertPlanPeriod: (period: string) => string;
//   getStatusStyle: (status: string) => string;
//   calculateRemainingDays: (planExpiredDate: string) => number;
//   versionNumber: string;
//   togglePasswordVisibility: (subscriptionId: number) => void;
//   setParentActiveTab: (tab: string) => void; 
//   setSelectedProduct: (product: Product | null) => void;
//   setAccountDetails: React.Dispatch<React.SetStateAction<{
//     accountID: string;
//     accountType: string;
//     accountNumber: string;
//     accountPassword: string;
//     accountServer: string;
//   } | null>>;
//   handleRenewClick: (subscription: Subscription) => void; 
// }

// const SubscriptionItem: React.FC<SubscriptionItemProps> = ({
//   subscription,
//   getProductImageUrl,
//   formatDate,
//   convertPlanPeriod,
//   getStatusStyle,
//   calculateRemainingDays,
//   versionNumber,
//   togglePasswordVisibility,
//   // setParentActiveTab, 
//   // setSelectedProduct,
//   // setAccountDetails,
//   handleRenewClick,
// }) => {
//   const { apiUrl } = useEnv();
//   const [isExpanded, setIsExpanded] = useState(false);
//   const isExpandCollapseDisabled = ['Pending', 'Deactive', 'Expire'].includes(subscription.accountStatus);
//   const [activeTab, setActiveTab] = useState<'AlgoMatrix' | 'Lirunex'>('AlgoMatrix');
//   const [algoMatrixUrl, setAlgoMatrixUrl] = useState<string | null>(null);
//   const [lirunexUrl, setLirunexUrl] = useState<string | null>(null);
//   const [videoUrl, setVideoUrl] = useState<string | null>(null);
//   const [pdfUrl, setPdfUrl] = useState<string | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     const fetchUrls = async () => {
//       try {
//         const [algoResponse, lirunexResponse, videoResponse, pdfResponse] = await Promise.all([
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrix`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=lirunexTerminal`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixVideo`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixPdf`)
//         ]);
  
//         const algoData = await algoResponse.json();
//         const lirunexData = await lirunexResponse.json();
//         const videoData = await videoResponse.json();
//         const pdfData = await pdfResponse.json();
  
//         setAlgoMatrixUrl(algoData.url);
//         setLirunexUrl(lirunexData.url);
//         setVideoUrl(videoData.url);
//         setPdfUrl(pdfData.url);
//       } catch (error) {
//         console.error('Error fetching encrypted URLs:', error);
//       }
//     };
//     fetchUrls();
//   }, [apiUrl]);
  
//   // expired plan trigger
//   const planDays = calculateRemainingDays(subscription.planExpiredDate);
//   const planTrialDays = subscription.planType === 'Trial' ? calculateRemainingDays(subscription.planExpiredTrialDate) : null;

//   if (planDays === -1 || (planTrialDays !== null && planTrialDays === -1)) {
//     subscription.accountStatus = 'Expire';
//   }
//   // expired days trigger
//   const isNearExpiry = planDays !== null && planDays <= 14;
//   const isVeryNearExpiry = planDays !== null && planDays <= 5;
//   const isAnnuallyNearExpiry = planDays !== null && subscription.planPeriod === '365' && planDays <= 30;
//   const isExpired = planDays !== null && planDays < 0;
//   // expired trials trigger
//   const isTrialNearExpiry = planTrialDays !== null && planTrialDays <= 14;
//   const isTrialVeryNearExpiry = planTrialDays !== null && planTrialDays <= 5;
//   const isTrialExpired = planTrialDays !== null && planTrialDays < 0;

//   return (
//     <div key={subscription.id} className="flex flex-col items-center rounded-lg p-4 bg-gray-100 mb-4">
//       <div className="flex flex-row justify-between w-full">
//         {/* Product Image */}
//         <div className="flex flex-row items-center">
//           <img
//             src={getProductImageUrl(subscription.image)}
//             alt={subscription.name}
//             className="w-[150px] h-[150px] rounded-lg object-cover"
//           />
//           <div className="ml-4">
//             <div className='my-2'>
//               <div className="flex justify-normal items-center">
//                 {/* Product Name */}
//                 <div className="text-xl font-bold">{subscription.productName}</div>
//                 {/* Account Status */}
//                 <div className={`text-[10px] ml-2 px-4 py-0 rounded-full ${getStatusStyle(subscription.accountStatus)}`}>
//                   {subscription.accountStatus}
//                 </div>
//                 {/* Account ID */}
//                 <div className="text-xs ml-4"><strong>(id:</strong>{subscription.accountID})</div>
//               </div>
//               {/* Sub Username and Email */}
//               <div className="text-sm"><strong>{subscription.userName}</strong> ({subscription.email})</div>
//             </div>
//             <div className="flex flex-row space-x-4">
//                 {/* Sub, Plan, Period & Expired On */}
//               <div className="text-sm font-semibold">
//                 <div>Plan:</div>
//                 <div>Period:</div>
//                 <div>Expired On:</div>
//               </div>
//               <div>
//                 <div className="text-sm">{subscription.planName}</div>
//                 <div className="text-sm">
//                   {subscription.planType === 'Trial' ? `Trial with ${subscription.planTrialPeriod} Days` : `${subscription.planType} with ${convertPlanPeriod(subscription.planPeriod)}`}
//                 </div>
//                 <div className="text-sm">
//                   {subscription.planType === 'Trial' ? formatDate(subscription.planExpiredTrialDate) : formatDate(subscription.planExpiredDate)}
//                 </div>
//               </div>
//             </div>
//             {(isNearExpiry || isAnnuallyNearExpiry || isExpired || isTrialNearExpiry || isTrialExpired || subscription.accountStatus === 'Pending') && (
//               <div className={`text-rose-600 text-xs mt-2 ${isExpired || isTrialExpired ? 'cursor-pointer' : ''}`}>
//                 {subscription.accountStatus === 'Pending' ? (
//                   'Please wait for admin approval. Pay attention to your email.'
//                  ) : isExpired || isTrialExpired ? (
//                   <>
//                     {subscription.planType === 'Trial' ? 'Your trial has expired! Click ' : 'Your subscription has expired! Click '}
//                     <button
//                       className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                       onClick={() => handleRenewClick(subscription)}
//                     >
//                       here
//                     </button>
//                     {subscription.planType === 'Trial' ? ' to subscribe a plan now.' : ' to renew now.'}
//                   </>
//                  ) : isVeryNearExpiry ? (
//                     <>
//                       <span className="mr-1">
//                         Your subscription is expiring soon!
//                       </span> 
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         Renew
//                       </button>
//                       <span className="ml-1">
//                         now to avoid interruption.
//                       </span>
//                     </>
//                  ) : isAnnuallyNearExpiry ? (
//                     <>
//                       <span className="mr-1">
//                         Your annual subscription is expiring in a month. Please
//                       </span> 
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         renew
//                       </button>
//                       <span className="ml-1">
//                         it.
//                       </span>
//                     </>
//                  ) : isTrialVeryNearExpiry ? (
//                     <>
//                       <span className="mr-1">
//                         Your trial is expiring soon! Please
//                       </span>
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         renew
//                       </button>
//                       <span className="ml-1">
//                         to subscription plan.
//                       </span>
//                     </>
//                  ) : isTrialNearExpiry ? (
//                     <>
//                       <span className="mr-1">
//                         Your trial subscription is near expiry. 
//                       </span>
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         Renew
//                       </button>
//                       <span className="ml-1">
//                         now.
//                       </span>
//                     </>
//                   ) : (
//                     <>
//                       <span className="mr-1">
//                         Your subscription is near expiry. Please
//                       </span> 
//                       <button
//                         className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                         onClick={() => handleRenewClick(subscription)}
//                       >
//                         renew
//                       </button>
//                       <span className="ml-1">
//                         it.
//                       </span>
//                     </>
//                   )}
//                 </div>
//               )}
//           </div>
//         </div>
//         <div className="flex flex-row items-center">
//           <div className="flex justify-between items-center">
//             <div className="text-4xl px-4 py-2 bg-cyan-800 text-white rounded-xl">
//               {subscription.planType === 'Trial' ? (planTrialDays !== null && planTrialDays <= -1 ? 0 : planTrialDays) : (planDays !== null && planDays <= -1 ? 0 : planDays)}
//             </div>
//             <div className="text-cyan-800 text-sm ml-2">Expired<br />Days</div>
//           </div>
//           <button
//             onClick={() => !isExpandCollapseDisabled && setIsExpanded(!isExpanded)}
//             className={`text-gray-600 hover:text-cyan-800 mx-4 ${isExpandCollapseDisabled ? 'cursor-not-allowed' : ''}`}
//             disabled={isExpandCollapseDisabled}
//            >
//             {isExpanded ? <FaMinusSquare /> : <FaPlusSquare />}
//           </button>
//         </div>
//       </div>

//       {/* Expand/Collapse section */}
//       {isExpanded && (
//       <div className="mt-2 bg-white p-4 rounded-lg shadow-lg w-full">
//         <div className="flex justify-between items-center ">
//           <div>
//             <div className="text-sm"><strong>Account ID:</strong> {subscription.accountNumber}</div>
//             <div className="text-sm"><strong>Account Type:</strong> {subscription.accountType}</div>
//             <div className="text-sm"><strong>Account Server:</strong> {subscription.accountServer}</div>
//             <div className="text-sm flex items-center"><strong>Account Password:</strong>
//               <span className="ml-2">
//                 {subscription.showPassword ? 
//                 `${'*'.repeat(subscription.accountPassword.length - 4)}${subscription.accountPassword.slice(-4)}` : 
//                 '••••••••'}
//               </span>
//               <button 
//                onClick={() => togglePasswordVisibility(subscription.id)}
//                className="ml-1">
//                {subscription.showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
//               </button>
//             </div>
//         </div>

//         {/* Resources Tab */}
//         <div className="w-[350px]">
//           <div className="flex justify-normal items-center text-xs mb-2">
//             <button
//               className={`px-4 py-2 w-full rounded-s-md ${activeTab === 'AlgoMatrix' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
//               onClick={() => setActiveTab('AlgoMatrix')}
//             >
//               AlgoMatrix
//             </button>
//             <button
//               className={`px-4 py-2 w-full rounded-e-md ${activeTab === 'Lirunex' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
//               onClick={() => setActiveTab('Lirunex')}
//             >
//               Lirunex Terminal
//             </button>
//           </div>
                
//           {/* AlgoMatrix Section */}
//           {activeTab === 'AlgoMatrix' && (
//             <div>
//               <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1">
//                 <div> Current Version <strong>{versionNumber}</strong> </div>
//                 <a
//                   href={`${apiUrl}/api/download/${algoMatrixUrl}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
//                 >
//                   <AiOutlineDownload className="mr-2" /> Download
//                 </a>
//               </div>
//               <div className="text-red-600 text-xs text-center">
//                 Only support MT4, installation instruction click 
//                 <button
//                   className=" text-rose-600 underline hover:text-cyan-800 px-1 py-0 text-xs"
//                   onClick={() => setIsModalOpen(true)} 
//                   >
//                    here
//                 </button>
//               </div>
//             </div>
//           )}

//           {/* Lirunex Section */}
//           {activeTab === 'Lirunex' && (
//             <div>
//               <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1">
//                 <div> Lirunex Terminal <strong>MT4</strong> </div>
//                 <a
//                   href={`${apiUrl}/api/download/${lirunexUrl}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
//                 >
//                   <AiOutlineDownload className="mr-2" /> Download
//                 </a>
//               </div>
//               <div className="text-red-600 text-xs text-center">
//                 Registration for Lirunex account click 
//                   <a 
//                     href="https://my.lirunex.online/?Referral=90859&GroupId=8046" 
//                     target="_blank" 
//                     rel="noopener noreferrer" 
//                     className=" text-rose-600 underline hover:text-cyan-800 px-1 py-0 text-xs">
//                     here
//                   </a>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//     )}

//     {/* Modal for installation instructions */}
//     {isModalOpen && (
//       <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//         <div className="bg-white p-4 rounded-lg w-auto max-w-screen">
//           <div className="flex justify-between items-center mb-4">
//             <h1 className="text-xl font-bold p-2">Installation Instructions & Strategy Video</h1>
//             <button onClick={() => setIsModalOpen(false)} className="text-2xl"><AiFillCloseSquare /></button>
//           </div>

//           {/* Video Section */}
//           <div className="flex flex-row justify-between">
//             <div className="px-4">
//               <video 
//                 controls 
//                 src={`${apiUrl}/api/download/${videoUrl}`} 
//                 className="w-full h-auto rounded-lg"
//                 controlsList="nodownload"
//                 onContextMenu={(e) => e.preventDefault()}
//               >
//               </video>
//             </div>

//             {/* Manual Guide Book Section */}
//             <div className="p-4 border-2 rounded-lg w-[600px]">
//               <h1 className="font-bold mb-2">CONTENT</h1>
//               <div className="border-2 p-4 text-center">
//                 <a href={`${apiUrl}/api/download/${pdfUrl}`} target="_blank" rel="noopener noreferrer" className="text-cyan-800 underline">
//                   Download Manual Guide Book
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       )}
//    </div>
//   );
// };

// export default SubscriptionItem;


// // udpate code  --> 07 Aug 2024 v02
// // src/components/client/MiniApp/App03_MySubscriptionItem.tsx

// import React, { useState, useEffect } from 'react';
// import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";
// import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineDownload, AiFillCloseSquare } from 'react-icons/ai';
// import { Subscription, Product } from '../../../hooks/Subscription/useSubscriptionData';
// import { useEnv } from '../../../context/EnvContext';

// interface SubscriptionItemProps {
//   subscription: Subscription;
//   getProductImageUrl: (image: string | undefined) => string;
//   formatDate: (date: string) => string;
//   convertPlanPeriod: (period: string) => string;
//   getStatusStyle: (status: string) => string;
//   calculateRemainingDays: (planExpiredDate: string) => number;
//   versionNumber: string;
//   togglePasswordVisibility: (subscriptionId: number) => void;
//   setParentActiveTab: (tab: string) => void; 
//   setSelectedProduct: (product: Product | null) => void;
//   setAccountDetails: React.Dispatch<React.SetStateAction<{
//     accountID: string;
//     accountType: string;
//     accountNumber: string;
//     accountPassword: string;
//     accountServer: string;
//   } | null>>;
//   handleRenewClick: (subscription: Subscription) => void; 
// }

// const SubscriptionItem: React.FC<SubscriptionItemProps> = ({
//   subscription,
//   getProductImageUrl,
//   formatDate,
//   convertPlanPeriod,
//   getStatusStyle,
//   calculateRemainingDays,
//   versionNumber,
//   togglePasswordVisibility,
//   setParentActiveTab, 
//   setSelectedProduct,
//   setAccountDetails,
//   handleRenewClick,
// }) => {
//   const { apiUrl } = useEnv();
//   const [isExpanded, setIsExpanded] = useState(false);
//   const isExpandCollapseDisabled = ['Pending', 'Deactive', 'Expire'].includes(subscription.accountStatus);
//   const [activeTab, setActiveTab] = useState<'AlgoMatrix' | 'Lirunex'>('AlgoMatrix');
//   const [algoMatrixUrl, setAlgoMatrixUrl] = useState<string | null>(null);
//   const [lirunexUrl, setLirunexUrl] = useState<string | null>(null);
//   const [videoUrl, setVideoUrl] = useState<string | null>(null);
//   const [pdfUrl, setPdfUrl] = useState<string | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     const fetchUrls = async () => {
//       try {
//         const [algoResponse, lirunexResponse, videoResponse, pdfResponse] = await Promise.all([
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrix`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=lirunexTerminal`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixVideo`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixPdf`)
//         ]);
  
//         const algoData = await algoResponse.json();
//         const lirunexData = await lirunexResponse.json();
//         const videoData = await videoResponse.json();
//         const pdfData = await pdfResponse.json();
  
//         setAlgoMatrixUrl(algoData.url);
//         setLirunexUrl(lirunexData.url);
//         setVideoUrl(videoData.url);
//         setPdfUrl(pdfData.url);
//       } catch (error) {
//         console.error('Error fetching encrypted URLs:', error);
//       }
//     };
//     fetchUrls();
//   }, [apiUrl]);
  
//   // expired plan trigger
//   const planDays = calculateRemainingDays(subscription.planExpiredDate);
//   const planTrialDays = subscription.planType === 'Trial' ? calculateRemainingDays(subscription.planExpiredTrialDate) : null;

//   if (planDays === -1 || (planTrialDays !== null && planTrialDays === -1)) {
//     subscription.accountStatus = 'Expire';
//   }
//   // expired days trigger
//   const isNearExpiry = planDays !== null && planDays <= 14;
//   const isVeryNearExpiry = planDays !== null && planDays <= 5;
//   const isAnnuallyNearExpiry = planDays !== null && subscription.planPeriod === '365' && planDays <= 30;
//   const isExpired = planDays !== null && planDays < 0;
//   // expired trials trigger
//   const isTrialNearExpiry = planTrialDays !== null && planTrialDays <= 14;
//   const isTrialVeryNearExpiry = planTrialDays !== null && planTrialDays <= 5;
//   const isTrialExpired = planTrialDays !== null && planTrialDays < 0;

//   return (
//     <div key={subscription.id} className="flex flex-col items-center rounded-lg p-4 bg-gray-100 mb-4">
//       <div className="flex flex-row justify-between w-full">
//         <div className="flex flex-row items-center">
//           <img
//             src={getProductImageUrl(subscription.image)}
//             alt={subscription.name}
//             className="w-[150px] h-[150px] rounded-lg object-cover"
//           />
//           <div className="ml-4">
//             <div className='my-2'>
//               <div className="flex justify-normal items-center">
//                 <div className="text-xl font-bold">{subscription.productName}</div>
//                 <div className={`text-[10px] ml-2 px-4 py-0 rounded-full ${getStatusStyle(subscription.accountStatus)}`}>
//                   {subscription.accountStatus}
//                 </div>
//                 <div className="text-xs ml-4"><strong>(id:</strong>{subscription.accountID})</div>
//               </div>
//               <div className="text-sm"><strong>{subscription.userName}</strong> ({subscription.email})</div>
//             </div>
//             <div className="flex flex-row space-x-4">
//               <div className="text-sm font-semibold">
//                 <div>Plan:</div>
//                 <div>Period:</div>
//                 <div>Expired On:</div>
//               </div>
//               <div>
//                 <div className="text-sm">{subscription.planName}</div>
//                 <div className="text-sm">
//                   {subscription.planType === 'Trial' ? `Trial with ${subscription.planTrialPeriod} Days` : `${subscription.planType} with ${convertPlanPeriod(subscription.planPeriod)}`}
//                 </div>
//                 <div className="text-sm">
//                   {subscription.planType === 'Trial' ? formatDate(subscription.planExpiredTrialDate) : formatDate(subscription.planExpiredDate)}
//                 </div>
//               </div>
//             </div>
//             {(isNearExpiry || isAnnuallyNearExpiry || isExpired || isTrialNearExpiry || isTrialExpired) && (
//               <div className={`text-rose-600 text-xs mt-2 ${isExpired || isTrialExpired ? 'cursor-pointer' : ''}`}>
//                 {isExpired || isTrialExpired ? (
//                     <>
//                     {subscription.planType === 'Trial' ? 'Your trial has expired! Click ' : 'Your subscription has expired! Click '}
//                     <button
//                       className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                       onClick={() => handleRenewClick(subscription)}
//                     >
//                       here
//                     </button>
//                     {subscription.planType === 'Trial' ? '  to subscribe a plan now.' : ' to renew now.'}
//                     </>
//                 ) : isVeryNearExpiry ? (
//                     'Your subscription is expiring soon! Renew now to avoid interruption.'
//                 ) : isAnnuallyNearExpiry ? (
//                     'Your annual subscription is expiring in a month. Please renew it.'
//                 ) : isTrialVeryNearExpiry ? (
//                     'Your trial is expiring soon! Please Renew to subscription plan.'
//                 ) : isTrialNearExpiry ? (
//                     'Your trial subscription is near expiry.'
//                 ) : (
//                     'Your subscription is near expiry. Please renew it.'
//                 )}
//               </div>
//             )}
//           </div>
//         </div>
//         <div className="flex flex-row items-center">
//           <div className="flex justify-between items-center">
//             <div className="text-4xl px-4 py-2 bg-cyan-800 text-white rounded-xl">
//               {subscription.planType === 'Trial' ? (planTrialDays === -1 ? 0 : planTrialDays) : subscription.planDays}
//             </div>
//             <div className="text-cyan-800 text-sm ml-2">Expired<br />Days</div>
//           </div>
//           <button
//             onClick={() => !isExpandCollapseDisabled && setIsExpanded(!isExpanded)}
//             className={`text-gray-600 hover:text-cyan-800 mx-4 ${isExpandCollapseDisabled ? 'cursor-not-allowed' : ''}`}
//             disabled={isExpandCollapseDisabled}
//            >
//             {isExpanded ? <FaMinusSquare /> : <FaPlusSquare />}
//           </button>
//         </div>
//       </div>

//       {/* Expand/Collapse section */}
//       {isExpanded && (
//       <div className="mt-2 bg-white p-4 rounded-lg shadow-lg w-full">
//         <div className="flex justify-between items-center ">
//           <div>
//             <div className="text-sm"><strong>Account ID:</strong> {subscription.accountNumber}</div>
//             <div className="text-sm"><strong>Account Type:</strong> {subscription.accountType}</div>
//             <div className="text-sm"><strong>Account Server:</strong> {subscription.accountServer}</div>
//             <div className="text-sm flex items-center"><strong>Account Password:</strong>
//               <span className="ml-2">
//                 {subscription.showPassword ? 
//                 `${'*'.repeat(subscription.accountPassword.length - 4)}${subscription.accountPassword.slice(-4)}` : 
//                 '••••••••'}
//               </span>
//               <button 
//                onClick={() => togglePasswordVisibility(subscription.id)}
//                className="ml-1">
//                {subscription.showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
//               </button>
//             </div>
//         </div>

//         {/* Resources Tab */}
//         <div className="w-[350px]">
//           <div className="flex justify-normal items-center text-xs mb-2">
//             <button
//               className={`px-4 py-2 w-full rounded-s-md ${activeTab === 'AlgoMatrix' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
//               onClick={() => setActiveTab('AlgoMatrix')}
//             >
//               AlgoMatrix
//             </button>
//             <button
//               className={`px-4 py-2 w-full rounded-e-md ${activeTab === 'Lirunex' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
//               onClick={() => setActiveTab('Lirunex')}
//             >
//               Lirunex Terminal
//             </button>
//           </div>
                
//           {/* AlgoMatrix Section */}
//           {activeTab === 'AlgoMatrix' && (
//             <div>
//               <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1">
//                 <div> Current Version <strong>{versionNumber}</strong> </div>
//                 <a
//                   href={`${apiUrl}/api/download/${algoMatrixUrl}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
//                 >
//                   <AiOutlineDownload className="mr-2" /> Download
//                 </a>
//               </div>
//               <div className="text-red-600 text-xs text-center">
//                 Only support MT4, installation instruction click 
//                 <button
//                   className=" text-rose-600 underline hover:text-cyan-800 px-1 py-0 text-xs"
//                   onClick={() => setIsModalOpen(true)} 
//                   >
//                    here
//                 </button>
//               </div>
//             </div>
//           )}

//           {/* Lirunex Section */}
//           {activeTab === 'Lirunex' && (
//             <div>
//               <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1">
//                 <div> Lirunex Terminal <strong>MT4</strong> </div>
//                 <a
//                   href={`${apiUrl}/api/download/${lirunexUrl}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
//                 >
//                   <AiOutlineDownload className="mr-2" /> Download
//                 </a>
//               </div>
//               <div className="text-red-600 text-xs text-center">
//                 Registration for Lirunex account click 
//                   <a 
//                     href="https://my.lirunex.online/?Referral=90859&GroupId=8046" 
//                     target="_blank" 
//                     rel="noopener noreferrer" 
//                     className=" text-rose-600 underline hover:text-cyan-800 px-1 py-0 text-xs">
//                     here
//                   </a>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//     )}

//     {/* Modal for installation instructions */}
//     {isModalOpen && (
//       <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//         <div className="bg-white p-4 rounded-lg w-auto max-w-screen">
//           <div className="flex justify-between items-center mb-4">
//             <h1 className="text-xl font-bold p-2">Installation Instructions & Strategy Video</h1>
//             <button onClick={() => setIsModalOpen(false)} className="text-2xl"><AiFillCloseSquare /></button>
//           </div>

//           {/* Video Section */}
//           <div className="flex flex-row justify-between">
//             <div className="px-4">
//               <video 
//                 controls 
//                 src={`${apiUrl}/api/download/${videoUrl}`} 
//                 className="w-full h-auto rounded-lg"
//                 controlsList="nodownload"
//                 onContextMenu={(e) => e.preventDefault()}
//               >
//               </video>
//             </div>

//             {/* Manual Guide Book Section */}
//             <div className="p-4 border-2 rounded-lg w-[600px]">
//               <h1 className="font-bold mb-2">CONTENT</h1>
//               <div className="border-2 p-4 text-center">
//                 <a href={`${apiUrl}/api/download/${pdfUrl}`} target="_blank" rel="noopener noreferrer" className="text-cyan-800 underline">
//                   Download Manual Guide Book
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       )}
//    </div>
//   );
// };

// export default SubscriptionItem;



// // update code --> 07 Aug 2024
// // src/components/client/MiniApp/App03_MySubscriptionItem.tsx

// import React, { useState, useEffect } from 'react';
// import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";
// import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineDownload, AiFillCloseSquare } from 'react-icons/ai';
// import { Subscription, Product } from '../../../hooks/Subscription/useSubscriptionData';
// import { useEnv } from '../../../context/EnvContext';

// interface SubscriptionItemProps {
//   subscription: Subscription;
//   getProductImageUrl: (image: string | undefined) => string;
//   formatDate: (date: string) => string;
//   convertPlanPeriod: (period: string) => string;
//   getStatusStyle: (status: string) => string;
//   calculateRemainingDays: (planExpiredDate: string) => number;
//   versionNumber: string;
//   togglePasswordVisibility: (subscriptionId: number) => void;
//   setParentActiveTab: (tab: string) => void; 
//   setSelectedProduct: (product: Product | null) => void;
//   setAccountDetails: React.Dispatch<React.SetStateAction<{
//     accountID: string;
//     accountType: string;
//     accountNumber: string;
//     accountPassword: string;
//     accountServer: string;
//   } | null>>;
// }

// const SubscriptionItem: React.FC<SubscriptionItemProps> = ({
//   subscription,
//   getProductImageUrl,
//   formatDate,
//   convertPlanPeriod,
//   getStatusStyle,
//   calculateRemainingDays,
//   versionNumber,
//   togglePasswordVisibility,
//   setParentActiveTab, 
//   setSelectedProduct,
//   setAccountDetails 
// }) => {
//   const { apiUrl } = useEnv();
//   const [isExpanded, setIsExpanded] = useState(false);
//   const isExpandCollapseDisabled = ['Pending', 'Deactive', 'Expire'].includes(subscription.accountStatus);
//   const [activeTab, setActiveTab] = useState<'AlgoMatrix' | 'Lirunex'>('AlgoMatrix');
//   const [algoMatrixUrl, setAlgoMatrixUrl] = useState<string | null>(null);
//   const [lirunexUrl, setLirunexUrl] = useState<string | null>(null);
//   const [videoUrl, setVideoUrl] = useState<string | null>(null);
//   const [pdfUrl, setPdfUrl] = useState<string | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     const fetchUrls = async () => {
//       try {
//         const [algoResponse, lirunexResponse, videoResponse, pdfResponse] = await Promise.all([
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrix`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=lirunexTerminal`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixVideo`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixPdf`)
//         ]);
  
//         const algoData = await algoResponse.json();
//         const lirunexData = await lirunexResponse.json();
//         const videoData = await videoResponse.json();
//         const pdfData = await pdfResponse.json();
  
//         setAlgoMatrixUrl(algoData.url);
//         setLirunexUrl(lirunexData.url);
//         setVideoUrl(videoData.url);
//         setPdfUrl(pdfData.url);
//       } catch (error) {
//         console.error('Error fetching encrypted URLs:', error);
//       }
//     };
//     fetchUrls();
//   }, [apiUrl]);
  
//   // expired plan trigger
//   const planDays = calculateRemainingDays(subscription.planExpiredDate);
//   const planTrialDays = subscription.planType === 'Trial' ? calculateRemainingDays(subscription.planExpiredTrialDate) : null;

//   if (planDays === -1 || (planTrialDays !== null && planTrialDays === -1)) {
//     subscription.accountStatus = 'Expire';
//   }
//   // expired days trigger
//   const isNearExpiry = planDays !== null && planDays <= 14;
//   const isVeryNearExpiry = planDays !== null && planDays <= 5;
//   const isAnnuallyNearExpiry = planDays !== null && subscription.planPeriod === '365' && planDays <= 30;
//   const isExpired = planDays !== null && planDays < 0;
//   // expired trials trigger
//   const isTrialNearExpiry = planTrialDays !== null && planTrialDays <= 14;
//   const isTrialVeryNearExpiry = planTrialDays !== null && planTrialDays <= 5;
//   const isTrialExpired = planTrialDays !== null && planTrialDays < 0;

//   return (
//     <div key={subscription.id} className="flex flex-col items-center rounded-lg p-4 bg-gray-100 mb-4">
//       <div className="flex flex-row justify-between w-full">
//         <div className="flex flex-row items-center">
//           <img
//             src={getProductImageUrl(subscription.image)}
//             alt={subscription.name}
//             className="w-[150px] h-[150px] rounded-lg object-cover"
//           />
//           <div className="ml-4">
//             <div className='my-2'>
//               <div className="flex justify-normal items-center">
//                 <div className="text-xl font-bold">{subscription.productName}</div>
//                 <div className={`text-[10px] ml-2 px-4 py-0 rounded-full ${getStatusStyle(subscription.accountStatus)}`}>
//                   {subscription.accountStatus}
//                 </div>
//                 <div className="text-xs ml-4"><strong>(id:</strong>{subscription.accountID})</div>
//               </div>
//               <div className="text-sm"><strong>{subscription.userName}</strong> ({subscription.email})</div>
//             </div>
//             <div className="flex flex-row space-x-4">
//               <div className="text-sm font-semibold">
//                 <div>Plan:</div>
//                 <div>Period:</div>
//                 <div>Expired On:</div>
//               </div>
//               <div>
//                 <div className="text-sm">{subscription.planName}</div>
//                 <div className="text-sm">
//                   {subscription.planType === 'Trial' ? `Trial with ${subscription.planTrialPeriod} Days` : `${subscription.planType} with ${convertPlanPeriod(subscription.planPeriod)}`}
//                 </div>
//                 <div className="text-sm">
//                   {subscription.planType === 'Trial' ? formatDate(subscription.planExpiredTrialDate) : formatDate(subscription.planExpiredDate)}
//                 </div>
//               </div>
//             </div>
//             {(isNearExpiry || isAnnuallyNearExpiry || isExpired || isTrialNearExpiry || isTrialExpired) && (
//               <div className={`text-rose-600 text-xs mt-2 ${isExpired || isTrialExpired ? 'cursor-pointer' : ''}`}>
//                 {isExpired || isTrialExpired ? (
//                     <>
//                     {subscription.planType === 'Trial' ? 'Your trial has expired! Click ' : 'Your subscription has expired! Click '}
//                     <button
//                       className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                       onClick={() => { setParentActiveTab('products'); setSelectedProduct(subscription.product || null); }}>
//                       here
//                     </button>
//                     {subscription.planType === 'Trial' ? '  to subscribe a plan now.' : ' to renew now.'}
//                     </>
//                 ) : isVeryNearExpiry ? (
//                     'Your subscription is expiring soon! Renew now to avoid interruption.'
//                 ) : isAnnuallyNearExpiry ? (
//                     'Your annual subscription is expiring in a month. Please renew it.'
//                 ) : isTrialVeryNearExpiry ? (
//                     'Your trial is expiring soon! Please Renew to subscription plan.'
//                 ) : isTrialNearExpiry ? (
//                     'Your trial subscription is near expiry.'
//                 ) : (
//                     'Your subscription is near expiry. Please renew it.'
//                 )}
//               </div>
//             )}
//           </div>
//         </div>
//         <div className="flex flex-row items-center">
//           <div className="flex justify-between items-center">
//             <div className="text-4xl px-4 py-2 bg-cyan-800 text-white rounded-xl">
//               {subscription.planType === 'Trial' ? (planTrialDays === -1 ? 0 : planTrialDays) : subscription.planDays}
//             </div>
//             <div className="text-cyan-800 text-sm ml-2">Expired<br />Days</div>
//           </div>
//           <button
//             onClick={() => !isExpandCollapseDisabled && setIsExpanded(!isExpanded)}
//             className={`text-gray-600 hover:text-cyan-800 mx-4 ${isExpandCollapseDisabled ? 'cursor-not-allowed' : ''}`}
//             disabled={isExpandCollapseDisabled}
//            >
//             {isExpanded ? <FaMinusSquare /> : <FaPlusSquare />}
//           </button>
//         </div>
//       </div>

//       {/* Expand/Collapse section */}
//       {isExpanded && (
//       <div className="mt-2 bg-white p-4 rounded-lg shadow-lg w-full">
//         <div className="flex justify-between items-center ">
//           <div>
//             <div className="text-sm"><strong>Account ID:</strong> {subscription.accountNumber}</div>
//             <div className="text-sm"><strong>Account Type:</strong> {subscription.accountType}</div>
//             <div className="text-sm"><strong>Account Server:</strong> {subscription.accountServer}</div>
//             <div className="text-sm flex items-center"><strong>Account Password:</strong>
//               <span className="ml-2">
//                 {subscription.showPassword ? 
//                 `${'*'.repeat(subscription.accountPassword.length - 4)}${subscription.accountPassword.slice(-4)}` : 
//                 '••••••••'}
//               </span>
//               <button 
//                onClick={() => togglePasswordVisibility(subscription.id)}
//                className="ml-1">
//                {subscription.showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
//               </button>
//             </div>
//         </div>

//         {/* Resources Tab */}
//         <div className="w-[350px]">
//           <div className="flex justify-normal items-center text-xs mb-2">
//             <button
//               className={`px-4 py-2 w-full rounded-s-md ${activeTab === 'AlgoMatrix' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
//               onClick={() => setActiveTab('AlgoMatrix')}
//             >
//               AlgoMatrix
//             </button>
//             <button
//               className={`px-4 py-2 w-full rounded-e-md ${activeTab === 'Lirunex' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
//               onClick={() => setActiveTab('Lirunex')}
//             >
//               Lirunex Terminal
//             </button>
//           </div>
                
//           {/* AlgoMatrix Section */}
//           {activeTab === 'AlgoMatrix' && (
//             <div>
//               <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1">
//                 <div> Current Version <strong>{versionNumber}</strong> </div>
//                 <a
//                   href={`${apiUrl}/api/download/${algoMatrixUrl}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
//                 >
//                   <AiOutlineDownload className="mr-2" /> Download
//                 </a>
//               </div>
//               <div className="text-red-600 text-xs text-center">
//                 Only support MT4, installation instruction click 
//                 <button
//                   className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                   onClick={() => {
//                     setParentActiveTab('subscriptions');
//                     setSelectedProduct(subscription.product || null);
//                     setAccountDetails({
//                       accountID: subscription.accountID,
//                       accountType: subscription.accountType,
//                       accountNumber: subscription.accountNumber,
//                       accountPassword: subscription.accountPassword,
//                       accountServer: subscription.accountServer,
//                     });
//                    }}
//                   >
//                    here
//                 </button>
//               </div>
//             </div>
//           )}

//           {/* Lirunex Section */}
//           {activeTab === 'Lirunex' && (
//             <div>
//               <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1">
//                 <div> Lirunex Terminal <strong>MT4</strong> </div>
//                 <a
//                   href={`${apiUrl}/api/download/${lirunexUrl}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
//                 >
//                   <AiOutlineDownload className="mr-2" /> Download
//                 </a>
//               </div>
//               <div className="text-red-600 text-xs text-center">
//                 Registration for Lirunex account click 
//                   <a 
//                     href="https://my.lirunex.online/?Referral=90859&GroupId=8046" 
//                     target="_blank" 
//                     rel="noopener noreferrer" 
//                     className=" text-rose-600 underline hover:text-cyan-800 px-1 py-0 text-xs">
//                     here
//                   </a>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//     )}

//     {/* Modal for installation instructions */}
//     {isModalOpen && (
//       <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//         <div className="bg-white p-4 rounded-lg w-auto max-w-screen">
//           <div className="flex justify-between items-center mb-4">
//             <h1 className="text-xl font-bold p-2">Installation Instructions & Strategy Video</h1>
//             <button onClick={() => setIsModalOpen(false)} className="text-2xl"><AiFillCloseSquare /></button>
//           </div>

//           {/* Video Section */}
//           <div className="flex flex-row justify-between">
//             <div className="px-4">
//               <video 
//                 controls 
//                 src={`${apiUrl}/api/download/${videoUrl}`} 
//                 className="w-full h-auto rounded-lg"
//                 controlsList="nodownload"
//                 onContextMenu={(e) => e.preventDefault()}
//               >
//               </video>
//             </div>

//             {/* Manual Guide Book Section */}
//             <div className="p-4 border-2 rounded-lg w-[600px]">
//               <h1 className="font-bold mb-2">CONTENT</h1>
//               <div className="border-2 p-4 text-center">
//                 <a href={`${apiUrl}/api/download/${pdfUrl}`} target="_blank" rel="noopener noreferrer" className="text-cyan-800 underline">
//                   Download Manual Guide Book
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       )}
//    </div>
//   );
// };

// export default SubscriptionItem;

// // update code --> 06 aug 2024
// // src/components/client/MiniApp/App03_MySubscriptionItem.tsx

// import React, { useState, useEffect } from 'react';
// import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";
// import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineDownload, AiFillCloseSquare } from 'react-icons/ai';
// import { Subscription, Product } from '../../../hooks/Subscription/useSubscriptionData';
// import { useEnv } from '../../../context/EnvContext';

// interface SubscriptionItemProps {
//   subscription: Subscription;
//   getProductImageUrl: (image: string | undefined) => string;
//   formatDate: (date: string) => string;
//   convertPlanPeriod: (period: string) => string;
//   getStatusStyle: (status: string) => string;
//   calculateRemainingDays: (planExpiredDate: string) => number;
//   versionNumber: string;
//   togglePasswordVisibility: (subscriptionId: number) => void;
//   setParentActiveTab: (tab: string) => void; 
//   setSelectedProduct: (product: Product | null) => void;
// }

// const SubscriptionItem: React.FC<SubscriptionItemProps> = ({
//   subscription,
//   getProductImageUrl,
//   formatDate,
//   convertPlanPeriod,
//   getStatusStyle,
//   calculateRemainingDays,
//   versionNumber,
//   togglePasswordVisibility,
//   setParentActiveTab, 
//   setSelectedProduct
// }) => {
//   const { apiUrl } = useEnv();
//   const [isExpanded, setIsExpanded] = useState(false);
//   const isExpandCollapseDisabled = ['Pending', 'Deactive', 'Expire'].includes(subscription.accountStatus);
//   const [activeTab, setActiveTab] = useState<'AlgoMatrix' | 'Lirunex'>('AlgoMatrix');
//   const [algoMatrixUrl, setAlgoMatrixUrl] = useState<string | null>(null);
//   const [lirunexUrl, setLirunexUrl] = useState<string | null>(null);
//   const [videoUrl, setVideoUrl] = useState<string | null>(null);
//   const [pdfUrl, setPdfUrl] = useState<string | null>(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     const fetchUrls = async () => {
//       try {
//         const [algoResponse, lirunexResponse, videoResponse, pdfResponse] = await Promise.all([
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrix`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=lirunexTerminal`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixVideo`),
//           fetch(`${apiUrl}/api/encrypted-download-url?type=algoMatrixPdf`)
//         ]);
  
//         const algoData = await algoResponse.json();
//         const lirunexData = await lirunexResponse.json();
//         const videoData = await videoResponse.json();
//         const pdfData = await pdfResponse.json();
  
//         setAlgoMatrixUrl(algoData.url);
//         setLirunexUrl(lirunexData.url);
//         setVideoUrl(videoData.url);
//         setPdfUrl(pdfData.url);
//       } catch (error) {
//         console.error('Error fetching encrypted URLs:', error);
//       }
//     };
//     fetchUrls();
//   }, [apiUrl]);
  
//   // expired plan trigger
//   const planDays = calculateRemainingDays(subscription.planExpiredDate);
//   const planTrialDays = subscription.planType === 'Trial' ? calculateRemainingDays(subscription.planExpiredTrialDate) : null;

//   if (planDays === -1 || (planTrialDays !== null && planTrialDays === -1)) {
//     subscription.accountStatus = 'Expire';
//   }
//   // expired days trigger
//   const isNearExpiry = planDays !== null && planDays <= 14;
//   const isVeryNearExpiry = planDays !== null && planDays <= 5;
//   const isAnnuallyNearExpiry = planDays !== null && subscription.planPeriod === '365' && planDays <= 30;
//   const isExpired = planDays !== null && planDays < 0;
//   // expired trials trigger
//   const isTrialNearExpiry = planTrialDays !== null && planTrialDays <= 14;
//   const isTrialVeryNearExpiry = planTrialDays !== null && planTrialDays <= 5;
//   const isTrialExpired = planTrialDays !== null && planTrialDays < 0;

//   return (
//     <div key={subscription.id} className="flex flex-col items-center rounded-lg p-4 bg-gray-100 mb-4">
//       <div className="flex flex-row justify-between w-full">
//         <div className="flex flex-row items-center">
//           <img
//             src={getProductImageUrl(subscription.image)}
//             alt={subscription.name}
//             className="w-[150px] h-[150px] rounded-lg object-cover"
//           />
//           <div className="ml-4">
//             <div className='my-2'>
//               <div className="flex justify-normal items-center">
//                 <div className="text-xl font-bold">{subscription.productName}</div>
//                 <div className={`text-[10px] ml-2 px-4 py-0 rounded-full ${getStatusStyle(subscription.accountStatus)}`}>
//                   {subscription.accountStatus}
//                 </div>
//                 <div className="text-xs ml-4"><strong>(id:</strong>{subscription.accountID})</div>
//               </div>
//               <div className="text-sm"><strong>{subscription.userName}</strong> ({subscription.email})</div>
//             </div>
//             <div className="flex flex-row space-x-4">
//               <div className="text-sm font-semibold">
//                 <div>Plan:</div>
//                 <div>Period:</div>
//                 <div>Expired On:</div>
//               </div>
//               <div>
//                 <div className="text-sm">{subscription.planName}</div>
//                 <div className="text-sm">
//                   {subscription.planType === 'Trial' ? `Trial with ${subscription.planTrialPeriod} Days` : `${subscription.planType} with ${convertPlanPeriod(subscription.planPeriod)}`}
//                 </div>
//                 <div className="text-sm">
//                   {subscription.planType === 'Trial' ? formatDate(subscription.planExpiredTrialDate) : formatDate(subscription.planExpiredDate)}
//                 </div>
//               </div>
//             </div>
//             {(isNearExpiry || isAnnuallyNearExpiry || isExpired || isTrialNearExpiry || isTrialExpired) && (
//               <div className={`text-rose-600 text-xs mt-2 ${isExpired || isTrialExpired ? 'cursor-pointer' : ''}`}>
//                 {isExpired || isTrialExpired ? (
//                     <>
//                     {subscription.planType === 'Trial' ? 'Your trial has expired! Click ' : 'Your subscription has expired! Click '}
//                     <button
//                       className="border border-rose-600 text-rose-600 hover:bg-rose-500 hover:text-white px-2 py-0 rounded-full text-xs"
//                       onClick={() => { setParentActiveTab('products'); setSelectedProduct(subscription.product || null); }}>
//                       here
//                     </button>
//                     {subscription.planType === 'Trial' ? '  to subscribe a plan now.' : ' to renew now.'}
//                     </>
//                 ) : isVeryNearExpiry ? (
//                     'Your subscription is expiring soon! Renew now to avoid interruption.'
//                 ) : isAnnuallyNearExpiry ? (
//                     'Your annual subscription is expiring in a month. Please renew it.'
//                 ) : isTrialVeryNearExpiry ? (
//                     'Your trial is expiring soon! Please Renew to subscription plan.'
//                 ) : isTrialNearExpiry ? (
//                     'Your trial subscription is near expiry.'
//                 ) : (
//                     'Your subscription is near expiry. Please renew it.'
//                 )}
//               </div>
//             )}
//           </div>
//         </div>
//         <div className="flex flex-row items-center">
//           <div className="flex justify-between items-center">
//             <div className="text-4xl px-4 py-2 bg-cyan-800 text-white rounded-xl">
//               {subscription.planType === 'Trial' ? (planTrialDays === -1 ? 0 : planTrialDays) : subscription.planDays}
//             </div>
//             <div className="text-cyan-800 text-sm ml-2">Expired<br />Days</div>
//           </div>
//           <button
//             onClick={() => !isExpandCollapseDisabled && setIsExpanded(!isExpanded)}
//             className={`text-gray-600 hover:text-cyan-800 mx-4 ${isExpandCollapseDisabled ? 'cursor-not-allowed' : ''}`}
//             disabled={isExpandCollapseDisabled}
//            >
//             {isExpanded ? <FaMinusSquare /> : <FaPlusSquare />}
//           </button>
//         </div>
//       </div>

//       {/* Expand/Collapse section */}
//       {isExpanded && (
//       <div className="mt-2 bg-white p-4 rounded-lg shadow-lg w-full">
//         <div className="flex justify-between items-center ">
//           <div>
//             <div className="text-sm"><strong>Account ID:</strong> {subscription.accountNumber}</div>
//             <div className="text-sm"><strong>Account Type:</strong> {subscription.accountType}</div>
//             <div className="text-sm"><strong>Account Server:</strong> {subscription.accountServer}</div>
//             <div className="text-sm flex items-center"><strong>Account Password:</strong>
//               <span className="ml-2">
//                 {subscription.showPassword ? 
//                 `${'*'.repeat(subscription.accountPassword.length - 4)}${subscription.accountPassword.slice(-4)}` : 
//                 '••••••••'}
//               </span>
//               <button 
//                onClick={() => togglePasswordVisibility(subscription.id)}
//                className="ml-1">
//                {subscription.showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
//               </button>
//             </div>
//         </div>

//         {/* Resources Tab */}
//         <div className="w-[350px]">
//           <div className="flex justify-normal items-center text-xs mb-2">
//             <button
//               className={`px-4 py-2 w-full rounded-s-md ${activeTab === 'AlgoMatrix' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
//               onClick={() => setActiveTab('AlgoMatrix')}
//             >
//               AlgoMatrix
//             </button>
//             <button
//               className={`px-4 py-2 w-full rounded-e-md ${activeTab === 'Lirunex' ? 'bg-cyan-800 text-white' : 'bg-gray-200 text-black'}`}
//               onClick={() => setActiveTab('Lirunex')}
//             >
//               Lirunex Terminal
//             </button>
//           </div>
                
//           {/* AlgoMatrix Section */}
//           {activeTab === 'AlgoMatrix' && (
//             <div>
//               <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1">
//                 <div> Current Version <strong>{versionNumber}</strong> </div>
//                 <a
//                   href={`${apiUrl}/api/download/${algoMatrixUrl}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
//                 >
//                   <AiOutlineDownload className="mr-2" /> Download
//                 </a>
//               </div>
//               <div className="text-red-600 text-xs text-center">
//                 Only support MT4, installation instruction click 
//                 <button 
//                   onClick={() => setIsModalOpen(true)} 
//                   className="text-rose-600 underline hover:text-cyan-800 px-1 py-1 text-xs">
//                     here
//                 </button>.
//               </div>
//             </div>
//           )}

//           {/* Lirunex Section */}
//           {activeTab === 'Lirunex' && (
//             <div>
//               <div className="text-sm flex flex-row justify-center items-center space-x-6 py-1">
//                 <div> Lirunex Terminal <strong>MT4</strong> </div>
//                 <a
//                   href={`${apiUrl}/api/download/${lirunexUrl}`}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-xs flex items-center px-3 py-1 border-cyan-800 border text-cyan-800 hover:bg-cyan-800 hover:text-white rounded-full"
//                 >
//                   <AiOutlineDownload className="mr-2" /> Download
//                 </a>
//               </div>
//               <div className="text-red-600 text-xs text-center">
//                 Registration for Lirunex account click 
//                   <a 
//                     href="https://my.lirunex.online/?Referral=90859&GroupId=8046" 
//                     target="_blank" 
//                     rel="noopener noreferrer" 
//                     className=" text-rose-600 underline hover:text-cyan-800 px-1 py-0 text-xs">
//                     here
//                   </a>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//     )}

//     {/* Modal for installation instructions */}
//     {isModalOpen && (
//       <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//         <div className="bg-white p-4 rounded-lg w-auto max-w-screen">
//           <div className="flex justify-between items-center mb-4">
//             <h1 className="text-xl font-bold p-2">Installation Instructions & Strategy Video</h1>
//             <button onClick={() => setIsModalOpen(false)} className="text-2xl"><AiFillCloseSquare /></button>
//           </div>

//           {/* Video Section */}
//           <div className="flex flex-row justify-between">
//             <div className="px-4">
//               <video 
//                 controls 
//                 src={`${apiUrl}/api/download/${videoUrl}`} 
//                 className="w-full h-auto rounded-lg"
//                 controlsList="nodownload"
//                 onContextMenu={(e) => e.preventDefault()}
//               >
//               </video>
//             </div>

//             {/* Manual Guide Book Section */}
//             <div className="p-4 border-2 rounded-lg w-[600px]">
//               <h1 className="font-bold mb-2">CONTENT</h1>
//               <div className="border-2 p-4 text-center">
//                 <a href={`${apiUrl}/api/download/${pdfUrl}`} target="_blank" rel="noopener noreferrer" className="text-cyan-800 underline">
//                   Download Manual Guide Book
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       )}
//    </div>
//   );
// };

// export default SubscriptionItem;
