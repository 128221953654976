
// src/components/client/MiniApp/App01_MyTicket.tsx

import React, { useState, useEffect } from 'react';
import { useTheme } from '../../../context/ThemeContext';
import { useEnv } from '../../../context/EnvContext';

import { FaSearch, FaInfoCircle, FaSyncAlt, FaTag, FaMapMarkerAlt, FaTimes } from 'react-icons/fa';
import { IoTicketSharp } from "react-icons/io5";

import MyTicketAlert from './App_AlertMessage';
import EventDetail from '../MiniApp/App01_MyTicketEventDetail';

// import hook file
import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
import useAttendEvent from '../../../hooks/MiniApp/useEventAttend';
import useEventData, { 
  Event, MyTicketEvent, getEventImagePath, getEventStatusStyle,
  convertToShortForm, getTagColor, formatDateWithTime, isToday, 
  formatAmount
} from '../../../hooks/MiniApp/useEventData';

// import assets
import r1Logo from '../../../assets/login/logo_r1_ori_v02.png';
import officeImg from '../../../assets/miniApp/NoahSpace_img.png';

const MyTicket: React.FC = () => {
  const { apiUrl } = useEnv();
  const { theme } = useTheme();
  const { getSlotMessage, fetchApprovedEvents, events, myTickets, fetchEvents } = useEventData();
  const { attendEvent } = useAttendEvent();
  const { user } = useUserDashboard();
  const [activeTab, setActiveTab] = useState<'Event List' | 'My Ticket'>('Event List');
  const [selectedEvent, setSelectedEvent] = useState<Event | MyTicketEvent | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [onlineConferenceModalVisible, setOnlineConferenceModalVisible] = useState<boolean>(false);
  const [onlineConferenceText, setOnlineConferenceText] = useState<string>('');
  const [confirmAction, setConfirmAction] = useState<{ type: 'attend', ticketId: string, eventTitle: string } | null>(null);
  const [attendedTickets, setAttendedTickets] = useState<string[]>([]);
  const [disabledTickets, setDisabledTickets] = useState<string[]>([]);
  const [alert, setAlert] = useState<{ type: 'error' | 'success' | 'confirm' | 'warning' | 'check'; message: string; visible: boolean; onClose: () => void }>({
    type: 'error',
    message: '',
    visible: false,
    onClose: () => {}
  });

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    if (user && user.email) {
      fetchApprovedEvents(user.email);
    }
  }, [user, fetchApprovedEvents]);

  useEffect(() => {
  }, [myTickets]);

  const handleDetailClick = (event: Event) => {
    if (event.slot === 0) {
      setAlert({
        type: 'error',
        message: 'Ticket slot already filled and sold out.',
        visible: true,
        onClose: () => setAlert((prevState) => ({ ...prevState, visible: false }))
      });
      return;
    }
    setSelectedEvent(event);
  };

  const handleRefreshClick = () => {
    fetchEvents();
    if (user && user.email) {
      fetchApprovedEvents(user.email);
    }
  };

  const handleAttendClick = (ticketId: string, eventTitle: string) => {
    setDisabledTickets((prev) => [...prev, ticketId]);
    setConfirmAction({ type: 'attend', ticketId, eventTitle });
    setAlert({
      type: 'check',
      message: 'Are you sure you want to mark this event as attended?',
      visible: true,
      onClose: () => setAlert((prevState) => ({ ...prevState, visible: false }))
    });
  }; 

  const handleConfirm = async () => {
    if (confirmAction) {
      await attendEvent(confirmAction.ticketId, confirmAction.eventTitle);
      setAttendedTickets((prev) => [...prev, confirmAction.ticketId]); // Update attended tickets
      if (user && user.email) {
        fetchApprovedEvents(user.email);
      }
    }
    setAlert((prevState) => ({ ...prevState, visible: false }));
    setConfirmAction(null);
  };

  const handleShowLocation = () => setLocationModalVisible(true);
  const handleCloseLocationModal = () => setLocationModalVisible(false);

  const filteredEvents = activeTab === 'Event List' 
    ? events.filter(event => event.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : myTickets.filter(event => event.eventTitle.toLowerCase().includes(searchTerm.toLowerCase()));

  const getDaysUntilEvent = (eventDate: string): number => {
    const today = new Date();
    const event = new Date(eventDate);
    const diffTime = event.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };   

  const styles = {
    icon: {
      color: theme.primaryColor,
    },
    title: {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
    text: {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
    },
    button: {
      color: theme.text2Color,
      backgroundColor: theme.primaryColor,
      fontFamily: theme.fontFamily,
    },
  };

  // Event List Card
  const renderEventListCard = (event: Event) => (
    <div key={event.id} className="flex flex-row items-center rounded-lg p-4 relative bg-gray-100 sm:flex-col xs:flex-col">
      <img
        src={getEventImagePath(event.image, apiUrl)}
        alt={event.eventTitle}
        className="flex w-[180px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
      />
      <div className="mt-2 flex-1 sm:mt-0 xs:mt-0">
        <div className="text-xl text-left font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-sm xs:text-center" 
          style={styles.title}>
            {event.name}
        </div>
        <div className="flex flex-wrap">
        {event.MemberType.map((memberType, index) => {
          const shortForm = convertToShortForm(memberType);
          const tagColor = getTagColor(shortForm);
          return (
            <div 
              key={index} 
              className={`${tagColor} py-1 px-2 rounded-lg text-xs flex items-center space-x-1 sm:text-[10px] xs:text-[10px]`} 
              title={memberType}>
              <FaTag />
              <span>{shortForm}</span>
            </div>
          );
        })}
      </div>
        <div className="my-2">
          <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
            <strong>Date:</strong> {formatDateWithTime(event.startDate, event.time)}
          </div>
          <div className="flex flex-wrap items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
            <strong>Venue:</strong> {event.location || 'N/A'}
          </div>
          <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
            <strong>Amount:</strong> {event.amount === 0 ? 'Free of Charges' : `${event.currency} ${formatAmount(event.amount)}`}
          </div>
        </div>
        <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
          <span className="mr-2"><strong>Slots:</strong></span>{getSlotMessage(event.slot)}
        </div>
      </div>
      <button
        className="px-2 mt-4 rounded-full self-center flex items-center justify-around md:text-sm sm:text-xs xs:text-xs"
        style={styles.icon}
        onClick={() => handleDetailClick(event)}
        title="View Details"
      >
        <FaInfoCircle className="w-5 h-5 md:w-4 sm:w-3 xs:w-3" />
        <div className='ml-2' style={styles.title}>
          More Details
        </div>
      </button>
    </div>
  );
  
  // My Ticket List Card
  const renderMyTicketCard = (event: MyTicketEvent) => {
    const daysUntilEvent = getDaysUntilEvent(event.appointmentDate);
    const isEventToday = isToday(event.appointmentDate);
    const isAttended = attendedTickets.includes(event.ticketId);
    const isExpired = new Date(event.appointmentDate) < new Date(); 
  
    return (
      <div key={event.ticketId} className="flex flex-row items-center rounded-lg p-4 relative bg-gray-100 sm:flex-col xs:flex-col">
        {/* {isExpired && !isEventToday && (
          <div className="absolute inset-0 bg-gray-300 opacity-95 rounded-lg flex items-center justify-center">
            <span className="text-white bg-rose-700 py-2 px-4 rounded-full">{event.eventTitle} expired on {formatDateWithTime(event.appointmentDate, event.time)}</span>
          </div>
        )} */}
        <img
          src={getEventImagePath(event.image, apiUrl)}
          alt={event.eventTitle}
          className="flex w-[180px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
        />
        <div className="mt-4 flex-1 sm:mt-0 xs:mt-0">
          <div className="text-xl text-left font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-sm xs:text-center" 
              style={styles.title}>{event.eventTitle}</div>
            <div className="my-2">
              <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
                <span className="font-extrabold mr-4">Ticket ID:</span>{event.ticketId}
              </div>
              <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
                <span className="font-extrabold mr-4">Date:</span>{formatDateWithTime(event.appointmentDate, event.time)}
              </div>
              <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
                <span className="font-extrabold mr-4">Location:</span>{event.location}
                  {event.location === "Noah's Space" && (
                    <button
                      type="button"
                      className="ml-2"
                      onClick={handleShowLocation}
                    >
                      <FaMapMarkerAlt className="ml-1 w-4 h-4 xs:w-3" style={styles.icon} />
                    </button>
                  )}
                    {event.location === "Online Conference" && (
                      <button
                        type="button"
                        className="ml-2"
                        onClick={() => {
                            setOnlineConferenceText(event.onlineConferenceText || '');
                            setOnlineConferenceModalVisible(true);
                        }}
                      >
                        <FaInfoCircle className="ml-1 w-4 h-4 xs:w-3" style={styles.icon} />
                      </button>
                    )}
                  </div>
                </div>
                
                {/* Noah Space Modal */}
                {locationModalVisible && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
                      <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto lg:text-base md:text-base sm:text-sm xs:text-xs">
                        <div className="flex justify-between items-center mb-4">
                          <h2 className="text-xl font-semibold">Location Details</h2>
                            <button onClick={handleCloseLocationModal} className="text-gray-500 hover:text-gray-800">
                              <FaTimes className="w-6 h-6" />
                            </button>
                          </div>
                        <div>
                        <div className="mb-4">
                          <h3 className="font-semibold">Address:</h3>
                          <p>Unit 3-2-2, Kompleks Kantonmen Prima, 698, Jln Ipoh, Taman Bamboo, 51200 Kuala Lumpur, Federal Territory of Kuala Lumpur</p>
                        </div>
                        <div className="mb-4">
                          <h3 className="font-semibold">Google Maps:</h3>
                          <div className="flex items-center">
                              <a 
                                  href="https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7" 
                                  target="_blank" 
                                  rel="noopener noreferrer" 
                                  className="text-blue-500 underline"
                                  style={{ color: theme.primaryColor }}
                              >
                                  https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7
                              </a>
                          </div>
                        </div>
                      <div>
                        <img src={officeImg} alt="Noah's Space location" className="w-full h-auto" />
                      </div>
                    </div>
                  </div>
                </div>
                )}
  
                {/* Online Link Modal */}
                {onlineConferenceModalVisible && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
                    <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-semibold">Online Conference Details</h2>
                        <button onClick={() => setOnlineConferenceModalVisible(false)} className="text-gray-500 hover:text-gray-800">
                          <FaTimes className="w-6 h-6" />
                        </button>
                      </div>
                      <div>
                        <div className="mb-4">
                          <h3 className="font-semibold">Details:</h3>
                          <p dangerouslySetInnerHTML={{ __html: onlineConferenceText }}></p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              <div className="text-sm text-zinc-600 flex items-center mt-2 md:text-xs sm:text-xs xs:text-xs">
                <span className="font-extrabold mr-2">Event Status:</span>
                <span className={getEventStatusStyle(event.eventStatus)}>{event.eventStatus}</span>
              </div>
            </div>
            <div className="flex items-center">
              <button
                className={`px-4 py-2 mt-4 mr-4 rounded-full self-center flex items-center justify-around md:text-sm sm:text-xs xs:text-xs ${
                  (isExpired && !isEventToday) || isAttended || event.eventStatus === 'attend' 
                    ? 'bg-gray-400 text-gray-100 cursor-not-allowed'
                    : disabledTickets.includes(event.ticketId) 
                    ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
                    : 'bg-blue-500 text-white'
                  }`}
                onClick={() => isEventToday && event.eventStatus !== 'attend' && !isAttended && handleAttendClick(event.ticketId, event.eventTitle)}
                title={(isExpired && !isEventToday) || isAttended || event.eventStatus === 'attend' ? 'Expired' : 'Attend'}
                disabled={
                  ((isExpired && !isEventToday) ||
                  isAttended ||
                  event.eventStatus === 'attend' ||
                  disabledTickets.includes(event.ticketId))
                }
                style={{ 
                  backgroundColor: 
                    ((isExpired && !isEventToday) || isAttended || event.eventStatus === 'attend')
                      ? 'gray'
                      : disabledTickets.includes(event.ticketId)
                      ? 'gray'
                      : theme.primaryColor, 
                  fontFamily: theme.fontFamily 
                }}
              >
                {(isExpired && !isEventToday) 
                  ? 'Expired'
                  : isAttended || event.eventStatus === 'attend' 
                  ? 'Attended' 
                  : isEventToday ? 'Check-In' : `Event in ${daysUntilEvent} day(s)`}
              </button>
            </div>
          </div>
        );
      };

  return (
    <div className="h-auto w-auto px-4 py-2">
      <MyTicketAlert 
        {...alert}
        onConfirm={handleConfirm}
        onCancel={() => setAlert((prevState) => ({ ...prevState, visible: false }))}
      />
      {!selectedEvent && (
        <div className="flex flex-row justify-between items-center px-6 py-4 border-2 rounded-xl sm:flex-col sm:p-4 xs:flex-col xs:p-3 xs:rounded-lg">
          <div className="flex items-center">
            <img src={r1Logo} alt="Logo" className="w-18 h-14 mr-4 md:w-14 md:h-14 md:mr-2 sm:w-[65px] xs:w-[55px]" />
            <div className="mx-4 mt-5">
              <div className="text-xl font-bold lg:text-lg md:text-base sm:text-sm xs:text-sm" style={styles.title}>
                  Classes and Events
              </div>
            </div>
          </div>
          <div className="flex items-center p-2 rounded-lg mt-2">
            <div className="relative">
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search events..."
                className="p-2 pl-10 rounded border-2 w-auto md:w-[220px] sm:w-full sm:h-[35px] sm:text-sm xs:h-[30px] xs:w-[150px] xs:text-xs"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button onClick={handleRefreshClick} className="ml-4 xs:mr-4" title="Refresh">
              <FaSyncAlt style={styles.title}/>
            </button>
          </div>
        </div>
      )}

      {!selectedEvent ? (
        <div className="mt-4 px-2 rounded-lg overflow-hidden">
          <div className="flex justify-around font-semibold sm:text-sm xs:text-xs"
              style={styles.title}>
            <button
              className={`${activeTab === 'Event List' ? 'border-b-4 border-cyan-700' : ''}`}
              onClick={() => setActiveTab('Event List')}
              style={styles.title}>
              Event List
            </button>
            <button
              className={`${activeTab === 'My Ticket' ? 'border-b-4 border-cyan-700' : ''}`}
              onClick={() => setActiveTab('My Ticket')}
              style={styles.title}>
              My Ticket
            </button>
          </div>
          <div className="mt-4 space-y-4 overflow-y-auto h-auto max-h-[500px] xl:h-[430px] lg:h-[420px] md:h-[400px] sm:h-[400px] xs:h-[250px]">
            {activeTab === 'Event List'
              ? filteredEvents.length > 0 
                ? (filteredEvents as Event[]).map(renderEventListCard)
                : (
                  <div className="flex flex-col items-center justify-center h-full border-4 border-dashed border-gray-200 p-4 rounded-lg">
                    <div className="flex justify-center items-center">
                      <p className="text-lg font-semibold mb-2 sm:text-base xs:text-sm">No events available</p>
                      <FaSearch className="w-6 h-6 ml-2 mb-2 sm:w-4 xs:w-[15px]"/>
                    </div>
                    <p className="text-gray-400 mb-4 sm:text-center sm:text-sm xs:text-center xs:text-xs">Please check back later for more events.</p>
                  </div>
                )
              : (filteredEvents as MyTicketEvent[]).length > 0 
                ? (filteredEvents as MyTicketEvent[]).map(renderMyTicketCard)
                : (
                  <div className="flex flex-col items-center justify-center h-full border-4 border-dashed border-gray-200 p-4 rounded-lg">
                    <div className="flex justify-center items-center">
                      <p className="text-lg font-semibold mb-2 sm:text-base xs:text-sm">You have no tickets yet</p>
                      <IoTicketSharp className="w-6 h-6 ml-2 mb-2 sm:w-4 xs:w-[15px]"/>
                    </div>
                    <p className="text-gray-400 mb-4 sm:text-center sm:text-sm xs:text-center xs:text-xs">Browse our events and buy tickets to join.</p>
                    <button
                      onClick={() => setActiveTab('Event List')}
                      className="px-4 py-2 rounded-lg sm:text-sm xs:text-sm"
                      style={styles.button}>
                        Go to Event List
                    </button>
                  </div>
                )}
            </div>
          </div>
      ) : (
        <EventDetail event={selectedEvent} onBack={() => setSelectedEvent(null)} />
      )}
    </div>
  );
};

export default MyTicket;

// // udpate code --> 04 aug 2024 v02
// // src/components/client/MiniApp/App01_MyTicket.tsx

// import React, { useState, useEffect } from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import { useEnv } from '../../../context/EnvContext';

// import { FaSearch, FaInfoCircle, FaSyncAlt, FaTag, FaMapMarkerAlt, FaTimes } from 'react-icons/fa';
// import { IoTicketSharp } from "react-icons/io5";

// import MyTicketAlert from './App_AlertMessage';
// import EventDetail from '../MiniApp/App01_MyTicketEventDetail';

// // import hook file
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useAttendEvent from '../../../hooks/MiniApp/useEventAttend';
// import useEventData, { 
//   Event, MyTicketEvent, getEventImagePath, getEventStatusStyle,
//   convertToShortForm, getTagColor, formatDateWithTime, isToday, 
//   formatAmount
// } from '../../../hooks/MiniApp/useEventData';

// // import assets
// import r1Logo from '../../../assets/login/logo_r1_ori_v02.png';
// import officeImg from '../../../assets/miniApp/NoahSpace_img.png';

// const MyTicket: React.FC = () => {
//   const { apiUrl } = useEnv();
//   const { theme } = useTheme();
//   const { getSlotMessage, fetchApprovedEvents, events, myTickets, fetchEvents } = useEventData();
//   const { attendEvent } = useAttendEvent();
//   const { user } = useUserDashboard();
//   const [activeTab, setActiveTab] = useState<'Event List' | 'My Ticket'>('Event List');
//   const [selectedEvent, setSelectedEvent] = useState<Event | MyTicketEvent | null>(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [locationModalVisible, setLocationModalVisible] = useState(false);
//   const [onlineConferenceModalVisible, setOnlineConferenceModalVisible] = useState<boolean>(false);
//   const [onlineConferenceText, setOnlineConferenceText] = useState<string>('');
//   const [confirmAction, setConfirmAction] = useState<{ type: 'attend', ticketId: string, eventTitle: string } | null>(null);
//   const [attendedTickets, setAttendedTickets] = useState<string[]>([]);
//   const [disabledTickets, setDisabledTickets] = useState<string[]>([]);
//   const [alert, setAlert] = useState<{ type: 'error' | 'success' | 'confirm' | 'warning' | 'check'; message: string; visible: boolean; onClose: () => void }>({
//     type: 'error',
//     message: '',
//     visible: false,
//     onClose: () => {}
//   });

//   useEffect(() => {
//     fetchEvents();
//   }, [fetchEvents]);

//   useEffect(() => {
//     if (user && user.email) {
//       fetchApprovedEvents(user.email);
//     }
//   }, [user, fetchApprovedEvents]);

//   useEffect(() => {
//   }, [myTickets]);

//   const handleDetailClick = (event: Event) => {
//     if (event.slot === 0) {
//       setAlert({
//         type: 'error',
//         message: 'Ticket slot already filled and sold out.',
//         visible: true,
//         onClose: () => setAlert((prevState) => ({ ...prevState, visible: false }))
//       });
//       return;
//     }
//     setSelectedEvent(event);
//   };

//   const handleRefreshClick = () => {
//     fetchEvents();
//     if (user && user.email) {
//       fetchApprovedEvents(user.email);
//     }
//   };

//   const handleAttendClick = (ticketId: string, eventTitle: string) => {
//     setDisabledTickets((prev) => [...prev, ticketId]);
//     setConfirmAction({ type: 'attend', ticketId, eventTitle });
//     setAlert({
//       type: 'check',
//       message: 'Are you sure you want to mark this event as attended?',
//       visible: true,
//       onClose: () => setAlert((prevState) => ({ ...prevState, visible: false }))
//     });
//   }; 

//   const handleConfirm = async () => {
//     if (confirmAction) {
//       await attendEvent(confirmAction.ticketId, confirmAction.eventTitle);
//       setAttendedTickets((prev) => [...prev, confirmAction.ticketId]); // Update attended tickets
//       if (user && user.email) {
//         fetchApprovedEvents(user.email);
//       }
//     }
//     setAlert((prevState) => ({ ...prevState, visible: false }));
//     setConfirmAction(null);
//   };

//   const handleShowLocation = () => setLocationModalVisible(true);
//   const handleCloseLocationModal = () => setLocationModalVisible(false);

//   const filteredEvents = activeTab === 'Event List' 
//     ? events.filter(event => event.name.toLowerCase().includes(searchTerm.toLowerCase()))
//     : myTickets.filter(event => event.eventTitle.toLowerCase().includes(searchTerm.toLowerCase()));

//   const getDaysUntilEvent = (eventDate: string): number => {
//     const today = new Date();
//     const event = new Date(eventDate);
//     const diffTime = event.getTime() - today.getTime();
//     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//     return diffDays;
//   };   

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   // Event List Card
//   const renderEventListCard = (event: Event) => (
//     <div key={event.id} className="flex flex-row items-center rounded-lg p-4 relative bg-gray-100 sm:flex-col xs:flex-col">
//       <img
//         src={getEventImagePath(event.image, apiUrl)}
//         alt={event.eventTitle}
//         className="flex w-[200px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
//       />
//       <div className="mt-4 flex-1 sm:mt-0 xs:mt-0">
//         <div className="text-xl text-left font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-sm xs:text-center" 
//           style={styles.title}>
//             {event.name}
//         </div>
//         <div className="flex flex-wrap">
//         {event.MemberType.map((memberType, index) => {
//           const shortForm = convertToShortForm(memberType);
//           const tagColor = getTagColor(shortForm);
//           return (
//             <div 
//               key={index} 
//               className={`${tagColor} py-1 px-2 rounded-lg text-xs flex items-center space-x-1 sm:text-[10px] xs:text-[10px]`} 
//               title={memberType}>
//               <FaTag />
//               <span>{shortForm}</span>
//             </div>
//           );
//         })}
//       </div>
//         <div className="my-2">
//           <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//             <strong>Date:</strong> {formatDateWithTime(event.startDate, event.time)}
//           </div>
//           <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//             <strong>Venue:</strong> {event.location || 'N/A'}
//           </div>
//           <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//             <strong>Amount:</strong> {event.amount === 0 ? 'Free of Charges' : `${event.currency} ${formatAmount(event.amount)}`}
//           </div>
//         </div>
//         <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//           <span className="mr-2"><strong>Slots:</strong></span>{getSlotMessage(event.slot)}
//         </div>
//       </div>
//       <button
//         className="px-2 mt-4 rounded-full self-center flex items-center justify-around md:text-sm sm:text-xs xs:text-xs"
//         style={styles.icon}
//         onClick={() => handleDetailClick(event)}
//         title="View Details"
//       >
//         <FaInfoCircle className="w-5 h-5 md:w-4 sm:w-3 xs:w-3" />
//         <div className='ml-2' style={styles.title}>
//           More Details
//         </div>
//       </button>
//     </div>
//   );
  
//   // My Ticket List Card
//   const renderMyTicketCard = (event: MyTicketEvent) => {
//     const daysUntilEvent = getDaysUntilEvent(event.appointmentDate);
//     const isEventToday = isToday(event.appointmentDate);
//     const isAttended = attendedTickets.includes(event.ticketId);
//     const isExpired = new Date(event.appointmentDate) < new Date(); 
  
//     return (
//       <div key={event.ticketId} className="flex flex-row items-center rounded-lg p-4 relative bg-gray-100 sm:flex-col xs:flex-col">
//         {/* {isExpired && !isEventToday && (
//           <div className="absolute inset-0 bg-gray-300 opacity-95 rounded-lg flex items-center justify-center">
//             <span className="text-white bg-rose-700 py-2 px-4 rounded-full">{event.eventTitle} expired on {formatDateWithTime(event.appointmentDate, event.time)}</span>
//           </div>
//         )} */}
//         <img
//           src={getEventImagePath(event.image, apiUrl)}
//           alt={event.eventTitle}
//           className="flex w-[200px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
//         />
//         <div className="mt-4 flex-1 sm:mt-0 xs:mt-0">
//           <div className="text-xl text-left font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-sm xs:text-center" 
//               style={styles.title}>{event.eventTitle}</div>
//             <div className="my-2">
//               <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//                 <span className="font-extrabold mr-4">Ticket ID:</span>{event.ticketId}
//               </div>
//               <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//                 <span className="font-extrabold mr-4">Date:</span>{formatDateWithTime(event.appointmentDate, event.time)}
//               </div>
//               <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//                 <span className="font-extrabold mr-4">Location:</span>{event.location}
//                   {event.location === "Noah's Space" && (
//                     <button
//                       type="button"
//                       className="ml-2"
//                       onClick={handleShowLocation}
//                     >
//                       <FaMapMarkerAlt className="ml-1 w-4 h-4 xs:w-3" style={styles.icon} />
//                     </button>
//                   )}
//                     {event.location === "Online Conference" && (
//                       <button
//                         type="button"
//                         className="ml-2"
//                         onClick={() => {
//                             setOnlineConferenceText(event.onlineConferenceText || '');
//                             setOnlineConferenceModalVisible(true);
//                         }}
//                       >
//                         <FaInfoCircle className="ml-1 w-4 h-4 xs:w-3" style={styles.icon} />
//                       </button>
//                     )}
//                   </div>
//                 </div>
                
//                 {/* Noah Space Modal */}
//                 {locationModalVisible && (
//                   <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
//                       <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto lg:text-base md:text-base sm:text-sm xs:text-xs">
//                         <div className="flex justify-between items-center mb-4">
//                           <h2 className="text-xl font-semibold">Location Details</h2>
//                             <button onClick={handleCloseLocationModal} className="text-gray-500 hover:text-gray-800">
//                               <FaTimes className="w-6 h-6" />
//                             </button>
//                           </div>
//                         <div>
//                         <div className="mb-4">
//                           <h3 className="font-semibold">Address:</h3>
//                           <p>Unit 3-2-2, Kompleks Kantonmen Prima, 698, Jln Ipoh, Taman Bamboo, 51200 Kuala Lumpur, Federal Territory of Kuala Lumpur</p>
//                         </div>
//                         <div className="mb-4">
//                           <h3 className="font-semibold">Google Maps:</h3>
//                           <div className="flex items-center">
//                               <a 
//                                   href="https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7" 
//                                   target="_blank" 
//                                   rel="noopener noreferrer" 
//                                   className="text-blue-500 underline"
//                                   style={{ color: theme.primaryColor }}
//                               >
//                                   https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7
//                               </a>
//                           </div>
//                         </div>
//                       <div>
//                         <img src={officeImg} alt="Noah's Space location" className="w-full h-auto" />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 )}
  
//                 {/* Online Link Modal */}
//                 {onlineConferenceModalVisible && (
//                   <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
//                     <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto">
//                       <div className="flex justify-between items-center mb-4">
//                         <h2 className="text-xl font-semibold">Online Conference Details</h2>
//                         <button onClick={() => setOnlineConferenceModalVisible(false)} className="text-gray-500 hover:text-gray-800">
//                           <FaTimes className="w-6 h-6" />
//                         </button>
//                       </div>
//                       <div>
//                         <div className="mb-4">
//                           <h3 className="font-semibold">Details:</h3>
//                           <p dangerouslySetInnerHTML={{ __html: onlineConferenceText }}></p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               <div className="text-sm text-zinc-600 flex items-center mt-2 md:text-xs sm:text-xs xs:text-xs">
//                 <span className="font-extrabold mr-2">Event Status:</span>
//                 <span className={getEventStatusStyle(event.eventStatus)}>{event.eventStatus}</span>
//               </div>
//             </div>
//             <div className="flex items-center">
//               <button
//                 className={`px-4 py-2 mt-4 mr-4 rounded-full self-center flex items-center justify-around md:text-sm sm:text-xs xs:text-xs ${
//                   (isExpired && !isEventToday) || isAttended || event.eventStatus === 'attend' 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed'
//                     : disabledTickets.includes(event.ticketId) 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                     : 'bg-blue-500 text-white'
//                   }`}
//                 onClick={() => isEventToday && event.eventStatus !== 'attend' && !isAttended && handleAttendClick(event.ticketId, event.eventTitle)}
//                 title={(isExpired && !isEventToday) || isAttended || event.eventStatus === 'attend' ? 'Expired' : 'Attend'}
//                 disabled={
//                   ((isExpired && !isEventToday) ||
//                   isAttended ||
//                   event.eventStatus === 'attend' ||
//                   disabledTickets.includes(event.ticketId))
//                 }
//                 style={{ 
//                   backgroundColor: 
//                     ((isExpired && !isEventToday) || isAttended || event.eventStatus === 'attend')
//                       ? 'gray'
//                       : disabledTickets.includes(event.ticketId)
//                       ? 'gray'
//                       : theme.primaryColor, 
//                   fontFamily: theme.fontFamily 
//                 }}
//               >
//                 {(isExpired && !isEventToday) 
//                   ? 'Expired'
//                   : isAttended || event.eventStatus === 'attend' 
//                   ? 'Attended' 
//                   : isEventToday ? 'Check-In' : `Event in ${daysUntilEvent} day(s)`}
//               </button>
//             </div>
//           </div>
//         );
//       };

//   return (
//     <div className="h-auto w-auto px-4 py-2">
//       <MyTicketAlert 
//         {...alert}
//         onConfirm={handleConfirm}
//         onCancel={() => setAlert((prevState) => ({ ...prevState, visible: false }))}
//       />
//       {!selectedEvent && (
//         <div className="flex flex-row justify-between items-center p-6 border-2 rounded-xl sm:flex-col sm:p-4 xs:flex-col xs:p-3 xs:rounded-lg">
//           <div className="flex items-center">
//             <img src={r1Logo} alt="Logo" className="w-20 h-18 mr-4 md:w-14 md:h-14 md:mr-2 sm:w-[65px] xs:w-[55px]" />
//             <div className="mx-4 mt-5">
//               <div className="text-xl font-bold md:text-base sm:text-sm xs:text-sm" style={styles.title}>
//                   Classes and Events
//               </div>
//             </div>
//           </div>
//           <div className="flex items-center p-2 rounded-lg mt-2">
//             <div className="relative">
//               <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
//               <input
//                 type="text"
//                 placeholder="Search events..."
//                 className="p-2 pl-10 rounded border-2 w-auto md:w-[220px] sm:w-full sm:h-[35px] sm:text-sm xs:h-[30px] xs:text-xs"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//             </div>
//             <button onClick={handleRefreshClick} className="ml-4" title="Refresh">
//               <FaSyncAlt style={styles.title}/>
//             </button>
//           </div>
//         </div>
//       )}

//       {!selectedEvent ? (
//         <div className="mt-4 px-2 rounded-lg overflow-hidden">
//           <div className="flex justify-around font-semibold sm:text-sm xs:text-xs"
//               style={styles.title}>
//             <button
//               className={`${activeTab === 'Event List' ? 'border-b-4 border-cyan-700' : ''}`}
//               onClick={() => setActiveTab('Event List')}
//               style={styles.title}>
//               Event List
//             </button>
//             <button
//               className={`${activeTab === 'My Ticket' ? 'border-b-4 border-cyan-700' : ''}`}
//               onClick={() => setActiveTab('My Ticket')}
//               style={styles.title}>
//               My Ticket
//             </button>
//           </div>
//           <div className="mt-4 space-y-4 overflow-y-auto h-[750px] md:h-[500px] sm:h-[400px] xs:h-[250px]">
//             {activeTab === 'Event List'
//               ? filteredEvents.length > 0 
//                 ? (filteredEvents as Event[]).map(renderEventListCard)
//                 : (
//                   <div className="flex flex-col items-center justify-center h-full border-4 border-dashed border-gray-200 p-4 rounded-lg">
//                     <div className="flex justify-center items-center">
//                       <p className="text-lg font-semibold mb-2 sm:text-base xs:text-sm">No events available</p>
//                       <FaSearch className="w-6 h-6 ml-2 mb-2 sm:w-4 xs:w-[15px]"/>
//                     </div>
//                     <p className="text-gray-400 mb-4 sm:text-center sm:text-sm xs:text-center xs:text-xs">Please check back later for more events.</p>
//                   </div>
//                 )
//               : (filteredEvents as MyTicketEvent[]).length > 0 
//                 ? (filteredEvents as MyTicketEvent[]).map(renderMyTicketCard)
//                 : (
//                   <div className="flex flex-col items-center justify-center h-full border-4 border-dashed border-gray-200 p-4 rounded-lg">
//                     <div className="flex justify-center items-center">
//                       <p className="text-lg font-semibold mb-2 sm:text-base xs:text-sm">You have no tickets yet</p>
//                       <IoTicketSharp className="w-6 h-6 ml-2 mb-2 sm:w-4 xs:w-[15px]"/>
//                     </div>
//                     <p className="text-gray-400 mb-4 sm:text-center sm:text-sm xs:text-center xs:text-xs">Browse our events and buy tickets to join.</p>
//                     <button
//                       onClick={() => setActiveTab('Event List')}
//                       className="px-4 py-2 rounded-lg sm:text-sm xs:text-sm"
//                       style={styles.button}>
//                         Go to Event List
//                     </button>
//                   </div>
//                 )}
//           </div>
//         </div>
//       ) : (
//         <EventDetail event={selectedEvent} onBack={() => setSelectedEvent(null)} />
//       )}
//     </div>
//   );
// };

// export default MyTicket;

// // udpate code  --> 04 aug 2024
// // src/components/client/MiniApp/App01_MyTicket.tsx

// import React, { useState, useEffect } from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import { useEnv } from '../../../context/EnvContext';

// import { FaSearch, FaInfoCircle, FaSyncAlt, FaTag, FaMapMarkerAlt, FaTimes } from 'react-icons/fa';
// import { IoTicketSharp } from "react-icons/io5";

// import MyTicketAlert from './App_AlertMessage';
// import EventDetail from '../MiniApp/App01_MyTicketEventDetail';

// // import hook file
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useAttendEvent from '../../../hooks/MiniApp/useEventAttend';
// import useEventData, { 
//   Event, MyTicketEvent, getEventImagePath, getEventStatusStyle,
//   convertToShortForm, getTagColor, formatDateWithTime, isToday, 
//   formatAmount
// } from '../../../hooks/MiniApp/useEventData';

// // import assets
// import r1Logo from '../../../assets/login/logo_r1_ori_v02.png';
// import officeImg from '../../../assets/miniApp/NoahSpace_img.png';

// const MyTicket: React.FC = () => {
//   const { apiUrl } = useEnv();
//   const { theme } = useTheme();
//   const { getSlotMessage, fetchApprovedEvents, events, myTickets, fetchEvents } = useEventData();
//   const { attendEvent } = useAttendEvent();
//   const { user } = useUserDashboard();
//   const [activeTab, setActiveTab] = useState<'Event List' | 'My Ticket'>('Event List');
//   const [selectedEvent, setSelectedEvent] = useState<Event | MyTicketEvent | null>(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [locationModalVisible, setLocationModalVisible] = useState(false);
//   const [onlineConferenceModalVisible, setOnlineConferenceModalVisible] = useState<boolean>(false);
//   const [onlineConferenceText, setOnlineConferenceText] = useState<string>('');
//   const [confirmAction, setConfirmAction] = useState<{ type: 'attend', ticketId: string, eventTitle: string } | null>(null);
//   const [attendedTickets, setAttendedTickets] = useState<string[]>([]);
//   const [disabledTickets, setDisabledTickets] = useState<string[]>([]);
//   const [alert, setAlert] = useState<{ type: 'error' | 'success' | 'confirm' | 'warning' | 'check'; message: string; visible: boolean; onClose: () => void }>({
//     type: 'error',
//     message: '',
//     visible: false,
//     onClose: () => {}
//   });

//   useEffect(() => {
//     fetchEvents();
//   }, [fetchEvents]);

//   useEffect(() => {
//     if (user && user.email) {
//       fetchApprovedEvents(user.email);
//     }
//   }, [user, fetchApprovedEvents]);

//   useEffect(() => {
//   }, [myTickets]);

//   const handleDetailClick = (event: Event) => {
//     if (event.slot === 0) {
//       setAlert({
//         type: 'error',
//         message: 'Ticket slot already filled and sold out.',
//         visible: true,
//         onClose: () => setAlert((prevState) => ({ ...prevState, visible: false }))
//       });
//       return;
//     }
//     setSelectedEvent(event);
//   };

//   const handleRefreshClick = () => {
//     fetchEvents();
//     if (user && user.email) {
//       fetchApprovedEvents(user.email);
//     }
//   };

//   const handleAttendClick = (ticketId: string, eventTitle: string) => {
//     setDisabledTickets((prev) => [...prev, ticketId]);
//     setConfirmAction({ type: 'attend', ticketId, eventTitle });
//     setAlert({
//       type: 'check',
//       message: 'Are you sure you want to mark this event as attended?',
//       visible: true,
//       onClose: () => setAlert((prevState) => ({ ...prevState, visible: false }))
//     });
//   }; 

//   const handleConfirm = async () => {
//     if (confirmAction) {
//       await attendEvent(confirmAction.ticketId, confirmAction.eventTitle);
//       setAttendedTickets((prev) => [...prev, confirmAction.ticketId]); // Update attended tickets
//       if (user && user.email) {
//         fetchApprovedEvents(user.email);
//       }
//     }
//     setAlert((prevState) => ({ ...prevState, visible: false }));
//     setConfirmAction(null);
//   };

//   const handleShowLocation = () => setLocationModalVisible(true);
//   const handleCloseLocationModal = () => setLocationModalVisible(false);

//   const filteredEvents = activeTab === 'Event List' 
//     ? events.filter(event => event.name.toLowerCase().includes(searchTerm.toLowerCase()))
//     : myTickets.filter(event => event.eventTitle.toLowerCase().includes(searchTerm.toLowerCase()));

//   const getDaysUntilEvent = (eventDate: string): number => {
//     const today = new Date();
//     const event = new Date(eventDate);
//     const diffTime = event.getTime() - today.getTime();
//     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//     return diffDays;
//   };   

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   // Event List Card
//   const renderEventListCard = (event: Event) => (
//     <div key={event.id} className="flex flex-row items-center rounded-lg p-4 relative bg-gray-100 sm:flex-col xs:flex-col">
//       <img
//         src={getEventImagePath(event.image, apiUrl)}
//         alt={event.eventTitle}
//         className="flex w-[200px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
//       />
//       <div className="mt-4 flex-1 sm:mt-0 xs:mt-0">
//         <div className="text-xl text-left font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-sm xs:text-center" 
//           style={styles.title}>
//             {event.name}
//         </div>
//         <div className="flex flex-wrap">
//         {event.MemberType.map((memberType, index) => {
//           const shortForm = convertToShortForm(memberType);
//           const tagColor = getTagColor(shortForm);
//           return (
//             <div 
//               key={index} 
//               className={`${tagColor} py-1 px-2 rounded-lg text-xs flex items-center space-x-1 sm:text-[10px] xs:text-[10px]`} 
//               title={memberType}>
//               <FaTag />
//               <span>{shortForm}</span>
//             </div>
//           );
//         })}
//       </div>
//         <div className="my-2">
//           <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//             <strong>Date:</strong> {formatDateWithTime(event.startDate, event.time)}
//           </div>
//           <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//             <strong>Venue:</strong> {event.location || 'N/A'}
//           </div>
//           <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//             <strong>Amount:</strong> {event.amount === 0 ? 'Free of Charges' : `${event.currency} ${formatAmount(event.amount)}`}
//           </div>
//         </div>
//         <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//           <span className="mr-2"><strong>Slots:</strong></span>{getSlotMessage(event.slot)}
//         </div>
//       </div>
//       <button
//         className="px-2 mt-4 rounded-full self-center flex items-center justify-around md:text-sm sm:text-xs xs:text-xs"
//         style={styles.icon}
//         onClick={() => handleDetailClick(event)}
//         title="View Details"
//       >
//         <FaInfoCircle className="w-5 h-5 md:w-4 sm:w-3 xs:w-3" />
//         <div className='ml-2' style={styles.title}>
//           More Details
//         </div>
//       </button>
//     </div>
//   );
  
//   // My Ticket List Card
//   const renderMyTicketCard = (event: MyTicketEvent) => {
//     const daysUntilEvent = getDaysUntilEvent(event.appointmentDate);
//     const isEventToday = isToday(event.appointmentDate);
//     const isAttended = attendedTickets.includes(event.ticketId);
//     const isExpired = new Date(event.appointmentDate) < new Date(); 
  
//     return (
//       <div key={event.ticketId} className="flex flex-row items-center rounded-lg p-4 relative bg-gray-100 sm:flex-col xs:flex-col">
//         {/* {isExpired && !isEventToday && (
//           <div className="absolute inset-0 bg-gray-300 opacity-95 rounded-lg flex items-center justify-center">
//             <span className="text-white bg-rose-700 py-2 px-4 rounded-full">{event.eventTitle} expired on {formatDateWithTime(event.appointmentDate, event.time)}</span>
//           </div>
//         )} */}
//         <img
//           src={getEventImagePath(event.image, apiUrl)}
//           alt={event.eventTitle}
//           className="flex w-[200px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
//         />
//         <div className="mt-4 flex-1 sm:mt-0 xs:mt-0">
//           <div className="text-xl text-left font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-sm xs:text-center" 
//               style={styles.title}>{event.eventTitle}</div>
//             <div className="my-2">
//               <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//                 <span className="font-extrabold mr-4">Ticket ID:</span>{event.ticketId}
//               </div>
//               <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//                 <span className="font-extrabold mr-4">Date:</span>{formatDateWithTime(event.appointmentDate, event.time)}
//               </div>
//               <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//                 <span className="font-extrabold mr-4">Location:</span>{event.location}
//                   {event.location === "Noah's Space" && (
//                     <button
//                       type="button"
//                       className="ml-2"
//                       onClick={handleShowLocation}
//                     >
//                       <FaMapMarkerAlt className="ml-1 w-4 h-4 xs:w-3" style={styles.icon} />
//                     </button>
//                   )}
//                     {event.location === "Online Conference" && (
//                       <button
//                         type="button"
//                         className="ml-2"
//                         onClick={() => {
//                             setOnlineConferenceText(event.onlineConferenceText || '');
//                             setOnlineConferenceModalVisible(true);
//                         }}
//                       >
//                         <FaInfoCircle className="ml-1 w-4 h-4 xs:w-3" style={styles.icon} />
//                       </button>
//                     )}
//                   </div>
//                 </div>
                
//                 {/* Noah Space Modal */}
//                 {locationModalVisible && (
//                   <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
//                       <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto lg:text-base md:text-base sm:text-sm xs:text-xs">
//                         <div className="flex justify-between items-center mb-4">
//                           <h2 className="text-xl font-semibold">Location Details</h2>
//                             <button onClick={handleCloseLocationModal} className="text-gray-500 hover:text-gray-800">
//                               <FaTimes className="w-6 h-6" />
//                             </button>
//                           </div>
//                         <div>
//                         <div className="mb-4">
//                           <h3 className="font-semibold">Address:</h3>
//                           <p>Unit 3-2-2, Kompleks Kantonmen Prima, 698, Jln Ipoh, Taman Bamboo, 51200 Kuala Lumpur, Federal Territory of Kuala Lumpur</p>
//                         </div>
//                         <div className="mb-4">
//                           <h3 className="font-semibold">Google Maps:</h3>
//                           <div className="flex items-center">
//                               <a 
//                                   href="https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7" 
//                                   target="_blank" 
//                                   rel="noopener noreferrer" 
//                                   className="text-blue-500 underline"
//                                   style={{ color: theme.primaryColor }}
//                               >
//                                   https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7
//                               </a>
//                           </div>
//                         </div>
//                       <div>
//                         <img src={officeImg} alt="Noah's Space location" className="w-full h-auto" />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 )}
  
//                 {/* Online Link Modal */}
//                 {onlineConferenceModalVisible && (
//                   <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
//                     <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto">
//                       <div className="flex justify-between items-center mb-4">
//                         <h2 className="text-xl font-semibold">Online Conference Details</h2>
//                         <button onClick={() => setOnlineConferenceModalVisible(false)} className="text-gray-500 hover:text-gray-800">
//                           <FaTimes className="w-6 h-6" />
//                         </button>
//                       </div>
//                       <div>
//                         <div className="mb-4">
//                           <h3 className="font-semibold">Details:</h3>
//                           <p dangerouslySetInnerHTML={{ __html: onlineConferenceText }}></p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               <div className="text-sm text-zinc-600 flex items-center mt-2 md:text-xs sm:text-xs xs:text-xs">
//                 <span className="font-extrabold mr-2">Event Status:</span>
//                 <span className={getEventStatusStyle(event.eventStatus)}>{event.eventStatus}</span>
//               </div>
//             </div>
//             <div className="flex items-center">
//               <button
//                 className={`px-4 py-2 mt-4 mr-4 rounded-full self-center flex items-center justify-around md:text-sm sm:text-xs xs:text-xs ${
//                   (isExpired && !isEventToday) || isAttended || event.eventStatus === 'attend' 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed'
//                     : disabledTickets.includes(event.ticketId) 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                     : 'bg-blue-500 text-white'
//                   }`}
//                 onClick={() => isEventToday && event.eventStatus !== 'attend' && !isAttended && handleAttendClick(event.ticketId, event.eventTitle)}
//                 title={(isExpired && !isEventToday) || isAttended || event.eventStatus === 'attend' ? 'Expired' : 'Attend'}
//                 disabled={
//                   ((isExpired && !isEventToday) ||
//                   isAttended ||
//                   event.eventStatus === 'attend' ||
//                   disabledTickets.includes(event.ticketId))
//                 }
//                 style={{ 
//                   backgroundColor: 
//                     ((isExpired && !isEventToday) || isAttended || event.eventStatus === 'attend')
//                       ? 'gray'
//                       : disabledTickets.includes(event.ticketId)
//                       ? 'gray'
//                       : theme.primaryColor, 
//                   fontFamily: theme.fontFamily 
//                 }}
//               >
//                 {(isExpired && !isEventToday) 
//                   ? 'Expired'
//                   : isAttended || event.eventStatus === 'attend' 
//                   ? 'Attended' 
//                   : isEventToday ? 'Check-In' : `Event in ${daysUntilEvent} day(s)`}
//               </button>
//             </div>
//           </div>
//         );
//       };

//   return (
//     <div className="h-auto w-auto px-4 py-2">
//       <MyTicketAlert 
//         {...alert}
//         onConfirm={handleConfirm}
//         onCancel={() => setAlert((prevState) => ({ ...prevState, visible: false }))}
//       />
//       {!selectedEvent && (
//         <div className="flex flex-row justify-between items-center p-6 border-2 rounded-xl sm:flex-col sm:p-4 xs:flex-col xs:p-3 xs:rounded-lg">
//           <div className="flex items-center">
//             <img src={r1Logo} alt="Logo" className="w-20 h-18 mr-4 md:w-14 md:h-14 md:mr-2 sm:w-[65px] xs:w-[55px]" />
//             <div className="mx-4 mt-5">
//               <div className="text-xl font-bold md:text-base sm:text-sm xs:text-sm" style={styles.title}>
//                   Classes and Events
//               </div>
//             </div>
//           </div>
//           <div className="flex items-center p-2 rounded-lg mt-2">
//             <div className="relative">
//               <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
//               <input
//                 type="text"
//                 placeholder="Search events..."
//                 className="p-2 pl-10 rounded border-2 w-auto md:w-[220px] sm:w-full sm:h-[35px] sm:text-sm xs:h-[30px] xs:text-xs"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//             </div>
//             <button onClick={handleRefreshClick} className="ml-4" title="Refresh">
//               <FaSyncAlt style={styles.title}/>
//             </button>
//           </div>
//         </div>
//       )}

//       {!selectedEvent ? (
//         <div className="mt-4 px-2 rounded-lg overflow-hidden">
//           <div className="flex justify-around font-semibold sm:text-sm xs:text-xs"
//               style={styles.title}>
//             <button
//               className={`${activeTab === 'Event List' ? 'border-b-4 border-cyan-700' : ''}`}
//               onClick={() => setActiveTab('Event List')}
//               style={styles.title}>
//               Event List
//             </button>
//             <button
//               className={`${activeTab === 'My Ticket' ? 'border-b-4 border-cyan-700' : ''}`}
//               onClick={() => setActiveTab('My Ticket')}
//               style={styles.title}>
//               My Ticket
//             </button>
//           </div>
//           <div className="mt-4 space-y-4 overflow-y-auto h-[750px] md:h-[500px] sm:h-[400px] xs:h-[250px]">
//             {activeTab === 'Event List'
//               ? filteredEvents.length > 0 
//                 ? (filteredEvents as Event[]).map(renderEventListCard)
//                 : (
//                   <div className="flex flex-col items-center justify-center h-full border-4 border-dashed border-gray-200 p-4 rounded-lg">
//                     <div className="flex justify-center items-center">
//                       <p className="text-lg font-semibold mb-2 sm:text-base xs:text-sm">No events available</p>
//                       <FaSearch className="w-6 h-6 ml-2 mb-2 sm:w-4 xs:w-[15px]"/>
//                     </div>
//                     <p className="text-gray-400 mb-4 sm:text-center sm:text-sm xs:text-center xs:text-xs">Please check back later for more events.</p>
//                   </div>
//                 )
//               : (filteredEvents as MyTicketEvent[]).length > 0 
//                 ? (filteredEvents as MyTicketEvent[]).map(renderMyTicketCard)
//                 : (
//                   <div className="flex flex-col items-center justify-center h-full border-4 border-dashed border-gray-200 p-4 rounded-lg">
//                     <div className="flex justify-center items-center">
//                       <p className="text-lg font-semibold mb-2 sm:text-base xs:text-sm">You have no tickets yet</p>
//                       <IoTicketSharp className="w-6 h-6 ml-2 mb-2 sm:w-4 xs:w-[15px]"/>
//                     </div>
//                     <p className="text-gray-400 mb-4 sm:text-center sm:text-sm xs:text-center xs:text-xs">Browse our events and buy tickets to join.</p>
//                     <button
//                       onClick={() => setActiveTab('Event List')}
//                       className="px-4 py-2 rounded-lg sm:text-sm xs:text-sm"
//                       style={styles.button}>
//                         Go to Event List
//                     </button>
//                   </div>
//                 )}
//           </div>
//         </div>
//       ) : (
//         <EventDetail event={selectedEvent} onBack={() => setSelectedEvent(null)} />
//       )}
//     </div>
//   );
// };

// export default MyTicket;

// workign code --> 21 july 2024
// // src/components/client/MiniApp/App01_MyTicket.tsx

// import React, { useState, useEffect } from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import { useEnv } from '../../../context/EnvContext';

// import { FaSearch, FaInfoCircle, FaSyncAlt, FaTag, FaMapMarkerAlt, FaTimes } from 'react-icons/fa';
// import { IoTicketSharp } from "react-icons/io5";

// import MyTicketAlert from './App_AlertMessage';
// import EventDetail from '../MiniApp/App01_MyTicketEventDetail';

// // import hook file
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useAttendEvent from '../../../hooks/MiniApp/useEventAttend';
// import useEventData, { 
//   Event, MyTicketEvent, getEventImagePath, getEventStatusStyle,
//   convertToShortForm, getTagColor, formatDateWithTime, isToday, 
//   formatAmount
// } from '../../../hooks/MiniApp/useEventData';

// // import assets
// import r1Logo from '../../../assets/login/logo_r1_ori_v02.png';
// import officeImg from '../../../assets/miniApp/NoahSpace_img.png';

// const MyTicket: React.FC = () => {
//   const { apiUrl } = useEnv();
//   const { theme } = useTheme();
//   const { getSlotMessage, fetchApprovedEvents, events, myTickets, fetchEvents, attendedTickets } = useEventData();
//   const { attendEvent } = useAttendEvent();
//   const { user } = useUserDashboard();
//   const [activeTab, setActiveTab] = useState<'Event List' | 'My Ticket'>('Event List');
//   const [selectedEvent, setSelectedEvent] = useState<Event | MyTicketEvent | null>(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [locationModalVisible, setLocationModalVisible] = useState(false);
//   const [onlineConferenceModalVisible, setOnlineConferenceModalVisible] = useState<boolean>(false);
//   const [onlineConferenceText, setOnlineConferenceText] = useState<string>('');
//   const [confirmAction, setConfirmAction] = useState<{ type: 'attend', ticketId: string, eventTitle: string } | null>(null);
//   const [disabledTickets, setDisabledTickets] = useState<string[]>([]);
//   const [alert, setAlert] = useState<{ type: 'error' | 'success' | 'confirm' | 'warning' | 'check'; message: string; visible: boolean; onClose: () => void }>({
//     type: 'error',
//     message: '',
//     visible: false,
//     onClose: () => {}
//   });

//   useEffect(() => {
//     fetchEvents();
//   }, [fetchEvents]);

//   useEffect(() => {
//     if (user && user.email) {
//       fetchApprovedEvents(user.email);
//     }
//   }, [user, fetchApprovedEvents]);

//   useEffect(() => {
//   }, [myTickets]);

//   const handleDetailClick = (event: Event) => {
//     if (event.slot === 0) {
//       setAlert({
//         type: 'error',
//         message: 'Ticket slot already filled and sold out.',
//         visible: true,
//         onClose: () => setAlert((prevState) => ({ ...prevState, visible: false }))
//       });
//       return;
//     }
//     setSelectedEvent(event);
//   };

//   const handleRefreshClick = () => {
//     fetchEvents();
//     if (user && user.email) {
//       fetchApprovedEvents(user.email);
//     }
//   };

//   const handleAttendClick = (ticketId: string, eventTitle: string) => {
//     setDisabledTickets((prev) => [...prev, ticketId]);
//     setConfirmAction({ type: 'attend', ticketId, eventTitle });
//     setAlert({
//       type: 'check',
//       message: 'Are you sure you want to mark this event as attended?',
//       visible: true,
//       onClose: () => setAlert((prevState) => ({ ...prevState, visible: false }))
//     });
//   }; 

//   const handleConfirm = async () => {
//     if (confirmAction) {
//       await attendEvent(confirmAction.ticketId, confirmAction.eventTitle);
//       if (user && user.email) {
//         fetchApprovedEvents(user.email);
//       }
//     }
//     setAlert((prevState) => ({ ...prevState, visible: false }));
//     setConfirmAction(null);
//   };

//   const handleShowLocation = () => setLocationModalVisible(true);
//   const handleCloseLocationModal = () => setLocationModalVisible(false);

//   const filteredEvents = activeTab === 'Event List' 
//     ? events.filter(event => event.name.toLowerCase().includes(searchTerm.toLowerCase()))
//     : myTickets.filter(event => event.eventTitle.toLowerCase().includes(searchTerm.toLowerCase()));

//   const getDaysUntilEvent = (eventDate: string): number => {
//     const today = new Date();
//     const event = new Date(eventDate);
//     const diffTime = event.getTime() - today.getTime();
//     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//     return diffDays;
//   };   

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   // Event List Card
//   const renderEventListCard = (event: Event) => (
//     <div key={event.id} className="flex flex-row items-center rounded-lg p-4 relative bg-gray-100 sm:flex-col xs:flex-col">
//       <img
//         src={getEventImagePath(event.image, apiUrl)}
//         alt={event.eventTitle}
//         className="flex w-[200px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
//       />
//       <div className="mt-4 flex-1 sm:mt-0 xs:mt-0">
//         <div className="text-xl text-left font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-sm xs:text-center" 
//           style={styles.title}>
//             {event.name}
//         </div>
//         <div className="flex flex-wrap">
//         {event.MemberType.map((memberType, index) => {
//           const shortForm = convertToShortForm(memberType);
//           const tagColor = getTagColor(shortForm);
//           return (
//             <div 
//               key={index} 
//               className={`${tagColor} py-1 px-2 rounded-lg text-xs flex items-center space-x-1 sm:text-[10px] xs:text-[10px]`} 
//               title={memberType}>
//               <FaTag />
//               <span>{shortForm}</span>
//             </div>
//           );
//         })}
//       </div>
//         <div className="my-2">
//           <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//             <strong>Date:</strong> {formatDateWithTime(event.startDate, event.time)}
//           </div>
//           <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//             <strong>Venue:</strong> {event.location || 'N/A'}
//           </div>
//           <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//             <strong>Amount:</strong> {event.amount === 0 ? 'Free of Charges' : `${event.currency} ${formatAmount(event.amount)}`}
//           </div>
//         </div>
//         <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//           <span className="mr-2"><strong>Slots:</strong></span>{getSlotMessage(event.slot)}
//         </div>
//       </div>
//       <button
//         className="px-2 mt-4 rounded-full self-center flex items-center justify-around md:text-sm sm:text-xs xs:text-xs"
//         style={styles.icon}
//         onClick={() => handleDetailClick(event)}
//         title="View Details"
//       >
//         <FaInfoCircle className="w-5 h-5 md:w-4 sm:w-3 xs:w-3" />
//         <div className='ml-2' style={styles.title}>
//           More Details
//         </div>
//       </button>
//     </div>
//   );
  
//   // My Ticket List Card
//   const renderMyTicketCard = (event: MyTicketEvent) => {
//     const daysUntilEvent = getDaysUntilEvent(event.appointmentDate);
//     const isEventToday = isToday(event.appointmentDate);
//     const isAttended = attendedTickets.includes(event.ticketId);
//     const isExpired = new Date(event.appointmentDate) < new Date(); 
  
//     return (
//       <div key={event.ticketId} className="flex flex-row items-center rounded-lg p-4 relative bg-gray-100 sm:flex-col xs:flex-col">
//         {isExpired && !isEventToday && (
//           <div className="absolute inset-0 bg-gray-700 opacity-75 rounded-lg flex items-center justify-center">
//             <span className="text-white font-semibold bg-red-800 py-2 px-4 rounded-full">Event expired on {formatDateWithTime(event.appointmentDate, event.time)}</span>
//           </div>
//         )}
//         <img
//           src={getEventImagePath(event.image, apiUrl)}
//           alt={event.eventTitle}
//           className="flex w-[200px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
//         />
//         <div className="mt-4 flex-1 sm:mt-0 xs:mt-0">
//           <div className="text-xl text-left font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-sm xs:text-center" 
//               style={styles.title}>{event.eventTitle}</div>
//             <div className="my-2">
//               <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//                 <span className="font-extrabold mr-4">Ticket ID:</span>{event.ticketId}
//               </div>
//               <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//                 <span className="font-extrabold mr-4">Date:</span>{formatDateWithTime(event.appointmentDate, event.time)}
//               </div>
//               <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//                 <span className="font-extrabold mr-4">Location:</span>{event.location}
//                   {event.location === "Noah's Space" && (
//                     <button
//                       type="button"
//                       className="ml-2"
//                       onClick={handleShowLocation}
//                     >
//                       <FaMapMarkerAlt className="ml-1 w-4 h-4 xs:w-3" style={styles.icon} />
//                     </button>
//                   )}
//                     {event.location === "Online Conference" && (
//                       <button
//                         type="button"
//                         className="ml-2"
//                         onClick={() => {
//                             setOnlineConferenceText(event.onlineConferenceText || '');
//                             setOnlineConferenceModalVisible(true);
//                         }}
//                       >
//                         <FaInfoCircle className="ml-1 w-4 h-4 xs:w-3" style={styles.icon} />
//                       </button>
//                     )}
//                   </div>
//                 </div>
                
//                 {/* Noah Space Modal */}
//                 {locationModalVisible && (
//                   <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
//                       <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto">
//                         <div className="flex justify-between items-center mb-4">
//                           <h2 className="text-xl font-semibold">Location Details</h2>
//                             <button onClick={handleCloseLocationModal} className="text-gray-500 hover:text-gray-800">
//                               <FaTimes className="w-6 h-6" />
//                             </button>
//                           </div>
//                         <div>
//                         <div className="mb-4">
//                           <h3 className="font-semibold">Address:</h3>
//                           <p>Unit 3-2-2, Kompleks Kantonmen Prima, 698, Jln Ipoh, Taman Bamboo, 51200 Kuala Lumpur, Federal Territory of Kuala Lumpur</p>
//                         </div>
//                         <div className="mb-4">
//                           <h3 className="font-semibold">Google Maps:</h3>
//                           <div className="flex items-center">
//                               <a 
//                                   href="https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7" 
//                                   target="_blank" 
//                                   rel="noopener noreferrer" 
//                                   className="text-blue-500 underline"
//                                   style={{ color: theme.primaryColor }}
//                               >
//                                   https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7
//                               </a>
//                           </div>
//                         </div>
//                       <div>
//                         <img src={officeImg} alt="Noah's Space location" className="w-full h-auto" />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 )}
  
//                 {/* Online Link Modal */}
//                 {onlineConferenceModalVisible && (
//                   <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
//                     <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto">
//                       <div className="flex justify-between items-center mb-4">
//                         <h2 className="text-xl font-semibold">Online Conference Details</h2>
//                         <button onClick={() => setOnlineConferenceModalVisible(false)} className="text-gray-500 hover:text-gray-800">
//                           <FaTimes className="w-6 h-6" />
//                         </button>
//                       </div>
//                       <div>
//                         <div className="mb-4">
//                           <h3 className="font-semibold">Details:</h3>
//                           <p dangerouslySetInnerHTML={{ __html: onlineConferenceText }}></p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               <div className="text-sm text-zinc-600 flex items-center mt-2 md:text-xs sm:text-xs xs:text-xs">
//                 <span className="font-extrabold mr-2">Event Status:</span>
//                 <span className={getEventStatusStyle(event.eventStatus)}>{event.eventStatus}</span>
//               </div>
//             </div>
//             <div className="flex items-center">
//               <button
//                 className={`px-4 py-2 mt-4 mr-4 rounded-full self-center flex items-center justify-around md:text-sm sm:text-xs xs:text-xs ${
//                   (isExpired && !isEventToday) || isAttended || event.eventStatus === 'attend' 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed'
//                     : disabledTickets.includes(event.ticketId) 
//                     ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                     : 'bg-blue-500 text-white'
//                   }`}
//                 onClick={() => isEventToday && event.eventStatus !== 'attend' && handleAttendClick(event.ticketId, event.eventTitle)}
//                 title={(isExpired && !isEventToday) || isAttended || event.eventStatus === 'attend' ? 'Expired' : 'Attend'}
//                 disabled={
//                   ((isExpired && !isEventToday) ||
//                   isAttended ||
//                   event.eventStatus === 'attend' ||
//                   disabledTickets.includes(event.ticketId))
//                 }
//                 style={{ 
//                   backgroundColor: 
//                     ((isExpired && !isEventToday) || isAttended || event.eventStatus === 'attend')
//                       ? 'gray'
//                       : disabledTickets.includes(event.ticketId)
//                       ? 'gray'
//                       : theme.primaryColor, 
//                   fontFamily: theme.fontFamily 
//                 }}
//               >
//                 {(isExpired && !isEventToday) 
//                   ? 'Expired'
//                   : isAttended || event.eventStatus === 'attend' 
//                   ? 'Attended' 
//                   : isEventToday ? 'Check-In' : `Event in ${daysUntilEvent} day(s)`}
//               </button>
//             </div>
//           </div>
//         );
//       };
  
  

//   return (
//     <div className="h-auto w-auto px-4 py-2">
//       <MyTicketAlert 
//         {...alert}
//         onConfirm={handleConfirm}
//         onCancel={() => setAlert((prevState) => ({ ...prevState, visible: false }))}
//       />
//       {!selectedEvent && (
//         <div className="flex flex-row justify-between items-center p-6 border-2 rounded-xl sm:flex-col sm:p-4 xs:flex-col xs:p-3 xs:rounded-lg">
//           <div className="flex items-center">
//             <img src={r1Logo} alt="Logo" className="w-20 h-18 mr-4 md:w-14 md:h-14 md:mr-2 sm:w-[65px] xs:w-[55px]" />
//             <div className="mx-4 mt-5">
//               <div className="text-xl font-bold md:text-base sm:text-sm xs:text-sm" style={styles.title}>
//                   Classes and Events
//               </div>
//             </div>
//           </div>
//           <div className="flex items-center p-2 rounded-lg mt-2">
//             <div className="relative">
//               <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
//               <input
//                 type="text"
//                 placeholder="Search events..."
//                 className="p-2 pl-10 rounded border-2 w-auto md:w-[220px] sm:w-full sm:h-[35px] sm:text-sm xs:h-[30px] xs:text-xs"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//             </div>
//             <button onClick={handleRefreshClick} className="ml-4" title="Refresh">
//               <FaSyncAlt style={styles.title}/>
//             </button>
//           </div>
//         </div>
//       )}

//       {!selectedEvent ? (
//         <div className="mt-4 px-2 rounded-lg overflow-hidden">
//           <div className="flex justify-around font-semibold sm:text-sm xs:text-xs"
//               style={styles.title}>
//             <button
//               className={`${activeTab === 'Event List' ? 'border-b-4 border-cyan-700' : ''}`}
//               onClick={() => setActiveTab('Event List')}
//               style={styles.title}>
//               Event List
//             </button>
//             <button
//               className={`${activeTab === 'My Ticket' ? 'border-b-4 border-cyan-700' : ''}`}
//               onClick={() => setActiveTab('My Ticket')}
//               style={styles.title}>
//               My Ticket
//             </button>
//           </div>
//           <div className="mt-4 space-y-4 overflow-y-auto h-[750px] md:h-[500px] sm:h-[400px] xs:h-[300px]">
//             {activeTab === 'Event List'
//               ? filteredEvents.length > 0 
//                 ? (filteredEvents as Event[]).map(renderEventListCard)
//                 : (
//                   <div className="flex flex-col items-center justify-center h-full border-4 border-dashed border-gray-200 p-4 rounded-lg">
//                     <div className="flex justify-center items-center">
//                       <p className="text-lg font-semibold mb-2 sm:text-base xs:text-sm">No events available</p>
//                       <FaSearch className="w-6 h-6 ml-2 mb-2 sm:w-4 xs:w-[15px]"/>
//                     </div>
//                     <p className="text-gray-400 mb-4 sm:text-center sm:text-sm xs:text-center xs:text-xs">Please check back later for more events.</p>
//                   </div>
//                 )
//               : (filteredEvents as MyTicketEvent[]).length > 0 
//                 ? (filteredEvents as MyTicketEvent[]).map(renderMyTicketCard)
//                 : (
//                   <div className="flex flex-col items-center justify-center h-full border-4 border-dashed border-gray-200 p-4 rounded-lg">
//                     <div className="flex justify-center items-center">
//                       <p className="text-lg font-semibold mb-2 sm:text-base xs:text-sm">You have no tickets yet</p>
//                       <IoTicketSharp className="w-6 h-6 ml-2 mb-2 sm:w-4 xs:w-[15px]"/>
//                     </div>
//                     <p className="text-gray-400 mb-4 sm:text-center sm:text-sm xs:text-center xs:text-xs">Browse our events and buy tickets to join.</p>
//                     <button
//                       onClick={() => setActiveTab('Event List')}
//                       className="px-4 py-2 rounded-lg sm:text-sm xs:text-sm"
//                       style={styles.button}>
//                         Go to Event List
//                     </button>
//                   </div>
//                 )}
//           </div>
//         </div>
//       ) : (
//         <EventDetail event={selectedEvent} onBack={() => setSelectedEvent(null)} />
//       )}
//     </div>
//   );
// };

// export default MyTicket;

// // udpate code --> 21 july 2024
// // src/components/client/MiniApp/App01_MyTicket.tsx

// import React, { useState, useEffect } from 'react';
// import { useTheme } from '../../../context/ThemeContext';
// import { useEnv } from '../../../context/EnvContext';

// import { FaSearch, FaInfoCircle, FaSyncAlt, FaTag, FaMapMarkerAlt, FaTimes } from 'react-icons/fa';
// import { IoTicketSharp } from "react-icons/io5";

// import MyTicketAlert from './App_AlertMessage';
// import EventDetail from '../MiniApp/App01_MyTicketEventDetail';

// // import hook file
// import useUserDashboard from '../../../hooks/Dashboard/useUserDashboard';
// import useAttendEvent from '../../../hooks/MiniApp/useEventAttend';
// import useEventData, { 
//   Event, MyTicketEvent, getEventImagePath, getEventStatusStyle,
//   convertToShortForm, getTagColor, formatDateWithTime, isToday, 
//   formatAmount
// } from '../../../hooks/MiniApp/useEventData';

// // import assets
// import r1Logo from '../../../assets/login/logo_r1_ori_v02.png';
// import officeImg from '../../../assets/miniApp/NoahSpace_img.png';

// const MyTicket: React.FC = () => {
//   const { apiUrl } = useEnv();
//   const { theme } = useTheme();
//   const { getSlotMessage, fetchApprovedEvents, events, myTickets, fetchEvents, attendedTickets } = useEventData();
//   const { attendEvent } = useAttendEvent();
//   const { user } = useUserDashboard();
//   const [activeTab, setActiveTab] = useState<'Event List' | 'My Ticket'>('Event List');
//   const [selectedEvent, setSelectedEvent] = useState<Event | MyTicketEvent | null>(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [locationModalVisible, setLocationModalVisible] = useState(false);
//   const [onlineConferenceModalVisible, setOnlineConferenceModalVisible] = useState<boolean>(false);
//   const [onlineConferenceText, setOnlineConferenceText] = useState<string>('');
//   const [confirmAction, setConfirmAction] = useState<{ type: 'attend', ticketId: string, eventTitle: string } | null>(null);
//   const [disabledTickets, setDisabledTickets] = useState<string[]>([]);
//   const [alert, setAlert] = useState<{ type: 'error' | 'success' | 'confirm' | 'warning' | 'check'; message: string; visible: boolean; onClose: () => void }>({
//     type: 'error',
//     message: '',
//     visible: false,
//     onClose: () => {}
//   });

//   useEffect(() => {
//     fetchEvents();
//   }, [fetchEvents]);

//   useEffect(() => {
//     if (user && user.email) {
//       fetchApprovedEvents(user.email);
//     }
//   }, [user, fetchApprovedEvents]);

//   useEffect(() => {
//   }, [myTickets]);

//   const handleDetailClick = (event: Event) => {
//     if (event.slot === 0) {
//       setAlert({
//         type: 'error',
//         message: 'Ticket slot already filled and sold out.',
//         visible: true,
//         onClose: () => setAlert((prevState) => ({ ...prevState, visible: false }))
//       });
//       return;
//     }
//     setSelectedEvent(event);
//   };

//   const handleRefreshClick = () => {
//     fetchEvents();
//     if (user && user.email) {
//       fetchApprovedEvents(user.email);
//     }
//   };

//   const handleAttendClick = (ticketId: string, eventTitle: string, appointmentDate: string) => {
//     if (!isToday(appointmentDate)) {
//         setAlert({
//             type: 'warning',
//             message: `This event is not happening today. It is scheduled for ${appointmentDate}.`,
//             visible: true,
//             onClose: () => setAlert((prevState) => ({ ...prevState, visible: false }))
//         });
//         return;
//     }

//     setDisabledTickets((prev) => [...prev, ticketId]);
//     setConfirmAction({ type: 'attend', ticketId, eventTitle });
//     setAlert({
//         type: 'check',
//         message: 'Please check you are on the event day, Are you sure you want to mark this event as attended?',
//         visible: true,
//         onClose: () => setAlert((prevState) => ({ ...prevState, visible: false }))
//     });
// }; 

//   const handleConfirm = async () => {
//     if (confirmAction) {
//       await attendEvent(confirmAction.ticketId, confirmAction.eventTitle);
//       if (user && user.email) {
//         fetchApprovedEvents(user.email);
//       }
//     }
//     setAlert((prevState) => ({ ...prevState, visible: false }));
//     setConfirmAction(null);
//   };

//   const handleShowLocation = () => setLocationModalVisible(true);
//   const handleCloseLocationModal = () => setLocationModalVisible(false);

//   const filteredEvents = activeTab === 'Event List' 
//     ? events.filter(event => event.name.toLowerCase().includes(searchTerm.toLowerCase()))
//     : myTickets.filter(event => event.eventTitle.toLowerCase().includes(searchTerm.toLowerCase()));

//   const getDaysUntilEvent = (eventDate: string): number => {
//     const today = new Date();
//     const event = new Date(eventDate);
//     const diffTime = event.getTime() - today.getTime();
//     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//     return diffDays;
//   };   

//   const styles = {
//     icon: {
//       color: theme.primaryColor,
//     },
//     title: {
//       color: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//     text: {
//       color: theme.textColor,
//       fontFamily: theme.fontFamily,
//     },
//     button: {
//       color: theme.text2Color,
//       backgroundColor: theme.primaryColor,
//       fontFamily: theme.fontFamily,
//     },
//   };

//   // Event List Card
//   const renderEventListCard = (event: Event) => (
//     <div key={event.id} className="flex flex-row items-center rounded-lg p-4 relative bg-gray-100 sm:flex-col xs:flex-col">
//       <img
//         src={getEventImagePath(event.image, apiUrl)}
//         alt={event.eventTitle}
//         className="flex w-[200px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
//       />
//       <div className="mt-4 flex-1 sm:mt-0 xs:mt-0">
//         <div className="text-xl text-left font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-sm xs:text-center" 
//           style={styles.title}>
//             {event.name}
//         </div>
//         <div className="flex flex-wrap">
//         {event.MemberType.map((memberType, index) => {
//           const shortForm = convertToShortForm(memberType);
//           const tagColor = getTagColor(shortForm);
//           return (
//             <div 
//               key={index} 
//               className={`${tagColor} py-1 px-2 rounded-lg text-xs flex items-center space-x-1 sm:text-[10px] xs:text-[10px]`} 
//               title={memberType}>
//               <FaTag />
//               <span>{shortForm}</span>
//             </div>
//           );
//         })}
//       </div>
//         <div className="my-2">
//           <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//             <strong>Date:</strong> {formatDateWithTime(event.startDate, event.time)}
//           </div>
//           <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//             <strong>Venue:</strong> {event.location || 'N/A'}
//           </div>
//           <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//             <strong>Amount:</strong> {event.amount === 0 ? 'Free of Charges' : `${event.currency} ${formatAmount(event.amount)}`}
//           </div>
//         </div>
//         <div className="flex items-center text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//           <span className="mr-2"><strong>Slots:</strong></span>{getSlotMessage(event.slot)}
//         </div>
//       </div>
//       <button
//         className="px-2 mt-4 rounded-full self-center flex items-center justify-around md:text-sm sm:text-xs xs:text-xs"
//         style={styles.icon}
//         onClick={() => handleDetailClick(event)}
//         title="View Details"
//       >
//         <FaInfoCircle className="w-5 h-5 md:w-4 sm:w-3 xs:w-3" />
//         <div className='ml-2' style={styles.title}>
//           More Details
//         </div>
//       </button>
//     </div>
//   );
  
//   // My Ticket List Card
//   const renderMyTicketCard = (event: MyTicketEvent) => {
//     const daysUntilEvent = getDaysUntilEvent(event.appointmentDate);
//     const isEventToday = isToday(event.appointmentDate);
//     const isAttended = attendedTickets.includes(event.ticketId);
//     const isExpired = new Date(event.appointmentDate) < new Date(); 

//     return (
//       <div key={event.ticketId} className="flex flex-row items-center rounded-lg p-4 relative bg-gray-100 sm:flex-col xs:flex-col">
//         {event.eventStatus === 'expired' && (
//         <div className="absolute inset-0 bg-gray-700 opacity-75 rounded-lg flex items-center justify-center">
//           <span className="text-white font-semibold bg-red-800 py-2 px-4 rounded-full">Event expired on {formatDateWithTime(event.endDate, event.time)}</span>
//         </div>
//         )}
//           <img
//             src={getEventImagePath(event.image, apiUrl)}
//             alt={event.eventTitle}
//             className="flex w-[200px] mx-4 rounded-lg object-cover md:w-[150px] sm:w-[125px] xs:w-[100px]"
//           />
//           <div className="mt-4 flex-1 sm:mt-0 xs:mt-0">
//             <div className="text-xl text-left font-bold my-2 md:text-lg sm:text-lg sm:text-center xs:text-sm xs:text-center" 
//                 style={styles.title}>{event.eventTitle}</div>
//               <div className="my-2">
//                 <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//                   <span className="font-extrabold mr-4">Ticket ID:</span>{event.ticketId}
//                 </div>
//                 <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//                   <span className="font-extrabold mr-4">Date:</span>{formatDateWithTime(event.appointmentDate, event.time)}
//                 </div>
//                 <div className="text-sm text-zinc-600 md:text-xs sm:text-xs xs:text-xs">
//                   <span className="font-extrabold mr-4">Location:</span>{event.location}
//                     {event.location === "Noah's Space" && (
//                       <button
//                         type="button"
//                         className="ml-2"
//                         onClick={handleShowLocation}
//                       >
//                         <FaMapMarkerAlt className="ml-1 w-4 h-4 xs:w-3" style={styles.icon} />
//                       </button>
//                     )}
//                       {event.location === "Online Conference" && (
//                         <button
//                           type="button"
//                           className="ml-2"
//                           onClick={() => {
//                               setOnlineConferenceText(event.onlineConferenceText || '');
//                               setOnlineConferenceModalVisible(true);
//                           }}
//                         >
//                           <FaInfoCircle className="ml-1 w-4 h-4 xs:w-3" style={styles.icon} />
//                         </button>
//                       )}
//                     </div>
//                   </div>
                  
//                   {/* Noah Space Modal */}
//                   {locationModalVisible && (
//                     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
//                         <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto">
//                           <div className="flex justify-between items-center mb-4">
//                             <h2 className="text-xl font-semibold">Location Details</h2>
//                               <button onClick={handleCloseLocationModal} className="text-gray-500 hover:text-gray-800">
//                                 <FaTimes className="w-6 h-6" />
//                               </button>
//                             </div>
//                           <div>
//                           <div className="mb-4">
//                             <h3 className="font-semibold">Address:</h3>
//                             <p>Unit 3-2-2, Kompleks Kantonmen Prima, 698, Jln Ipoh, Taman Bamboo, 51200 Kuala Lumpur, Federal Territory of Kuala Lumpur</p>
//                           </div>
//                           <div className="mb-4">
//                             <h3 className="font-semibold">Google Maps:</h3>
//                             <div className="flex items-center">
//                                 <a 
//                                     href="https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7" 
//                                     target="_blank" 
//                                     rel="noopener noreferrer" 
//                                     className="text-blue-500 underline"
//                                     style={{ color: theme.primaryColor }}
//                                 >
//                                     https://maps.app.goo.gl/7tUGGuGDy2FsMm8r7
//                                 </a>
//                             </div>
//                           </div>
//                         <div>
//                           <img src={officeImg} alt="Noah's Space location" className="w-full h-auto" />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   )}

//                   {/* Online Link Modal */}
//                   {onlineConferenceModalVisible && (
//                     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
//                       <div className="bg-white p-10 rounded-lg max-w-lg w-full h-auto">
//                         <div className="flex justify-between items-center mb-4">
//                           <h2 className="text-xl font-semibold">Online Conference Details</h2>
//                           <button onClick={() => setOnlineConferenceModalVisible(false)} className="text-gray-500 hover:text-gray-800">
//                             <FaTimes className="w-6 h-6" />
//                           </button>
//                         </div>
//                         <div>
//                           <div className="mb-4">
//                             <h3 className="font-semibold">Details:</h3>
//                             <p dangerouslySetInnerHTML={{ __html: onlineConferenceText }}></p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   )}
//                 <div className="text-sm text-zinc-600 flex items-center mt-2 md:text-xs sm:text-xs xs:text-xs">
//                   <span className="font-extrabold mr-2">Event Status:</span>
//                   <span className={getEventStatusStyle(event.eventStatus)}>{event.eventStatus}</span>
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <button
//                   className={`px-4 py-2 mt-4 mr-4 rounded-full self-center flex items-center justify-around md:text-sm sm:text-xs xs:text-xs ${
//                     isExpired || isAttended || event.eventStatus === 'attend' 
//                       ? 'bg-gray-400 text-gray-100 cursor-not-allowed'
//                       : disabledTickets.includes(event.ticketId) 
//                       ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
//                       : 'bg-blue-500 text-white'
//                     }`}
//                   onClick={() => !isExpired && event.eventStatus !== 'attend' && handleAttendClick(event.ticketId, event.eventTitle, event.appointmentDate)}
//                   title={isExpired || isAttended || event.eventStatus === 'attend' ? 'Expired' : 'Attend'}
//                   disabled={
//                     isExpired ||
//                     isAttended ||
//                     event.eventStatus === 'attend' ||
//                     disabledTickets.includes(event.ticketId) ||
//                     (!isEventToday && daysUntilEvent > 0)
//                   }
//                   style={{ 
//                     backgroundColor: 
//                       isExpired || isAttended || event.eventStatus === 'attend'
//                         ? 'gray'
//                         : disabledTickets.includes(event.ticketId)
//                         ? 'gray'
//                         : theme.primaryColor, 
//                     fontFamily: theme.fontFamily 
//                   }}
//                 >
//                   {isExpired 
//                     ? 'Expired'
//                     : isAttended || event.eventStatus === 'attend' 
//                     ? 'Attended' 
//                     : (isEventToday ? 'Check-In' : `Event in ${daysUntilEvent} day(s)`)}
//                 </button>
//               </div>
//             </div>
//           );
//         };

//   return (
//     <div className="h-auto w-auto px-4 py-2">
//       <MyTicketAlert 
//         {...alert}
//         onConfirm={handleConfirm}
//         onCancel={() => setAlert((prevState) => ({ ...prevState, visible: false }))}
//       />
//       {!selectedEvent && (
//         <div className="flex flex-row justify-between items-center p-6 border-2 rounded-xl sm:flex-col sm:p-4 xs:flex-col xs:p-3 xs:rounded-lg">
//           <div className="flex items-center">
//             <img src={r1Logo} alt="Logo" className="w-20 h-18 mr-4 md:w-14 md:h-14 md:mr-2 sm:w-[65px] xs:w-[55px]" />
//             <div className="mx-4 mt-5">
//               <div className="text-xl font-bold md:text-base sm:text-sm xs:text-sm" style={styles.title}>
//                   Classes and Events
//               </div>
//             </div>
//           </div>
//           <div className="flex items-center p-2 rounded-lg mt-2">
//             <div className="relative">
//               <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
//               <input
//                 type="text"
//                 placeholder="Search events..."
//                 className="p-2 pl-10 rounded border-2 w-auto md:w-[220px] sm:w-full sm:h-[35px] sm:text-sm xs:h-[30px] xs:text-xs"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//             </div>
//             <button onClick={handleRefreshClick} className="ml-4" title="Refresh">
//               <FaSyncAlt style={styles.title}/>
//             </button>
//           </div>
//         </div>
//       )}

//       {!selectedEvent ? (
//         <div className="mt-4 px-2 rounded-lg overflow-hidden">
//           <div className="flex justify-around font-semibold sm:text-sm xs:text-xs"
//               style={styles.title}>
//             <button
//               className={`${activeTab === 'Event List' ? 'border-b-4 border-cyan-700' : ''}`}
//               onClick={() => setActiveTab('Event List')}
//               style={styles.title}>
//               Event List
//             </button>
//             <button
//               className={`${activeTab === 'My Ticket' ? 'border-b-4 border-cyan-700' : ''}`}
//               onClick={() => setActiveTab('My Ticket')}
//               style={styles.title}>
//               My Ticket
//             </button>
//           </div>
//           <div className="mt-4 space-y-4 overflow-y-auto h-[750px] md:h-[500px] sm:h-[400px] xs:h-[300px]">
//             {activeTab === 'Event List'
//               ? filteredEvents.length > 0 
//                 ? (filteredEvents as Event[]).map(renderEventListCard)
//                 : (
//                   <div className="flex flex-col items-center justify-center h-full border-4 border-dashed border-gray-200 p-4 rounded-lg">
//                     <div className="flex justify-center items-center">
//                       <p className="text-lg font-semibold mb-2 sm:text-base xs:text-sm">No events available</p>
//                       <FaSearch className="w-6 h-6 ml-2 mb-2 sm:w-4 xs:w-[15px]"/>
//                     </div>
//                     <p className="text-gray-400 mb-4 sm:text-center sm:text-sm xs:text-center xs:text-xs">Please check back later for more events.</p>
//                   </div>
//                 )
//               : (filteredEvents as MyTicketEvent[]).length > 0 
//                 ? (filteredEvents as MyTicketEvent[]).map(renderMyTicketCard)
//                 : (
//                   <div className="flex flex-col items-center justify-center h-full border-4 border-dashed border-gray-200 p-4 rounded-lg">
//                     <div className="flex justify-center items-center">
//                       <p className="text-lg font-semibold mb-2 sm:text-base xs:text-sm">You have no tickets yet</p>
//                       <IoTicketSharp className="w-6 h-6 ml-2 mb-2 sm:w-4 xs:w-[15px]"/>
//                     </div>
//                     <p className="text-gray-400 mb-4 sm:text-center sm:text-sm xs:text-center xs:text-xs">Browse our events and buy tickets to join.</p>
//                     <button
//                       onClick={() => setActiveTab('Event List')}
//                       className="px-4 py-2 rounded-lg sm:text-sm xs:text-sm"
//                       style={styles.button}>
//                         Go to Event List
//                     </button>
//                   </div>
//                 )}
//           </div>
//         </div>
//       ) : (
//         <EventDetail event={selectedEvent} onBack={() => setSelectedEvent(null)} />
//       )}
//     </div>
//   );
// };

// export default MyTicket;

